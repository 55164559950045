import {
  Button,
  LoadingSpinner,
  PlainButton,
  SidebarDrawer,
  Tabs,
} from "@jugl-web/ui-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import {
  EntitySpaceType,
  useRestApiProvider,
  UserGeneralProfile,
} from "@jugl-web/rest-api";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useUserRolePermissionWarning } from "@jugl-web/domain-resources/users/hooks/useUserRolePermissionWarning";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { CHAT_GROUP_MAX_SIZE } from "@jugl-web/utils/consts";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";

export const SelectMembersDrawer: React.FC<{
  isOpen: boolean;
  isDisabled?: boolean;
  users?: UserGeneralProfile[];
  workspaceId?: string;
  currentGroupUsersCount?: number;
  onSelectUsers: (users: UserGeneralProfile[]) => void;
  onRequestClose: () => void;
}> = ({
  isOpen,
  isDisabled,
  workspaceId,
  users,
  currentGroupUsersCount = 0,
  onRequestClose,
  onSelectUsers,
}) => {
  const { t } = useTranslations();
  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedUsers, setSelectedUsers] = useState<UserGeneralProfile[]>([]);
  const { entitySpacesApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { searchQuery, inputProps, reset } = useSearchInput();
  const meId = useSelector(selectUserId);
  const getUserRolePermissionWarning = useUserRolePermissionWarning();

  useEffect(() => {
    if (!isOpen) {
      setSelectedUsers(users || []);
      setSelectedTab("all");
      reset();
    }
  }, [isOpen, users, reset]);

  const totalSelectedUsersCount = useMemo(
    () => selectedUsers.length + currentGroupUsersCount,
    [selectedUsers, currentGroupUsersCount]
  );

  const handleUserSelect = useCallback((selectedUser: UserGeneralProfile) => {
    setSelectedUsers((prev) =>
      prev.find((item) => item.id === selectedUser.id)
        ? prev.filter((item) => item.id !== selectedUser.id)
        : [...prev, selectedUser]
    );
  }, []);

  const { data: spaces, isLoading } =
    entitySpacesApi.use_DEPRECATED_allEntitySpacesListQuery({
      entityId: entity.id,
      params: {
        type: EntitySpaceType.dept,
      },
    });

  const tabs = useMemo(
    () => [
      {
        id: "all",
        label: t({
          id: "common.all",
          defaultMessage: "All",
        }),
      },
      ...(spaces
        ? spaces.map((space) => ({ id: space.id, label: space.info.title }))
        : []),
    ],
    [spaces, t]
  );

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      customHeader={
        <div className="border-dark-100 flex items-center gap-4 border-0 border-b border-solid py-[14px] pl-8 pr-6">
          <PlainButton onClick={onRequestClose}>
            <ArrowIcon />
          </PlainButton>
          <div className="flex flex-col gap-0.5">
            <span className="font-secondary text-sm font-semibold text-[#383838]">
              {t({
                id: "chats-page.select-members",
                defaultMessage: "Select Members",
              })}
            </span>
            <span className="text-grey-900 font-secondary leading-2 text-sm">
              {t(
                {
                  id: "chats-page.up-to-limit",
                  defaultMessage: "Up to {limit}",
                },
                { limit: CHAT_GROUP_MAX_SIZE }
              )}
            </span>
          </div>
        </div>
      }
      hasBackdrop={false}
    >
      <SidebarDrawer.Content className="flex flex-col pt-6">
        <div className="px-8">
          <SearchInput
            variant="filled"
            color="grey"
            {...inputProps}
            onReset={reset}
            onClear={reset}
          />
          <Tabs isLoading={isLoading} className="mt-3 shrink-0" color="grey">
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab.id}
                color="grey"
                fontSize="sm"
                label={tab.label}
                isActive={selectedTab === tab.id}
                onClick={() => setSelectedTab(tab.id)}
              />
            ))}
          </Tabs>
        </div>
        <div className="mt-6 mb-3 h-full">
          <HeadlessUsersList
            fetchParams={{
              entityId: entity.id,
              searchQuery,
              excludeUsers: meId,
              excludeWorkspaces: workspaceId,
              departments: selectedTab !== "all" ? selectedTab : "",
              sortBy: "name",
              preferCache: false,
            }}
            userRenderer={(user) => (
              <div className="mx-8 mb-2.5">
                <UserListItem
                  entityId={entity.id}
                  userId={user.id}
                  variant="web"
                  mode="check"
                  isSelected={
                    !!selectedUsers.find((item) => item.id === user.id)
                  }
                  onSelect={handleUserSelect}
                  defaultSubTitle="department"
                  highlightText={searchQuery}
                  isDisabled={!hasRoleAccessToModule(user.role, AppModule.chat)}
                  disabledInfo={getUserRolePermissionWarning(AppModule.chat)}
                />
              </div>
            )}
            emptyContent={
              <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                {t({
                  id: "common.no-users-found",
                  defaultMessage: "No users found",
                })}
              </div>
            }
            loadingContent={
              <div className="flex h-full w-full items-center justify-center">
                <LoadingSpinner />
              </div>
            }
            virtualized
          />
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex flex-col items-center justify-center gap-3 pb-8 pt-4">
        <Button
          className="h-10 w-[300px]"
          isDisabled={
            isDisabled ||
            !selectedUsers.length ||
            totalSelectedUsersCount > CHAT_GROUP_MAX_SIZE
          }
          color={
            totalSelectedUsersCount > CHAT_GROUP_MAX_SIZE
              ? "tertiary"
              : "primary"
          }
          onClick={() => {
            onSelectUsers(selectedUsers);
            onRequestClose();
          }}
        >
          {selectedUsers.length
            ? t(
                {
                  id: "common.select-with-count",
                  defaultMessage: "Select {count}",
                },
                {
                  count: selectedUsers.length,
                }
              )
            : t({
                id: "common.select",
                defaultMessage: "Select",
              })}
        </Button>
        {totalSelectedUsersCount > CHAT_GROUP_MAX_SIZE && (
          <span className="text-tertiary-700 font-secondary leading-2 text-xs">
            {t(
              {
                id: "chats-page.group-chat-limit-members",
                defaultMessage:
                  "Group chat can contain a maximum of {limit} members",
              },
              { limit: CHAT_GROUP_MAX_SIZE }
            )}
          </span>
        )}
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
