import { cx } from "@jugl-web/utils";
import React from "react";
import { ReactComponent as CancelIcon } from "./assets/cancel-icon.svg";
import { ReactComponent as CancelOutgoingIcon } from "./assets/cancel-outgoing-icon.svg";

export const CircleProgressBar: React.FC<{
  progress: number;
  isOutgoing: boolean;
}> = ({ progress, isOutgoing }) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="relative flex h-[24px] w-[24px]">
      <svg
        className="absolute top-0 left-0 h-full w-full"
        viewBox="0 0 100 100"
      >
        <circle
          className={isOutgoing ? "stroke-primary-100" : "stroke-dark-400"}
          strokeWidth="10"
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
        />
        <circle
          className={cx(
            "origin-center -rotate-90 transition-all duration-300",
            isOutgoing ? "stroke-primary" : "stroke-white"
          )}
          strokeWidth="10"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          r="40"
          cx="50"
          cy="50"
          strokeLinecap="round"
        />
      </svg>
      {/* {isOutgoing ? <CancelOutgoingIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /> : <CancelIcon />} */}
      <div className="absolute top-1/2 left-1/2 h-[18px] -translate-x-1/2 -translate-y-1/2">
        {isOutgoing ? <CancelOutgoingIcon /> : <CancelIcon />}
      </div>
    </div>
  );
};
