import React, { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { isImageType, isoToLocalDate } from "@web-src/utils/helper";
import { FileType, DriveItemDisplayType } from "@jugl-web/rest-api/drive/types";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import format from "date-fns-tz/format";
import { useToast, useTranslations } from "@jugl-web/utils";
import { MenuSections } from "@jugl-web/ui-components";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { useDeleteObject } from "../../../../hooks/useDeleteObject";
import DriveDeleteModal from "../../../DriveDeleteModal";
import DriveObject from "../DriveObject";
import { actionSidebar } from "../../../../driveSlice";
import MoveFileSidebar from "../MoveFileSidebar";
import { ReactComponent as ArrowLeftIcon } from "./assets/arrowLeft.svg";
import { ReactComponent as DeleteIcon } from "./assets/bin.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";

type FileDetails = {
  id: string;
  name: string;
};

const DriveFile: React.FC<{
  handleOpen?: () => void;
  id: string;
  name: string;
  search: string;
  type: FileType;
  ext: string;
  link: string;
  display: DriveItemDisplayType;
  date: string;
  size: number;
  mime: string;
  shortUrl: string;
  previewImg?: string;
  readOnly?: boolean;
}> = ({
  handleOpen,
  id,
  name,
  search,
  type,
  display,
  ext,
  link,
  date,
  size,
  mime,
  shortUrl,
  previewImg,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const { hasEmployeeLikeRole } = useUserRole();
  const { toast } = useToast({ variant: "web" });
  const { deleteObject } = useDeleteObject();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToMove, setFileToMove] = useState<FileDetails | null>(null);

  const isMoveFileSidebarOpen = !!fileToMove;
  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(shortUrl);
    toast(
      t(
        {
          id: "drive-page.link-was-copied-to-clipboard",
          defaultMessage: "{type} link was copied to clipboard",
        },
        {
          type: "File",
        }
      )
    );
  }, [shortUrl, toast, t]);

  const displayFileMenuElements: MenuSections = useMemo(
    () =>
      readOnly
        ? []
        : [
            [
              {
                id: "rename",
                isHidden: hasEmployeeLikeRole,
                icon: <EditIcon />,
                label: t({
                  id: "common.rename",
                  defaultMessage: "Rename",
                }),
                onSelect: () =>
                  dispatch(
                    actionSidebar({
                      id,
                      type,
                      name,
                      allowed_users: undefined,
                      action: "rename",
                    })
                  ),
              },
              {
                id: "copy-link",
                icon: <CopyIcon />,
                label: t({
                  id: "common.copy-link",
                  defaultMessage: "Copy link",
                }),
                onSelect: () => handleCopyLink(),
              },
              {
                id: "move",
                isHidden: hasEmployeeLikeRole,
                icon: <ArrowLeftIcon />,
                label: t({
                  id: "common.move",
                  defaultMessage: "Move",
                }),
                onSelect: () => setFileToMove({ id, name: name.concat(ext) }),
              },
              {
                id: "delete",
                isHidden: hasEmployeeLikeRole,
                icon: <DeleteIcon />,
                label: t({
                  id: "common.delete",
                  defaultMessage: "Delete",
                }),
                onSelect: () => setIsDeleteModalOpen(true),
              },
            ],
          ],
    [
      readOnly,
      dispatch,
      id,
      type,
      name,
      ext,
      t,
      handleCopyLink,
      hasEmployeeLikeRole,
    ]
  );

  return (
    <>
      <DriveObject
        title={name}
        search={search}
        firstSubtitle={format(isoToLocalDate(date), "MMM dd, yyyy")}
        secondSubtitle={getFileSizeLabel(size)}
        displayFileMenuElements={displayFileMenuElements}
        image={previewImg || (isImageType(mime) ? link : undefined)}
        icon={ext.toLowerCase()}
        display={display}
        onClick={() => handleOpen?.()}
        fileType={false}
      />
      {isDeleteModalOpen && (
        <DriveDeleteModal
          name={name.concat(ext)}
          type="file"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onClick={() => deleteObject("file", id)}
        />
      )}
      {isMoveFileSidebarOpen && (
        <MoveFileSidebar
          isOpen={fileToMove}
          onRequestClose={() => setFileToMove(null)}
        />
      )}
    </>
  );
};

export default DriveFile;
