import {
  SupportedXAxis,
  SupportedYAxis,
  WidgetChartSorting,
  WidgetChartType,
  WidgetChartYAxisFormat,
  WidgetModel,
} from "@jugl-web/rest-api/dashboard/models/Widget";
import { UpdateWidgetAttributes } from "@jugl-web/rest-api/dashboard/types";
import { TextInput } from "@jugl-web/ui-components";
import { Accordion } from "@jugl-web/ui-components/cross-platform/Accordion";
import { FormGroup } from "@jugl-web/ui-components/cross-platform/forms/FormGroup";
import { Segment } from "@jugl-web/ui-components/cross-platform/forms/Segment";
import { SelectTrigger } from "@jugl-web/ui-components/cross-platform/forms/SelectTrigger";
import { ColorPickerPopover } from "@jugl-web/ui-components/web/ColorPickerPopover/ColorPickerPopover";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { colord } from "colord";
import { FC, useEffect, useMemo, useState } from "react";
import { useDashboardWidgetChartConfig } from "../../hooks/useDashboardWidgetChartConfig";
import { useUnassignedCategoryLabel } from "../../hooks/useUnassignedCategoryLabel";
import { DashboardChartTypeList } from "../DashboardChartTypeList";
import { CheckboxButton } from "./CheckboxButton";

interface DashboardWidgetDialogSettingsSidebarProps {
  isOpen: boolean;
  widget: WidgetModel;
  onUpdateWidget: (attributes: UpdateWidgetAttributes) => void;
}

const CHART_NAME_MAX_LENGTH = 100;

const chartNameValidator = (name: string) =>
  name.trim().length > 0 && name.length <= CHART_NAME_MAX_LENGTH;

export const DashboardWidgetDialogSettingsSidebar: FC<
  DashboardWidgetDialogSettingsSidebarProps
> = ({ isOpen, widget, onUpdateWidget: onExternalUpdateWidget }) => {
  const { entityId } = useEntitySelectedProvider();

  const [internalWidgetState, setInternalWidgetState] = useState(widget);

  const { getUnassignedCategoryLabelByXAxisType } = useUnassignedCategoryLabel({
    entityId,
  });

  const { title: internalWidgetTitle, config: internalWidgetConfig } =
    internalWidgetState;

  const unassignedCategoryLabel = getUnassignedCategoryLabelByXAxisType(
    internalWidgetConfig.x_axis
  );

  const {
    chartXAxisItems,
    chartYAxisItems,
    chartSortingItems,
    getChartXAxisItemById,
    getChartYAxisItemById,
    getChartSortingItemById,
    getChartColorPalette,
  } = useDashboardWidgetChartConfig();

  const { t } = useTranslations();

  const selectedXAxisItem = useMemo(
    () => getChartXAxisItemById(internalWidgetConfig.x_axis),
    [getChartXAxisItemById, internalWidgetConfig.x_axis]
  );

  const selectedYAxisItem = useMemo(
    () => getChartYAxisItemById(internalWidgetConfig.series[0]?.y_axis),
    [getChartYAxisItemById, internalWidgetConfig.series]
  );

  const selectedSortingItem = useMemo(
    () => getChartSortingItemById(internalWidgetConfig.meta.sorting),
    [getChartSortingItemById, internalWidgetConfig.meta.sorting]
  );

  const colorPalette = useMemo(
    () => getChartColorPalette(internalWidgetConfig),
    [getChartColorPalette, internalWidgetConfig]
  );

  const handleUpdateWidget = (updatedAttributes: UpdateWidgetAttributes) => {
    const updatedWidget: WidgetModel = {
      ...internalWidgetState,
      ...updatedAttributes,
    };

    // Update internal state to immediately reflect changes in the UI
    setInternalWidgetState(updatedWidget);

    // Propagate the changes further up to update the widget in the server
    onExternalUpdateWidget(updatedAttributes);
  };

  const handleUpdateWidgetConfig = (
    updatedAttributes: Partial<WidgetModel["config"]>
  ) => {
    const updatedConfig: WidgetModel["config"] = {
      ...internalWidgetConfig,
      ...updatedAttributes,
    };

    handleUpdateWidget({ config: updatedConfig });
  };

  const handleUpdateWidgetName = () => {
    if (!chartNameValidator(internalWidgetTitle)) {
      return;
    }

    if (internalWidgetTitle === widget.title) {
      return;
    }

    handleUpdateWidget({ title: internalWidgetTitle });
  };

  const handleSelectChartType = (chartType: WidgetChartType) => {
    handleUpdateWidgetConfig({
      series: [{ ...internalWidgetConfig.series[0], type: chartType }],
    });
  };

  const handleSelectXAxis = (xAxis: SupportedXAxis) => {
    handleUpdateWidgetConfig({ x_axis: xAxis });
  };

  const handleSelectYAxis = (yAxis: SupportedYAxis) => {
    handleUpdateWidgetConfig({
      series: [{ ...internalWidgetConfig.series[0], y_axis: yAxis }],
    });
  };

  const handleToggleIncludeTasksWithNoDueDate = () => {
    handleUpdateWidgetConfig({
      include_nodue: !internalWidgetConfig.include_nodue,
    });
  };

  const handleToggleIncludeOverdueTasks = () => {
    handleUpdateWidgetConfig({
      include_overdue: !internalWidgetConfig.include_overdue,
    });
  };

  const handleToggleShowEmptyValues = () => {
    handleUpdateWidgetConfig({ show_empty: !internalWidgetConfig.show_empty });
  };

  const handleToggleShowUnassignedCategories = () => {
    handleUpdateWidgetConfig({
      meta: {
        ...internalWidgetConfig.meta,
        show_unassigned_categories:
          !internalWidgetConfig.meta.show_unassigned_categories,
      },
    });
  };

  const handleSelectYAxisType = (yAxisFormat: WidgetChartYAxisFormat) => {
    handleUpdateWidgetConfig({
      meta: { ...internalWidgetConfig.meta, y_axis_format: yAxisFormat },
    });
  };

  const handleSelectSorting = (sorting: WidgetChartSorting) => {
    handleUpdateWidgetConfig({
      meta: { ...internalWidgetConfig.meta, sorting },
    });
  };

  const handleChangePaletteColor = (
    updatedColor: string,
    updatedColorIndex: number
  ) => {
    const updatedColorPalette = colorPalette.map((color, index) =>
      index === updatedColorIndex ? updatedColor : color
    );

    handleUpdateWidgetConfig({
      meta: {
        ...internalWidgetConfig.meta,
        color_palette: updatedColorPalette,
      },
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setInternalWidgetState(widget);
    }
  }, [isOpen, widget]);

  return (
    <div
      className={cx(
        "jugl__custom-scrollbar h-full overflow-y-auto border border-r-0 border-t-0 border-b-0 border-solid border-[#E0E0E0] bg-white transition-all will-change-[width]",
        isOpen ? "w-[28%] min-w-[320px] opacity-100" : "w-0 min-w-0 opacity-0"
      )}
    >
      <div className="flex min-w-full flex-col gap-7 whitespace-nowrap p-10">
        <div className="border border-r-0 border-l-0 border-t-0 border-solid border-[#E0E0E0] pb-7">
          <TextInput
            label={t({
              id: "form-controls.dashboard-chart-name",
              defaultMessage: "Chart Name",
            })}
            isRequired
            isInvalid={!chartNameValidator(internalWidgetTitle)}
            lengthIndicator={
              internalWidgetTitle.length >= 90
                ? { max: CHART_NAME_MAX_LENGTH }
                : undefined
            }
            value={internalWidgetTitle}
            onChange={(event) =>
              setInternalWidgetState((previousState) => ({
                ...previousState,
                title: event.target.value,
              }))
            }
            onBlur={handleUpdateWidgetName}
          />
        </div>
        <Accordion
          initialIsOpen
          variant="web"
          title={
            <span className="font-secondary text-base font-medium leading-4">
              {t({
                id: "dashboard-page.chart-type",
                defaultMessage: "Chart type",
              })}
            </span>
          }
          className="p-0"
        >
          <DashboardChartTypeList
            selectedChartType={internalWidgetConfig.series[0]?.type}
            className="pt-4"
            onSelect={handleSelectChartType}
          />
        </Accordion>
        <div className="flex flex-col gap-4 border border-r-0 border-l-0 border-solid border-[#E0E0E0] py-7">
          {/* X-axis */}
          <ResourcePickerPopover
            placement="bottom"
            className="w-[420px]"
            renderTrigger={({ Trigger, triggerRef, isOpen: isPopoverOpen }) => (
              <FormGroup
                label={t({
                  id: "dashboard-page.x-axis",
                  defaultMessage: "X-axis",
                })}
              >
                <Trigger
                  ref={triggerRef}
                  as={SelectTrigger}
                  hasValue={!!selectedXAxisItem}
                  isOpen={isPopoverOpen}
                  label={
                    selectedXAxisItem?.name ||
                    t({ id: "common.select", defaultMessage: "Select" })
                  }
                  className="w-full"
                />
              </FormGroup>
            )}
            hasSearch
            items={chartXAxisItems.map((item) => ({
              id: item.id,
              value: item,
            }))}
            selectionBehavior={{ mode: "single", canToggle: false }}
            itemSize="lg"
            maxVisibleItems={6}
            defaultSelectedIds={[internalWidgetConfig.x_axis]}
            onSelect={({ item, onClose }) => {
              handleSelectXAxis(item.value.id);
              onClose();
            }}
            renderLabel={(item) => (
              <span className="text-dark font-secondary text-sm -tracking-[0.14px]">
                {item.value.name}
              </span>
            )}
            renderStartIcon={(item, _, isSelected) => (
              <item.value.SvgIcon
                className={cx(isSelected ? "text-primary" : "text-grey-500")}
              />
            )}
            renderSearchLabel={(item) => item.value.name}
          />
          {/* Y-axis */}
          <ResourcePickerPopover
            placement="bottom"
            className="w-[420px]"
            renderTrigger={({ Trigger, triggerRef, isOpen: isPopoverOpen }) => (
              <FormGroup
                label={t({
                  id: "dashboard-page.y-axis",
                  defaultMessage: "Y-axis",
                })}
              >
                <Trigger
                  ref={triggerRef}
                  as={SelectTrigger}
                  hasValue={!!selectedYAxisItem}
                  isOpen={isPopoverOpen}
                  label={
                    selectedYAxisItem?.name ||
                    t({ id: "common.select", defaultMessage: "Select" })
                  }
                  className="w-full"
                />
              </FormGroup>
            )}
            hasSearch
            items={chartYAxisItems.map((item) => ({
              id: item.id,
              value: item,
            }))}
            selectionBehavior={{ mode: "single", canToggle: false }}
            itemSize="lg"
            maxVisibleItems={6}
            defaultSelectedIds={[internalWidgetConfig.series[0]?.y_axis]}
            onSelect={({ item, onClose }) => {
              handleSelectYAxis(item.value.id);
              onClose();
            }}
            renderLabel={(item) => (
              <span className="text-dark font-secondary text-sm -tracking-[0.14px]">
                {item.value.name}
              </span>
            )}
            renderStartIcon={(item, _, isSelected) => (
              <item.value.SvgIcon
                className={cx(isSelected ? "text-primary" : "text-grey-500")}
              />
            )}
            renderSearchLabel={(item) => item.value.name}
          />
        </div>
        <div className="-mx-1">
          <Accordion
            initialIsOpen
            variant="web"
            title={
              <span className="font-secondary text-base font-medium leading-4">
                {t({
                  id: "dashboard-page.widget-settings",
                  defaultMessage: "Settings",
                })}
              </span>
            }
            className="py-0 px-1"
          >
            <div className="flex flex-col gap-5 px-1 py-4">
              {/* Y Axis format */}
              <Segment
                items={[
                  {
                    id: "value",
                    title: t({
                      id: "dashboard-page.widget-settings-y-axis-format-value",
                      defaultMessage: "Value",
                    }),
                  },
                  {
                    id: "percent",
                    title: t({
                      id: "dashboard-page.widget-settings-y-axis-format-percent",
                      defaultMessage: "Percent (%)",
                    }),
                  },
                ]}
                selectedItem={
                  internalWidgetConfig.meta.y_axis_format || "value"
                }
                onChange={(id) =>
                  handleSelectYAxisType(id as WidgetChartYAxisFormat)
                }
                segmentClassName="[&>button]:p-0 [&>button]:h-10"
              />
              <div className="bg-grey-200 h-px w-full" />
              {/* Include tasks with no due date */}
              <div className="flex flex-col gap-3">
                <CheckboxButton
                  label={t({
                    id: "dashboard-page.widget-settings-include-no-due-tasks",
                    defaultMessage: "Tasks with no due date",
                  })}
                  hint={t({
                    id: "dashboard-page.widget-settings-include-no-due-tasks-hint",
                    defaultMessage:
                      "Include tasks without a due date in the chart data",
                  })}
                  isChecked={internalWidgetConfig.include_nodue}
                  onClick={handleToggleIncludeTasksWithNoDueDate}
                />
                {/* Include overdue tasks */}
                <CheckboxButton
                  label={t({
                    id: "dashboard-page.widget-settings-include-overdue-tasks",
                    defaultMessage: "Overdue tasks",
                  })}
                  hint={t(
                    {
                      id: "dashboard-page.widget-settings-include-overdue-tasks-hint",
                      defaultMessage:
                        "Include overdue tasks from the <b>last {n} months</b> in the chart data",
                    },
                    {
                      n: 6,
                      b: (chunks: (string | JSX.Element)[]) => <b>{chunks}</b>,
                    }
                  )}
                  isChecked={internalWidgetConfig.include_overdue}
                  onClick={handleToggleIncludeOverdueTasks}
                />
                {/* Show empty values */}
                <CheckboxButton
                  label={t({
                    id: "dashboard-page.widget-settings-show-empty-values",
                    defaultMessage: "Show empty values",
                  })}
                  hint={t({
                    id: "dashboard-page.widget-settings-show-empty-values-hint",
                    defaultMessage:
                      "Show categories with a value of 0 on the chart",
                  })}
                  isChecked={internalWidgetConfig.show_empty}
                  onClick={handleToggleShowEmptyValues}
                />
                {/* Show "Without assignee", "Without board", etc. */}
                {unassignedCategoryLabel && (
                  <CheckboxButton
                    label={t(
                      {
                        id: "dashboard-page.widget-settings-show-category",
                        defaultMessage: `Show "{category}" category`,
                      },
                      { category: unassignedCategoryLabel }
                    )}
                    hint={t(
                      {
                        id: "dashboard-page.widget-settings-show-category-hint",
                        defaultMessage: `Show the "{category}" category, which represents unassigned values`,
                      },
                      { category: unassignedCategoryLabel }
                    )}
                    isChecked={
                      internalWidgetConfig.meta.show_unassigned_categories
                    }
                    onClick={handleToggleShowUnassignedCategories}
                  />
                )}
              </div>
              <div className="bg-grey-200 h-px w-full" />
              {/* Sorting options */}
              <ResourcePickerPopover
                placement="bottom"
                className="w-[420px]"
                renderTrigger={({
                  Trigger,
                  triggerRef,
                  isOpen: isPopoverOpen,
                }) => (
                  <FormGroup
                    label={t({
                      id: "common.sort-by",
                      defaultMessage: "Sort by",
                    })}
                  >
                    <Trigger
                      ref={triggerRef}
                      as={SelectTrigger}
                      hasValue={!!selectedSortingItem}
                      isOpen={isPopoverOpen}
                      label={
                        selectedSortingItem?.name ||
                        t({ id: "common.select", defaultMessage: "Select" })
                      }
                      className="w-full"
                    />
                  </FormGroup>
                )}
                items={chartSortingItems.map((item) => ({
                  id: item.id,
                  value: item,
                }))}
                selectionBehavior={{ mode: "single", canToggle: false }}
                itemSize="lg"
                maxVisibleItems={6}
                defaultSelectedIds={[internalWidgetConfig.meta.sorting]}
                onSelect={({ item, onClose }) => {
                  handleSelectSorting(item.value.id);
                  onClose();
                }}
                renderLabel={(item) => (
                  <span className="text-dark font-secondary text-sm -tracking-[0.14px]">
                    {item.value.name}
                  </span>
                )}
                renderStartIcon={(item, _, isSelected) => (
                  <item.value.SvgIcon
                    className={cx(
                      isSelected ? "text-primary" : "text-grey-500"
                    )}
                  />
                )}
                renderSearchLabel={(item) => item.value.name}
              />
              <div className="bg-grey-200 h-px w-full" />
              {/* Color palette */}
              <div className="flex flex-col gap-2">
                <span className="text-dark-700 font-secondary text-base">
                  {t({
                    id: "dashboard-page.widget-settings-color-palette",
                    defaultMessage: "Color palette",
                  })}
                </span>
                <div className="flex flex-wrap items-center gap-1.5">
                  {colorPalette.map((color, index) => (
                    <ColorPickerPopover
                      key={+index}
                      placement="top"
                      title={t({
                        id: "dashboard-page.select-color",
                        defaultMessage: "Select color",
                      })}
                      color={color}
                      onColorChange={(updatedColor) =>
                        handleChangePaletteColor(updatedColor, index)
                      }
                      renderTrigger={({
                        Trigger,
                        triggerRef,
                        isOpen: isPopoverOpen,
                      }) => (
                        <Trigger
                          ref={triggerRef}
                          className={cx(
                            "h-8 w-8 cursor-pointer rounded-md border-2 border-solid transition",
                            isPopoverOpen ? "scale-125" : "hover:scale-110"
                          )}
                          style={{
                            backgroundColor: color,
                            borderColor: colord(color).darken(0.2).toHex(),
                          }}
                        />
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
