import {
  TaskCustomStatus,
  TaskDefaultStatusId,
} from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import {
  ResourcePickerDrawer,
  ResourcePickerDrawerProps,
} from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import {
  ResourcePickerPopover,
  ResourcePickerPopoverProps,
} from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { useTaskStatuses } from "../../../../hooks/useTaskStatuses";
import { ReactComponent as StatusIcon } from "../../assets/status.svg";
import { FieldComponentProps } from "../../types";

interface StatusFieldProps extends FieldComponentProps<"status"> {
  entityId: string;
}

export const StatusField: FC<StatusFieldProps> = ({
  entityId,
  statusId,
  isReadonly,
  hideCompleted,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { allStatusesSorted, getStatusById } = useTaskStatuses({ entityId });

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const isFieldVisible = !!statusId || !isReadonly;

  const status = getStatusById(statusId);

  const statusesAsListItems = useMemo<ListBoxItem<TaskCustomStatus>[]>(() => {
    let statuses = allStatusesSorted;

    if (hideCompleted) {
      statuses = statuses.filter((s) => s.id !== TaskDefaultStatusId.completed);
    }

    return statuses.map((s) => ({ id: s.id, value: s }));
  }, [allStatusesSorted, hideCompleted]);

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: <StatusIcon className="text-primary" />,
    className: "bg-primary-50 text-primary-900 max-w-[250px]",
    children: t(
      {
        id: "tasks-page.status-with-label",
        defaultMessage: "Status: {label}",
      },
      { label: status.text }
    ),
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.select-task-status",
      defaultMessage: "Select task status",
    }),
    items: statusesAsListItems,
    selectionBehavior: { mode: "single", canToggle: false },
    hasSearch: true,
    defaultSelectedIds: [statusId],
    maxVisibleItems: 8,
    itemSize: "lg",
    spaceBetweenItems: "compact",
    renderLabel: (item) => item.value.text,
    onSelect: ({ item, onClose }) => {
      onChange?.(item.id);
      onClose();
    },
  } satisfies Partial<
    | ResourcePickerDrawerProps<TaskCustomStatus>
    | ResourcePickerPopoverProps<TaskCustomStatus>
  >;

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
      {...commonResourcePickerProps}
    />
  );
};
