import { FC, useMemo } from "react";
import {
  InteractiveContainer,
  PlainButton,
  Popover,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useDownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { ReactComponent as OptionsVertical } from "./assets/options-vertical.svg";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as ForwardIcon } from "./assets/forward.svg";
import { ReactComponent as ReplyIcon } from "./assets/reply.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ChatMessageBubbleAction } from "../../../../types/ChatMessageBubbleAction";

export const ContextMenu: FC = () => {
  const { t } = useTranslations();
  const { subscriptionRequiredAction } = useEntityProvider();
  const { files } = useDownloadManagerProvider();
  const {
    isOutgoing,
    attachment,
    message,
    messageTextBody,
    triggerAction,
    triggerReaction,
    hasContentProtectionRestriction,
    hasBroadcastChatRestriction,
  } = useChatMessageProvider();
  const meId = useSelector(selectUserId);
  const { toast } = useToast({ variant: "web" });

  const myReactions = useMemo(
    () =>
      message.reactions
        ?.filter(({ user_id }) => user_id === meId)
        .map(({ reaction }) => reaction) || [],
    [meId, message.reactions]
  );

  const isDownloadAttachmentInProgress = useMemo(() => {
    if (attachment) {
      return files[attachment?.uid]?.status === "in-progress";
    }
    return false;
  }, [attachment, files]);

  const reactionSet = useMemo(() => ["😄", "👍", "👎", "😍", "✅"], []);

  const { hasEmployeeLikeRole } = useUserRole();

  const items: {
    icon: React.ReactNode;
    text: string;
    onClick?: () => void;
    isHidden?: boolean;
  }[] = useMemo(
    () =>
      [
        {
          icon: <ReplyIcon />,
          text: t({
            id: "common.reply",
            defaultMessage: `Reply`,
          }),
          isHidden: hasBroadcastChatRestriction,
          onClick: () => triggerAction(ChatMessageBubbleAction.REPLY),
        },
        {
          icon: <CopyIcon />,
          text: t({
            id: "common.copy",
            defaultMessage: "Copy",
          }),
          isHidden: !messageTextBody || hasContentProtectionRestriction,
          onClick: () => {
            if (messageTextBody) {
              navigator.clipboard.writeText(messageTextBody);
            }
            toast(
              t({
                id: "chats-page.message-copied",
                defaultMessage: "Message copied to your clipboard",
              })
            );
          },
        },
        {
          icon: <ForwardIcon />,
          text: t({
            id: "common.forward",
            defaultMessage: "Forward",
          }),
          isHidden: hasEmployeeLikeRole || hasContentProtectionRestriction,
          onClick: () => triggerAction(ChatMessageBubbleAction.FORWARD),
        },
        {
          icon: <EditIcon />,
          text: t({
            id: "chats-page.edit-message",
            defaultMessage: "Edit Message",
          }),
          isHidden: !isOutgoing || hasBroadcastChatRestriction,
          onClick: () => triggerAction(ChatMessageBubbleAction.EDIT),
        },
        {
          icon: <InfoIcon />,
          onClick: () =>
            triggerAction(ChatMessageBubbleAction.SHOW_MESSAGE_INFO),
          isHidden: !isOutgoing,
          text: t({
            id: "chats-page.message-info",
            defaultMessage: "Message Info",
          }),
        },
        {
          icon: <DeleteIcon />,
          isHidden:
            !isOutgoing ||
            isDownloadAttachmentInProgress ||
            hasBroadcastChatRestriction,
          text: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          onClick: () => triggerAction(ChatMessageBubbleAction.DELETE),
        },
      ].filter((item) => !item.isHidden),
    [
      hasBroadcastChatRestriction,
      hasContentProtectionRestriction,
      hasEmployeeLikeRole,
      isDownloadAttachmentInProgress,
      isOutgoing,
      messageTextBody,
      t,
      toast,
      triggerAction,
    ]
  );

  return (
    <Popover
      placement="right-start"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          as="div"
          ref={triggerRef}
          className={cx(
            "absolute top-0 z-10 flex h-7 w-7 -translate-y-2 cursor-pointer items-center justify-center rounded-full bg-white opacity-0 shadow transition-all hover:bg-gray-50 group-hover:opacity-100",
            isOutgoing ? "left-0 -translate-x-2" : "right-0 translate-x-2",
            isOpen && "bg-gray-50 opacity-100"
          )}
        >
          <OptionsVertical />
        </Trigger>
      )}
      className="py-2"
    >
      {({ onClose }) => (
        <>
          {items.map((item) => (
            <InteractiveContainer
              key={item.text}
              onClick={() => {
                if (item.onClick) {
                  subscriptionRequiredAction(item.onClick)();
                }
                onClose();
              }}
              className="hover:bg-grey-100 flex h-full w-full items-center gap-2.5 px-4 py-2"
            >
              <div className="block h-4 w-4">{item.icon}</div>
              <span className="text-dark grow text-left text-sm leading-[16px]">
                {item.text}
              </span>
            </InteractiveContainer>
          ))}
          <div className="mx-2 mt-2 flex justify-center gap-1 border-0 border-t border-solid border-gray-100 pt-2 text-[20px]">
            {reactionSet.map((item) => (
              <PlainButton
                key={item}
                onClick={() => {
                  onClose();
                  triggerReaction(item);
                }}
                className={cx(
                  "h-8 w-8 cursor-pointer rounded border-none bg-transparent text-[20px] hover:bg-gray-100",
                  myReactions.includes(item) && "bg-gray-100"
                )}
              >
                {item}
              </PlainButton>
            ))}
            <Popover
              placement="top"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  as={PlainButton}
                  ref={triggerRef}
                  className="h-8 w-8 cursor-pointer rounded border-none bg-transparent text-[20px] hover:bg-gray-100"
                >
                  <AddIcon />
                </Trigger>
              )}
            >
              <Picker
                theme="light"
                data={data}
                skinTonePosition="none"
                previewPosition="none"
                onEmojiSelect={(emoji: { [key: string]: string }) => {
                  triggerReaction(emoji.native);
                  onClose();
                }}
              />
            </Popover>
          </div>
        </>
      )}
    </Popover>
  );
};
