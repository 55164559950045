import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  addItems as addItemsAction,
  deleteItem as deleteItemAction,
  updateItem as updateItemAction,
  changeItemId as changeItemIdAction,
  addOrUpdateItem as addOrUpdateItemAction,
  bumpItem as bumpItemItemAction,
  resetAll as resetAllAction,
} from "./paginationSlice";
import { PaginationItem } from "./types";

const usePaginationUpdate = <T>() => {
  const dispatch = useDispatch();

  const addItems = useCallback(
    ({
      paginationId,
      itemsToAdd,
      position,
      skipIfNotInitialized,
    }: {
      paginationId: string;
      itemsToAdd: PaginationItem<T>[];
      position?: "start" | "end";
      skipIfNotInitialized?: boolean;
    }) => {
      dispatch(
        addItemsAction({
          paginationId,
          items: itemsToAdd,
          position,
          skipIfNotInitialized,
        })
      );
    },
    [dispatch]
  );

  const updateItem = useCallback(
    ({
      paginationId,
      item,
      merge,
    }: {
      paginationId: string;
      item: PaginationItem<T>;
      merge?: boolean;
    }) => {
      dispatch(updateItemAction({ paginationId, item, merge }));
    },
    [dispatch]
  );

  const addOrUpdateItem = useCallback(
    (params: Parameters<typeof addOrUpdateItemAction>[0]) =>
      dispatch(addOrUpdateItemAction(params)),
    [dispatch]
  );

  const deleteItem = useCallback(
    ({ paginationId, itemId }: { paginationId: string; itemId: string }) => {
      dispatch(deleteItemAction({ paginationId, itemId }));
    },
    [dispatch]
  );

  const bumpItem = useCallback(
    ({ paginationId, itemId }: { paginationId: string; itemId: string }) => {
      dispatch(bumpItemItemAction({ paginationId, itemId }));
    },
    [dispatch]
  );

  const changeItemId = useCallback(
    ({
      paginationId,
      oldId,
      newId,
    }: {
      paginationId: string;
      oldId: string;
      newId: string;
    }) => {
      dispatch(changeItemIdAction({ paginationId, oldId, newId }));
    },
    [dispatch]
  );

  const resetAll = useCallback(() => {
    dispatch(resetAllAction());
  }, [dispatch]);

  return {
    addItems,
    bumpItem,
    deleteItem,
    updateItem,
    changeItemId,
    addOrUpdateItem,
    resetAll,
  };
};

export default usePaginationUpdate;
