import { useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";
import { AppModule } from "../../common/types";

export const useUserRolePermissionWarning = () => {
  const { t } = useTranslations();

  return useCallback(
    (module?: AppModule) => {
      switch (module) {
        case AppModule.tasks:
          return t({
            id: "feedback.role-doesnt-have-permissions-to-tasks",
            defaultMessage:
              "Due to Role Permissions, this user doesn’t have access to the Tasks 🙅‍♀️",
          });
        case AppModule.calls:
          return t({
            id: "feedback.role-doesnt-have-permissions-to-calls",
            defaultMessage:
              "Due to Role Permissions, this user doesn’t have access to the Calls 🙅‍♀️",
          });
        case AppModule.drive:
          return t({
            id: "feedback.role-doesnt-have-permissions-to-drive",
            defaultMessage:
              "Due to Role Permissions, this user doesn’t have access to the Drive 🙅‍♀️",
          });
        case AppModule.chat:
          return t({
            id: "feedback.role-doesnt-have-permissions-to-chat",
            defaultMessage:
              "Due to Role Permissions, this user doesn’t have access to the Chat 🙅‍♀️",
          });
        default:
          return t({
            id: "feedback.role-doesnt-have-permissions-to-section",
            defaultMessage:
              "Due to Role Permissions, this user doesn’t have access to this section 🙅‍♀️",
          });
      }
    },
    [t]
  );
};
