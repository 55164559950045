import { INITIAL_FILTERS_STATE } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { WidgetModel } from "@jugl-web/rest-api/dashboard/models/Widget";
import { adaptTaskFiltersToInternalTaskFilters } from "@jugl-web/rest-api/tasks";
import {
  InteractiveContainer,
  Menu,
  PlainButton,
  Tooltip,
} from "@jugl-web/ui-components";
import { assignRefs, cx, useTranslations } from "@jugl-web/utils";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import isEqual from "lodash/isEqual";
import { FC, useMemo } from "react";
import Highlighter from "react-highlight-words";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { useDashboardWidgetChartConfig } from "../../hooks/useDashboardWidgetChartConfig";
import { OpenWidgetDialogFn } from "../../types";
import { DashboardWidgetDeleteConfirmationDialog } from "../DashboardWidgetDeleteConfirmationDialog";
import { ReactComponent as DragIcon } from "./assets/drag.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as FullScreenIcon } from "./assets/full-screen.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import { ReactComponent as TrashIcon } from "./assets/trash.svg";
import styles from "./DashboardWidget.module.css";

interface DashboardWidgetTopBarProps {
  widget: WidgetModel;
  isLayoutFrozen: boolean;
  canManageWidget: boolean;
  onOpenWidgetDialog: OpenWidgetDialogFn;
}

export const DashboardWidgetTopBar: FC<DashboardWidgetTopBarProps> = ({
  widget,
  isLayoutFrozen,
  canManageWidget,
  onOpenWidgetDialog,
}) => {
  const { searchQuery } = useDashboardContext();

  const deleteConfirmationDialogState = useConfirmationDialogState<{
    widget: WidgetModel;
  }>();

  const { getChartXAxisItemById, getChartYAxisItemById } =
    useDashboardWidgetChartConfig();

  const { t } = useTranslations();

  const adaptedWidgetFilters = useMemo(
    () => adaptTaskFiltersToInternalTaskFilters(widget.filters),
    [widget.filters]
  );

  const hasActiveFilter = useMemo(
    () => !isEqual(adaptedWidgetFilters, INITIAL_FILTERS_STATE),
    [adaptedWidgetFilters]
  );

  const xAxisName = getChartXAxisItemById(widget.config.x_axis)?.name;
  const yAxisName = getChartYAxisItemById(widget.config.series[0].y_axis)?.name;

  return (
    <>
      <div className="border-grey-300 flex shrink-0 items-center overflow-hidden rounded-t-2xl border-b border-l-0 border-r-0 border-t-0 border-solid bg-white px-6 py-4">
        <div className="flex grow items-center gap-3 overflow-hidden">
          {!isLayoutFrozen && (
            <PlainButton className="widget-drag-handle cursor-move">
              <DragIcon />
            </PlainButton>
          )}
          <InteractiveContainer
            className="flex w-full flex-col gap-0.5 overflow-hidden"
            onClick={() => onOpenWidgetDialog()}
          >
            <span className="text-dark-800 font-secondary truncate text-base font-semibold leading-[22px] tracking-[0.16px]">
              {searchQuery ? (
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary"
                  highlightTag="span"
                  textToHighlight={widget.title}
                  searchWords={[searchQuery]}
                />
              ) : (
                widget.title
              )}
            </span>
            <span
              className={cx(
                styles.dashboardWidgetTopBarSubtitle,
                "font-secondary truncate text-sm leading-3 tracking-[0.14px] text-[#828282]"
              )}
            >
              {xAxisName} / {yAxisName}
            </span>
          </InteractiveContainer>
        </div>
        <div className="flex items-center gap-2">
          {(canManageWidget || hasActiveFilter) && (
            <Tooltip
              placement="bottom"
              renderTrigger={({ ref, props }) => (
                <PlainButton
                  ref={ref}
                  className="hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-md transition"
                  onClick={() => onOpenWidgetDialog("open-filters")}
                  {...props}
                >
                  <span className="relative">
                    <FilterIcon />
                    {hasActiveFilter && (
                      <div
                        className={cx(
                          "bg-gradients-success absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-[1.5px] border-solid border-white"
                        )}
                      />
                    )}
                  </span>
                </PlainButton>
              )}
            >
              {t({ id: "dashboard-page.filters", defaultMessage: "Filters" })}
            </Tooltip>
          )}
          {canManageWidget && (
            <>
              <Tooltip
                placement="bottom"
                renderTrigger={({ ref, props }) => (
                  <PlainButton
                    ref={ref}
                    className="hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-md transition"
                    onClick={() => onOpenWidgetDialog("open-settings")}
                    {...props}
                  >
                    <SettingsIcon />
                  </PlainButton>
                )}
              >
                {t({
                  id: "dashboard-page.settings",
                  defaultMessage: "Settings",
                })}
              </Tooltip>
              <Menu
                placement="bottom-start"
                sections={[
                  [
                    {
                      id: "full-screen",
                      label: t({
                        id: "dashboard-page.full-screen",
                        defaultMessage: "Full screen",
                      }),
                      icon: <FullScreenIcon />,
                      className: "font-secondary",
                      onSelect: (_, close) => {
                        onOpenWidgetDialog();
                        close();
                      },
                    },
                    {
                      id: "delete",
                      label: t({
                        id: "dashboard-page.delete-chart",
                        defaultMessage: "Delete chart",
                      }),
                      icon: <TrashIcon />,
                      className: "font-secondary",
                      onSelect: (_, close) => {
                        deleteConfirmationDialogState.open({
                          metadata: { widget },
                        });
                        close();
                      },
                    },
                  ],
                ]}
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Tooltip
                    placement="bottom"
                    renderTrigger={({ ref, props }) => (
                      <Trigger
                        ref={assignRefs([triggerRef, ref])}
                        as={PlainButton}
                        className={cx(
                          "hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-md transition",
                          isOpen && "bg-grey-200"
                        )}
                        {...props}
                      >
                        <MoreIcon />
                      </Trigger>
                    )}
                  >
                    {t({ id: "common.more", defaultMessage: "More" })}
                  </Tooltip>
                )}
              />
            </>
          )}
        </div>
      </div>
      <DashboardWidgetDeleteConfirmationDialog
        isOpen={deleteConfirmationDialogState.isOpen}
        widget={deleteConfirmationDialogState.metadata?.widget || null}
        onClose={deleteConfirmationDialogState.close}
      />
    </>
  );
};
