import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import {
  SupportedXAxis,
  SupportedYAxis,
  WidgetChartSorting,
  WidgetChartType,
  WidgetModel,
} from "@jugl-web/rest-api/dashboard/models/Widget";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, SVGProps, useCallback, useMemo } from "react";
import { DEFAULT_CHART_COLOR_PALETTE } from "../../consts";
import { ReactComponent as AreaChartTypeIcon } from "./assets/area-chart-type.svg";
import { ReactComponent as AssigneeAxisItemIcon } from "./assets/assignee-axis-item.svg";
import { ReactComponent as BarChartTypeIcon } from "./assets/bar-chart-type.svg";
import { ReactComponent as DueDateAxisItemIcon } from "./assets/due-date-axis-item.svg";
import { ReactComponent as BoardAxisItemIcon } from "./assets/board-axis-item.svg";
import { ReactComponent as ClientAxisItemIcon } from "./assets/client-axis-item.svg";
import { ReactComponent as ColumnChartTypeIcon } from "./assets/column-chart-type.svg";
import { ReactComponent as CountAxisItemIcon } from "./assets/count-axis-item.svg";
import { ReactComponent as CustomFieldAxisItemIcon } from "./assets/custom-field-axis-item.svg";
import { ReactComponent as DonutChartTypeIcon } from "./assets/donut-chart-type.svg";
import { ReactComponent as FunnelChartTypeIcon } from "./assets/funnel-chart-type.svg";
import { ReactComponent as LabelAxisItemIcon } from "./assets/label-axis-item.svg";
import { ReactComponent as LineChartTypeIcon } from "./assets/line-chart-type.svg";
import { ReactComponent as NumAscSortingItemIcon } from "./assets/num-asc-sorting-item.svg";
import { ReactComponent as NumDescSortingItemIcon } from "./assets/num-desc-sorting-item.svg";
import { ReactComponent as PieChartTypeIcon } from "./assets/pie-chart-type.svg";
import { ReactComponent as PriorityAxisItemIcon } from "./assets/priority-axis-item.svg";
import { ReactComponent as PyramidChartTypeIcon } from "./assets/pyramid-chart-type.svg";
import { ReactComponent as RadarChartTypeIcon } from "./assets/radar-chart-type.svg";
import { ReactComponent as StatusAxisItemIcon } from "./assets/status-axis-item.svg";
import { ReactComponent as SumAxisItemIcon } from "./assets/sum-axis-item.svg";
import { ReactComponent as TextAscSortingItemIcon } from "./assets/text-asc-sorting-item.svg";
import { ReactComponent as TextDescSortingItemIcon } from "./assets/text-desc-sorting-item.svg";
import { ReactComponent as TreeMapChartTypeIcon } from "./assets/tree-map-chart-type.svg";
import { ReactComponent as WaterfallChartTypeIcon } from "./assets/waterfall-chart-type.svg";

interface DashboardChartTypeItem {
  type: WidgetChartType;
  name: string;
  SvgIcon: FC<SVGProps<SVGSVGElement>>;
}

interface DashboardChartSettingsItem<TId extends string> {
  id: TId;
  name: string;
  SvgIcon: FC<SVGProps<SVGSVGElement>>;
}

type DashboardChartXAxisItem = DashboardChartSettingsItem<SupportedXAxis>;
type DashboardChartYAxisItem = DashboardChartSettingsItem<SupportedYAxis>;
type DashboardChartSortingItem = DashboardChartSettingsItem<WidgetChartSorting>;

export const useDashboardWidgetChartConfig = () => {
  const { entityId } = useEntitySelectedProvider();

  const { customFields } = useTaskFields({ entityId });

  const { t } = useTranslations();

  // #region Chart type items
  const lineChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "line",
      name: t({
        id: "dashboard-page.line-chart",
        defaultMessage: "Line Chart",
      }),
      SvgIcon: LineChartTypeIcon,
    }),
    [t]
  );

  const areaChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "area",
      name: t({
        id: "dashboard-page.area-chart",
        defaultMessage: "Area Chart",
      }),
      SvgIcon: AreaChartTypeIcon,
    }),
    [t]
  );

  const columnChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "column",
      name: t({
        id: "dashboard-page.column-chart",
        defaultMessage: "Column Chart",
      }),
      SvgIcon: ColumnChartTypeIcon,
    }),
    [t]
  );

  const barChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "bar",
      name: t({
        id: "dashboard-page.bar-chart",
        defaultMessage: "Bar Chart",
      }),
      SvgIcon: BarChartTypeIcon,
    }),
    [t]
  );

  const pieChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "pie",
      name: t({
        id: "dashboard-page.pie-chart",
        defaultMessage: "Pie Chart",
      }),
      SvgIcon: PieChartTypeIcon,
    }),
    [t]
  );

  const donutChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "donut",
      name: t({
        id: "dashboard-page.donut-chart",
        defaultMessage: "Donut Chart",
      }),
      SvgIcon: DonutChartTypeIcon,
    }),
    [t]
  );

  const treeMapChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "treemap",
      name: t({
        id: "dashboard-page.treemap-chart",
        defaultMessage: "Tree Map",
      }),
      SvgIcon: TreeMapChartTypeIcon,
    }),
    [t]
  );

  const radarChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "radar",
      name: t({
        id: "dashboard-page.radar-chart",
        defaultMessage: "Radar Chart",
      }),
      SvgIcon: RadarChartTypeIcon,
    }),
    [t]
  );

  const waterfallChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "waterfall",
      name: t({
        id: "dashboard-page.waterfall-chart",
        defaultMessage: "Waterfall Chart",
      }),
      SvgIcon: WaterfallChartTypeIcon,
    }),
    [t]
  );

  const pyramidChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "pyramid",
      name: t({
        id: "dashboard-page.pyramid-chart",
        defaultMessage: "Pyramid Chart",
      }),
      SvgIcon: PyramidChartTypeIcon,
    }),
    [t]
  );

  const funnelChartItem = useMemo<DashboardChartTypeItem>(
    () => ({
      type: "funnel",
      name: t({
        id: "dashboard-page.funnel-chart",
        defaultMessage: "Funnel Chart",
      }),
      SvgIcon: FunnelChartTypeIcon,
    }),
    [t]
  );

  const chartItems = useMemo<DashboardChartTypeItem[]>(
    () => [
      lineChartItem,
      areaChartItem,
      columnChartItem,
      barChartItem,
      pieChartItem,
      donutChartItem,
      pyramidChartItem,
      funnelChartItem,
      waterfallChartItem,
      radarChartItem,
      treeMapChartItem,
    ],
    [
      areaChartItem,
      barChartItem,
      columnChartItem,
      donutChartItem,
      funnelChartItem,
      lineChartItem,
      pieChartItem,
      pyramidChartItem,
      radarChartItem,
      treeMapChartItem,
      waterfallChartItem,
    ]
  );
  // #endregion

  // #region Chart X-axis items
  const chartXAxisItemsMap = useMemo(
    () =>
      new Map<SupportedXAxis, DashboardChartXAxisItem>([
        [
          "due_date",
          {
            id: "due_date",
            name: t({
              id: "dashboard-page.due-date-x-axis-item",
              defaultMessage: "Due date",
            }),
            SvgIcon: DueDateAxisItemIcon,
          },
        ],
        [
          "board",
          {
            id: "board",
            name: t({
              id: "dashboard-page.board-x-axis-item",
              defaultMessage: "Board",
            }),
            SvgIcon: BoardAxisItemIcon,
          },
        ],
        [
          "status",
          {
            id: "status",
            name: t({
              id: "dashboard-page.status-x-axis-item",
              defaultMessage: "Status",
            }),
            SvgIcon: StatusAxisItemIcon,
          },
        ],
        [
          "label",
          {
            id: "label",
            name: t({
              id: "dashboard-page.label-x-axis-item",
              defaultMessage: "Label",
            }),
            SvgIcon: LabelAxisItemIcon,
          },
        ],
        [
          "priority",
          {
            id: "priority",
            name: t({
              id: "dashboard-page.priority-x-axis-item",
              defaultMessage: "Priority",
            }),
            SvgIcon: PriorityAxisItemIcon,
          },
        ],
        [
          "assignee",
          {
            id: "assignee",
            name: t({
              id: "dashboard-page.assignee-x-axis-item",
              defaultMessage: "Assignee",
            }),
            SvgIcon: AssigneeAxisItemIcon,
          },
        ],
        [
          "customer",
          {
            id: "customer",
            name: t({
              id: "dashboard-page.customer-x-axis-item",
              defaultMessage: "Customer",
            }),
            SvgIcon: ClientAxisItemIcon,
          },
        ],
        ...customFields
          .filter((customField) => customField.type === "dropdown")
          .map<[SupportedXAxis, DashboardChartXAxisItem]>((field) => [
            field.id,
            {
              id: field.id,
              name: field.name,
              SvgIcon: CustomFieldAxisItemIcon,
            },
          ]),
      ]),
    [t, customFields]
  );

  const chartXAxisItems = useMemo<DashboardChartXAxisItem[]>(
    () => Array.from(chartXAxisItemsMap.values()),
    [chartXAxisItemsMap]
  );

  const getChartXAxisItemById = useCallback(
    (id: SupportedXAxis) => chartXAxisItemsMap.get(id),
    [chartXAxisItemsMap]
  );
  // #endregion

  // #region Chart Y-axis items
  const chartYAxisItemsMap = useMemo(
    () =>
      new Map<SupportedYAxis, DashboardChartYAxisItem>([
        [
          "count",
          {
            id: "count",
            name: t({
              id: "dashboard-page.tasks-count-y-axis-item",
              defaultMessage: "Tasks count",
            }),
            SvgIcon: CountAxisItemIcon,
          },
        ],
        [
          "sum_duration",
          {
            id: "sum_duration",
            name: t({
              id: "dashboard-page.sum-of-total-hours-spent-y-axis-item",
              defaultMessage: "Sum of total hours spent",
            }),
            SvgIcon: CountAxisItemIcon,
          },
        ],
        ...customFields
          .filter((customField) => customField.type === "number")
          .map<[SupportedYAxis, DashboardChartYAxisItem]>((field) => [
            `sum_${field.id}`,
            {
              id: `sum_${field.id}`,
              name: t(
                {
                  id: "dashboard-page.sum-of-field-y-axis-item",
                  defaultMessage: "Sum of {fieldName}",
                },
                { fieldName: field.name }
              ),
              SvgIcon: SumAxisItemIcon,
            },
          ]),
      ]),
    [t, customFields]
  );

  const chartYAxisItems = useMemo<DashboardChartYAxisItem[]>(
    () => Array.from(chartYAxisItemsMap.values()),
    [chartYAxisItemsMap]
  );

  const getChartYAxisItemById = useCallback(
    (id: SupportedYAxis) => chartYAxisItemsMap.get(id),
    [chartYAxisItemsMap]
  );
  // #endregion

  // #region Chart sorting items
  const chartSortingItemsMap = useMemo(
    () =>
      new Map<WidgetChartSorting, DashboardChartSortingItem>([
        [
          "x-axis-asc",
          {
            id: "x-axis-asc",
            name: t({
              id: "dashboard-page.x-axis-ascending-sorting-item",
              defaultMessage: "X-axis ascending",
            }),
            SvgIcon: TextAscSortingItemIcon,
          },
        ],
        [
          "x-axis-desc",
          {
            id: "x-axis-desc",
            name: t({
              id: "dashboard-page.x-axis-descending-sorting-item",
              defaultMessage: "X-axis descending",
            }),
            SvgIcon: TextDescSortingItemIcon,
          },
        ],
        [
          "y-axis-asc",
          {
            id: "y-axis-asc",
            name: t({
              id: "dashboard-page.y-axis-ascending-sorting-item",
              defaultMessage: "Y-axis ascending",
            }),
            SvgIcon: NumAscSortingItemIcon,
          },
        ],
        [
          "y-axis-desc",
          {
            id: "y-axis-desc",
            name: t({
              id: "dashboard-page.y-axis-descending-sorting-item",
              defaultMessage: "Y-axis descending",
            }),
            SvgIcon: NumDescSortingItemIcon,
          },
        ],
      ]),
    [t]
  );

  const chartSortingItems = useMemo<DashboardChartSortingItem[]>(
    () => Array.from(chartSortingItemsMap.values()),
    [chartSortingItemsMap]
  );

  const getChartSortingItemById = useCallback(
    (id: WidgetChartSorting) => chartSortingItemsMap.get(id),
    [chartSortingItemsMap]
  );
  // #endregion

  // #region Chart color palette
  const getChartColorPalette = useCallback(
    (widgetConfig: WidgetModel["config"]) =>
      DEFAULT_CHART_COLOR_PALETTE.map((defaultColor, defaultColorIndex) => {
        const color =
          widgetConfig.meta.color_palette?.[defaultColorIndex] || defaultColor;

        return color;
      }),
    []
  );

  return {
    chartItems,
    chartXAxisItems,
    chartYAxisItems,
    chartSortingItems,
    getChartXAxisItemById,
    getChartYAxisItemById,
    getChartSortingItemById,
    getChartColorPalette,
  };
};
