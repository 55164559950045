import {
  Button,
  Checkbox,
  TextInput,
} from "@jugl-web/ui-components/cross-platform";
import {
  Select,
  SelectItem,
} from "@jugl-web/ui-components/cross-platform/forms/Select";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, ReactNode, forwardRef, useMemo } from "react";
import {
  SupportedCustomFieldType,
  useCustomFieldTypes,
} from "../../hooks/useCustomFieldTypes";

interface CustomFieldFromProps {
  children: ReactNode;
}

export const CustomFieldForm = ({ children }: CustomFieldFromProps) => (
  <div className="flex w-full max-w-[500px] flex-col px-8">{children}</div>
);

interface HeadingProps {
  title: ReactNode;
  subtitle?: ReactNode;
  className?: string;
}

CustomFieldForm.Heading = (({ title, subtitle, className }) => (
  <div className={cx("flex flex-col items-center gap-2", className)}>
    <h3 className="text-dark font-secondary m-0 text-center text-lg font-medium">
      {title}
    </h3>
    {subtitle && (
      <h2 className="text-dark-600 font-secondary m-0 text-center text-sm font-normal leading-[21px]">
        {subtitle}
      </h2>
    )}
  </div>
)) as FC<HeadingProps>;

interface FieldInputProps {
  label: string;
  value: string;
  placeholder?: string;
  errorMessage?: string;
  className?: string;
  onChange: (name: string) => void;
}

CustomFieldForm.FieldInput = forwardRef<HTMLInputElement, FieldInputProps>(
  ({ label, value, placeholder, className, errorMessage, onChange }, ref) => (
    <TextInput
      ref={ref}
      isRequired
      label={label}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      classNames={{ wrapperClassName: className }}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
    />
  )
);

interface FieldTypeSelectProps<TFieldType extends SupportedCustomFieldType> {
  fieldTypes: TFieldType[];
  selectedType: TFieldType;
  className?: string;
  onSelect?: (type: TFieldType) => void;
}

const FieldTypeSelect = <TFieldType extends SupportedCustomFieldType>({
  fieldTypes,
  selectedType,
  className,
  onSelect,
}: FieldTypeSelectProps<TFieldType>) => {
  const { t } = useTranslations();
  const { customFieldTypeToDetails } = useCustomFieldTypes();

  const items = useMemo(
    () =>
      fieldTypes.map<SelectItem>((type) => ({
        name: customFieldTypeToDetails[type].shortLabel,
        value: type,
      })),
    [customFieldTypeToDetails, fieldTypes]
  );

  return (
    <Select
      isRequired
      label={t({
        id: "tasks-page.custom-field-type",
        defaultMessage: "Field type",
      })}
      value={selectedType}
      items={items}
      onSelect={(value) => onSelect?.(value as TFieldType)}
      isDisabled={!onSelect}
      classNames={{ wrapperClassName: className }}
    />
  );
};

CustomFieldForm.FieldTypeSelect = FieldTypeSelect;

interface IsShownInCardSwitchProps {
  isChecked: boolean;
  className?: string;
  onChange: (value: boolean) => void;
}

CustomFieldForm.IsShownInCardCheckbox = (({
  isChecked,
  className,
  onChange,
}) => {
  const { t } = useTranslations();

  return (
    <div className={className}>
      <Checkbox
        size="md"
        label={t({
          id: "tasks-page.show-field-in-task-card",
          defaultMessage: "Show field in a task card",
        })}
        labelClassName="text-dark-700 font-secondary text-xs leading-[140%] ml-0.5"
        isChecked={isChecked}
        onChange={(event) => onChange(event.target.checked)}
      />
    </div>
  );
}) as FC<IsShownInCardSwitchProps>;

interface ActionsProps {
  submitButton: {
    label: string;
    isDisabled: boolean;
    onClick: () => void;
  };
  onCancel: () => void;
}

CustomFieldForm.Actions = (({ submitButton, onCancel }) => {
  const { t } = useTranslations();

  return (
    <div className="flex items-center justify-center gap-3.5">
      <Button
        variant="contained"
        color="grey"
        className="font-secondary h-10 w-[143px] font-medium"
        onClick={onCancel}
      >
        {t({
          id: "common.cancel",
          defaultMessage: "Cancel",
        })}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="font-secondary h-10 w-[143px] font-medium"
        isDisabled={submitButton.isDisabled}
        onClick={submitButton.onClick}
      >
        {submitButton.label}
      </Button>
    </div>
  );
}) as FC<ActionsProps>;
