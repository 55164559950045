import { WidgetChartType } from "@jugl-web/rest-api/dashboard/models/Widget";
import { InteractiveContainer, Tooltip } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { useDashboardWidgetChartConfig } from "../../hooks/useDashboardWidgetChartConfig";

interface DashboardChartTypeListProps {
  selectedChartType?: WidgetChartType;
  className?: string;
  onSelect: (type: WidgetChartType) => void;
}

export const DashboardChartTypeList: FC<DashboardChartTypeListProps> = ({
  selectedChartType,
  className,
  onSelect,
}) => {
  const { chartItems } = useDashboardWidgetChartConfig();

  return (
    <div className={cx("flex flex-wrap items-center gap-4", className)}>
      {chartItems.map((item) => {
        const isSelected = item.type === selectedChartType;

        return (
          <Tooltip
            key={item.type}
            placement="bottom"
            delay="none"
            renderTrigger={({ props, ref }) => (
              <InteractiveContainer
                ref={ref}
                isDisabled={isSelected}
                className={cx(
                  "group flex h-14 w-14 items-center justify-center rounded-lg border border-solid border-[#E0E0E0] transition",
                  isSelected ? "border-primary" : "hover:border-primary"
                )}
                onClick={() => onSelect(item.type)}
                {...props}
              >
                <item.SvgIcon
                  className={cx(
                    "transition",
                    isSelected ? "scale-110" : "group-hover:scale-110"
                  )}
                />
              </InteractiveContainer>
            )}
          >
            {item.name}
          </Tooltip>
        );
      })}
    </div>
  );
};
