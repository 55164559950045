import { ModuleNotificationsDialog } from "@jugl-web/domain-resources/module-notifications/components/ModuleNotificationsDialog";
import { DRAWER_TRANSITION_DURATION_MS } from "@jugl-web/ui-components/cross-platform/Drawer";
import { PeopleInfoSidebar } from "@web-src/modules/people/components/PeopleInfoSidebar";
import { FC, useState } from "react";

interface PeopleNotificationsDialogProps {
  isOpen: boolean;
  entityId: string;
  onClose: () => void;
}

const GAP_BETWEEN_DRAWER_OPENINGS_MS = DRAWER_TRANSITION_DURATION_MS + 50;

export const PeopleNotificationsDialog: FC<PeopleNotificationsDialogProps> = ({
  isOpen,
  entityId,
  onClose,
}) => {
  const [bufferedUserId, setBufferedUserId] = useState<string>();
  const [isUserInfoSidebarOpen, setIsUserInfoSidebarOpen] = useState(false);

  return (
    <>
      <ModuleNotificationsDialog
        isOpen={isOpen}
        entityId={entityId}
        module="people"
        onNotificationClick={(log) => {
          onClose();

          if (log.people.user_id) {
            setBufferedUserId(log.people.user_id as string);

            window.setTimeout(() => {
              setIsUserInfoSidebarOpen(true);
            }, GAP_BETWEEN_DRAWER_OPENINGS_MS);
          }
        }}
        onClose={onClose}
      />
      <PeopleInfoSidebar
        isOpen={isUserInfoSidebarOpen}
        userId={bufferedUserId}
        onRequestClose={() => setIsUserInfoSidebarOpen(false)}
      />
    </>
  );
};
