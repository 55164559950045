import { TaskArchiveConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskArchiveConfirmationDialog";
import { TaskCompleteConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskCompleteConfirmationDialog";
import { TaskDeleteConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskDeleteConfirmationDialog";
import { useDuplicateTask } from "@jugl-web/domain-resources/tasks/hooks/useDuplicateTask";
import { TaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useTaskPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskPreferences";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  DetailedTask,
  PreviewTask,
  TaskChecklistItem,
  TaskDefaultStatusId,
} from "@jugl-web/rest-api/tasks";
import { Menu, MenuProps } from "@jugl-web/ui-components";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { FC, useState } from "react";
import { TASK_TABLE_ROW_CUSTOMER_CELL_ID } from "../CustomerCell";
import { ReactComponent as ArchiveIcon } from "./assets/archive.svg";
import { ReactComponent as CompleteInOrderIcon } from "./assets/complete-in-order.svg";
import { ReactComponent as CompleteIcon } from "./assets/complete.svg";
import { ReactComponent as CopyLinkIcon } from "./assets/copy-link.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as DuplicateIcon } from "./assets/duplicate.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as HideCompletedIcon } from "./assets/hide-completed.svg";
import { ReactComponent as SendUpdateIcon } from "./assets/send-update.svg";

interface TaskNameCellContextMenuProps
  extends Pick<MenuProps, "renderTrigger"> {
  entityId: string;
  permissions: TaskPermissions;
  task: PreviewTask;
  onUpdateTask: (attributes: Partial<DetailedTask>) => void;
  onDeleteTask: () => void;
  onEditTaskName: () => void;
}

const sortItemsByCompleted = (items: TaskChecklistItem[]) =>
  [...items]
    .sort(
      (itemA, itemB) => Number(itemB.is_completed) - Number(itemA.is_completed)
    )
    .map((item, index) => ({ ...item, order: index + 1 }));

export const TaskNameCellContextMenu: FC<TaskNameCellContextMenuProps> = ({
  entityId,
  permissions,
  task,
  onUpdateTask,
  onDeleteTask,
  onEditTaskName,
  renderTrigger,
}) => {
  const [
    isCompleteTaskConfirmationDialogOpen,
    setIsCompleteTaskConfirmationDialogOpen,
  ] = useState(false);

  const [
    isArchiveTaskConfirmationDialogOpen,
    setIsArchiveTaskConfirmationDialogOpen,
  ] = useState(false);

  const [
    isDeleteTaskConfirmationDialogOpen,
    setIsDeleteTaskConfirmationDialogOpen,
  ] = useState(false);

  const {
    tasksSource,
    previewTaskId,
    navigateToTaskDetailsPage,
    setPreviewTaskId,
  } = useTasksPageContext();

  const { preferences, updatePreference } = useTaskPreferences({
    taskId: task.id,
  });

  const { tasksApi } = useRestApiProvider();

  const [sendUpdateToCustomer] = tasksApi.useSendUpdateToCustomerMutation();
  const [getTask] = tasksApi.useLazyGetTaskQuery();

  const { duplicateTask, showTaskDuplicatedToast } = useDuplicateTask({
    entityId,
    onNavigate: navigateToTaskDetailsPage,
  });

  const { t } = useTranslations();
  const { toast } = useToast();

  const handleTaskDelete = () => {
    onDeleteTask();

    if (previewTaskId) {
      setPreviewTaskId("");
    }
  };

  const handleToggleCompleteChecklistInOrder = async () => {
    if (task.chklist_total > 0 && !task.has_chklist_chk) {
      // extra step is needed to make sure that the checklist is sorted by completed
      // before the "complete in order" option is set to true
      const response = await getTask({ entityId, taskId: task.id }, true);

      if (response.data) {
        onUpdateTask({
          checklist: sortItemsByCompleted(response.data.checklist),
          has_chklist_chk: true,
        });
      }

      return;
    }

    onUpdateTask({ has_chklist_chk: !task.has_chklist_chk });
  };

  const handleSendUpdateToCustomer = async () => {
    if (task.cust_id) {
      const response = await sendUpdateToCustomer({
        entityId,
        taskId: task.id,
      });

      if ("data" in response) {
        toast(
          t({
            id: "tasks-page.update-sent-to-customer",
            defaultMessage: "Update was shared with Customer",
          })
        );
      }

      return;
    }

    const customerCellElement = document.querySelector<HTMLButtonElement>(
      `[data-id='${TASK_TABLE_ROW_CUSTOMER_CELL_ID(task.id)}']`
    );

    if (customerCellElement) {
      customerCellElement.scrollIntoView({
        inline: "nearest",
        block: "nearest",
        behavior: "instant",
      });
      customerCellElement.click();
    }

    toast(
      t({
        id: "tasks-page.assign-customer-to-send-update",
        defaultMessage: "To send update, assign Customer to the Task first",
      }),
      { variant: "error" }
    );
  };

  const handleDuplicateTask = async () => {
    try {
      const duplicatedTask = await duplicateTask(task.id, tasksSource);
      showTaskDuplicatedToast(duplicatedTask.id);
    } catch {
      toast(
        t({
          id: "tasks-page.failed-duplicate-task",
          defaultMessage: "Failed to duplicate the task",
        }),
        { variant: "error" }
      );
    }
  };

  const handleCopyTaskLink = async () => {
    await navigator.clipboard?.writeText(task.short_url);

    toast(
      t({
        id: "tasks-page.task-link-copied",
        defaultMessage: "Task Link is copied to clipboard",
      }),
      { variant: "success" }
    );
  };

  return (
    <>
      <Menu
        placement="bottom-end"
        sections={[
          [
            {
              id: "edit-task-name",
              label: t({
                id: "tasks.edit-task-name",
                defaultMessage: "Edit task name",
              }),
              icon: <EditIcon />,
              isHidden: !permissions.canEditTitle,
              onSelect: (_, close) => {
                onEditTaskName();
                close();
              },
            },
            {
              id: "complete-checklist-items-in-order",
              label: t({
                id: "tasks-page.complete-subtasks-in-order",
                defaultMessage: "Complete subtasks in order",
              }),
              icon: <CompleteInOrderIcon />,
              isHidden: !permissions.canManageCompleteInSpecificOrderOption,
              toggle: "checkbox",
              isSelected: task.has_chklist_chk,
              onSelect: handleToggleCompleteChecklistInOrder,
            },
            {
              id: "hide-completed-checklists",
              label: t({
                id: "tasks-page.hide-completed-subtasks",
                defaultMessage: "Hide completed subtasks",
              }),
              icon: <HideCompletedIcon />,
              toggle: "checkbox",
              isSelected: preferences.areCompletedChecklistItemsHidden,
              onSelect: () => {
                updatePreference("areCompletedChecklistItemsHidden", (s) => !s);
              },
            },
            {
              id: "send-update-to-customer",
              label: t({
                id: "tasks-page.send-update-to-the-customer",
                defaultMessage: "Send update to the customer",
              }),
              icon: <SendUpdateIcon />,
              isHidden: !permissions.canSendUpdateToCustomer,
              onSelect: (_, close) => {
                handleSendUpdateToCustomer();
                close();
              },
            },
            {
              id: "copy-task-link",
              label: t({
                id: "tasks-page.copy-task-link",
                defaultMessage: "Copy task link",
              }),
              icon: <CopyLinkIcon />,
              onSelect: (_, close) => {
                handleCopyTaskLink();
                close();
              },
            },
            {
              id: "complete-task",
              label: t({
                id: "tasks-page.complete-task",
                defaultMessage: "Complete task",
              }),
              icon: <CompleteIcon />,
              isHidden:
                !permissions.canEditStatus ||
                task.status === TaskDefaultStatusId.completed,
              onSelect: (_, close) => {
                setIsCompleteTaskConfirmationDialogOpen(true);
                close();
              },
            },
            {
              id: "duplicate-task",
              label: t({
                id: "tasks-page.duplicate-task",
                defaultMessage: "Duplicate task",
              }),
              icon: <DuplicateIcon />,
              isHidden: !permissions.canDuplicateTask,
              onSelect: (_, close) => {
                handleDuplicateTask();
                close();
              },
            },
            {
              id: "archive-task",
              label: t({
                id: "tasks-page.archive-task",
                defaultMessage: "Archive task",
              }),
              icon: <ArchiveIcon />,
              isHidden: !permissions.canArchiveTask,
              onSelect: (_, close) => {
                setIsArchiveTaskConfirmationDialogOpen(true);
                close();
              },
            },
            {
              id: "delete-task",
              label: t({
                id: "tasks-page.delete-task",
                defaultMessage: "Delete task",
              }),
              icon: <DeleteIcon />,
              isHidden: !permissions.canDeleteTask,
              onSelect: (_, close) => {
                setIsDeleteTaskConfirmationDialogOpen(true);
                close();
              },
            },
          ],
        ]}
        renderTrigger={renderTrigger}
        className="jugl__custom-scrollbar max-h-[45vh]"
      />
      <TaskCompleteConfirmationDialog
        isOpen={isCompleteTaskConfirmationDialogOpen}
        entityId={entityId}
        taskId={task.id}
        areAllChecklistItemsCompleted={
          task.chklist_total === task.chklist_completed
        }
        onClose={() => setIsCompleteTaskConfirmationDialogOpen(false)}
        onAfterComplete={() =>
          toast(
            t({
              id: "tasks-page.task-completed",
              defaultMessage: "Task has been completed",
            })
          )
        }
      />
      <TaskDeleteConfirmationDialog
        isOpen={isDeleteTaskConfirmationDialogOpen}
        onDelete={handleTaskDelete}
        onClose={() => setIsDeleteTaskConfirmationDialogOpen(false)}
      />
      <TaskArchiveConfirmationDialog
        isOpen={isArchiveTaskConfirmationDialogOpen}
        entityId={entityId}
        task={task}
        source={tasksSource}
        onClose={() => null}
        setPreviewTaskId={setPreviewTaskId}
      />
    </>
  );
};
