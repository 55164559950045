import React from "react";

export const SectionHeader: React.FC<{
  icon: React.ReactNode;
  title: string;
  endContent?: React.ReactNode;
}> = ({ icon, title, endContent }) => (
  <div className="flex items-center justify-between gap-2">
    <div className="flex items-center gap-2">
      {icon}
      <span className="text-dark font-secondary text-xl font-medium">
        {title}
      </span>
    </div>
    {endContent}
  </div>
);
