import { cx } from "@jugl-web/utils";
import { forwardRef, MouseEvent, TouchEvent } from "react";
import { ReactComponent as ResizeIcon } from "./assets/resize.svg";

interface ResizeHandleComponentProps {
  handleAxis: string;
  onMouseDown: (event: MouseEvent) => void;
  onMouseUp: (event: MouseEvent) => void;
  onTouchEnd: (event: TouchEvent) => void;
}

interface DashboardWidgetHandleProps extends ResizeHandleComponentProps {
  isHidden: boolean;
}

export const DashboardWidgetHandle = forwardRef<
  HTMLDivElement,
  DashboardWidgetHandleProps
>(({ isHidden, handleAxis, ...props }, ref) => (
  <div
    ref={ref}
    className={cx(
      "absolute right-0 bottom-0 flex h-6 w-6 translate-x-1/3 translate-y-1/3 cursor-se-resize items-center justify-center rounded-full opacity-0 transition",
      isHidden ? "pointer-events-none" : "group-hover/widget:opacity-100"
    )}
    {...props}
  >
    <ResizeIcon />
  </div>
));
