import { autoUpdate, useFloating } from "@floating-ui/react-dom";
import { EntityModel } from "@jugl-web/rest-api";
import {
  InteractiveContainer,
  Menu,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useSelectEntity } from "@web-src/features/app/hooks/useSelectEntity";
import { FC, useRef, useState } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ListItemCard } from "../../pages/EntitySelectPage/components/ListItemCard";
import { ReactComponent as BellIcon } from "./assets/bell.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import { ReactComponent as StarsIcon } from "./assets/stars.svg";
import { ReactComponent as SelectedIcon } from "./assets/selected.svg";
import { EntityUpdatesTooltip } from "./components/EntityUpdatesTooltip";

export interface EntityCardProps {
  entity: EntityModel;
  variant?: "default" | "popover";
  isSelected?: boolean;
  onLeaveEntity: () => void;
  onDeleteEntity?: () => void;
  showToastOnSwitch?: boolean;
  onEntitySwitch?: () => void;
}

const TOOLTIP_APPEARANCE_DEBOUNCE_TIME_MS = 300;

export const EntityCard: FC<EntityCardProps> = ({
  entity,
  variant = "default",
  isSelected,
  onLeaveEntity,
  onDeleteEntity,
  showToastOnSwitch,
  onEntitySwitch,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { selectEntity } = useSelectEntity();
  const { generateUrl } = useNavigation();
  const tooltipTimeoutRef = useRef<number>();

  const { t } = useTranslations();

  const { refs, floatingStyles } = useFloating({
    placement: "right",
    transform: false,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = () => {
    if (isTooltipVisible) {
      window.clearTimeout(tooltipTimeoutRef.current);
    } else {
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setIsTooltipVisible(true);
      }, TOOLTIP_APPEARANCE_DEBOUNCE_TIME_MS);
    }
  };

  const handleMouseLeave = () => {
    if (isTooltipVisible) {
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setIsTooltipVisible(false);
      }, TOOLTIP_APPEARANCE_DEBOUNCE_TIME_MS);
    } else {
      window.clearTimeout(tooltipTimeoutRef.current);
    }
  };

  return (
    <>
      <div className="relative">
        <EntityUpdatesTooltip
          ref={refs.setFloating}
          isVisible={isTooltipVisible}
          entityId={entity.id}
          style={floatingStyles}
        />
        <ListItemCard
          avatarSrc={entity.logoImg}
          title={entity.name}
          subTitle={
            entity.subscriptionInfo.type === "expired" ? (
              <span className="text-red-500">Subscription expired</span>
            ) : undefined
          }
          className={
            (isSelected &&
              variant === "popover" &&
              "bg-primary-50 hover:bg-primary-50") ||
            ""
          }
          onClick={() => {
            if (!isSelected) {
              selectEntity(entity, showToastOnSwitch);
            }
            onEntitySwitch?.();
          }}
          rightContent={
            <div className="flex items-center gap-4">
              {entity.unread && (
                <InteractiveContainer
                  ref={refs.setReference}
                  className="hover:bg-dark-100 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent transition-colors"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={(event) => event.stopPropagation()}
                >
                  <BellIcon />
                </InteractiveContainer>
              )}
              {variant === "popover" && isSelected && <SelectedIcon />}
              <Menu
                placement="bottom-end"
                sections={[
                  [
                    {
                      isHidden:
                        entity.role !== "admin" ||
                        entity.subscriptionInfo.type !== "expired",
                      id: "open-update-subscription",
                      label: t({
                        id: "entities-page.update-subscription",
                        defaultMessage: "Update Subscription",
                      }),
                      icon: <StarsIcon />,
                      onSelect: (_, close) => {
                        selectEntity(
                          entity,
                          showToastOnSwitch,
                          generateUrl("workspaceNoSubscription", undefined, {
                            entityId: entity.id,
                          })
                        );
                        onEntitySwitch?.();
                        close();
                      },
                    },
                    {
                      isHidden:
                        entity.role !== "admin" ||
                        entity.subscriptionInfo.type === "expired",
                      id: "open-workspace-settings",
                      label: t({
                        id: "entities-page.open-workspace-settings",
                        defaultMessage: "Open Workspace Settings",
                      }),
                      icon: <StarsIcon />,
                      onSelect: (_, close) => {
                        selectEntity(
                          entity,
                          showToastOnSwitch,
                          generateUrl("workspaceSettings", undefined, {
                            entityId: entity.id,
                          })
                        );
                        onEntitySwitch?.();
                        close();
                      },
                    },
                    {
                      id: "leave-workspace",
                      label: t({
                        id: "entities-page.leave-workspace",
                        defaultMessage: "Leave workspace",
                      }),
                      icon: <LeaveIcon />,
                      onSelect: (_, close) => {
                        onLeaveEntity();
                        close();
                      },
                    },
                    {
                      isHidden: entity.role !== "admin" || !onDeleteEntity,
                      id: "delete-workspace",
                      label: t({
                        id: "entities-page.delete-workspace",
                        defaultMessage: "Delete Workspace",
                      }),
                      icon: <StarsIcon />,
                      onSelect: (_, close) => {
                        onDeleteEntity?.();
                        close();
                      },
                    },
                  ],
                ]}
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    ref={triggerRef}
                    as={InteractiveContainer}
                    className="hover:bg-dark-100 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent transition-colors"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuIcon />
                  </Trigger>
                )}
              />
            </div>
          }
        />
      </div>
    </>
  );
};
