import {
  OrderResponseInvoiceItem,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  LoadingSpinner,
  PlainButton,
  Alert,
  Tooltip,
} from "@jugl-web/ui-components";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import {
  ManageServiceDrawer,
  ManageServiceDrawerRefState,
} from "@jugl-web/domain-resources/orders/components/ManageServiceDrawer";
import { useState, useRef, useMemo } from "react";
import { useTranslations, useToast } from "@jugl-web/utils";
import Lottie from "react-lottie";
import { MAX_NUMBER_VALUE } from "@web-src/modules/inventory/pages/InventoryItemEditStockFormPage/consts";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { AddNewProductForm } from "@jugl-web/domain-resources/tasks/components/AddNewProductForm";
import { useDispatch } from "react-redux";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { OrderInvoiceHistoryItems } from "@jugl-web/domain-resources/tasks/components/OrderInvoiceHistoryItems";
import { DEFAULT_ENTITY_CURRENCY } from "@jugl-web/utils/consts";
import { OrderItemsTable } from "./components/OrderItemsTable";
import { OrderInfo } from "./components/OrderInfo";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";
import { TabContentButton } from "../TabContentButton";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import alertAnimation from "./assets/alert-animation.json";
import { undiscountedPriceInCents } from "./components/OrderItemsTable/utils";

export const TaskDetailsOrderTabContent = () => {
  const [isManageServiceDrawerOpen, setIsManageServiceDrawerOpen] =
    useState(false);
  const [isAddProductFormOpen, setIsAddProductFormOpen] = useState(false);
  const [orderLineToDelete, setOrderLineToDelete] =
    useState<OrderResponseInvoiceItem>();
  const { task } = useLoadedTaskDetailsContext();
  const { meId } = useLoadedMe();
  const { entityId, entity } = useEntitySelectedProvider();
  const { ordersApi, tasksApi } = useRestApiProvider();
  const [deleteOrderLine] = ordersApi.useDeleteOrderLineItemMutation();
  const [addOrderLines] = ordersApi.useAddOrderLineItemsMutation();
  const [updateOrderLines] = ordersApi.useUpdateLineItemsMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<ThunkDispatch<any, unknown, AnyAction>>();

  const selectedServicesRef = useRef<ManageServiceDrawerRefState>(null);

  const { getBoardById } = useTaskBoards({ entityId });

  const matchingBoard = task.board_id && getBoardById(task.board_id);

  const isBoardOwner = useMemo(
    () =>
      matchingBoard &&
      matchingBoard.users?.find((u) => u.id === meId)?.type === "owner",
    [matchingBoard, meId]
  );

  const { t } = useTranslations();
  const { toast } = useToast();
  const { data, isLoading } = ordersApi.useGetOrderResponseQuery(
    task.order_resp
      ? {
          entityId,
          orderResponseId: task.order_resp,
        }
      : skipToken
  );

  const handleManageItems = async ({
    itemIds,
    newItem,
    itemsQty,
  }: {
    itemIds: string[];
    newItem?: {
      inventory_id: string;
      qty: number;
    };
    itemsQty?: { [key: string]: number };
  }) => {
    if (!data?.invoice?.id || !task.order_resp) return;

    const idsToAdd = itemIds
      .filter(
        (id) => !data.invoice?.items.find((item) => item.inventory_id === id)
      )
      .map((id) => ({ inventory_id: id, qty: itemsQty?.[id] || 1 }));

    if (idsToAdd.length || newItem) {
      const items = newItem ? [newItem, ...(idsToAdd || [])] : idsToAdd;

      const response = await addOrderLines({
        entityId,
        invoiceId: data.invoice.id,
        items,
        orderResponseId: task.order_resp,
      });

      if (!("error" in response)) {
        dispatch(
          tasksApi.util.updateQueryData(
            "getTask",
            { entityId, taskId: task.id },
            (taskDraft) => {
              taskDraft.order_count += items.length;
            }
          )
        );

        toast(
          t({
            id: "feedback.order-details-updated",
            defaultMessage: "Order details updated",
          })
        );
      }
    }
  };

  const onAddNewProductSuccess = async (item: {
    inventory_id: string;
    qty: number;
  }) => {
    const currentItemsCopy = [...(selectedServicesRef.current?.items || [])];
    const currentItemsQtyCopy = {
      ...selectedServicesRef.current?.itemsQty,
    };
    handleManageItems({
      itemIds: currentItemsCopy,
      itemsQty: currentItemsQtyCopy,
      newItem: item,
    });
  };

  const handleDeleteOrderLine = async (inventoryId: string) => {
    if (!inventoryId || !data?.invoice?.id || !task.order_resp) return;
    const response = await deleteOrderLine({
      entityId,
      invoiceId: data?.invoice?.id,
      inventoryId: [inventoryId],
      orderResponseId: task.order_resp,
    });
    setOrderLineToDelete(undefined);
    if (!("error" in response)) {
      dispatch(
        tasksApi.util.updateQueryData(
          "getTask",
          { entityId, taskId: task.id },
          (taskDraft) => {
            taskDraft.order_count -= 1;
          }
        )
      );

      toast(
        t({
          id: "feedback.order-details-updated",
          defaultMessage: "Order details updated",
        })
      );
    }
  };

  const handleInlineEdit = async ({
    item,
    value,
    type,
  }: {
    item: OrderResponseInvoiceItem;
    value: number;
    type: "qty" | "price";
  }) => {
    const isSamePrice = item.discount.discount
      ? value * 100 ===
        undiscountedPriceInCents(item.discount.discount || 0, item.price)
      : item.price === value;

    const isSameValue = type === "qty" ? item.qty === value : isSamePrice;
    if (!data?.invoice?.id || !task.order_resp || isSameValue) return;
    if (value > MAX_NUMBER_VALUE) {
      toast(
        t(
          {
            id: "feedback.max-number-value-is",
            defaultMessage: "Max number value is {value}",
          },
          {
            value: MAX_NUMBER_VALUE,
          }
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    if (Number.isNaN(value)) {
      toast(
        t({
          id: "feedback.value-can-be-empty",
          defaultMessage: "Value can't be empty",
        }),
        { variant: "error" }
      );
      return;
    }
    if (type === "qty" && value === 0) {
      toast(
        t(
          {
            id: "feedback.value-cant-be-zero",
            defaultMessage: "Value can't be zero",
          },
          {
            value: MAX_NUMBER_VALUE,
          }
        ),
        { variant: "error" }
      );
      return;
    }
    if (type === "price" && !item.discount?.discount) {
      value = Number(value.toFixed(2));
    }
    if (type === "price" && item.discount?.discount) {
      const newPrice = Number(value.toFixed(2));
      const discountedNewPrice =
        (newPrice * (100 - item.discount.discount)) / 100;
      value = discountedNewPrice;
    }

    const response = await updateOrderLines({
      entityId,
      invoiceId: data.invoice.id,
      items: [{ inventory_id: item.inventory_id, [type]: value }],
      orderResponseId: task.order_resp,
    });

    if (!("error" in response)) {
      toast(
        t({
          id: "feedback.order-details-updated",
          defaultMessage: "Order details updated",
        })
      );
    }
  };

  if (isLoading) {
    return (
      <div className="bg-grey-100 flex h-[284px] animate-pulse items-center justify-center rounded-2xl">
        <LoadingSpinner />
      </div>
    );
  }

  if (!data) return null;
  return (
    <div className="flex flex-col gap-14">
      <AddNewProductForm
        entityId={entityId}
        currency={entity.currency}
        isOpen={isAddProductFormOpen}
        onSuccess={onAddNewProductSuccess}
        onClose={() => setIsAddProductFormOpen(false)}
      />
      <ManageServiceDrawer
        currency={entity.currency}
        entityId={entityId}
        ref={selectedServicesRef}
        isOpen={isManageServiceDrawerOpen}
        items={[]}
        onRequestClose={() => setIsManageServiceDrawerOpen(false)}
        onSubmit={(items, itemsQty) => {
          handleManageItems({
            itemIds: items,
            itemsQty,
          });
        }}
        invoiceId={data.invoice?.id}
        emptyInventoryContent={
          <PlainButton
            className="bg-grey-100 text-dark-700 font-secondary flex h-[40px] w-[158px] items-center justify-center gap-2.5 rounded-lg text-sm hover:brightness-95"
            onClick={() => {
              setIsManageServiceDrawerOpen(false);
              setIsAddProductFormOpen(true);
            }}
          >
            <PlusIcon />

            {t({
              id: "tasks-page.add-new-product",
              defaultMessage: "Add New Item",
            })}
          </PlainButton>
        }
        searchBarEndContent={
          <div className="ml-4">
            <Tooltip
              renderTrigger={({ props, ref }) => (
                <div {...props} ref={ref}>
                  <PlainButton
                    className="bg-grey-100 flex h-[40px] w-[40px] items-center justify-center rounded-lg hover:brightness-95"
                    onClick={() => {
                      setIsManageServiceDrawerOpen(false);
                      setIsAddProductFormOpen(true);
                    }}
                  >
                    <PlusIcon />
                  </PlainButton>
                </div>
              )}
            >
              {t({
                id: "tasks-page.add-new-product",
                defaultMessage: "Add New Item",
              })}
            </Tooltip>
          </div>
        }
      />

      {data.invoice && (
        <OrderItemsTable
          invoice={data.invoice}
          enableEdit={Boolean(isBoardOwner)}
          addButton={
            <TabContentButton
              label="Add"
              icon={<AddIcon />}
              onClick={() => setIsManageServiceDrawerOpen(true)}
            />
          }
          onInlineEdit={handleInlineEdit}
          tableActions={[
            {
              title: t({
                id: "common.action",
                defaultMessage: "Action",
              }),
              className: "min-w-[100px] p-0",
              headerClassName: "border-r-0",
              grow: true,
              isHidden: !isBoardOwner,
              content: (item) => (
                <PlainButton
                  className="flex h-full w-full items-center justify-center"
                  onClick={() => {
                    setOrderLineToDelete(item);
                  }}
                >
                  <DeleteIcon />
                </PlainButton>
              ),
            },
          ]}
        />
      )}
      <OrderInfo orderResponse={data} />
      {data.invoice && (
        <OrderInvoiceHistoryItems
          entityId={data.invoice.entity_id}
          invoiceId={data.invoice.id}
          orderResponse={data}
          currency={entity.currency || DEFAULT_ENTITY_CURRENCY}
        />
      )}
      <Alert
        isOpen={!!orderLineToDelete}
        title={t({
          id: "common.confirm-action",
          defaultMessage: "Confirm action",
        })}
        img={
          <Lottie
            options={{ animationData: alertAnimation }}
            height={70}
            width={70}
          />
        }
        content={t(
          {
            id: "task-details.order-delete-order-line-confirmation",
            defaultMessage:
              "Do you really want to delete {category} <b>{productName}</b> from the Order?",
          },
          {
            category:
              orderLineToDelete?.category === "item" ? "Item" : "Service",
            productName: orderLineToDelete?.name,
            b: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        )}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
            onClick: () => setOrderLineToDelete(undefined),
          },
          {
            text: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            color: "tertiary",
            onClick: () => {
              if (!orderLineToDelete) return;
              handleDeleteOrderLine(orderLineToDelete.inventory_id);
            },
          },
        ]}
      />
    </div>
  );
};
