import { useTranslations } from "@jugl-web/utils";
import { TaskViewLoading } from "@web-src/features/tasks/TaskViewLoading";
import { useMemo } from "react";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { DashboardEmptyState } from "../DashboardEmptyState";
import { DashboardNoResultsState } from "../DashboardNoResultsState";
import { DashboardWidgetsGrid } from "../DashboardWidgetsGrid";

export const DashboardContent = () => {
  const {
    detailedDashboard,
    isDetailedDashboardLoading,
    selectedDashboardId,
    searchQuery,
    isDashboardOwner,
  } = useDashboardContext();

  const { t } = useTranslations();

  const hasSearchQuery = searchQuery.trim().length > 0;

  const canManageDashboard = useMemo(
    () => !!detailedDashboard && isDashboardOwner(detailedDashboard),
    [detailedDashboard, isDashboardOwner]
  );

  const visibleWidgets = useMemo(() => {
    if (!detailedDashboard) {
      return [];
    }

    if (!hasSearchQuery) {
      return detailedDashboard.widgets;
    }

    return detailedDashboard.widgets.filter((widget) =>
      widget.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [detailedDashboard, hasSearchQuery, searchQuery]);

  const isEmpty = visibleWidgets.length === 0;

  if (
    isDetailedDashboardLoading ||
    !detailedDashboard ||
    !selectedDashboardId
  ) {
    return (
      <div className="flex-1">
        <TaskViewLoading
          label={t({
            id: "dashboard-page.loading-dashboard",
            defaultMessage: "Loading Dashboard...",
          })}
          className="h-full"
        />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="flex-1">
        {hasSearchQuery ? (
          <DashboardNoResultsState />
        ) : (
          <DashboardEmptyState canManageDashboard={canManageDashboard} />
        )}
      </div>
    );
  }

  return (
    <DashboardWidgetsGrid
      dashboard={detailedDashboard}
      visibleWidgets={visibleWidgets}
    />
  );
};
