import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export type StickyIndexContextProps = {
  activeIndex: number;
  setActiveIndex: (i: number) => void;
};

export const StickyIndexContext = createContext<StickyIndexContextProps | null>(
  null
);

export const StickyIndexContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const value = useMemo(() => ({ activeIndex, setActiveIndex }), [activeIndex]);
  return (
    <StickyIndexContext.Provider value={value}>
      {children}
    </StickyIndexContext.Provider>
  );
};

export const useStickyIndexContext = () => {
  const context = useContext(StickyIndexContext);
  if (!context) {
    throw new Error(
      "useStickyIndexContext must be used within a StickyIndexContextProvider"
    );
  }
  return context;
};
