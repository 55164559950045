import { Button } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import emptyStateAnimation from "./assets/empty-state-animation.json";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

interface NoDashboardFeedbackProps {
  onNewDashboardClick: () => void;
}

export const NoDashboardFeedback: FC<NoDashboardFeedbackProps> = ({
  onNewDashboardClick,
}) => {
  const { t } = useTranslations();

  return (
    <div className="animate-fade-in flex w-[480px] flex-col items-center text-center">
      <Lottie
        options={{ animationData: emptyStateAnimation }}
        height={300}
        width={300}
      />
      <h1 className="font-secondary mb-0 mt-8 text-2xl font-medium text-black">
        {t({
          id: "dashboard-page.no-dashboard-feedback-title",
          defaultMessage:
            "Create custom Dashboards to visualize data from your Boards 📈",
        })}
      </h1>
      <h2 className="font-secondary mb-8 mt-[9px] text-base font-normal text-[#828282]">
        {t({
          id: "dashboard-page.no-dashboard-feedback-subtitle",
          defaultMessage: "Easy way to track Task progress using charts",
        })}
      </h2>
      <Button
        variant="contained"
        color="primary"
        iconEnd={<PlusIcon />}
        className="font-secondary h-10 w-[219px]"
        onClick={onNewDashboardClick}
      >
        {t({
          id: "dashboard-page.create-dashboard",
          defaultMessage: "Create Dashboard",
        })}
      </Button>
    </div>
  );
};
