import { InventoryItemDataModel } from "@jugl-web/rest-api/inventory/models/InventoryItem";
import { useTranslations, priceDisplay, cx } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useMemo, Fragment } from "react";

export const StockTab = ({ data }: { data: InventoryItemDataModel }) => {
  const { price, category, unit, stock, warehouse } = data;
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const tableData = useMemo(
    () => [
      {
        id: "reorderPoint",
        label: t({
          id: "inventory-items-sidebar.reorder-point-alert",
          defaultMessage: "Reorder Point Alert",
        }),
        isHidden: !stock.reorder_point?.toString(),
        value: (
          <div
            title={`${stock.reorder_point} ${unit}`}
            className="max-w-[400px] truncate"
          >
            {`${stock.reorder_point}`}
            <span className="text-grey-700 pl-1">{unit}</span>
          </div>
        ),
      },
      {
        id: "price",
        label: t({
          id: "common.price",
          defaultMessage: "Price",
        }),
        value: (
          <>
            {priceDisplay(parseFloat(price) * 100, entity.currency)}{" "}
            <span className="text-grey-700 pl-1">
              /
              {category === "service"
                ? t({
                    id: "inventory-items-sidebar.service",
                    defaultMessage: "Service",
                  })
                : unit}
            </span>
          </>
        ),
      },
      {
        id: "stock",
        label: t({
          id: "inventory-items-sidebar.stock-on-hand",
          defaultMessage: "Stock on Hand",
        }),
        value: (
          <>
            {stock.stock + (stock.in_process || 0)}{" "}
            <span className="text-grey-700 pl-1"> {unit}</span>
          </>
        ),
      },
      {
        id: "stock",
        label: t({
          id: "inventory-items-sidebar.processing-orders",
          defaultMessage: "Processing Orders",
        }),
        isHidden: !stock.in_process,
        value: (
          <>
            {stock.in_process}{" "}
            <span className="text-grey-700 pl-1"> {unit}</span>
          </>
        ),
      },
      {
        id: "stock",
        label: t({
          id: "inventory-items-sidebar.available-for-sale",
          defaultMessage: "Available for Sale",
        }),
        value: (
          <>
            {stock.stock} <span className="text-grey-700 pl-1"> {unit}</span>
          </>
        ),
      },
      {
        id: "warehouse",
        label: t({
          id: "common.warehouse",
          defaultMessage: "Warehouse",
        }),
        isHidden: !warehouse,
        value: (
          <div title={warehouse?.name} className="w-11/12 truncate">
            {warehouse?.name}
          </div>
        ),
      },
    ],
    [
      category,
      entity.currency,
      price,
      stock.in_process,
      stock.reorder_point,
      stock.stock,
      t,
      unit,
      warehouse,
    ]
  );

  return (
    <div className="align-between flex h-full w-full border-collapse flex-col justify-between overflow-hidden overflow-y-auto pt-10">
      <div className="bg-dark-100 border-dark-100 grid border-collapse grid-cols-[180px_auto] gap-[1px] overflow-hidden rounded-xl border border-solid">
        {tableData.map(
          (row) =>
            !row.isHidden && (
              <Fragment key={row.label}>
                <div
                  className={cx(
                    "text-dark font-secondary border-dark-100 flex h-[53px] w-[180px] border-collapse items-center bg-white pl-4 text-sm font-medium"
                  )}
                >
                  <div className="w-[160px]">{row.label}</div>
                </div>
                <div
                  className={cx(
                    "text-dark-800 border-dark-100 font-secondary flex h-[53px] w-full border-collapse items-center truncate bg-white pl-4 text-left text-sm"
                  )}
                >
                  {row.value}
                </div>
              </Fragment>
            )
        )}
      </div>
    </div>
  );
};
