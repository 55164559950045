import { TasksBoardId, TasksSource } from "@jugl-web/rest-api/tasks";
import {
  useLocalStorage,
  useSessionStorage,
} from "@jugl-web/utils/hooks/useStorage";
import { TASK_LIST_PREFERENCES_KEY } from "@jugl-web/utils/storage";
import { useCallback, useMemo } from "react";
import {
  TaskCalendarManageColumnTab,
  TaskCalendarView,
  TaskFiltering,
  TaskKanbanViewId,
  TaskLayout,
  TaskSorting,
} from "../types";
import { INITIAL_FILTERS_STATE } from "./useTaskFiltersState";

interface UseTaskListPreferencesOptions {
  entityId: string;
  source: TasksSource;
}

interface LocalStoragePreferences {
  layout: TaskLayout;
  filtering: TaskFiltering;
  sorting: TaskSorting;
  kanbanViewId: TaskKanbanViewId;
  visibleCustomFieldTableColumnsMap: Record<string, boolean>;
  calendarView: TaskCalendarView;
}

interface SessionStoragePreferences {
  searchQuery: string;
  searchQueryFilters: string[];
  expandedTables: Record<string, boolean>;
  calendarDate: string;
  calendarManageColumnTab: TaskCalendarManageColumnTab;
}

type TaskListPreferences = LocalStoragePreferences & SessionStoragePreferences;

const DEFAULT_TASK_FILTERING: TaskFiltering = {
  type: "standard",
  filters: INITIAL_FILTERS_STATE,
};

const DEFAULT_LOCAL_STORAGE_PREFERENCES: LocalStoragePreferences = {
  layout: "kanban",
  filtering: DEFAULT_TASK_FILTERING,
  sorting: "lastUpdated",
  kanbanViewId: "default",
  visibleCustomFieldTableColumnsMap: {},
  calendarView: "week",
};

const DEFAULT_SESSION_STORAGE_PREFERENCES: SessionStoragePreferences = {
  searchQuery: "",
  searchQueryFilters: [],
  expandedTables: {},
  calendarDate: new Date().toJSON(),
  calendarManageColumnTab: "noDueDate",
};

const localStoragePreferenceKeys = new Set(
  Object.keys(DEFAULT_LOCAL_STORAGE_PREFERENCES)
);

const getEntityBoardCombinedId = (entityId: string, boardId: TasksBoardId) =>
  `${entityId}-${boardId}`;

export const useTaskListPreferences = ({
  entityId,
  source,
}: UseTaskListPreferencesOptions) => {
  const entityBoardCombinedId = useMemo(() => {
    const finalBoardId: TasksBoardId =
      source.type === "boardTasks" ? source.boardId : "my";

    return getEntityBoardCombinedId(entityId, finalBoardId);
  }, [entityId, source]);

  const [
    localStoragePreferencesByEntityBoardCombinedId,
    setLocalStoragePreferencesByEntityBoardCombinedId,
  ] = useLocalStorage<Record<string, LocalStoragePreferences>>(
    TASK_LIST_PREFERENCES_KEY,
    {}
  );

  const [
    sessionStoragePreferencesByEntityBoardCombinedId,
    setSessionStoragePreferencesByEntityBoardCombinedId,
  ] = useSessionStorage<Record<string, SessionStoragePreferences>>(
    TASK_LIST_PREFERENCES_KEY,
    {}
  );

  const taskListPreferences = useMemo<TaskListPreferences>(() => {
    const localStoragePreferences = {
      ...DEFAULT_LOCAL_STORAGE_PREFERENCES,
      ...localStoragePreferencesByEntityBoardCombinedId[entityBoardCombinedId],
    };

    const sessionStoragePreferences = {
      ...DEFAULT_SESSION_STORAGE_PREFERENCES,
      ...sessionStoragePreferencesByEntityBoardCombinedId[
        entityBoardCombinedId
      ],
    };

    return {
      ...localStoragePreferences,
      ...sessionStoragePreferences,
    };
  }, [
    entityBoardCombinedId,
    localStoragePreferencesByEntityBoardCombinedId,
    sessionStoragePreferencesByEntityBoardCombinedId,
  ]);

  const updateTaskListPreference = useCallback(
    <TPreference extends keyof TaskListPreferences>(
      preference: TPreference,
      value:
        | TaskListPreferences[TPreference]
        | ((
            previousState: TaskListPreferences[TPreference]
          ) => TaskListPreferences[TPreference])
    ) => {
      const newValue =
        typeof value === "function"
          ? value(taskListPreferences[preference])
          : value;

      if (localStoragePreferenceKeys.has(preference)) {
        setLocalStoragePreferencesByEntityBoardCombinedId((previousState) => ({
          ...previousState,
          [entityBoardCombinedId]: {
            ...previousState[entityBoardCombinedId],
            [preference]: newValue,
          },
        }));

        return;
      }

      setSessionStoragePreferencesByEntityBoardCombinedId((previousState) => ({
        ...previousState,
        [entityBoardCombinedId]: {
          ...previousState[entityBoardCombinedId],
          [preference]: newValue,
        },
      }));
    },
    [
      entityBoardCombinedId,
      setLocalStoragePreferencesByEntityBoardCombinedId,
      setSessionStoragePreferencesByEntityBoardCombinedId,
      taskListPreferences,
    ]
  );

  return { taskListPreferences, updateTaskListPreference };
};
