import React, { useEffect, useState, useRef, FC } from "react";

interface RangeSliderProps {
  min: number;
  max: number;
  step: number;
  variant: "incoming" | "outgoing";
  value: number;
  onChange: (value: number) => void;
}

const SLIDER_WIDTH = 160;
const ANCHOR_FULL_RADIUS = 8;
const ANCHOR_BORDER = 1;

export interface RangeSliderHandle {
  updateRangeSlider: (value: number) => void;
}

export const RangeSlider: FC<RangeSliderProps> = ({
  min,
  max,
  step,
  variant,
  onChange,
  value,
}) => {
  const [dragging, setDragging] = useState(false);
  const svgRef = useRef<SVGSVGElement>(null);
  const circleRef = useRef<SVGCircleElement>(null);
  const progressRef = useRef<SVGRectElement>(null);
  const isOutgoing = variant === "outgoing";

  useEffect(() => {
    if (!circleRef.current || !progressRef.current) return;
    const percents = (value - min) / (max - min);
    const percentage = Math.round(SLIDER_WIDTH * ((value - min) / (max - min)));
    circleRef.current.setAttribute(
      "cx",
      (percents * SLIDER_WIDTH + ANCHOR_FULL_RADIUS).toString()
    );
    progressRef.current.setAttribute("width", percentage.toString());
  }, [max, min, value]);

  const handleClick = (e: React.MouseEvent<SVGSVGElement>) => {
    if (!svgRef.current || !circleRef.current) return;
    const rect = svgRef.current.getBoundingClientRect();
    let clickPosition = e.clientX - rect.left - ANCHOR_FULL_RADIUS;
    if (clickPosition < 0) {
      clickPosition = 0;
    } else if (clickPosition > SLIDER_WIDTH) {
      clickPosition = SLIDER_WIDTH;
    }
    let newValue = min + (clickPosition / SLIDER_WIDTH) * (max - min);
    newValue = Math.round(newValue / step) * step;

    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }
    onChange(newValue);
  };

  const handleMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
    if (!dragging) return;
    handleClick(e);
  };

  const handleMouseDown = () => {
    setDragging(true);
  };

  useEffect(() => {
    const handleDocumentMouseUp = () => {
      if (dragging) {
        setDragging(false);
      }
    };

    document.addEventListener("mouseup", handleDocumentMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    };
  }, [dragging]);

  return (
    <svg
      ref={svgRef}
      width={SLIDER_WIDTH + ANCHOR_FULL_RADIUS * 2}
      height="16"
      viewBox={`0 0 ${SLIDER_WIDTH + ANCHOR_FULL_RADIUS * 2} 16`}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <rect
        x={ANCHOR_FULL_RADIUS}
        y="7"
        width={SLIDER_WIDTH}
        height="2"
        fill={isOutgoing ? "#BBDEFB" : "#D9D9D9"}
        rx="2"
      />
      <rect
        x={ANCHOR_FULL_RADIUS}
        y="7"
        width="0"
        height="2"
        fill="#63B4F6"
        rx="2"
        ref={progressRef}
      />
      <circle
        ref={circleRef}
        cx={ANCHOR_FULL_RADIUS}
        cy={8}
        r={ANCHOR_FULL_RADIUS - ANCHOR_BORDER / 2}
        fill={isOutgoing ? "#FFFFFF" : "#2196F3"}
        strokeWidth={ANCHOR_BORDER}
        stroke={isOutgoing ? "#4E93CA" : "#FFFFFF"}
      />
    </svg>
  );
};
