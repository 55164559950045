import {
  EntitySpaceType,
  useRestApiProvider,
  UserRole,
} from "@jugl-web/rest-api";
import {
  Button,
  EmptyListContent,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import Lottie from "react-lottie";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { ReactComponent as AddIcon } from "./assets/add-icon.svg";
import { TeamCard } from "./components/TeamCard";
import { DepartmentFormSidebar } from "./components/DepartmentFormSidebar";
import { TeamsPageProvider } from "./TeamsPageContext";
import teamsAnimation from "./assets/teams.json";

const TeamsPageContent: FC = () => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { entitySpacesApi } = useRestApiProvider();
  const { entityId, entity } = useEntitySelectedProvider();
  const {
    data: spaces,
    isError,
    refetch,
  } = entitySpacesApi.use_DEPRECATED_allEntitySpacesListQuery({
    entityId,
    params: {
      type: EntitySpaceType.dept,
    },
  });

  const { searchQuery, reset, inputProps } = useSearchInput();
  const visibleSpaces = useMemo(
    () =>
      searchQuery
        ? spaces?.filter((item) =>
            item.info.title.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : spaces,
    [searchQuery, spaces]
  );

  const [isNewTeamSideBarOpen, setIsNewTeamSideBarOpen] = useState(false);

  const $content = useMemo(() => {
    if (isError) {
      return <EmptyListContent type="error" onRefetch={refetch} />;
    }
    if (spaces?.length === 0) {
      return (
        <EmptyListContent
          type="custom"
          customTitle={t({
            id: "people-page.welcome-to-teams",
            defaultMessage: "Welcome to Teams!",
          })}
          customSubtitle={`${t({
            id: "people-page.combine-people-into-teams",
            defaultMessage:
              "Combine People into Teams for easy work management",
          })} 🚀`}
          customImg={
            <Lottie
              options={{ animationData: teamsAnimation }}
              height={230}
              width={200}
            />
          }
          customButton={
            entity.role === UserRole.admin
              ? {
                  onClick: () => setIsNewTeamSideBarOpen(true),
                  text: t({
                    id: "people-page.create-team",
                    defaultMessage: "Create team",
                  }),
                  iconEnd: <AddIcon />,
                }
              : undefined
          }
        />
      );
    }
    if (visibleSpaces?.length === 0) {
      return <EmptyListContent type="noResults" />;
    }
    return visibleSpaces?.map((space) => (
      <TeamCard key={space.id} space={space} />
    ));
  }, [isError, spaces?.length, visibleSpaces, entity.role, refetch, t]);

  return (
    <>
      <MultiSectionLayout
        className="bg-white"
        header={
          <>
            <HeaderBreadcrumbs
              items={[
                {
                  title: "People",
                  onClick: () => navigateToPage("peoplePeople"),
                },
                { title: "Teams" },
              ]}
            />
            <div className="flex items-center justify-between bg-[#EEF2F5] py-4 px-8">
              <div className="max-w-[280px]">
                <SearchInput
                  variant="filled"
                  color="white"
                  onReset={reset}
                  {...inputProps}
                  onClear={reset}
                />
              </div>
              {entity.role === UserRole.admin && (
                <div className="flex gap-4">
                  <Button
                    onClick={() => setIsNewTeamSideBarOpen(true)}
                    iconEnd={<AddIcon />}
                    className="h-10"
                  >
                    Create team
                  </Button>
                </div>
              )}
            </div>
          </>
        }
      >
        <div className="flex h-full flex-col gap-7 p-8">{$content}</div>
      </MultiSectionLayout>
      {isNewTeamSideBarOpen && (
        <DepartmentFormSidebar
          isOpen
          onRequestClose={() => setIsNewTeamSideBarOpen(false)}
        />
      )}
    </>
  );
};

export const TeamsPage: FC = () => (
  <TeamsPageProvider>
    <TeamsPageContent />
  </TeamsPageProvider>
);
