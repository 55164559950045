import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useMemo } from "react";

export const GroupAdminManagementAlert: React.FC<{
  isOpen: boolean;
  type?: "assign" | "remove";
  userId?: string;
  onConfirm: () => void;
  onRequestClose: () => void;
}> = ({ isOpen, userId, type, onConfirm, onRequestClose }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { profile } = useUserGeneralProfile({ userId, entityId });
  const isNotRegistered = profile && !profile?.hasProfile;

  const name = useMemo(
    () =>
      `${profile?.displayName || ""}${
        isNotRegistered ? ` (Not registered)` : ""
      }`,
    [isNotRegistered, profile?.displayName]
  );

  return (
    <Alert
      isOpen={isOpen && !!type}
      onRequestClose={onRequestClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      content={
        type === "remove"
          ? t(
              {
                id: "chats-page.remove-group-admin-confirmation",
                defaultMessage:
                  "Do you really want to remove Group Admin role from {name}?",
              },
              {
                name: (
                  <span className="text-primary-800 font-semibold">{name}</span>
                ),
              }
            )
          : t(
              {
                id: "chats-page.assign-group-admin-confirmation",
                defaultMessage:
                  "Do you really want to assign Group Admin role to {name}? User will be able to fully manage Group Settings",
              },
              {
                name: (
                  <span className="text-primary-800 font-semibold">{name}</span>
                ),
              }
            )
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.confirm",
            defaultMessage: "Confirm",
          }),
          onClick: () => {
            onConfirm();
            onRequestClose();
          },
        },
      ]}
    />
  );
};
