import { getUniqueId } from "@jugl-web/utils";
import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { WhatsappAgentModel } from "./models";
import { WhatsappAgentLogModel } from "./models/WhatsappAgentLog";
import {
  transformWhatsappTemplateDtoToModel,
  WhatsappTemplateDto,
  WhatsappTemplateModel,
} from "./models/WhatsappTemplate";
import { IntegrationsApiTag } from "./tags";
import { WhatsappTemplateCategory, WhatsappTemplatePayload } from "./types";

export const addIntegrationsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [IntegrationsApiTag.whatsappAccount],
  });

  const integrationsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      getWhatsappTemplates: builder.query<
        {
          data: WhatsappTemplateModel[];
          next_page: string | null;
        },
        {
          entityId: string;
          params: {
            category?: WhatsappTemplateCategory;
            page?: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/template`,
          params,
        }),
        transformResponse: (response: {
          data: WhatsappTemplateDto[];
          next_page: string | null;
        }) => ({
          ...response,
          data: response.data.map(transformWhatsappTemplateDtoToModel),
        }),
      }),

      createWhatsappTemplate: builder.mutation<
        WhatsappTemplateModel,
        { entityId: string; data: WhatsappTemplatePayload }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/template`,
          method: "POST",
          // TODO: temp solution until backend picks up
          data: {
            ...data,
            action: data.action || getUniqueId(),
          },
          silentError: true,
        }),
        transformResponse: (response: WhatsappTemplateDto) =>
          transformWhatsappTemplateDtoToModel(response),
      }),
      deleteWhatsappTemplate: builder.mutation<
        void,
        { entityId: string; templateId: string }
      >({
        query: ({ entityId, templateId }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/template/${templateId}`,
          method: "DELETE",
        }),
      }),
      getWhatsappTemplate: builder.query<
        WhatsappTemplateModel,
        { entityId: string; templateId: string }
      >({
        query: ({ entityId, templateId }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/template/${templateId}`,
        }),
        transformResponse: (response: WhatsappTemplateDto) =>
          transformWhatsappTemplateDtoToModel(response),
      }),
      updateWhatsappTemplate: builder.mutation<
        WhatsappTemplateModel,
        {
          entityId: string;
          data: WhatsappTemplatePayload;
          templateId: string;
        }
      >({
        query: ({ entityId, data, templateId }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/template/${templateId}`,
          method: "PUT",
          data,
          silentError: true,
        }),
        transformResponse: (response: WhatsappTemplateDto) =>
          transformWhatsappTemplateDtoToModel(response),
      }),

      whatsappAgentAccount: builder.query<
        WhatsappAgentModel | null,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/account`,
          silentError: true,
        }),
        transformResponse: (data: WhatsappAgentModel | Record<string, never>) =>
          Object.keys(data).length === 0 ? null : (data as WhatsappAgentModel),
        providesTags: (result, error, { entityId }) => [
          { type: IntegrationsApiTag.whatsappAccount, id: entityId },
        ],
      }),

      connectWhatsappAgentAccount: builder.mutation<
        WhatsappAgentModel,
        {
          entityId: string;
          data: {
            code: string;
            waba_id: string;
            phone_number_id: string;
          };
        }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/account`,
          method: "POST",
          data: { ...data, redirect_url: "" },
        }),
        invalidatesTags: (result, error, { entityId }) =>
          result
            ? [{ type: IntegrationsApiTag.whatsappAccount, id: entityId }]
            : [],
      }),

      disconnectWhatsappAgentAccount: builder.mutation<
        void,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/account`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, { entityId }) => [
          { type: IntegrationsApiTag.whatsappAccount, id: entityId },
        ],
      }),
      changeWhatsappTemplateStatus: builder.mutation<
        void,
        { entityId: string; templateId: string; isEnabled: boolean }
      >({
        query: ({ entityId, templateId, isEnabled }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/template/${templateId}`,
          method: "PUT",
          data: {
            enabled: isEnabled,
          },
        }),
      }),
      getAgentLogs: builder.query<
        PaginatedResponse<WhatsappAgentLogModel>,
        PaginatedRequestParams<{ entityId: string }>
      >({
        query: ({ entityId, ...params }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/logs`,
          params: {
            ...params,
          },
        }),
      }),

      registerWhatsappAgentAccount: builder.mutation<
        { success: boolean },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/agents/whatsapp/account/register`,
          method: "POST",
        }),
        invalidatesTags: (result, error, { entityId }) =>
          result?.success
            ? [{ type: IntegrationsApiTag.whatsappAccount, id: entityId }]
            : [],
      }),
    }),
  });

  return integrationsApi;
};

export type IntegrationsApi = ReturnType<typeof addIntegrationsApi>;
