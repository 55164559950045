import { useTranslations } from "@jugl-web/utils";

export const DashboardNoResultsState = () => {
  const { t } = useTranslations();

  return (
    <div className="py-8 text-center">
      <span className="font-secondary text-base leading-4 text-[#828282]">
        {t({
          id: "tasks-page.no-results",
          defaultMessage: "No results 😔",
        })}
      </span>
    </div>
  );
};
