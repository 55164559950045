import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterSectionLayout } from "../FilterSectionLayout";
import { OverdueFilterPill } from "../OverdueFilterPill";
import { FilterSectionProps } from "../../types";

export const TypeFilterSection: FC<FilterSectionProps> = ({ isReadonly }) => {
  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const isFilterEmpty = filtersState.isOverdue === null;
  const isSectionHidden = isReadonly && isFilterEmpty;

  if (isSectionHidden) {
    return null;
  }

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-task-type-filter",
          defaultMessage: "By Task type",
        })}
      />
      <FilterSectionLayout.PillsContainer>
        <OverdueFilterPill
          isChecked={!!filtersState.isOverdue}
          onClick={
            !isReadonly
              ? () =>
                  updateFiltersState("isOverdue", (previousIsOverdue) =>
                    previousIsOverdue ? null : true
                  )
              : undefined
          }
        />
      </FilterSectionLayout.PillsContainer>
    </FilterSectionLayout>
  );
};
