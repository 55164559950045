import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import {
  ModulePagesConfig,
  PageNavParams,
} from "@web-src/modules/navigation/types";
import { WhatsappTemplateCategory } from "@jugl-web/rest-api/integrations/types";
import { WhatsappAccountPage } from "./WhatsappAccountPage";
import { WhatsappTemplateFormPage } from "./WhatsappTemplateFormPage";
import { WhatsappTemplatesPage } from "./WhatsappTemplatesPage";

export type IntegrationsPagesNavigationParams = {
  integrationsWhatsappTemplates: PageNavParams<{
    templateCategory: WhatsappTemplateCategory;
  }>;
  integrationsWhatsappTemplateCreate: PageNavParams<{
    templateCategory: WhatsappTemplateCategory;
  }>;
  integrationsWhatsappAccount: PageNavParams;
  integrationsWhatsappTemplateEdit: PageNavParams<{
    templateId: string;
    templateCategory: WhatsappTemplateCategory;
  }>;
};

export const integrationsPagesNavigationConfig: ModulePagesConfig<IntegrationsPagesNavigationParams> =
  {
    integrationsWhatsappTemplates: {
      element: <WhatsappTemplatesPage />,
      path: "integrations/whatsapp/templates/:templateCategory",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.integrations,
    },
    integrationsWhatsappTemplateCreate: {
      element: <WhatsappTemplateFormPage />,
      path: "integrations/whatsapp/templates/:templateCategory/create",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.integrations,
    },
    integrationsWhatsappAccount: {
      element: <WhatsappAccountPage />,
      path: "integrations/whatsapp/account",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.integrations,
    },
    integrationsWhatsappTemplateEdit: {
      element: <WhatsappTemplateFormPage />,
      path: "integrations/whatsapp/templates/:templateCategory/edit/:templateId",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.integrations,
    },
  };
