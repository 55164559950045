import { useSwipe, cx } from "@jugl-web/utils";
import { FC } from "react";
import { PlainButton } from "../../cross-platform/PlainButton";
import { Text } from "../../cross-platform/Text";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { SwipeableHeaderProps } from "./types";

export const SwipeableHeader: FC<SwipeableHeaderProps> = ({
  title,
  startSlot,
  hasHandle = true,
  hasCloseButton = true,
  customEndSlot,
  isDetached,
  showOnlyEndSlot,
  onClose,
  onSwipeUp,
}) => {
  const { touchProps } = useSwipe({
    onSwipeDown: onClose,
    onSwipeUp,
  });

  return (
    <header
      className="flex items-center justify-between px-4 pt-[30px] pb-5"
      style={
        isDetached
          ? { boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.04)" }
          : undefined
      }
    >
      {hasHandle && (
        <div className="absolute inset-x-0 top-0 h-10" {...touchProps}>
          <div className="absolute left-1/2 top-4 h-1 w-14 -translate-x-1/2 rounded-sm bg-[#F2F2F2]" />
        </div>
      )}
      {!showOnlyEndSlot && (
        <span className="flex items-center gap-4 overflow-hidden">
          {startSlot}
          <Text app="mobile" variant="h3" className="m-0 truncate">
            {title}
          </Text>
        </span>
      )}
      {customEndSlot ||
        (hasCloseButton && (
          <PlainButton onClick={onClose} className="relative">
            <CloseIcon />
          </PlainButton>
        ))}
    </header>
  );
};
