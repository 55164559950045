import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import {
  WITHOUT_CUSTOMER_ITEM_ID,
  useCustomerListBox,
} from "@jugl-web/domain-resources/users/hooks/useCustomerListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterSectionProps } from "../../types";
import { CustomerFilterPill } from "../CustomerFilterPill";
import { FilterSectionLayout } from "../FilterSectionLayout";

export const CustomerFilterSection: FC<FilterSectionProps> = ({
  entityId,
  isReadonly,
}) => {
  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const { getListBoxProps, clearSearchQuery } = useCustomerListBox({
    entityId,
    addWithoutCustomerItem: true,
  });

  const isFilterEmpty = filtersState.customers.length === 0;
  const isSectionHidden = isReadonly && isFilterEmpty;

  if (isSectionHidden) {
    return null;
  }

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-customer-filter",
          defaultMessage: "By Customer",
        })}
        endSlot={
          !isReadonly ? (
            <ResourcePickerPopover
              placement="bottom-end"
              title={t({
                id: "tasks-page.select-customer",
                defaultMessage: "Select customer",
              })}
              className="w-[315px]"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
              )}
              onSubmit={(ids) =>
                updateFiltersState(
                  "customers",
                  ids.map((id) => (id === WITHOUT_CUSTOMER_ITEM_ID ? null : id))
                )
              }
              onUnmount={clearSearchQuery}
              {...getListBoxProps({
                selectionBehavior: { mode: "multiple" },
                defaultSelectedIds: filtersState.customers.map(
                  (customerId) => customerId || WITHOUT_CUSTOMER_ITEM_ID
                ),
                maxVisibleItems: 5,
              })}
            />
          ) : undefined
        }
      />
      {!isFilterEmpty && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.customers.map((customerId) => (
            <CustomerFilterPill
              key={customerId}
              entityId={entityId}
              customerId={customerId}
              onRemove={
                !isReadonly
                  ? () =>
                      updateFiltersState("customers", (previousCustomers) =>
                        previousCustomers.filter((a) => a !== customerId)
                      )
                  : undefined
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
