import { Alert, LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC, useEffect, useState, useCallback } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OrderListItemModel } from "@jugl-web/rest-api/orders/models/OrderListItem";
import { useInView } from "react-intersection-observer";
import Lottie from "react-lottie";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { removeDiscountFlow } from "@web-src/modules/orders/pages/OrderFormWizardPage/consts";
import { ReactComponent as LinkIcon } from "./assets/link.svg";
import alertAnimation from "./assets/alert.json";

export const ConfirmDiscountActionPopover: FC<{
  mode: "status" | "remove";
  isOpen: boolean;
  discountId: string | undefined;
  showSkipLabel?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm?: (count: number) => void;
}> = ({
  mode,
  isOpen,
  discountId,
  showSkipLabel,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { ordersApi, inventoryApi } = useRestApiProvider();

  const { ref: moreOrdersRef, inView: isMoreOrdersInView } = useInView();
  const { entity } = useEntitySelectedProvider();
  const [internalIsLoading, setInternalIsLoading] = useState(false);
  const [ordersList, setOrdersList] = useState<{
    data: OrderListItemModel[];
    page: number;
    hasMore: boolean;
    totalEntries: number;
  }>({ data: [], page: 0, hasMore: false, totalEntries: 0 });
  const [loadDiscountInfo, { data: discountInfo }] =
    inventoryApi.useLazyGetDiscountItemQuery();
  const [loadDiscountedOrders] = ordersApi.useLazyGetOrdersByDiscountIdQuery();

  const handleLoadList = useCallback(async () => {
    if (!ordersList.hasMore || !discountId || internalIsLoading) {
      return;
    }
    const response = await loadDiscountedOrders({
      entityId: entity.id,
      discountId,
      page: ordersList.page + 1,
    });

    if ("data" in response && response.data?.data) {
      const hasMore = response.data?.total_pages > response.data.page_number;

      setOrdersList((prevState) => ({
        ...prevState,
        items: [...ordersList.data, ...(response.data?.data || [])],
        page: prevState.page + 1,
        hasMore,
      }));
    }
  }, [
    discountId,
    entity.id,
    internalIsLoading,
    loadDiscountedOrders,
    ordersList,
  ]);

  useEffect(() => {
    const handleData = async () => {
      if (isOpen && discountId) {
        setInternalIsLoading(true);
        loadDiscountInfo({
          entityId: entity.id,
          id: discountId,
        });
        const response = await loadDiscountedOrders({
          discountId,
          entityId: entity.id,
          page: 1,
        });

        if ("data" in response) {
          const hasMore = (response.data?.total_pages ?? 0) > 1;
          setOrdersList({
            data: response.data?.data || [],
            page: 1,
            hasMore,
            totalEntries: response.data?.total_entries || 0,
          });
          setInternalIsLoading(false);
        }
      }
    };
    handleData();
  }, [discountId, entity.id, isOpen, loadDiscountInfo, loadDiscountedOrders]);

  useEffect(() => {
    if (isMoreOrdersInView) {
      handleLoadList();
    }
  }, [handleLoadList, isMoreOrdersInView]);

  useEffect(() => {
    if (!isOpen) {
      setOrdersList({
        data: [],
        page: 0,
        hasMore: false,
        totalEntries: 0,
      });
    }
  }, [isOpen]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      img={
        <Lottie
          options={{ animationData: alertAnimation }}
          width={70}
          height={70}
        />
      }
      contentContainerClassName="px-6"
      buttonsContainerClassName="px-10 mt-8"
      content={
        <div>
          <div className="pb-8 text-sm text-[#4F4F4F]">
            {mode === "status" && (
              <>
                {ordersList.data.length
                  ? t(
                      {
                        id: "inventory-items-page.discount-status-change-warning-description",
                        defaultMessage:
                          "Discount {discountName} is <fancyText>used in {count} {count, plural, one {Form} other {Forms}}.</fancyText> If you deactivate it, discount will also be removed from the following Forms:",
                      },
                      {
                        fancyText: (text: (string | JSX.Element)[]) => (
                          <span className="text-primary-800 font-medium">
                            {text}
                          </span>
                        ),
                        discountName:
                          (discountId === discountInfo?.id &&
                            discountInfo?.name) ||
                          "",
                        count: ordersList.totalEntries,
                      }
                    )
                  : t(
                      {
                        id: "inventory-items-page.discount-status-change-warning-description-no-orders",
                        defaultMessage:
                          "Do you really want to deactivate the Discount <fancyText>{discountName}</fancyText>? While it is Inactive You will not be able to use it in Order forms",
                      },
                      {
                        fancyText: (text: (string | JSX.Element)[]) => (
                          <span className="text-primary-800 font-medium">
                            {text}
                          </span>
                        ),
                        discountName:
                          (discountId === discountInfo?.id &&
                            discountInfo?.name) ||
                          "",
                      }
                    )}
              </>
            )}
            {mode === "remove" && (
              <>
                {ordersList.data.length
                  ? t(
                      {
                        id: "inventory-items-page.discount-remove-warning-description",
                        defaultMessage:
                          "Discount {discountName} is <fancyText>used in {count} {count, plural, one {Form} other {Forms}}.</fancyText> If you delete it, discount will also be removed from the following Forms:",
                      },
                      {
                        fancyText: (text: (string | JSX.Element)[]) => (
                          <span className="text-primary-800 font-medium">
                            {text}
                          </span>
                        ),
                        discountName:
                          (discountId === discountInfo?.id &&
                            discountInfo?.name) ||
                          "",
                        count: ordersList.totalEntries,
                      }
                    )
                  : t(
                      {
                        id: "inventory-items-page.discount-remove-warning-description-no-orders",
                        defaultMessage:
                          "Do you really want to delete the Discount <fancyText>{discountName}</fancyText>? This Action cant be undone",
                      },
                      {
                        fancyText: (text: (string | JSX.Element)[]) => (
                          <span className="text-primary-800 font-medium">
                            {text}
                          </span>
                        ),
                        discountName:
                          (discountId === discountInfo?.id &&
                            discountInfo?.name) ||
                          "",
                      }
                    )}
              </>
            )}
          </div>
          <div className="flex max-h-[220px] flex-col gap-3 overflow-y-auto">
            {ordersList.data?.length
              ? ordersList.data.map((order) => (
                  <div
                    className="bg-grey-100 flex h-[57px] cursor-pointer items-center justify-between rounded-lg py-2 px-4 text-left"
                    onClick={() => {
                      navigateToPage(
                        "orderFormEdit",
                        {
                          formId: order.id,
                        },
                        {
                          queryParams: {
                            flow: removeDiscountFlow,
                          },
                        }
                      );
                    }}
                  >
                    <div>
                      <div className="font-secondary text-dark text-sm font-medium">
                        {order.formName}
                      </div>
                      <div className="text-xs text-[#828282]">
                        {order.prefix} - {order.counter}
                      </div>
                    </div>

                    <div className="flex items-center">
                      <LinkIcon />
                    </div>
                  </div>
                ))
              : null}
            {ordersList.hasMore && (
              <div ref={moreOrdersRef}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      }
      buttons={[
        {
          text: showSkipLabel
            ? t({
                id: "common.skip",
                defaultMessage: "Skip",
              })
            : t({
                id: "common.cancel",
                defaultMessage: "Cancel",
              }),
          color: "grey",
          onClick: () => {
            onClose();
            onCancel?.();
          },
        },
        {
          text:
            mode === "status"
              ? t({
                  id: "common.deactivate",
                  defaultMessage: "Deactivate",
                })
              : t({
                  id: "common.delete",
                  defaultMessage: "Delete",
                }),
          color: "tertiary",
          onClick: () => {
            onConfirm?.(ordersList.totalEntries);
            onClose();
          },
        },
      ]}
    />
  );
};
