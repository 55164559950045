import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  AttachmentsLayout,
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useToast, useTranslations } from "@jugl-web/utils";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { TASK_ATTACHMENTS_LAYOUT_KEY } from "@jugl-web/utils/storage";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useRef } from "react";
import { useTabSearchPersistenceContext } from "@jugl-web/domain-resources/tasks";
import { ReactComponent as AddSubtaskIcon } from "../../assets/add-subtask.svg";
import { ReactComponent as GridIcon } from "../../assets/grid.svg";
import { ReactComponent as ListIcon } from "../../assets/list.svg";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";
import { TabContentButton } from "../TabContentButton";
import { TabContentFeedbackText } from "../TabContentFeedbackText";

export const TaskDetailsAttachmentsTabContent = () => {
  const [layout, setLayout] = useLocalStorage<AttachmentsLayout>(
    TASK_ATTACHMENTS_LAYOUT_KEY,
    "grid"
  );

  const { task } = useLoadedTaskDetailsContext();
  const { meId } = useLoadedMe();
  const { entityId } = useEntitySelectedProvider();

  const permissions = useTaskPermissions({ task, entityId, meId });

  const { getInitialSearchQuery, saveSearchQuery } =
    useTabSearchPersistenceContext();

  const {
    searchQuery,
    inputProps: searchInputProps,
    reset: resetSearchInput,
  } = useSearchInput({
    defaultValue: getInitialSearchQuery("attachments"),
    onSearch: (query) => saveSearchQuery("attachments", query),
  });

  const { previewFile } = useFilePreview();
  const { downloadFile } = useDownloadFile();

  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);

  const { toast, closeToast } = useToast();
  const { t } = useTranslations();

  const handleDownloadAttachment = async (attachment: TaskAttachment) => {
    const downloadingToastId = toast(
      t({ id: "feedback.file-downloading", defaultMessage: "Downloading..." }),
      { variant: "info", persist: true }
    );

    const response = await downloadFile({
      entityId,
      id: attachment.id,
      fileName: attachment.name,
      fileSize: attachment.size,
      mimeType: attachment.content_type,
    });

    if ("data" in response) {
      downloadBlobAsFile(response.data, attachment.name);
    } else {
      toast(
        t(
          {
            id: "tasks-page.attachment-download-failed",
            defaultMessage: "Couldn't download file {fileName}",
          },
          { fileName: attachment.name }
        ),
        { variant: "error", style: { zIndex: 99999 } }
      );
    }

    closeToast(downloadingToastId);
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) => {
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
      id: attachment.id,
      size: attachment.size,
      entityId,
      onDownload: () => handleDownloadAttachment(attachment),
    });
  };

  return (
    <>
      <div className="flex items-center justify-between gap-4">
        <SearchInput
          variant="filled"
          color="grey"
          containerClassName="w-full max-w-[320px]"
          onClear={resetSearchInput}
          {...searchInputProps}
        />
        <div className="flex items-center gap-4">
          <TabContentButton
            label={
              layout === "grid"
                ? t({
                    id: "tasks-page.show-as-list",
                    defaultMessage: "Show as list",
                  })
                : t({
                    id: "tasks-page.show-as-cards",
                    defaultMessage: "Show as cards",
                  })
            }
            icon={layout === "grid" ? <ListIcon /> : <GridIcon />}
            onClick={() => setLayout(layout === "grid" ? "list" : "grid")}
          />
          {permissions.canManageAttachments && (
            <TabContentButton
              label={t({ id: "common.add", defaultMessage: "Add" })}
              icon={<AddSubtaskIcon />}
              onClick={() => $taskAttachmentsRef.current?.openFilePicker()}
            />
          )}
        </div>
      </div>
      <div className="mt-8">
        <TaskAttachments
          mode="uncontrolled"
          ref={$taskAttachmentsRef}
          entityId={entityId}
          layout={layout}
          emptyContent={
            <TabContentFeedbackText
              className="mt-8"
              text={t({
                id: "tasks-page.no-attachments-added",
                defaultMessage: "No attachments added yet",
              })}
            />
          }
          noSearchResultsContent={
            <TabContentFeedbackText
              className="mt-8"
              text={t({
                id: "tasks-page.no-results",
                defaultMessage: "No results 😔",
              })}
            />
          }
          taskId={task.id}
          attachments={task.attachments}
          searchQuery={searchQuery}
          isEditable={permissions.canManageAttachments}
          onPreviewAttachment={handlePreviewAttachment}
        />
      </div>
    </>
  );
};
