import React, { useMemo } from "react";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import { ChatType } from "@jugl-web/rest-api";
import { useDispatch } from "react-redux";
import {
  InteractiveContainer,
  Avatar,
} from "@jugl-web/ui-components/cross-platform";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks/useCallInvitation";
import { useTranslations } from "@jugl-web/utils";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { setOpenedUserProfile } from "@web-src/modules/chats/store/chatsSlice";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { HeaderButton } from "./components/HeaderButton";
import { GroupChatMoreMenu } from "./components/GroupChatMoreMenu";
import { PersonalChatMoreMenu } from "./components/PersonalChatMoreMenu";
import { useChatsPageWithSelectedChatProvider } from "../../../../providers/ChatsPageProvider";

export const ChatHeader: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  const { chat, isParticipant } = useActiveChat();
  const { entity } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const { profile } = useUserGeneralProfile({
    entityId: entity?.id,
    userId: chat?.id,
    skip: chat?.type !== ChatType.chat,
  });

  const dispatch = useDispatch();
  const { call } = useCallInvitation();
  const {
    activeChatState,
    closeSideBar,
    activeChatId,
    openSideBar,
    setSearchedMessage,
  } = useChatsPageWithSelectedChatProvider();
  const searchSidebarIsOpen = activeChatState?.openedSideBar?.type === "search";
  const handleToggleSearchMessageSidebar = () => {
    if (searchSidebarIsOpen) {
      closeSideBar(activeChatId);
      setSearchedMessage(activeChatId, null);
    } else {
      openSideBar(activeChatId, "search");
    }
  };

  const isEntityConversation = useMemo(
    () => chat?.type === ChatType.entity_conversation,
    [chat?.type]
  );

  const avatarSrc = useMemo(() => {
    if (isEntityConversation) {
      return entity?.logoImg;
    }
    if (chat?.isSelf || chat?.type === ChatType.chat) {
      return profile?.avatar;
    }
    return chat?.img;
  }, [
    chat?.img,
    chat?.isSelf,
    chat?.type,
    entity?.logoImg,
    isEntityConversation,
    profile?.avatar,
  ]);

  const chatTitle = useMemo(() => {
    if (chat?.isSelf || chat?.type === ChatType.chat) {
      return profile?.displayName || "";
    }
    return chat?.title || "";
  }, [chat?.isSelf, chat?.title, chat?.type, profile?.displayName]);

  return (
    <div className="border-grey-200 flex h-[62px] w-full items-center justify-between border-0 border-b border-solid bg-white px-10">
      <InteractiveContainer
        className="flex min-w-0 items-center"
        onClick={onClick}
        isDisabled={!onClick}
      >
        <Avatar size="lg" imageUrl={avatarSrc} username={chatTitle} />
        <div className="mr-2 ml-2 min-w-0 flex-1">
          {isEntityConversation ? (
            <div className="flex flex-col gap-1">
              <span className="font-semibold">
                {t({
                  id: "chats-page.workspace-updates",
                  defaultMessage: "Workspace updates",
                })}
              </span>
              <div className="truncate text-sm text-[#9E9E9E]">{chatTitle}</div>
            </div>
          ) : (
            <>
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                {chatTitle}
              </div>
              <div className="truncate whitespace-nowrap text-sm text-[#9E9E9E]">
                {chat?.type === "muc" &&
                  chat.config?.admin_only_msg &&
                  t({
                    id: "chats-page.broadcast-chat",
                    defaultMessage: "Broadcast chat",
                  })}

                {chat?.type === "muc" &&
                  chat.config?.admin_only_msg &&
                  chat.role === "admin" &&
                  " • "}

                {chat?.type === "muc" &&
                  (!chat?.config?.admin_only_msg || chat.role === "admin") &&
                  t(
                    {
                      id: "chats-page.members-count",
                      defaultMessage: "{membersCount} Members",
                    },
                    {
                      membersCount: chat.userCount || 0,
                    }
                  )}

                {chat?.type === "muc" &&
                  !chat.config?.admin_only_msg &&
                  !chat.deleted &&
                  isParticipant &&
                  ` • ${t({
                    id: "chats-page.tap-to-see-group-info",
                    defaultMessage: "Tap to see group info",
                  })}`}

                {chat?.type !== "muc" &&
                  t({
                    id: "chats-page.tap-to-see-profile-info",
                    defaultMessage: "Tap to see profile info",
                  })}
              </div>
            </>
          )}
        </div>
      </InteractiveContainer>

      {!isEntityConversation && !chat?.deleted && (
        <div className="flex items-center gap-4">
          {chat?.type === ChatType.chat && !chat.isSelf && (
            <>
              <HeaderButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setOpenedUserProfile(chat.id));
                }}
                icon={<UserIcon />}
              />
              {profile &&
                hasRoleAccessToModule(profile.role, AppModule.calls) && (
                  <HeaderButton
                    icon={<PhoneIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      call({ callType: "audio", userId: chat?.id || "" });
                    }}
                  />
                )}
            </>
          )}
          <HeaderButton
            onClick={(e) => {
              e.stopPropagation();
              handleToggleSearchMessageSidebar();
            }}
            icon={<SearchIcon />}
          />
          {chat && chat.type === ChatType.muc && isParticipant && (
            <GroupChatMoreMenu chat={chat} />
          )}
          {chat && chat.type === ChatType.chat && !chat.isSelf && (
            <PersonalChatMoreMenu chat={chat} />
          )}
        </div>
      )}
    </div>
  );
};
