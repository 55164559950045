import { useRestApiProvider } from "@jugl-web/rest-api";
import { WidgetChartType } from "@jugl-web/rest-api/dashboard/models/Widget";
import {
  Button,
  ButtonProps,
  PlainButton,
  Popover,
  PopoverProps,
} from "@jugl-web/ui-components";
import {
  assignRefs,
  cx,
  NonUndefined,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { forwardRef } from "react";
import { WIDGET_PER_DASHBOARD_LIMIT } from "../../consts";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import {
  hasDashboardReachedWidgetLimit,
  openNewDashboardDialog$,
  produceDefaultWidgetConfig,
} from "../../utils";
import { DashboardChartTypeList } from "../DashboardChartTypeList";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

interface AddChartButtonProps extends Omit<ButtonProps, "ref"> {
  popoverPlacement: NonUndefined<PopoverProps["placement"]>;
}

export const AddChartButton = forwardRef<
  HTMLButtonElement,
  AddChartButtonProps
>(({ popoverPlacement, ...props }, ref) => {
  const { entityId } = useEntitySelectedProvider();
  const { selectedDashboardId, detailedDashboard } = useDashboardContext();

  const { t } = useTranslations();
  const { toast } = useToast();

  const { dashboardApi } = useRestApiProvider();

  const [createWidget] = dashboardApi.useCreateWidgetMutation();

  const isWidgetCountLimitReached = detailedDashboard
    ? hasDashboardReachedWidgetLimit(detailedDashboard)
    : false;

  const produceChartName = (postfix: string) =>
    t(
      {
        id: "dashboard-page.chart-name-autogenerated",
        defaultMessage: "Chart {chartName}",
      },
      { chartName: postfix }
    );

  const autogenerateChartName = () => {
    if (!detailedDashboard) {
      return produceChartName("1");
    }

    let count = detailedDashboard.widgets.length + 1;

    const doesNameExist = (name: string) =>
      detailedDashboard.widgets.some((widget) => widget.title === name);

    while (doesNameExist(produceChartName(count.toString()))) {
      count += 1;
    }

    return produceChartName(count.toString());
  };

  const handleClick = async (chartType: WidgetChartType) => {
    if (!selectedDashboardId) {
      return;
    }

    const response = await createWidget({
      entityId,
      dashboardId: selectedDashboardId,
      attributes: {
        title: autogenerateChartName(),
        config: produceDefaultWidgetConfig(chartType),
      },
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.widget-created",
          defaultMessage: "Widget has been created",
        })
      );
    }
  };

  if (isWidgetCountLimitReached) {
    return (
      <Popover
        placement="bottom"
        hasArrow
        className="bg-dark/80 font-secondary w-[250px] rounded-lg px-4 py-3 text-sm leading-3 text-white shadow-none"
        arrowClassName="fill-dark/80"
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            ref={assignRefs([ref, triggerRef])}
            as={Button}
            color="grey"
            className={cx(
              "font-secondary h-10 whitespace-nowrap px-8 font-medium",
              isOpen ? "bg-[#D6DDE1]" : "bg-[#E1E6EB] hover:bg-[#D6DDE1]"
            )}
            {...props}
          >
            {t(
              {
                id: "dashboard-page.chart-limit-reached-button-label",
                defaultMessage: "⚠️ {count}/{limit} Charts created",
              },
              {
                count: detailedDashboard ? detailedDashboard.widgets.length : 0,
                limit: WIDGET_PER_DASHBOARD_LIMIT,
              }
            )}
          </Trigger>
        )}
      >
        {({ onClose: onClosePopover }) => (
          <>
            <div className="mb-2.5">
              {t(
                {
                  id: "dashboard-page.chart-limit-reached-message-first-line",
                  defaultMessage:
                    "Dashboard already contains {count} out of {limit} Charts. To add new Charts, delete existing ones first ✅",
                },
                {
                  count: detailedDashboard
                    ? detailedDashboard.widgets.length
                    : 0,
                  limit: WIDGET_PER_DASHBOARD_LIMIT,
                }
              )}
            </div>
            <div>
              {t(
                {
                  id: "dashboard-page.chart-limit-reached-message-second-line",
                  defaultMessage:
                    "As an option, you can <link>Create a new Dashboard</link> ✨",
                },
                {
                  link: (chunks: (JSX.Element | string)[]) => (
                    <PlainButton
                      className="text-primary-200 font-secondary inline text-sm font-bold underline"
                      onClick={() => {
                        onClosePopover();
                        openNewDashboardDialog$.next();
                      }}
                    >
                      {chunks}
                    </PlainButton>
                  ),
                }
              )}
            </div>
          </>
        )}
      </Popover>
    );
  }

  return (
    <Popover
      placement={popoverPlacement}
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={assignRefs([ref, triggerRef])}
          as={Button}
          className="font-secondary h-10 whitespace-nowrap px-8 font-medium"
          iconEnd={<PlusIcon />}
          {...props}
        >
          {t({
            id: "dashboard-page.add-chart",
            defaultMessage: "Add Chart",
          })}
        </Trigger>
      )}
      className="border-dark-100 w-[336px] rounded-xl border border-solid p-4"
      style={{ boxShadow: "0px 24px 24px 0px rgba(53, 54, 56, 0.10)" }}
    >
      {({ onClose }) => (
        <div className="flex flex-col gap-2">
          <span className="text-dark font-secondary text-sm font-medium leading-[21px]">
            {t({
              id: "dashboard-page.select-chart-type",
              defaultMessage: "Select Chart type",
            }).concat(":")}
          </span>
          <DashboardChartTypeList
            onSelect={(chartType) => {
              onClose();
              handleClick(chartType);
            }}
          />
        </div>
      )}
    </Popover>
  );
});
