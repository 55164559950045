import { transformPreviewDashboardDtoToModel } from "../PreviewDashboard";
import { transformWidgetDtoToModel } from "../Widget";
import { DetailedDashboardDto } from "./DetailedDashboardDto";
import { DetailedDashboardModel } from "./DetailedDashboardModel";

export const transformDetailedDashboardDtoToModel = (
  dto: DetailedDashboardDto
): DetailedDashboardModel => ({
  ...transformPreviewDashboardDtoToModel(dto),
  widgets: dto.widgets.map(transformWidgetDtoToModel),
});
