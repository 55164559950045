import { Button, Text } from "@jugl-web/ui-components/cross-platform";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { forwardRef } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import mime from "mime";
import Lottie from "react-lottie";
import animationData from "../../assets/loading-animation.json";
import { FilePreviewConfig, FilePreviewType } from "../../types";
import { ReactComponent as FileIcon } from "./assets/file.svg";
import { ReactComponent as UnknownFileIcon } from "./assets/unknown-file-icon.svg";
import "./index.css";

const UNKNOWN_FILE_EXTENSION = "bin";

interface MediaPanelProps {
  config: FilePreviewConfig;
  previewType: FilePreviewType;
  isLoading: boolean;
  hasError: boolean;
  inMultipleFilesMode?: boolean;
  onLoad: () => void;
  onError: () => void;
  onClose: () => void;
  onDownload?: () => void;
}

export const MediaPanel = forwardRef<HTMLDivElement, MediaPanelProps>(
  (
    {
      config,
      previewType,
      isLoading,
      hasError,
      inMultipleFilesMode,
      onLoad,
      onError,
      onDownload,
      onClose,
    },
    ref
  ) => {
    const { isMobile } = useAppVariant();
    const { t } = useTranslations();
    const fileExtension = mime.getExtension(config.mimeType);

    const docPreviewFileTypes = [
      "odt",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "xls",
      "xlsx",
      "csv",
      "txt",
      "pdf",
    ];

    if (fileExtension && docPreviewFileTypes.includes(fileExtension)) {
      return (
        <div className="flex justify-center">
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: config.url,
                fileType: config.name.split(".").at(-1),
              },
            ]}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false,
              },
            }}
            className="h-full"
            style={{
              height: inMultipleFilesMode
                ? "calc(100vh * 0.88 - 198px)"
                : `calc(100vh - ${isMobile ? "80px" : "150px"})`,
              overflow: "scroll",
              maxWidth: isMobile ? "95%" : "80%",
            }}
          />
          <div
            ref={ref}
            className="flex items-center justify-center overflow-hidden"
            style={{
              height: inMultipleFilesMode
                ? "calc(100vh * 0.88 - 198px)"
                : `calc(100vh - ${isMobile ? "44px" : "120px"})`,
            }}
            onClick={onClose}
          >
            {" "}
          </div>
        </div>
      );
    }
    return (
      <div
        ref={ref}
        className="flex items-center justify-center overflow-hidden"
        style={{
          height: inMultipleFilesMode
            ? "calc(100vh * 0.88 - 198px)"
            : `calc(100vh - ${isMobile ? "44px" : "120px"})`,
        }}
        onClick={onClose}
      >
        <div
          className={cx(
            "relative flex h-[90%] items-center justify-center overflow-hidden",
            isMobile ? "w-[95%]" : "w-[80%]"
          )}
        >
          {!hasError && (
            <>
              {previewType === "image" && (
                <>
                  <div
                    className={cx(
                      "absolute top-1/2 left-1/2 z-0 -translate-x-1/2 -translate-y-1/2 transition-opacity",
                      isLoading ? "opacity-100" : "opacity-0"
                    )}
                  >
                    <Lottie
                      options={{ animationData }}
                      height={70}
                      width={70}
                    />
                  </div>
                  <img
                    src={config.url}
                    alt={config.name}
                    className="relative z-10 max-h-full max-w-full select-none rounded-lg"
                    onClick={(event) => event.stopPropagation()}
                    onContextMenu={
                      !onDownload
                        ? (event) => event.preventDefault()
                        : undefined
                    }
                    onLoad={onLoad}
                    onError={onError}
                  />
                </>
              )}
              {previewType === "video" && (
                <video
                  className={cx(
                    "max-h-full max-w-full rounded-lg",
                    isMobile ? "w-full" : "min-h-[300px] min-w-[400px]"
                  )}
                  autoPlay
                  controls
                  onContextMenu={
                    !onDownload ? (event) => event.preventDefault() : undefined
                  }
                  controlsList={!onDownload ? "nodownload" : undefined}
                  playsInline
                  onClick={(event) => event.stopPropagation()}
                  onCanPlay={onLoad}
                  onError={onError}
                >
                  <source src={config.url} />
                </video>
              )}
              {previewType === "audio" && (
                <audio
                  autoPlay
                  controls
                  onContextMenu={
                    !onDownload ? (event) => event.preventDefault() : undefined
                  }
                  controlsList={!onDownload ? "nodownload" : undefined}
                  onClick={(event) => event.stopPropagation()}
                  onCanPlay={onLoad}
                  onError={onError}
                >
                  <source src={config.url} type={config.mimeType} />
                </audio>
              )}
            </>
          )}
          {(previewType === "other" || hasError) && (
            <div
              className="flex flex-col items-center p-12"
              onClick={(event) => event.stopPropagation()}
            >
              <span className="relative">
                <FileIcon />
                <Text
                  variant="body3"
                  className="text-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-medium"
                >
                  {fileExtension === UNKNOWN_FILE_EXTENSION ? (
                    <UnknownFileIcon />
                  ) : (
                    fileExtension
                  )}
                </Text>
              </span>
              <span
                className={cx(
                  "font-secondary mt-6 overflow-hidden text-ellipsis text-center font-bold text-white",
                  isMobile ? "text-lg leading-5" : "text-2xl leading-7"
                )}
              >
                {config.name}
              </span>
              <span
                className={cx(
                  "mt-2 mb-10 font-normal leading-4 text-white",
                  isMobile ? "text-sm" : "text-base"
                )}
              >
                {t({
                  id: "file-preview-component.preview-not-available",
                  defaultMessage: "Preview is not available",
                })}
              </span>
              {onDownload && (
                <Button
                  color="primary"
                  variant="contained"
                  className={cx(isMobile ? "h-10 w-[250px]" : "h-12 w-[300px]")}
                  onClick={onDownload}
                >
                  {t({
                    id: "common.download-file",
                    defaultMessage: "Download file",
                  })}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
