import React, { useEffect, useRef } from "react";
import {
  assignRefs,
  cx,
  useIsFocused,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { ReactComponent as CancelIcon } from "./icons/cancel.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { PlainButton, Popover } from "../../cross-platform";

export type ExpandableSearchBarSize = "md" | "lg";

export interface ExpandableSearchBarProps {
  color: "white" | "grey";
  size: ExpandableSearchBarSize;
  isAlwaysExpanded?: boolean;
  defaultValue?: string;
  hasSettingsIndicator?: boolean;
  settingsPopover?: React.ReactNode;
  expandedClassName?: string;
  className?: string;
  onSearch: (searchQuery: string) => void;
  onReset?: () => void;
  onExpand?: (isOpen: boolean) => void;
}

const sizeToClasses: Record<ExpandableSearchBarSize, string> = {
  md: "h-8 w-8",
  lg: "h-10 w-10",
};

const sizeToIconClasses: Record<ExpandableSearchBarSize, string> = {
  md: "h-4 w-4",
  lg: "h-[18px] w-[18px]",
};

export const ExpandableSearchBar: React.FC<ExpandableSearchBarProps> = ({
  color,
  size,
  defaultValue,
  isAlwaysExpanded,
  settingsPopover,
  hasSettingsIndicator,
  expandedClassName,
  className,
  onSearch,
  onReset,
  onExpand,
}) => {
  const { hasInputValue, inputProps, reset } = useSearchInput({
    defaultValue,
    onSearch,
    onReset,
  });
  const { t } = useTranslations();
  const { isFocused, focusProps } = useIsFocused();
  const inputRef = useRef<HTMLInputElement>(null);

  const isSearchBarExpanded = isAlwaysExpanded || hasInputValue || isFocused;
  useEffect(() => {
    onExpand?.(isSearchBarExpanded);
  }, [isSearchBarExpanded, onExpand]);
  return (
    <div
      className={cx(
        "jugl__focusable-outline relative w-max rounded-lg transition-colors",

        color === "grey" &&
          (isSearchBarExpanded ? "bg-grey-200" : "hover:bg-grey-200"),
        color === "white" &&
          (isSearchBarExpanded ? "bg-white" : "hover:bg-white"),
        className
      )}
      {...focusProps}
    >
      <SearchIcon
        className={cx(
          "pointer-events-none absolute top-1/2 -translate-y-1/2 transition-all",
          isSearchBarExpanded ? "left-4" : "left-1/2 -translate-x-1/2",
          sizeToIconClasses[size]
        )}
      />
      <input
        type="text"
        ref={inputRef}
        placeholder={t({
          id: "common.search-with-ellipsis",
          defaultMessage: "Search...",
        })}
        className={cx(
          "placeholder:text-grey border-none bg-transparent font-[Roboto] text-base font-medium leading-3 text-[#333333] outline-none transition-all placeholder:font-[Roboto] placeholder:font-normal placeholder:opacity-100",
          sizeToClasses[size],
          isSearchBarExpanded
            ? cx("w-[300px] pl-[42px] pr-14 opacity-100", expandedClassName)
            : "cursor-pointer opacity-0",
          isSearchBarExpanded && settingsPopover && "pr-[98px]"
        )}
        {...inputProps}
      />
      <div
        className={cx(
          "absolute top-1/2 right-4 flex -translate-y-1/2 items-center gap-2.5 opacity-100 transition-opacity",
          !isSearchBarExpanded && "pointer-events-none opacity-0"
        )}
      >
        {settingsPopover && (
          <Popover
            renderTrigger={({ Trigger, triggerRef }) => (
              <Tooltip
                renderTrigger={({ ref, props }) => (
                  <Trigger
                    ref={assignRefs([ref, triggerRef])}
                    as={PlainButton}
                    className="hover:bg-grey-200 relative flex h-8 w-8 items-center justify-center rounded-[4px] outline-none transition-colors"
                    {...props}
                  >
                    <SettingsIcon className={sizeToIconClasses[size]} />
                    {hasSettingsIndicator && (
                      <div className="bg-gradients-danger absolute top-[7px] right-[7px] h-2 w-2 rounded-full border border-solid border-white" />
                    )}
                  </Trigger>
                )}
              >
                {t({
                  id: "expandable-search-bar-component.search-settings",
                  defaultMessage: "Search Settings",
                })}
              </Tooltip>
            )}
          >
            {settingsPopover}
          </Popover>
        )}
        {hasInputValue && (
          <Tooltip
            renderTrigger={({ ref, props }) => (
              <PlainButton
                onClick={(e) => {
                  e.currentTarget.blur();
                  reset();
                }}
                className="hover:bg-grey-200 animate-fade-in flex h-8 w-8 items-center justify-center rounded-[4px] outline-none"
                ref={ref}
                {...props}
              >
                <CancelIcon className={sizeToIconClasses[size]} />
              </PlainButton>
            )}
          >
            {isAlwaysExpanded
              ? t({
                  id: "expandable-search-bar-component.clear-search",
                  defaultMessage: "Clear Search",
                })
              : t({
                  id: "expandable-search-bar-component.quit-search",
                  defaultMessage: "Quit Search",
                })}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
