import { FeedbackBox } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { TaskCommentsFab } from "./components/TaskCommentsFab";
import { TaskDetailsCommentsSidebar } from "./components/TaskDetailsCommentsSidebar/TaskDetailsCommentsSidebar";
import { TaskDetailsGeneralInfo } from "./components/TaskDetailsGeneralInfo";
import { TaskDetailsLoadingSkeleton } from "./components/TaskDetailsLoadingSkeleton";
import { TaskDetailsTabs } from "./components/TaskDetailsTabs";
import { TaskDetailsTopBar } from "./components/TaskDetailsTopBar";
import { MAIN_SECTION_SCROLLABLE_CONTAINER_ID } from "./consts";
import { useTaskDetailsContext } from "./hooks/useTaskDetailsContext";
import { TaskDetailsProvider } from "./TaskDetailsProvider";

const InnerTaskDetails = () => {
  const { task, isError, isNotFoundError, navigateBackToTaskList } =
    useTaskDetailsContext();

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const { t } = useTranslations();

  return (
    <div className="jugl__border-box-component bg-grey-200 flex h-full flex-col overflow-x-hidden">
      <TaskDetailsTopBar />
      {isError ? (
        <div className="mx-auto flex flex-1 items-center">
          {isNotFoundError ? (
            <FeedbackBox
              iconType="network-error"
              title={t({
                id: "tasks-page.task-not-found",
                defaultMessage: "Task not found",
              })}
              subtitle={t({
                id: "tasks-page.task-access-error",
                defaultMessage:
                  "It may have been deleted or you don't have access to it",
              })}
              actionButton={{
                label: t({
                  id: "common.go-back",
                  defaultMessage: "Go back",
                }),
                onClick: navigateBackToTaskList,
              }}
            />
          ) : (
            <FeedbackBox
              iconType="network-error"
              title={t({
                id: "feedback.network-error",
                defaultMessage: "Network error",
              })}
              subtitle={t(
                {
                  id: "feedback.inconvenience-occurred-try-again-later",
                  defaultMessage:
                    "We're sorry for the inconvenience.{br}Please try again later",
                },
                {
                  br: <br />,
                }
              )}
              actionButton={{
                label: t({
                  id: "common.refresh-page",
                  defaultMessage: "Refresh page",
                }),
                onClick: () => window.location.reload(),
              }}
            />
          )}
        </div>
      ) : (
        <main
          className="flex pt-8 pl-8"
          style={{ height: "calc(100vh - 108px)" }}
        >
          <section className="relative mr-8 min-w-[600px] flex-1 rounded-t-3xl bg-white">
            {task ? (
              <div
                id={MAIN_SECTION_SCROLLABLE_CONTAINER_ID}
                className="animate-fade-in h-full overflow-y-auto px-8 pt-10 pb-[100px]"
              >
                <TaskDetailsGeneralInfo />
                <TaskDetailsTabs />
              </div>
            ) : (
              <TaskDetailsLoadingSkeleton />
            )}
            <TaskCommentsFab
              isVisible={!isSidebarExpanded}
              hasUnreadItems={!!(task?.cmnt_unread || task?.feedback_unread)}
              className="absolute -right-4 top-[110px]"
              onClick={() => setIsSidebarExpanded((s) => !s)}
            />
          </section>
          <TaskDetailsCommentsSidebar
            isExpanded={isSidebarExpanded}
            onCollapse={() => setIsSidebarExpanded(false)}
          />
        </main>
      )}
    </div>
  );
};

export const TaskDetails = () => {
  const { taskId } = useParams<{ taskId: string; customerId: string }>();

  if (!taskId) {
    throw new Error("Task ID is required to render TaskDetails page");
  }

  return (
    <TaskDetailsProvider taskId={taskId}>
      <InnerTaskDetails />
    </TaskDetailsProvider>
  );
};
