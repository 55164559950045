import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface ChatMessagesWindowContextType {
  highlightedMessageId: string | undefined;
  highlightMessage: (messageId: string) => void;
}

const ChatMessagesWindowContext = createContext<
  ChatMessagesWindowContextType | undefined
>(undefined);

export const useChatMessagesWindow = () => {
  const context = useContext(ChatMessagesWindowContext);
  if (!context) {
    throw new Error(
      "useChatMessagesWindow must be used within a ChatMessagesWindowProvider"
    );
  }
  return context;
};

interface ChatMessagesWindowProviderProps {
  children: ReactNode;
}

export const ChatMessagesWindowProvider = ({
  children,
}: ChatMessagesWindowProviderProps) => {
  const [highlightedMessageId, setHighlightedMessageId] = useState<
    string | undefined
  >();
  const [highlightTimeoutId, setHighlightTimeoutId] = useState<
    NodeJS.Timeout | undefined
  >();

  const highlightMessage = useCallback(
    (messageId: string) => {
      setHighlightedMessageId(messageId);

      // Clear previous timeout if exists
      if (highlightTimeoutId) {
        clearTimeout(highlightTimeoutId);
      }

      // Set new timeout to clear highlight after 3 seconds
      const timeoutId = setTimeout(() => {
        setHighlightedMessageId(undefined);
      }, 3000);

      setHighlightTimeoutId(timeoutId);
    },
    [highlightTimeoutId]
  );

  const value = useMemo(
    () => ({
      highlightedMessageId,
      highlightMessage,
    }),
    [highlightMessage, highlightedMessageId]
  );

  return (
    <ChatMessagesWindowContext.Provider value={value}>
      {children}
    </ChatMessagesWindowContext.Provider>
  );
};
