import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { UsersAvatarStack } from "@jugl-web/domain-resources/users/components/UsersAvatarStack";
import { PreviewDashboardModel } from "@jugl-web/rest-api/dashboard/models/PreviewDashboard";
import {
  Button,
  EmptyState,
  ListBoxItemComponent,
  ListBoxItemComponentProps,
  Menu,
  PlainButton,
  Popover,
  Tooltip,
} from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { HeaderBreadcrumbsItemContainer } from "@jugl-web/ui-components/web/HeaderBreadcrumbs/components/HeaderBreadcrumbsItemContainer";
import { HeaderBreadcrumbsItemContent } from "@jugl-web/ui-components/web/HeaderBreadcrumbs/components/HeaderBreadcrumbsItemContent";
import {
  assignRefs,
  cx,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import { useSpotlightTourContext } from "@jugl-web/utils/hooks/useSpotlight";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { DashboardSpotlightTourStep } from "../../consts";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { DeleteDashboardConfirmationDialog } from "../DeleteDashboardConfirmationDialog";
import { ManageDashboardDialog } from "../ManageDashboardDialog";
import { ReactComponent as BinIcon } from "./assets/bin.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { ReactComponent as ChevronBottomIcon } from "./assets/chevron-bottom.svg";
import { ReactComponent as DashboardIcon } from "./assets/dashboard.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

interface DashboardListItemProps
  extends Pick<
    ListBoxItemComponentProps,
    "isSelected" | "highlightedText" | "onClick"
  > {
  dashboard: PreviewDashboardModel;
  onEdit: () => void;
  onDelete: () => void;
}

const DashboardListItem: FC<DashboardListItemProps> = ({
  dashboard,
  isSelected,
  highlightedText,
  onClick,
  onEdit,
  onDelete,
}) => {
  const { entityId } = useEntitySelectedProvider();
  const { isDashboardOwner } = useDashboardContext();

  const { t } = useTranslations();

  const canManageDashboard = useMemo(
    () => isDashboardOwner(dashboard),
    [dashboard, isDashboardOwner]
  );

  const dashboardOwnerId = useMemo(
    () => dashboard.users.find((user) => user.role === "owner")?.user_id,
    [dashboard.users]
  );

  return (
    <ListBoxItemComponent
      label={
        <span className="font-secondary text-sm -tracking-[0.14px]">
          {highlightedText ? (
            <Highlighter
              autoEscape
              highlightClassName="text-primary font-medium"
              highlightTag="span"
              textToHighlight={dashboard.name}
              searchWords={[highlightedText]}
            />
          ) : (
            dashboard.name
          )}
        </span>
      }
      isInteractive
      height={56}
      secondaryText={
        dashboardOwnerId && !canManageDashboard ? (
          <UserGeneralProfile entityId={entityId} userId={dashboardOwnerId}>
            {({ safeProfile }) => (
              <span className="font-secondary text-[#828282]">
                {safeProfile.displayName}
              </span>
            )}
          </UserGeneralProfile>
        ) : undefined
      }
      startSlot={
        <DashboardIcon
          className={cx(!isSelected && "[&>path]:fill-grey-500")}
        />
      }
      endSlot={
        <div className="flex items-center gap-2">
          {isSelected && <CheckIcon />}
          {canManageDashboard && (
            <Menu
              placement="right"
              sections={[
                [
                  {
                    id: "edit",
                    icon: <EditIcon />,
                    label: t({
                      id: "dashboard-page.edit-dashboard",
                      defaultMessage: "Edit Dashboard",
                    }),
                    onSelect: (_, close) => {
                      onEdit();
                      close();
                    },
                  },
                  {
                    id: "delete",
                    icon: <BinIcon />,
                    label: t({
                      id: "dashboard-page.delete-dashboard",
                      defaultMessage: "Delete Dashboard",
                    }),
                    onSelect: (_, close) => {
                      onDelete();
                      close();
                    },
                  },
                ],
              ]}
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={PlainButton}
                  className={cx(
                    "flex h-8 w-8 items-center justify-center rounded-md transition",
                    isSelected ? "hover:bg-white" : "hover:bg-grey-200",
                    isOpen && (isSelected ? "bg-white" : "bg-grey-200")
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <MoreIcon />
                </Trigger>
              )}
              className="z-[110]"
            />
          )}
        </div>
      }
      className="rounded-2xl"
      isSelected={isSelected}
      onClick={onClick}
    />
  );
};

interface InteractiveDashboardBreadcrumbItemProps {
  onOpenNewDashboardDialog: () => void;
}

export const InteractiveDashboardBreadcrumbItem: FC<
  InteractiveDashboardBreadcrumbItemProps
> = ({ onOpenNewDashboardDialog }) => {
  const {
    dashboards,
    areDashboardsLoading,
    selectedDashboardId,
    setSelectedDashboardId,
    isDashboardOwner,
  } = useDashboardContext();

  const spotlightTourContext =
    useSpotlightTourContext<DashboardSpotlightTourStep>();

  const { entityId } = useEntitySelectedProvider();
  const { id: meId } = useMe();

  const [editDashboardDialogState, setEditDashboardDialogState] = useState<{
    isOpen: boolean;
    dashboard?: PreviewDashboardModel;
  }>({ isOpen: false });

  const [deleteDashboardDialogState, setDeleteDashboardDialogState] = useState<{
    isOpen: boolean;
    dashboard?: PreviewDashboardModel;
  }>({ isOpen: false });

  const { searchQuery, inputProps, reset: resetSearchInput } = useSearchInput();

  const { t } = useTranslations();

  const matchingDashboard = useMemo(() => {
    if (!selectedDashboardId) {
      return undefined;
    }

    return dashboards.find((dashboard) => dashboard.id === selectedDashboardId);
  }, [dashboards, selectedDashboardId]);

  const dashboardMemberIds = useMemo(() => {
    if (!matchingDashboard) {
      return [];
    }

    return matchingDashboard.users.map((user) => user.user_id);
  }, [matchingDashboard]);

  const sortedDashboards = useMemo(
    () =>
      [...dashboards].sort((dashboardA, dashboardB) =>
        dashboardA.name.localeCompare(dashboardB.name)
      ),
    [dashboards]
  );

  const [visibleMyDashboards, visibleOtherDashboards] = useMemo(() => {
    const my: PreviewDashboardModel[] = [];
    const other: PreviewDashboardModel[] = [];

    sortedDashboards.forEach((dashboard) => {
      const matchesSearchQuery = dashboard.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (!matchesSearchQuery) {
        return;
      }

      if (isDashboardOwner(dashboard)) {
        my.push(dashboard);
      } else {
        other.push(dashboard);
      }
    });

    return [my, other];
  }, [sortedDashboards, isDashboardOwner, searchQuery]);

  const isEmpty =
    visibleMyDashboards.length === 0 && visibleOtherDashboards.length === 0;

  const getDashboardListItemProps = (
    dashboard: PreviewDashboardModel,
    closePopover: () => void
  ): DashboardListItemProps => ({
    dashboard,
    isSelected: dashboard.id === selectedDashboardId,
    highlightedText: searchQuery,
    onClick: () => {
      setSelectedDashboardId(dashboard.id);
      closePopover();
    },
    onEdit: () => {
      setEditDashboardDialogState({ isOpen: true, dashboard });
      closePopover();
    },
    onDelete: () => {
      setDeleteDashboardDialogState({ isOpen: true, dashboard });
      closePopover();
    },
  });

  if (areDashboardsLoading) {
    return (
      <div className="h-6 w-[150px] animate-pulse rounded-full bg-gray-200" />
    );
  }

  return (
    <>
      <Popover
        placement="bottom-start"
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <SpotlightTooltip
            isOpen={spotlightTourContext.isStepActive(
              DashboardSpotlightTourStep.switchOrCreateDashboard
            )}
            placement="bottom"
            className="w-[282px]"
            onDismiss={() =>
              spotlightTourContext.markStepAsSeen(
                DashboardSpotlightTourStep.switchOrCreateDashboard
              )
            }
            tip={t({
              id: "dashboard-page.tap-to-switch-or-create-board-hint",
              defaultMessage:
                "Tap here to switch between Dashboards or create a new one ✨",
            })}
            showButton
            renderTrigger={({ ref: tooltipRef, props: tooltipProps }) => (
              <Trigger
                ref={assignRefs([triggerRef, tooltipRef])}
                as={HeaderBreadcrumbsItemContainer}
                isBold
                className={cx(
                  "-mx-3 flex items-center rounded-lg px-3 py-1.5 transition-colors",
                  isOpen ? "bg-grey-200" : "hover:bg-grey-200"
                )}
                {...tooltipProps}
              >
                <HeaderBreadcrumbsItemContent>
                  <DashboardIcon />
                  <span>
                    {matchingDashboard
                      ? matchingDashboard.name
                      : t({
                          id: "dashboard-page.unknown-dashboard",
                          defaultMessage: "Unknown Dashboard",
                        })}
                  </span>
                  {matchingDashboard?.type === "private" && (
                    <UsersAvatarStack
                      entityId={entityId}
                      userIds={dashboardMemberIds}
                      maxCount={5}
                      size="sm"
                    />
                  )}
                  <ChevronBottomIcon
                    className={cx(
                      "transition-transform",
                      isOpen && "rotate-180"
                    )}
                  />
                </HeaderBreadcrumbsItemContent>
              </Trigger>
            )}
          />
        )}
        onUnmount={resetSearchInput}
        className="w-[440px] rounded-xl px-4 pt-4 pb-6"
        style={{ boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.16)" }}
      >
        {({ onClose }) => (
          <div className="flex flex-col">
            <div className="flex items-center gap-4">
              <SearchInput
                autoFocus
                variant="filled"
                color="grey"
                containerClassName="w-full"
                onClear={resetSearchInput}
                {...inputProps}
              />
              <Tooltip
                placement="bottom"
                renderTrigger={({ ref, props }) => (
                  <Button
                    ref={ref}
                    className="flex h-10 w-10 shrink-0 items-center justify-center rounded-[10px] p-0"
                    iconEnd={<PlusIcon />}
                    onClick={onOpenNewDashboardDialog}
                    {...props}
                  />
                )}
              >
                {t({
                  id: "dashboard-page.create-new-dashboard",
                  defaultMessage: "Create new Dashboard",
                })}
              </Tooltip>
            </div>
            <div className="jugl__custom-scrollbar -mr-2 mt-6 flex max-h-[420px] flex-col gap-8 overflow-y-auto pr-2">
              {isEmpty ? (
                <EmptyState />
              ) : (
                <>
                  {visibleMyDashboards.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <span className="text-dark font-secondary text-sm font-medium -tracking-[0.14px]">
                        {t({
                          id: "dashboard-page.my-dashboards",
                          defaultMessage: "My Dashboards",
                        })}
                      </span>
                      <div className="flex flex-col gap-2">
                        {visibleMyDashboards.map((dashboard) => (
                          <DashboardListItem
                            key={dashboard.id}
                            {...getDashboardListItemProps(dashboard, onClose)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-2">
                    <span className="text-dark font-secondary text-sm font-medium -tracking-[0.14px]">
                      {t({
                        id: "dashboard-page.other-dashboards",
                        defaultMessage: "Other Dashboards",
                      })}
                    </span>
                    {visibleOtherDashboards.length > 0 ? (
                      <div className="flex flex-col gap-2">
                        {visibleOtherDashboards.map((dashboard) => (
                          <DashboardListItem
                            key={dashboard.id}
                            {...getDashboardListItemProps(dashboard, onClose)}
                          />
                        ))}
                      </div>
                    ) : (
                      <span className="font-secondary text-sm text-[#828282]">
                        {t({
                          id: "dashboard-page.other-dashboards-description",
                          defaultMessage:
                            "Here, you will see the list of Dashboards that were shared with you by other users",
                        })}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Popover>
      <ManageDashboardDialog
        entityId={entityId}
        meId={meId || ""}
        isOpen={editDashboardDialogState.isOpen}
        editingDashboard={editDashboardDialogState.dashboard}
        onClose={() =>
          setEditDashboardDialogState((previousState) => ({
            ...previousState,
            isOpen: false,
          }))
        }
      />
      <DeleteDashboardConfirmationDialog
        isOpen={deleteDashboardDialogState.isOpen}
        dashboard={deleteDashboardDialogState.dashboard}
        onClose={() =>
          setDeleteDashboardDialogState((previousState) => ({
            ...previousState,
            isOpen: false,
          }))
        }
      />
    </>
  );
};
