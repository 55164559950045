import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedDashboardModel } from "@jugl-web/rest-api/dashboard/models/DetailedDashboard";
import { ExpectedDashboardLayoutConfig } from "@jugl-web/rest-api/dashboard/models/PreviewDashboard";
import { WidgetModel } from "@jugl-web/rest-api/dashboard/models/Widget";
import { cx } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import isEqual from "lodash/isEqual";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import GridLayout, { Layout, WidthProvider } from "react-grid-layout";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { WidgetDialogState } from "../../types";
import { findFirstAvailableLayoutPosition } from "../../utils";
import { DashboardWidget } from "../DashboardWidget";
import { DashboardWidgetDialog } from "../DashboardWidgetDialog";
import { DashboardWidgetHandle } from "../DashboardWidgetHandle";

import "react-grid-layout/css/styles.css";
import "./DashboardWidgetsGrid.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-resizable/css/styles.css";

interface DashboardWidgetsGridProps {
  dashboard: DetailedDashboardModel;
  visibleWidgets: WidgetModel[];
}

const WidthAwareGridLayout = WidthProvider(GridLayout);

const GRID_COLS = 4;
const MAX_WIDGET_WIDTH_IN_GRID_UNITS = 4;
const MAX_WIDGET_HEIGHT_IN_GRID_UNITS = 3;
const INITIAL_WIDGET_SIZE_IN_GRID_UNITS = 2;

const SCROLL_THRESHOLD = 200;
const SCROLL_SPEED = 10;

const produceLayoutEntry = (
  widgetId: string,
  attributes: ExpectedDashboardLayoutConfig[string]
) => ({
  i: widgetId,
  x: attributes.x,
  y: attributes.y,
  w: attributes.w,
  h: attributes.h,
  maxW: MAX_WIDGET_WIDTH_IN_GRID_UNITS,
  maxH: MAX_WIDGET_HEIGHT_IN_GRID_UNITS,
});

export const DashboardWidgetsGrid: FC<DashboardWidgetsGridProps> = ({
  dashboard,
  visibleWidgets,
}) => {
  const [widgetDialogState, setWidgetDialogState] = useState<WidgetDialogState>(
    { isOpen: false, widgetId: null }
  );

  const [draggingItemId, setDraggingItemId] = useState<string | null>(null);
  const [resizingItemId, setResizingItemId] = useState<string | null>(null);

  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, isDashboardOwner } = useDashboardContext();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { dashboardApi } = useRestApiProvider();
  const [updateDashboard] = dashboardApi.useUpdateDashboardMutation();

  const isResizing = !!resizingItemId;
  const hasSearchQuery = searchQuery.trim().length > 0;

  const canManageWidget = useMemo(
    () => isDashboardOwner(dashboard),
    [dashboard, isDashboardOwner]
  );

  const isLayoutFrozen = hasSearchQuery || !canManageWidget;

  const layout = useMemo<Layout[]>(() => {
    const layoutEntries: Layout[] = [];

    visibleWidgets.forEach((widget) => {
      const existingLayoutConfigEntry = dashboard.layout_config[widget.id];

      if (existingLayoutConfigEntry) {
        layoutEntries.push(
          produceLayoutEntry(widget.id, existingLayoutConfigEntry)
        );

        return;
      }

      const firstAvailablePosition = findFirstAvailableLayoutPosition({
        layoutEntries,
        gridColumns: GRID_COLS,
        widgetWidth: INITIAL_WIDGET_SIZE_IN_GRID_UNITS,
        widgetHeight: INITIAL_WIDGET_SIZE_IN_GRID_UNITS,
      });

      layoutEntries.push(
        produceLayoutEntry(widget.id, {
          x: firstAvailablePosition.x,
          y: firstAvailablePosition.y,
          w: INITIAL_WIDGET_SIZE_IN_GRID_UNITS,
          h: INITIAL_WIDGET_SIZE_IN_GRID_UNITS,
        })
      );
    });

    return layoutEntries;
  }, [dashboard.layout_config, visibleWidgets]);

  const memoizedGridLayoutDOM = useMemo(
    () =>
      visibleWidgets.map((widget) => (
        <DashboardWidget
          key={widget.id}
          widget={widget}
          isDragging={draggingItemId === widget.id}
          canManageWidget={canManageWidget}
          isLayoutFrozen={isLayoutFrozen}
          onOpenWidgetDialog={(afterOpenAction) =>
            setWidgetDialogState({
              isOpen: true,
              widgetId: widget.id,
              afterOpenAction,
            })
          }
        />
      )),
    [canManageWidget, draggingItemId, isLayoutFrozen, visibleWidgets]
  );

  const normalizeLayout = (layoutToNormalize: Layout[]) =>
    layoutToNormalize.reduce<ExpectedDashboardLayoutConfig>((acc, item) => {
      acc[item.i] = { x: item.x, y: item.y, w: item.w, h: item.h };
      return acc;
    }, {});

  const handleLayoutChange = (newLayout: Layout[]) => {
    if (isLayoutFrozen) {
      return;
    }

    const normalizedLayout = normalizeLayout(newLayout);

    const hasLayoutChanged = !isEqual(
      dashboard.layout_config,
      normalizedLayout
    );

    if (!hasLayoutChanged) {
      return;
    }

    updateDashboard({
      entityId,
      dashboardId: dashboard.id,
      attributes: { layout_config: normalizedLayout },
    });
  };

  // Implements auto-scroll behavior when dragging or resizing widgets
  // as it seems broken or not supported by the `react-grid-layout` library
  const handleDragOrResize = useCallback<GridLayout.ItemCallback>(
    (_layout, _oldItem, _newItem, _placeholder, event) => {
      if (!containerRef.current) {
        return;
      }

      const { clientX, clientY } = event;
      const containerRect = containerRef.current.getBoundingClientRect();

      if (clientY - containerRect.top < SCROLL_THRESHOLD) {
        containerRef.current.scrollBy({ top: -SCROLL_SPEED });
      } else if (containerRect.bottom - clientY < SCROLL_THRESHOLD) {
        containerRef.current.scrollBy({ top: SCROLL_SPEED });
      }

      if (clientX - containerRect.left < SCROLL_THRESHOLD) {
        containerRef.current.scrollBy({ left: -SCROLL_SPEED });
      } else if (containerRect.right - clientX < SCROLL_THRESHOLD) {
        containerRef.current.scrollBy({ left: SCROLL_SPEED });
      }
    },
    []
  );

  return (
    <>
      <div
        ref={containerRef}
        className={cx(
          "animate-fade-in flex flex-1 flex-wrap gap-6 overflow-y-auto p-8"
        )}
        style={{
          paddingBottom: isResizing ? SCROLL_THRESHOLD + 50 : undefined,
        }}
      >
        <WidthAwareGridLayout
          layout={layout}
          cols={GRID_COLS}
          rowHeight={255}
          margin={[16, 16]}
          containerPadding={[0, 0]}
          className="dashboard-widgets-grid w-full"
          draggableHandle=".widget-drag-handle"
          resizeHandles={["se"]}
          // @ts-expect-error - `resizeHandle` prop is not typed properly. `ResizeHandleComponentProps`-like interface is supplied to the passed component
          resizeHandle={<DashboardWidgetHandle isHidden={isLayoutFrozen} />}
          isBounded
          isDraggable={!isLayoutFrozen}
          isResizable={!isLayoutFrozen}
          onLayoutChange={handleLayoutChange}
          onDragStart={(_layout, oldItem) => setDraggingItemId(oldItem.i)}
          onDrag={handleDragOrResize}
          onDragStop={() => setDraggingItemId(null)}
          onResizeStart={(_layout, oldItem) => setResizingItemId(oldItem.i)}
          onResize={handleDragOrResize}
          onResizeStop={() => setResizingItemId(null)}
        >
          {memoizedGridLayoutDOM}
        </WidthAwareGridLayout>
      </div>
      <DashboardWidgetDialog
        {...widgetDialogState}
        onClose={() => {
          setWidgetDialogState((previousState) => ({
            ...previousState,
            isOpen: false,
          }));
        }}
      />
    </>
  );
};
