import { useCallback, useMemo } from "react";
import {
  PreviewTask,
  TaskCustomFieldType,
  TasksSource,
} from "@jugl-web/rest-api/tasks";
import { isoToLocalDate, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import format from "date-fns/format";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { useTaskFields } from "../useTaskFields";
import { ReactComponent as NameIcon } from "./assets/name.svg";
import { ReactComponent as DateIcon } from "./assets/date.svg";
import { ReactComponent as OrderIcon } from "./assets/order.svg";
// import { ReactComponent as AssigneeIcon } from "./assets/assignee.svg";
import { ReactComponent as BoardIcon } from "./assets/board.svg";
import { ReactComponent as LabelIcon } from "./assets/label.svg";
import { ReactComponent as StatusIcon } from "./assets/status.svg";
import { ReactComponent as PriorityIcon } from "./assets/priority.svg";
import { ReactComponent as ClientIcon } from "./assets/client.svg";
import { ReactComponent as TimeIcon } from "./assets/time.svg";
import { ReactComponent as TextIcon } from "./assets/text.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown.svg";
import { ReactComponent as NumberIcon } from "./assets/number.svg";
import { ReactComponent as AllIcon } from "./assets/all.svg";
import { useTaskBoards } from "../useTaskBoards";
import { useTaskPriorities } from "../useTaskPriorities";
import { useTaskStatuses } from "../useTaskStatuses";

const customFieldTypeToIcon: Record<TaskCustomFieldType, React.ReactNode> = {
  text: <TextIcon />,
  date: <DateIcon />,
  dropdown: <DropdownIcon />,
  number: <NumberIcon />,
};

export type SearchQueryFilter = {
  id: string;
  title: string;
  backgroundColor: string;
  icon: React.ReactNode;
  isDefault?: boolean;
};

export const useTaskSearchQueryFilters = ({
  entityId,
  source,
}: {
  entityId: string;
  source: TasksSource;
}) => {
  const { t } = useTranslations();
  const { customFields, getLabelById, getCustomFieldById } = useTaskFields({
    entityId,
  });
  const { getBoardById } = useTaskBoards({ entityId });
  const { getPriorityDetailsById } = useTaskPriorities();
  const { getStatusById } = useTaskStatuses({ entityId });
  const { formatDateTimeLabel } = useFormattedDate();
  const { dateLocale } = useLanguage();

  const shouldShowBoardOption = useMemo(
    () =>
      source.type === "boardTasks" &&
      (source.boardId === "my" || source.boardId === "team"),
    [source]
  );

  const shouldShowClientOption = useMemo(
    () => source.type !== "customerTasks",
    [source]
  );

  const filterTasksByName = useCallback(
    (task: PreviewTask, searchQuery: string) =>
      task.name.toLowerCase().includes(searchQuery.toLowerCase()),
    []
  );

  const filterTasksByOrderId = useCallback(
    (task: PreviewTask, searchQuery: string) =>
      task.order_id?.toLowerCase().includes(searchQuery.toLowerCase()) || false,
    []
  );

  const filterTasksByAssignees = useCallback(
    (task: PreviewTask, searchQuery: string) =>
      task.assignees.some((assignee) =>
        assignee.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    []
  );

  const filterTasksByStatus = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      const status = getStatusById(task.status);
      return status.text.toLowerCase().includes(searchQuery.toLowerCase());
    },
    [getStatusById]
  );

  const filterTasksByBoard = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      if (!task.board_id) return false;
      const board = getBoardById(task.board_id);
      if (!board) return false;
      return board.name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    [getBoardById]
  );

  const filterTasksByLabel = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      if (!task.label_id) return false;
      const label = getLabelById(task.label_id);
      if (!label) return false;
      return label.text.toLowerCase().includes(searchQuery.toLowerCase());
    },
    [getLabelById]
  );

  const filterTasksByPriority = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      const priority = getPriorityDetailsById(task.priority);
      return priority.longLabel
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    },
    [getPriorityDetailsById]
  );

  const filterTasksByDate = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      if (!task.due_at) return false;
      return formatDateTimeLabel(isoToLocalDate(task.due_at))
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    },
    [formatDateTimeLabel]
  );

  const filterTasksByClient = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      if (!task.customer) return false;
      return task.customer.first_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    },
    []
  );

  const filterTasksByTime = useCallback(
    (task: PreviewTask, searchQuery: string) => {
      if (!task.duration) return false;
      const { hours, minutes } = convertMillisecondsToTimeComponents(
        task.duration
      );

      return `${hours}h : ${minutes}min`.includes(searchQuery.toLowerCase());
    },
    []
  );

  const filterTasksByCustomField = useCallback(
    (customFieldId: string) => (task: PreviewTask, searchQuery: string) => {
      const customField = getCustomFieldById(customFieldId);
      if (
        !task.custom_fields ||
        !customField ||
        !Object.keys(task.custom_fields).find(
          (fieldId) => fieldId === customFieldId
        )
      ) {
        return false;
      }

      let value: string | undefined = task.custom_fields[customFieldId];

      if (!value) return false;

      if (customField.type === "date") {
        value = format(new Date(value), "d MMMM, yyyy, hh:mm a", {
          locale: dateLocale,
        });
      } else if (customField.type === "dropdown") {
        value = customField.values?.find((v) => v.id === value)?.value;
        if (!value) return false;
      }
      return value.toLowerCase().includes(searchQuery.toLowerCase());
    },
    [getCustomFieldById, dateLocale]
  );

  const taskSearchQueryFilterIdToFilterFunction: Record<
    string,
    (task: PreviewTask, searchQuery: string) => boolean
  > = useMemo(
    () => ({
      name: filterTasksByName,
      order: filterTasksByOrderId,
      assignee: filterTasksByAssignees,
      board: filterTasksByBoard,
      label: filterTasksByLabel,
      status: filterTasksByStatus,
      priority: filterTasksByPriority,
      date: filterTasksByDate,
      time: filterTasksByTime,
      client: filterTasksByClient,
      ...Object.fromEntries(
        customFields.map((customField) => [
          customField.id,
          (task: PreviewTask, searchQuery: string) =>
            filterTasksByCustomField(customField.id)(task, searchQuery),
        ])
      ),
    }),
    [
      filterTasksByName,
      filterTasksByOrderId,
      filterTasksByAssignees,
      filterTasksByBoard,
      filterTasksByLabel,
      filterTasksByStatus,
      filterTasksByPriority,
      filterTasksByDate,
      filterTasksByTime,
      filterTasksByClient,
      customFields,
      filterTasksByCustomField,
    ]
  );

  const getTaskSearchQueryFilters = useCallback(
    (
      selectedTaskSearchQueryFilters: string[]
    ): ((task: PreviewTask, searchQuery: string) => boolean)[] => [
      taskSearchQueryFilterIdToFilterFunction.name,
      ...selectedTaskSearchQueryFilters
        .map((id) => taskSearchQueryFilterIdToFilterFunction[id])
        .filter(Boolean),
    ],
    [taskSearchQueryFilterIdToFilterFunction]
  );

  const taskSearchQueryFilters = useMemo(
    (): SearchQueryFilter[] => [
      {
        id: "all",
        title: t({
          id: "tasks-page.all-columns",
          defaultMessage: "All columns",
        }),
        backgroundColor: "bg-[#6192BE]",
        icon: <AllIcon />,
      },
      {
        id: "name",
        title: t({
          id: "tasks-page.task-name",
          defaultMessage: "Task Name",
        }),
        backgroundColor: "bg-primary",
        icon: <NameIcon />,
        isDefault: true,
      },
      {
        id: "date",
        title: t({
          id: "tasks-page.due-date",
          defaultMessage: "Due date",
        }),
        backgroundColor: "bg-[#22C59E]",
        icon: <DateIcon />,
      },
      {
        id: "order",
        title: t({
          id: "tasks-page.order-id",
          defaultMessage: "Order ID",
        }),
        backgroundColor: "bg-[#B021F3]",
        icon: <OrderIcon />,
      },
      // TEMPORARILY HIDDEN
      // {
      //   id: "assignee",
      //   title: t({
      //     id: "tasks-page.assignees",
      //     defaultMessage: "Assignees",
      //   }),
      //   backgroundColor: "bg-[#F33A9E]",
      //   icon: <AssigneeIcon />,
      // },
      ...(shouldShowBoardOption
        ? [
            {
              id: "board",
              title: t({
                id: "tasks-page.board",
                defaultMessage: "Board",
              }),
              backgroundColor: "bg-primary",
              icon: <BoardIcon />,
            },
          ]
        : []),
      {
        id: "label",
        title: t({
          id: "tasks-page.label",
          defaultMessage: "Label",
        }),
        backgroundColor: "bg-[#22C59E]",
        icon: <LabelIcon />,
      },
      {
        id: "status",
        title: t({
          id: "tasks-page.status",
          defaultMessage: "Status",
        }),
        backgroundColor: "bg-[#B021F3]",
        icon: <StatusIcon />,
      },
      {
        id: "priority",
        title: t({
          id: "tasks-page.priority",
          defaultMessage: "Priority",
        }),
        backgroundColor: "bg-[#F33A9E]",
        icon: <PriorityIcon />,
      },
      ...(shouldShowClientOption
        ? [
            {
              id: "client",
              title: t({
                id: "tasks-page.customer",
                defaultMessage: "Customer",
              }),
              backgroundColor: "bg-primary",
              icon: <ClientIcon />,
            },
          ]
        : []),
      {
        id: "time",
        title: t({
          id: "tasks-page.time-spent",
          defaultMessage: "Time spent on Task",
        }),
        backgroundColor: "bg-[#22C59E]",
        icon: <TimeIcon />,
      },
      ...customFields.map((customField) => ({
        id: customField.id,
        title: customField.name,
        backgroundColor: "bg-[#F15C5C]",
        icon: customFieldTypeToIcon[customField.type],
      })),
    ],
    [customFields, shouldShowBoardOption, shouldShowClientOption, t]
  );

  return {
    taskSearchQueryFilters,
    getTaskSearchQueryFilters,
  };
};
