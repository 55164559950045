import { Tooltip } from "@jugl-web/ui-components";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import add from "date-fns/add";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";

export const DashboardDateRangePicker = () => {
  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const todayIn30Days = add(new Date(), { days: 30 });

  return (
    <Tooltip
      placement="bottom"
      delay="none"
      renderTrigger={({ props, ref }) => (
        <ControlBarButton
          ref={ref}
          startSlot={<CalendarIcon />}
          className="hover:bg-grey-100 cursor-help px-2.5"
          label={
            <span className="text-dark-700">
              {t({ id: "common.today", defaultMessage: "Today" })} -{" "}
              {localeAwareFormat(todayIn30Days, "MMM d, yyyy")}
            </span>
          }
          isDisabled
          {...props}
        />
      )}
      className="w-[236px] text-center"
    >
      {t({
        id: "dashboard-page.date-range-picker-info",
        defaultMessage:
          "By default, all charts are created based on data from Tasks that are due within the next 30 days, starting from today 📆",
      })}
    </Tooltip>
  );
};
