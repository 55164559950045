import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React from "react";

export const RemoveMemberAlert: React.FC<{
  isOpen: boolean;
  name?: string;
  isDisabled: boolean;
  onRemove: () => void;
  onRequestClose: () => void;
}> = ({ isOpen, isDisabled, name, onRemove, onRequestClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      content={t(
        {
          id: "chats-page.remove-group-member-confirmation",
          defaultMessage:
            "Do you really want to remove member {name} from the Group?",
        },
        {
          name: <span className="text-primary-800 font-semibold">{name}</span>,
        }
      )}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.remove",
            defaultMessage: "Remove",
          }),
          color: "tertiary",
          onClick: onRemove,
          isDisabled,
        },
      ]}
    />
  );
};
