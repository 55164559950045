import {
  ListBoxItem,
  ListBoxProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { assert, useTranslations } from "@jugl-web/utils";
import { ReactNode, useCallback, useMemo, useState, useRef } from "react";
import { useEffectOnce } from "react-use";
import { EntitySpaceType, useRestApiProvider } from "@jugl-web/rest-api";
import sortBy from "lodash/sortBy";
import { UserListBoxItem } from "../../components/UserListBoxItem";
import { WithoutUserListBoxItem } from "../../components/WithoutUserListBoxItem";
import {
  HeadlessUserItem,
  useHeadlessUsersList,
} from "../useHeadlessUsersList";
import { WITHOUT_USER_ITEM_ID } from "./consts";
import { AppModule } from "../../../common/types";
import { useMultipleUserProfiles } from "../useMultipleUserProfiles";

type SupportedOverrides = Partial<
  Omit<ListBoxProps<HeadlessUserItem | null>, "onSelect">
>;

interface UseUserListBoxOptions {
  entityId: string;
  withoutUserItem?: { label: string };
  onlyReportees?: boolean;
  skipLoading?: boolean;
  module?: AppModule;
  showDepartmentFilters?: boolean;
  topUsers?: string[];
  excludeUsers?: string[];
}

export const ALL_DEPARTMENTS_FILTER_VALUE = "all";

export const useUserListBox = ({
  entityId,
  withoutUserItem,
  onlyReportees = false,
  skipLoading = false,
  excludeUsers,
  module,
  showDepartmentFilters,
  topUsers,
}: UseUserListBoxOptions) => {
  const { usersApi } = useRestApiProvider();
  const [searchQuery, setSearchQuery] = useState("");
  const itemsRef = useRef<{ [key: string]: HTMLDivElement } | null>({});
  const { data: userCustomFields } = usersApi.useGetUserCustomFieldsQuery({
    entityId,
  });

  const [selectedDepartmentFilter, setSelectedDepartmentFilter] =
    useState<string>(ALL_DEPARTMENTS_FILTER_VALUE);

  const { users, listState, isLoading, loadMore, reachedEnd } =
    useHeadlessUsersList({
      entityId,
      searchQuery,
      sortBy: "name",
      onlyReportees,
      excludeUsers: [...(excludeUsers || []), ...(topUsers || [])].join(","),
      skipInitialLoading: skipLoading,
      departments:
        selectedDepartmentFilter === ALL_DEPARTMENTS_FILTER_VALUE
          ? undefined
          : selectedDepartmentFilter,
    });

  const { profiles: topUsersProfiles } = useMultipleUserProfiles({
    entityId,
    userIds: topUsers || [],
    skip: !topUsers?.length,
  });

  const { entitySpacesApi } = useRestApiProvider();
  const { data: departmentsList } =
    entitySpacesApi.use_DEPRECATED_allEntitySpacesListQuery({
      entityId,
      params: {
        type: EntitySpaceType.dept,
      },
    });

  const { t } = useTranslations();

  const isUsersRequestInitialized = !!listState;
  const wasSearchingInLastResponse = !!listState?.isSearch;

  const usersAsListItems = useMemo(() => {
    const bookmarkedAttributes = userCustomFields?.value
      .filter((el) => el.bookmark)
      .map((el) => el.id);
    const initialItems: ListBoxItem<HeadlessUserItem | null>[] =
      withoutUserItem?.label && !wasSearchingInLastResponse
        ? [{ id: WITHOUT_USER_ITEM_ID, value: null }]
        : [];

    let topProfiles = topUsersProfiles;
    if (selectedDepartmentFilter !== ALL_DEPARTMENTS_FILTER_VALUE) {
      topProfiles = topUsersProfiles.filter(
        (profile) => profile.department?.id === selectedDepartmentFilter
      );
    }
    if (searchQuery) {
      topProfiles = topProfiles.filter((profile) => {
        const bookmarkedValues = profile._user.work_info
          .filter((el) => bookmarkedAttributes?.includes(el.key))
          .map((el) => el.value?.toLowerCase());

        return (
          profile.displayName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          bookmarkedValues.some((el) => el?.includes(searchQuery.toLowerCase()))
        );
      });
    }
    topProfiles = sortBy(topProfiles, (item) => item.displayName.toLowerCase());

    const userItems = users.map((user) => ({ id: user.id, value: user }));

    const uniqueItems = new Map(
      [
        ...initialItems,
        ...topProfiles.map((item) => ({
          id: item.id,
          value: {
            id: item.id,
            role: item.role,
            managers: item.managers,
            entityRelId: item.entityRelId,
            space: item.department
              ? { id: item.department.id, title: item.department.name }
              : undefined,
          },
        })),
        ...userItems,
      ].map((item) => [item.id, item])
    );
    return Array.from(uniqueItems.values());
  }, [
    userCustomFields?.value,
    withoutUserItem?.label,
    wasSearchingInLastResponse,
    topUsersProfiles,
    selectedDepartmentFilter,
    searchQuery,
    users,
  ]);

  const attemptToLoadMoreUsers = useCallback(() => {
    if (isLoading || reachedEnd) {
      return;
    }
    loadMore();
  }, [isLoading, loadMore, reachedEnd]);

  useEffectOnce(() => {
    if (!skipLoading) {
      attemptToLoadMoreUsers();
    }
  });

  const getTitleProps = useCallback(
    (): { title: ReactNode; subtitle: ReactNode } => ({
      title: onlyReportees
        ? t({
            id: "tasks-page.assignee",
            defaultMessage: "Assignee",
          })
        : t({
            id: "tasks-page.select-assignee",
            defaultMessage: "Select assignee",
          }),
      subtitle: onlyReportees
        ? t(
            {
              id: "tasks-page.select-assignees-from-reportees",
              defaultMessage: "Select assignees from your <b>Reportees</b>",
            },
            {
              b: (chunks: (string | JSX.Element)[]) => (
                <span className="text-primary-700">{chunks}</span>
              ),
            }
          )
        : undefined,
    }),
    [onlyReportees, t]
  );

  const getListBoxProps = useCallback(
    (overrides?: SupportedOverrides) =>
      ({
        items: usersAsListItems,
        selectionBehavior: { mode: "multiple" },
        hasSearch: true,
        shouldFilterOnSearch: false,
        loading: isLoading
          ? isUsersRequestInitialized
            ? "bottom-spinner"
            : "skeleton"
          : undefined,
        defaultSelectedIds: [],
        maxVisibleItems: 6,
        itemSize: "lg",
        spaceBetweenItems: "normal",
        renderCustomItem: (item, _, props) => {
          if (item.id === WITHOUT_USER_ITEM_ID) {
            assert(!!withoutUserItem?.label, "withoutUserItem is not provided");

            return (
              <WithoutUserListBoxItem
                {...props}
                label={withoutUserItem.label}
              />
            );
          }

          return (
            <div
              ref={(el) => {
                if (!itemsRef.current && el) {
                  itemsRef.current = {};
                  itemsRef.current[item.id] = el;
                }
                if (itemsRef.current && el) {
                  itemsRef.current[item.id] = el;
                }
              }}
            >
              <UserListBoxItem
                entityId={entityId}
                userId={item.id}
                module={module}
                onExpandClick={() => {
                  setTimeout(() => {
                    itemsRef.current?.[item.id]?.scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                      inline: "nearest",
                    });
                  }, 300);
                }}
                {...props}
              />
            </div>
          );
        },
        onReachEnd: attemptToLoadMoreUsers,
        onSearch: setSearchQuery,
        filters:
          showDepartmentFilters && departmentsList
            ? [
                { value: "all", title: "All" },
                ...departmentsList.map((dept) => ({
                  value: dept.id,
                  title: dept.info.title,
                })),
              ]
            : undefined,
        selectedFilter: selectedDepartmentFilter,
        onFilterChange: setSelectedDepartmentFilter,
        ...overrides,
      } satisfies ListBoxProps<HeadlessUserItem | null>),
    [
      usersAsListItems,
      isLoading,
      isUsersRequestInitialized,
      attemptToLoadMoreUsers,
      departmentsList,
      entityId,
      module,
      withoutUserItem?.label,
      selectedDepartmentFilter,
      showDepartmentFilters,
    ]
  );

  const output = useMemo(
    () => ({
      getListBoxProps,
      getTitleProps,
      clearSearchQuery: () => setSearchQuery(""),
      clearFilter: () =>
        setSelectedDepartmentFilter(ALL_DEPARTMENTS_FILTER_VALUE),
      users,
      selectedDepartmentFilter,
    }),
    [getListBoxProps, getTitleProps, selectedDepartmentFilter, users]
  );

  return output;
};
