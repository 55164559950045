import { WriteRestrictedAlert } from "@jugl-web/domain-resources/entities/components/WriteRestrictedAlert";
import {
  EntityModel,
  SubscriptionInfoModel,
  useRestApiProvider,
  UserRole,
} from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { TasksApiTag } from "@jugl-web/rest-api/tasks";
import { Alert, PageLoaderFull } from "@jugl-web/ui-components/cross-platform";
import { HookOutOfContextError, isValidUUID } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { tasksApi } from "@web-src/features/api/createApi";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  PlanLimitsReachedAlert,
  PlanLimitsReachedAlertProps,
} from "../../components/PlanLimitsReachedAlert";
import { ModuleNotAvailableAlert } from "./components/ModuleNotAvailableAlert";
import { SubscriptionAlertType } from "./types/SubscriptionAlertType";

interface EntityContextValue {
  entityId: string | null;
  entity: EntityModel | null;
  subscriptionInfo: SubscriptionInfoModel | null;
  refetchSubscriptionInfo: () => void;

  reinitialize: () => Promise<void>;
  isInitialized: boolean;
  isInitializationError: boolean;
  isVerifyBillingAlertVisible: boolean;
  showWriteRestrictedAlert: () => void;
  showPlanLimitsReachedAlert: (
    type: PlanLimitsReachedAlertProps["type"]
  ) => void;
  subscriptionRequiredAction: <T>(func: T) => T | (() => void);
  verifyBillingEmail: (entityId: string, email: string, otp?: string) => void;
  entitiesList: EntityModel[];
  isEntitiesFetching: boolean;

  isModuleAvailable: (moduleId: SubscriptionPlanModuleId) => boolean;
  moduleRequiredAction: (
    moduleId: SubscriptionPlanModuleId,
    availableCallback?: () => void,
    unavailableCallback?: () => void
  ) => void;
}

interface EntitySelectedContextValue extends EntityContextValue {
  entityId: string;
  entity: EntityModel;
  subscriptionInfo: SubscriptionInfoModel;
}

const EntityContext = createContext<EntityContextValue | null>(null);

export const EntityProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  // TODO: error handling
  const entityIdMatch = useMatch("/:entityId/*");
  const entityVerifyEmailPageMatch = useMatch("/:entityId/verify-email");
  const validEntityId = useMemo(
    () =>
      entityIdMatch?.params.entityId &&
      isValidUUID(entityIdMatch.params.entityId)
        ? entityIdMatch.params.entityId
        : null,
    [entityIdMatch]
  );
  const { isAuthenticated } = useSelector(selectAuthState);

  const { entitiesApi } = useRestApiProvider();

  const [visibleAlert, setVisibleAlert] = useState<
    SubscriptionAlertType | undefined
  >();

  const dispatch = useDispatch();

  const resetApiState = useCallback(() => {
    // TODO: Consider using `emptySplitApi.util.resetApiState()` to reset all API states (needs more testing)
    const resetTaskQueriesAction = tasksApi.util.invalidateTags([
      TasksApiTag.task,
      TasksApiTag.taskActivity,
    ]);

    dispatch(resetTaskQueriesAction);
  }, [dispatch]);

  useEffect(() => {
    setVisibleAlert(undefined);

    if (validEntityId) {
      resetApiState();
    }
  }, [resetApiState, validEntityId]);

  const [verifyEmail, { isLoading: isVerifyEmailLoading }] =
    entitiesApi.useVerifyEmailMutation();

  const verifyBillingEmail = useCallback(
    async (entityId: string, billingEmail: string, otp?: string) => {
      if (!billingEmail) {
        return null;
      }
      const response = await verifyEmail({
        entityId,
        data: { billing_email: billingEmail, otp },
      });
      return response;
    },
    [verifyEmail]
  );

  const {
    refetch,
    data: entitiesList,
    isLoading: isEntitiesLoading,
    isError: isEntitiesError,
    isFetching: isEntitiesFetching,
  } = entitiesApi.use_DEPRECATED_getAllOrgEntitiesListQuery(undefined, {
    skip: !isAuthenticated,
  });

  const {
    data: subscriptionInfoData,
    isLoading: isSubscriptionInfoLoading,
    isError: isSubscriptionInfoError,
    refetch: refetchSubscriptionInfo,
  } = entitiesApi.useGetSubscriptionInfoQuery(
    validEntityId
      ? {
          entityId: validEntityId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const subscriptionInfo =
    subscriptionInfoData?.entityId === validEntityId
      ? subscriptionInfoData
      : undefined;

  const entity = useMemo(
    () =>
      validEntityId
        ? entitiesList?.find((item) => item.id === validEntityId) || null
        : null,
    [entitiesList, validEntityId]
  );

  const reinitialize = useCallback(async () => {
    refetch();
  }, [refetch]);

  const showWriteRestrictedAlert = useCallback(() => {
    setVisibleAlert("actionNotAllowed");
  }, []);

  const subscriptionRequiredAction: EntityContextValue["subscriptionRequiredAction"] =
    useCallback(
      (func) => {
        if (subscriptionInfo?.actionsAllowed) {
          return func;
        }
        return () => showWriteRestrictedAlert();
      },
      [showWriteRestrictedAlert, subscriptionInfo?.actionsAllowed]
    );

  const [verifyAlertShown, setVerifyAlertShown] = useState<{
    [key: string]: boolean;
  }>({});
  const [isVerifyBillingAlertVisible, setIsVerifyBillingAlertVisible] =
    useState<boolean>(false);
  useEffect(() => {
    if (
      !entity ||
      entity.role !== UserRole.admin ||
      localStorage.getItem("jugl:updateBillingPopupShown") ||
      !entity.billingEmail ||
      entity.isEmailVerified ||
      verifyAlertShown[entity.id]
    ) {
      return;
    }
    localStorage.setItem("jugl:updateBillingPopupShown", "true");
    setVerifyAlertShown((prev) => ({ ...prev, [entity.id]: true }));
    setIsVerifyBillingAlertVisible(true);
  }, [entity, verifyAlertShown, isVerifyBillingAlertVisible]);

  const [moduleNotAvailableAlertState, setModuleNotAvailableAlertState] =
    useState<{ isOpen: boolean; moduleId: SubscriptionPlanModuleId }>();

  const isModuleAvailable = useCallback(
    (moduleId: SubscriptionPlanModuleId) => {
      if (!subscriptionInfo) {
        return false;
      }
      // NOTE: trial or old entities
      if (subscriptionInfo.availableModules === null) {
        return true;
      }
      return !!subscriptionInfo.availableModules?.find(
        ({ id }) => id === moduleId
      )?.available;
    },
    [subscriptionInfo]
  );

  const moduleRequiredAction: EntityContextValue["moduleRequiredAction"] =
    useCallback(
      (moduleId, availableCallback, unavailableCallback) => {
        const isAvailable = isModuleAvailable(moduleId);
        if (isAvailable) {
          availableCallback?.();
        } else {
          setModuleNotAvailableAlertState({ isOpen: true, moduleId });
          unavailableCallback?.();
        }
      },
      [isModuleAvailable]
    );

  const [planLimitsReachedAlertState, setPlanLimitsReachedAlertState] =
    useState<PlanLimitsReachedAlertProps["type"]>();

  const showPlanLimitsReachedAlert = useCallback(
    (type: PlanLimitsReachedAlertProps["type"]) => {
      setPlanLimitsReachedAlertState(type);
    },
    []
  );

  const value: EntityContextValue = useMemo(
    () => ({
      isInitialized:
        !isEntitiesLoading && !isSubscriptionInfoLoading && !!subscriptionInfo,
      isInitializationError: isEntitiesError || isSubscriptionInfoError,
      subscriptionInfo: subscriptionInfo || null,
      entity: entity || null,
      entityId: entity?.id || null,
      isVerifyBillingAlertVisible,
      reinitialize,
      subscriptionRequiredAction,
      showWriteRestrictedAlert,
      entitiesList: entitiesList || [],
      isEntitiesFetching,
      verifyBillingEmail,
      refetchSubscriptionInfo,
      moduleRequiredAction,
      isModuleAvailable,
      showPlanLimitsReachedAlert,
    }),
    [
      isEntitiesLoading,
      isVerifyBillingAlertVisible,
      isSubscriptionInfoLoading,
      isEntitiesError,
      isSubscriptionInfoError,
      subscriptionInfo,
      entity,
      reinitialize,
      subscriptionRequiredAction,
      showWriteRestrictedAlert,
      entitiesList,
      isEntitiesFetching,
      verifyBillingEmail,
      refetchSubscriptionInfo,
      moduleRequiredAction,
      isModuleAvailable,
      showPlanLimitsReachedAlert,
    ]
  );

  return (
    <EntityContext.Provider value={value}>
      <>
        <PageLoaderFull isActive={isVerifyEmailLoading} isTransparent />
        {entity && (
          <>
            {moduleNotAvailableAlertState && (
              <ModuleNotAvailableAlert
                entityId={entity.id}
                isAdmin={entity.role === UserRole.admin}
                isOpen={moduleNotAvailableAlertState.isOpen}
                module={moduleNotAvailableAlertState.moduleId}
                onRequestClose={() => {
                  setModuleNotAvailableAlertState((prev) =>
                    prev
                      ? {
                          ...prev,
                          isOpen: false,
                        }
                      : undefined
                  );
                }}
              />
            )}
            <PlanLimitsReachedAlert
              isOpen={!!planLimitsReachedAlertState}
              isAdmin={entity.role === UserRole.admin}
              onRequestClose={() => setPlanLimitsReachedAlertState(undefined)}
              type={planLimitsReachedAlertState}
            />
            <WriteRestrictedAlert
              isOpen={visibleAlert === "actionNotAllowed"}
              isAdmin={entity.role === UserRole.admin}
              onRequestClose={() => setVisibleAlert(undefined)}
              onRequestPlanUpgrade={() => {
                navigate(`/${entity.id}/workspace/subscription/manage`);
                setVisibleAlert(undefined);
              }}
            />
            <Alert
              isOpen={
                !isEntitiesLoading &&
                !isEntitiesError &&
                !isEntitiesFetching &&
                subscriptionInfo &&
                subscriptionInfo.actionsAllowed &&
                isVerifyBillingAlertVisible
              }
              title="Verify email"
              content={
                <>
                  Indicated Workspace Email will receive all Workspace updates,
                  we want to verify it, to make sure your{" "}
                  <span className="font-semibold text-blue-500">
                    data is secured
                  </span>{" "}
                  🤝
                </>
              }
              onRequestClose={() => setIsVerifyBillingAlertVisible(false)}
              buttons={[
                {
                  text: "Later",
                  onClick: (e, { closeAlert }) => {
                    if (entityVerifyEmailPageMatch) {
                      navigate(`/${entity.id}/chats`);
                    }
                    closeAlert();
                  },
                  color: "grey",
                },
                {
                  text: "Verify Now",
                  onClick: async (e, { closeAlert }) => {
                    if (!entity.billingEmail) {
                      return;
                    }
                    await verifyBillingEmail(entity.id, entity.billingEmail);
                    navigate(`/${entity.id}/verify-email`);
                    closeAlert();
                  },
                },
              ]}
            />
          </>
        )}
        {children}
      </>
    </EntityContext.Provider>
  );
};

export const useEntityProvider = () => {
  const context = useContext(EntityContext);

  if (!context) {
    throw new HookOutOfContextError("useEntityProvider", "EntityContext");
  }

  return context;
};

export const useEntitySelectedProvider = () => {
  const context = useContext(EntityContext);

  if (!context) {
    throw new HookOutOfContextError("useEntityProvider", "EntityContext");
  }

  if (!context.entityId || !context.entity || !context.subscriptionInfo) {
    throw new Error(
      "useEntitySelectedProvider should be used only when user has selected entity"
    );
  }

  return context as EntitySelectedContextValue;
};
