import { DetailedDashboardModel } from "@jugl-web/rest-api/dashboard/models/DetailedDashboard";
import {
  ExpectedWidgetMetadata,
  WidgetChartType,
  WidgetConfig,
} from "@jugl-web/rest-api/dashboard/models/Widget";
import add from "date-fns/add";
import { Layout } from "react-grid-layout";
import { Subject } from "rxjs";
import {
  DEFAULT_CHART_COLOR_PALETTE,
  WIDGET_PER_DASHBOARD_LIMIT,
} from "./consts";

export const getInitialWidgetDateRange = () => {
  const from = new Date();
  const to = add(from, { days: 30 });

  return { from, to };
};

export const produceDefaultWidgetConfig = (
  chartType: WidgetChartType
): WidgetConfig<WidgetChartType, ExpectedWidgetMetadata> => ({
  x_axis: "board",
  series: [{ y_axis: "count", type: chartType }],
  show_empty: false,
  include_overdue: false,
  include_nodue: true,
  meta: {
    y_axis_format: "value",
    sorting: "x-axis-asc",
    color_palette: DEFAULT_CHART_COLOR_PALETTE,
    show_unassigned_categories: true,
  },
});

export const hasDashboardReachedWidgetLimit = (
  dashboard: DetailedDashboardModel
) => dashboard.widgets.length >= WIDGET_PER_DASHBOARD_LIMIT;

export const openNewDashboardDialog$ = new Subject<void>();

interface FindFirstAvailableLayoutPositionOptions {
  layoutEntries: Layout[];
  gridColumns: number;
  widgetWidth: number;
  widgetHeight: number;
}

const MAX_ITERATIONS = 1000;

export const findFirstAvailableLayoutPosition = ({
  layoutEntries,
  gridColumns,
  widgetWidth,
  widgetHeight,
}: FindFirstAvailableLayoutPositionOptions) => {
  const occupiedMatrix: boolean[][] = [];

  /**
   * Calculates boolean representation of the occupied cells in the grid.
   *
   * For example:
   * [
   *  [true, true, true, true], -> all rows occupied
   *  [true, false, false, true], -> 2 rows in the middle free
   *  [true, false, false, true], -> 2 rows in the middle free
   *  [true, true, true, true], -> all rows occupied
   * ]
   *
   * There is a 2x2 spot right in the middle where a widget can be placed.
   */
  layoutEntries.forEach((entry) => {
    for (let dx = 0; dx < entry.w; dx += 1) {
      for (let dy = 0; dy < entry.h; dy += 1) {
        const x = entry.x + dx;
        const y = entry.y + dy;

        if (!occupiedMatrix[y]) {
          occupiedMatrix[y] = [];
        }

        occupiedMatrix[y][x] = true;
      }
    }
  });

  let y = 0;

  // Iterates over the grid rows and columns to find the first available spot
  while (y < MAX_ITERATIONS) {
    for (let x = 0; x <= gridColumns - widgetWidth; x += 1) {
      let fits = true;

      for (let dx = 0; dx < widgetWidth; dx += 1) {
        for (let dy = 0; dy < widgetHeight; dy += 1) {
          const isOccupied =
            occupiedMatrix[y + dy] && occupiedMatrix[y + dy][x + dx];

          if (isOccupied) {
            fits = false;
            break;
          }
        }

        if (!fits) {
          break;
        }
      }

      if (fits) {
        return { x, y };
      }
    }

    y += 1;
  }

  // As a fallback, return the first cell in the last row
  return { x: 0, y: Infinity };
};
