import { RenderTriggerProps, ListBoxItem } from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { WarehouseItemDataDto } from "@jugl-web/rest-api/inventory/models/WarehouseItem";

type WarehousePickerProps = {
  value: { id: string; name: string } | null;
  onChange: (warehouse: { id: string; name: string } | null) => void;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
};
export const WarehousePicker = ({
  value,
  onChange,
  renderTrigger,
}: WarehousePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const [warehouseListState, setWarehouseListState] = useState<{
    items: WarehouseItemDataDto[];
    page: number;
    hasMore: boolean;
    isInitialized: boolean;
  }>({
    items: [],
    page: 0,
    hasMore: true,
    isInitialized: false,
  });

  const [loadWarehouses] = inventoryApi.useLazyGetWarehouseListQuery();

  const handleLoadWarehouses = useCallback(
    async (searchQuery?: string, reset?: boolean) => {
      if (!warehouseListState.hasMore && !reset) {
        return;
      }
      const response = await loadWarehouses({
        entityId: entity.id,
        params: {
          page: reset ? 1 : warehouseListState.page + 1,
          page_size: 10,
          search: searchQuery || undefined,
          status: "active",
        },
      });
      if ("data" in response && response.data?.data) {
        const hasMore = response.data?.total_pages > response.data.page_number;
        const items = () => {
          if (!response.data) return warehouseListState.items;
          if (reset) {
            return [...response.data.data];
          }
          const uniqueItems = new Map(
            [...warehouseListState.items, ...response.data.data].map((item) => [
              item.id,
              item,
            ])
          );
          return Array.from(uniqueItems.values());
        };
        setWarehouseListState((prevState) => ({
          ...prevState,
          items: items(),
          page: reset ? 1 : prevState.page + 1,
          hasMore,
          isInitialized: true,
        }));
      }
    },
    [
      entity.id,
      loadWarehouses,
      warehouseListState.items,
      warehouseListState.page,
      warehouseListState.hasMore,
    ]
  );

  const warehouseListItems = useMemo<ListBoxItem<WarehouseItemDataDto>[]>(
    () =>
      warehouseListState.items?.map((warehouse) => ({
        id: warehouse.id,
        value: warehouse,
      })) || [],
    [warehouseListState.items]
  );
  useEffect(() => {
    if (!warehouseListState.isInitialized && isOpen) {
      handleLoadWarehouses();
    }
  }, [handleLoadWarehouses, isOpen, warehouseListState.isInitialized]);

  return (
    <ResourcePickerPopover
      onUnmount={() => {
        setWarehouseListState({
          items: [],
          page: 0,
          hasMore: true,
          isInitialized: false,
        });
      }}
      hasSearch
      items={warehouseListItems}
      selectionBehavior={{ mode: "single", canToggle: true }}
      loading={!warehouseListState.isInitialized ? "skeleton" : undefined}
      defaultSelectedIds={value?.id ? [value.id] : undefined}
      maxVisibleItems={4}
      itemSize="lg"
      spaceBetweenItems="normal"
      renderLabel={(item) => item.value.name}
      onSelect={({ item, onClose, isSelected }) => {
        onChange(
          isSelected
            ? {
                id: item.value.id,
                name: item.value.name,
              }
            : null
        );
        onClose();
      }}
      onSearch={(searchQuery) => handleLoadWarehouses(searchQuery, true)}
      onReachEnd={handleLoadWarehouses}
      placement="top-end"
      className="z-[2000]"
      renderTrigger={(props) => {
        if (props.isOpen) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
        return renderTrigger(props);
      }}
    />
  );
};
