import {
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import {
  cx,
  getUniqueId,
  onEnter,
  onEscape,
  reorder,
  useTranslations,
} from "@jugl-web/utils";
import {
  Dispatch,
  FC,
  forwardRef,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useEffectOnce } from "react-use";
import { CUSTOM_DROPDOWN_VALUE_CHAR_LIMIT } from "../../consts";
import { customDropdownValueValidator } from "../../utils";
import { DraggableFieldBoxContainer } from "../DraggableFieldBoxContainer";
import { ReactComponent as TrashBinIcon } from "./assets/trash-bin.svg";

export interface DropdownValueListItem {
  id: string;
  text: string;
}

interface DropdownValueListItemProps {
  item: DropdownValueListItem;
  index: number;
  isEditing: boolean;
  onStartEditing: () => void;
  onFinishEditing: () => void;
  onUpdate: (text: string) => void;
  onRemove: () => void;
}

const DropdownValueListItemComponent: FC<DropdownValueListItemProps> = ({
  item,
  index,
  isEditing,
  onStartEditing,
  onFinishEditing,
  onUpdate,
  onRemove,
}) => {
  const [internalText, setInternalText] = useState(item.text);
  const { t } = useTranslations();

  const isValid = customDropdownValueValidator(internalText);

  const handleEditingFinished = useCallback(() => {
    if (!isValid) {
      onRemove();
      return;
    }

    onUpdate(internalText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalText, isValid]);

  useLayoutEffect(() => {
    if (!isEditing) {
      handleEditingFinished();
    }
  }, [handleEditingFinished, isEditing]);

  return (
    <DraggableFieldBoxContainer id={item.id} index={index} className="mb-4">
      <InteractiveContainer
        className={cx(
          "relative flex h-[56px] !cursor-pointer items-center justify-between rounded-lg px-6 transition-colors",
          isEditing ? "bg-[#F6F6F7]" : "bg-grey-100 hover:bg-[#F6F6F7]"
        )}
        onClick={onStartEditing}
      >
        <div className="grow">
          {isEditing ? (
            <div className="relative mr-10">
              <input
                type="text"
                autoFocus
                maxLength={CUSTOM_DROPDOWN_VALUE_CHAR_LIMIT}
                placeholder={t({
                  id: "form.controls.custom-dropdown-field-value.placeholder",
                  defaultMessage: "Enter value name",
                })}
                className="placeholder:text-grey-600 text-dark w-full border-b border-l-0 border-r-0 border-t-0 border-b-[#E0E0E0] bg-transparent py-[6.5px] px-0 font-[Roboto] text-base outline-none"
                value={internalText}
                onChange={(event) => setInternalText(event.target.value)}
                onKeyUp={onEnter(() => isValid && onFinishEditing())}
                onKeyDown={onEscape((event) => {
                  event.stopPropagation();
                  onFinishEditing();
                })}
                onBlur={onFinishEditing}
              />
              <span className="text-grey-600 pointer-events-none absolute right-0 top-1/2 -translate-y-1/2">
                {internalText.length}/{CUSTOM_DROPDOWN_VALUE_CHAR_LIMIT}
              </span>
            </div>
          ) : (
            <span className="text-dark text-base">{item.text}</span>
          )}
        </div>
        <PlainButton
          className="hover:bg-grey-200 flex h-8 w-8 shrink-0 items-center justify-center rounded-full transition-colors"
          onClick={(event) => {
            event.stopPropagation();
            onRemove();
          }}
        >
          <TrashBinIcon />
        </PlainButton>
      </InteractiveContainer>
    </DraggableFieldBoxContainer>
  );
};

interface DropdownValuesListProps {
  items: DropdownValueListItem[];
  onChange: Dispatch<SetStateAction<DropdownValueListItem[]>>;
  autoAddItem?: boolean;
  className?: string;
}

export interface DropdownValuesListHandle {
  addItem: () => void;
}

export const DropdownValueList = forwardRef<
  DropdownValuesListHandle,
  DropdownValuesListProps
>(({ items, onChange, autoAddItem, className }, ref) => {
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  // #region Public API
  const addItem = useCallback(() => {
    const newItem: DropdownValueListItem = {
      id: getUniqueId(),
      text: "",
    };

    onChange((prevItems) => [...prevItems, newItem]);
    setEditingItemId(newItem.id);
  }, [onChange]);

  useImperativeHandle(ref, () => ({ addItem }));
  // #endregion

  const handleUpdateItem = (id: string, text: string) => {
    onChange((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, text } : item))
    );
  };

  const handleRemoveItem = (id: string) => {
    onChange((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    onChange((prevItems) =>
      reorder(prevItems, source.index, destination.index)
    );
  };

  useEffectOnce(() => {
    if (autoAddItem && items.length === 0) {
      addItem();
    }
  });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="dropdownValueList">
        {(provided) => (
          <div
            ref={provided.innerRef}
            className={cx("flex flex-col", className)}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              <DropdownValueListItemComponent
                key={item.id}
                item={item}
                index={index}
                isEditing={editingItemId === item.id}
                onStartEditing={() => setEditingItemId(item.id)}
                onFinishEditing={() => setEditingItemId(null)}
                onUpdate={(text) => handleUpdateItem(item.id, text)}
                onRemove={() => handleRemoveItem(item.id)}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
