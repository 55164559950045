import { useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";

export type TaskListErrorType = "forbidden" | "bad-request" | "unknown";

export enum TaskListErrorCode {
  badRequest = 400,
  forbidden = 403,
}

export interface UseTaskListErrorOptions {
  errorType: TaskListErrorType;
  onNavigateToMyTasks: () => void;
  onRefetch: () => void;
}

export const useTaskListError = ({
  errorType,
  onNavigateToMyTasks,
  onRefetch,
}: UseTaskListErrorOptions) => {
  const { t } = useTranslations();

  const messageByErrorType: Record<TaskListErrorType, string> = {
    forbidden: t({
      id: "tasks-page.list-forbidden-error-screen-description",
      defaultMessage:
        "The page you are trying to reach has restricted access or no longer exists",
    }),
    "bad-request": t({
      id: "tasks-page.list-forbidden-error-screen-description",
      defaultMessage:
        "The page you are trying to reach has restricted access or no longer exists",
    }),
    unknown: t({
      id: "tasks-page.list-unknown-error-screen-description",
      defaultMessage:
        "Failed to load your tasks. Please try again in a moment.",
    }),
  };

  const title = t({
    id: "tasks-page.list-error-screen-title",
    defaultMessage: "Something went wrong",
  });

  const buttonLabel =
    errorType === "unknown"
      ? t({ id: "common.retry", defaultMessage: "Retry" })
      : t({ id: "common.okay", defaultMessage: "Okay" });

  const onButtonClick = useCallback(() => {
    if (errorType === "unknown") {
      onRefetch();
    } else {
      onNavigateToMyTasks();
    }
  }, [errorType, onNavigateToMyTasks, onRefetch]);

  return {
    title,
    message: messageByErrorType[errorType],
    buttonLabel,
    onButtonClick,
  };
};
