import { HookOutOfContextError } from "@jugl-web/utils";
import { useContext } from "react";
import { DashboardContext } from "../providers/DashboardProvider";

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new HookOutOfContextError("useDashboardContext", "DashboardContext");
  }

  return context;
};
