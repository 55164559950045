import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import { cx } from "@jugl-web/utils";
import { ReactComponent as PlayIcon } from "./assets/play.svg";
import { ReactComponent as PauseIcon } from "./assets/pause.svg";
import { InteractiveContainer } from "../../../cross-platform";

const getDurationStorageKey = (src: string) => `audio:duration:${src}`;

interface VoiceMessageAudioPlayerProps {
  audioSrc: string;
  waveform: number[];
  variant: "incoming" | "outgoing";
  duration?: number;
}

const roundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.closePath();
  ctx.fill();
};

export const VoiceMessageAudioPlayer: React.FC<
  VoiceMessageAudioPlayerProps
> = ({ audioSrc, waveform, variant, duration: defaultDuration = 0 }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number | null>(null);
  const timeRef = useRef<HTMLSpanElement>(null);
  const [duration, setDuration] = useState(() => {
    if (defaultDuration) {
      return defaultDuration;
    }
    const storedDuration = sessionStorage.getItem(
      getDurationStorageKey(audioSrc)
    );
    if (storedDuration && !Number.isNaN(Number(storedDuration))) {
      return Number(storedDuration);
    }
    return 0;
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const isOutgoing = variant === "outgoing";

  const drawWaveform = useCallback(
    (actualTime: number) => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      const audio = audioRef.current;
      if (ctx && audio && canvas) {
        const dpr = window.devicePixelRatio || 1;
        const canvasWidth = canvas.offsetWidth;
        const canvasHeight = canvas.offsetHeight;

        canvas.width = canvasWidth * dpr;
        canvas.height = canvasHeight * dpr;

        ctx.scale(dpr, dpr);
        ctx.imageSmoothingEnabled = false;

        const segmentWidth = canvasWidth / waveform.length;
        const progressX = (actualTime / audio.duration) * canvasWidth;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        waveform.forEach((value, index) => {
          const barHeight = Math.max(3, value * canvasHeight * 0.8);
          const x = index * segmentWidth;
          const barWidth = segmentWidth * 0.3;
          const barX = x + (segmentWidth - barWidth) / 2;
          const barY = canvasHeight - barHeight;
          const progressInBar = Math.max(
            0,
            Math.min(barWidth, progressX - barX)
          );

          ctx.fillStyle = isOutgoing ? "#FFFFFF" : "#A4A5AA";
          roundedRect(ctx, barX, barY, barWidth, barHeight, barWidth / 2);
          if (progressInBar > 0) {
            ctx.fillStyle = isOutgoing ? "#90C9F9" : "#1A75D2";
            roundedRect(
              ctx,
              barX,
              barY,
              progressInBar,
              barHeight,
              barWidth / 2
            );
          }
        });
      }
    },
    [waveform, isOutgoing]
  );

  const updateProgress = useCallback(() => {
    const audio = audioRef.current;
    if (isDragging || !audio) {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
      return;
    }
    const { currentTime: actualTime } = audio;
    drawWaveform(actualTime);
    if (timeRef.current && (actualTime > 0 || isPlaying)) {
      timeRef.current.textContent = formatTime(actualTime);
    }

    animationFrameRef.current = requestAnimationFrame(updateProgress);
  }, [drawWaveform, isDragging, isPlaying]);

  const handleCanvasClick = useCallback(
    (e: MouseEvent | React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current;
      const audio = audioRef.current;
      if (canvas && audio) {
        const rect = canvas.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const clickTime = (clickX / rect.width) * audio.duration;
        audio.currentTime = clickTime;
        drawWaveform(clickTime);
      }
    },
    [drawWaveform]
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent | React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      setIsDragging(true);
      handleCanvasClick(e);
    },
    [handleCanvasClick]
  );

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        handleCanvasClick(e);
      }
    },
    [handleCanvasClick, isDragging]
  );

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    updateProgress();
  }, [updateProgress]);

  useEffect(() => {
    if (isPlaying) {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    } else if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  }, [isPlaying, updateProgress]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canPlay || !canvas) return undefined;

    canvas.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove, handleMouseDown, canPlay]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const buttonClassNames = useMemo(() => {
    if (isOutgoing) {
      if (isPlaying) {
        return "bg-white text-gradients-g3 hover:bg-grey-200";
      }
      return "bg-white text-primary hover:bg-grey-200";
    }
    if (isPlaying) {
      return "bg-gradients-g3 text-white hover:bg-[#9D4AD7]";
    }
    return "bg-primary text text-white hover:bg-primary-600";
  }, [isPlaying, isOutgoing]);

  return (
    <>
      <audio
        ref={audioRef}
        src={audioSrc}
        hidden
        onEnded={() => setIsPlaying(false)}
        onLoadedMetadata={(e) => {
          if (defaultDuration) {
            return;
          }
          sessionStorage.setItem(
            getDurationStorageKey(audioSrc),
            e.currentTarget.duration.toString()
          );
          setDuration(e.currentTarget.duration);
        }}
        onCanPlay={() => setCanPlay(true)}
      />
      <div className="flex items-center gap-2.5 p-1">
        <InteractiveContainer
          onClick={togglePlayPause}
          className={cx(
            "bg-primary flex h-11 w-11 shrink-0 items-center justify-center rounded-full transition-colors",
            buttonClassNames
          )}
          isDisabled={!canPlay}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </InteractiveContainer>
        <div className="flex w-full flex-col gap-1">
          <canvas
            ref={canvasRef}
            className={cx("h-10 w-full", canPlay && "cursor-pointer")}
          />
          <span
            className={cx("text-sm", isOutgoing ? "text-white" : "text-grey")}
            ref={timeRef}
          >
            {formatTime(duration)}
          </span>
        </div>
      </div>
    </>
  );
};
