import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  TaskCustomStatus,
  TaskDefaultStatusId,
} from "@jugl-web/rest-api/tasks";
import { mapIndexesToOrder } from "@jugl-web/utils/array/mapIndexesToOrder";
import { reorder } from "@jugl-web/utils/reorder";
import assert from "assert";
import partition from "lodash/partition";
import { useCallback, useMemo } from "react";
import { useTaskFieldsQuery } from "./useTaskFieldsQuery";

export interface UseTaskStatusesOptions {
  entityId: string | undefined;
}

const taskDefaultStatusIds = Object.values<string>(TaskDefaultStatusId);

const sortStatuses = (statuses: TaskCustomStatus[]) =>
  [...statuses].sort((statusA, statusB) => {
    // Pending status always first
    if (statusA.id === TaskDefaultStatusId.pending) {
      return -1;
    }

    if (statusB.id === TaskDefaultStatusId.pending) {
      return 1;
    }

    // Completed status always last
    if (statusA.id === TaskDefaultStatusId.completed) {
      return 1;
    }

    if (statusB.id === TaskDefaultStatusId.completed) {
      return -1;
    }

    // Custom statuses in between sorted by order property
    return statusA.order - statusB.order;
  });

export const useTaskStatuses = ({ entityId }: UseTaskStatusesOptions) => {
  const { taskFields } = useTaskFieldsQuery({ entityId });

  const { tasksApi } = useRestApiProvider();

  const allStatuses = useMemo(
    () => taskFields?.customStatuses || [],
    [taskFields?.customStatuses]
  );

  const statusesMap = useMemo<Map<string, TaskCustomStatus>>(
    () => new Map(allStatuses.map((status) => [status.id, status])),
    [allStatuses]
  );

  const [defaultStatuses, customStatuses] = useMemo(
    () =>
      partition(allStatuses, (status) =>
        taskDefaultStatusIds.includes(status.id)
      ),
    [allStatuses]
  );

  const allStatusesSorted = useMemo<TaskCustomStatus[]>(
    () => sortStatuses(allStatuses),
    [allStatuses]
  );

  const getStatusById = useCallback(
    (id: string): TaskCustomStatus => {
      const matchingStatus = statusesMap.get(id);

      if (matchingStatus) {
        return matchingStatus;
      }

      // Return default status as a fallback
      return {
        id: TaskDefaultStatusId.pending,
        text: "Pending",
        order: 0,
      };
    },
    [statusesMap]
  );

  // #region Mutations
  const [
    createTaskCustomStatusMutation,
    { isLoading: isCreatingCustomStatus },
  ] = tasksApi.useCreateTaskCustomStatusMutation();

  const [
    updateTaskCustomStatusesMutation,
    { isLoading: isUpdatingCustomStatuses },
  ] = tasksApi.useUpdateTaskCustomStatusesMutation();

  const [
    deleteTaskCustomStatusMutation,
    { isLoading: isDeletingCustomStatus },
  ] = tasksApi.useDeleteTaskCustomStatusMutation();

  const updateCustomStatuses = useCallback(
    (
      callback: (
        previousCustomStatuses: TaskCustomStatus[]
      ) => TaskCustomStatus[]
    ) =>
      new Promise<void>((resolve, reject) => {
        if (!entityId || !taskFields) {
          reject();
          return;
        }

        const updatedCustomStatuses = callback(taskFields.customStatuses);

        updateTaskCustomStatusesMutation({
          entityId,
          customStatuses: updatedCustomStatuses,
        }).then((response) => {
          if ("data" in response) {
            resolve();
          } else {
            reject();
          }
        });
      }),
    [entityId, taskFields, updateTaskCustomStatusesMutation]
  );

  const createCustomStatus = useCallback(
    async (newCustomStatus: Pick<TaskCustomStatus, "text">) => {
      assert(!!entityId);

      return new Promise<void>((resolve, reject) => {
        createTaskCustomStatusMutation({
          entityId,
          customStatus: {
            ...newCustomStatus,
            order:
              customStatuses.length > 0
                ? customStatuses[customStatuses.length - 1].order + 1
                : 1,
          },
        }).then((response) => {
          if ("data" in response) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    [createTaskCustomStatusMutation, entityId, customStatuses]
  );

  const updateCustomStatus = useCallback(
    (statusId: string, updatedText: string) =>
      updateCustomStatuses((previousCustomStatuses) =>
        previousCustomStatuses.map((status) =>
          status.id === statusId ? { ...status, text: updatedText } : status
        )
      ),
    [updateCustomStatuses]
  );

  const deleteCustomStatus = useCallback(
    (customStatusId: string) => {
      assert(!!entityId);
      return deleteTaskCustomStatusMutation({
        entityId,
        statusId: customStatusId,
      });
    },
    [deleteTaskCustomStatusMutation, entityId]
  );

  const reorderCustomStatus = useCallback(
    (startIndex: number, endIndex: number) => {
      updateCustomStatuses(() => {
        const reorderedCustomStatuses = mapIndexesToOrder(
          reorder(customStatuses, startIndex, endIndex)
        );

        const reorderedAllStatuses = mapIndexesToOrder(
          sortStatuses([...defaultStatuses, ...reorderedCustomStatuses])
        );

        return reorderedAllStatuses;
      });
    },
    [customStatuses, defaultStatuses, updateCustomStatuses]
  );
  // #endregion

  return {
    defaultStatuses,
    customStatuses,
    allStatusesSorted,
    getStatusById,

    // Mutations
    createCustomStatus,
    updateCustomStatus,
    deleteCustomStatus,
    reorderCustomStatus,
    isCreatingCustomStatus,
    isUpdatingCustomStatuses,
    isDeletingCustomStatus,
  };
};
