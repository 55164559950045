import {
  CustomerModuleLog,
  CustomerModuleLogChanges,
} from "@jugl-web/rest-api";
import {
  addSearchParamsToURL,
  isoToLocalDate,
  joinReactNodes,
} from "@jugl-web/utils";
import { joinReactNodesInHumanReadableForm } from "@jugl-web/utils/utils/joinReactNodes";
import { ReactNode, useCallback, useMemo } from "react";
import externalAvatarImg from "./assets/external-avatar-img.png";

type SupportedCustomerModuleLogChanges = Required<CustomerModuleLogChanges>;

type ChangeKeyToMessageRendererMap = {
  [Key in keyof SupportedCustomerModuleLogChanges]: (
    arg: SupportedCustomerModuleLogChanges[Key]
  ) => ReactNode;
};

export const useCustomerLogParser = () => {
  const customerAddedMessageRenderer = useCallback(
    (username: string, clientName: string) =>
      username ? (
        <>
          <b>{username}</b> has added customer <b>{clientName}</b> to the
          workspace
        </>
      ) : (
        <>
          <b>{clientName}</b> was added to the workspace
        </>
      ),
    []
  );

  const customerUpdatedMessageRenderer = useCallback(
    (username: string, changesMessage: ReactNode, clientName: string) =>
      joinReactNodes(
        [
          <b>{username}</b>,
          changesMessage,
          <>
            for the customer <b>{clientName}</b>
          </>,
        ],
        " "
      ),
    []
  );

  const customerDeletedMessageRenderer = useCallback(
    (username: string, clientName: string) => (
      <>
        <b>{username}</b> has removed customer <b>{clientName}</b> from the
        workspace
      </>
    ),
    []
  );

  const changeKeyToMessageRendererMap: ChangeKeyToMessageRendererMap = useMemo(
    () => ({
      first_name: (firstName) => (
        <>
          has updated first name to <b>{firstName}</b>
        </>
      ),
      last_name: (lastName) => (
        <>
          has updated last name to <b>{lastName}</b>
        </>
      ),
      position: (position) => (
        <>
          has updated position to <b>{position}</b>
        </>
      ),
      phone_number: (phoneNumber) => (
        <>
          has updated phone number to <b>{phoneNumber}</b>
        </>
      ),
      state: (state) => (
        <>
          has updated state to <b>{state}</b>
        </>
      ),
      country: (country) => (
        <>
          has updated country to <b>{country}</b>
        </>
      ),
      city: (city) => (
        <>
          has updated city to <b>{city}</b>
        </>
      ),
      zip_code: (zipCode) => (
        <>
          has updated zip code to <b>{zipCode}</b>
        </>
      ),
      street: (street) => (
        <>
          has updated street to <b>{street}</b>
        </>
      ),
      company_name: (companyName) => (
        <>
          has updated company name to <b>{companyName}</b>
        </>
      ),
      industry: (industry) => (
        <>
          has updated industry to <b>{industry}</b>
        </>
      ),
      website: (website) => (
        <>
          has updated website to <b>{website}</b>
        </>
      ),
      logo: () => "has updated the customer image",
    }),
    []
  );

  const customerChangeRenderer = useCallback(
    <
      TKey extends keyof SupportedCustomerModuleLogChanges,
      TValue extends SupportedCustomerModuleLogChanges[TKey]
    >(
      key: TKey,
      value: TValue
    ): ReactNode | undefined => {
      const isKnownField = key in changeKeyToMessageRendererMap;

      if (isKnownField) {
        return changeKeyToMessageRendererMap[key]?.(value);
      }

      return (
        <>
          has updated field <b>{key}</b> to <b>{value}</b>
        </>
      );
    },
    [changeKeyToMessageRendererMap]
  );

  const extractClientNameFromLog = useCallback(
    (log: CustomerModuleLog) =>
      [log.customer.first_name, log.customer.last_name]
        .filter(Boolean)
        .join(" ") || "Unknown customer",
    []
  );

  const extractMessageFromChanges = useCallback(
    (changes: CustomerModuleLogChanges) => {
      const changeMessages = Object.keys(changes).reduce<ReactNode[]>(
        (acc, key) => {
          const changeKey = key as keyof SupportedCustomerModuleLogChanges;

          const changeValue = changes[
            changeKey
          ] as SupportedCustomerModuleLogChanges[keyof SupportedCustomerModuleLogChanges];

          let changeMessage: ReactNode;

          try {
            changeMessage = customerChangeRenderer(changeKey, changeValue);
          } catch {
            // Do nothing
          }

          if (!changeMessage) {
            // eslint-disable-next-line no-console
            console.warn(
              "Couldn't parse the following customer module log change",
              { changeKey, changeValue }
            );

            return acc;
          }

          return [...acc, changeMessage];
        },
        []
      );

      // Fallback message if there is no valid change message
      if (changeMessages.length === 0) {
        return `has introduced a change`;
      }

      return joinReactNodesInHumanReadableForm(changeMessages);
    },

    [customerChangeRenderer]
  );

  const customerMessageRenderer = useCallback(
    (username: string, log: CustomerModuleLog) => {
      if (log.action === "added") {
        return customerAddedMessageRenderer(
          username,
          extractClientNameFromLog(log)
        );
      }

      if (log.action === "updated") {
        return customerUpdatedMessageRenderer(
          username,
          extractMessageFromChanges(log.changes),
          extractClientNameFromLog(log)
        );
      }

      if (log.action === "deleted") {
        return customerDeletedMessageRenderer(
          username,
          extractClientNameFromLog(log)
        );
      }

      throw new Error(`Unsupported customer log action: ${log.action}`);
    },
    [
      extractMessageFromChanges,
      extractClientNameFromLog,
      customerAddedMessageRenderer,
      customerDeletedMessageRenderer,
      customerUpdatedMessageRenderer,
    ]
  );

  const parseCustomerLog = useCallback(
    (log: CustomerModuleLog) => {
      const username = [log.action_by?.first_name, log.action_by?.last_name]
        .filter((item) => !!item)
        .join(" ");

      return {
        id: log.id,
        user: {
          name: username,
          avatarUrl: log.action_by?.img
            ? addSearchParamsToURL(log.action_by.img, {
                t: log.action_by.updated_at,
              })
            : (externalAvatarImg as unknown as string),
        },
        message: customerMessageRenderer(username, log),
        date: new Date(isoToLocalDate(log.inserted_at)),
      };
    },
    [customerMessageRenderer]
  );

  return { parseCustomerLog };
};
