import { ChatItem } from "@web-src/modules/chats/types";

export const getInitFromMessage = (
  chat: ChatItem
):
  | {
      id: string;
      created: string;
      type: "first-unread" | "last-read";
    }
  | undefined => {
  if (chat.lastLiveOutgoingMessage) {
    return undefined;
  }
  if (chat.lastReadMessage) {
    return {
      ...chat.lastReadMessage,
      created: chat.lastReadMessage.createdAt,
      id: chat.lastReadMessage.msgId,
      type: "last-read",
    };
  }
  if (chat.firstUnreadMessage) {
    return {
      ...chat.firstUnreadMessage,
      created: chat.firstUnreadMessage.createdAt,
      id: chat.firstUnreadMessage.msgId,
      type: "first-unread",
    };
  }
  return undefined;
};
