import { PlainButton } from "@jugl-web/ui-components";
import React, { useState, useEffect } from "react";
import { OrderResponseInvoiceItem } from "@jugl-web/rest-api/orders";
import { InventoryItemCategory } from "@jugl-web/rest-api";
import {
  cx,
  priceDisplay,
  useTranslations,
  currencyCodeToSymbol,
} from "@jugl-web/utils";
import { MAX_NUMBER_VALUE } from "@web-src/modules/inventory/pages/InventoryItemEditStockFormPage/consts";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { preventIncorrectNumberInputCharacters } from "@jugl-web/domain-resources/inventory/utils";
import { undiscountedPriceInCents } from "../../utils";

export const OrderInlineEditCell: React.FC<{
  item: OrderResponseInvoiceItem;
  type: "qty" | "price";
  onEdit?: ({
    item,
    value,
    type,
  }: {
    item: OrderResponseInvoiceItem;
    value: number;
    type: "qty" | "price";
  }) => void;
}> = ({ item, type, onEdit }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [value, setValue] = useState(item[type].toString());
  const { entity } = useEntitySelectedProvider();
  const { t } = useTranslations();

  useEffect(() => {
    if (!isEditing) {
      const valueByType = {
        qty: item.qty,
        price: item.discount?.discount
          ? undiscountedPriceInCents(item.discount.discount, item.price) /
              100 || 0
          : item.price,
      };

      const valueToSet = valueByType[type].toString();
      const isDecimal = type === "price";
      setValue(
        isDecimal
          ? Number(valueToSet || 0)
              .toFixed(2)
              .toString()
          : valueToSet
      );
    }
  }, [isEditing, item.discount?.discount, item.price, item.qty, type]);

  const canEdit = {
    price: !!onEdit,
    qty: item.category === InventoryItemCategory.item && !!onEdit,
  };

  const minInputValue = {
    qty: 0,
    price: 0,
  };

  return (
    <PlainButton
      onClick={() => {
        if (canEdit[type]) {
          setIsEditing(true);
        }
      }}
      className={cx(
        "font-secondary h-full w-full cursor-default truncate px-4 text-left",
        {
          "cursor-pointer": canEdit[type],
          "border-primary-200 border-[2px] border-solid": isEditing,
          "px-3": type === "qty" && isEditing,
          "px-0": type === "price" && isEditing,
        }
      )}
    >
      {!isEditing ? (
        <span className="w-full text-left">
          {type === "price" && (
            <span>
              {priceDisplay(
                item.discount?.discount
                  ? undiscountedPriceInCents(
                      item.discount.discount,
                      item.price
                    ) || 0
                  : item.price * 100,
                entity.currency
              )}{" "}
            </span>
          )}
          {type === "qty" && item.qty}
          {type === "qty" &&
            ` ${(item.category === "item" && item.unit) || ""}`}
          {type === "price" && (
            <span className="text-grey-700 text-sm">
              /{item.category === InventoryItemCategory.item && item.unit}
              {type === "price" &&
                item.category === "service" &&
                t({
                  id: "tasks-page.order-details-service-cell-slash",
                  defaultMessage: "service",
                })}
            </span>
          )}
        </span>
      ) : (
        <div
          className={cx("flex h-full w-full items-center justify-evenly", {
            "-ml-1.5": type === "price",
          })}
        >
          <div>
            {type === "price" && entity.currency && (
              <span className="font-primary text-[#828282]">
                {currencyCodeToSymbol(entity.currency)}
              </span>
            )}

            <input
              max={MAX_NUMBER_VALUE}
              type="text"
              value={value}
              min={minInputValue[type]}
              onKeyDown={(e) => {
                preventIncorrectNumberInputCharacters(e, type === "price");
                if (e.key === "Enter") {
                  setIsEditing(false);
                  onEdit?.({
                    item,
                    value: Number(
                      parseFloat(value.replace(",", ".")).toFixed(
                        type === "price" ? 2 : 0
                      )
                    ),
                    type,
                  });
                }
              }}
              onChange={(e) => {
                const hasMultipleDecimals = [
                  ...e.target.value.matchAll(/[.,]/g),
                ];
                if (hasMultipleDecimals.length > 1) {
                  return;
                }
                setValue(e.target.value);
              }}
              onBlur={() => {
                setIsEditing(false);
                onEdit?.({
                  item,
                  value: Number(
                    parseFloat(value.replace(",", ".")).toFixed(
                      type === "price" ? 2 : 0
                    )
                  ),
                  type,
                });
              }}
              className="text-dark font-secondary h-full w-[83px] border-none bg-transparent text-sm outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
              autoFocus
            />
          </div>
          <div className="text-grey-700 flex h-full min-w-[40px] items-center truncate text-sm">
            {item.category === InventoryItemCategory.item && item.unit}
          </div>
        </div>
      )}
    </PlainButton>
  );
};
