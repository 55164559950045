import { UserRole } from "@jugl-web/rest-api";

interface UserRoleFlags {
  isAdmin: boolean;
  isMember: boolean;
  isTaskEmployee: boolean;
  isTaskManager: boolean;
  isTravelLogEmployee: boolean;
  isChatManager: boolean;
  isChatEmployee: boolean;
  hasManagerLikeRole: boolean;
  hasEmployeeLikeRole: boolean;
}

const FALLBACK_FLAGS: UserRoleFlags = {
  isAdmin: false,
  isMember: false,
  isTaskEmployee: false,
  isTaskManager: false,
  isTravelLogEmployee: false,
  isChatManager: false,
  isChatEmployee: false,
  hasManagerLikeRole: false,
  hasEmployeeLikeRole: false,
};

/**
 *
 * @param role Accepts a UserRole enum value or undefined so it can be used conveniently with hooks
 * @returns An object with flags that represent the user's role or a fallback object if the role is undefined
 */
export const getUserRoleFlags = (role: UserRole | undefined): UserRoleFlags => {
  if (!role) {
    return FALLBACK_FLAGS;
  }

  // Base flags
  const isAdmin = role === UserRole.admin;
  const isMember = role === UserRole.member;
  const isTaskEmployee = role === UserRole.taskEmployee;
  const isTaskManager = role === UserRole.taskManager;
  const isTravelLogEmployee = role === UserRole.travelLogEmployee;
  const isChatManager = role === UserRole.chatManager;
  const isChatEmployee = role === UserRole.chatEmployee;

  // Derived flags
  const hasManagerLikeRole = isTaskManager || isChatManager;

  const hasEmployeeLikeRole =
    isTaskEmployee || isTravelLogEmployee || isChatEmployee;

  return {
    isAdmin,
    isMember,
    isTaskEmployee,
    isTaskManager,
    isTravelLogEmployee,
    isChatManager,
    isChatEmployee,
    hasManagerLikeRole,
    hasEmployeeLikeRole,
  };
};
