export enum WhatsappTemplateCategory {
  message = "MARKETING",
  tasks = "UTILITY",
}

export enum WhatsappTemplateStatus {
  approved = "APPROVED",
  pending = "PENDING",
  rejected = "REJECTED",
  deleted = "DELETED",
}

export interface WhatsappTemplateValues {
  name: string;
  action?: string;
  header: string;
  bodyPlain: string;
  bodyHtml: string;
  footer: string;
}

export type WhatsappTemplatePayload = Pick<
  WhatsappTemplateValues,
  "name" | "action"
> & {
  category?: WhatsappTemplateCategory;
  template: {
    components: {
      text: string;
      type: string;
      format?: string;
      example?: {
        body_text: string[][];
      };
    }[];
  };
};

export enum WhatsappAgentLogStatus {
  sent = "sent",
  failed = "failed",
  payment_error = "payment_error",
}
