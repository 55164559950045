import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { PageRoute } from "@web-src/features/app/types";
import { useTranslations, cx } from "@jugl-web/utils";
import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { Buffer } from "buffer";
import { PlainButton } from "@jugl-web/ui-components";
import { ChatMessagePayload } from "@jugl-web/rest-api";
import { ReactComponent as LogoIcon } from "./images/logo.svg";
import { ReactComponent as VideoMeetingIcon } from "./images/video-meeting.svg";
import { ReactComponent as CloseIcon } from "./images/close.svg";

const ChatMessageCall: React.FC<{
  conference: ChatMessagePayload["conference"];
  isOutgoing: boolean;
  reply?: boolean;
  preview?: boolean;
  onCancel?: () => void;
}> = ({ conference, isOutgoing, reply, preview, onCancel }) => {
  const { entity } = useEntity();
  const { t } = useTranslations();
  const base64 = useMemo(() => {
    const base64JSON = Buffer.from(
      JSON.stringify(conference),
      "utf-8"
    ).toString("base64");
    return base64JSON;
  }, [conference]);
  const formattedDate = useMemo(() => {
    if (!conference?.date && !conference?.time) {
      return null;
    }
    return (
      <Text
        variant="body2"
        className={cx({
          "text-white": isOutgoing,
          "text-dark": (!isOutgoing && !reply) || reply,
          "text-grey-900": preview,
        })}
      >
        {` ${conference?.date}, ${t({
          id: "common.at",
          defaultMessage: "at",
        })}
        ${conference?.time}`}
      </Text>
    );
  }, [conference?.date, conference?.time, isOutgoing, preview, reply, t]);
  const Content = () => (
    <>
      <Text
        variant="body2"
        className={cx("text-grey-900", { hidden: !preview })}
      >
        {conference?.name}. {formattedDate}
      </Text>
      <div
        className={cx(
          "mb-2 flex max-w-[300px] flex-col rounded border-0 p-0 text-lg text-white shadow",
          {
            "mt-4": preview,
          }
        )}
      >
        <div className="relative">
          {onCancel && (
            <PlainButton
              onClick={onCancel}
              className="bg-primary-400 absolute top-2 right-2 flex h-[24px] w-[24px] items-center justify-center rounded-full hover:brightness-90"
            >
              <CloseIcon />
            </PlainButton>
          )}
          <div
            className={cx(
              "bg-primary-400 flex w-full flex-col items-start gap-1 py-2 px-4",
              {
                "bg-primary": preview,
              }
            )}
          >
            <LogoIcon />
            <Text variant="body3">
              {conference?.time
                ? t({
                    id: "chats-page.call-bubble.scheduled-video-meeting",
                    defaultMessage: "Scheduled video meeting",
                  })
                : t({
                    id: "chats-page.join-video-meeting",
                    defaultMessage: "Join video meeting",
                  })}
            </Text>
          </div>
          <VideoMeetingIcon className="bg-white" />
        </div>
      </div>
      <Text
        variant="body2"
        className={cx({
          "text-white": isOutgoing,
          "text-dark": (!isOutgoing && !reply) || reply,
          hidden: preview,
        })}
      >
        {conference?.name ? `${conference.name}.` : ""}
        {conference?.name &&
          !conference.date &&
          t({
            id: "chats-page.call-bubble-description",
            defaultMessage: "Tap to join",
          })}
      </Text>
      {!preview && formattedDate}
    </>
  );
  if (preview) {
    return <div>{Content()}</div>;
  }
  return (
    <Link
      to={{
        pathname: `/${entity?.id}/${PageRoute.conference}/${conference?.channel}`,
        search: `?i=${base64}`,
      }}
      className="cursor-pointer no-underline"
      target="_blank"
    >
      {Content()}
    </Link>
  );
};

export default memo(ChatMessageCall);
