import { useRestApiProvider } from "@jugl-web/rest-api";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { TaskTemplatesContent } from "@web-src/features/tasks/TaskTemplatesContent";
import { TaskTemplatesControlBar } from "@web-src/features/tasks/TaskTemplatesControlBar";
import { TaskTemplatesPageContextProvider } from "@web-src/features/tasks/TaskTemplatesPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const TaskTemplates = () => {
  const { entity } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);
  const { folderId } = useParams<{ folderId: string }>();

  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  const { tasksTemplatesApi } = useRestApiProvider();

  const { folder } = tasksTemplatesApi.useGetTemplateFoldersQuery(
    { entityId: entity.id },
    {
      selectFromResult: ({ data }) => ({
        folder: data?.find((f) => f.id === folderId),
      }),
    }
  );

  const hasRequiredParams = !!(meId && folderId);

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(
    () => [
      {
        title: t({
          id: "tasks-page.templates",
          defaultMessage: "Templates",
        }),
        href: generateUrl("tasksTemplates"),
      },
      {
        title: folder
          ? folder.name
          : t({
              id: "tasks-page.template",
              defaultMessage: "Template",
            }),
      },
    ],
    [folder, generateUrl, t]
  );

  return (
    <TaskTemplatesPageContextProvider>
      <TasksPageLayout breadcrumbs={breadcrumbs}>
        {hasRequiredParams && (
          <>
            <TaskTemplatesControlBar
              entityId={entity.id}
              meId={meId}
              folderId={folderId}
            />
            <TaskTemplatesContent
              entityId={entity.id}
              meId={meId}
              folderId={folderId}
              role={entity.role}
            />
          </>
        )}
      </TasksPageLayout>
    </TaskTemplatesPageContextProvider>
  );
};
