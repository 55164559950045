import { MobileDrawer, PlainButton } from "@jugl-web/ui-components";
import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import { SwipeableHeader } from "@jugl-web/ui-components/mobile/MobileDrawer/SwipeableHeader";
import { MobileDrawerHeader } from "@jugl-web/ui-components/mobile/MobileDrawer/types";
import {
  NoParameters,
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import React, { useEffect, useMemo, useState } from "react";
import { priceDisplay, useTranslations } from "@jugl-web/utils";
import { ServiceCartList } from "../ServiceCartList";
import { ServiceCartItem } from "../../types";
import { OrderFormInventorySelectValueItem } from "../../../OrderFormInventorySelect";
import { ReactComponent as CartIcon } from "../../assets/cart.svg";
import { DeleteConfirmationScreen } from "./components/DeleteConfirmationScreen";

interface ServiceCartDrawerProps {
  items: ServiceCartItem[];
  isPreview: boolean;
  currency: string;
  discounts: Record<string, { discount: string; value: number }>;
  value: OrderFormInventorySelectValueItem[];
  total: number;
  onUpdateService: (serivceId: string, newValue: number) => void;
  onRemoveService: (serivceId: string) => void;
  onChange: (value: OrderFormInventorySelectValueItem[]) => void;
}

type ServiceCartScreenToParametersMap = {
  entry: NoParameters;
  deleteConfirmation: NoParameters;
};

const INITIAL_SCREEN: Screen<ServiceCartScreenToParametersMap> = {
  name: "entry",
};

export const ServiceCartDrawerContent: React.FC<ServiceCartDrawerProps> = ({
  items,
  isPreview,
  currency,
  discounts,
  value,
  total,
  onUpdateService,
  onRemoveService,
  onChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslations();

  // We close the cart drawer if all selected services have been removed from the order
  useEffect(() => {
    if (!value.length) {
      setIsDrawerOpen(false);
    }
  }, [value]);

  const { screen, renderContent, transitionTo } =
    useScreenTransitionManager<ServiceCartScreenToParametersMap>();

  const drawerHeader = useMemo(
    () =>
      renderContent<MobileDrawerHeader>({
        entry: {
          title: t({
            id: "order-form-submission-component.order",
            defaultMessage: "Order",
          }),
          hasCloseButton: true,
        },
        deleteConfirmation: { title: "" },
      }),
    [renderContent, t]
  );

  const content = useMemo(
    () =>
      renderContent({
        entry: (
          <>
            <ServiceCartList
              currency={currency}
              discounts={discounts}
              isPreview={isPreview}
              items={items}
              value={value}
              onRemoveService={onRemoveService}
              onUpdateService={onUpdateService}
            />
            <div className="bg-grey-100 flex items-center justify-between py-[14px] px-6">
              <span
                className="text-gradients-danger font-secondary text-xs leading-[140%] tracking-[0.12px] underline"
                onClick={() => transitionTo({ name: "deleteConfirmation" })}
              >
                {t({
                  id: "order-form-submission-component.clear-all",
                  defaultMessage: "Clear All",
                })}
              </span>
              <span className="text-dark text-lg font-medium leading-[140%] tracking-[0.18px]">
                {t(
                  {
                    id: "order-form-submission-component.total-cost",
                    defaultMessage: "Total: {cost}",
                  },
                  { cost: priceDisplay(total * 100, currency) }
                )}
              </span>
            </div>
          </>
        ),
        deleteConfirmation: (
          <DeleteConfirmationScreen
            count={items.length}
            onDelete={() => onChange([])}
            onCancel={() => transitionTo({ name: "entry" })}
          />
        ),
      }),
    [
      total,
      onChange,
      transitionTo,
      renderContent,
      items,
      isPreview,
      currency,
      discounts,
      value,
      t,
      onUpdateService,
      onRemoveService,
    ]
  );

  return (
    <>
      {!!value.length && (
        <PlainButton
          className="border-grey/10 fixed bottom-[120px] right-5 flex h-20 w-20 items-center justify-center rounded-2xl border-4 border-solid bg-white shadow-[0px_12px_16px_rgba(25,25,25,0.14)] outline-none"
          onClick={() => setIsDrawerOpen(true)}
        >
          <div className="relative h-max w-max">
            <CartIcon />
            <div className="bg-gradients-danger absolute -right-1 -top-1 rounded-[10px] border border-solid border-white py-0.5 px-2">
              <span className="font-secondary text-[10px] font-bold leading-[15px] text-white">
                {items.length}
              </span>
            </div>
          </div>
        </PlainButton>
      )}
      <MobileDrawer
        isOpen={isDrawerOpen}
        size="auto"
        animateHeight
        onClose={() => setIsDrawerOpen(false)}
        onTransitionEnd={() => {
          if (!isDrawerOpen) {
            transitionTo({ name: "entry" });
          }
        }}
      >
        <ScreenTransitionWrapper
          screenName={screen.name}
          transitionType="fade"
          transitionTime="fast"
        >
          <SwipeableHeader
            {...drawerHeader}
            onClose={() => setIsDrawerOpen(false)}
          />
          <MobileDrawer.Content className="p-0">{content}</MobileDrawer.Content>
        </ScreenTransitionWrapper>
      </MobileDrawer>
    </>
  );
};

export const ServiceCartDrawer: React.FC<ServiceCartDrawerProps> = (props) => (
  <ScreenTransitionManagerProvider initialScreen={INITIAL_SCREEN}>
    <ServiceCartDrawerContent {...props} />
  </ScreenTransitionManagerProvider>
);
