import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { Avatar, Checkbox } from "@jugl-web/ui-components/cross-platform";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import {
  ChatMessageAttachmentType,
  ChatMessageModel,
} from "@jugl-web/rest-api";
import { isChatMessageEmojiOnly } from "@web-src/modules/chats/utils";
import { setOpenedUserProfile } from "@web-src/modules/chats/store/chatsSlice";
import { useDispatch } from "react-redux";
import { MediaPreviewAttachment } from "./components/MediaPreviewAttachment";
import { ChatMessageBody } from "./components/ChatMessageBody";
import { ChatMessageFooter } from "./components/ChatMessageFooter";
import { useChatMessageProvider } from "../../providers/ChatMessageProvider";
import { FileAttachment } from "./components/FileAttachment";
import { DirectGifAttachment } from "./components/DirectGifAttachment";
import { AudioAttachment } from "./components/AudioAttachment";
import { LinkPreview } from "./components/LinkPreview";
import { ReplyMessagePreview } from "./components/ReplyMessagePreview";
import { ContextMenu } from "./components/ContextMenu";
import { Reactions } from "./components/Reactions";
import { VideoMeetingAttachment } from "./components/VideoMeetingAttachment";
import { ReactComponent as ForwardedIncomingIcon } from "./assets/forwarded-icon-incoming.svg";
import { ReactComponent as ForwardedOutgoingIcon } from "./assets/forwarded-icon.svg";

export type ChatMessageProps = {
  messageId: string;
  chatId: string;
};

export const ChatMessageBubble: FC<{
  onReplyPreviewClick?: (message: ChatMessageModel) => void;
  isHighlighted?: boolean;
  isPreview?: boolean;
}> = ({ onReplyPreviewClick, isHighlighted, isPreview }) => {
  const {
    message,
    isOutgoing,
    isGroupChat,
    entityId,
    previewUrl,
    readOnly,
    isSelectable,
    onSelect,
    isSelected,
    isMessageActionsDisabled,
    groupInfo,
  } = useChatMessageProvider();

  const { t } = useTranslations();
  const dispatch = useDispatch();

  const attachment = message.payload.attachments?.[0];
  const messageBodyStr = message.payload.body || attachment?.caption || null;
  const isEmojiOnly = useMemo(() => isChatMessageEmojiOnly(message), [message]);

  const { profile: userProfile, safeProfile: userSafeProfile } =
    useUserGeneralProfile({
      entityId,
      userId: message.from,
      skip: !isGroupChat,
    });

  const $attachment = useMemo(() => {
    if (message.payload.conference) {
      return <VideoMeetingAttachment conference={message.payload.conference} />;
    }
    if (!attachment) {
      return null;
    }
    switch (attachment.type) {
      case ChatMessageAttachmentType.image:
      case ChatMessageAttachmentType.gif:
      case ChatMessageAttachmentType.video:
        return <MediaPreviewAttachment />;
      case ChatMessageAttachmentType.gifDirectUrl:
        return <DirectGifAttachment />;
      case ChatMessageAttachmentType.otherFile:
      case ChatMessageAttachmentType.doc:
        return (
          <div className={cx("h-10 px-1")}>
            <FileAttachment />
          </div>
        );
      case ChatMessageAttachmentType.voice:
      case ChatMessageAttachmentType.audio:
        return <AudioAttachment />;
      default:
        return (
          <div className="flex h-[80px] max-w-[300px] items-center justify-center rounded bg-gray-50 px-8 text-center text-sm text-gray-500">
            {/* TODO: i18n */}
            Attachment type temporarily not supported
          </div>
        );
    }
  }, [attachment, message.payload.conference]);

  return (
    <div
      className={cx(
        "group relative flex items-end px-[56px] py-[3px] transition-colors",
        {
          "justify-end": isOutgoing,
          "hover:bg-primary-50 cursor-pointer": isSelectable,
          "bg-primary-50": isHighlighted || isSelected,
        }
      )}
      onClick={
        isSelectable
          ? (e) => {
              if (!message.payload.client_msg_id) {
                return;
              }
              e.stopPropagation();
              onSelect?.(message.payload.client_msg_id, !isSelected);
            }
          : undefined
      }
    >
      {isSelectable && (
        <div className={cx(isOutgoing ? "mr-auto" : "mr-4")}>
          <div className="pr-4">
            <Checkbox
              isChecked={isSelected}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) =>
                message.payload.client_msg_id &&
                onSelect?.(message.payload.client_msg_id, e.target.checked)
              }
            />
          </div>
        </div>
      )}
      {!isOutgoing && isGroupChat && !isPreview && (
        <Avatar
          imageUrl={userProfile?.avatar}
          username={userProfile?.displayName || userSafeProfile.displayName}
          size="sm"
          className={cx("mr-2", { invisible: groupInfo && !groupInfo.isLast })}
          onClick={() => dispatch(setOpenedUserProfile(userProfile?.id))}
        />
      )}
      <div
        className={cx(
          "relative min-w-[100px] max-w-[500px] rounded-t-lg px-1 pt-1 pb-[6px]",
          {
            "shadow-[0px 0px 24px 0px rgba(0, 0, 0, 0.05)] rounded-br-lg  bg-white":
              !isOutgoing,
            "shadow-[0px 1px 2px 0px rgba(0, 0, 0, 0.17)] rounded-bl-lg  bg-[#4E93CA]":
              isOutgoing,
            "mb-5": !!message.reactions?.length,
            "rounded-tr-none":
              groupInfo && groupInfo.isLast && !groupInfo.isFirst,
            "rounded-br-none":
              groupInfo && groupInfo.isFirst && !groupInfo.isLast,
            "w-full max-w-[400px]":
              !message.deleted &&
              attachment?.type === ChatMessageAttachmentType.voice,
          }
        )}
      >
        {!message.deleted && !isSelectable && !isMessageActionsDisabled && (
          <ContextMenu />
        )}
        {message.payload?.forwarded ? (
          <div className="flex flex-row items-center gap-[2px] opacity-70">
            {isOutgoing ? <ForwardedOutgoingIcon /> : <ForwardedIncomingIcon />}
            <span
              className={cx(
                "text-xs font-medium",
                isOutgoing ? "text-grey-100" : "text-grey-900"
              )}
            >
              {t({
                id: "chats-page.forwarded",
                defaultMessage: "Forwarded",
              })}
            </span>
          </div>
        ) : null}
        {!isOutgoing && isGroupChat && (!groupInfo || groupInfo.isFirst) && (
          <div className="text-primary-500 mb-[2px] px-1 text-[15px] font-medium leading-[140%]">
            {userProfile?.displayName || userSafeProfile.displayName}
          </div>
        )}
        {message.deleted ? (
          <div
            className={cx(
              "text-dark-600 px-1 text-[15px] italic leading-[130%]",
              { "text-dark-600": !isOutgoing, "text-white": isOutgoing }
            )}
          >
            {/* TODO: i18n */}
            Message deleted by owner
          </div>
        ) : (
          <>
            {message.payload.reply_attributes_map && (
              <ReplyMessagePreview
                onClick={() =>
                  !isSelectable ? onReplyPreviewClick?.(message) : null
                }
              />
            )}
            {!attachment && !!previewUrl && <LinkPreview url={previewUrl} />}
            {$attachment ? (
              <div className={cx({ "relative mb-1": messageBodyStr })}>
                {isSelectable && (
                  <div className="absolute top-0 left-0 bottom-0 right-0 z-10" />
                )}
                {$attachment}
              </div>
            ) : null}
            {messageBodyStr && (
              <div className="whitespace-pre-wrap break-words px-1">
                <ChatMessageBody
                  isEmojiOnly={isEmojiOnly}
                  isOutgoing={isOutgoing}
                  body={messageBodyStr}
                />
              </div>
            )}
            {!!message.reactions?.length &&
              !readOnly &&
              !isMessageActionsDisabled && <Reactions />}
          </>
        )}
        <ChatMessageFooter />
      </div>
    </div>
  );
};
