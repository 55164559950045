import { LiveUpdateEvent } from "@jugl-web/domain-resources/live-updates";
import { TaskLiveUpdatesProvider } from "@jugl-web/domain-resources/tasks/components/TaskLiveUpdatesProvider";
import { cx, usePrevious, useTabIsActive } from "@jugl-web/utils";
import IncomingCallBanner from "@web-src/components/IncomingCallBanner";
import { LiveUpdatesListener } from "@web-src/components/LiveUpdatesListener";
import { UnreadIndicatorsProvider } from "@web-src/components/UnreadIndicatorsProvider";
import { useApi } from "@web-src/features/api/useApi";
import { selectIsSideBarVisible } from "@web-src/features/app/appSlice";
import { HomeSidebar } from "@web-src/features/app/components/HomeSidebar";
import { MainNavBar } from "@web-src/features/app/components/MainNavBar";
import { TabRoute } from "@web-src/features/app/types";
import { useUpdateTokenMutation } from "@web-src/features/auth/authApi";
import {
  selectAuthState,
  selectUserId,
} from "@web-src/features/auth/authSlice";
import { DownloadMobileAppCTA } from "@web-src/modules/common/components/DownloadMobileAppCTA";
import ActiveCallWrapper from "@web-src/modules/conference/pages/ConferencePage/components/ActiveCallWrapper";
import { WelcomeToEntityAlert } from "@web-src/modules/entities/components/WelcomeToEntityAlert";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { AppRoutes } from "@web-src/modules/navigation/components/AppRoutes";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { usePhoenixSocket } from "@web-src/modules/socket/providers/PhoenixSocket";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useNetworkState } from "react-use";
import { Subject } from "rxjs";

const liveUpdateEvents$ = new Subject<LiveUpdateEvent>();

const App: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useSelector(selectAuthState);
  const meId = useSelector(selectUserId);
  const {
    showWriteRestrictedAlert,
    subscriptionInfo,
    entity,
    showPlanLimitsReachedAlert,
  } = useEntityProvider();

  useApi({
    onError: (error) => {
      if (!subscriptionInfo?.planIsActive) {
        return;
      }
      if (error.data?.errors === "quota_limit_exceeded") {
        // NOTE: order form submit is handled separately
        if (
          error.url?.includes("/order/form") &&
          error.url?.includes("/submit")
        ) {
          return;
        }
        if (error.url?.includes("/share_link")) {
          showPlanLimitsReachedAlert("clientUpdates");
        } else if (error.url?.includes("/task")) {
          showPlanLimitsReachedAlert("tasks");
        } else if (error.url?.includes("/order/form")) {
          showPlanLimitsReachedAlert("orderForms");
        } else {
          showPlanLimitsReachedAlert("default");
        }
        return;
      }
      if (error.data?.error === "write_restricted") {
        showWriteRestrictedAlert();
        return;
      }
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: error.preventDuplicate,
      });
    },
  });
  const isSideBarVisible = useSelector(selectIsSideBarVisible);

  const [updateToken] = useUpdateTokenMutation();
  const { token } = useFCM();

  useEffect(() => {
    if (!token || !isAuthenticated) {
      return;
    }
    updateToken(token);
  }, [updateToken, token, isAuthenticated]);

  const { online } = useNetworkState();
  const previousOnline = usePrevious(online);
  useEffect(() => {
    // make sure app will be reloaded after user wakes up
    const wakeupWorker = new Worker("detect-wakeup.js");
    wakeupWorker.onmessage = (ev) => {
      if (ev?.data === "app:wakeup") {
        window.location.reload();
      }
    };
  }, []);
  useEffect(() => {
    if (previousOnline !== undefined && !previousOnline && online) {
      window.location.reload();
    }
  }, [online, previousOnline]);

  const { channel } = usePhoenixSocket();
  const tabIsActive = useTabIsActive();
  const previousTabIsActive = usePrevious(tabIsActive);
  useEffect(() => {
    if (tabIsActive === previousTabIsActive) {
      return;
    }
    channel?.push(
      "device_status",
      tabIsActive ? { status: null } : { status: "offline" }
    );
  }, [channel, tabIsActive, previousTabIsActive]);

  const $authenticatedContent = useMemo(
    () =>
      entity && isSideBarVisible && subscriptionInfo?.planIsActive ? (
        <>
          <ActiveCallWrapper />
          <div className="fixed top-0 left-0 flex h-[100vh] w-[100vw] min-w-0 flex-col">
            <MainNavBar />
            <span className="flex h-[calc(100%-_48px)] min-w-0">
              <HomeSidebar />
              <div className="h-full w-full min-w-0 grow overflow-y-auto">
                <AppRoutes />
              </div>
            </span>
          </div>
          <WelcomeToEntityAlert />
        </>
      ) : (
        <AppRoutes />
      ),
    [entity, isSideBarVisible, subscriptionInfo?.planIsActive]
  );

  const taskDetailsPageMatch = useMatch(
    `/:entityId/${TabRoute.tasks}/details/:taskId`
  );

  const isInTaskDetailsView = !!taskDetailsPageMatch;

  return (
    <>
      {!online && (
        <div className="bg-tertiary-500 fixed top-10 left-[50%] z-50 -translate-x-[50%] rounded py-5 px-12 text-center font-bold text-white">
          No internet connection, please reconnect to use Jugl
        </div>
      )}
      <DownloadMobileAppCTA />
      <div className={cx({ "opacity-50": !online })}>
        {isAuthenticated ? (
          <>
            <IncomingCallBanner />
            <LiveUpdatesListener events$={liveUpdateEvents$} />
            <TaskLiveUpdatesProvider
              events$={liveUpdateEvents$}
              meId={meId}
              isInTaskDetailsView={isInTaskDetailsView}
            >
              <UnreadIndicatorsProvider>
                {$authenticatedContent}
              </UnreadIndicatorsProvider>
            </TaskLiveUpdatesProvider>
          </>
        ) : (
          <AppRoutes />
        )}
      </div>
    </>
  );
};

export default App;
