import {
  HeadlessUsersList,
  HeadlessUsersListHandle,
} from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import {
  LoadingSpinner,
  PlainButton,
  SidebarDrawer,
} from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { useRestApiProvider, UserGeneralProfile } from "@jugl-web/rest-api";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { GroupAdminManagementAlert } from "../GroupAdminManagementAlert";
import { ChatAdminsPopover } from "./components/ChatAdminsPopover";

export const ChatAdminsDrawer: React.FC<{
  isOpen: boolean;
  chatId: string;
  onRequestClose: () => void;
}> = ({ isOpen, chatId, onRequestClose }) => {
  const { t } = useTranslations();
  const meId = useSelector(selectUserId);
  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, inputProps, reset } = useSearchInput();
  const removeGroupAdminAlertState = useConfirmationDialogState<{
    userId: string;
  }>();
  const { workspacesApi } = useRestApiProvider();
  const [changeRoleWorkspaceParticipants] =
    workspacesApi.useChangeWorkspaceParticipantsRoleMutation();
  const $headlessUsersList = useRef<HeadlessUsersListHandle | null>(null);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleSelect = useCallback(
    (user: UserGeneralProfile) => {
      removeGroupAdminAlertState.open({
        metadata: {
          userId: user.id,
        },
        onConfirm: async () => {
          const response = await changeRoleWorkspaceParticipants({
            entityId,
            workspaceId: chatId,
            users: [user.id],
            role: null,
          });

          if (response && "data" in response) {
            setTimeout(() => {
              $headlessUsersList.current?.refetch();
            }, 500);
          }
        },
      });
    },
    [
      changeRoleWorkspaceParticipants,
      chatId,
      entityId,
      removeGroupAdminAlertState,
    ]
  );

  return (
    <>
      <SidebarDrawer
        onClose={onRequestClose}
        isOpen={isOpen}
        className="w-[400px]"
        hasBackdrop={false}
        customHeader={
          <div className="flex items-center justify-between py-[7px] px-8 shadow-[0px_0px_8px_rgba(0,0,0,0.08)]">
            <div className="flex items-center gap-2">
              <PlainButton onClick={onRequestClose}>
                <ArrowIcon />
              </PlainButton>
              <span className="font-secondary leading-2 text-[15px] font-semibold text-[#333]">
                {t({
                  id: "chats-page.group-admins",
                  defaultMessage: "Group Admins",
                })}
              </span>
            </div>
            <ChatAdminsPopover
              chatId={chatId}
              onSuccess={() => {
                setTimeout(() => {
                  $headlessUsersList.current?.refetch();
                }, 500);
              }}
            />
          </div>
        }
      >
        <SidebarDrawer.Content className="flex flex-col gap-4 py-6">
          <div className="px-6">
            <SearchInput
              variant="filled"
              color="grey"
              {...inputProps}
              onReset={reset}
              onClear={reset}
            />
          </div>
          <HeadlessUsersList
            fetchParams={{
              entityId,
              workspaceId: chatId,
              role: "admin",
              searchQuery,
            }}
            ref={$headlessUsersList}
            userRenderer={(user) => (
              <div className="px-8 pb-2.5">
                <UserListItem
                  mode="delete"
                  onSelect={handleSelect}
                  entityId={entityId}
                  userId={user.id}
                  variant="web"
                  highlightText={searchQuery}
                  defaultSubTitle="department"
                  isDisabled={user.id === meId}
                  isMe={user.id === meId}
                />
              </div>
            )}
            emptyContent={
              <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                {t({
                  id: "common.no-users-found",
                  defaultMessage: "No users found",
                })}
              </div>
            }
            loadingContent={
              <div className="flex h-full w-full items-center justify-center">
                <LoadingSpinner />
              </div>
            }
            virtualized
          />
        </SidebarDrawer.Content>
      </SidebarDrawer>
      <GroupAdminManagementAlert
        isOpen={removeGroupAdminAlertState.isOpen}
        onRequestClose={removeGroupAdminAlertState.close}
        onConfirm={() =>
          removeGroupAdminAlertState.confirm({ closeOnceConfirmed: true })
        }
        userId={removeGroupAdminAlertState.metadata?.userId}
        type="remove"
      />
    </>
  );
};
