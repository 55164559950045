import { ListBoxProps } from "@jugl-web/ui-components/cross-platform";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { HeadlessUserItem } from "../../../users/hooks/useHeadlessUsersList";
import { useUserGeneralProfile } from "../../../users/hooks/useUserGeneralProfile";
import { useUserListBox } from "../../../users/hooks/useUserListBox";
import {
  TaskChecklistItemButton,
  TaskChecklistItemButtonProps,
} from "./TaskChecklistItemButton";
import { ReactComponent as AddAssigneeIcon } from "./icons/add-assignee.svg";
import { TaskChecklistItemMode } from "./types";
import { AppModule } from "../../../common/types";

interface AssigneePickerButtonProps {
  itemMode: TaskChecklistItemMode;
  entityId: string;
  assigneeId: string | null;
  onlyReportees?: boolean;
  isDisabled?: boolean;
  onAssigneeIdChange: (assigneeId: string | null) => void;
}

export const AssigneePickerButton: FC<AssigneePickerButtonProps> = ({
  itemMode,
  entityId,
  assigneeId,
  onlyReportees,
  isDisabled,
  onAssigneeIdChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const { safeProfile } = useUserGeneralProfile({
    entityId,
    userId: assigneeId || undefined,
    skip: !assigneeId,
  });

  const { getListBoxProps, getTitleProps, clearSearchQuery, clearFilter } =
    useUserListBox({
      entityId,
      skipLoading: isDisabled,
      onlyReportees,
      module: AppModule.tasks,
      showDepartmentFilters: true,
      topUsers: assigneeId ? [assigneeId] : undefined,
    });

  const commonButtonProps: TaskChecklistItemButtonProps = {
    itemMode,
    isDisabled,
    className: "overflow-hidden",
    children: assigneeId ? (
      <div className="flex max-w-[150px] items-center  gap-1.5 truncate">
        <Avatar
          size="xs"
          className="shrink-0"
          username={safeProfile.displayName}
          imageUrl={safeProfile.avatar}
        />
        <span className="@lg:block hidden truncate">
          {safeProfile.displayName}
        </span>
      </div>
    ) : (
      <AddAssigneeIcon className="text-grey shrink-0" />
    ),
  };

  const commonResourcePickerProps = {
    selectionBehavior: { mode: "single", canToggle: true },
    defaultSelectedIds: assigneeId ? [assigneeId] : undefined,
  } satisfies Partial<ListBoxProps<HeadlessUserItem>>;

  if (isMobile) {
    return (
      <>
        <TaskChecklistItemButton
          onClick={(event) => {
            event.stopPropagation();
            setIsDialogOpen(true);
          }}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          title={t({
            id: "tasks-page.select-assignee",
            defaultMessage: "Select assignee",
          })}
          isHeightFixed
          onSelect={({ item, isSelected }) => {
            onAssigneeIdChange(isSelected ? item.id : null);
            setIsDialogOpen(false);
          }}
          onClose={() => setIsDialogOpen(false)}
          onTransitionEnd={() => {
            if (!isDialogOpen) {
              clearSearchQuery();
              clearFilter();
            }
          }}
          {...getListBoxProps(commonResourcePickerProps)}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[315px]"
      isHeightFixed
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskChecklistItemButton}
          {...commonButtonProps}
        />
      )}
      {...getListBoxProps({
        itemSize: "md",
        spaceBetweenItems: "compact",
        maxVisibleItems: 6,
        ...commonResourcePickerProps,
      })}
      {...(onlyReportees ? getTitleProps() : {})}
      onSelect={({ item, isSelected, onClose }) => {
        onAssigneeIdChange(isSelected ? item.id : null);
        onClose();
      }}
      onUnmount={() => {
        clearSearchQuery();
        clearFilter();
      }}
    />
  );
};
