import { isAPIError, useToast, useTranslations } from "@jugl-web/utils";
import { TaskListErrorScreen } from "@web-src/components/TaskListErrorScreen";
import {
  TaskListErrorCode,
  TaskListErrorType,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskListError";
import { tasksApi } from "@web-src/features/api/createApi";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { SidebarDrawer, Tooltip } from "@jugl-web/ui-components";
import {
  DataLoadingWrapper,
  Text,
} from "@jugl-web/ui-components/cross-platform";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { TaskCreator } from "@jugl-web/domain-resources/tasks/components/TaskCreator";
import { ReactComponent as CollapseIcon } from "@web-src/pages/TaskDetails/assets/collapse.svg";
import { ReactComponent as CopyIcon } from "@web-src/pages/TaskDetails/assets/copy.svg";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useUpdateTask } from "@jugl-web/domain-resources/tasks/hooks/useUpdateTask";
import { TaskInfoAccessButton } from "@jugl-web/domain-resources/tasks/components/TaskInfoAccessButton";
import { TaskHoverableButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskHoverableButton";
import { TaskDetailsMoreMenu } from "@web-src/pages/TaskDetails/components/TaskDetailsMoreMenu";
import { TaskDetailsTabs } from "../../../TaskDetails/components/TaskDetailsTabs";
import { ReactComponent as ExpandIcon } from "./assets/expand.svg";

type TaskPreviewSideBarProps = {
  previewTaskId: string;
  entityId: string;
  isOpen: boolean;
};

export const TaskPreviewSideBar = ({
  previewTaskId,
  entityId,
  isOpen,
}: TaskPreviewSideBarProps) => {
  const {
    data: task,
    isLoading,
    error,
    refetch,
  } = tasksApi.useGetTaskQuery({ entityId, taskId: previewTaskId });

  const updateTask = useUpdateTask({ entityId, taskId: previewTaskId });

  const isError = !!error || !!task?._is_deleted;

  const { navigateToPage } = useNavigation();
  const { meId } = useLoadedMe();
  const permissions = useTaskPermissions({ task, entityId, meId });
  const { setPreviewTaskId, navigateToTaskDetailsPage } = useTasksPageContext();
  const { t } = useTranslations();
  const { toast } = useToast();

  const handleTaskPreviewClose = () => {
    setPreviewTaskId("");
  };

  if (isError && error) {
    const errorType: TaskListErrorType = (() => {
      if (isAPIError(error)) {
        if (error.status === TaskListErrorCode.forbidden) {
          return "forbidden";
        }

        if (error.status === TaskListErrorCode.badRequest) {
          return "bad-request";
        }
      }

      return "unknown";
    })();

    return (
      <TaskListErrorScreen
        errorType={errorType}
        onNavigateToMyTasks={() =>
          navigateToPage("tasksTasks", { boardId: "my" })
        }
        onRefetch={refetch}
      />
    );
  }

  const handleCopyTaskLink = () => {
    if (!task) {
      return;
    }
    navigator.clipboard.writeText(task.short_url).then(() => {
      toast(
        t({
          id: "feedback.task-link-copied",
          defaultMessage: "Task link has been copied to clipboard",
        })
      );
    });
  };

  const handleTaskExpandButtonClick = () => {
    navigateToTaskDetailsPage(previewTaskId);
  };

  const isRecurring = !!task?.recurrence;

  return (
    <SidebarDrawer
      className="w-[700px]"
      isOpen={isOpen}
      hasBackdrop={false}
      onClose={handleTaskPreviewClose}
      title={t({
        id: "task-preview-sidebar.title",
        defaultMessage: "Task info",
      })}
      customHeader={
        task && (
          <div className="flex flex-row items-center justify-between gap-2 py-4 px-6 shadow-sm">
            <div className="flex items-center gap-2">
              <CollapseIcon
                className="cursor-pointer"
                onClick={handleTaskPreviewClose}
              />
              <Text variant="body3" className="text-[18px] font-bold">
                {t({
                  id: "task-preview-sidebar.title",
                  defaultMessage: "Task info",
                })}
              </Text>
            </div>
            <div className="flex shrink-0 items-center gap-4">
              <Tooltip
                className="w-[110px] text-center"
                renderTrigger={({ ref, props }) => (
                  <TaskHoverableButton
                    ref={ref}
                    className="w-8"
                    onClick={handleTaskExpandButtonClick}
                    {...props}
                  >
                    <ExpandIcon />
                  </TaskHoverableButton>
                )}
              >
                {t({
                  id: "task-preview-sidebar.expand-icon-title",
                  defaultMessage: "Go to task details",
                })}
              </Tooltip>
              {meId && task && (
                <TaskInfoAccessButton
                  task={task}
                  entityId={entityId}
                  meId={meId}
                />
              )}
              <Tooltip
                className="w-[110px] text-center"
                renderTrigger={({ ref, props }) => (
                  <TaskHoverableButton
                    ref={ref}
                    className="w-8"
                    onClick={handleCopyTaskLink}
                    {...props}
                  >
                    <CopyIcon />
                  </TaskHoverableButton>
                )}
              >
                {t({
                  id: "tasks-page.copy-task-link",
                  defaultMessage: "Copy task link",
                })}
              </Tooltip>
              <TaskDetailsMoreMenu
                onClearPreview={setPreviewTaskId}
                isPreview
              />
            </div>
          </div>
        )
      }
    >
      <SidebarDrawer.Content className="h-full flex-1">
        <DataLoadingWrapper
          isLoading={isLoading}
          isError={!!error}
          onRetry={refetch}
        >
          {task && (
            <div
              className="base-0 flex h-full flex-1 grow flex-col"
              style={{ WebkitUserSelect: "text" }}
            >
              <TaskCreator
                entityId={entityId}
                userId={task.created_by}
                createdAt={task.created_at}
                className="mb-2 mt-8 px-8"
              />
              <TitleBox
                prefix={task.order_id || undefined}
                isOrder={!!task.order_id}
                title={task.name}
                isEditable={permissions.canEditTitle}
                classes={{
                  titleText: "py-2 mb-10 px-8",
                  editingBox: "mb-1",
                }}
                onChange={(title) => updateTask({ name: title })}
                isPreview
                isRecurring={isRecurring}
              />
              <TaskDetailsTabs isPreview />
            </div>
          )}
        </DataLoadingWrapper>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
