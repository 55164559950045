import { InventoryItem, InventoryItemCategory } from "@jugl-web/rest-api";
import { Checkbox, InteractiveContainer } from "@jugl-web/ui-components";
import { cx, priceDisplay, useTranslations } from "@jugl-web/utils";
import React, { useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { ReactComponent as ImageIcon } from "./assets/image.svg";
import { ReactComponent as CheckedIcon } from "./assets/checked.svg";
import { MAX_NUMBER_VALUE } from "../../../../../tasks/components/AddNewProductForm/consts";
import { preventIncorrectNumberInputCharacters } from "../../../../../inventory/utils";

export const ServiceDrawerItem: React.FC<{
  isChecked: boolean;
  item: InventoryItem;
  currency: string;
  searchQuery?: string;
  isMobile?: boolean;
  isOrderForms?: boolean;
  onChange: (isChecked: boolean, qty?: number) => void;
  onQtyChange: (qty: number) => void;
}> = ({
  isChecked,
  item,
  currency,
  searchQuery,
  isMobile,
  isOrderForms,
  onChange,
  onQtyChange,
}) => {
  const { t } = useTranslations();
  const [qty, setQty] = useState("1");
  const [isQtyInputFocused, setIsQtyInputFocused] = useState(false);
  const itemImage = useMemo(
    () => item.img?.find((el) => el.order === 1)?.path || item.img?.[0]?.path,
    [item.img]
  );

  const qtyInput = useMemo(
    () =>
      !isOrderForms &&
      item.category === "item" &&
      isChecked && (
        <div
          className={cx(
            "font-secondary flex h-[32px] justify-end overflow-hidden rounded-lg border-[1px] border-solid transition-colors",
            {
              "border-grey-400": !isQtyInputFocused,
              "border-primary-200 border-[2px]": isQtyInputFocused,
            }
          )}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="flex h-full min-w-[42px] items-center rounded-tl-lg rounded-bl-lg bg-white pl-4 pr-2.5 text-[#828282]">
            {item.unit}
          </div>
          {isQtyInputFocused && (
            <div className="bg-primary-200 h-full min-w-[2px]" />
          )}
          <input
            onKeyDown={(e) => preventIncorrectNumberInputCharacters(e)}
            onBlur={() => {
              if (Number.isNaN(qty) || Number(qty) < 1) {
                setQty("1");
                onQtyChange(1);
              }
              setIsQtyInputFocused(false);
            }}
            onChange={(e) => {
              setQty(e.target.value);
              onQtyChange(Number(e.target.value));
            }}
            value={qty}
            min={1}
            max={MAX_NUMBER_VALUE}
            type="number"
            inputMode="numeric"
            className="text-dark-A400 h-full w-[56px] rounded-tr-lg rounded-br-lg border-none bg-[#F5F5F7] px-2 text-center outline-none transition-colors [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
            onFocus={() => {
              setIsQtyInputFocused(true);
            }}
          />
        </div>
      ),
    [
      isChecked,
      isOrderForms,
      isQtyInputFocused,
      item.category,
      item.unit,
      onQtyChange,
      qty,
    ]
  );

  const price = useMemo(
    () => (
      <div
        className={cx(
          "text-dark flex shrink-0 flex-col items-center justify-end gap-4 text-sm font-medium leading-[140%] tracking-[0.14px]"
        )}
      >
        <div className="flex w-full justify-end">
          {priceDisplay(parseFloat(item.price) * 100, currency)}
          <span className="text-grey-700 font-normal">
            /
            {item.category === InventoryItemCategory.item
              ? item.unit
              : t({
                  id: "order-form-wizard-page.service",
                  defaultMessage: "service",
                })}
          </span>
        </div>
        {!isMobile && qtyInput}
      </div>
    ),
    [isMobile, item.price, item.category, item.unit, currency, t, qtyInput]
  );

  return (
    <InteractiveContainer
      className={cx("py-4 px-5", {
        "rounded-2xl bg-[#F1F9FF] ": isChecked,
      })}
      onClick={() => {
        onChange(!isChecked);
        setIsQtyInputFocused(false);
      }}
    >
      <div
        className={cx(
          "flex w-full items-center gap-4 overflow-hidden  transition-colors"
        )}
      >
        <div className="flex w-full items-center gap-4 overflow-hidden">
          {!isMobile && <Checkbox isChecked={isChecked} readOnly />}
          <div className="flex w-full items-center gap-5 overflow-hidden">
            <div
              className={cx(
                "border-grey-400 bg-grey-200 flex shrink-0 items-center justify-center overflow-hidden rounded-[10px] border border-solid",
                {
                  "h-[48px] w-[48px]": isMobile,
                  "h-[72px] w-[72px]": !isMobile,
                }
              )}
            >
              {itemImage ? (
                <img
                  src={itemImage}
                  className="h-full w-full object-cover"
                  alt={item.name}
                />
              ) : (
                <ImageIcon />
              )}
            </div>
            <div className="flex w-full flex-col gap-0.5 overflow-hidden">
              <span className="text-dark font-secondary flex w-full justify-between break-words text-sm font-medium leading-[140%] tracking-[0.14px]">
                <Highlighter
                  autoEscape
                  highlightClassName="bg-primary-200"
                  searchWords={[searchQuery || ""]}
                  textToHighlight={item.name}
                />
                {isMobile && isChecked && <CheckedIcon />}
              </span>
              <span className="text-grey-800 font-secondary break-words text-xs leading-[140%]">
                {item.desc}
              </span>
            </div>
          </div>
        </div>
        {!isMobile && price}
      </div>
      {item.category === InventoryItemCategory.item && (
        <div
          className={cx(
            "text-grey-800 font-secondary mt-[14px] text-xs leading-[140%] tracking-[0.12px]",
            {
              "pl-[128px]": !isMobile,
            }
          )}
        >
          {t({
            id: "order-form-wizard-page.available-for-sale",
            defaultMessage: "Available for sale",
          })}
          {": "}
          {item.stock.stock <= 0 ? (
            <span className="text-gradients-danger text-sm leading-[140%] tracking-[0.14px]">
              {t({
                id: "order-form-wizard-page.out-of-stock",
                defaultMessage: "Out of Stock",
              })}
            </span>
          ) : (
            <span className="text-primary-700 text-sm leading-[140%] tracking-[0.14px]">
              {item.stock.stock} {item.unit}
            </span>
          )}
        </div>
      )}
      {isMobile && (
        <div className="flex w-full items-center justify-end gap-4 pt-3">
          {qtyInput} {price}
        </div>
      )}
    </InteractiveContainer>
  );
};
