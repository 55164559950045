import { useRestApiProvider } from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface UseTaskFieldsQueryOptions {
  entityId?: string;
}

export const useTaskFieldsQuery = ({ entityId }: UseTaskFieldsQueryOptions) => {
  const { tasksApi } = useRestApiProvider();

  const {
    data: taskFields,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = tasksApi.useGetTaskFieldsQuery(entityId ? { entityId } : skipToken, {
    refetchOnMountOrArgChange: false,
  });

  return {
    taskFields,
    isLoading,
    isFetching,
    isError,
    refetch,
  };
};
