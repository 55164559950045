import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { usePopper } from "react-popper";
import GifPicker from "gif-picker-react";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { ReactComponent as AttachmentsIcon } from "./assets/attachments.svg";
import { ReactComponent as SmileIcon } from "./assets/smile.svg";
import { ReactComponent as GIFIcon } from "./assets/gif.svg";
import { ReactComponent as VideoMessageIcon } from "./assets/video-message.svg";

export enum ActionType {
  emoji,
  attachment,
  videoMessage,
  calendarEvent,
  addTask,
  gif,
}

interface IconButton {
  icon: React.FC;
  tooltip?: string;
  type: ActionType;
}

export interface ChatInputToolbarParams {
  onAction: (params: {
    type: ActionType;
    emoji?: string;
    gif?: string;
  }) => void;
  disabled?: boolean;
  disabledAttachments?: boolean;
}

const ChatInputToolbar: React.FC<ChatInputToolbarParams> = ({
  onAction,
  disabled,
  disabledAttachments,
}) => {
  const btnClass = useMemo(
    () =>
      cx("m-0 block h-[40px] w-[40px] rounded border-none bg-white p-0", {
        "cursor-pointer hover:bg-gray-100": !disabled,
      }),
    [disabled]
  );

  const [emojiBtnRef, setEmojiBtnRef] = useState<HTMLButtonElement | null>();
  const [emojiPopoverRef, setEmojiPopoverRef] = useState<HTMLElement | null>();
  const { styles: emojiPopperStyles, attributes: emojiPopperAttrs } = usePopper(
    emojiBtnRef,
    emojiPopoverRef,
    {
      placement: "top-start",
    }
  );
  const { dateLocale } = useLanguage();
  const { logEvent } = useFCM();

  const [gifBtnRef, setGifBtnRef] = useState<HTMLButtonElement | null>();
  const [gifPopoverRef, setGifPopoverRef] = useState<HTMLElement | null>();
  const { styles: gifPopperStyles, attributes: gifPopperAttrs } = usePopper(
    gifBtnRef,
    gifPopoverRef,
    {
      placement: "top-start",
    }
  );
  const buttons: IconButton[] = useMemo(
    () => [
      ...(disabledAttachments
        ? []
        : [
            {
              icon: AttachmentsIcon,
              type: ActionType.attachment,
            },
            {
              icon: VideoMessageIcon,
              type: ActionType.videoMessage,
            },
          ]),
    ],
    [disabledAttachments]
  );
  const handleClick = useCallback(
    (type: ActionType) => {
      onAction?.({ type });
      if (type === ActionType.attachment) {
        logEvent("action_chat_attachment");
      }
    },
    [logEvent, onAction]
  );
  return (
    <div
      className={cx(
        "flex gap-[10px]",
        disabled ? "pointer-events-none opacity-50" : null
      )}
    >
      <Popover>
        <PopoverButton ref={setEmojiBtnRef} className={btnClass}>
          <SmileIcon />
        </PopoverButton>
        <PopoverPanel
          className="z-50"
          ref={setEmojiPopoverRef}
          style={emojiPopperStyles.popper}
          {...emojiPopperAttrs.popper}
        >
          {({ close }) => (
            <Picker
              theme="light"
              locale={dateLocale?.code}
              data={data}
              skinTonePosition="none"
              previewPosition="none"
              onEmojiSelect={(emoji: { [key: string]: string }) => {
                onAction?.({ type: ActionType.emoji, emoji: emoji.native });
                close();
              }}
            />
          )}
        </PopoverPanel>
      </Popover>
      {!disabledAttachments && (
        <Popover>
          <PopoverButton ref={setGifBtnRef} className={btnClass}>
            <GIFIcon />
          </PopoverButton>
          <PopoverPanel
            className="z-50"
            ref={setGifPopoverRef}
            style={gifPopperStyles.popper}
            {...gifPopperAttrs.popper}
          >
            {({ close }) => (
              <GifPicker
                tenorApiKey="AIzaSyD3gXEUnVD2OlXH6oWDjCuprKFRNCo_m1E"
                onGifClick={(e) => {
                  onAction?.({ type: ActionType.gif, gif: e.url });
                  close();
                }}
              />
            )}
          </PopoverPanel>
        </Popover>
      )}
      {buttons.map(({ icon, type }) => (
        <button
          type="button"
          key={type}
          onClick={handleClick?.bind(null, type)}
          className={cx(
            "m-0 block h-[40px] w-[40px] rounded border-none bg-white p-0",
            {
              "cursor-pointer hover:bg-gray-100": !disabled,
            }
          )}
        >
          {React.createElement(icon)}
        </button>
      ))}
    </div>
  );
};

export default ChatInputToolbar;
