import { useState, useEffect } from "react";

export const useInventoryTableCheckboxState = ({
  ids,
  totalEntries,
}: {
  ids: string[];
  totalEntries?: number;
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [checkedList, setCheckedList] = useState<{
    [key: string]: boolean;
  }>({});

  const [isAllChecked, setIsAllChecked] = useState(false);
  const [numbersOfChecked, setNumbersOfChecked] = useState(0);

  const handleCheckboxChange = (id: string) => {
    if (isAllChecked) {
      setIsAllChecked(false);
    }
    const newCheckedList = {
      ...checkedList,
      [id]: !checkedList[id],
    };

    const newNumbersOfChecked = !newCheckedList[id]
      ? numbersOfChecked - 1
      : numbersOfChecked + 1;

    setNumbersOfChecked((prevState) => {
      if (!newCheckedList[id]) {
        return prevState - 1;
      }
      return prevState + 1;
    });

    setCheckedList(newCheckedList);
    if (newNumbersOfChecked === totalEntries) {
      setIsAllChecked(true);
    }
  };

  const handleRemoveIds = (idsToRemove: string[]) => {
    idsToRemove.forEach((id) => {
      if (checkedList[id]) {
        setNumbersOfChecked((prevState) => prevState - 1);
      }
    });
    setCheckedList((prevState) => {
      const newCheckedList = Object.keys(prevState)
        .filter((key) => !idsToRemove.includes(key))
        .reduce((obj, key) => {
          obj[key] = prevState[key];
          return obj;
        }, {} as Record<string, boolean>);
      return newCheckedList;
    });
  };

  const handleSelectAll = () => {
    setIsAllChecked(true);
    setNumbersOfChecked(totalEntries || 0);
    setCheckedList((prevState) =>
      ids.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        { ...prevState }
      )
    );
  };

  const isIdChecked = (id: string) => {
    if (isAllChecked) {
      return true;
    }
    const checkedListTrueValuesNumber =
      Object.values(checkedList).filter(Boolean).length;
    if (checkedListTrueValuesNumber !== numbersOfChecked) {
      const wasVisible = Object.keys(checkedList).includes(id);
      return wasVisible ? checkedList[id] : true;
    }

    return checkedList[id] || false;
  };

  const handleClearAll = () => {
    setIsAllChecked(false);
    setNumbersOfChecked(0);
    setCheckedList({});
  };

  useEffect(() => {
    if (ids.length === 0) {
      return;
    }
    if (isInitialized) {
      setCheckedList((prevState) => {
        const newCheckedList = Object.keys(prevState).reduce((obj, key) => {
          obj[key] = prevState[key];
          return obj;
        }, {} as Record<string, boolean>);
        ids.forEach((id) => {
          newCheckedList[id] = isIdChecked(id);
        });
        return newCheckedList;
      });
      if (numbersOfChecked === totalEntries) {
        setIsAllChecked(true);
      }
      return;
    }
    const initialCheckedList = ids.reduce((acc, id) => {
      if (!checkedList[id]) {
        acc[id] = isAllChecked;
      } else {
        acc[id] = checkedList[id];
      }
      return acc;
    }, {} as { [key: string]: boolean });
    setCheckedList(initialCheckedList);
    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  return {
    checkedList,
    isAllChecked,
    numbersOfChecked,
    handleRemoveIds,
    handleCheckboxChange,
    handleSelectAll,
    handleClearAll,
    isIdChecked,
  };
};
