import React, { useCallback, useMemo } from "react";
import { ChatMessage } from "@web-src/modules/chats/types";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useDeprecatedFilePreview } from "@web-src/features/app/providers/DeprecatedFilePreviewProvider";
import { getChatMessageAttachmentUrls } from "@web-src/modules/chats/utils/getChatMessageAttachmentUrls";
import { cx } from "@jugl-web/utils";
import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import { PhotoAttachmentItem } from "./components/PhotoAttachmentItem";
import { ChatInfoError } from "../ChatInfoError";
import { ChatInfoLoading } from "../ChatInfoLoading";
import { ChatInfoEmptyContent } from "../ChatInfoEmptyContent";
import { HeadlessMessagesList } from "../../../HeadlessMessagesList";
import { VideoAttachmentItem } from "./components/VideoAttachmentItem";
import { VoiceAttachmentItem } from "./components/VoiceAttachmentItem";
import { AudioAttachmentItem } from "./components/AudioAttachmentItem";
import { FileAttachmentItem } from "./components/FileAttachmentItem";
import { InfoTabId } from "../../types";

export type AttachmentsSectionProps = {
  chatId: string;
  attachmentType: ChatMessageAttachmentType;
  tabId: InfoTabId;
};

export const AttachmentsSection: React.FC<AttachmentsSectionProps> = ({
  chatId,
  attachmentType,
  tabId,
}) => {
  const { entity } = useEntity();
  const { openFile } = useDeprecatedFilePreview();

  const layout: "grid" | "list" = useMemo(() => {
    if (
      [
        ChatMessageAttachmentType.image,
        ChatMessageAttachmentType.video,
      ].includes(attachmentType)
    ) {
      return "grid";
    }
    return "list";
  }, [attachmentType]);

  const messageRenderer = useCallback(
    (chatMessage: ChatMessage) => {
      const attachment = chatMessage.payload.attachments?.find(
        (item) => item.type === attachmentType
      );
      if (
        !entity?.id ||
        chatMessage.deleted ||
        !attachment ||
        (attachment._progress !== undefined && attachment._progress < 100)
      ) {
        return <></>;
      }
      switch (attachmentType) {
        case ChatMessageAttachmentType.image:
          return (
            <PhotoAttachmentItem
              attachment={attachment}
              key={attachment.uid}
              onClick={() => {
                if (!entity?.id) {
                  return;
                }
                const { stream } = getChatMessageAttachmentUrls(
                  entity.id,
                  attachment
                );
                openFile(stream);
              }}
            />
          );
        case ChatMessageAttachmentType.video:
          return (
            <VideoAttachmentItem
              attachment={attachment}
              onClick={() => {
                if (!entity?.id) {
                  return;
                }
                const { stream } = getChatMessageAttachmentUrls(
                  entity.id,
                  attachment
                );
                openFile(stream);
              }}
            />
          );
        case ChatMessageAttachmentType.voice:
          return (
            <VoiceAttachmentItem
              attachment={attachment}
              senderUserId={chatMessage.from}
              date={chatMessage.timestamp}
            />
          );
        case ChatMessageAttachmentType.audio:
          return (
            <AudioAttachmentItem
              attachment={attachment}
              senderUserId={chatMessage.from}
              date={chatMessage.timestamp}
            />
          );
        case ChatMessageAttachmentType.otherFile:
        case ChatMessageAttachmentType.doc:
          return (
            <FileAttachmentItem
              key={attachment.uid}
              attachment={attachment}
              date={chatMessage.timestamp}
            />
          );
        default:
          return <></>;
      }
    },
    [attachmentType, entity?.id, openFile]
  );

  if (!entity?.id) {
    return null;
  }

  return (
    <div
      className={cx("grid w-full", {
        "grid-cols-3 gap-0.5": layout === "grid",
        "gap-4 p-4": layout === "list",
      })}
    >
      <HeadlessMessagesList
        entityId={entity.id}
        chatId={chatId}
        fetchParams={{
          attachment_type: attachmentType,
        }}
        loadingContent={
          <div className={cx({ "col-span-3": layout === "grid" })}>
            <ChatInfoLoading />
          </div>
        }
        moreLoadingContent={<ChatInfoLoading />}
        emptyContent={
          <div className={cx({ "col-span-3": layout === "grid" })}>
            <ChatInfoEmptyContent tab={tabId} />
          </div>
        }
        errorContent={(refetch) => <ChatInfoError onRefresh={refetch} />}
        messageRenderer={messageRenderer}
      />
    </div>
  );
};
