import {
  TaskChecklist,
  TaskChecklistHandle,
  getDueDateInDays,
  taskNameValidator,
  templateChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import { useTaskFormStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { mapTaskTemplateToFormState } from "@jugl-web/domain-resources/tasks/utils/mapTaskTemplateToFormState";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { CreateOrUpdateTaskTemplatePayload } from "@jugl-web/rest-api/tasks-templates";
import { LoadingAnimation } from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useToast, useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FC, MutableRefObject, useEffect, useState } from "react";
import { TaskFormDialog } from "../../../TaskFormDialog";
import { ManageTaskTemplateDialogProps } from "../../types";

interface ManageTaskTemplateDialogContentProps
  extends ManageTaskTemplateDialogProps {
  titleInputRef: MutableRefObject<HTMLInputElement | null>;
  taskChecklistRef: MutableRefObject<TaskChecklistHandle | null>;
  onSafeClose: () => void;
}

export const ManageTaskTemplateDialogContent: FC<
  ManageTaskTemplateDialogContentProps
> = ({
  editingTemplateId,
  entityId,
  meId,
  folderId,
  isOpen,
  titleInputRef,
  taskChecklistRef,
  onClose,
  onSafeClose,
}) => {
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const { t } = useTranslations();

  const {
    formState,
    updateFormState,
    updateChecklistItems,
    updateCustomField,
    resetFormState,
  } = useTaskFormStateContext();

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const { toast } = useToast({ variant: "web" });

  const { tasksTemplatesApi } = useRestApiProvider();

  const { data: editingTemplate, isLoading } =
    tasksTemplatesApi.useGetTemplateQuery(
      isOpen && editingTemplateId
        ? { entityId, templateId: editingTemplateId }
        : skipToken
    );

  const [createTemplate, { isLoading: isCreatingTemplate }] =
    tasksTemplatesApi.useCreateTemplateMutation();

  const [updateTemplate, { isLoading: isUpdatingTemplate }] =
    tasksTemplatesApi.useUpdateTemplateMutation();

  const isDescriptionBoxVisible = formState.description || isEditingDescription;
  const isValid = taskNameValidator(formState.title);
  const isEditing = !!editingTemplateId;

  const handleSubmit = async () => {
    const templateProperties: CreateOrUpdateTaskTemplatePayload = {
      name: formState.title,
      desc: formState.description,
      assignees: formState.assigneeIds,
      due_in: formState.dueDate
        ? getDueDateInDays(formState.dueDate.date)
        : null,
      priority: formState.priority,
      custom_fields: formState.customFields,
      checklist: formState.checklistItems.map(
        templateChecklistItemAdapters.toBackendModel
      ),
      board_id: formState.boardId,
      label_id: formState.labelId,
      folder_id: folderId,
    };

    if (isEditing) {
      const response = await updateTemplate({
        entityId,
        templateId: editingTemplateId,
        template: templateProperties,
      });

      if ("data" in response) {
        toast(
          t({
            id: "feedback.template-updated",
            defaultMessage: "Template has been updated",
          })
        );
        onClose();
      }

      return;
    }

    const response = await createTemplate({
      entityId,
      template: templateProperties,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.template-created",
          defaultMessage: "Template has been created",
        })
      );
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (editingTemplate) {
      resetFormState(mapTaskTemplateToFormState(editingTemplate));
    } else {
      resetFormState();
    }
  }, [editingTemplate, isOpen, resetFormState]);

  if (isLoading) {
    return (
      <TaskFormDialog.Content className="flex items-center justify-center">
        <LoadingAnimation />
      </TaskFormDialog.Content>
    );
  }

  return (
    <>
      <TaskFormDialog.Content className="animate-fade-in">
        <TaskFormDialog.TitleSection>
          <TitleBox
            title={formState.title}
            inputRef={titleInputRef}
            classes={{ titleText: "py-3" }}
            onInternalValueChange={(title) => updateFormState("title", title)}
          />
          {isDescriptionBoxVisible && (
            <DescriptionBox
              description={formState.description}
              isEditing={isEditingDescription}
              classes={{ editingBox: "mt-2", descriptionText: "mt-2 py-2" }}
              onChange={(description) =>
                updateFormState("description", description)
              }
              onStartEditing={() => setIsEditingDescription(true)}
              onFinishEditing={() => setIsEditingDescription(false)}
            />
          )}
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              board: {
                boardId: formState.boardId,
                onChange: (boardId) => updateFormState("boardId", boardId),
              },
              description: {
                isHidden: !!formState.description || isEditingDescription,
                onClick: () => setIsEditingDescription(true),
              },
              dueDate: {
                state: formState.dueDate,
                displayAs: "days",
                onChange: (state) => updateFormState("dueDate", state),
              },
              label: {
                labelId: formState.labelId,
                onChange: (labelId) => updateFormState("labelId", labelId),
              },
              priority: {
                priority: formState.priority,
                onChange: (priority) => updateFormState("priority", priority),
              },
              customFields: {
                valuesById: formState.customFields,
                onFieldChange: updateCustomField,
              },
              assignees: {
                assigneeIds: formState.assigneeIds,
                onChange: (assigneeIds) =>
                  updateFormState("assigneeIds", assigneeIds),
              },
            }}
            className="mt-8"
          />
        </TaskFormDialog.TitleSection>
        <TaskFormDialog.ChecklistSection
          onAddItem={() => taskChecklistRef.current?.addItem()}
        >
          <TaskChecklist
            ref={taskChecklistRef}
            entityId={entityId}
            meId={meId}
            items={formState.checklistItems}
            isCompletable={false}
            displayDueDateAs="days"
            {...taskChecklistHandlers}
          />
        </TaskFormDialog.ChecklistSection>
        {/* To be done */}
        {/* <TaskFormDialog.AttachmentsSection onAddAttachment={() => {}}>
          </TaskFormDialog.AttachmentsSection> */}
      </TaskFormDialog.Content>
      <TaskFormDialog.Actions className="animate-fade-in">
        <Button
          variant="contained"
          color="grey"
          className="h-10 w-[200px]"
          onClick={onSafeClose}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          isDisabled={!isValid || isCreatingTemplate || isUpdatingTemplate}
          className="h-10 w-[200px]"
          onClick={handleSubmit}
        >
          {isEditing
            ? t({
                id: "common.save-changes",
                defaultMessage: "Save changes",
              })
            : t({
                id: "tasks-page.create-template",
                defaultMessage: "Create template",
              })}
        </Button>
      </TaskFormDialog.Actions>
    </>
  );
};
