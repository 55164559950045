import { environment } from "@web-src/environments/environment";
import { ChatMessagePayloadAttachment } from "@jugl-web/rest-api";

export const getChatMessageAttachmentUrls = (
  entityId: string,
  attachment: ChatMessagePayloadAttachment
) => ({
  preview: `${environment.apiUrl}/api/auth/attachment/preview?file=${entityId}/${attachment.uid}`,
  stream: `${environment.apiUrl}/api/auth/attachment/stream?file=${entityId}/${attachment.uid}`,
});
