import { OrderSubmitForm } from "@jugl-web/domain-resources/orders/components/OrderSubmitForm";
import { FormType } from "@jugl-web/rest-api";
import { HeaderBreadcrumbs, MultiSectionLayout } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import React from "react";
import { useParams } from "react-router-dom";

export const OrderFormSubmitPage: React.FC = () => {
  const { entityId } = useEntitySelectedProvider();
  const { formId, formType } = useParams<{
    formId: string;
    formType: FormType;
  }>();
  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  return (
    <MultiSectionLayout
      header={
        <HeaderBreadcrumbs
          items={[
            {
              title: t({
                id: "order-form-submit-page.forms",
                defaultMessage: "Forms",
              }),
              href: generateUrl("orderForms"),
            },
            {
              title:
                formType === "external"
                  ? t({
                      id: "order-form-submit-page.place-an-order",
                      defaultMessage: "Place an Order",
                    })
                  : t({
                      id: "order-form-submit-page.fill-the-form",
                      defaultMessage: "Fill the Form",
                    }),
            },
          ]}
        />
      }
      className="bg-[#E8EDF4]"
    >
      <div className="mx-auto flex w-full justify-center gap-2 rounded-xl px-4 pt-[60px] pb-12">
        <OrderSubmitForm entityId={entityId} formId={formId || ""} />
      </div>
    </MultiSectionLayout>
  );
};
