import { Customer } from "@jugl-web/rest-api/customer";

export const getCustomerFullName = (customer: Customer) => {
  const firstName = customer.fields.find(
    (item) =>
      item.field.section === "personal" && item.field.name === "first_name"
  )?.value;
  const lastName = customer.fields.find(
    (item) =>
      item.field.section === "personal" && item.field.name === "last_name"
  )?.value;
  return [firstName, lastName].filter((item) => !!item).join(" ");
};

export interface CustomerDetails {
  id: string;
  fullName: string;
  avatarUrl: string | null;
  companyName?: string;
  position?: string;
}

export const getCustomerDetails = (customer: Customer): CustomerDetails => ({
  id: customer.id,
  fullName: getCustomerFullName(customer),
  avatarUrl: customer.logo,
  position:
    customer.fields.find((c) => c.field.name === "position")?.value ||
    undefined,
  companyName:
    customer.fields.find((c) => c.field.name === "company_name")?.value ||
    undefined,
});
