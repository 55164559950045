import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import {
  TaskPriorityDetails,
  useTaskPriorities,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskPriorities";
import { TaskPriority } from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { FilterSectionLayout } from "../FilterSectionLayout";
import { PriorityFilterPill } from "../PriorityFilterPill";
import { FilterSectionProps } from "../../types";

export const PriorityFilterSection: FC<FilterSectionProps> = ({
  isReadonly,
}) => {
  const { allPriorities } = useTaskPriorities();

  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const prioritiesAsListItems = useMemo<ListBoxItem<TaskPriorityDetails>[]>(
    () => allPriorities.map((p) => ({ id: p.id, value: p })),
    [allPriorities]
  );

  const isFilterEmpty = filtersState.priorities.length === 0;
  const isSectionHidden = isReadonly && isFilterEmpty;

  if (isSectionHidden) {
    return null;
  }

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-priority-filter",
          defaultMessage: "By Priority",
        })}
        endSlot={
          !isReadonly ? (
            <ResourcePickerPopover
              placement="bottom-end"
              title={t({
                id: "tasks-page.select-priority",
                defaultMessage: "Select priority",
              })}
              items={prioritiesAsListItems}
              selectionBehavior={{ mode: "multiple" }}
              defaultSelectedIds={filtersState.priorities}
              maxVisibleItems={5}
              itemSize="md"
              spaceBetweenItems="compact"
              renderLabel={(item) => item.value.shortLabel}
              renderStartIcon={(item) => <item.value.Icon />}
              className="w-[315px]"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
              )}
              onSubmit={(ids) =>
                updateFiltersState("priorities", ids as TaskPriority[])
              }
            />
          ) : undefined
        }
      />
      {!isFilterEmpty && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.priorities.map((priority) => (
            <PriorityFilterPill
              key={priority}
              priority={priority}
              onRemove={
                !isReadonly
                  ? () =>
                      updateFiltersState("priorities", (previousPriorities) =>
                        previousPriorities.filter((p) => p !== priority)
                      )
                  : undefined
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
