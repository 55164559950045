import { Transition } from "@headlessui/react";
import { PlainButton } from "@jugl-web/ui-components";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as CrossIcon } from "./assets/cross-icon.svg";
import { ReactComponent as LampIcon } from "./assets/lamp-icon.svg";

export const NotificationsPermissionsPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  notificationStatus: NotificationPermission | "not-supported";
}> = ({ isVisible, onClose, notificationStatus }) => {
  const { customTheme } = useTheme();
  return (
    <Transition
      as="div"
      className="fixed bottom-0 left-[50%] z-[99999] -translate-x-1/2 transform pb-10 transition"
      show={isVisible}
      enter="transition"
      enterFrom="opacity-0 -translate-y-4 scale-75"
      enterTo="opacity-100 translate-y-0 scale-100"
      leave="transition"
      leaveFrom="opacity-100 translate-y-0 scale-100"
      leaveTo="opacity-0 -translate-y-4 scale-75"
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div
        className={cx(
          "util-prevent-drawer-close font-secondary relative mx-auto inline-flex w-full items-center justify-center gap-6 rounded-2xl py-3 pl-4 pr-10 text-sm font-medium text-white md:px-[120px]",
          {
            "bg-primary-900": customTheme === "blue",
            "bg-primary-800": customTheme === "light",
            "bg-[#333333]": customTheme === "dark",
          }
        )}
      >
        <div className="flex items-center gap-2">
          <LampIcon />
          <span className="md:whitespace-nowrap">
            Enable Notifications for the Jugl.com in your browser
          </span>
        </div>
        {notificationStatus !== "denied" && (
          <PlainButton
            className="font-secondary whitespace-nowrap rounded-lg bg-white bg-opacity-20 py-4 px-6 text-sm font-medium text-white transition-colors hover:bg-opacity-30"
            onClick={() => {
              Notification.requestPermission();
              onClose();
            }}
          >
            Open Settings
          </PlainButton>
        )}
        <PlainButton
          className="absolute top-[50%] right-6 -translate-y-1/2 transform"
          onClick={(e) => {
            onClose();
            e.stopPropagation();
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <CrossIcon />
        </PlainButton>
      </div>
    </Transition>
  );
};
