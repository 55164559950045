import {
  InteractiveContainer,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import { useOpenGraphQuery } from "@web-src/features/api/createApi";
import React, { useState } from "react";
import { ReactComponent as ImagePlaceholder } from "./assets/image-placeholder.svg";

export const LinkPreview: React.FC<{ url: string }> = ({ url }) => {
  const [cachedData, setCachedData] = useState<{
    imgSrc?: string;
    title?: string;
    description?: string;
    domain: string;
  } | null>(() => {
    const cached = sessionStorage.getItem(`linkPreview:${url}`);
    if (cached) {
      return JSON.parse(cached);
    }
    return null;
  });

  const { data, isLoading } = useOpenGraphQuery(url, {
    skip: !!cachedData, // Skip fetching if data is already cached
  });

  const imgSrc =
    cachedData?.imgSrc ||
    data?.openGraph?.image?.url ||
    data?.hybridGraph?.image;
  const title =
    cachedData?.title || data?.openGraph?.title || data?.hybridGraph?.title;
  const description =
    cachedData?.description ||
    data?.openGraph?.description ||
    data?.hybridGraph?.description;
  const domain = cachedData?.domain || new URL(url).hostname;

  const [isImageError, setIsImageError] = useState(false);

  if (data && !cachedData) {
    const newCache = {
      imgSrc,
      title,
      description,
      domain,
    };
    sessionStorage.setItem(`linkPreview:${url}`, JSON.stringify(newCache));
    setCachedData(newCache);
  }

  return (
    <InteractiveContainer
      className="mb-1.5 flex w-full flex-col overflow-hidden rounded"
      onClick={() => window.open(url, "_blank")}
    >
      {imgSrc && !isImageError && (
        <div className="flex h-24 w-full shrink-0 items-center justify-center overflow-hidden bg-gradient-to-b from-[#F4F6F7] to-[#CDE2F2]">
          {(() => {
            if (isLoading && !cachedData) {
              return <LoadingSpinner size="md" />;
            }
            if (imgSrc && !isImageError) {
              return (
                <img
                  src={imgSrc}
                  alt={title}
                  className="h-full w-full object-contain"
                  onError={() => {
                    setIsImageError(true);
                  }}
                />
              );
            }
            return <ImagePlaceholder />;
          })()}
        </div>
      )}
      <div className="flex h-full flex-col justify-center gap-0.5 bg-[#ECF5FA] py-2 px-2">
        <span className="text-dark test-sm truncate font-medium">
          {title || url}
        </span>
        {description && (
          <span className="line-clamp-2 text-[13px] text-[#818288]">
            {description}
          </span>
        )}
        <span className="truncate text-[12px] text-[#818288]">{domain}</span>
      </div>
    </InteractiveContainer>
  );
};
