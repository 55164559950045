import React, { memo, useEffect, useRef } from "react";

const ChatDateHeaderComponent: React.FC<{ date?: string }> = ({ date }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    (ref.current?.parentNode as HTMLDivElement)?.classList.add("z-10");
  }, []);
  return (
    <div className="top-4 flex w-full justify-center p-2" ref={ref}>
      <div className="text-dark-1000 flex h-[22px] items-center justify-center rounded-[11px] bg-gray-200 px-2 py-1 text-xs font-[500]">
        {date}
      </div>
    </div>
  );
};

export const ChatDateHeader = memo(ChatDateHeaderComponent);
