import { useTranslations, cx } from "@jugl-web/utils";
import {
  LoadingAnimation,
  PlainButton,
  SidebarDrawer,
  Tooltip,
} from "@jugl-web/ui-components";
import { InventoryItemCategory, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { Tab } from "@jugl-web/ui-components/web/TabsLayout/components/Tab";
import { useState, useMemo, useEffect } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { usePrevious } from "@web-src/utils/helper";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { downloadFile } from "@jugl-web/utils/utils/downloadFile";
import { ItemInfoTab } from "./components/ItemInfoTab";
import { StockTab } from "./components/StockTab";
import { HistoryTab } from "./components/HistoryTab";
import { ReactComponent as NoImgIcon } from "./assets/no-img.svg";
import { ReactComponent as EditIcon } from "./assets/edit-item.svg";
import { ReactComponent as StockIcon } from "./assets/stock-item.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete-item.svg";
import { ConfirmRemoveItemsAlert } from "../ConfirmRemoveItemsAlert";

export const InventoryItemDetailsSidebar = ({
  isOpen,
  itemId,
  onClose,
  onRemove,
}: {
  isOpen: boolean;
  itemId: string | undefined;
  onClose: () => void;
  onRemove: (id: string) => void;
}) => {
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const [isConfirmRemoveAlertOpen, setConfirmRemoveAlertOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<
    "info" | "invoices" | "history" | "stock"
  >("info");
  const previousIsOpen = usePrevious(isOpen);
  const { navigateToPage } = useNavigation();
  const { previewFiles } = useFilePreview();

  const handleEditItem = () => {
    if (!itemId) return;
    navigateToPage("inventoryItemEditForm", {
      id: itemId,
    });
  };

  const handleEditStock = () => {
    if (!itemId) return;
    navigateToPage("inventoryItemEditStockForm", {
      id: itemId,
    });
  };

  const [loadItemData, { data, error, isLoading, isFetching }] =
    inventoryApi.useLazyGetInventoryItemQuery();

  const headerTitle = useMemo(() => {
    if (error) {
      return t({
        id: "common.error",
        defaultMessage: "Error",
      });
    }
    if (data?.category === "item") {
      return t({
        id: "inventory-items-page.item-details",
        defaultMessage: "Item details",
      });
    }
    if (data?.category === "service") {
      return t({
        id: "inventory-items-page.service-details",
        defaultMessage: "Service details",
      });
    }
    if (isLoading || isFetching) {
      return t({
        id: "common.loading",
        defaultMessage: "Loading",
      });
    }
    return "";
  }, [data?.category, error, isLoading, isFetching, t]);

  const mainImage = useMemo(
    () => data?.img?.find((img) => img.order === 1) || data?.img?.[0],
    [data?.img]
  );

  const handleOpenProductImages = () => {
    if (!data?.img) return;
    const sortedFiles =
      data.img.length > 1
        ? [...data.img].sort((a, b) => (a.order || 0) - (b.order || 0))
        : data.img;
    const files = sortedFiles.map((img) => ({
      id: img.id,
      mimeType: "image/*",
      name: "",
      url: img.path,
      onDownload: async () => {
        downloadFile(img.path, img.path);
      },
    }));
    previewFiles(files);
  };

  const handleClose = () => {
    onClose();
    setSelectedTab("info");
  };

  useEffect(() => {
    if (itemId && isOpen && !previousIsOpen) {
      loadItemData({
        itemId,
        entityId: entity?.id,
      });
    }
  }, [data, entity?.id, isOpen, itemId, loadItemData, previousIsOpen]);

  return (
    <SidebarDrawer
      isOpen={isOpen}
      hasBackdrop={false}
      onClose={handleClose}
      className="flex w-[660px] flex-col rounded-tl-lg rounded-bl-lg"
      title={headerTitle}
    >
      <SidebarDrawer.Content>
        {(isLoading ||
          isFetching ||
          (data && data?.id !== itemId && !error)) && (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingAnimation />
          </div>
        )}
        {error && (
          <div className="flex h-full w-full items-center justify-center">
            {t({
              id: "inventory-items-sidebar.something-went-wrong",
              defaultMessage: "Something went wrong, please try again later",
            })}
          </div>
        )}
        {data && data.id === itemId && (
          <div className="relative flex flex-col justify-between px-8 pb-8">
            <div className="sticky top-0 z-10 bg-white pt-8">
              <div className="relative flex w-full items-center gap-8">
                {data?.img.length ? (
                  <img
                    src={mainImage?.path}
                    alt=""
                    className={cx("h-[80px] w-[80px] rounded-[14px]", {
                      "cursor-pointer": mainImage?.path,
                    })}
                    onClick={() => handleOpenProductImages()}
                  />
                ) : (
                  <div className="bg-grey-200 flex h-[80px] w-[80px] items-center justify-center rounded-[14px]">
                    <NoImgIcon className="rounded-[14px]" />
                  </div>
                )}
                <div className="w-1/2 flex-col gap-1">
                  <div
                    className="font-secondary text-dark truncate text-xl font-semibold leading-5 tracking-[1%]"
                    title={data?.name}
                  >
                    {data?.name}
                  </div>
                  <div className="font-secondary text-grey-800 text-sm">
                    {data?.category === InventoryItemCategory.service
                      ? t({
                          id: "inventory-items-page.service",
                          defaultMessage: "Service",
                        })
                      : t({
                          id: "inventory-items-page.item",
                          defaultMessage: "Item",
                        })}
                  </div>
                </div>
                <div className="absolute top-0 right-0 flex gap-4">
                  {data?.category === "item" && (
                    <Tooltip
                      renderTrigger={({ props, ref }) => (
                        <PlainButton
                          className="bg-grey-100 h-10 w-10 rounded-lg hover:brightness-90"
                          onClick={handleEditStock}
                          {...props}
                          ref={ref}
                        >
                          <StockIcon />
                        </PlainButton>
                      )}
                    >
                      {t({
                        id: "inventory-items-page.adjust-stock",
                        defaultMessage: "Adjust Stock",
                      })}
                    </Tooltip>
                  )}

                  <Tooltip
                    renderTrigger={({ props, ref }) => (
                      <PlainButton
                        className="bg-grey-100 h-10 w-10 rounded-lg hover:brightness-90"
                        onClick={handleEditItem}
                        ref={ref}
                        {...props}
                      >
                        <EditIcon />
                      </PlainButton>
                    )}
                  >
                    {t({
                      id: "common.edit",
                      defaultMessage: "Edit",
                    })}
                  </Tooltip>

                  <Tooltip
                    renderTrigger={({ props, ref }) => (
                      <PlainButton
                        className="bg-grey-100 h-10 w-10 rounded-lg hover:brightness-90"
                        onClick={() => setConfirmRemoveAlertOpen(true)}
                        {...props}
                        ref={ref}
                      >
                        <DeleteIcon />
                      </PlainButton>
                    )}
                  >
                    {t({
                      id: "common.delete",
                      defaultMessage: "Delete",
                    })}
                  </Tooltip>
                </div>
              </div>
              <div className="border-grey-300 w-full border-0 border-b-[1px] border-solid">
                <div className="mt-[45px] flex w-[210px]">
                  {data?.category === "item" && (
                    <Tab
                      title={t({
                        id: "inventory-items-sidebar.stock",
                        defaultMessage: "Stock",
                      })}
                      isSelected={selectedTab === "stock"}
                      onClick={() => setSelectedTab("stock")}
                      className="text-center"
                    />
                  )}
                  <Tab
                    title={t({
                      id: "inventory-items-sidebar.info",
                      defaultMessage: "Info",
                    })}
                    isSelected={selectedTab === "info"}
                    onClick={() => setSelectedTab("info")}
                    className="text-center"
                  />
                  {/* < Tab
            title={t({
              id: "inventory-items-sidebar.invoices",
              defaultMessage: "Invoices",
            })}
            isSelected={selectedTab === "invoices"}
            onClick={() => setSelectedTab("invoices")}
            className="text-center"
          /> */}
                  <Tab
                    title={t({
                      id: "inventory-items-sidebar.history",
                      defaultMessage: "History",
                    })}
                    isSelected={selectedTab === "history"}
                    onClick={() => setSelectedTab("history")}
                    className="text-center"
                  />
                </div>
              </div>
            </div>

            {selectedTab === "info" && data && <ItemInfoTab data={data} />}
            {selectedTab === "stock" && data && <StockTab data={data} />}
            {selectedTab === "history" && data && (
              <HistoryTab itemData={data} />
            )}
          </div>
        )}
        {itemId && (
          <ConfirmRemoveItemsAlert
            isOpen={isConfirmRemoveAlertOpen}
            itemIds={[itemId]}
            onClose={() => {
              setConfirmRemoveAlertOpen(false);
              handleClose();
            }}
            onRemove={() => onRemove(itemId)}
          />
        )}
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
