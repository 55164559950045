import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { ClickAwayListener, cx, useTranslations } from "@jugl-web/utils";
import { FC, KeyboardEvent, useState } from "react";
import { ReactComponent as ChecklistIcon } from "./assets/checklist.svg";
import { ReactComponent as PenIcon } from "./assets/pen.svg";

export interface EditableDropdownFieldNameProps {
  fieldName: string;
  onChange: (fieldName: string) => void;
}

export const EditableDropdownFieldName: FC<EditableDropdownFieldNameProps> = ({
  fieldName,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(fieldName);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslations();

  const handleSubmit = () => {
    if (inputValue !== fieldName) {
      onChange(inputValue);
    }

    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleClick = () => {
    setIsEditing(true);
    setInputValue(fieldName);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      handleSubmit();
    } else if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
    <ClickAwayListener isDisabled={!isEditing} onClickAway={handleSubmit}>
      <InteractiveContainer
        className={cx(
          "hover:bg-grey-200 flex h-10 w-[350px] items-center gap-2 rounded-xl px-2 transition-colors",
          isEditing && "bg-grey-200 outline-primary-300 outline outline-2"
        )}
        onClick={handleClick}
      >
        {isEditing ? (
          <>
            <PenIcon className="shrink-0" />
            <input
              type="text"
              placeholder={t({
                id: "tasks-page.enter-field-name",
                defaultMessage: "Enter field name",
              })}
              className="text-dark placeholder:text-grey-600 w-full border-none bg-transparent font-[Roboto] text-base font-medium outline-none placeholder:font-normal"
              autoFocus
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          </>
        ) : (
          <>
            <ChecklistIcon className="shrink-0" />
            <span className="text-dark truncate text-base font-medium">
              {fieldName}
            </span>
          </>
        )}
      </InteractiveContainer>
    </ClickAwayListener>
  );
};
