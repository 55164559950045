import emojiData from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import { Menu, Popover } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { useEditorToolbar } from "../../../common/components/RichTextarea";
import { SUPPORTED_WHATS_APP_VARIABLES } from "../../consts";
import { ReactComponent as BoldIcon } from "./assets/bold.svg";
import { ReactComponent as EmojiIcon } from "./assets/emoji.svg";
import { ReactComponent as ItalicIcon } from "./assets/italic.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as StrikeIcon } from "./assets/strike.svg";
import { ToolbarButton } from "./components/ToolbarButton";

export const WhatsAppTemplateEditorToolbar: FC<{ hideVariables?: boolean }> = ({
  hideVariables,
}) => {
  const { toolbarState, formatSelection, insertText, insertVariable } =
    useEditorToolbar();

  const { t } = useTranslations();

  return (
    <div className="mt-2 flex w-full items-center gap-4">
      <Popover
        placement="right-start"
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger ref={triggerRef} as={ToolbarButton} isActive={isOpen}>
            <EmojiIcon />
          </Trigger>
        )}
      >
        {({ onClose }) => (
          <EmojiPicker
            theme="light"
            data={emojiData}
            skinTonePosition="none"
            previewPosition="none"
            onEmojiSelect={(e: { native: string }) => {
              insertText(e.native);
              onClose();
            }}
          />
        )}
      </Popover>
      <ToolbarButton
        isActive={toolbarState.isBold}
        onClick={() => formatSelection("bold")}
      >
        <BoldIcon />
      </ToolbarButton>
      <ToolbarButton
        isActive={toolbarState.isItalic}
        onClick={() => formatSelection("italic")}
      >
        <ItalicIcon />
      </ToolbarButton>
      <ToolbarButton
        isActive={toolbarState.isStrikethrough}
        onClick={() => formatSelection("strikethrough")}
      >
        <StrikeIcon />
      </ToolbarButton>
      {!hideVariables && (
        <Menu
          placement="right-start"
          sections={[
            SUPPORTED_WHATS_APP_VARIABLES.map((variable) => ({
              id: variable,
              label: variable,
            })),
          ]}
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={ToolbarButton}
              isActive={isOpen}
              className="w-max gap-1 px-2"
            >
              <span>
                {t({
                  id: "whatsapp-page.add-variable",
                  defaultMessage: "Add Variable",
                })}
              </span>
              <PlusIcon />
            </Trigger>
          )}
          onSelect={(item, _, close) => {
            insertVariable(item.id);
            close();
          }}
        />
      )}
    </div>
  );
};
