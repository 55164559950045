import { InventoryItem } from "@jugl-web/rest-api";
import {
  Checkbox,
  FormGroup,
  FormGroupProps,
  Pill,
} from "@jugl-web/ui-components";
import {
  cx,
  priceDisplay,
  useSearchInput,
  useAppVariant,
  useTranslations,
} from "@jugl-web/utils";
import { FC, useEffect, useMemo, useState, useCallback } from "react";
import { downloadFile } from "@jugl-web/utils/utils/downloadFile";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import Highlighter from "react-highlight-words";
import { ReactComponent as ImageIcon } from "./assets/image.svg";
import { useFilePreview } from "../../../../../files/providers/FilePreviewProvider/FilePreviewProvider";
import { ReactComponent as ThreeWhiteDotsIcon } from "./assets/three-white-dots.svg";

export const OrderFormInventoryItem: FC<{
  item: InventoryItem;
  isSelected: boolean;
  qty?: number;
  onChange: (isSelected: boolean, qty?: number) => void;
  currency: string;
  highlightStr?: string;
  discount?: number;
}> = ({
  item,
  isSelected,
  onChange,
  qty,
  currency,
  highlightStr,
  discount,
}) => {
  const { isMobile } = useAppVariant();
  const { previewFiles } = useFilePreview();
  const { t } = useTranslations();
  const handleOpenProductImages = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const sortedFiles =
      item.img.length > 1
        ? [...item.img].sort((a, b) => (a.order || 0) - (b.order || 0))
        : item.img;

    const files = sortedFiles?.map((img) => ({
      name: "",
      url: img.path,
      mimeType: "image/*",
      onDownload: async () => {
        downloadFile(img.path, img.path);
      },
    }));
    previewFiles(files);
  };

  const itemImage = useMemo(
    () => item?.img?.find((el) => el.order === 1)?.path || item?.img?.[0]?.path,
    [item?.img]
  );

  return (
    <div
      className={cx(
        "hover:bg-grey-100 flex w-full cursor-pointer items-center gap-4 rounded-[10px] border border-solid border-transparent px-5 py-4 transition-colors",
        { "border-grey-400": isSelected }
      )}
      onClick={(e) => {
        e.stopPropagation();
        onChange(!isSelected, !isSelected ? 1 : qty);
      }}
    >
      <Checkbox isChecked={isSelected} readOnly />
      <div className="flex flex-1 items-center gap-5">
        <div className="border-grey-400 bg-grey-200 flex h-[46px] w-[46px] shrink-0 items-center justify-center overflow-hidden rounded-[10px] border border-solid md:h-[72px] md:w-[72px]">
          {itemImage ? (
            <div
              className="relative h-full w-full cursor-pointer"
              onClick={(e) => {
                handleOpenProductImages(e);
              }}
            >
              <img
                src={itemImage}
                className="h-full w-full object-cover"
                alt={item.name}
                onClick={handleOpenProductImages}
              />
              {item.img?.length > 1 && (
                <ThreeWhiteDotsIcon className="absolute bottom-1 left-1/2 z-10 -translate-x-1/2 transform" />
              )}
            </div>
          ) : (
            <ImageIcon />
          )}
        </div>
        <div className="flex flex-1 flex-col gap-4 md:flex-row md:items-center md:gap-[40px]">
          <div className="font-secondary flex-1">
            <h3
              className={cx("font-secondary m-0 leading-[140%]", {
                "text-base": isMobile,
              })}
              style={{ wordBreak: "break-word" }}
            >
              {highlightStr ? (
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary font-semibold"
                  highlightTag="span"
                  textToHighlight={item.name}
                  searchWords={[highlightStr]}
                />
              ) : (
                item.name
              )}
            </h3>
            <p
              className="font-secondary m-0 text-sm leading-[140%] text-[#93949A]"
              style={{ wordBreak: "break-word" }}
            >
              {item.desc}
            </p>
            {discount && (
              <Pill
                size="sm"
                label={
                  <span className="font-secondary text-xs text-white">
                    -{discount} %
                  </span>
                }
                className="bg-gradients-danger"
              />
            )}
          </div>
          <div className="flex flex-col items-start gap-4 md:items-end">
            <div className="flex items-center text-sm">
              <span className="font-semibold">
                {discount && (
                  <span className="text-gradients-danger font-secondary pr-1 text-sm font-medium">
                    {priceDisplay(
                      parseFloat(item.price) * 100 * (1 - discount / 100),
                      currency
                    )}
                  </span>
                )}
                <span
                  className={cx({
                    "font-secondary text-xs line-through": discount,
                  })}
                >
                  {priceDisplay(
                    parseFloat(item.price) * 100,
                    currency,
                    !!discount
                  )}
                </span>
              </span>
              <span className="text-[#A4A5AA]">
                /
                {(item.category === "item" &&
                  item.stock?.stock?.toString() &&
                  item.unit) ||
                  t({
                    id: "common.service",
                    defaultMessage: "Service",
                  })}
              </span>
            </div>
            {item.unit && isSelected && (
              <label
                onClick={(e) => e.stopPropagation()}
                className={cx(
                  "border-grey-400 font-secondary flex h-[32px] w-full items-center justify-between gap-4 overflow-hidden rounded-lg border border-solid pl-4 text-sm",
                  {
                    "w-auto": isMobile,
                  }
                )}
              >
                {item.unit}
                <input
                  className="h-[32px] w-[72px] flex-1 cursor-pointer border-none bg-[#F5F5F7] px-4 outline-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  value={qty === 0 ? undefined : qty}
                  onChange={(e) => {
                    let qtyValue = e.target.value
                      ? parseInt(e.target.value as string, 10)
                      : 0;
                    if (qtyValue && qtyValue < 0) {
                      qtyValue *= -1;
                    }
                    onChange(isSelected, qtyValue);
                  }}
                  onBlur={() => {
                    if (!qty) {
                      onChange(false);
                    }
                  }}
                />
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export type OrderFormInventorySelectValueItem = {
  inventory_id: string;
  qty: number;
};

export const OrderFormInventorySelect: FC<
  FormGroupProps & {
    services: (InventoryItem & { discount?: { discount: number } })[];
    items: string[];
    entityId: string;
    discounts: Record<string, { discount: string; value: number }>;
    value?: OrderFormInventorySelectValueItem[];
    onChange?: (value: OrderFormInventorySelectValueItem[]) => void;
    currency: string;
    isPreview?: boolean;
  }
> = ({
  items,
  discounts,
  entityId,
  services,
  value,
  onChange,
  currency,
  isPreview,
  ...formGroupProps
}) => {
  const [total, setTotal] = useState(0);
  const mappedItems = useMemo(
    () =>
      items
        .map((itemId) => ({
          itemId,
          item: services.find((service) => service.id === itemId),
        }))
        .filter((item) => item.item),
    [items, services]
  );

  const currencyDisplay = currency;

  const calculateTotal = useCallback(async () => {
    let result = 0;
    value?.forEach((v) => {
      const item = services.find((i) => i.id === v.inventory_id);
      if (!item) {
        return;
      }
      const discountValue = isPreview
        ? discounts[item?.id]?.value
        : item?.discount?.discount;

      if (item && discountValue) {
        result += parseFloat(item.price) * v.qty * (1 - discountValue / 100);
      }
      if (item && !discountValue) {
        result += parseFloat(item.price) * v.qty;
      }
    });
    setTotal(result);
  }, [value, services, isPreview, discounts]);

  const { inputProps, searchQuery, reset } = useSearchInput();

  const filteredItems = useMemo(
    () =>
      searchQuery
        ? mappedItems.filter(
            ({ item }) =>
              !searchQuery ||
              item?.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : mappedItems,
    [mappedItems, searchQuery]
  );

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal, value, services]);

  return (
    <FormGroup classNames={{ wrapperClassName: "w-full" }} {...formGroupProps}>
      <div className="max-w-[280px] py-2">
        <SearchInput
          variant="filled"
          color="grey"
          onClear={reset}
          {...inputProps}
        />
      </div>
      <div className="jugl__custom-scrollbar flex max-h-[500px] flex-col gap-6 overflow-y-auto pt-2">
        {filteredItems.length ? (
          filteredItems.map(({ item }) =>
            item ? (
              <OrderFormInventoryItem
                key={item.id}
                item={item}
                currency={currencyDisplay}
                discount={
                  isPreview
                    ? discounts[item.id]?.value
                    : item.discount?.discount
                }
                isSelected={!!value?.find((v) => v.inventory_id === item.id)}
                qty={value?.find((v) => v.inventory_id === item.id)?.qty}
                highlightStr={searchQuery}
                onChange={(isSelected, qty) => {
                  if (!isSelected) {
                    onChange?.(
                      value?.filter((v) => v.inventory_id !== item.id) || []
                    );
                    return;
                  }
                  if (value?.find((v) => v.inventory_id === item.id)) {
                    onChange?.([
                      ...value.map((v) =>
                        v.inventory_id === item.id
                          ? { inventory_id: item.id, qty: qty || 0 }
                          : v
                      ),
                    ]);
                  } else {
                    onChange?.([
                      ...(value || []),
                      { inventory_id: item.id, qty: qty || 0 },
                    ]);
                  }
                }}
              />
            ) : null
          )
        ) : (
          <div className="leading-[160%] text-[#828282]">
            No Items found 😔 Please try something else
          </div>
        )}
      </div>
      <div className="mt-6 flex justify-end py-2">
        <span className="font-[500] text-[#40494D]">
          Total price : {priceDisplay(total * 100, currencyDisplay)}
        </span>
      </div>
    </FormGroup>
  );
};
