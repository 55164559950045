import { Tooltip } from "@jugl-web/ui-components";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { cx, useRerender, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, useEffect, useState } from "react";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { getInitialWidgetDateRange } from "../../utils";
import { ReactComponent as RefreshIcon } from "./assets/refresh.svg";

export const DashboardRefreshButton: FC = () => {
  const { widgetDateRange, setWidgetDateRange, refetchDetailedDashboard } =
    useDashboardContext();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const { formatDateTimeLabel, humanizePastDate } = useFormattedDate();
  const { t } = useTranslations();

  const rerender = useRerender();

  const humanizedLastRefreshDate = humanizePastDate(widgetDateRange.to, {
    customJustNowMessage: t({ id: "common.now", defaultMessage: "Now" }),
  });

  const handleClick = async () => {
    setIsRefreshing(true);

    await refetchDetailedDashboard();
    // Also, update the date range so all widgets will refetch their data
    setWidgetDateRange(getInitialWidgetDateRange());

    // Wait a bit before setting isRefreshing to false as the widget data might be still loading
    // (consider listening to the loading state of the widgets as a more robust solution)
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };

  // Re-render component every minute to update the last refresh date label
  useEffect(() => {
    const intervalId = setInterval(() => {
      rerender();
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [rerender]);

  return (
    <Tooltip
      placement="bottom"
      isDisabled={isRefreshing}
      renderTrigger={({ props, ref }) => (
        <ControlBarButton
          ref={ref}
          startSlot={
            <RefreshIcon
              className={cx(
                isRefreshing ? "text-primary animate-spin" : "text-grey"
              )}
            />
          }
          className="hover:bg-grey-100 px-2.5"
          label={
            <span className="text-dark-700">
              {isRefreshing
                ? t({
                    id: "common.refreshing-ellipsis",
                    defaultMessage: "Refreshing...",
                  })
                : t(
                    {
                      id: "tasks-page.refreshed-x-time-ago",
                      defaultMessage: "Refreshed: {lastRefreshDate}",
                    },
                    {
                      lastRefreshDate: (
                        <span className="text-dark-800">
                          {humanizedLastRefreshDate}
                        </span>
                      ),
                    }
                  )}
            </span>
          }
          isActive={isRefreshing}
          isDisabled={isRefreshing}
          onClick={handleClick}
          {...props}
        />
      )}
    >
      {formatDateTimeLabel(widgetDateRange.from)}
    </Tooltip>
  );
};
