import {
  useTranslations,
  apiUTCToLocalDateTime,
  priceDisplay,
} from "@jugl-web/utils";
import { ReactNode } from "react";
import { InvoiceGuestLogModel } from "@jugl-web/rest-api/orders/models/InvoiceGuestLog";
import { format } from "date-fns";
import { Avatar } from "@jugl-web/ui-components";
import { UserGeneralProfile } from "../../../../../users/components/UserGeneralProfile/UserGeneralProfile";

export const InvoiceHistoryItem = ({
  log,
  currency,
  units,
  entityId,
}: {
  log: InvoiceGuestLogModel;
  currency: string;
  units: string;
  entityId: string;
}) => {
  const { t } = useTranslations();

  const formattedDate = format(
    apiUTCToLocalDateTime(log.inserted_at),
    "MMM d, h:mm a"
  );

  const getLogLabel = (username: string) => ({
    reopened: t(
      {
        id: "task-page.order-log-reopened",
        defaultMessage: "<b>{username}</b> reopened Order",
      },
      {
        username,
        b: (chunks: ReactNode) => (
          <b className="text-dark font-semibold">{chunks}</b>
        ),
      }
    ),
    line_item_added: t(
      {
        id: "task-page.invoice-log-line-item-added",
        defaultMessage:
          "<b>{username}</b> added {category} <b2>{itemName}</b2> to Order Details",
      },
      {
        username,
        category: log.info.category === "service" ? "Service" : "Item",
        itemName: log.info.item_name,
        b: (chunks: ReactNode) => (
          <b className="text-dark font-semibold">{chunks}</b>
        ),
        b2: (chunks: ReactNode) => (
          <b className="text-dark-600 font-semibold">{chunks}</b>
        ),
      }
    ),
    line_item_removed: t(
      {
        id: "task-page.invoice-log-line-item-removed",
        defaultMessage:
          "<b>{username}</b> removed {category} <b2>{itemName}</b2> from Order Details",
      },
      {
        username,
        category: log.info.category === "service" ? "Service" : "Item",
        itemName: log.info.item_name,
        b: (chunks: ReactNode) => (
          <b className="text-dark font-semibold">{chunks}</b>
        ),
        b2: (chunks: ReactNode) => (
          <b className="text-dark-600 font-semibold">{chunks}</b>
        ),
      }
    ),
    line_item_updated: t(
      {
        id: "guest-task-page.invoice-log-line-item-updated",
        defaultMessage:
          "<b>{username}</b> updated {changedParameter} of {category} <b1>{itemName}</b1> from {from} to {to}.",
      },
      {
        username,
        itemName: log.info.item_name,
        category: log.info.category === "service" ? "Service" : "Item",
        changedParameter: "new_price" in log.info ? "Price" : "Quantity",
        from:
          "prev_price" in log.info
            ? priceDisplay(
                log.info.prev_price ? log.info.prev_price * 100 : 0,
                currency
              )
            : `${log.info.prev_qty} ${log.info.unit || units}`,
        to:
          "new_price" in log.info
            ? priceDisplay(
                log.info.new_price ? log.info.new_price * 100 : 0,
                currency
              )
            : `${log.info.new_qty} ${units}`,
        b: (chunks: ReactNode) => (
          <b className="text-dark break-all font-semibold">{chunks}</b>
        ),
        b1: (chunks: ReactNode) => (
          <span className="text-dark-medium font-semibold">{chunks}</span>
        ),
      }
    ),
  });

  return (
    <UserGeneralProfile entityId={entityId} userId={log.action_by}>
      {({ safeProfile }) => (
        <div className="font-secondary flex items-start gap-3 py-6">
          <div className="h-[32px] w-[32px]">
            <Avatar
              username={safeProfile.displayName}
              imageUrl={safeProfile.avatar}
            />
          </div>
          <div className="text-dark-600 flex flex-col gap-1.5">
            <div className="text-sm">
              {getLogLabel(safeProfile.displayName)[log.action]}
            </div>
            <div className="text-grey-900 text-xs">{formattedDate}</div>
          </div>
        </div>
      )}
    </UserGeneralProfile>
  );
};
