import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useCallback } from "react";
import { EntityModel, UserRole } from "@jugl-web/rest-api";

export const useNavigateToRoleBasedInitialPage = () => {
  const { navigateToPage } = useNavigation();

  const navigateToRoleBasedInitialPage = useCallback(
    (entity: EntityModel) => {
      switch (entity.role) {
        case UserRole.travelLogEmployee:
          navigateToPage("travelLogSchedule", undefined, {
            entityId: entity?.id,
          });
          break;
        case UserRole.taskEmployee:
          navigateToPage(
            "tasksTasks",
            { boardId: "my" },
            { entityId: entity?.id }
          );
          break;
        default:
          navigateToPage("chatsChats", undefined, { entityId: entity?.id });
      }
    },
    [navigateToPage]
  );

  return navigateToRoleBasedInitialPage;
};
