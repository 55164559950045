import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  PropsWithChildren,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Channel, Socket } from "phoenix";
import { useSelector } from "react-redux";
import {
  selectIsAuthenticated,
  selectUserId,
} from "@web-src/features/auth/authSlice";
import { getJwtToken } from "@web-src/features/auth/utils";
import { logger } from "@web-src/utils/logger";
import {
  BulkReceiptSentResponse,
  ChatMessage,
  ChatMessagePayloadType,
  ChatMessageType,
} from "@web-src/modules/chats/types";
import { useGroupInfoChatMessageHandler } from "@web-src/modules/chats/hooks/useGroupInfoChatMessageHandler";
import { Subject } from "rxjs";
import { environment } from "@web-src/environments/environment";
import { HookOutOfContextError, useTabIsActive } from "@jugl-web/utils";
import { useNavigateToChat } from "@web-src/modules/chats/hooks/useNavigateToChat";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  EntitiesApiTag,
  EntitySubscription,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { useAppDispatch } from "@web-src/store";
import {
  getMessageChatId,
  payloadToClearMessage,
} from "@web-src/modules/chats/utils";

export type PhxResponse<T> = {
  status: string;
  response: T;
};

const CHAT_CHANNEL = "chat:lobby";

const PhoenixSocketContext = createContext<{
  socket?: Socket;
  channel?: Channel;
  incomingMessages$?: Subject<ChatMessage>;
  indicatorMessages$?: Subject<{ message: ChatMessage; merge: boolean }>;
  receiptSent$?: Subject<BulkReceiptSentResponse>;
  socketConnected: boolean;
}>({
  socket: undefined,
  channel: undefined,
  incomingMessages$: undefined,
  indicatorMessages$: undefined,
  socketConnected: false,
});

const PhoenixSocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const groupInfoMessageHandler = useGroupInfoChatMessageHandler();
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const { triggerInAppNotification } = useNotifications();
  const { entitiesApi } = useRestApiProvider();
  const dispatch = useAppDispatch();

  const tabIsActive = useTabIsActive();
  const tabIsActiveRef = useRef<boolean>(tabIsActive);

  const didDisconnectedWhileInactive = useRef<boolean>(false);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const incomingMessages$ = useMemo(() => new Subject<ChatMessage>(), []);
  const indicatorMessages$ = useMemo(
    () => new Subject<{ message: ChatMessage; merge: boolean }>(),
    []
  );
  const receiptSent$ = useMemo(
    () => new Subject<BulkReceiptSentResponse>(),
    []
  );
  const [socket, setSocket] = useState<Socket>();
  const socketRef = useRef<Socket | null>(null);
  const setSocketState = useCallback((currentSocket?: Socket) => {
    setSocket(currentSocket);
    socketRef.current = currentSocket || null;
  }, []);

  const [channel, setChannel] = useState<Channel>();
  const channelRef = useRef<Channel | null>(null);
  const setChannelState = useCallback((currentChannel?: Channel) => {
    setChannel(currentChannel);
    channelRef.current = currentChannel || null;
  }, []);

  const { entity, subscriptionInfo } = useEntityProvider();
  const entityId = entity?.id;

  const meJid = useSelector(selectUserId);
  const navigateToChat = useNavigateToChat();

  const initialSubscriptionUpdatePassed = useRef<{
    entityId: string;
    passed: boolean;
  } | null>(null);

  const getModule = useCallback((type: ChatMessageType) => {
    switch (type) {
      case ChatMessageType.muc:
        return "groups";
      case ChatMessageType.chat:
        return "chats";
      default:
        return "calls";
    }
  }, []);

  useEffect(() => {
    if (!channel) {
      return () => {};
    }
    const incomingMessageCallback = (e: PhxResponse<ChatMessage>) => {
      if (e.status !== "ok") {
        // TODO: process
        return;
      }
      const { response: message } = e;
      if (
        message.payload?.action === "action" &&
        message.payload?.type === "typing_indicator"
      ) {
        return;
      }
      if (
        [
          ChatMessageType.chat,
          ChatMessageType.muc,
          ChatMessageType.call,
        ].includes(message.type)
      ) {
        if (localStorage.getItem("jugl:debug") === "true") {
          // eslint-disable-next-line no-console
          console.log(`Incoming message [${message.from}]:`, message);
        }
        if (message.payload.title && message?.from !== meJid) {
          const chatId = getMessageChatId(message, meJid);
          const callId = message.from === meJid ? message.to : message.from;
          const senderId =
            message.type === ChatMessageType.call ? callId : chatId;
          triggerInAppNotification({
            entityId: message.entity_id,
            key: message.msg_id,
            title: message.payload.title,
            module: getModule(message.type),
            body: payloadToClearMessage(message),
            clearBody: message.payload.body || "",
            onClick:
              message.type === ChatMessageType.call
                ? undefined
                : () => navigateToChat(chatId, message.entity_id),
            senderId,
          });
        }
      }
      if (message.entity_id !== entityId) {
        return;
      }
      if (message?.payload?.type === ChatMessagePayloadType.group_info) {
        groupInfoMessageHandler(message, true);
      }
      incomingMessages$.next(e.response);
    };
    const indicatorMessageCallback = (
      event: string,
      e: PhxResponse<ChatMessage>
    ) => {
      if (e.status !== "ok") {
        // TODO: process
        return;
      }
      const { response: message } = e;
      if (message.entity_id !== entityId) {
        return;
      }
      indicatorMessages$.next({
        merge: false,
        message,
      });
    };
    const receiptSentCallback = (
      event: string,
      e: PhxResponse<BulkReceiptSentResponse>
    ) => {
      if (e.status !== "ok") {
        // TODO: process
        return;
      }
      const { response } = e;
      if (response.entity_id !== entityId || e.response.type !== "read") {
        return;
      }
      receiptSent$.next(response);
    };

    const inidicatorRefId = channel.on(
      "phx_message_indicator",
      indicatorMessageCallback.bind(null, "phx_message_indicator")
    );
    const updateRefId = channel.on(
      "phx_message_update",
      indicatorMessageCallback.bind(null, "phx_message_update")
    );
    const messageReactionRefId = channel.on(
      "phx_message_reaction",
      indicatorMessageCallback.bind(null, "phx_message_reaction")
    );
    const groupInforefId = channel.on(
      "phx_group_info",
      incomingMessageCallback
    );
    const messageRefId = channel.on("phx_message", incomingMessageCallback);
    const messageSentRefId = channel.on(
      "phx_message_sent",
      incomingMessageCallback
    );
    const receiptSentRefId = channel.on(
      "phx_receipt_sent",
      receiptSentCallback.bind(null, "phx_receipt_sent")
    );
    const callReactionRefId = channel.on("phx_call", incomingMessageCallback);
    const callSentReactionRefId = channel.on(
      "phx_call_sent",
      incomingMessageCallback
    );
    const callUpdateRefId = channel.on("update_call", incomingMessageCallback);
    const subscriptionUpdateCallback = (e: PhxResponse<EntitySubscription>) => {
      if (e.status !== "ok") {
        return;
      }
      if (
        initialSubscriptionUpdatePassed.current &&
        !initialSubscriptionUpdatePassed.current?.passed
      ) {
        initialSubscriptionUpdatePassed.current = {
          ...initialSubscriptionUpdatePassed.current,
          passed: true,
        };
        return;
      }
      dispatch(entitiesApi.util.invalidateTags([EntitiesApiTag.getEntityList]));
    };
    const subscriptionUpdateRefId = channel.on(
      "subscription_update",
      subscriptionUpdateCallback
    );

    return () => {
      channel.off("phx_message", messageRefId);
      channel.off("phx_group_info", groupInforefId);
      channel.off("phx_message_indicator", inidicatorRefId);
      channel.off("phx_message_update", updateRefId);
      channel.off("phx_message_sent", messageSentRefId);
      channel.off("phx_message_reaction", messageReactionRefId);
      channel.off("phx_receipt_sent", receiptSentRefId);
      channel.off("phx_call", callReactionRefId);
      channel.off("phx_call_sent", callSentReactionRefId);
      channel.off("update_call", callUpdateRefId);
      channel.off("subscription_update", subscriptionUpdateRefId);
    };
  }, [
    getModule,
    navigateToChat,
    channel,
    entityId,
    groupInfoMessageHandler,
    incomingMessages$,
    indicatorMessages$,
    receiptSent$,
    meJid,
    triggerInAppNotification,
    dispatch,
    entitiesApi.util,
  ]);

  const connectingEntityId = useRef<string>();
  useEffect(() => {
    const currentSocket = socketRef.current;
    const currentChannel = channelRef.current;
    const cleanupConnection = () => {
      currentChannel?.leave();
      currentSocket?.disconnect();
      setSocketConnected(false);
      if (currentChannel) {
        setChannelState(undefined);
      }
      if (currentSocket) {
        setSocketState(undefined);
      }
    };

    // Clean-up when user logs out
    if (!isAuthenticated || !entityId || !subscriptionInfo?.socketAllowed) {
      cleanupConnection();
      return;
    }

    // User changed entity
    if (connectingEntityId.current !== entityId) {
      cleanupConnection();
    }
    connectingEntityId.current = entityId;
    initialSubscriptionUpdatePassed.current = { entityId, passed: false };
    const socketObj = new Socket(`wss://${environment.wsDomain}/socket`, {
      params: {
        token: getJwtToken()?.accessToken,
        entity_id: entityId,
        web: true,
      },
      // timeout: 60000 * 5,
      reconnectAfterMs: (number) => {
        if (number === 5) {
          setSocketConnected(false);
        }
        if (number >= 5) {
          return 10000;
        }
        return 2000;
      },
    });
    socketObj.onOpen(() => {
      logger.info("Socket opened.");
      connectingEntityId.current = undefined;
      setSocketConnected(true);
    });
    socketObj.onError((e) => {
      logger.error("Socket error.", e);
      connectingEntityId.current = undefined;
      setSocketConnected(false);
    });
    socketObj.onClose((e) => {
      logger.info("Socket closed.", e);
      if (!tabIsActiveRef.current) {
        didDisconnectedWhileInactive.current = true;
      }
      // setSocketConnected(false);
      connectingEntityId.current = undefined;
    });
    socketObj.connect();

    const channelObj = socketObj.channel(CHAT_CHANNEL);
    channelObj
      .join()
      .receive("error", (e) => {
        logger.error(`[${CHAT_CHANNEL} channel] Read access denied.`, e);
      })
      .receive("ok", () => {
        logger.info(`[${CHAT_CHANNEL} channel] Read access granted.`);
      });

    setChannelState(channelObj);
    setSocketState(socketObj);
  }, [
    isAuthenticated,
    entityId,
    setSocketState,
    setChannelState,
    subscriptionInfo?.socketAllowed,
  ]);

  const value = useMemo(
    () => ({
      socket,
      channel,
      incomingMessages$,
      indicatorMessages$,
      receiptSent$,
      socketConnected,
    }),
    [
      socket,
      channel,
      incomingMessages$,
      indicatorMessages$,
      receiptSent$,
      socketConnected,
    ]
  );

  return (
    <PhoenixSocketContext.Provider value={value}>
      {children}
    </PhoenixSocketContext.Provider>
  );
};

export { PhoenixSocketContext, PhoenixSocketProvider };

export const usePhoenixSocket = () => {
  const context = useContext(PhoenixSocketContext);

  if (!context) {
    throw new HookOutOfContextError("usePheonixSocket", "PhoenixSocketContext");
  }

  return context;
};
