import { getRandomFolderPreset } from "@jugl-web/domain-resources/tasks";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  CreateOrUpdateTaskTemplateFolderPayload,
  TaskTemplateFolder,
} from "@jugl-web/rest-api/tasks-templates";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { EditableTaskTemplateFolderTile } from "@jugl-web/ui-components/cross-platform/tasks/EditableTaskTemplateFolderTile";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useToast, useTranslations } from "@jugl-web/utils";
import { FC, useCallback, useRef, useState } from "react";

interface TemplateFolderFormState {
  color: string;
  emoji: string;
  name: string;
}

type UpdateTemplateFolderFormStateCallback = <
  TField extends keyof TemplateFolderFormState,
  TValue extends TemplateFolderFormState[TField]
>(
  field: TField,
  value: TValue
) => void;

const getInitialFormState = (): TemplateFolderFormState => ({
  name: "",
  ...getRandomFolderPreset(),
});

interface ManageTaskTemplateFolderDialogContentProps
  extends Pick<
    ManageTaskTemplateFolderDialogProps,
    "entityId" | "editingFolder" | "onClose"
  > {
  nameInputRef: React.RefObject<HTMLInputElement>;
}

const ManageTaskTemplateFolderDialogContent: FC<
  ManageTaskTemplateFolderDialogContentProps
> = ({ entityId, nameInputRef, editingFolder, onClose }) => {
  const [formState, setFormState] = useState(() => {
    if (editingFolder) {
      return {
        color: editingFolder.color,
        emoji: editingFolder.img,
        name: editingFolder.name,
      };
    }

    return getInitialFormState();
  });

  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const updateFormState = useCallback<UpdateTemplateFolderFormStateCallback>(
    (field, value) =>
      setFormState((prevFormState) => ({ ...prevFormState, [field]: value })),
    []
  );

  const { tasksTemplatesApi } = useRestApiProvider();

  const [createTemplateFolder, { isLoading: isCreatingTemplateFolder }] =
    tasksTemplatesApi.useCreateTemplateFolderMutation();

  const [updateTemplateFolder, { isLoading: isUpdatingTemplateFolder }] =
    tasksTemplatesApi.useUpdateTemplateFolderMutation();

  const isValid = formState.name.trim().length > 0;
  const isEditing = !!editingFolder;
  const isLoading = isCreatingTemplateFolder || isUpdatingTemplateFolder;

  const handleSubmit = async () => {
    const folderProperties: CreateOrUpdateTaskTemplateFolderPayload = {
      name: formState.name,
      color: formState.color,
      img: formState.emoji,
    };

    if (isEditing) {
      const response = await updateTemplateFolder({
        entityId,
        folderId: editingFolder.id,
        folder: folderProperties,
      });

      if ("data" in response) {
        toast(
          t({
            id: "feedback.template-folder-updated",
            defaultMessage: "Template folder has been updated",
          })
        );
        onClose();
      }

      return;
    }

    const response = await createTemplateFolder({
      entityId,
      folder: folderProperties,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.template-folder-created",
          defaultMessage: "Template folder has been created",
        })
      );
      onClose();
    }
  };

  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center py-6">
      <EditableTaskTemplateFolderTile
        color={formState.color}
        emoji={formState.emoji}
        className="mb-8"
        onColorChange={(color) => updateFormState("color", color)}
        onEmojiChange={(emoji) => updateFormState("emoji", emoji)}
      />
      <TextInput
        ref={nameInputRef}
        isRequired
        label={t({
          id: "form-controls.template-folder-name.label",
          defaultMessage: "Template folder name",
        })}
        placeholder={t({
          id: "form-controls.template-folder-name.placeholder",
          defaultMessage: "Folder name",
        })}
        value={formState.name}
        onChange={(event) => updateFormState("name", event.target.value)}
        classNames={{ wrapperClassName: "mb-10 w-[400px]" }}
      />
      <div className="flex items-center gap-3.5">
        <Button
          variant="contained"
          color="grey"
          className="w-[143px]"
          onClick={onClose}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          isDisabled={!isValid || isLoading}
          className="w-[143px]"
          onClick={handleSubmit}
        >
          {isEditing
            ? t({
                id: "common.save-changes",
                defaultMessage: "Save changes",
              })
            : t({
                id: "tasks-page.create-folder",
                defaultMessage: "Create folder",
              })}
        </Button>
      </div>
    </BottomCenteredDrawer.Content>
  );
};

export interface ManageTaskTemplateFolderDialogProps {
  entityId: string;
  isOpen: boolean;
  editingFolder?: TaskTemplateFolder | null;
  onClose: () => void;
}

export const ManageTaskTemplateFolderDialog: FC<
  ManageTaskTemplateFolderDialogProps
> = ({ entityId, isOpen, editingFolder, onClose }) => {
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslations();

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        type: "title",
        title: editingFolder
          ? t({
              id: "tasks-page.edit-template-folder",
              defaultMessage: "Edit template folder",
            })
          : t({
              id: "tasks-page.new-template-folder",
              defaultMessage: "New template folder",
            }),
      }}
      initialFocus={nameInputRef}
      onClose={onClose}
    >
      <ManageTaskTemplateFolderDialogContent
        entityId={entityId}
        nameInputRef={nameInputRef}
        editingFolder={editingFolder}
        onClose={onClose}
      />
    </BottomCenteredDrawer>
  );
};
