import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import AgoraRTC, {
  ILocalVideoTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  IMicrophoneAudioTrack,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import { usePrevious } from "@web-src/utils/helper";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from "agora-extension-virtual-background";
import {
  AIDenoiserExtension,
  AIDenoiserProcessorLevel,
  AIDenoiserProcessorMode,
} from "agora-extension-ai-denoiser";
import { ScenarioCallType } from "@web-src/modules/chats/types";
import { useToast, useTranslations } from "@jugl-web/utils";
import { ActiveCallProps } from "../types";
import { useCallsSettings } from "./useCallsSettings";

const virtualBackgroundExtension = new VirtualBackgroundExtension();
const denoiser = new AIDenoiserExtension({
  assetsPath: "",
});

AgoraRTC.registerExtensions([virtualBackgroundExtension, denoiser]);

interface AgoraRTCRemoteUser extends IAgoraRTCRemoteUser {
  _uintid?: number;
}

type ActiveCallParams = {
  localAudioTrack: IMicrophoneAudioTrack | null;
  localVideoTrack: ICameraVideoTrack | null;
  localScreenShareTrack: ILocalVideoTrack | undefined;
  remoteUsers: AgoraRTCRemoteUser[] | undefined;
  toggleScreenShare: () => void;
  toggleVideo: (shouldSaveSetting?: boolean) => void;
  toggleAudio: (shouldSaveSetting?: boolean) => void;
  toggleProcessor: () => void;
  leaveCall: () => void;

  screenSharingEnabled: boolean;
  videoEnabled: boolean;
  audioEnabled: boolean;
  videoProcessor: IVirtualBackgroundProcessor | undefined;
  handleStopScreenShare: () => void;
  joinConferenceFromStaging: () => void;
  stage: "staging" | "participation";
};

const screenSharingClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

export const useActiveCall = (
  client: IAgoraRTCClient,
  rtcProps: ActiveCallProps | undefined
): ActiveCallParams | null => {
  const { getCallsSettings, setCallsSettings } = useCallsSettings();
  const storedDevicesSettings = getCallsSettings();
  const videoProcessor = useRef<IVirtualBackgroundProcessor>();
  const [videoEnabled, setVideoEnabled] = useState<boolean>(
    storedDevicesSettings.isVideoOn || false
  );
  const [audioEnabled, setAudioEnabled] = useState<boolean>(
    storedDevicesSettings.isAudioOn || false
  );

  const { localMicrophoneTrack: localAudioTrack, error: localAudioTrackError } =
    useLocalMicrophoneTrack(audioEnabled, {
      encoderConfig: "speech_standard",
      ANS: true,
      AEC: true,
      microphoneId: storedDevicesSettings?.microphone_id,
    });

  const { localCameraTrack: localVideoTrack, error: localVideoTrackError } =
    useLocalCameraTrack(videoEnabled, {
      optimizationMode: "motion",
      encoderConfig: storedDevicesSettings.camera_resolution || "480p_1",
      cameraId: storedDevicesSettings?.camera_id || undefined,
    });

  usePublish([localAudioTrack, localVideoTrack]);

  const [localScreenShareTrack, setLocalScreenShareTrack] =
    useState<ILocalVideoTrack>();
  const [localScreenShareAudioTrack, setLocalScreenShareAudioTrack] =
    useState<ILocalAudioTrack>();
  const localScreenShareTrackRef = useRef<
    ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]
  >();

  const unprocessedRemoteUsers = useRemoteUsers(client);
  const remoteUsers = useMemo(() => {
    // Users with staging_ prefix are in entry screen and need to be filtered out
    const filteredUsers: IAgoraRTCRemoteUser[] = unprocessedRemoteUsers.filter(
      (user) => {
        const id = user.uid.toString();
        return !id.includes("staging_");
      }
    );
    return filteredUsers;
  }, [unprocessedRemoteUsers]);

  const meId = useSelector(selectUserId);
  const previousRtcProps = usePrevious(rtcProps);

  const [screenSharingEnabled, setScreenSharingEnabled] =
    useState<boolean>(false);

  const [processorEnabled, setProcessorEnabled] = useState<boolean>();
  const [stage, setStage] = useState<"staging" | "participation">("staging");
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const toggleVideo = useCallback(
    async (shouldSaveSettings = true) => {
      if (!localVideoTrack) {
        setVideoEnabled(true);
      }
      const nextIsDisabled = !localVideoTrack?.enabled;
      if (nextIsDisabled) {
        await client.disableDualStream();
      }
      await localVideoTrack?.setEnabled(nextIsDisabled);
      if (shouldSaveSettings) {
        setCallsSettings({
          isVideoOn: nextIsDisabled,
        });
      }
      setVideoEnabled(nextIsDisabled);
    },
    [localVideoTrack, setCallsSettings, client]
  );

  useEffect(() => {
    if (
      !rtcProps ||
      (previousRtcProps && isEqual(rtcProps, previousRtcProps))
    ) {
      return;
    }
    const isCall = rtcProps.type === ScenarioCallType.call;
    const clientPrefix = stage === "staging" && !isCall ? "staging" : "web";
    client
      .join(
        rtcProps.appId,
        rtcProps.channel,
        rtcProps.token,
        `${clientPrefix}_${meId}`
      )
      .then(async () => {
        try {
          if (isCall) {
            setAudioEnabled(true);
            setVideoEnabled(false);
            if (rtcProps.video) {
              toggleVideo();
              setVideoEnabled(true);
            } else {
              setVideoEnabled(false);
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            // do nothing
          }
        }
      });
  }, [
    rtcProps,
    previousRtcProps,
    client,
    meId,
    stage,
    toggleVideo,
    getCallsSettings,
    toast,
  ]);

  useEffect(() => {
    const videoTrack = localVideoTrack as ICameraVideoTrack & {
      processor: unknown;
    };
    if (videoEnabled && localVideoTrack && !videoTrack.processor) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoProcessor.current = virtualBackgroundExtension.createProcessor();
      videoProcessor.current?.init("../assets/agora-wasm.wasm");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoProcessor.current?.enable();
      localVideoTrack
        ?.pipe(videoProcessor.current)
        .pipe(localVideoTrack.processorDestination);
    }
  }, [localVideoTrack, videoEnabled]);

  useEffect(() => {
    const audioTrack = localAudioTrack as IMicrophoneAudioTrack & {
      processor: unknown;
    };
    if (audioEnabled && localAudioTrack && !audioTrack.processor) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const audioProcessor = denoiser.createProcessor();

      localAudioTrack
        ?.pipe(audioProcessor)
        .pipe(localAudioTrack.processorDestination);
      audioProcessor.setMode(AIDenoiserProcessorMode.NSNG);
      audioProcessor.setLevel(AIDenoiserProcessorLevel.SOFT);
      audioProcessor.enable();
    }
  }, [audioEnabled, localAudioTrack]);

  useEffect(() => {
    remoteUsers.forEach((user) => {
      client.setStreamFallbackOption(user.uid, 2);
    });
  }, [remoteUsers, client]);

  const handleStopScreenShare = useCallback(async () => {
    if (Array.isArray(localScreenShareTrackRef?.current)) {
      await localScreenShareTrackRef.current[0]?.close();
      await localScreenShareTrackRef.current[1]?.close();
    } else {
      await localScreenShareTrackRef.current?.close();
    }
    await localScreenShareAudioTrack?.close();
    await localScreenShareTrack?.close();
    await screenSharingClient.leave();
    setLocalScreenShareAudioTrack(undefined);
    setLocalScreenShareTrack(undefined);
    setScreenSharingEnabled(false);
  }, [localScreenShareAudioTrack, localScreenShareTrack]);

  const toggleScreenShare = useCallback(async () => {
    if (screenSharingEnabled || localScreenShareTrack) {
      handleStopScreenShare();
      return;
    }
    const screenShareTrack = await AgoraRTC.createScreenVideoTrack(
      {
        optimizationMode: "detail",
      },
      "auto"
    );
    localScreenShareTrackRef.current = screenShareTrack;
    const screenShareVideoTrack = Array.isArray(screenShareTrack)
      ? screenShareTrack[0]
      : screenShareTrack;
    const screenShareAudioTrack = Array.isArray(screenShareTrack)
      ? screenShareTrack[1]
      : undefined;
    setLocalScreenShareTrack(screenShareVideoTrack);
    setLocalScreenShareAudioTrack(screenShareAudioTrack);
    if (!rtcProps) return;
    screenSharingClient
      .join(
        rtcProps?.appId,
        rtcProps?.channel,
        rtcProps?.token,
        `screenSharing_${meId}`
      )
      .then(async () => {
        screenSharingClient.publish(screenShareVideoTrack);
      });
    setScreenSharingEnabled(true);
  }, [
    screenSharingEnabled,
    localScreenShareTrack,
    rtcProps,
    meId,
    handleStopScreenShare,
  ]);

  useEffect(() => {
    const isVideoPermissionError = localVideoTrackError?.message
      .toLocaleLowerCase()
      .includes("permission_denied");
    if (isVideoPermissionError) {
      toast(
        t({
          id: "call-conference.video-no-browser-permission",
          defaultMessage:
            "Unable to access the camera. Please check your browser permissions and ensure that access to the camera is allowed.",
        }),
        {
          variant: "error",
        }
      );
      setVideoEnabled(false);
    }
  }, [localVideoTrackError, t, toast]);
  useEffect(() => {
    const isAudioPermissionError = localAudioTrackError?.message
      .toLocaleLowerCase()
      .includes("permission_denied");

    if (isAudioPermissionError) {
      toast(
        t({
          id: "call-conference.audio-no-browser-permissions",
          defaultMessage:
            "Unable to access the microphone. Please check your browser permissions and ensure that access to the microphone is allowed.",
        }),
        {
          variant: "error",
        }
      );
      setAudioEnabled(false);
    }
  }, [localAudioTrackError, t, toast]);
  const toggleAudio = useCallback(
    async (shouldSaveSetting = true) => {
      const nextIsDisabled = !localAudioTrack?.enabled;

      if (!localAudioTrack) {
        setAudioEnabled(true);
      } else {
        await localAudioTrack?.setEnabled(nextIsDisabled);
        setAudioEnabled(nextIsDisabled);
      }
      if (shouldSaveSetting) {
        setCallsSettings({
          isAudioOn: nextIsDisabled,
        });
      }
    },
    [localAudioTrack, setCallsSettings]
  );

  const toggleProcessor = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (videoProcessor?.current.enabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoProcessor.current.release();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoProcessor?.current?.enable();
    }
    setProcessorEnabled(!processorEnabled);
  }, [processorEnabled]);
  const leaveCall = useCallback(async () => {
    if (audioEnabled) {
      toggleAudio(false);
    }
    if (videoEnabled) {
      toggleVideo(false);
    }
    if (localScreenShareTrack) {
      handleStopScreenShare();
    }
    setStage("staging");
    await client.leave();
    await screenSharingClient.leave();
  }, [
    audioEnabled,
    client,
    handleStopScreenShare,
    localScreenShareTrack,
    toggleAudio,
    toggleVideo,
    videoEnabled,
  ]);

  const joinConferenceFromStaging = useCallback(async () => {
    await leaveCall();
    setStage("participation");
  }, [leaveCall, setStage]);
  const params: ActiveCallParams | null = useMemo(
    () =>
      rtcProps
        ? {
            localAudioTrack,
            localVideoTrack,
            remoteUsers,
            toggleScreenShare,
            toggleVideo,
            toggleAudio,
            toggleProcessor,
            leaveCall,
            screenSharingEnabled,
            localScreenShareTrack,
            videoEnabled,
            audioEnabled,
            videoProcessor: videoProcessor.current,
            handleStopScreenShare,
            joinConferenceFromStaging,
            stage,
          }
        : null,
    [
      localAudioTrack,
      localVideoTrack,
      remoteUsers,
      rtcProps,
      toggleScreenShare,
      toggleVideo,
      toggleAudio,
      toggleProcessor,
      leaveCall,
      handleStopScreenShare,
      screenSharingEnabled,
      localScreenShareTrack,
      videoEnabled,
      audioEnabled,
      joinConferenceFromStaging,
      stage,
    ]
  );

  return params;
};
