import { PlainButton, Popover, PopoverProps } from "@jugl-web/ui-components";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, forwardRef, HTMLAttributes, ReactNode } from "react";
import { MoveToTaskPopover } from "../../MoveToTaskPopover";
import { useTaskChecklistContext } from "../../TaskChecklistProvider";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as MoveIcon } from "./assets/move.svg";
import { ReactComponent as SelectItemsIcon } from "./assets/select-items.svg";

interface MenuItemButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  isActive?: boolean;
  startSlot?: ReactNode;
  endSlot?: ReactNode;
}

const MenuItemButton = forwardRef<HTMLButtonElement, MenuItemButtonProps>(
  ({ label, isActive, startSlot, endSlot, className, ...props }, ref) => (
    <PlainButton
      ref={ref}
      className={cx(
        "transition-color hover:bg-grey-100 focus:bg-grey-100 flex h-full w-full items-center gap-2.5 px-4 py-3 outline-none",
        isActive && "bg-grey-100",
        className
      )}
      {...props}
    >
      {startSlot}
      <span
        className={cx(
          "text-dark font-secondary grow text-left text-sm leading-[16px]"
        )}
      >
        {label}
      </span>
      {endSlot}
    </PlainButton>
  )
);

interface TaskChecklistItemMenuPopoverProps
  extends Pick<PopoverProps, "placement" | "renderTrigger"> {
  itemId: string;
  onDelete: (itemId: string) => void;
}

export const TaskChecklistItemMenuPopover: FC<
  TaskChecklistItemMenuPopoverProps
> = ({ itemId, onDelete, ...popoverProps }) => {
  const {
    entityId,
    meId,
    taskId,
    isSelectable,
    onSelectModeChange,
    onSelectedChecklistIdsChange,
    onMoveChecklist,
  } = useTaskChecklistContext();

  const { isWeb } = useAppVariant();
  const { t } = useTranslations();

  const handleEnterSelectMode = () => {
    onSelectModeChange(true);
    onSelectedChecklistIdsChange([itemId]);
  };

  return (
    <>
      <Popover
        className="max-h-[300px] w-[242px] overflow-y-auto rounded-xl bg-white shadow-[0px_8px_16px_rgba(0,0,0,0.12)] outline-none"
        {...popoverProps}
      >
        {({ onClose }) => (
          <>
            {isSelectable && isWeb && (
              <MoveToTaskPopover
                entityId={entityId}
                meId={meId}
                taskId={taskId as string}
                placement="left-start"
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Trigger
                    ref={triggerRef}
                    as={MenuItemButton}
                    label={t({
                      id: "tasks-page.move-subtask",
                      defaultMessage: "Move Subtask",
                    })}
                    isActive={isOpen}
                    startSlot={<MoveIcon className="shrink-0" />}
                    endSlot={<ChevronRightIcon className="shrink-0" />}
                    onClick={() => onSelectedChecklistIdsChange([itemId])}
                  />
                )}
                onSelect={async (targetTask) => {
                  const isChecklistMovedSuccesfully = await onMoveChecklist(
                    targetTask.id
                  );
                  if (isChecklistMovedSuccesfully) {
                    onClose();
                  }
                }}
              />
            )}
            {isSelectable && !isWeb && (
              <MenuItemButton
                label={t({
                  id: "tasks-page.move-subtask",
                  defaultMessage: "Move Subtask",
                })}
                startSlot={<MoveIcon className="shrink-0" />}
                endSlot={<ChevronRightIcon className="shrink-0" />}
                onClick={(event) => {
                  event.stopPropagation();
                  onSelectedChecklistIdsChange([itemId]);
                  onMoveChecklist();
                  onClose();
                }}
              />
            )}
            {isSelectable && (
              <MenuItemButton
                label={t({
                  id: "tasks-page.select-subtasks",
                  defaultMessage: "Select Subtasks",
                })}
                startSlot={<SelectItemsIcon className="shrink-0" />}
                onClick={(event) => {
                  event.stopPropagation();
                  handleEnterSelectMode();
                }}
              />
            )}
            <MenuItemButton
              label={t({
                id: "common.delete",
                defaultMessage: "Delete",
              })}
              startSlot={<DeleteIcon className="shrink-0" />}
              onClick={(event) => {
                event.stopPropagation();
                onDelete(itemId);
              }}
            />
          </>
        )}
      </Popover>
    </>
  );
};
