import { UserCustomField } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { useMemo } from "react";

export const useDefaultUserFields = () => {
  const { t } = useTranslations();

  const defaultUserFields = useMemo(
    (): UserCustomField[] => [
      {
        id: "user-name",
        name: t({
          id: "cpanel-page.user-name",
          defaultMessage: "User name",
        }),
        bookmark: 0,
        type: "text",
        order: -1,
      },
      {
        id: "email",
        name: t({
          id: "cpanel-page.email",
          defaultMessage: "Email",
        }),
        bookmark: 0,
        type: "email",
        order: -2,
      },
      {
        id: "phone-number",
        name: t({
          id: "cpanel-page.phone-number",
          defaultMessage: "Phone number",
        }),
        bookmark: 0,
        type: "phone",
        order: -3,
      },
    ],
    [t]
  );

  return { defaultUserFields };
};
