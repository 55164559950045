import { Alert } from "@jugl-web/ui-components";
import { useTranslations, useToast } from "@jugl-web/utils";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useForm } from "react-hook-form";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  DiscountItemDataDto,
  DiscountItemDataModel,
} from "@jugl-web/rest-api/inventory/models/DiscountItem";
import { useEffect, useState, useRef } from "react";
import Lottie from "react-lottie";
import { ReactComponent as DiscountIcon } from "./assets/discount.svg";
import { NAME_MAX_LENGTH, DESC_MAX_LENGTH } from "./conts";
import alertAnimation from "./assets/alert.json";

type FormParams = {
  name: string;
  discount: number;
  desc: string;
};
export const ManageDiscountDialog = ({
  isOpen,
  discountItem,
  onClose,
  onSuccess,
}: {
  isOpen: boolean;
  discountItem: DiscountItemDataModel | null;
  onClose: () => void;
  onSuccess: (item: DiscountItemDataDto) => void;
}) => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormParams>({
    defaultValues: {
      name: undefined,
      discount: undefined,
      desc: undefined,
    },
  });
  const [
    isConfirmDiscardManageDiscountChangesAlertOpen,
    setIsConfirmDiscardManageDiscountChangesAlertOpen,
  ] = useState(false);
  const inProgress = useRef(false);
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast();
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const [addDiscount] = inventoryApi.useAddDiscountMutation();
  const [updateDiscount] = inventoryApi.useUpdateDiscountMutation();
  const formParams = watch();
  const handleSubmitForm = handleSubmit(async (data: FormParams) => {
    if (inProgress.current) return;
    data.discount = Number(data.discount);
    inProgress.current = true;
    const response = discountItem
      ? await updateDiscount({
          entityId: entity.id,
          itemId: discountItem.id,
          params: data,
        })
      : await addDiscount({
          entityId: entity.id,
          params: data,
        });
    if ("data" in response) {
      onSuccess?.(response.data);
      toast(
        discountItem
          ? t({
              id: "feedback.discount-was-updated",
              defaultMessage: "Discount was updated",
            })
          : t({
              id: "feedback.new-discount-created",
              defaultMessage: "New Discount has been created",
            })
      );
      handleClose();
    }
    if ("error" in response) {
      inProgress.current = false;
    }
  });

  const handleClose = () => {
    onClose();
    inProgress.current = true;
    reset({
      name: undefined,
      discount: undefined,
      desc: undefined,
    });
  };

  useEffect(() => {
    if (isOpen) {
      inProgress.current = false;
      reset({
        name: discountItem?.name || undefined,
        discount: Number(discountItem?.discount) || undefined,
        desc: discountItem?.desc || undefined,
      });
    } else {
      reset({
        name: undefined,
        discount: undefined,
        desc: undefined,
      });
    }
  }, [isOpen, discountItem, reset]);

  return (
    <>
      <Alert
        className="z-50 w-[660px]"
        isOpen={isOpen}
        onRequestClose={() =>
          setIsConfirmDiscardManageDiscountChangesAlertOpen(true)
        }
        header={
          <div className="flex items-center gap-3">
            <DiscountIcon />
            {discountItem
              ? t({
                  id: "inventory-discounts-page.edit-discount",
                  defaultMessage: "Edit Discount",
                })
              : t({
                  id: "inventory-discounts-page.add-discount",
                  defaultMessage: "Add Discount",
                })}
          </div>
        }
        isCloseButtonVisible
        buttonsContainerClassName="flex justify-center [&>button]:flex-none"
        buttons={[
          {
            text: t({ id: "common.save", defaultMessage: "Save" }),
            color: "primary",
            isDisabled: inProgress.current,
            onClick: () => {
              handleSubmitForm();
            },
            className: "w-[300px]",
          },
        ]}
        content={
          <div
            className="flex flex-col gap-6 text-left"
            key={isOpen ? "open" : "close"}
          >
            <TextInput
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              label={t({
                id: "inventory-discounts-page.discount-name",
                defaultMessage: "Discount Name",
              })}
              isRequired
              {...register("name", {
                maxLength: {
                  value: NAME_MAX_LENGTH,
                  message: `${formParams.name?.length}/${NAME_MAX_LENGTH}`,
                },
                required: {
                  value: true,
                  message: t({
                    id: "inventory-item-form-page.mandatory-field",
                    defaultMessage: "Mandatory field",
                  }),
                },
                validate: (value) => !!value.trim(),
              })}
              isInvalid={!!errors.name}
              errorMessage={
                (formParams.name?.length > NAME_MAX_LENGTH - 11 &&
                  `${formParams.name.length}/${NAME_MAX_LENGTH}`) ||
                errors.name?.message
              }
              value={formParams.name}
            />
            <TextInput
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              key={isOpen ? "open" : "close"}
              label={t({
                id: "inventory-discounts-page.discount-per-item",
                defaultMessage: "Discount per item",
              })}
              isRequired
              type="number"
              onKeyDown={(e) => {
                const notAllowedCharacters = ["e", "E", "+", "-", ",", "."];
                if (notAllowedCharacters.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              min={1}
              max={100}
              step={5}
              {...register("discount", {
                value: formParams.discount,
                required: {
                  value: true,
                  message: t({
                    id: "inventory-discounts-page.mandatory-field",
                    defaultMessage: "Mandatory field",
                  }),
                },
                validate: (value) => {
                  if (Number(value) === 0) {
                    return t({
                      id: "inventory-discounts-page.invalid-min-discount",
                      defaultMessage: "Min discount is 1%",
                    });
                  }
                  if (Number(value) > 100) {
                    return t(
                      {
                        id: "inventory-discounts-page.invalid-max-discount",
                        defaultMessage: "Max discount is {maxPriceValue}%",
                      },
                      {
                        maxPriceValue: 100,
                      }
                    );
                  }
                  return true;
                },
              })}
              isInvalid={!!errors.discount}
              errorMessage={errors.discount?.message}
              endContent="%"
            />
            <TextAreaInput
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              {...register("desc", {
                value: formParams.desc,
                maxLength: {
                  value: DESC_MAX_LENGTH,
                  message: `${formParams.desc?.length}/${DESC_MAX_LENGTH}`,
                },
                setValueAs: (value) => value.trim(),
              })}
              label={t({
                id: "common.description",
                defaultMessage: "Description",
              })}
              isInvalid={!!errors.desc}
              tooltip={t({
                id: "inventory-discounts-page.description-tooltip",
                defaultMessage:
                  "Description of the Discount will not be visible by Clients. This field is only for Internal usage ✅",
              })}
              errorMessage={
                (formParams.desc?.length > DESC_MAX_LENGTH - 11 &&
                  `${formParams.desc.length}/${DESC_MAX_LENGTH}`) ||
                errors.desc?.message
              }
            />
          </div>
        }
      />
      <Alert
        isOpen={isConfirmDiscardManageDiscountChangesAlertOpen}
        onRequestClose={() => {
          setIsConfirmDiscardManageDiscountChangesAlertOpen(false);
        }}
        img={
          <Lottie
            options={{
              animationData: alertAnimation,
            }}
            height={70}
            width={70}
          />
        }
        title={t({
          id: "order-form-create-page.discard-changes",
          defaultMessage: "Discard changes",
        })}
        content={t({
          id: "order-form-create-page.discard-changes-message",
          defaultMessage:
            "If you discard changes, entered Form info will not be saved",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
            onClick: () => {
              setIsConfirmDiscardManageDiscountChangesAlertOpen(false);
            },
          },
          {
            text: t({
              id: "common.discard",
              defaultMessage: "Discard",
            }),
            color: "tertiary",
            onClick: () => {
              setIsConfirmDiscardManageDiscountChangesAlertOpen(false);
              handleClose();
            },
          },
        ]}
      />
    </>
  );
};
