import {
  ChatMessageAttachmentType,
  ChatMessagePayloadType,
} from "@jugl-web/rest-api";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { FC, ReactElement, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { ChatItem } from "@web-src/modules/chats/types";
import { ReactComponent as ChatImage } from "./assets/chat-image.svg";
import { ReactComponent as ChatVideo } from "./assets/chat-video.svg";
import { ReactComponent as ChatAudio } from "./assets/chat-audio.svg";
import { ReactComponent as ChatGif } from "./assets/chat-gif.svg";
import { ReactComponent as ChatDocument } from "./assets/chat-document.svg";
import { ReactComponent as ChatVoice } from "./assets/chat-voice.svg";
import { ReactComponent as ChatAttachment } from "./assets/chat-attachment.svg";
import { ReactComponent as ChatPdf } from "./assets/chat-pdf.svg";
import chatVideoIcon from "./assets/chat-video.png";

export const ChatLastMessage: FC<{ chat: ChatItem }> = ({ chat }) => {
  const lastMessageTokens = chat.lastMessage?.tokenizedMessage;
  const { t } = useTranslations();
  const attachmentInfo: {
    icon: ReactElement;
  } | null = useMemo(() => {
    const attachment = chat.lastMessage?.attachments?.[0];
    if (chat.lastMessage?.conference) {
      return {
        icon: (
          <img
            src={chatVideoIcon}
            alt="video"
            className="relative bottom-0.5 h-[16px] w-[16px]"
          />
        ),
      };
    }
    if (!attachment) {
      return null;
    }
    switch (attachment.type) {
      case ChatMessageAttachmentType.image:
        return {
          icon: <ChatImage />,
        };
      case ChatMessageAttachmentType.audio:
        return {
          icon: <ChatAudio />,
        };
      case ChatMessageAttachmentType.video:
        return {
          icon: <ChatVideo />,
        };
      case ChatMessageAttachmentType.doc:
        return {
          icon: <ChatDocument />,
        };
      case ChatMessageAttachmentType.otherFile:
        return attachment.mimetype.search("pdf") === -1
          ? {
              icon: <ChatAttachment />,
            }
          : {
              icon: <ChatPdf />,
            };
      case ChatMessageAttachmentType.voice:
        return {
          icon: <ChatVoice />,
        };
      case ChatMessageAttachmentType.gif:
      case ChatMessageAttachmentType.gifDirectUrl:
        return {
          icon: <ChatGif />,
        };
      default:
        return null;
    }
  }, [chat.lastMessage?.attachments, chat.lastMessage?.conference]);

  if (chat.type === "entity_conversation") {
    return t({
      id: "chats-page.workspace-notifications",
      defaultMessage: "Workspace Notifications",
    });
  }

  if (chat.lastMessage?.deleted) {
    return t({
      id: "chats-page.deleted-message",
      defaultMessage: "Message deleted by owner",
    });
  }
  if (!chat.lastMessage) {
    return t({
      id: "chats-page.no-chat-history-yet",
      defaultMessage: "No Chat History yet 👋",
    });
  }
  if (!attachmentInfo && !lastMessageTokens?.length) {
    return t({
      id: "chats-page.unknown-message",
      defaultMessage: "Unknown message",
    });
  }

  return (
    <>
      {chat.lastMessage.from &&
        chat.lastMessage.type !== ChatMessagePayloadType.group_info &&
        chat.type === "muc" && (
          <UserProfileName userId={chat.lastMessage.from} suffix=": " />
        )}
      {attachmentInfo && (
        <>
          <span className="relative bottom-[-5px] mr-1 h-[16px]">
            {attachmentInfo.icon}
          </span>
        </>
      )}
      {chat.lastMessage.conference
        ? t({
            id: "common.join-video-meeting",
            defaultMessage: "Join video meeting",
          })
        : lastMessageTokens &&
          lastMessageTokens.map((item, idx) => (
            // TODO: make component
            <span key={item.id}>
              {item.type === "userName" ? (
                <UserProfileName userId={item.rawString} suffix={item.suffix} />
              ) : item.type === "mention" && item.value === MENTIONS_ALL_ID ? (
                t({
                  id: "common.all",
                  defaultMessage: "All",
                })
              ) : (
                item.humanizedString || item.rawString
              )}
              {idx !== lastMessageTokens.length ? " " : ""}
            </span>
          ))}
    </>
  );
};
