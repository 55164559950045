import React, { PropsWithChildren } from "react";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import { DrawerHeader } from "@jugl-web/ui-components/web/DrawerHeader";

export const ChatSideBar: React.FC<
  PropsWithChildren<{
    title?: string;
    footer?: React.ReactElement;
    header?: React.ReactElement;
    onRequestClose: () => void;
  }>
> = ({ children, title, footer, header, onRequestClose }) => (
  <MultiSectionLayout
    header={
      <div>
        <DrawerHeader type="title" title={title} onClose={onRequestClose} />
        {header}
      </div>
    }
    footer={footer}
    className="shadow-[-2px 0px 3px rgba(0, 0, 0, 0.05)] relative z-[100] h-full min-w-[360px] max-w-[415px]"
  >
    {children}
  </MultiSectionLayout>
);
