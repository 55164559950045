import { useFormattedCustomFieldValue } from "@jugl-web/domain-resources/tasks/hooks/useFormattedCustomFieldValue";
import {
  InternalTaskCustomField,
  InternalTaskFilters,
} from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterPill } from "../FilterPill";

interface CustomFieldFilterPillProps {
  customField: InternalTaskCustomField;
  value: InternalTaskFilters["customFields"][string][number];
  onRemove?: () => void;
}

export const CustomFieldFilterPill: FC<CustomFieldFilterPillProps> = ({
  customField,
  value,
  onRemove,
}) => {
  const { t } = useTranslations();
  const { formatCustomFieldValue } = useFormattedCustomFieldValue();

  if (value === null) {
    return (
      <FilterPill
        label={t({
          id: "tasks-page.without-custom-field-value",
          defaultMessage: "Without value",
        })}
        onRemove={onRemove}
      />
    );
  }

  return (
    <FilterPill
      label={formatCustomFieldValue(customField, value)}
      onRemove={onRemove}
    />
  );
};
