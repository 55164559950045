import { FC, useState } from "react";
import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import Lottie from "react-lottie";
import { cx } from "@jugl-web/utils";
import animationData from "./assets/loading-animation.json";
import { ReactComponent as ImagePlaceholder } from "./assets/image-placeholder.svg";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";

const MAX_SIZE = 300;
const getDimensions = (
  width?: number,
  height?: number
): { width: number; height: number } | null => {
  if (!width || !height) {
    return null;
  }
  let reduceBy = 1;
  if (width >= height && width > MAX_SIZE) {
    reduceBy = MAX_SIZE / width;
  } else if (width <= height && height > MAX_SIZE) {
    reduceBy = MAX_SIZE / height;
  }
  return {
    width: Math.round(width * reduceBy),
    height: Math.round(height * reduceBy),
  };
};

export const DirectGifAttachment: FC = () => {
  const { attachment } = useChatMessageProvider();
  if (
    !attachment ||
    ![ChatMessageAttachmentType.gifDirectUrl].includes(attachment.type)
  ) {
    throw new Error(
      `Message doesn't have attachment or attachment type "${attachment?.type}" is not supported`
    );
  }

  const [previewState, setPreviewState] = useState<
    "loading" | "error" | "loaded"
  >("loading");

  const dimensions = getDimensions(attachment.width, attachment.height);

  return (
    <div
      className="relative mx-auto overflow-hidden rounded"
      style={{
        background: "linear-gradient(180deg, #F4F6F7 0%, #CDE2F2 100%)",
        width: `${dimensions?.width || MAX_SIZE}px`,
        height: `${dimensions?.height || MAX_SIZE}px`,
      }}
    >
      {previewState === "loading" && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Lottie options={{ animationData }} height={32} width={32} />
        </div>
      )}
      {previewState === "error" && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <ImagePlaceholder />
        </div>
      )}
      <img
        onLoad={() => setPreviewState("loaded")}
        onError={() => setPreviewState("error")}
        className={cx("mx-auto block", {
          invisible: previewState === "error",
        })}
        style={
          dimensions
            ? {
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
              }
            : {
                height: "100%",
                maxWidth: `${MAX_SIZE}px`,
                maxHeight: `${MAX_SIZE}px`,
              }
        }
        src={attachment.url}
        alt={attachment.name}
      />
    </div>
  );
};
