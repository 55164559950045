import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import emptyStateAnimation from "./assets/empty-state-animation.json";
import styles from "./DashboardWidgetEmptyState.module.css";

export const DashboardWidgetEmptyState: FC = () => {
  const { t } = useTranslations();

  return (
    <div className="animate-fade-in flex h-full items-center justify-center">
      <div className="flex flex-col items-center">
        <div className={styles.dashboardWidgetEmptyStateImage}>
          <Lottie
            options={{ animationData: emptyStateAnimation }}
            height={200}
            width={200}
          />
        </div>
        <span className="font-secondary mb-2 text-xl font-medium text-black">
          {t({
            id: "dashboard-page.widget-data-no-results-title",
            defaultMessage: "No results",
          })}
        </span>
        <span className="font-secondary text-sm text-[#828282]">
          {t({
            id: "dashboard-page.widget-data-no-results-description",
            defaultMessage: "Try to adjust your settings or filters",
          })}
        </span>
      </div>
    </div>
  );
};
