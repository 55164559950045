import useEntity from "@web-src/features/app/hooks/useEntity";
import { useCallback } from "react";
import { getUserProfileDisplayName } from "@web-src/features/users/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { ChatType, useRestApiProvider } from "@jugl-web/rest-api";

export const useNavigateToChat = () => {
  const { navigateToPage } = useNavigation();
  const { entity } = useEntity();
  const { setActiveChat } = useActiveChat();
  const { conversations, addConversation } = useConversations();
  const { usersApi } = useRestApiProvider();
  const [getProfile] = usersApi.useLazyGetUserProfileQuery();

  const navigateToChat = useCallback(
    async (chatId: string, entityId?: string) => {
      // TODO: error handling
      if (!conversations.find((item) => item.id === chatId)) {
        const resp = await getProfile({ params: { user_id: chatId } });
        if (resp?.data) {
          addConversation?.({
            params: {
              id: resp.data.user_id,
              type: ChatType.chat,
              title: getUserProfileDisplayName(resp.data),
              img: resp.data.general.img,
            },
          });
        }
      }
      setActiveChat(chatId);
      navigateToPage(
        "chatsActiveChat",
        { activeChatId: chatId },
        { entityId: entityId || entity?.id }
      );
    },
    [
      addConversation,
      conversations,
      entity?.id,
      getProfile,
      navigateToPage,
      setActiveChat,
    ]
  );

  return navigateToChat;
};
