import { useMemo } from "react";
import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { FormGroup, FormGroupProps } from "../FormGroup/FormGroup";

export type SegmentProps = FormGroupProps & {
  items: { id: string; title: string }[];
  onChange: (id: string) => void;
  selectedItem?: string;
  segmentClassName?: string;
};

export const Segment = ({
  items,
  selectedItem,
  segmentClassName,
  onChange,
  ...formGroupProps
}: SegmentProps) => {
  const uniqueId = useUniqueDOMId();
  const segmentId = useMemo(() => uniqueId, [uniqueId]);
  return (
    <FormGroup labelFor={segmentId} {...formGroupProps}>
      <div
        className={cx("flex justify-evenly rounded-[10px]", segmentClassName)}
      >
        {items.map((item, index) => (
          <button
            key={item.id}
            type="button"
            title={item.title}
            disabled={formGroupProps.isDisabled}
            onClick={() => onChange(item.id)}
            className={cx(
              index === items.length - 1 &&
                "rounded-tr-[10px] rounded-br-[10px] border-r-[1px]",
              selectedItem === item.id && "text-dark bg-[#F5F5F7]",
              selectedItem !== item.id && "text-dark-600 bg-white",
              "font-secondary border-grey-400 h-full w-full cursor-pointer truncate border-[1px] border-solid px-5 py-4 text-base font-normal leading-4 duration-300 ease-out hover:z-10 hover:border-[1px] hover:border-[#90C9F9] hover:ring-[1px] hover:ring-[#90C9F9] focus:z-10 focus:border-[1px] focus:border-[#90C9F9] focus:outline-none focus:ring-[1px] focus:ring-[#90C9F9]",
              index !== 0 &&
                index !== items.length - 1 &&
                "border-r-transparent",
              index === 0 &&
                "border-grey-400 rounded-tl-[10px] rounded-bl-[10px] border-r-transparent hover:border-r-[#90C9F9] focus:border-r-[#90C9F9]"
            )}
          >
            {item.title}
          </button>
        ))}
      </div>
    </FormGroup>
  );
};
