import {
  Collapse,
  InteractiveContainer,
  InteractiveContainerProps,
} from "@jugl-web/ui-components/cross-platform";
import {
  assert,
  ClickAwayListener,
  cx,
  getTextWithoutSpacesAndNewLines,
  useTranslations,
} from "@jugl-web/utils";
import { sanitize } from "dompurify";
import { FC, useEffect, useRef, useState } from "react";
import {
  RichTextarea,
  RichTextareaHandle,
} from "../../../common/components/RichTextarea";
import { TaskDescriptionEditorToolbar } from "../TaskDescriptionEditorToolbar";
import { ReactComponent as TextFormatIcon } from "./assets/text-format.svg";

interface DescriptionBoxProps {
  description: string;
  isEditing?: boolean;
  isEditable?: boolean;
  classes?: {
    descriptionText?: string;
    editingBox?: string;
  };
  isPreview?: boolean;
  onStartEditing?: () => void;
  onFinishEditing?: () => void;
  onChange?: (description: string) => void;
}

export const DescriptionBox: FC<DescriptionBoxProps> = ({
  description,
  isEditing,
  isEditable = true,
  classes,
  isPreview,
  onStartEditing,
  onFinishEditing,
  onChange,
}) => {
  const [rawTextDescription, setRawTextDescription] = useState("");
  const [isToolbarExpanded, setIsToolbarExpanded] = useState(false);

  const { t } = useTranslations();

  const richTextareaRef = useRef<RichTextareaHandle | null>(null);

  const handleRawTextChange = (text: string) => {
    setRawTextDescription(getTextWithoutSpacesAndNewLines(text));
  };

  const handleClickAway = () => {
    assert(!!richTextareaRef.current, "RichTextarea ref is not defined");

    const previousDescription = description;

    const nextDescription = rawTextDescription
      ? richTextareaRef.current.saveToHTML()
      : "";

    if (previousDescription !== nextDescription) {
      onChange?.(nextDescription);
    }

    onFinishEditing?.();
  };

  const handleDescriptionClick: InteractiveContainerProps["onClick"] = (
    event
  ) => {
    const isLink = !!(event.target as HTMLElement).closest("a");

    if (!isLink) {
      onStartEditing?.();
    }
  };

  useEffect(() => {
    if (isEditing) {
      richTextareaRef.current?.readFromHTML(description);
      richTextareaRef.current?.focus();
    }
  }, [isEditing, description]);

  if (!isEditing) {
    return (
      <InteractiveContainer
        className={cx(
          "transition-color group rounded py-1 pr-4 duration-300",
          isEditable && "hover:bg-grey-100",
          classes?.descriptionText
        )}
        isDisabled={!isEditable}
        onClick={handleDescriptionClick}
      >
        <div>
          {isPreview ? (
            <span className="inline-block pb-2 font-medium text-[#4F4F4F]">
              Description:
            </span>
          ) : null}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitize(description, {
                ADD_ATTR: ["target"],
              }),
            }}
            className={cx(
              "overflow-hidden break-words text-[#4F4F4F] transition-transform duration-300",
              isEditable && "group-hover:translate-x-2"
            )}
          />
        </div>
      </InteractiveContainer>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={cx(
          "bg-grey-100 flex flex-col overflow-hidden rounded-lg",
          classes?.editingBox
        )}
      >
        <RichTextarea
          ref={richTextareaRef}
          namespace="task-description-editor"
          endSlot={
            <div className="flex flex-col">
              <div className="mb-4 flex items-center gap-4 px-6">
                <button
                  className={cx(
                    "flex h-6 w-6 cursor-pointer items-center justify-center rounded border-[1px] border-solid bg-gray-200 outline-none",
                    isToolbarExpanded ? "border-primary" : "border-gray-200"
                  )}
                  type="button"
                  onClick={() => setIsToolbarExpanded((prev) => !prev)}
                >
                  <TextFormatIcon />
                </button>
              </div>
              <Collapse isOpen={isToolbarExpanded}>
                <div className="bg-gray-200 px-6 py-2">
                  <div className="max-w-sm">
                    <TaskDescriptionEditorToolbar
                      classes={{
                        activeButton: "text-primary-800 bg-primary-50",
                        inactiveButton: "text-dark-700",
                      }}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          }
          placeholder={t({
            id: "tasks-page.enter-description",
            defaultMessage: "Enter description",
          })}
          classes={{
            placeholder: "text-grey-900",
            contentEditable: "max-h-[200px] overflow-y-auto outline-0",
            container: "m-4",
          }}
          onRawTextChange={handleRawTextChange}
        />
      </div>
    </ClickAwayListener>
  );
};
