import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";
import { Tooltip } from "../../Tooltip";

export interface TaskCardPillProps {
  label: ReactNode;
  hint?: ReactNode;
  backgroundColor?: string;
  textColor?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
}

export const PILL_BASE_BG_COLOR = "#F9F9F9";
export const PILL_BASE_TEXT_COLOR = "#40494D";

export const TaskCardPill: FC<TaskCardPillProps> = ({
  label,
  hint,
  backgroundColor = PILL_BASE_BG_COLOR,
  textColor = PILL_BASE_TEXT_COLOR,
  startIcon,
  endIcon,
  className,
}) => (
  <Tooltip
    placement="bottom"
    isDisabled={!hint}
    className="font-secondary px-3 text-sm leading-3"
    renderTrigger={({ ref, props }) => (
      <div
        ref={ref}
        className={cx(
          "flex items-center gap-1.5 overflow-hidden rounded-md py-0.5 px-1.5",
          className
        )}
        style={{
          backgroundColor,
          color: textColor,
        }}
        {...props}
      >
        {startIcon && <div className="shrink-0 leading-[0]">{startIcon}</div>}
        <span className="leading-2 flex items-center truncate text-xs">
          {label}
        </span>
        {endIcon && <div className="shrink-0 leading-[0]">{endIcon}</div>}
      </div>
    )}
  >
    {hint}
  </Tooltip>
);
