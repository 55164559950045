import {
  UserCPanelModel,
  UserCustomField,
  UserEntityStatus,
  useRestApiProvider,
  User,
} from "@jugl-web/rest-api";
import {
  Avatar,
  Button,
  customerPhoneStringToInputValue,
  Dialog,
  inputValueToCustomerPhoneString,
  InteractiveContainer,
  PhoneInput,
  PlainButton,
  Popover,
  TextInput,
} from "@jugl-web/ui-components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { cx, useTranslations } from "@jugl-web/utils";
import { Highlightify } from "@jugl-web/utils/utils/Highlightify";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { MAX_USER_CUSTOM_FIELD_VALUE_LENGTH } from "../../../ManageProfileAttributesDialog/consts";
import { CPanelPageContextValue } from "../../../../CPanelPageProvider";

const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const UserCustomFieldInput: React.FC<{
  field: UserCustomField;
  user: UserCPanelModel | User;
  updateUser$?: CPanelPageContextValue["updateUser$"];
  searchQuery?: string;
  isReadonly?: boolean;
}> = ({ field, user, updateUser$, searchQuery, isReadonly }) => {
  const { usersApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const [value, setValue] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isDialogOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 1);
    } else {
      setValue("");
      setIsInvalid(false);
    }
  }, [isDialogOpen]);

  const [updateUsersApi] = usersApi.useUpdateUserMutation();

  const handleUpdateUser = useCallback(
    async (newValue: string | null) => {
      const response = await updateUsersApi({
        entityId,
        entityRelId: user.entity_rel_id,
        data: { work_info: { [field.id]: newValue } },
      });
      if ("data" in response) {
        updateUser$?.next({
          id: user.entity_rel_id,
          data: {
            work_info: [
              ...user.work_info.filter((el) => el.key !== field.id),
              { key: field.id, value: newValue },
            ],
          },
        });
      }
    },
    [user, updateUsersApi, entityId, updateUser$, field.id]
  );

  const fieldValue = useMemo(
    () => user.work_info.find((el) => el.key === field.id)?.value || null,
    [user, field]
  );

  const formattedFieldValue = useMemo(() => {
    if (fieldValue && field.type === "phone") {
      const [, code, number] = fieldValue.split(",");
      return `(${code}) ${number}`;
    }

    return fieldValue;
  }, [fieldValue, field]);

  useEffect(() => {
    if (fieldValue && isDialogOpen) {
      setValue(fieldValue);
    }
  }, [fieldValue, isDialogOpen]);

  if (field.type === "dropdown") {
    if (isReadonly) {
      return (
        <span className="font-secondary leading-2 truncate text-sm font-medium text-[#4F4F4F]">
          <Highlightify
            searchWord={searchQuery || ""}
            highlightClassName="text-primary"
          >
            {formattedFieldValue}
          </Highlightify>
        </span>
      );
    }
    return (
      <Popover
        placement="bottom"
        isDisabled={user.status === UserEntityStatus.left || isReadonly}
        renderTrigger={({ Trigger, triggerRef, isOpen }) =>
          formattedFieldValue ? (
            <div className="m-4">
              {!isReadonly && (
                <Trigger
                  as={InteractiveContainer}
                  ref={triggerRef}
                  className={cx(
                    "bg-primary flex max-w-max items-center gap-2.5 rounded-md px-2.5 py-2 transition-colors",
                    {
                      "hover:bg-primary-600": !isReadonly,
                    }
                  )}
                >
                  <span className="font-secondary leading-2 truncate text-xs font-medium text-white">
                    {formattedFieldValue}
                  </span>
                  <ArrowIcon
                    className={cx(
                      "shrink-0 transition-transform",
                      isOpen && "rotate-180"
                    )}
                  />
                </Trigger>
              )}
            </div>
          ) : (
            <Trigger
              as={InteractiveContainer}
              ref={triggerRef}
              className="flex h-full w-full items-center rounded py-2 px-2.5 outline-none"
            >
              <span className="text-grey-700">
                {!isReadonly &&
                  t({
                    id: "common.tap-to-select",
                    defaultMessage: "Tap to select",
                  })}
              </span>
            </Trigger>
          )
        }
        className="jugl__custom-scrollbar border-dark-100 flex max-h-[260px] w-[320px] flex-col gap-2 overflow-y-auto rounded-2xl border border-solid bg-white p-4 shadow-[0_18px_27px_2px_rgba(0,0,0,0.12)]"
      >
        {({ onClose }) =>
          field.values.map((fieldOption) => (
            <InteractiveContainer
              className={cx(
                "w-full break-words rounded-lg py-2 px-3",
                fieldValue === fieldOption.value &&
                  "bg-primary-50 hover:bg-primary-50"
              )}
              key={fieldOption.id}
              onClick={() => {
                handleUpdateUser(
                  fieldValue !== fieldOption.value ? fieldOption.value : null
                );
                onClose();
              }}
            >
              {fieldOption.value}
            </InteractiveContainer>
          ))
        }
      </Popover>
    );
  }

  return (
    <>
      <InteractiveContainer
        className={cx(
          "flex h-full w-full items-center rounded py-2 px-2.5 outline-none",
          {
            "hover:bg-grey-100": !isReadonly,
          }
        )}
        onClick={() => setIsDialogOpen(true)}
        isDisabled={user.status === UserEntityStatus.left || isReadonly}
      >
        {formattedFieldValue ? (
          <span className="truncate text-[#4F4F4F]" title={formattedFieldValue}>
            <Highlightify
              highlightClassName="text-primary"
              searchWord={searchQuery || ""}
            >
              {formattedFieldValue}
            </Highlightify>
          </span>
        ) : (
          <span className="text-grey-700">
            {!isReadonly &&
              t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
          </span>
        )}
      </InteractiveContainer>
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="flex w-[500px] flex-col gap-10 bg-white"
      >
        <div className="flex items-center justify-between gap-2 py-2.5 px-8 shadow-[2px_-6px_24px_rgba(18,22,34,0.16)]">
          <div className="flex items-center gap-2 overflow-hidden">
            <Avatar
              username={user.display_name}
              imageUrl={user.profile?.img}
              size="md"
            />
            <span className="font-secondary truncate text-sm font-medium text-[#383838]">
              {user.display_name}
            </span>
          </div>
          <PlainButton onClick={() => setIsDialogOpen(false)}>
            <CloseIcon />
          </PlainButton>
        </div>
        <div className="bg-white px-8">
          {field.type === "email" && (
            <TextInput
              label={field.name}
              value={value}
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              onChange={(e) => {
                const newValue = e.target.value.trimStart();
                setIsInvalid(!!newValue && !EMAIL_REGEXP.test(newValue));
                setValue(newValue);
              }}
              ref={inputRef}
              lengthIndicator={{
                max: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                current: value.length,
              }}
            />
          )}
          {field.type === "number" && (
            <TextInput
              classNames={{
                inputClassName:
                  "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
              }}
              type="number"
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              label={field.name}
              value={value}
              onKeyDown={(e) => {
                const notAllowedCharacters = ["e", "E", "+", "-"];
                if (notAllowedCharacters.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => setValue(e.currentTarget.value)}
              ref={inputRef}
              lengthIndicator={{
                max: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                current: value.length,
              }}
            />
          )}
          {field.type === "text" && (
            <TextInput
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              label={field.name}
              value={value}
              onChange={(e) => setValue(e.currentTarget.value.trimStart())}
              ref={inputRef}
              lengthIndicator={{
                max: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                current: value.length,
              }}
            />
          )}
          {field.type === "phone" && (
            <div className="w-full space-y-2">
              <label className="font-secondary text-dark-700 ml-[10px] text-[15px] leading-[22px]">
                {field.name}
              </label>
              <PhoneInput
                value={
                  value ? customerPhoneStringToInputValue(value) : undefined
                }
                wrapperClassName="border-grey-400 rounded-[10px] border-[2px] border-solid h-[56px] focus-within:border-[#90C9F9]"
                placeholder={t({
                  id: "common.phone-number",
                  defaultMessage: "Phone number",
                })}
                onChange={(phoneNumber) => {
                  setIsInvalid(!!phoneNumber.phone && !phoneNumber.isValid);
                  setValue(
                    phoneNumber.phone
                      ? inputValueToCustomerPhoneString(phoneNumber)
                      : ""
                  );
                }}
                listButtonClassName="text-xs py-2 pl-2"
                ref={inputRef}
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-center p-6 shadow-[0px_6px_24px_rgba(18,22,34,0.12)]">
          <Button
            className="h-10 w-[300px]"
            onClick={() => {
              handleUpdateUser(value);
              setIsDialogOpen(false);
            }}
            isDisabled={
              isInvalid || value.length > MAX_USER_CUSTOM_FIELD_VALUE_LENGTH
            }
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </div>
      </Dialog>
    </>
  );
};
