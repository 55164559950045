import { ChatMessageModel, ChatMessagePayloadType } from "@jugl-web/rest-api";
import { PaginationItem } from "@jugl-web/utils";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { ChatMessage } from "@web-src/modules/chats/components/ChatMessage";
import { ChatMessageBubbleAction } from "@web-src/modules/chats/components/ChatMessage/types/ChatMessageBubbleAction";
import { useSendMessageReaction } from "@web-src/modules/chats/hooks/useSendMessageReaction";
import { useChatsPageWithSelectedChatProvider } from "@web-src/modules/chats/pages/ChatsPage/providers/ChatsPageProvider";
import { ChatItem } from "@web-src/modules/chats/types";
import isSameDay from "date-fns/isSameDay";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

export const ChatMessagesItem: FC<{
  message: ChatMessageModel;
  messages: PaginationItem<ChatMessageModel>[];
  firstUnreadMsgId?: string;
  chat: ChatItem;
  disableMessageActions?: boolean;
  onShowOnScreen?: (message: ChatMessageModel) => void;
  onReplyPreviewClick?: (message: ChatMessageModel) => void;
  isHighlighted?: boolean;
}> = ({
  message,
  messages,
  firstUnreadMsgId,
  chat,
  disableMessageActions,
  onShowOnScreen,
  onReplyPreviewClick,
  isHighlighted,
}) => {
  const {
    changeMessageSelectState,
    activeChatId,
    setMessageAction,
    activeChatState,
    openMessageSideBar,
  } = useChatsPageWithSelectedChatProvider();

  const meId = useSelector(selectUserId);

  const groupInfo = useMemo(() => {
    const idx = messages.findIndex(
      (item) => item.data.msg_id === message.msg_id
    );
    const result = {
      isFirst: false,
      isLast: false,
    };
    if (message?.payload?.type === ChatMessagePayloadType.message) {
      const prevMessage = messages[idx - 1]?.data;
      const nextMessage = messages[idx + 1]?.data;
      if (
        prevMessage?.payload?.type !== ChatMessagePayloadType.message ||
        !prevMessage ||
        prevMessage?.from !== message.from ||
        !isSameDay(new Date(prevMessage.timestamp), new Date(message.timestamp))
      ) {
        result.isFirst = true;
      }
      if (
        nextMessage?.payload?.type !== ChatMessagePayloadType.message ||
        !nextMessage ||
        nextMessage?.from !== message.from
      ) {
        result.isLast = true;
      }
    }
    return result;
  }, [
    message.from,
    message.msg_id,
    message?.payload?.type,
    message.timestamp,
    messages,
  ]);

  const isFirstUnread = firstUnreadMsgId === message.msg_id;

  const { isMessageSelectable, isMessageSelected } = useMemo(() => {
    let isMessageSelectableResult = false;
    if (activeChatState?.messageAction?.isMulti && !message.deleted) {
      if (
        activeChatState?.messageAction?.type ===
          ChatMessageBubbleAction.DELETE &&
        meId === message.from
      ) {
        isMessageSelectableResult = true;
      } else if (
        activeChatState?.messageAction?.type === ChatMessageBubbleAction.FORWARD
      ) {
        isMessageSelectableResult = true;
      }
    }
    let isMessageSelectedResult = false;
    if (isMessageSelectableResult) {
      isMessageSelectedResult =
        !!activeChatState?.messageAction?.messages?.find(
          (item) => item.msg_id === message.msg_id
        );
    }
    return {
      isMessageSelectable: isMessageSelectableResult,
      isMessageSelected: isMessageSelectedResult,
    };
  }, [
    activeChatState?.messageAction?.isMulti,
    activeChatState?.messageAction?.messages,
    activeChatState?.messageAction?.type,
    meId,
    message.deleted,
    message.from,
    message.msg_id,
  ]);

  const sendMessageReaction = useSendMessageReaction();

  return (
    <ChatMessage
      chatId={activeChatId}
      message={message}
      isFirstUnread={isFirstUnread}
      isHighlighted={isHighlighted}
      onTriggerAction={(action) => {
        if (action === ChatMessageBubbleAction.SHOW_MESSAGE_INFO) {
          openMessageSideBar("messageInfo", message);
        } else if (action === ChatMessageBubbleAction.SHOW_REACTIONS) {
          openMessageSideBar("reactions", message);
        }
        setMessageAction(activeChatId, message, action);
      }}
      onReaction={(value) => {
        sendMessageReaction({
          msgId: message.msg_id,
          reaction: value,
          action: message.reactions?.find(
            (item) => item.user_id === meId && item.reaction === value
          )
            ? "remove"
            : "add",
        });
      }}
      isSelectable={isMessageSelectable}
      isSelected={isMessageSelected}
      onSelect={(_, isSelected) =>
        changeMessageSelectState(activeChatId, message, isSelected)
      }
      hasBroadcastChatRestriction={
        chat.config?.admin_only_msg && chat.role !== "admin"
      }
      hasContentProtectionRestriction={
        chat.config?.content_protected && chat.role !== "admin"
      }
      isMessageActionsDisabled={disableMessageActions}
      onShowOnScreen={onShowOnScreen}
      groupInfo={groupInfo}
      onReplyPreviewClick={onReplyPreviewClick}
    />
  );
};
