import { format } from "date-fns";
import { useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useMemo } from "react";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Tooltip } from "@jugl-web/ui-components";

export type ProfileInfoTableCellType =
  | "educations"
  | "achievements"
  | "careers"
  | "location"
  | "native";

export const ProfileInfoTableCell = ({
  type,
  userId,
  onClick,
}: {
  type: ProfileInfoTableCellType;
  userId: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslations();
  const { usersApi } = useRestApiProvider();
  const { data: profile } = usersApi.useGetUserProfileQuery(
    { params: { user_id: userId } },
    { skip: !userId }
  );

  const tooltip = t({
    id: `people-page.profile-info-cell-tooltip`,
    defaultMessage: "Tap to see all Profile info",
  });
  const parameters = useMemo<
    | {
        title: string | null;
        subtitle?: string | null;
        from?: string | null;
        to?: string | null;
        count?: number;
      }
    | undefined
  >(() => {
    if (!profile) return undefined;
    if (type === "educations") {
      const firstElement = profile?.[type]?.[0];
      if (!firstElement) return undefined;
      const { where: title, name: subtitle, start, end } = firstElement;
      const from = firstElement && start && format(new Date(start), "MMM yyyy");
      const to = firstElement && end && format(new Date(end), "MMM yyyy");
      return {
        title,
        subtitle,
        from,
        to,
        count: (profile?.[type]?.length || 0) - 1,
      };
    }
    if (type === "achievements") {
      const firstElement = profile?.[type]?.[0];
      if (!firstElement) return undefined;
      const { title, ref: subtitle, date } = firstElement;
      const from = date && format(new Date(date), "MMM yyyy");
      return {
        title,
        subtitle,
        from,
        count: (profile?.[type]?.length || 0) - 1,
      };
    }
    if (type === "careers") {
      const firstElement = profile?.[type]?.[0];
      if (!firstElement) return undefined;
      const { title, start, end, current } = firstElement;
      const from = start && format(new Date(start), "do MMM yyyy");
      const to = current
        ? t({
            id: "common.present",
            defaultMessage: "Present",
          })
        : end && format(new Date(end), "do MMM yyyy");
      const subtitle = from && to ? `${from} - ${to}` : from || to;

      return {
        title,
        subtitle,
        from: firstElement.org,
        count: (profile?.[type]?.length || 0) - 1,
      };
    }
    return undefined;
  }, [profile, t, type]);

  if (type === "native") {
    const city = profile?.native?.city || "";
    const country = profile?.native?.country || "";
    const locationParts = [city, country].filter(Boolean);
    const content = locationParts.length ? locationParts.join(" - ") : "";
    return (
      <Tooltip
        renderTrigger={({ props, ref }) => (
          <div
            className="flex h-full w-11/12 cursor-pointer items-center truncate"
            title={content}
            {...props}
            ref={ref}
            onClick={onClick}
          >
            {content}
          </div>
        )}
      >
        {tooltip}
      </Tooltip>
    );
  }

  if (type === "location") {
    const location = profile?.locations?.[0];
    const city = location?.city || "";
    const state = location?.state || "";
    const country = location?.country || "";
    const locationParts = [city, state, country].filter(Boolean);
    const content = locationParts.length ? locationParts.join(", ") : "";
    return (
      <Tooltip
        renderTrigger={({ props, ref }) => (
          <div
            className="flex h-full w-11/12 cursor-pointer items-center truncate"
            title={content}
            {...props}
            ref={ref}
            onClick={onClick}
          >
            {content}
          </div>
        )}
      >
        {tooltip}
      </Tooltip>
    );
  }
  const { title, subtitle, from, to, count } = parameters || {};
  return (
    <Tooltip
      renderTrigger={({ props, ref }) => (
        <div
          className="font-secondary [&_a]:text-primary flex h-full w-11/12 cursor-pointer flex-col justify-center gap-1"
          {...props}
          ref={ref}
          onClick={onClick}
        >
          <div
            className="text-dark truncate text-sm font-medium"
            title={title || ""}
          >
            {title}
          </div>
          {subtitle && (
            <div
              className="text-dark-800  truncate text-sm"
              title={subtitle || ""}
            >
              <Linkify>{subtitle}</Linkify>
            </div>
          )}
          {(from || to) && (
            <div
              className="truncate text-xs leading-[140%] text-[#828282]"
              title={(from && to ? `${from} - ${to}` : to || from) || ""}
            >
              {type !== "careers" && (
                <>{from && to ? `${from} - ${to}` : to || from}</>
              )}
              {type === "careers" && (
                <Linkify>
                  <div className="truncate">{from}</div>
                </Linkify>
              )}
            </div>
          )}
          {count && count >= 1 ? (
            <div className="text-primary text-xs">
              + {count}{" "}
              {t(
                {
                  id: "common.items",
                  defaultMessage: "{count, plural, one {item} other {items}}",
                },
                {
                  count,
                }
              )}
            </div>
          ) : null}
        </div>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};
