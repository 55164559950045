import { FC, useMemo } from "react";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { cx, useTranslations } from "@jugl-web/utils";
import { clearMessageBody } from "@web-src/modules/chats/utils";
import { ReactComponent as ReplyIcon } from "./assets/reply-icon.svg";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";
import { ReplyAttachmentPreview } from "./components/ReplyAttachmentPreview";

export const ReplyMessagePreview: FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  const { message, isOutgoing } = useChatMessageProvider();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();

  const replyAttributesMap = useMemo(
    () => message.payload.reply_attributes_map,
    [message.payload.reply_attributes_map]
  );
  if (!replyAttributesMap) {
    throw new Error("Message doesn't have reply attributes");
  }
  const attachment = useMemo(
    () => replyAttributesMap.reply_attachments?.[0],
    [replyAttributesMap.reply_attachments]
  );
  const bodyText = useMemo(() => {
    if (replyAttributesMap.reply_conference) {
      return t({
        id: "chats-page.join-vide-meeting",
        defaultMessage: "Join video meeting",
      });
    }
    if (attachment?.name) {
      return attachment.name;
    }
    const messageBody =
      replyAttributesMap?.reply_msg_body ||
      replyAttributesMap?.reply_attachments?.[0]?.caption ||
      "";
    return clearMessageBody(messageBody);
  }, [
    attachment?.name,
    replyAttributesMap?.reply_attachments,
    replyAttributesMap?.reply_msg_body,
    replyAttributesMap.reply_conference,
    t,
  ]);

  return (
    <div
      className={cx(
        "mb-1 min-w-[200px] rounded bg-white p-[10px]",
        isOutgoing ? "bg-white" : "bg-dark-100",
        { "cursor-pointer": !!onClick }
      )}
      onClick={onClick}
    >
      <div className="flex h-10 shrink-0 gap-2">
        {!!attachment && <ReplyAttachmentPreview />}
        <div className="flex h-full flex-col justify-center gap-1 overflow-hidden">
          <UserGeneralProfile
            userId={replyAttributesMap.reply_msg_user}
            entityId={entityId}
          >
            {(user) => (
              <div className="flex justify-between overflow-hidden text-ellipsis whitespace-nowrap">
                <span
                  className={cx(
                    "overflow-hidden text-ellipsis text-[15px] font-medium",
                    isOutgoing ? "text-primary-500" : "text-[#B021F3]"
                  )}
                >
                  <>
                    {user.profile?.displayName || user.safeProfile.displayName}
                  </>
                </span>
                <ReplyIcon
                  className="shrink-0"
                  fill={isOutgoing ? "#2196F3" : "#B021F3"}
                />
              </div>
            )}
          </UserGeneralProfile>
          <div className="text-dark-800 overflow-hidden text-ellipsis whitespace-nowrap text-[13px]">
            {bodyText}
          </div>
        </div>
      </div>
    </div>
  );
};
