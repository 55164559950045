import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { ChatMessage } from "@web-src/modules/chats/types";
import { ChatMessagesApiFetchParams } from "@jugl-web/rest-api";
import { useMessages } from "../../hooks/useMessages";

export type HeadlessMessagesListHandle = { refetch: () => void };

export type HeadlessMessagesListParams = {
  entityId: string;
  chatId: string;
  fetchParams?: Partial<ChatMessagesApiFetchParams>;
  messageRenderer?: (message: ChatMessage) => JSX.Element;
  children?: (params: {
    messages?: ChatMessage[];
    isLoading?: boolean;
    isError?: boolean;
  }) => JSX.Element;

  emptyContent?: JSX.Element;
  loadingContent?: JSX.Element;
  moreLoadingContent?: JSX.Element;
  errorContent?: (retry: () => void) => JSX.Element;
  moreLoadingErrorContent?: (retry: () => void) => JSX.Element;
};

export const HeadlessMessagesList = forwardRef<
  HeadlessMessagesListHandle,
  HeadlessMessagesListParams
>(
  (
    {
      messageRenderer,
      emptyContent,
      loadingContent,
      moreLoadingContent,
      errorContent,
      moreLoadingErrorContent,
      fetchParams,
      entityId,
      chatId,
      children,
    },
    ref
  ) => {
    const [initialLoadingStarted, setInitialLoadingStarted] =
      useState<boolean>(false);
    const {
      messages: messagesPaginationItems,
      isMessagesLoading: isLoading,
      isMessagesError: isError,
      load: loadMore,
      oldLastLoaded: reachedEnd,
      resetToBottom: refetch,
    } = useMessages({ entityId, chatId, fetchParams });

    const messages = useMemo(
      () => messagesPaginationItems?.map((item) => item.data).reverse() || [],
      [messagesPaginationItems]
    );

    const { ref: inViewRef, inView } = useInView();

    useImperativeHandle(ref, () => ({
      refetch,
    }));

    useEffect(() => {
      if (isLoading && !initialLoadingStarted) {
        setInitialLoadingStarted(true);
      }
    }, [initialLoadingStarted, isLoading]);

    useEffect(() => {
      if (inView) {
        loadMore({ loadDireaction: "old" });
      }
    }, [inView, loadMore]);

    const $infiniteBottomContent = useMemo(() => {
      if (isError) {
        return moreLoadingErrorContent?.(refetch) || null;
      }
      if (isLoading) {
        return moreLoadingContent || null;
      }
      return null;
    }, [
      isError,
      isLoading,
      moreLoadingContent,
      moreLoadingErrorContent,
      refetch,
    ]);

    if (children) {
      return children({ messages, isLoading, isError });
    }

    if (!messages?.length) {
      if (isError) {
        return errorContent?.(refetch) || null;
      }
      if (isLoading || !initialLoadingStarted) {
        return loadingContent || null;
      }
      return emptyContent || null;
    }

    if (!messageRenderer) {
      throw new Error('Either "children" or "messageRenderer" is required');
    }

    const $bottomContent = (
      <>
        {$infiniteBottomContent}
        {!isLoading && !isError && !reachedEnd ? <div ref={inViewRef} /> : null}
      </>
    );

    return (
      <>
        {messages.map((item) => (
          <Fragment key={item.msg_id}>{messageRenderer(item)}</Fragment>
        ))}
        {$bottomContent}
      </>
    );
  }
);
