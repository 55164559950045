import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import {
  joinReactNodes,
  reorder,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import React, { FC, useCallback, useMemo } from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import {
  Button,
  InteractiveContainer,
  Menu,
  PlainButton,
  Tooltip,
} from "@jugl-web/ui-components";
import { DraggableFieldBoxContainer } from "@jugl-web/domain-resources/custom-fields/components/DraggableFieldBoxContainer";
import { FieldBox } from "@jugl-web/domain-resources/custom-fields/components/FieldBox";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { UserCustomField, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import Highlighter from "react-highlight-words";
import { useCustomFieldTypes } from "@jugl-web/domain-resources/custom-fields/hooks/useCustomFieldTypes";
import { ReactComponent as PlusIcon } from "../assets/plus.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";
import { ReactComponent as BookmarkIcon } from "../assets/bookmark.svg";
import { useDefaultUserFields } from "../hooks/useDefaultUserFields";
import { ManageProfileAttributesDialogScreenToParametersMap } from "../types";
import {
  MAX_USER_BOOKMARKS_COUNT,
  MAX_USER_CUSTOM_FIELDS_COUNT,
} from "../consts";

export const EntryScreen: FC<{
  userCustomFields: UserCustomField[];
  bookmarks: UserCustomField[];
  propId: string;
}> = ({ propId, userCustomFields, bookmarks }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, inputProps, reset } = useSearchInput();
  const { defaultUserFields } = useDefaultUserFields();
  const { customFieldTypeToDetails } = useCustomFieldTypes();
  const { transitionTo } =
    useScreenTransitionManager<ManageProfileAttributesDialogScreenToParametersMap>();

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    updateUserCustomFields({
      value: reorder(userCustomFields, source.index, destination.index).map(
        (customField, idx) => ({
          ...customField,
          order: idx + 1,
        })
      ),
      entityId,
      propId,
    });
  };

  const { usersApi } = useRestApiProvider();

  const [updateUserCustomFields] = usersApi.useUpdateUserCustomFieldsMutation();

  const [deleteUserCustomField] = usersApi.useDeleteUserCustomFieldMutation();

  const handleDeleteUserCustomField = useCallback(
    (customFieldId: string) =>
      deleteUserCustomField({
        customFieldId,
        entityId,
        propId,
      }),
    [propId, entityId, deleteUserCustomField]
  );

  const { visibleDefaultUserFields, visibleUserCustomFields } = useMemo(() => {
    if (!searchQuery) {
      return {
        visibleDefaultUserFields: defaultUserFields,
        visibleUserCustomFields: userCustomFields,
      };
    }

    return {
      visibleDefaultUserFields: defaultUserFields.filter((userDefaultField) =>
        userDefaultField.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
      visibleUserCustomFields: userCustomFields.filter((userCustomField) =>
        userCustomField.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    };
  }, [searchQuery, userCustomFields, defaultUserFields]);

  return (
    <BottomCenteredDrawer.Content className="animate-fade-in p-10">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between px-2">
          <SearchInput
            variant="filled"
            color="grey"
            className="w-[280px]"
            {...inputProps}
            onReset={reset}
            onClear={reset}
          />
          <div className="flex items-center gap-4">
            <InteractiveContainer
              className="bg-grey-200 hover:bg-grey-300 flex h-10 items-center justify-center gap-1.5 rounded-[10px] px-[14px] transition-colors"
              onClick={() => transitionTo({ name: "bookmarks" })}
            >
              <BookmarkIcon />
              <span className="text-dark-600 font-secondary text-sm leading-[21px]">
                {t({
                  id: "cpanel-page.bookmarks",
                  defaultMessage: "Bookmarks",
                })}
              </span>
              <div className="bg-dark/5 h-[19px] rounded-2xl px-2">
                <span className="text-dark-700 font-secondary text-xs leading-[160%]">
                  {bookmarks.length}/{MAX_USER_BOOKMARKS_COUNT}
                </span>
              </div>
            </InteractiveContainer>
            {userCustomFields.length >= MAX_USER_CUSTOM_FIELDS_COUNT ? (
              <Tooltip
                renderTrigger={({ props, ref }) => (
                  <div
                    className="bg-dark-100 flex h-10 items-center rounded-lg px-8 transition-colors hover:bg-[#D6DDE1]"
                    ref={ref}
                    {...props}
                  >
                    <span className="text-dark font-secondary leading-2 text-sm">
                      {t(
                        {
                          id: "cpanel-page.max-custom-attrubutes-created",
                          defaultMessage:
                            "⚠️ {maxAttributesCount}/{maxAttributesCount} Fields Created",
                        },
                        { maxAttributesCount: MAX_USER_CUSTOM_FIELDS_COUNT }
                      )}
                    </span>
                  </div>
                )}
              >
                {t(
                  {
                    id: "cpanel-page.max-custom-attrubutes-reached-description",
                    defaultMessage:
                      "Profile already contain {maxAttributesCount} of {maxAttributesCount} Custom attributes created. To add new Fields, please delete existing ones first ✅",
                  },
                  { maxAttributesCount: MAX_USER_CUSTOM_FIELDS_COUNT }
                )}
              </Tooltip>
            ) : (
              <Button
                className="h-10"
                iconStart={<PlusIcon />}
                onClick={() => transitionTo({ name: "newUserCustomField" })}
              >
                {t({
                  id: "common.add",
                  defaultMessage: "Add",
                })}
              </Button>
            )}
          </div>
        </div>
        {!visibleDefaultUserFields.length &&
          !visibleUserCustomFields.length && (
            <span className="font-secondary mt-16 text-center text-base leading-4 text-[#828282]">
              {t({
                id: "cpanel-page.no-results",
                defaultMessage: "No results 😔",
              })}
            </span>
          )}
        <div className="flex flex-col gap-4">
          {visibleDefaultUserFields.map((defaultField) => (
            <FieldBox
              key={defaultField.id}
              className="cursor-default"
              title={
                <div className="flex items-center gap-2">
                  <div className="bg-primary flex h-6 w-6 items-center justify-center rounded-full text-white">
                    {React.createElement(
                      customFieldTypeToDetails[defaultField.type].primaryIcon
                    )}
                  </div>
                  <Highlighter
                    autoEscape
                    highlightClassName="text-primary font-semibold"
                    highlightTag="span"
                    textToHighlight={defaultField.name}
                    searchWords={[searchQuery || ""]}
                  />
                </div>
              }
              subtitle={customFieldTypeToDetails[defaultField.type].fullLabel}
              customEndSlot={
                <span className="text-grey-700 font-secondary text-xs">
                  {t({
                    id: "cpanel-page.default-field",
                    defaultMessage: "Default Field",
                  })}
                </span>
              }
            />
          ))}
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="userCustomFieldList">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="flex flex-col gap-4"
                {...provided.droppableProps}
              >
                {visibleUserCustomFields.map((customField, idx) => {
                  const subtitle =
                    customField.type === "dropdown"
                      ? t(
                          {
                            id: "cpanel-page.values-count",
                            defaultMessage: `{count} {count, plural, one {Value} other {Values}}`,
                          },
                          { count: customField.values.length }
                        )
                      : undefined;

                  return (
                    <DraggableFieldBoxContainer
                      key={customField.id}
                      id={customField.id}
                      index={idx}
                    >
                      <FieldBox
                        title={
                          <div className="flex items-center gap-2">
                            <div
                              className="flex h-6 w-6 items-center justify-center rounded-full text-white"
                              style={{
                                backgroundColor:
                                  customFieldTypeToDetails[customField.type]
                                    .iconBackgroundColor,
                              }}
                            >
                              {React.createElement(
                                customFieldTypeToDetails[customField.type]
                                  .primaryIcon
                              )}
                            </div>
                            <Highlighter
                              autoEscape
                              highlightClassName="text-primary font-semibold"
                              highlightTag="span"
                              textToHighlight={customField.name}
                              searchWords={[searchQuery || ""]}
                            />
                          </div>
                        }
                        subtitle={joinReactNodes(
                          [
                            customFieldTypeToDetails[customField.type]
                              .fullLabel,
                            subtitle,
                          ],
                          <div className="h-4 w-px bg-[#E0E0E0]" />
                        )}
                        customEndSlot={
                          <div className="flex items-center gap-4">
                            {customField.bookmark > 0 && (
                              <Tooltip
                                placement="bottom-end"
                                renderTrigger={({ ref, props }) => (
                                  <PlainButton
                                    ref={ref}
                                    {...props}
                                    className="hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-lg transition-colors"
                                    onClick={() =>
                                      transitionTo({ name: "bookmarks" })
                                    }
                                  >
                                    <BookmarkIcon />
                                  </PlainButton>
                                )}
                              >
                                {t({
                                  id: "cpanel-page.bookmarked-field",
                                  defaultMessage: "Bookmarked Field",
                                })}
                              </Tooltip>
                            )}
                            <Menu
                              placement="bottom-end"
                              sections={[
                                [
                                  {
                                    id: "edit",
                                    label: t({
                                      id: "cpanel-page.edit-field",
                                      defaultMessage: "Edit Field",
                                    }),
                                    onSelect: () =>
                                      transitionTo({
                                        name: "editUserCustomField",
                                        userCustomFieldId: customField.id,
                                      }),
                                  },
                                  {
                                    id: "delete",
                                    label: t({
                                      id: "cpanel-page.delete-field",
                                      defaultMessage: "Delete Field",
                                    }),
                                    onSelect: () =>
                                      handleDeleteUserCustomField(
                                        customField.id
                                      ),
                                  },
                                ],
                              ]}
                              autoClose
                              renderTrigger={({ Trigger, triggerRef }) => (
                                <Trigger ref={triggerRef} as={PlainButton}>
                                  <MenuIcon />
                                </Trigger>
                              )}
                            />
                          </div>
                        }
                      />
                    </DraggableFieldBoxContainer>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </BottomCenteredDrawer.Content>
  );
};
