import { useRestApiProvider } from "@jugl-web/rest-api";
import { PreviewDashboardModel } from "@jugl-web/rest-api/dashboard/models/PreviewDashboard";
import { Alert } from "@jugl-web/ui-components";
import { assert, useToast, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC } from "react";

interface DeleteDashboardConfirmationDialogProps {
  isOpen: boolean;
  dashboard?: PreviewDashboardModel;
  onClose: () => void;
}

export const DeleteDashboardConfirmationDialog: FC<
  DeleteDashboardConfirmationDialogProps
> = ({ isOpen, dashboard, onClose }) => {
  const { entityId } = useEntitySelectedProvider();

  const { dashboardApi } = useRestApiProvider();

  const [deleteDashboard, { isLoading }] =
    dashboardApi.useDeleteDashboardMutation();

  const { toast } = useToast();
  const { t } = useTranslations();

  const handleDelete = async () => {
    assert(!!dashboard);

    const response = await deleteDashboard({
      entityId,
      dashboardId: dashboard.id,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.dashboard-deleted",
          defaultMessage: "Dashboard has been deleted",
        })
      );
      onClose();
    }
  };

  return (
    <Alert
      isOpen={isOpen}
      title={t({
        id: "dashboard-page.delete-dashboard",
        defaultMessage: "Delete Dashboard",
      })}
      content={t(
        {
          id: "dashboard-page.delete-dashboard-confirmation",
          defaultMessage: "Are you sure you want to delete dashboard {name}?",
        },
        {
          name: (
            <span className="text-primary-800 font-semibold">
              {dashboard?.name}
            </span>
          ),
        }
      )}
      buttons={[
        {
          text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
          role: "close",
        },
        {
          text: t({ id: "common.delete", defaultMessage: "Delete" }),
          color: "tertiary",
          isDisabled: isLoading,
          onClick: handleDelete,
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
