import React, { useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import { PageRoute } from "@web-src/features/app/types";
import { useRestApiProvider, UserRole } from "@jugl-web/rest-api";
import { EntityGateway } from "@web-src/modules/entities/components/EntityGateway";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FullPageError } from "@web-src/modules/common/components/FullPageError";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { useLinkToOpenAfterLogin } from "@web-src/modules/auth/hooks/useLinkToOpenAfterLogin";
import { PageLoaderFull } from "@jugl-web/ui-components";
import { useMe } from "../../hooks/useMe";
import { useNavigateToRoleBasedInitialPage } from "../../hooks/useNavigateToRoleBasedInitialPage";

const PrivateRoute: React.FC<{
  component: React.ReactNode;
  profileRequired?: boolean;
  entityRequired?: boolean;
  activeSubscriptionRequired?: boolean;
  requiredUserRoles?: UserRole[];
  requiredSubscriptionModule?: SubscriptionPlanModuleId;
}> = ({
  component,
  profileRequired = true,
  entityRequired = true,
  activeSubscriptionRequired = true,
  requiredUserRoles,
  requiredSubscriptionModule,
}) => {
  const { isAuthenticated } = useSelector(selectAuthState);
  const location = useLocation();
  const [linkToOpenAfterLogin, setLinkToOpenAfterLogin] =
    useLinkToOpenAfterLogin();
  const {
    isInitialized: isEntityInitialized,
    entity,
    isEntitiesFetching,
    reinitialize,
    isInitializationError,
    subscriptionInfo,
    isModuleAvailable,
    moduleRequiredAction,
  } = useEntityProvider();
  const { usersApi } = useRestApiProvider();
  const { me, isLoading: isMeLoading } = useMe();
  const {
    data: profileData,
    error: profileError,
    isLoading: isProfileLoading,
    isError: isProfileLoadingError,
    refetch: refetchProfileData,
  } = usersApi.useGetUserProfileQuery(
    {},
    {
      skip: !isAuthenticated,
    }
  );
  const hasProfile = !!profileData;

  const handleRetryOnError = useCallback(() => {
    if (isInitializationError) {
      reinitialize();
    }
    if (isProfileLoadingError) {
      refetchProfileData();
    }
  }, [
    isInitializationError,
    isProfileLoadingError,
    refetchProfileData,
    reinitialize,
  ]);

  const navigateToRoleBasedInitialPage = useNavigateToRoleBasedInitialPage();

  if (
    (profileRequired &&
      isProfileLoadingError &&
      (profileError as unknown as { status?: number }).status !== 404) ||
    isInitializationError
  ) {
    return <FullPageError onRetry={handleRetryOnError} />;
  }

  if (isAuthenticated === false) {
    const requestedPath = `${location.pathname}${location.search}`;
    if (linkToOpenAfterLogin !== requestedPath) {
      setLinkToOpenAfterLogin(`${location.pathname}${location.search}`);
    }
    return (
      <Navigate
        to={{
          pathname: `/${PageRoute.login}`,
        }}
      />
    );
  }

  if (
    !isAuthenticated ||
    (isProfileLoading && !hasProfile) ||
    (!me && isMeLoading) ||
    (entityRequired && !isEntityInitialized) ||
    (entityRequired && !entity && isEntitiesFetching)
  ) {
    return <PageLoaderFull />;
  }

  if (!hasProfile && profileRequired) {
    return (
      <Navigate
        to={{
          pathname: `/${PageRoute.profileForm}`,
        }}
      />
    );
  }

  if (hasProfile && !profileRequired) {
    return (
      <Navigate
        to={{
          pathname: `/${PageRoute.orgSelect}`,
        }}
      />
    );
  }

  if (entityRequired) {
    if (!entity) {
      return (
        <Navigate
          to={{
            pathname: `/${PageRoute.orgSelect}`,
          }}
        />
      );
    }
    const hasActiveSubscription =
      !!subscriptionInfo && subscriptionInfo.planIsActive;
    if (activeSubscriptionRequired && !hasActiveSubscription) {
      return (
        <Navigate
          to={{ pathname: `/${entity.id}/${PageRoute.noSubscription}` }}
        />
      );
    }
    if (
      requiredSubscriptionModule &&
      !isModuleAvailable(requiredSubscriptionModule)
    ) {
      moduleRequiredAction(requiredSubscriptionModule);
      navigateToRoleBasedInitialPage(entity);
      return null;
    }
    if (requiredUserRoles && !requiredUserRoles.includes(entity.role)) {
      navigateToRoleBasedInitialPage(entity);
      return null;
    }
    return <EntityGateway>{component}</EntityGateway>;
  }
  return <>{component}</>;
};

export default PrivateRoute;
