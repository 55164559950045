import { SideImageLayout } from "@jugl-web/ui-components/web/layouts";
import { FC, useCallback, useState } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { DiscardChangesDialog } from "@web-src/modules/common/components";
import {
  isErrorCodeOnlyAPIError,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { Alert, PageLoaderFull } from "@jugl-web/ui-components/cross-platform";
import Lottie from "react-lottie";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { EntityForm, EntityFormProps } from "../../components/EntityForm";
import workspaceLimitationAnimation from "./assets/workspace-limitation.json";

export const EntityCreatePage: FC = () => {
  const [discardChangesDialogIsOpen, setDiscardChangesDialogIsOpen] =
    useState(false);
  const [
    workspaceLimitReachedAlertIsOpen,
    setWorkspaceLimitReachedAlertIsOpen,
  ] = useState(false);
  const { entitiesApi } = useRestApiProvider();
  const [createOrganization, { isLoading: isCreateLoading }] =
    entitiesApi.useCreateEntityMutation();
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();

  const { toast } = useToast({ variant: "mobile" });

  const handleSubmit: EntityFormProps["onSubmit"] = useCallback(
    async (values) => {
      const response = await createOrganization({
        data: {
          country: values.country?.iso,
          name: values.name,
          billing_email: values.email,
          currency: "USD",
        },
        displayImg: values.imageFile,
      });
      if ("error" in response && isErrorCodeOnlyAPIError(response.error)) {
        if (response.error.data.errors === "max_limit_reached") {
          setWorkspaceLimitReachedAlertIsOpen(true);
        } else {
          toast(
            t({
              id: "feedback.failed-to-create-workspace",
              defaultMessage: "Failed to create a workspace",
            })
          );
        }
        return;
      }
      if ("data" in response) {
        navigateToPage("chatsChats", undefined, { entityId: response.data.id });
        toast(
          t({
            id: "feedback.workspace-created-successfully",
            defaultMessage: "Workspace created successfully",
          }),
          { autoHideDuration: 3000 }
        );
      }
    },
    [createOrganization, navigateToPage, t, toast]
  );

  return (
    <>
      <SideImageLayout onBackClick={() => setDiscardChangesDialogIsOpen(true)}>
        <div className="font-secondary mb-4 px-6 text-center text-4xl font-semibold leading-[48px]">
          New Workspace
        </div>
        <div className="text-dark-600 mb-16 px-6 text-center">
          To create new Workspace, please provide the following data 🙌
        </div>
        <EntityForm onSubmit={handleSubmit} canDeleteImage />
      </SideImageLayout>
      <Alert
        isOpen={workspaceLimitReachedAlertIsOpen}
        onRequestClose={() => setWorkspaceLimitReachedAlertIsOpen(false)}
        img={
          <Lottie
            options={{ animationData: workspaceLimitationAnimation }}
            height={150}
            width={150}
          />
        }
        title="You reached Workspace Limit"
        content="Oh no! Seems like You already created 5 of 5 free Personal Workspaces. You can update your plan to go unlimited ✨"
        buttons={[
          {
            text: "Cancel",
            color: "grey",
            role: "close",
          },
          {
            text: "Get More",
            onClick: () => {
              window.open("https://help.jugl.com/en/", "_blank");
              setWorkspaceLimitReachedAlertIsOpen(false);
            },
          },
        ]}
      />
      <DiscardChangesDialog
        isOpen={discardChangesDialogIsOpen}
        content={t({
          id: "entity-create-page.discard-workspace-will-not-be-created",
          defaultMessage: "If you discard, workspace will not be created",
        })}
        onClose={() => setDiscardChangesDialogIsOpen(false)}
        onSubmit={() => navigateToPage("entitySelect", undefined)}
      />
      <PageLoaderFull isActive={isCreateLoading} isTransparent />
    </>
  );
};
