import { useMarkModuleAsRead } from "@jugl-web/domain-resources/entities/hooks/useMarkModuleAsRead";
import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { useRestApiProvider, UserRole } from "@jugl-web/rest-api";
import {
  Button,
  MultiSectionLayout,
  TableGrid,
  Avatar,
  InteractiveContainer,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { useSearchInput, useTranslations, cx } from "@jugl-web/utils";
import { NotificationsButton } from "@web-src/components/NotificationsButton";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HeadlessUserItem,
  useHeadlessUsersList,
} from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { Highlightify } from "@jugl-web/utils/utils/Highlightify";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { useUserRoleName } from "@jugl-web/domain-resources/users/hooks/useUserRoleName";
import { UserCustomFieldInput } from "@web-src/modules/workspace/pages/CPanelPage/components/CPanelTable/components/UserCustomFieldInput";
import { usePrevious } from "@web-src/utils/helper";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { ReactComponent as AddIcon } from "./assets/add-icon.svg";
import { ReactComponent as GroupIcon } from "./assets/group-icon.svg";
import { ReactComponent as ManagersIcon } from "./assets/managers-icon.svg";
import { ReactComponent as ProfileAttributeIcon } from "./assets/profile-attribute.svg";
import { PeopleNotificationsDialog } from "./components/PeopleNotificationsDialog";
import { UserManagersPopover } from "../../components/PeopleUserItem/components/UserManagersPopover";
import { ProfileInfoTableCell } from "./components/ProfileInfoTableCell";
import { PeopleInfoSidebar } from "../../components/PeopleInfoSidebar";

export const PeoplePage: FC = () => {
  const { usersApi } = useRestApiProvider();
  const { t } = useTranslations();
  const { me } = useMe();
  const { entityId, entity } = useEntitySelectedProvider();
  const { navigateToPage } = useNavigation();
  const { searchQuery, reset, inputProps } = useSearchInput();
  const { getRoleName } = useUserRoleName();
  const { data: userCustomFields } = usersApi.useGetUserCustomFieldsQuery({
    entityId,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    users,
    loadMore: loadMoreUsers,
    isLoading: isLoadingMoreUsers,
  } = useHeadlessUsersList({
    entityId,
    searchQuery,
    sortBy: "name",
  });

  const prevIsLoadingMoreUsers = usePrevious(isLoadingMoreUsers);
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);
  const [profileIdToOpen, setProfileIdToOpen] = useState<string | null>(null);
  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({
    entityId: entity?.id,
    module: "people",
  });

  useMarkModuleAsRead({ entityId: entity.id, module: "people" });
  const customFieldsColumns = useMemo(() => {
    const customFields = userCustomFields?.value;
    if (!customFields || customFields?.length === 0) return undefined;
    return customFields
      .filter((field) => field.bookmark)
      .sort((a, b) => a.bookmark - b.bookmark)
      .map((field) => ({
        title: (
          <div className="flex items-center gap-1.5">
            <ProfileAttributeIcon />
            {field.name}
          </div>
        ),
        grow: true,
        headerClassName: "border-r-[2px] border-t-[1px]",
        className: "border-r-[2px] truncate min-w-[250px] border-l-0",
        content: ({ id }: HeadlessUserItem) => (
          <UserGeneralProfile entityId={entityId} userId={id}>
            {(profile) => {
              const fieldValue = profile.profile?._user.work_info.find(
                (el) => el.key === field.id
              );
              return (
                <div
                  className="w-11/12 cursor-pointer truncate"
                  title={fieldValue?.value || ""}
                  onClick={() => setProfileIdToOpen(id)}
                >
                  {profile.profile?._user && (
                    <UserCustomFieldInput
                      field={field}
                      user={profile.profile?._user}
                      searchQuery={searchQuery}
                      isReadonly
                    />
                  )}
                </div>
              );
            }}
          </UserGeneralProfile>
        ),
      }));
  }, [entityId, searchQuery, userCustomFields?.value]);

  useEffect(() => {
    if (prevIsLoadingMoreUsers && !isLoadingMoreUsers && !isInitialized) {
      setIsInitialized(true);
    }
  }, [
    isInitialized,
    isLoadingMoreUsers,
    loadMoreUsers,
    prevIsLoadingMoreUsers,
  ]);

  return (
    <>
      <MultiSectionLayout
        header={
          <>
            <HeaderBreadcrumbs
              items={[{ title: "People" }]}
              rightContent={
                <NotificationsButton
                  hasUnreadIndicator={hasUnreadNotifications}
                  onClick={() => {
                    setIsNotificationsDialogOpen(true);
                    markNotificationsAsRead();
                  }}
                />
              }
              disableShadow
            />
            <div className="flex items-center justify-between bg-[#EEF2F5] py-4 px-8">
              <div className="max-w-[280px]">
                <SearchInput
                  variant="filled"
                  color="white"
                  onReset={reset}
                  {...inputProps}
                  onClear={reset}
                />
              </div>
              <div className="flex items-center gap-6">
                <Link to="teams" relative="path" className="no-underline">
                  <ControlBarButton
                    label="Teams"
                    onClick={() => navigateToPage("peopleTeams")}
                    startSlot={<GroupIcon />}
                  />
                </Link>
                {entity.role === UserRole.admin && (
                  <>
                    <Button
                      onClick={() => navigateToPage("workspaceMembers")}
                      iconEnd={<AddIcon />}
                      className="h-10"
                    >
                      {t({
                        id: "people-page.manage-members",
                        defaultMessage: "Manage members",
                      })}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        }
      >
        <div className="h-full py-8 pr-8">
          <TableGrid
            unstyled
            data={users}
            className="min-w-fit rounded-t-xl border-[1px] border-b-0 border-l-0 border-t-0 border-solid border-[#EEF2F5]"
            headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-l-0 border-r border-b border-solid border-[#EEF2F5] flex items-center font-secondary"
            cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-4 border-[1px] border-t border-r border-solid border-[#EEF2F5] leading-[140%] tracking-[0.14px] hover:!bg-grey-200 font-secondary"
            rowHoverClassName="!bg-grey-100"
            activeRowColorInHex="#F9F9F9"
            onReachEnd={loadMoreUsers}
            columns={[
              {
                title: t({
                  id: "people-page.user-name",
                  defaultMessage: "User Name",
                }),
                grow: false,
                width: 32,
                headerClassName:
                  "sticky left-[0px] z-10 border-t-[1px] border-x-0 border-white border-solid bg-white",
                className:
                  "truncate border-l sticky left-[0px] z-10 bg-white border-0 !bg-white",

                content: () => <div className="w-full" />,
              },
              {
                title: t({
                  id: "people-page.user-name",
                  defaultMessage: "User Name",
                }),
                grow: false,
                width: 394,
                headerClassName:
                  "sticky left-[32px] z-10 bg-white rounded-tl-xl border-l border-r-[2px] border-t-[1px]",
                className:
                  "truncate border-l sticky left-[32px] z-10 bg-white border-r-[2px]",

                content: ({ id }) => (
                  <div
                    className="flex h-full w-full cursor-pointer items-center gap-3 truncate py-3.5"
                    onClick={() => setProfileIdToOpen(id)}
                  >
                    <UserGeneralProfile entityId={entityId} userId={id}>
                      {(profile) => (
                        <>
                          <Avatar
                            imageUrl={profile.safeProfile.avatar}
                            username={profile.safeProfile.displayName}
                            size="xl"
                          />
                          <div
                            className="font-secondary text-dark-800 w-3/4 truncate pt-1 text-base font-medium"
                            title={id}
                          >
                            <Highlightify
                              searchWord={searchQuery}
                              highlightClassName="text-primary"
                            >
                              {profile.safeProfile.displayName}
                              {me?.id === id ? ` (You)` : ""}
                            </Highlightify>
                            <div
                              className="truncate text-sm font-normal leading-3 text-[#828282]"
                              title={profile.profile?.department?.name}
                            >
                              {profile.profile?.department?.name ||
                                t({
                                  id: "people-page.no-department",
                                  defaultMessage: "No Department",
                                })}
                            </div>
                          </div>
                        </>
                      )}
                    </UserGeneralProfile>
                  </div>
                ),
              },
              {
                title: t({
                  id: "people-page.user-role",
                  defaultMessage: "User Role",
                }),
                grow: false,
                isHidden: entity.role === UserRole.member,
                width: 250,
                headerClassName: "border-r-[2px] border-t-[1px]",
                className: "truncate border-l-0 border-r-[2px]",
                content: ({ id }) => (
                  <UserGeneralProfile entityId={entityId} userId={id}>
                    {(profile) => (
                      <div
                        className="w-11/12 truncate"
                        title={getRoleName(profile?.profile?.role)}
                      >
                        <div
                          className={cx(
                            "bg-grey-100 font-secondary text-dark-700 w-fit rounded-[4px] px-2.5 py-1.5 text-sm",
                            {
                              "bg-primary-50":
                                profile?.profile?.role === UserRole.admin,
                            }
                          )}
                        >
                          {getRoleName(profile?.profile?.role)}
                        </div>
                      </div>
                    )}
                  </UserGeneralProfile>
                ),
              },
              {
                title: t({
                  id: "people-page.direct-managers",
                  defaultMessage: "Direct Managers",
                }),
                grow: false,
                headerClassName: "border-r-[2px] border-t-[1px]",
                className:
                  "truncate p-0 min-w-[250px] border-r-[2px] border-l-0",
                content: ({ id }) => (
                  <UserGeneralProfile entityId={entityId} userId={id}>
                    {(profile) => {
                      const managers = profile?.profile?.managers || [];
                      return (
                        <UserManagersPopover
                          userId={id}
                          onSelect={(managerId) =>
                            setProfileIdToOpen(managerId)
                          }
                          renderTrigger={({ Trigger, triggerRef }) => (
                            <Trigger
                              ref={triggerRef}
                              as={InteractiveContainer}
                              className={cx(
                                "flex h-full w-full items-center justify-start gap-1.5 rounded-lg p-4 text-sm text-[#4F4F4F]",
                                {
                                  "text-dark-600": managers.length === 0,
                                }
                              )}
                            >
                              <ManagersIcon />
                              <div className="pt-[1px]">
                                {t(
                                  {
                                    id: "people-page.managers",
                                    defaultMessage:
                                      "{count} {count, plural, one {Manager} other {Managers}}",
                                  },
                                  {
                                    count: managers.length || 0,
                                  }
                                )}
                              </div>
                            </Trigger>
                          )}
                        />
                      );
                    }}
                  </UserGeneralProfile>
                ),
              },
              ...(customFieldsColumns ?? []),
              {
                title: t({
                  id: "common.education",
                  defaultMessage: "Education",
                }),
                grow: false,
                width: 350,
                headerClassName: "border-r-[2px] border-t-[1px]",
                className: "truncate border-l-0 border-r-[2px] py-3.5",
                // All profile info columns are hidden until we got profile info data in users response
                isHidden: true,
                content: ({ id }) => (
                  <ProfileInfoTableCell
                    type="educations"
                    userId={id}
                    onClick={() => setProfileIdToOpen(id)}
                  />
                ),
              },
              {
                title: t({
                  id: "people-page.current-city",
                  defaultMessage: "Current City",
                }),
                grow: false,
                width: 250,
                headerClassName: "border-r-[2px] border-t-[1px]",
                className: "truncate border-l-0 border-r-[2px] py-3.5",
                isHidden: true,
                content: ({ id }) => (
                  <ProfileInfoTableCell
                    type="location"
                    userId={id}
                    onClick={() => setProfileIdToOpen(id)}
                  />
                ),
              },
              {
                title: t({
                  id: "people-page.native-birth-place",
                  defaultMessage: "Native/Birth Place",
                }),
                grow: false,
                width: 250,
                headerClassName: "border-r-[2px] border-t-[1px]",
                className: "truncate border-l-0 border-r-[2px] py-3.5",
                isHidden: true,
                content: ({ id }) => (
                  <ProfileInfoTableCell
                    type="native"
                    userId={id}
                    onClick={() => setProfileIdToOpen(id)}
                  />
                ),
              },
              {
                title: t({
                  id: "common.achievements",
                  defaultMessage: "Achievements",
                }),
                grow: false,
                width: 350,
                headerClassName: "border-r-[2px] border-t-[1px]",
                className: "truncate border-l-0 border-r-[2px] py-0",
                isHidden: true,
                content: ({ id }) => (
                  <ProfileInfoTableCell
                    type="achievements"
                    userId={id}
                    onClick={() => setProfileIdToOpen(id)}
                  />
                ),
              },
              {
                title: t({
                  id: "common.experience",
                  defaultMessage: "Experience",
                }),
                grow: false,
                width: 350,
                headerClassName: "border-r-0 border-t-[1px]",
                className: "truncate border-l-0 border-r-0 py-3.5",
                isHidden: true,
                content: ({ id }) => (
                  <ProfileInfoTableCell
                    type="careers"
                    userId={id}
                    onClick={() => setProfileIdToOpen(id)}
                  />
                ),
              },
            ]}
          />
          {isLoadingMoreUsers && <LoadingSpinner className="pt-10" />}
          {!isLoadingMoreUsers && isInitialized && users.length === 0 && (
            <div className="text-dark-700 font-secondary absolute flex w-screen justify-center pt-[80px]">
              {t({
                id: "people-page.no-results",
                defaultMessage: "No results 😔",
              })}
            </div>
          )}
        </div>
      </MultiSectionLayout>
      <PeopleNotificationsDialog
        isOpen={isNotificationsDialogOpen}
        entityId={entity.id}
        onClose={() => setIsNotificationsDialogOpen(false)}
      />

      <PeopleInfoSidebar
        isOpen={!!profileIdToOpen}
        userId={profileIdToOpen || undefined}
        onRequestClose={() => setProfileIdToOpen(null)}
      />
    </>
  );
};
