import { downloadBlobAsFile } from "@jugl-web/domain-resources/drive";
import { useDownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ChatMessagePayloadAttachment } from "@jugl-web/rest-api";
import { displayIcon } from "@web-src/utils/displayIcon";
import format from "date-fns/format";
import mime from "mime";
import React, { useMemo } from "react";

export const FileAttachmentItem: React.FC<{
  attachment: ChatMessagePayloadAttachment;
  date: string;
}> = ({ attachment, date }) => {
  const { entity } = useEntity();
  const { dateLocale } = useLanguage();
  if (!entity?.id) {
    throw new Error("Selected entity is required");
  }

  const { files, download, open } = useDownloadManagerProvider();
  const fileExtension = useMemo(
    () => mime.getExtension(attachment.mimetype),
    [attachment.mimetype]
  );

  const handleDownloadClick = async () => {
    if (!entity) {
      return;
    }
    if (files[attachment.uid]) {
      open(attachment.uid);
      return;
    }
    const file = await download(attachment.uid, {
      entityId: entity.id,
      fileSize: attachment.size,
      mimeType: attachment.mimetype,
      module: "attachments",
      fileName: attachment.name || "jugl-file",
      // TODO: hack
      path: attachment.url?.includes("amazonaws.com")
        ? attachment.uid
        : `${entity.id}/${attachment.uid}`,
    });
    downloadBlobAsFile(file, attachment.name || "jugl-file");
  };

  return (
    <InteractiveContainer
      className="bg-grey-200 flex min-w-0 items-center justify-between gap-4 rounded-xl px-6 py-4"
      onClick={handleDownloadClick}
    >
      <div className="flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden">
        <img
          src={displayIcon(
            fileExtension ? `.${fileExtension.toLowerCase()}` : ""
          )}
          alt=""
          className="h-full w-full"
        />
      </div>
      <div className="flex w-full grow items-center justify-between gap-1 overflow-hidden">
        <div className="flex flex-col gap-1 overflow-hidden">
          <span className="text-dark truncate text-[15px] font-medium">
            {attachment.name}
          </span>
          <span className="text-grey shrink-0 truncate text-[13px] font-medium">
            {format(new Date(date), "MMM dd, hh:mm a", { locale: dateLocale })}
          </span>
        </div>
        <span className="text-grey whitespace-nowrap text-[13px] font-medium">
          {getFileSizeLabel(attachment.size)}
        </span>
      </div>
    </InteractiveContainer>
  );
};
