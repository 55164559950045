import { useDroppable } from "@dnd-kit/core";
import { TaskColumnDroppableData } from "@jugl-web/domain-resources/tasks";
import { useTaskCardAdapter } from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { TaskCard } from "@jugl-web/domain-resources/tasks/components/TaskCard/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskViewColumn } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { Tooltip } from "@jugl-web/ui-components";
import { Avatar, BoardAvatar } from "@jugl-web/ui-components/cross-platform";
import { assert, cx, useTranslations } from "@jugl-web/utils";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { TasksColumnLayout } from "../TasksColumnLayout";
import { NewTaskPopover } from "../TasksControlBar/components/NewTaskPopover";
import { useTasksPageContext } from "../TasksPageContext";
import { ReactComponent as EmptyListIcon } from "./assets/empty-list.svg";
import { ReactComponent as HintIcon } from "./assets/hint.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

export interface TaskKanbanColumnProps {
  column: TaskViewColumn;
  onTaskClick: (task: PreviewTask) => void;
  onTaskPreview: (task: PreviewTask) => void;
}

export const TaskKanbanColumn: FC<TaskKanbanColumnProps> = ({
  column,
  onTaskClick,
  onTaskPreview,
}) => {
  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);

  const { tasksSource } = useTasksPageContext();
  const { searchQuery } = useTaskFiltering();

  const { hasEmployeeLikeRole } = useUserRole();

  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    source: tasksSource,
  });

  const { isOver, setNodeRef, active } = useDroppable({
    id: column.id,
    disabled: !column.droppableData,
    data: column.droppableData satisfies TaskColumnDroppableData | undefined,
  });

  const { t } = useTranslations();

  const isEmpty = column.items.length === 0;
  const canCreateTask = !!column.createTaskOptions && !hasEmployeeLikeRole;

  return (
    <TasksColumnLayout
      ref={setNodeRef}
      className={cx(
        isOver && "bg-blue-100",
        column.isOverdue ? "bg-red-100" : undefined
      )}
    >
      <NewTaskPopover
        entityId={entityId}
        initialState={column.createTaskOptions?.initialState}
        placement="bottom"
        isDisabled={!canCreateTask}
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger
            as={TasksColumnLayout.Header}
            ref={triggerRef}
            isDisabled={!canCreateTask}
          >
            <div className="flex items-center justify-between">
              <div className="font-secondary flex items-center gap-2.5 overflow-hidden">
                {column.title.type === "user" && (
                  <UserGeneralProfile
                    entityId={entityId}
                    userId={column.title.userId}
                  >
                    {({ safeProfile, profile }) => {
                      assert(
                        column.title.type === "user",
                        'Column title has to be of type "user"'
                      );

                      const isMe = meId === column.title.userId;
                      const isProfileLoaded = !!profile;

                      return (
                        <>
                          <Avatar
                            imageUrl={safeProfile.avatar}
                            username={safeProfile.displayName}
                            size="sm"
                          />
                          <span
                            className="truncate"
                            title={safeProfile.displayName}
                          >
                            {safeProfile.displayName}{" "}
                            {isMe &&
                              isProfileLoaded &&
                              `(${t({
                                id: "common.you",
                                defaultMessage: "You",
                              })})`}
                          </span>
                        </>
                      );
                    }}
                  </UserGeneralProfile>
                )}
                {column.title.type === "board" && (
                  <div className="flex items-center gap-2.5 overflow-hidden">
                    <BoardAvatar
                      size="md"
                      name={column.title.name}
                      color={column.title.color}
                    />
                    <span className="truncate" title={column.title.name}>
                      {column.title.name}
                    </span>
                  </div>
                )}
                {column.title.type === "customer" && (
                  <div className="flex items-center gap-2.5 overflow-hidden">
                    <Avatar
                      imageUrl={column.title.avatar}
                      username={column.title.name}
                      size="sm"
                    />
                    <div className="flex flex-col overflow-hidden">
                      <span
                        className="truncate font-medium"
                        title={column.title.name}
                      >
                        {column.title.name}
                      </span>
                      <span
                        className="font-primary truncate text-xs font-normal text-[#828282]"
                        title={column.title.company}
                      >
                        {column.title.company ||
                          t({
                            id: "tasks-page.no-company-info",
                            defaultMessage: "No company info",
                          })}
                      </span>
                    </div>
                  </div>
                )}
                {column.title.type === "text" && (
                  <span className="truncate" title={column.title.value}>
                    {column.title.value}
                  </span>
                )}
                <div className="text-grey-800 flex h-6 items-center justify-center rounded-xl bg-white/70 px-2 text-sm">
                  {column.items.length}
                </div>
              </div>
              {column.hint && (
                <Tooltip
                  delay="none"
                  placement="bottom"
                  renderTrigger={({ props, ref }) => (
                    <HintIcon ref={ref} className="shrink-0" {...props} />
                  )}
                >
                  {column.hint}
                </Tooltip>
              )}
              {canCreateTask && <PlusIcon className="shrink-0" />}
            </div>
          </Trigger>
        )}
      />
      {isEmpty ? (
        <TasksColumnLayout.EmptyMessage>
          <div className="flex items-center gap-2">
            <EmptyListIcon />{" "}
            {t({
              id: "tasks-page.no-tasks-added",
              defaultMessage: "No task added",
            })}
          </div>
        </TasksColumnLayout.EmptyMessage>
      ) : (
        <TasksColumnLayout.List isDragging={!!active}>
          {column.items.map((task) => (
            <TaskCard
              key={task.id}
              className="shrink-0"
              highlightedText={searchQuery}
              onClick={() => onTaskClick(task)}
              onTaskPreview={() => onTaskPreview(task)}
              columnId={column.id}
              draggable
              {...getTaskCardProps(task)}
            />
          ))}
        </TasksColumnLayout.List>
      )}
    </TasksColumnLayout>
  );
};
