import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { PlainButton } from "@jugl-web/ui-components";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { cx, useTranslations } from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import useDebounce from "@web-src/hooks/useDebounce";
import { FC, useEffect, useState } from "react";
import { Tab } from "@jugl-web/ui-components/web/TabsLayout/components/Tab";
import { TaskArchiveFiltersDialog } from "../TaskArchiveFiltersDialog";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as ResetFiltersIcon } from "./assets/reset-filters.svg";

interface TasksArchiveControlBarProps {
  filters: InternalTaskFilters;
  activeFiltersCount: number;
  searchQuery: string;
  onChangeFilters: (state: InternalTaskFilters) => void;
  onResetFilters: () => void;
  onChangeSearchQuery: (query: string) => void;
  onUpdate: (resetSearchQuery?: boolean, resetFilterState?: boolean) => void;
}

export const TasksArchiveControlBar: FC<TasksArchiveControlBarProps> = ({
  filters,
  activeFiltersCount,
  searchQuery,
  onChangeFilters,
  onResetFilters,
  onChangeSearchQuery,
  onUpdate,
}) => {
  const { t } = useTranslations();
  const [isTasksFiltersDialogOpen, setIsTasksFiltersDialogOpen] =
    useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    const shouldReset = !debouncedSearchQuery;
    onUpdate(shouldReset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  return (
    <TasksPageLayout.ControlBar className="border-grey-300 border-[1px] border-x-0 border-t-0 border-solid">
      <div className="flex h-full w-full justify-between gap-1">
        <span className="flex flex-row items-end gap-8">
          <Tab
            title={t({
              id: "tasks-page.archived",
              defaultMessage: "Archived",
            })}
            isSelected
            className="font-secondary"
          />
          <ControlBarButton
            isActive={activeFiltersCount > 0}
            onClick={() => setIsTasksFiltersDialogOpen(true)}
            className="mt-1 self-center"
            startSlot={
              <span className="relative flex items-center justify-center gap-2">
                <FilterIcon className="fill-grey" />
                {(activeFiltersCount && (
                  <div
                    className={cx(
                      "bg-gradients-success absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-2 border-solid border-white"
                    )}
                  />
                )) ||
                  ""}
              </span>
            }
            endSlot={
              activeFiltersCount ? (
                <PlainButton
                  className="hover:bg-grey-300 rounded-full bg-[#EEF2F5] transition-colors"
                  onClick={(event) => {
                    event.stopPropagation();
                    onResetFilters();
                    onUpdate(false, true);
                  }}
                >
                  <ResetFiltersIcon />
                </PlainButton>
              ) : undefined
            }
          />
        </span>
        <div className="mt-1 flex h-full items-center">
          <ExpandableSearchBar
            color="white"
            size="lg"
            isAlwaysExpanded
            onSearch={onChangeSearchQuery}
            defaultValue={searchQuery}
            onReset={() => onUpdate(true)}
          />
        </div>
      </div>
      <TaskArchiveFiltersDialog
        isOpen={isTasksFiltersDialogOpen}
        filters={filters}
        onChangeFilters={onChangeFilters}
        onClose={() => {
          setIsTasksFiltersDialogOpen(false);
          onUpdate();
        }}
      />
    </TasksPageLayout.ControlBar>
  );
};
