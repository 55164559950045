import {
  AvatarSelect,
  AvatarSelectSize,
  InteractiveContainer,
  PlainButton,
  SidebarDrawer,
  TextInput,
} from "@jugl-web/ui-components";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { useTranslations } from "@jugl-web/utils";
import React, { useEffect, useMemo, useState } from "react";
import { ChatItem } from "@web-src/modules/chats/types";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as CancelIcon } from "./assets/cancel.svg";
import { ConfirmationSettingsChangeAlert } from "./components/ConfirmationSettingsChangeAlert";
import { ChatAdminsDrawer } from "../ChatAdminsDrawer";

export const ChatSettingsDrawer: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  chat: ChatItem;
}> = ({ isOpen, chat, onRequestClose }) => {
  const { t } = useTranslations();
  const { updateGroupConversation } = useConversations();
  const [name, setName] = useState("");
  const [isChatAdminsDrawerOpen, setIsChatAdminsDrawerOpen] = useState(false);
  const confirmationSettingsChangeAlertState = useConfirmationDialogState<{
    type:
      | "admins-channel-enable"
      | "admins-channel-disable"
      | "content-protection-enable"
      | "content-protection-disable";
  }>();
  const { workspacesApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();

  const { data } = workspacesApi.useGetWorkspaceParticipantsQuery(
    chat.id
      ? {
          entityId,
          workspaceId: chat.id,
          params: {
            page: 1,
            page_size: 0,
            role: "admin",
          },
        }
      : skipToken
  );

  useEffect(() => {
    if (!isOpen) {
      setIsChatAdminsDrawerOpen(false);
      setName(chat.title || "");
    }
  }, [isOpen, chat]);

  const sections = useMemo(
    () =>
      chat.config
        ? [
            {
              id: "content-protection",
              title: t({
                id: "chats-page.content-protection",
                defaultMessage: "Content protection",
              }),
              description: t({
                id: "chats-page.content-protection-description",
                defaultMessage:
                  "Members will not be able to save, forward it copy content from this Group",
              }),
              rightContent: (
                <Switch isChecked={chat.config.content_protected} />
              ),
              onClick: () =>
                confirmationSettingsChangeAlertState.open({
                  onConfirm: () => {
                    updateGroupConversation({
                      id: chat.id || "",
                      content_protected: !chat.config?.content_protected,
                    });
                  },
                  metadata: {
                    type: chat.config?.content_protected
                      ? "content-protection-disable"
                      : "content-protection-enable",
                  },
                }),
            },
            {
              id: "admins-channel",
              title: t({
                id: "chats-page.broadcast-chat",
                defaultMessage: "Broadcast Chat",
              }),
              description: t({
                id: "chats-page.admins-channel-description",
                defaultMessage:
                  "Admins-to-many channel, to share messages as broadcast chat",
              }),
              rightContent: <Switch isChecked={chat.config.admin_only_msg} />,
              onClick: () =>
                confirmationSettingsChangeAlertState.open({
                  onConfirm: () => {
                    updateGroupConversation({
                      id: chat.id || "",
                      admin_only_msg: !chat.config?.admin_only_msg,
                    });
                  },
                  metadata: {
                    type: chat.config?.admin_only_msg
                      ? "admins-channel-disable"
                      : "admins-channel-enable",
                  },
                }),
            },
            ...(data
              ? [
                  {
                    id: "chat-admins",
                    title: t({
                      id: "chats-page.group-admins",
                      defaultMessage: "Group Admins",
                    }),
                    description: t(
                      {
                        id: "chats-page.users-count",
                        defaultMessage:
                          "{count} {count, plural, one {User} other {Users}}",
                      },
                      { count: data.total_entries }
                    ),
                    rightContent: <ArrowIcon />,
                    onClick: () => setIsChatAdminsDrawerOpen(true),
                  },
                ]
              : []),
          ]
        : [],
    [
      chat,
      data,
      confirmationSettingsChangeAlertState,
      t,
      updateGroupConversation,
    ]
  );

  return (
    <>
      <SidebarDrawer
        onClose={onRequestClose}
        isOpen={isOpen}
        customHeader={
          <div className="z-10 flex items-center justify-between py-[7px] px-8 shadow-[0px_0px_8px_rgba(0,0,0,0.08)]">
            <span className="font-secondary leading-2 text-[15px] font-semibold text-[#333]">
              {t({
                id: "chats-page.group-settings",
                defaultMessage: "Group Settings",
              })}
            </span>
            <PlainButton
              onClick={onRequestClose}
              className="bg-grey-200 hover:bg-grey-300 flex h-8 w-8 items-center justify-center rounded-lg transition-colors"
            >
              <CancelIcon />
            </PlainButton>
          </div>
        }
        className="w-[400px]"
      >
        <SidebarDrawer.Content className="bg-dark-100 flex flex-col gap-2">
          <div className="flex flex-col items-center gap-8 bg-white p-6">
            <AvatarSelect
              defaultSrc={chat.img || undefined}
              onSave={(img) => {
                if (!img || !chat.id) return;
                updateGroupConversation({
                  id: chat.id,
                  img,
                });
              }}
              size={AvatarSelectSize.xl}
            />
            <TextInput
              label={t({
                id: "chats-page.group-name",
                defaultMessage: "Group Name",
              })}
              isRequired
              classNames={{
                wrapperClassName: "w-full",
              }}
              value={name}
              onChange={(e) => setName(e.currentTarget.value.trimStart())}
              onBlur={() => {
                if (!chat.id || name.trim() === chat.title) return;
                if (!name) {
                  setName(chat.title || "");
                  return;
                }
                updateGroupConversation({
                  id: chat.id,
                  title: name.trim(),
                });
              }}
            />
          </div>
          {sections.map((section) => (
            <InteractiveContainer
              className="flex items-center justify-between gap-2 bg-white p-6"
              key={section.id}
              onClick={section.onClick}
            >
              <div className="flex flex-col gap-1">
                <span className="text-dark font-secondary leading-4">
                  {section.title}
                </span>
                <span className="font-secondary leading-2 text-xs text-[#828282]">
                  {section.description}
                </span>
              </div>
              <div className="shrink-0">{section.rightContent}</div>
            </InteractiveContainer>
          ))}
          <div className="h-full bg-white" />
        </SidebarDrawer.Content>
      </SidebarDrawer>
      <ConfirmationSettingsChangeAlert
        isOpen={confirmationSettingsChangeAlertState.isOpen}
        onRequestClose={confirmationSettingsChangeAlertState.close}
        type={confirmationSettingsChangeAlertState.metadata?.type}
        onConfirm={() =>
          confirmationSettingsChangeAlertState.confirm({
            closeOnceConfirmed: true,
          })
        }
      />
      {chat.id && (
        <ChatAdminsDrawer
          chatId={chat.id}
          isOpen={isChatAdminsDrawerOpen}
          onRequestClose={() => setIsChatAdminsDrawerOpen(false)}
        />
      )}
    </>
  );
};
