import React, { useMemo, useState } from "react";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useTranslations } from "@jugl-web/utils";
import { getMessageReactionsSummary } from "@web-src/modules/chats/components/DeprecatedChatMessageBubble/utils";
import { SidebarDrawer } from "@jugl-web/ui-components";
import { ChatMessageModel } from "@jugl-web/rest-api";
import { dateTimeString } from "@web-src/utils/datetime";
import { ReactionTile } from "./components/ReactionTile";
import { useChatsPageWithSelectedChatProvider } from "../../pages/ChatsPage/providers/ChatsPageProvider";
import { ChatMessage } from "../ChatMessage/ChatMessage";

export const ChatMessageReactionsSideBar: React.FC<{
  message?: ChatMessageModel;
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose, message }) => {
  const [selectedReaction, setSelectedReaction] = useState<string>();
  const sortedReactions = useMemo(
    () => (message ? getMessageReactionsSummary(message) : null),
    [message]
  );
  const { activeChatId } = useChatsPageWithSelectedChatProvider();
  const { t } = useTranslations();

  const visibleReactions = useMemo(
    () =>
      message?.reactions?.filter(
        (item) => !selectedReaction || selectedReaction === item.reaction
      ),
    [message, selectedReaction]
  );

  return (
    <SidebarDrawer
      onClose={() => onRequestClose()}
      isOpen={isOpen}
      title={t({
        id: "chats-page.message-reactions",
        defaultMessage: "Message reactions",
      })}
    >
      <SidebarDrawer.Content className="pb-4">
        {message && (
          <>
            <div className="w-full">
              <div className="flex w-full max-w-full justify-center bg-gray-100 px-[16px] pt-5">
                <ChatMessage
                  message={message}
                  chatId={activeChatId}
                  isMessageActionsDisabled
                  isPreview
                />
              </div>
            </div>
            <div className="flex w-full gap-2 overflow-x-auto p-5">
              <ReactionTile
                isSelected={selectedReaction === undefined}
                count={message.reactions?.length}
                onClick={setSelectedReaction}
              />
              {sortedReactions?.map((item) => (
                <ReactionTile
                  isSelected={selectedReaction === item.reaction}
                  title={item.reaction}
                  count={item.count}
                  onClick={setSelectedReaction}
                />
              ))}
            </div>
            <div className="mb-3 h-[4px] w-full bg-gray-100" />
            <div className="space-y-2 px-4">
              {visibleReactions?.map((item) => (
                <div className="flex justify-between gap-1">
                  <div className="flex flex-col gap-1 truncate">
                    <span className="text-dark font-secondary truncate text-sm leading-3">
                      <UserProfileName userId={item.user_id} />
                    </span>
                    <span className="font-secondary text-xs leading-[15px] text-[#707577]">
                      {dateTimeString(item.timestamp)}
                    </span>
                  </div>
                  <div>{item.reaction}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
