import { useRestApiProvider } from "@jugl-web/rest-api";
import { Button, MobileDrawer } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React, { useCallback } from "react";
import { useTaskChecklistContext } from "./TaskChecklistProvider";

export const DeleteChecklistConfirmationScreen: React.FC<{
  entityId: string;
  taskId: string;
  onClose: () => void;
}> = ({ entityId, taskId, onClose }) => {
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const { selectedChecklistIds } = useTaskChecklistContext();
  const [deleteChecklistItems, { isLoading }] =
    tasksApi.useDeleteChecklistItemsMutation();

  const handleDeleteChecklistItems = useCallback(async () => {
    await deleteChecklistItems({
      entityId,
      taskId,
      subtaskIds: selectedChecklistIds,
    });

    onClose();
  }, [entityId, taskId, selectedChecklistIds, onClose, deleteChecklistItems]);

  return (
    <>
      <MobileDrawer.Content>
        <div className="font-secondary mt-3 text-sm leading-[21px] text-[#4F4F4F]">
          {t(
            {
              id: "tasks-page.delete-subtasks-confirmation",
              defaultMessage:
                "Are you sure you want to delete {count, plural, one {selected subtask} other {{count} selected subtasks}}?",
            },
            { count: selectedChecklistIds.length }
          )}
        </div>
      </MobileDrawer.Content>
      <MobileDrawer.Actions className="flex flex-row items-center gap-4">
        <Button onClick={onClose} color="grey" fullWidth>
          {t({ id: "common.cancel", defaultMessage: "Cancel" })}
        </Button>
        <Button
          onClick={handleDeleteChecklistItems}
          color="tertiary"
          fullWidth
          isDisabled={isLoading}
        >
          {t({ id: "common.delete", defaultMessage: "Delete" })}
        </Button>
      </MobileDrawer.Actions>
    </>
  );
};
