import { Alert, LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, useToast } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { FC } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import alertGif from "./assets/alert.gif";

export const ConfirmRemoveWarehouseAlert: FC<{
  isOpen: boolean;
  itemIds: string[];
  checkedList: {
    [key: string]: boolean;
  };
  numberOfChecked: number;
  onSuccess: (itemsIds: string[]) => void;
  onClose: () => void;
}> = ({
  isOpen,
  itemIds,
  checkedList,
  numberOfChecked,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const { inventoryApi } = useRestApiProvider();
  const [deleteItem, { isLoading: isRemovingWarehouses }] =
    inventoryApi.useDeleteWarehouseMutation();
  const [loadAllWarehouses] = inventoryApi.useLazyGetAllWarehousesQuery();
  const handleRemoveClick = async () => {
    let itemsToRemove = itemIds;
    const checkedListTrueValuesNumber =
      Object.values(checkedList).filter(Boolean).length;
    if (checkedListTrueValuesNumber !== numberOfChecked) {
      const allWarehousesList = await loadAllWarehouses({
        entityId: entity.id,
      });
      const visibleCheckboxIds = Object.keys(checkedList);
      const newItemsToRemoveList: string[] = [];
      allWarehousesList.data?.forEach((warehouse) => {
        if (
          !(
            visibleCheckboxIds.includes(warehouse.id) &&
            !checkedList[warehouse.id]
          )
        ) {
          newItemsToRemoveList.push(warehouse.id);
        }
      });
      itemsToRemove = newItemsToRemoveList;
    }

    const promises = itemsToRemove.map(async (id) =>
      deleteItem({ entityId: entity.id, warehouseId: id })
    );

    const results = await Promise.all(promises);
    if (results.some((res) => "error" in res)) {
      onClose();
      return;
    }
    if (!results.some((res) => "error" in res)) {
      onClose();
      onSuccess?.(itemsToRemove);
      toast(
        t(
          {
            id: "inventory-items-page.warehouses-was-deleted",
            defaultMessage:
              "{count} {count, plural, one {Warehouse} other {Warehouses}} was deleted",
          },
          {
            count: itemsToRemove.length,
          }
        )
      );
    }
  };

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      img={<img src={alertGif} alt="Alert" className="h-[70px] w-[70px]" />}
      contentContainerClassName="px-6"
      buttonsContainerClassName="px-10 mt-8"
      content={
        <div className="text-sm text-[#4F4F4F]">
          {t({
            id: "inventory-warehouses-page.warehouse-remove-confirm-description",
            defaultMessage:
              "If you delete this items, you will no longer be able to restore it",
          })}
        </div>
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          color: "grey",
          isDisabled: isRemovingWarehouses,
          onClick: onClose,
        },
        {
          text: isRemovingWarehouses
            ? ""
            : t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
          color: "tertiary",
          iconEnd: isRemovingWarehouses && <LoadingSpinner size="xs" />,
          isDisabled: isRemovingWarehouses,
          onClick: handleRemoveClick,
        },
      ]}
    />
  );
};
