export const hideInputArrowsClass =
  "[-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none";

export const preventIncorrectNumberInputCharacters = (
  e: React.KeyboardEvent<HTMLInputElement>,
  decimal?: boolean
) => {
  const valueToTest = e.currentTarget.value;
  const hasDecimal = /[.,]/.test(valueToTest);
  const allowedCharacters = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "Backspace",
    "Delete",
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
    "Enter",
    ...(decimal && !hasDecimal ? [".", ","] : []),
  ];

  if (!allowedCharacters.includes(e.key)) {
    e.preventDefault();
  }
};
