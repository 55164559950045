export enum AppModule {
  dashboard = "dashboard",
  chat = "chat",
  people = "people",
  tasks = "tasks",
  drive = "drive",
  calls = "calls",
  travelLog = "travelLog",
  clients = "clients",
  integrations = "integrations",
  orderForms = "orderForms",
  workspace = "workspace",
  inventory = "inventory",
  taskReports = "taskReports",
  timeClockReports = "timeClockReports",
  scheduledReports = "scheduledReports",
}
