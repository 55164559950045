import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import {
  Breadcrumb,
  DRAWER_HEADER_HEIGHT_PX,
} from "@jugl-web/ui-components/web/DrawerHeader";
import { useTranslations } from "@jugl-web/utils";
import {
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useEffect, useMemo } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import { ManageProfileAttributesDialogScreenToParametersMap } from "./types";
import { EntryScreen } from "./screens/EntryScreen";
import { NewUserCustomFieldScreen } from "./screens/NewUserCustomFieldScreen";
import { EditUserCustomFieldScreen } from "./screens/EditUserCustomFieldScreen";
import { NewUserCustomDropdownFieldValuesScreen } from "./screens/NewUserCustomDropdownFieldValuesScreen";
import { BookmarkScreen } from "./screens/BookmarkScreen";
import { ReactComponent as ManageAttributesIcon } from "./assets/manage-attributes.svg";

const InnerManageProfileAttributesDialog: FC<
  ManageProfileAttributesDialogProps
> = ({ isOpen, onClose }) => {
  const { screen, transitionTo, renderContent, transitionRequest$ } =
    useScreenTransitionManager<ManageProfileAttributesDialogScreenToParametersMap>();

  const { entityId } = useEntitySelectedProvider();
  const { usersApi } = useRestApiProvider();

  const { data, isFetching, isError, refetch } =
    usersApi.useGetUserCustomFieldsQuery(
      {
        entityId,
      },
      { skip: !isOpen, refetchOnMountOrArgChange: true }
    );

  const { userCustomFields, propId } = useMemo(
    () => ({ userCustomFields: data?.value || [], propId: data?.propId || "" }),
    [data]
  );

  const bookmarks = useMemo(
    () =>
      userCustomFields
        .filter((userCustomField) => userCustomField.bookmark > 0)
        .sort((a, b) => a.bookmark - b.bookmark),
    [userCustomFields]
  );

  const { t } = useTranslations();

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    const entryBreadcrumb: Breadcrumb = {
      id: "entry",
      title: (
        <div className="flex items-center gap-3">
          <ManageAttributesIcon />
          <span>
            {t({
              id: "cpanel-page.manage-profile-attributes",
              defaultMessage: "Manage Profile Attributes",
            })}
          </span>
        </div>
      ),
      onClick:
        screen.name !== "entry"
          ? () => transitionTo({ name: "entry" })
          : undefined,
    };

    return renderContent({
      entry: [entryBreadcrumb],
      newUserCustomField: [
        entryBreadcrumb,
        {
          id: "new-field",
          title: t({
            id: "cpanel-page.new-field",
            defaultMessage: "New Field",
          }),
        },
      ],
      editUserCustomField: [
        entryBreadcrumb,
        {
          id: "edit-field",
          title: t({
            id: "cpanel-page.edit-field",
            defaultMessage: "Edit Field",
          }),
        },
      ],
      newUserCustomDropdownFieldValues: [
        entryBreadcrumb,
        {
          id: "new-custom-field",
          title: t({
            id: "tasks-page.new-custom-field",
            defaultMessage: "New custom field",
          }),
        },
      ],
      bookmarks: [
        entryBreadcrumb,
        {
          id: "bookmarks",
          title: t({
            id: "tasks-page.bookmarks",
            defaultMessage: "Bookmarks",
          }),
        },
      ],
    });
  }, [renderContent, screen.name, transitionTo, t]);

  const content = useMemo<JSX.Element>(
    () =>
      renderContent({
        entry: (
          <EntryScreen
            userCustomFields={userCustomFields}
            propId={propId}
            bookmarks={bookmarks}
          />
        ),
        newUserCustomField: (params) => (
          <NewUserCustomFieldScreen
            propId={propId}
            userCustomFields={userCustomFields}
            {...params}
          />
        ),
        editUserCustomField: (params) => (
          <EditUserCustomFieldScreen
            propId={propId}
            userCustomFields={userCustomFields}
            {...params}
          />
        ),
        newUserCustomDropdownFieldValues: (params) => (
          <NewUserCustomDropdownFieldValuesScreen
            propId={propId}
            userCustomFields={userCustomFields}
            {...params}
          />
        ),
        bookmarks: (
          <BookmarkScreen
            propId={propId}
            userCustomFields={userCustomFields}
            bookmarks={bookmarks}
          />
        ),
      }),
    [renderContent, userCustomFields, propId, bookmarks]
  );

  const handleSafeClose = () => {
    if (transitionRequest$.observed) {
      transitionRequest$.next({ resolve: onClose });
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      transitionTo({ name: "entry" }, { force: true });
    }
  }, [isOpen, transitionTo]);

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        type: "breadcrumbs",
        breadcrumbs,
      }}
      onClose={handleSafeClose}
    >
      <ScreenTransitionWrapper
        screenName={screen.name}
        style={{ height: `calc(100% - ${DRAWER_HEADER_HEIGHT_PX}px)` }}
      >
        <DataLoadingWrapper
          isLoading={isFetching}
          isError={isError}
          onRetry={refetch}
        >
          {content}
        </DataLoadingWrapper>
      </ScreenTransitionWrapper>
    </BottomCenteredDrawer>
  );
};

interface ManageProfileAttributesDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ManageProfileAttributesDialog: FC<
  ManageProfileAttributesDialogProps
> = (props) => (
  <ScreenTransitionManagerProvider initialScreen={{ name: "entry" }}>
    <InnerManageProfileAttributesDialog {...props} />
  </ScreenTransitionManagerProvider>
);
