import { cx } from "@jugl-web/utils";
import React, { useMemo, useState } from "react";
import mime from "mime";
import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { useDownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { CircleProgressBar } from "./components/CircleProgressBar/CircleProgressBar";
import { ReactComponent as RetryIcon } from "./assets/retry-icon.svg";
import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";
import { ReactComponent as DownloadIconOutgoing } from "./assets/download-icon-outgoing.svg";
import { ReactComponent as FileIcon } from "./assets/file-icon.svg";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";

const SUPPORTED_EXTENSIONS = [
  "pdf",
  "xlsx",
  "doc",
  "jpg",
  "png",
  "json",
  "txt",
  "zip",
  "mp4",
  "mov",
  "mp3",
];

export const FileAttachment: React.FC = () => {
  const {
    attachment,
    isOutgoing,
    getAttachmentUrls,
    entityId,
    hasContentProtectionRestriction,
    isMessageActionsDisabled,
  } = useChatMessageProvider();
  if (
    !attachment ||
    ![
      ChatMessageAttachmentType.doc,
      ChatMessageAttachmentType.otherFile,
    ].includes(attachment.type)
  ) {
    throw new Error(
      "Message doesn't have attachment or attachment type is not supported"
    );
  }

  const { preview, stream } = getAttachmentUrls(attachment);
  const { previewFile } = useFilePreview();
  const { download, files, cancel } = useDownloadManagerProvider();
  const [previewStatus, setPreviewStatus] = useState<
    "loading" | "error" | "loaded"
  >("loading");
  const downloadFile = useMemo(
    () => files[attachment.uid],
    [files, attachment.uid]
  );
  const isDownloading = downloadFile?.status === "in-progress";

  const fileSizeString = useMemo(() => {
    if (isDownloading) {
      return `${getFileSizeLabel(
        attachment.size * (downloadFile.progress / 100)
      )} / ${getFileSizeLabel(attachment.size)}`;
    }
    return getFileSizeLabel(attachment.size, 2);
  }, [attachment.size, downloadFile, isDownloading]);

  const isDownloadError = downloadFile?.status === "error";
  const isDownloadAvailable =
    !hasContentProtectionRestriction &&
    (!downloadFile || downloadFile?.status === "cancelled" || isDownloadError);
  const isPreviewOpenAvailable = downloadFile?.status === "completed";

  const attachmentExtension = useMemo(() => {
    const extension = mime.getExtension(attachment?.mimetype);
    if (extension && SUPPORTED_EXTENSIONS.includes(extension)) {
      return extension;
    }
    return "file";
  }, [attachment?.mimetype]);

  return (
    <div
      className={cx(
        "flex min-w-[220px] items-start gap-2",
        (isDownloadAvailable || isPreviewOpenAvailable) && "cursor-pointer"
      )}
      onClick={() => {
        if (isMessageActionsDisabled) {
          return;
        }
        previewFile({
          url: stream,
          mimeType: attachment.mimetype,
          name: attachment.name || "jugl-file",
          onDownload: !hasContentProtectionRestriction
            ? () => window.open(stream, "_blank")
            : undefined,
        });
      }}
    >
      <div className="relative h-10 w-10">
        {previewStatus !== "error" && (
          <div
            className={cx(
              "border-dark-100 absolute h-10 w-10 shrink-0 overflow-hidden rounded border border-solid",
              {
                invisible: previewStatus === "loading",
              }
            )}
          >
            <img
              src={preview}
              className="h-full w-full object-cover"
              alt={attachment.name}
              onError={() => setPreviewStatus("error")}
              onLoad={() => setPreviewStatus("loaded")}
            />
          </div>
        )}

        {previewStatus !== "loaded" && (
          <>
            <FileIcon className={isOutgoing ? "text-white" : "text-primary"} />
            <div className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
              <span
                className={cx(
                  "font-secondary select-none text-[10px] font-medium",
                  isOutgoing ? "text-primary" : "text-white"
                )}
              >
                {attachmentExtension}
              </span>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-1 grow flex-col gap-1.5 truncate">
        <span
          className={cx(
            "leading-2 truncate font-medium",
            isOutgoing ? "text-white" : "text-dark"
          )}
        >
          {attachment.name}
        </span>
        <span
          className={cx(
            "truncate text-sm",
            isOutgoing ? "text-primary-100" : "text-[#828282]",
            isDownloadError && "text-gradients-danger"
          )}
        >
          {/* TODO: i18n */}
          {isDownloadError ? "Failed. Tap to retry" : fileSizeString}
        </span>
      </div>

      {!isMessageActionsDisabled &&
        (isDownloading || isDownloadAvailable || isDownloadError) && (
          <div
            className={cx(
              "flex h-6 w-6 shrink-0 items-center justify-center overflow-hidden rounded-full",
              isOutgoing ? "bg-white" : "bg-dark-500",
              isDownloadError && "bg-gradients-danger",
              isDownloading && "cursor-pointer"
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (isDownloading) {
                cancel(attachment.uid);
              } else {
                download(attachment.uid, {
                  entityId,
                  fileSize: attachment.size,
                  mimeType: attachment.mimetype,
                  module: "attachments",
                  fileName: attachment.name || "jugl-file",
                  path: `${entityId}/${attachment.uid}`,
                }).then((file) => {
                  const link = document.createElement("a");
                  const objectUrl = URL.createObjectURL(file);
                  link.href = objectUrl;
                  link.download = attachment.name || "jugl-file";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(objectUrl);
                });
              }
            }}
          >
            {(() => {
              if (isMessageActionsDisabled) {
                return null;
              }
              if (isDownloading) {
                return (
                  <CircleProgressBar
                    progress={downloadFile.progress}
                    isOutgoing={isOutgoing}
                  />
                );
              }
              if (isDownloadError) {
                return <RetryIcon />;
              }
              if (isDownloadAvailable) {
                return isOutgoing ? <DownloadIconOutgoing /> : <DownloadIcon />;
              }
              return null;
            })()}
          </div>
        )}
    </div>
  );
};
