import { HeaderBreadcrumbs } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import {
  SpotlightTourProvider,
  useSpotlightTour,
} from "@jugl-web/utils/hooks/useSpotlight";
import { DASHBOARD_SPOTLIGHT_TOUR_ID } from "@jugl-web/utils/storage";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useEffect, useMemo, useState } from "react";
import { DashboardContent } from "../../components/DashboardContent";
import { DashboardControlBar } from "../../components/DashboardControlBar";
import { InteractiveDashboardBreadcrumbItem } from "../../components/InteractiveDashboardBreadcrumbItem";
import { ManageDashboardDialog } from "../../components/ManageDashboardDialog";
import { NoDashboardFeedback } from "../../components/NoDashboardFeedback";
import { DashboardSpotlightTourStep } from "../../consts";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { DashboardProvider } from "../../providers/DashboardProvider";
import { openNewDashboardDialog$ } from "../../utils";

export const DashboardPageInner = () => {
  const { entityId } = useEntitySelectedProvider();
  const { id: meId } = useMe();

  const {
    dashboards,
    areDashboardsLoading,
    detailedDashboard,
    isDashboardOwner,
    setSelectedDashboardId,
  } = useDashboardContext();

  const [isNewDashboardDialogOpen, setIsNewDashboardDialogOpen] =
    useState(false);

  const { t } = useTranslations();

  const canManageDashboard = useMemo(() => {
    if (!detailedDashboard) {
      return false;
    }

    return isDashboardOwner(detailedDashboard);
  }, [detailedDashboard, isDashboardOwner]);

  const { context: spotlightTourContext } = useSpotlightTour({
    id: DASHBOARD_SPOTLIGHT_TOUR_ID,
    steps: [
      DashboardSpotlightTourStep.switchOrCreateDashboard,
      DashboardSpotlightTourStep.createWidget,
    ],
    delayBeforeStep: 400,
    isDisabled: !canManageDashboard,
  });

  useEffect(() => {
    const subscription = openNewDashboardDialog$.subscribe(() => {
      setIsNewDashboardDialogOpen(true);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const hasNoDashboard = !areDashboardsLoading && dashboards.length === 0;

  return (
    <>
      <SpotlightTourProvider context={spotlightTourContext}>
        <div className="flex h-full flex-col">
          <HeaderBreadcrumbs
            items={[
              hasNoDashboard
                ? {
                    title: t({
                      id: "dashboard-page.dashboard",
                      defaultMessage: "Dashboard",
                    }),
                  }
                : {
                    customRenderer: () => (
                      <InteractiveDashboardBreadcrumbItem
                        onOpenNewDashboardDialog={() =>
                          setIsNewDashboardDialogOpen(true)
                        }
                      />
                    ),
                  },
            ]}
          />
          {hasNoDashboard ? (
            <div className="flex h-full w-full items-center justify-center">
              <NoDashboardFeedback
                onNewDashboardClick={() => setIsNewDashboardDialogOpen(true)}
              />
            </div>
          ) : (
            <>
              <DashboardControlBar />
              <DashboardContent />
            </>
          )}
        </div>
      </SpotlightTourProvider>
      <ManageDashboardDialog
        entityId={entityId}
        meId={meId || ""}
        isOpen={isNewDashboardDialogOpen}
        onClose={() => setIsNewDashboardDialogOpen(false)}
        onAfterCreate={(dashboard) => setSelectedDashboardId(dashboard.id)}
      />
    </>
  );
};

export const DashboardPage = () => (
  <DashboardProvider>
    <DashboardPageInner />
  </DashboardProvider>
);
