import {
  Button,
  InteractiveContainer,
  MobileDrawer,
} from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import React, { useMemo, useState } from "react";
import { cx, useSearchInput, useTranslations } from "@jugl-web/utils";
import Highlighter from "react-highlight-words";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { useTasks } from "../../hooks/useTasks";
import { useTaskBoards } from "../../hooks/useTaskBoards";
import { ReactComponent as TaskIcon } from "./icons/task.svg";
import { ReactComponent as OrderIcon } from "./icons/order.svg";
import { useTaskPermissionsFactory } from "../../hooks/useTaskPermissions";
import { ReactComponent as CheckIcon } from "./icons/check.svg";

interface MoveToTaskScreenProps {
  entityId: string;
  taskId: string;
  meId: string;
  onSelect: (task: PreviewTask) => void;
}

export const MoveToTaskScreen: React.FC<MoveToTaskScreenProps> = ({
  entityId,
  taskId,
  meId,
  onSelect,
}) => {
  const { t } = useTranslations();

  const { boards, getBoardById } = useTaskBoards({ entityId });
  const [selectedTaskBoardId, setSelectedTaskBoardId] = useState("my");

  const [selectedTask, setSelectedTask] = useState<PreviewTask>();

  const { searchQuery, inputProps, reset } = useSearchInput({
    debounce: 500,
  });

  const { tasks } = useTasks({
    entityId,
    source: { type: "boardTasks", boardId: selectedTaskBoardId },
    searchQuery,
  });

  const taskBoardItems = useMemo(
    () => [
      {
        id: "my",
        title: t({
          id: "home-sidebar-component.my-tasks",
          defaultMessage: "My Tasks",
        }),
      },
      ...boards.map((board) => ({
        id: board.id,
        title: board.name,
      })),
    ],
    [t, boards]
  );

  const getTaskPermissions = useTaskPermissionsFactory({ entityId, meId });

  const filteredTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.id !== taskId && getTaskPermissions(task).canManageChecklist
      ),
    [taskId, tasks, getTaskPermissions]
  );

  return (
    <>
      <MobileDrawer.Content>
        <div className="bg-dark-100 mb-6 h-px w-full" />
        <SearchInput
          variant="filled"
          color="grey"
          {...inputProps}
          onClear={reset}
        />
        <div className="mt-2 flex gap-2 overflow-x-auto pb-1.5">
          {taskBoardItems.map((board) => {
            const isSelected = selectedTaskBoardId === board.id;
            return (
              <InteractiveContainer
                className={cx(
                  "bg-grey-100 max-w-[140px] shrink-0 truncate rounded-lg px-4 py-[5px] transition-colors",
                  isSelected && "bg-primary"
                )}
                key={board.id}
                onClick={() => setSelectedTaskBoardId(board.id)}
              >
                <span
                  className={cx(
                    "text-dark-700 font-secondary leading-2 text-[13px] transition-colors",
                    isSelected && "font-medium text-white"
                  )}
                >
                  {board.title}
                </span>
              </InteractiveContainer>
            );
          })}
        </div>
        <div className="mt-7 flex max-h-[50vh] flex-col gap-3 overflow-y-auto">
          {filteredTasks.length ? (
            filteredTasks.map((task) => (
              <InteractiveContainer
                className={cx(
                  "flex items-start justify-between gap-1 rounded-xl py-2 px-3 transition-colors",
                  selectedTask?.id === task.id && "bg-primary-50"
                )}
                key={task.id}
                onClick={() => setSelectedTask(task)}
              >
                <div className="flex items-center gap-3 overflow-hidden">
                  <div className="shrink-0">
                    {task.order_id ? <OrderIcon /> : <TaskIcon />}
                  </div>
                  <div className="flex flex-col gap-0.5 overflow-hidden">
                    <span className="text-dark font-secondary truncate text-sm leading-[21px]">
                      <Highlighter
                        autoEscape
                        highlightClassName="text-primary bg-transparent"
                        searchWords={[searchQuery || ""]}
                        textToHighlight={task.name}
                      />
                    </span>
                    <span className="text-grey-800 font-secondary truncate text-sm leading-[140%]">
                      {task.board_id
                        ? getBoardById(task.board_id)?.name
                        : "No Board"}
                    </span>
                  </div>
                </div>
                <div className="flex shrink-0 items-start gap-2">
                  <span className="font-secondary text-sm leading-[21px] text-[#828282]">
                    {task.order_id}
                  </span>
                  {selectedTask?.id === task.id && <CheckIcon />}
                </div>
              </InteractiveContainer>
            ))
          ) : (
            <span className="font-secondary text-center text-sm leading-[160%] text-[#828282]">
              {t({
                id: "tasks-page.no-results",
                defaultMessage: "No results 😔",
              })}
            </span>
          )}
        </div>
      </MobileDrawer.Content>
      <MobileDrawer.Actions>
        <Button
          isDisabled={!selectedTask}
          onClick={() => {
            if (selectedTask) {
              onSelect(selectedTask);
            }
          }}
        >
          {t({
            id: "tasks-page.move",
            defaultMessage: "Move",
          })}
        </Button>
      </MobileDrawer.Actions>
    </>
  );
};
