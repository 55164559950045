import { Avatar } from "@jugl-web/ui-components/cross-platform";
import { apiUTCToLocalDateTime, cx } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import isToday from "date-fns/isToday";
import format from "date-fns/format";
import { ChatItem } from "@web-src/modules/chats/types";
import Highlighter from "react-highlight-words";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { ChatLastMessage } from "./components/ChatLastMessage";
import { ReactComponent as NotificationsMuteAllExceptMentionsIcon } from "./assets/notifications_mute_all_except_mentions.svg";
import { ReactComponent as NotificationsMuteAllIcon } from "./assets/notifications_mute_all.svg";

export type ChatListItemProps = {
  chat: ChatItem;
  onClick: (chat: ChatItem) => void;
  isSelected?: boolean;
  searchQuery?: string;
};

export const ChatListItem: FC<ChatListItemProps> = ({
  chat,
  onClick,
  isSelected,
  searchQuery,
}) => {
  const { dateLocale } = useLanguage();
  const { me } = useMe();
  const time = useMemo(() => {
    if (!chat.lastMessage?.timestamp) {
      return null;
    }
    const date = apiUTCToLocalDateTime(chat.lastMessage?.timestamp);
    return format(date, isToday(date) ? "hh:mm a" : "dd MMM", {
      locale: dateLocale,
    });
  }, [chat.lastMessage?.timestamp, dateLocale]);

  const { getChatNotificationsPreferences } = useNotifications();
  const chatNotificationPreferences = useMemo(
    () => chat?.id && getChatNotificationsPreferences(chat?.id),
    [chat?.id, getChatNotificationsPreferences]
  );
  const $notificationsIcon = useMemo(() => {
    switch (chatNotificationPreferences) {
      case "all":
        return <NotificationsMuteAllIcon />;
      case "all_except_mentions":
        return <NotificationsMuteAllExceptMentionsIcon />;
      case "none":
      default:
        return null;
    }
  }, [chatNotificationPreferences]);

  return (
    <div
      className={cx(
        "flex cursor-pointer items-center px-8 py-4 transition-colors hover:bg-gray-50",
        { "bg-primary-50 hover:bg-primary-50": isSelected }
      )}
      onClick={() => onClick(chat)}
    >
      <div className="flex min-w-0 flex-1 items-center gap-3">
        <Avatar imageUrl={chat.img} size="lg" username={chat.title || ""} />
        <div className="min-w-0 flex-1">
          <div className="flex items-center gap-2">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap font-medium">
              <Highlighter
                autoEscape
                highlightClassName="text-primary bg-transparent"
                highlightTag="span"
                searchWords={[searchQuery || ""]}
                textToHighlight={
                  chat.title
                    ? `${chat.title}${me?.id === chat.id ? ` (You)` : ""}`
                    : ""
                }
              />
            </div>
            {$notificationsIcon && (
              <div className="h-4 w-4 pr-2">{$notificationsIcon}</div>
            )}
          </div>
          <div
            className={cx(
              "relative mt-[6px] overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-[16px] text-[#707577]",
              {
                "mt-[-2px]":
                  chat.lastMessage?.attachments?.length &&
                  !chat.lastMessage.deleted,
              }
            )}
          >
            <ChatLastMessage chat={chat} />
          </div>
        </div>
      </div>
      {(time || !!chat.unreadCount) && (
        <div className="flex flex-col items-end gap-1">
          {time && (
            <div
              className={cx(
                "overflow-ellipsis whitespace-nowrap text-[13px] text-[#707577]",
                { "mb-6": !chat.unreadCount }
              )}
            >
              {time}
            </div>
          )}
          {!!chat.unreadCount && chat.unreadCount > 0 && (
            <div className="flex h-[20px] min-w-[20px] items-center justify-center rounded-full bg-[#09B965] px-[6px] text-xs font-medium text-white">
              {chat.unreadCount}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
