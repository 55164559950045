import { InteractiveContainer, PlainButton } from "@jugl-web/ui-components";
import React, { useEffect, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { Screen } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { ReactComponent as CloseIcon } from "./icons/cancel.svg";
import { ReactComponent as TrashIcon } from "./icons/trash.svg";
import { ReactComponent as MoveIcon } from "./icons/move.svg";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { TaskChecklistSelectionDrawer } from "./TaskChecklistSelectionDrawer";
import { TaskChecklistSelectionScreenToParametersMap } from "./types";

export const TaskChecklistSelectionPanel: React.FC<{
  entityId: string;
  taskId: string;
  onClose: () => void;
}> = ({ entityId, taskId, onClose }) => {
  const { t } = useTranslations();
  const { selectedChecklistIds } = useTaskChecklistContext();
  const [initialScreen, setInitialScreen] =
    useState<Screen<TaskChecklistSelectionScreenToParametersMap>>();

  useEffect(() => {
    if (!selectedChecklistIds.length) {
      onClose();
    }
  }, [selectedChecklistIds, onClose]);

  return initialScreen ? (
    <TaskChecklistSelectionDrawer
      isOpen
      entityId={entityId}
      taskId={taskId}
      onClose={onClose}
      initialScreen={initialScreen}
    />
  ) : (
    <>
      <div className="fixed top-0 left-0 z-40 flex w-full items-center justify-between bg-white p-4 shadow-[0_8px_32px_6px_rgba(0,0,0,0.08)]">
        <span className="text-dark font-secondary text-lg font-medium leading-4">
          {t(
            {
              id: "tasks-page.checklist-count",
              defaultMessage:
                "{count} {count, plural, one {checklist} other {checklists}}",
            },
            { count: selectedChecklistIds.length }
          )}
        </span>
        <PlainButton onClick={onClose}>
          <CloseIcon />
        </PlainButton>
      </div>
      <div className="fixed bottom-0 left-0 z-50 flex h-[70px] w-full items-center bg-white shadow-[0_8px_32px_6px_rgba(0,0,0,0.16)]">
        <InteractiveContainer
          className="flex h-full w-1/2 flex-col items-center justify-center gap-0.5"
          onClick={() => setInitialScreen({ name: "moveToTask" })}
        >
          <MoveIcon />
          <span className="text-dark font-secondary text-sm leading-[21px]">
            {t({ id: "common.move", defaultMessage: "Move" })}
          </span>
        </InteractiveContainer>
        <div className="bg-dark-100 h-full w-px" />
        <InteractiveContainer
          className="flex h-full w-1/2 flex-col items-center justify-center gap-0.5"
          onClick={() => setInitialScreen({ name: "deleteConfirmation" })}
        >
          <TrashIcon />
          <span className="text-dark font-secondary text-sm leading-[21px]">
            {t({ id: "common.delete", defaultMessage: "Delete" })}
          </span>
        </InteractiveContainer>
      </div>
    </>
  );
};
