import { Alert, InteractiveContainer } from "@jugl-web/ui-components";
import React from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import alertAnimation from "./assets/alert-animation.json";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";

export const LeaveGroupSingleAdminAlert: React.FC<{
  isOpen: boolean;
  onRoleManage: () => void;
  onDelete: () => void;
  onRequestClose: () => void;
}> = ({ isOpen, onDelete, onRequestClose, onRoleManage }) => {
  const { t } = useTranslations();

  return (
    <Alert
      img={
        <Lottie
          options={{ animationData: alertAnimation }}
          height={70}
          width={70}
        />
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t({
        id: "chats-page.leave-group",
        defaultMessage: "Leave Group",
      })}
      content={
        <div className="flex flex-col gap-8">
          <span>
            {t(
              {
                id: "chats-page.",
                defaultMessage:
                  "You are a <highlightedText>single Admin of this Group</highlightedText>. If you leave the Group, if will also be deleted for all the members. Before leaving, please assign Additional Admins of the Group to keep it live",
              },
              {
                highlightedText: (text: (string | JSX.Element)[]) => (
                  <span className="text-primary-800 font-semibold">{text}</span>
                ),
              }
            )}
          </span>
          <InteractiveContainer
            onClick={onRoleManage}
            className="bg-grey-100 flex items-center justify-between gap-2 rounded-2xl p-4"
          >
            <div className="flex flex-col items-start gap-1">
              <span className="text-dark font-secondary leading-4">
                {t({
                  id: "chats-page.chat-admins",
                  defaultMessage: "Chat Admins",
                })}
              </span>
              <div className="font-secondary leading-2 flex items-center gap-1 text-xs text-[#828282]">
                <span>
                  {t(
                    {
                      id: "chats-page.users-count",
                      defaultMessage:
                        "{count} {count, plural, one {User} other {Users}}",
                    },
                    { count: 1 }
                  )}
                </span>
                <span>•</span>
                <span>
                  {t({
                    id: "chats-page.tap-to-manage",
                    defaultMessage: "Tap to manage",
                  })}
                </span>
              </div>
            </div>
            <ArrowIcon />
          </InteractiveContainer>
        </div>
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "chats-page.leave-and-delete",
            defaultMessage: "Leave and Delete",
          }),
          color: "tertiary",
          onClick: onDelete,
        },
      ]}
    />
  );
};
