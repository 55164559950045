import {
  ChatType,
  UserWorkspaceRole,
  ChatMessageType,
  ChatMessagePayloadCallAction,
  ChatMessagePayload,
  ChatMessageModel,
} from "@jugl-web/rest-api";

export {
  ChatMessageType,
  ChatMessagePayloadType,
  ChatMessagePayloadCallAction,
} from "@jugl-web/rest-api";

export type ChatMessageBodyToken = {
  type: "url" | "string" | "mention" | "userName";
  rawString?: string;
  humanizedString?: string;
  id: string;
  value?: string;
  suffix?: string;
};

export type DigestType =
  | "people"
  | "call"
  | "drive"
  | "chat"
  | "task"
  | "customer"
  | "task_reminder";

export enum PheonixPushAction {
  transport = "transport",
  read = "read",
  delivered = "delivered",
  update = "update",
  receipts = "receipts",
  bulk_receipts = "bulk_receipts",
  update_call = "update_call",
}

export enum ChatMessagePayloadActionFor {
  workspace = "workspace",
}

export enum ChatMessagePayloadPushType {
  silent = "silent",
  normal = "normal",
}

export type ChatMessage = ChatMessageModel;

export type SearchChatMessage = {
  body: string;
  caption: string;
  created_at: string;
  file_name: string;
  from: string;
  msg_id: string;
  msg_receipt_id: string;
};

export type ArchivedChatMessage = {
  entity_id: string;
  from: string;
  to: string;
  type: ChatMessageType;
  meta: unknown;
  msg_id: string;
  payload: ChatMessage;
};

export type ChatHistoryItem = {
  conversation: {
    id: string;
    img: string | null;
    title: string;
    type: ChatType;
    config?: { admin_only_msg: boolean; content_protected: boolean };
    role?: UserWorkspaceRole;
    user_count?: number;
    timestamp: string;
    users?: string[];
  } | null;
  from: string;
  msg_id: string;
  deleted: boolean;
  msg_receipt_id: string;
  payload: ChatMessagePayload;
  timestamp: string;
  to: string;
  unread_count: {
    first_item: {
      created_at: string;
      msg_id: string;
    };
    unread_count: number;
  } | null;
};

export type Chat = {
  id: string;
  name: string;
  type: ChatType;
  img?: string;
  lastMessage?: ChatMessage;
  messagesCount?: number;
  selfChat?: boolean;
  unreadState?: ChatHistoryItem["unread_count"];
};

export interface SendMessagePheonixResponse {
  client_msg_id: string;
  msg_id: string;
  result: string;
  timestamp: string;
}

export enum ChatCallType {
  audio = "audio",
  video = "video",
}
export enum ScenarioCallType {
  conference = "conference",
  call = "call",
}

export interface ChatItem {
  id?: string;
  img?: null | string;
  title?: string;
  type: ChatType;
  isSelf?: boolean;
  unreadCount?: number;
  userCount?: number;
  users?: string[];
  role?: UserWorkspaceRole;
  config?: { admin_only_msg: boolean; content_protected: boolean };
  firstUnreadMessage?: {
    msgId: string;
    createdAt: string;
  };
  lastReadMessage?: {
    msgId: string;
    createdAt: string;
  };
  lastLiveOutgoingMessage?: {
    msgId: string;
    createdAt: string;
  };
  lastMessage?: {
    msgId: string;
    from: string;
    message: ChatMessage["payload"]["body"];
    tokenizedMessage: ChatMessageBodyToken[];
    timestamp: string;
    attachments?: ChatMessage["payload"]["attachments"] | null;
    deleted?: boolean;
    type?: ChatMessage["payload"]["type"];
    conference?: ChatMessage["payload"]["conference"] | null;
  };
  deleted?: boolean;
  isChatInitialized?: boolean;
}

export enum ChatMessageActionType {
  forward = "forward",
  edit = "edit",
  reply = "reply",
  delete = "delete",
}

export interface ChatMessageAction {
  type: ChatMessageActionType;
  message: ChatMessage;
}

export interface BulkReceiptSentResponse {
  bulk: boolean;
  chat_id: string;
  chat_type: ChatType;
  count: number;
  entity_id: string;
  from: string;
  msg_id: string;
  msg_timestamp: string;
  msg_receipt_id: string;
  timestamp: string;
  type: string;
}

export type CallListPayload = {
  body: string | null | undefined;
  call_action: ChatMessagePayloadCallAction;
  call_channel: string;
  call_type: string;
  duration: string;
  push_type: ChatMessagePayloadPushType;
  sender_name: string;
  title: string;
  type: "call";
  version: number;
};
export type CallListHistoryItem = {
  from: string;
  msg_id: string;
  msg_receipt_id: string;
  payload: CallListPayload;
  timestamp: string;
  to: string;
  entity_id: string;
};
