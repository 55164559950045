import { useCallback, useContext } from "react";
import { PhoenixSocketContext } from "@web-src/modules/socket/providers/PhoenixSocket";
import { useUpdateChatMessages } from "@web-src/modules/chats/hooks/useUpdateChatMessages";
import { PheonixPushAction } from "@web-src/modules/chats/types";

type DeleteMessageParams = {
  msgId: string;
  chatId: string;
};

export const useDeleteChatMessage = () => {
  const { channel } = useContext(PhoenixSocketContext);
  const { updateMessage } = useUpdateChatMessages();
  const deleteMessageCallback = useCallback(
    ({ msgId, chatId }: DeleteMessageParams) =>
      new Promise<void>((resolve, reject) => {
        if (!channel) {
          reject(new Error("No channel"));
          return;
        }
        const message = channel?.push(PheonixPushAction.update, {
          msg_id: msgId,
          payload: {
            deleted: true,
          },
        });
        message?.receive("ok", () => {
          updateMessage({
            chatId,
            item: {
              id: msgId,
              data: {
                deleted: true,
                payload: {
                  body: null,
                  attachments: null,
                  reply_attributes_map: null,
                },
              },
            },
            merge: true,
          });
          resolve();
        });
        message?.receive("error", reject);
        message?.receive("timeout", reject);
      }),
    [channel, updateMessage]
  );
  return deleteMessageCallback;
};
