import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import useEntity from "@web-src/features/app/hooks/useEntity";
import React from "react";
import format from "date-fns/format";
import { CommonAudioPlayer } from "@web-src/modules/media/components/CommonAudioPlayer";
import { ChatMessagePayloadAttachment } from "@jugl-web/rest-api";
import { getChatMessageAttachmentUrls } from "@web-src/modules/chats/utils/getChatMessageAttachmentUrls";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";

export const AudioAttachmentItem: React.FC<{
  senderUserId: string;
  attachment: ChatMessagePayloadAttachment;
  date: string;
}> = ({ senderUserId, attachment, date }) => {
  const { entity } = useEntity();
  if (!entity) {
    throw new Error("Selected entity is required");
  }
  const { stream } = getChatMessageAttachmentUrls(entity.id, attachment);
  const { profile } = useUserGeneralProfile({
    entityId: entity?.id,
    userId: senderUserId,
  });
  const { dateLocale } = useLanguage();

  return (
    <CommonAudioPlayer url={stream}>
      <div className="flex flex-col gap-2">
        <span className="text-dark truncate font-medium">
          {attachment.name}
        </span>
        <div className="text-grey flex items-center gap-1 text-xs font-medium">
          <span className="truncate">{profile?.displayName}</span>
          <span>•</span>
          <span className="shrink-0">
            {format(new Date(date), "MMM dd, hh:mm a", { locale: dateLocale })}
          </span>
        </div>
      </div>
    </CommonAudioPlayer>
  );
};
