import { Menu, PlainButton, Tooltip } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks";
import React, { useCallback } from "react";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useUserRolePermissionWarning } from "@jugl-web/domain-resources/users/hooks/useUserRolePermissionWarning";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";

export const CallHistoryMenu: React.FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslations();
  const { call } = useCallInvitation();
  const { entityId } = useEntitySelectedProvider();
  const getUserRolePermissionWarning = useUserRolePermissionWarning();

  const handleStartCall = useCallback(() => {
    call({ callType: "audio", userId });
  }, [call, userId]);

  const { profile } = useUserGeneralProfile({
    entityId,
    userId,
  });

  const hasCallsPermission =
    profile && hasRoleAccessToModule(profile.role, AppModule.calls);

  return (
    <div className="flex w-full items-center justify-center">
      {hasCallsPermission ? (
        <Menu
          placement="bottom-end"
          autoClose
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger as={PlainButton} ref={triggerRef}>
              <MoreIcon />
            </Trigger>
          )}
          className="py-2"
          sections={[
            [
              {
                id: "start-call",
                label: t({
                  id: "calls-page.start-call",
                  defaultMessage: "Start call",
                }),
                onSelect: handleStartCall,
              },
            ],
          ]}
        />
      ) : (
        <Tooltip
          renderTrigger={({ props, ref }) => <InfoIcon ref={ref} {...props} />}
        >
          {getUserRolePermissionWarning(AppModule.calls)}
        </Tooltip>
      )}
    </div>
  );
};
