import { PaginatedResponse, RtkEmptySplitApi } from "../../types";
import {
  DetailedDashboardDto,
  DetailedDashboardModel,
  transformDetailedDashboardDtoToModel,
} from "./models/DetailedDashboard";
import {
  PreviewDashboardDto,
  PreviewDashboardModel,
  transformPreviewDashboardDtoToModel,
} from "./models/PreviewDashboard";
import {
  transformWidgetDtoToModel,
  WidgetDto,
  WidgetModel,
} from "./models/Widget";
import {
  transformWidgetDataDtoToModel,
  WidgetDataDto,
  WidgetDataModel,
} from "./models/WidgetData";
import { DashboardApiTags } from "./tags";
import {
  CreateDashboardAttributes,
  CreateWidgetAttributes,
  GetWidgetDataParams,
  UpdateDashboardAttributes,
  UpdateWidgetAttributes,
} from "./types";

export const addDashboardApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [DashboardApiTags.dashboard, DashboardApiTags.widget],
  });

  const dashboardsApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      // #region Dashboards
      getDashboardList: builder.query<
        PaginatedResponse<PreviewDashboardModel>,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/dashboard`,
          params: {
            page: 1,
            page_size: 50,
          },
        }),
        transformResponse: (
          response: PaginatedResponse<PreviewDashboardDto>
        ) => ({
          ...response,
          data: response.data.map(transformPreviewDashboardDtoToModel),
        }),
        providesTags: [{ type: DashboardApiTags.dashboard, id: "LIST" }],
      }),

      getDashboard: builder.query<
        DetailedDashboardModel,
        { entityId: string; dashboardId: string }
      >({
        query: ({ entityId, dashboardId }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}`,
        }),
        transformResponse: (response: DetailedDashboardDto) =>
          transformDetailedDashboardDtoToModel(response),
        providesTags: (_result, _error, { dashboardId }) => [
          { type: DashboardApiTags.dashboard, id: dashboardId },
        ],
      }),

      createDashboard: builder.mutation<
        PreviewDashboardModel,
        { entityId: string; attributes: CreateDashboardAttributes }
      >({
        query: ({ entityId, attributes }) => ({
          url: `/api/auth/${entityId}/dashboard`,
          method: "POST",
          data: attributes,
        }),
        transformResponse: (response: PreviewDashboardDto) =>
          transformPreviewDashboardDtoToModel(response),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: newDashboard } = await queryFulfilled;

            const updateDashboardListAction =
              dashboardsApi.util.updateQueryData(
                "getDashboardList",
                { entityId: args.entityId },
                (state) => {
                  state.data.push(newDashboard);
                }
              );

            dispatch(updateDashboardListAction);
          } catch {
            // Do nothing
          }
        },
      }),

      updateDashboard: builder.mutation<
        PreviewDashboardModel,
        {
          entityId: string;
          dashboardId: string;
          attributes: UpdateDashboardAttributes;
        }
      >({
        query: ({ entityId, dashboardId, attributes }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}`,
          method: "PATCH",
          data: attributes,
        }),
        transformResponse: (response: PreviewDashboardDto) =>
          transformPreviewDashboardDtoToModel(response),
        invalidatesTags: (_result, _error, { dashboardId }) => [
          { type: DashboardApiTags.dashboard, id: "LIST" },
          { type: DashboardApiTags.dashboard, id: dashboardId },
        ],
      }),

      deleteDashboard: builder.mutation<
        void,
        { entityId: string; dashboardId: string }
      >({
        query: ({ entityId, dashboardId }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}`,
          method: "DELETE",
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;

            const updateDashboardListAction =
              dashboardsApi.util.updateQueryData(
                "getDashboardList",
                { entityId: args.entityId },
                (state) => {
                  const deletedDashboardIndex = state.data.findIndex(
                    (dashboard) => dashboard.id === args.dashboardId
                  );

                  if (deletedDashboardIndex > -1) {
                    state.data.splice(deletedDashboardIndex, 1);
                  }
                }
              );

            dispatch(updateDashboardListAction);
          } catch {
            // Do nothing
          }
        },
      }),
      // #endregion

      // #region Widgets
      getWidgetData: builder.query<
        WidgetDataModel,
        {
          entityId: string;
          dashboardId: string;
          widgetId: string;
          params: GetWidgetDataParams;
        }
      >({
        query: ({ entityId, dashboardId, widgetId, params }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}/widget/${widgetId}`,
          params,
        }),
        transformResponse: (response: WidgetDataDto) =>
          transformWidgetDataDtoToModel(response),
        providesTags: (_result, _error, { widgetId }) => [
          { type: DashboardApiTags.widget, id: widgetId },
        ],
      }),

      createWidget: builder.mutation<
        WidgetModel,
        {
          entityId: string;
          dashboardId: string;
          attributes: CreateWidgetAttributes;
        }
      >({
        query: ({ entityId, dashboardId, attributes }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}/widget`,
          method: "POST",
          data: {
            ...attributes,
            filters: {},
            type: "chart",
            data_src: "task",
          },
        }),
        transformResponse: (response: WidgetDto) =>
          transformWidgetDtoToModel(response),
        invalidatesTags: (_result, _error, { dashboardId }) => [
          { type: DashboardApiTags.dashboard, id: dashboardId },
        ],
      }),

      updateWidget: builder.mutation<
        WidgetModel & { data: WidgetDataModel },
        {
          entityId: string;
          dashboardId: string;
          widgetId: string;
          attributes: UpdateWidgetAttributes;
          getWidgetDataParams: GetWidgetDataParams;
        }
      >({
        query: ({
          entityId,
          dashboardId,
          widgetId,
          attributes,
          getWidgetDataParams,
        }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}/widget/${widgetId}`,
          params: getWidgetDataParams,
          method: "PUT",
          data: {
            ...attributes,
            type: "chart",
            data_src: "task",
          },
        }),
        transformResponse: (response: WidgetDto & { data: WidgetDataDto }) => {
          const { data: widgetDataDto, ...widgetDto } = response;

          const widgetModel = transformWidgetDtoToModel(widgetDto);
          const widgetDataModel = transformWidgetDataDtoToModel(widgetDataDto);

          return { ...widgetModel, data: widgetDataModel };
        },
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled;

            const { data: newWidgetData, ...newWidget } = response.data;

            const updateWidgetConfigAction = dashboardsApi.util.updateQueryData(
              "getDashboard",
              { entityId: args.entityId, dashboardId: args.dashboardId },
              (state) => {
                const matchingWidgetIndex = state.widgets.findIndex(
                  (w) => w.id === args.widgetId
                );

                if (matchingWidgetIndex === -1) {
                  return state;
                }

                state.widgets[matchingWidgetIndex] = newWidget;

                return state;
              }
            );

            const updateWidgetDataAction = dashboardsApi.util.updateQueryData(
              "getWidgetData",
              {
                entityId: args.entityId,
                dashboardId: args.dashboardId,
                widgetId: args.widgetId,
                params: args.getWidgetDataParams,
              },
              () => newWidgetData
            );

            dispatch(updateWidgetConfigAction);
            dispatch(updateWidgetDataAction);
          } catch {
            // Do nothing
          }
        },
      }),

      deleteWidget: builder.mutation<
        void,
        { entityId: string; dashboardId: string; widgetId: string }
      >({
        query: ({ entityId, dashboardId, widgetId }) => ({
          url: `/api/auth/${entityId}/dashboard/${dashboardId}/widget/${widgetId}`,
          method: "DELETE",
        }),
        invalidatesTags: (_result, _error, { dashboardId }) => [
          { type: DashboardApiTags.dashboard, id: dashboardId },
        ],
      }),
      // #endregion
    }),

    overrideExisting: false,
  });

  return dashboardsApi;
};

export type DashboardApi = ReturnType<typeof addDashboardApi>;
