import { taskNameValidator } from "@jugl-web/domain-resources/tasks";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { Tooltip } from "@jugl-web/ui-components";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { cx, useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { FC, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { ReactComponent as OrderIcon } from "../../assets/order.svg";
import { ReactComponent as RecurringIcon } from "../../assets/recurring.svg";
import { ReactComponent as ScheduleIcon } from "../../assets/schedule.svg";
import { TaskTableCellComponentProps } from "../../types";
import { TaskNameCellContextMenu } from "../TaskNameCellContextMenu";
import { TaskTableLayout } from "../TaskTableLayout";
import { TextCellEditor, TextCellEditorHandle } from "../TextCellEditor";

export const TaskNameCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  permissions,
  isFutureTask,
  searchQuery,
  cellProps,
  onUpdate,
  onPreview,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const { tasksSource, openFutureTaskDialog, navigateToTaskDetailsPage } =
    useTasksPageContext();

  const { tasksApi } = useRestApiProvider();
  const [deleteTask] = tasksApi.useDeleteTaskMutation();

  const $textCellEditorRef = useRef<TextCellEditorHandle | null>(null);

  const { t } = useTranslations();

  const isRecurring = !!task.recurrence;
  const isFromOrder = !!task.order_id;

  const highlightedTaskName = (
    <>
      {searchQuery ? (
        <Highlighter
          autoEscape
          highlightClassName="text-primary bg-primary-50"
          highlightTag="span"
          textToHighlight={task.name}
          searchWords={[searchQuery]}
        />
      ) : (
        task.name
      )}
    </>
  );

  const handleCellClick = () => {
    if (isEditing) {
      $textCellEditorRef.current?.focus();
      return;
    }

    if (isFutureTask) {
      openFutureTaskDialog(task);
      return;
    }

    navigateToTaskDetailsPage(task.id);
  };

  const handleTaskPreviewClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onPreview(task.id);
  };

  const handleMenuButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const handleSubmit = (richText: string) => {
    onUpdate({ name: richText });
    setIsEditing(false);
  };

  useLayoutEffect(() => {
    if (isEditing) {
      $textCellEditorRef.current?.setContent(task.name);
    }
  }, [isEditing, task.name]);

  return (
    <Tooltip
      placement="bottom"
      renderTrigger={({ ref, props }) => (
        <TaskTableLayout.Cell
          ref={ref}
          isFocused={isEditing}
          isHoverable
          className={cx(
            "group justify-between gap-2",
            isEditing && "cursor-text"
          )}
          onClick={handleCellClick}
          {...cellProps}
          {...props}
        >
          {isEditing ? (
            <TextCellEditor
              ref={$textCellEditorRef}
              placeholder={t({
                id: "form-controls.task-title.placeholder-extended",
                defaultMessage: "Enter task title",
              })}
              validator={taskNameValidator}
              onSubmit={handleSubmit}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <>
              <div className="flex items-center gap-2 overflow-hidden">
                {isFromOrder && <OrderIcon className="shrink-0" />}
                {isRecurring && (
                  <>
                    {isFutureTask ? (
                      <ScheduleIcon className="shrink-0" />
                    ) : (
                      <RecurringIcon className="shrink-0" />
                    )}
                  </>
                )}
                <TaskTableLayout.CellText className="flex-grow font-medium">
                  {highlightedTaskName}
                </TaskTableLayout.CellText>
                {isFromOrder && (
                  <span className="shrink-0 text-xs tracking-[0.12px] text-[#828282]">
                    {task.order_id}
                  </span>
                )}
              </div>
              {!isFutureTask && (
                <div className="flex">
                  <button
                    className={cx(
                      "font-secondary mr-1 hidden cursor-pointer items-center gap-2.5 rounded-lg border-0 bg-[#eceff0] py-2 px-2 text-[#737b7e] transition-colors group-hover:flex"
                    )}
                    onClick={handleTaskPreviewClick}
                    type="button"
                  >
                    {t({
                      id: "task-preview-button.title",
                      defaultMessage: "Preview",
                    })}
                  </button>
                  <TaskNameCellContextMenu
                    entityId={entityId}
                    permissions={permissions}
                    task={task}
                    onEditTaskName={() => setIsEditing(true)}
                    onUpdateTask={onUpdate}
                    onDeleteTask={() =>
                      deleteTask({
                        entityId,
                        taskId: task.id,
                        source: tasksSource,
                      })
                    }
                    renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                      <Trigger
                        ref={triggerRef}
                        as={PlainButton}
                        className={cx(
                          "h-[34px] w-[34px] items-center justify-center rounded transition-colors",
                          isOpen
                            ? "bg-grey/5 flex"
                            : "hover:bg-grey/5 hidden group-hover/cell:flex"
                        )}
                        onClick={handleMenuButtonClick}
                      >
                        <MenuIcon />
                      </Trigger>
                    )}
                  />
                </div>
              )}
            </>
          )}
        </TaskTableLayout.Cell>
      )}
      className="max-w-[424px]"
    >
      {highlightedTaskName}
    </Tooltip>
  );
};
