import { cx, useTranslations } from "@jugl-web/utils";
import { forwardRef } from "react";
import { ReactComponent as ClearIcon } from "./icons/clear.svg";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { BaseInputProps } from "./types";
import { PlainButton } from "../PlainButton";

interface FilledSearchInputProps extends BaseInputProps {
  color: "primary" | "grey" | "white";
}

const colorToClasses: Record<FilledSearchInputProps["color"], string> = {
  primary:
    "bg-primary/5 font-medium text-[#333333] placeholder:text-grey placeholder:font-normal",
  grey: "bg-grey-100 font-medium text-[#333333] placeholder:text-grey placeholder:font-normal",
  white:
    "bg-white font-medium text-[#333333] placeholder:text-grey placeholder:font-normal",
};

export const FilledSearchInput = forwardRef<
  HTMLInputElement,
  FilledSearchInputProps
>(
  (
    {
      color,
      alwaysShowClear,
      className,
      containerClassName,
      hasSettingsIndicator,
      onSettingsClick,
      onClear,
      ...inputProps
    },
    ref
  ) => {
    const { t } = useTranslations();

    const isClearButtonVisible =
      (onClear && inputProps.value) || alwaysShowClear || onSettingsClick;

    return (
      <div className={cx("relative", containerClassName)}>
        <SearchIcon className="pointer-events-none absolute left-4 top-1/2 -translate-y-1/2" />
        <input
          type="text"
          placeholder={`${t({
            id: "common.search",
            defaultMessage: "Search",
          })}...`}
          className={cx(
            "h-10 w-full rounded-lg border-none pl-11 pr-4 text-base leading-3 outline-none",
            onSettingsClick ? "pr-20" : isClearButtonVisible ? "pr-12" : "pr-4",
            colorToClasses[color],
            className
          )}
          {...inputProps}
          ref={ref}
        />
        {(onSettingsClick || isClearButtonVisible) && (
          <div
            className={cx(
              "absolute top-1/2 right-4 flex -translate-y-1/2 items-center gap-2.5"
            )}
          >
            {onSettingsClick && (
              <PlainButton onClick={onSettingsClick} className="relative">
                <SettingsIcon />
                {hasSettingsIndicator && (
                  <div className="bg-gradients-danger absolute -top-px -right-px h-2 w-2 rounded-full border border-solid border-white" />
                )}
              </PlainButton>
            )}
            {isClearButtonVisible && (
              <PlainButton>
                <ClearIcon onClick={onClear} />
              </PlainButton>
            )}
          </div>
        )}
      </div>
    );
  }
);
