import orderBy from "lodash/orderBy";
import { ChatMessageModel } from "@jugl-web/rest-api";
import { MessageReactionSummary } from "./types";

export const getMessageReactionsSummary = (message: ChatMessageModel) => {
  const result: {
    [key: string]: MessageReactionSummary;
  } = {};
  message?.reactions?.forEach((item) => {
    if (!result[item.reaction]) {
      result[item.reaction] = {
        users: [],
        count: 0,
        reaction: item.reaction,
      };
    }
    result[item.reaction].users.push(item.user_id);
    result[item.reaction].count += 1;
  });
  return orderBy(Object.values(result), "count", ["desc"]);
};
