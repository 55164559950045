import { INITIAL_FILTERS_STATE } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { WidgetModel } from "@jugl-web/rest-api/dashboard/models/Widget";
import { UpdateWidgetAttributes } from "@jugl-web/rest-api/dashboard/types";
import {
  adaptInternalTaskFiltersToTaskFilters,
  adaptTaskFiltersToInternalTaskFilters,
  InternalTaskFilters,
} from "@jugl-web/rest-api/tasks";
import {
  DRAWER_TRANSITION_DURATION_MS,
  LoadingAnimation,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { assert, cx, useTranslations } from "@jugl-web/utils";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import isEqual from "lodash/isEqual";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { useDashboardWidgetChartConfig } from "../../hooks/useDashboardWidgetChartConfig";
import { useDashboardWidgetData } from "../../hooks/useDashboardWidgetData";
import { WidgetDialogState } from "../../types";
import { DashboardWidgetChart } from "../DashboardWidgetChart";
import { DashboardWidgetChartErrorBoundary } from "../DashboardWidgetChartErrorBoundary";
import { DashboardWidgetDeleteConfirmationDialog } from "../DashboardWidgetDeleteConfirmationDialog";
import { DashboardWidgetDialogSettingsSidebar } from "../DashboardWidgetDialogSettingsSidebar";
import { DashboardWidgetFiltersDialog } from "../DashboardWidgetFiltersDialog";
import { ReactComponent as ChartIcon } from "./assets/chart.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import { ReactComponent as ResetFiltersIcon } from "./assets/reset-filters.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import { ReactComponent as TrashIcon } from "./assets/trash.svg";
import styles from "./DashboardWidgetDialog.module.css";

interface DashboardWidgetDialogProps extends WidgetDialogState {
  onClose: () => void;
}

export const DashboardWidgetDialog: FC<DashboardWidgetDialogProps> = (
  props
) => {
  const { isOpen, widgetId, onClose } = props;

  const { detailedDashboard } = useDashboardContext();

  const { getChartXAxisItemById, getChartYAxisItemById } =
    useDashboardWidgetChartConfig();

  const matchingWidget = useMemo(() => {
    if (!detailedDashboard) {
      return null;
    }

    const foundWidget = detailedDashboard.widgets.find(
      (widget) => widget.id === widgetId
    );

    return foundWidget ?? null;
  }, [detailedDashboard, widgetId]);

  const xAxisName = matchingWidget
    ? getChartXAxisItemById(matchingWidget.config.x_axis)?.name
    : null;

  const yAxisName = matchingWidget
    ? getChartYAxisItemById(matchingWidget.config.series[0].y_axis)?.name
    : null;

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        type: "title",
        title: matchingWidget ? (
          <div className="flex items-center gap-3">
            <ChartIcon className="shrink-0" />
            <span className="font-secondary truncate text-lg font-semibold text-[#383838]">
              {matchingWidget.title}
            </span>
          </div>
        ) : null,
        subtitle: matchingWidget ? (
          <span className="font-secondary text-sm tracking-[0.14px] text-[#828282]">
            {xAxisName} / {yAxisName}
          </span>
        ) : null,
      }}
      className="left-12 right-12 h-[95%] w-auto translate-x-0"
      onClose={onClose}
    >
      {matchingWidget && (
        <DashboardWidgetDialogContent {...props} widget={matchingWidget} />
      )}
    </BottomCenteredDrawer>
  );
};

interface DashboardWidgetDialogContentProps
  extends Omit<DashboardWidgetDialogProps, "widgetId"> {
  widget: WidgetModel;
}

const DashboardWidgetDialogContent: FC<DashboardWidgetDialogContentProps> = ({
  widget,
  afterOpenAction,
  onClose: onDialogClose,
}) => {
  const { entityId } = useEntitySelectedProvider();
  const { detailedDashboard, isDashboardOwner } = useDashboardContext();

  assert(!!detailedDashboard, "Dashboard has to be loaded at this point");

  const [isSettingsSidebarOpen, setIsSettingsSidebarOpen] = useState(
    afterOpenAction === "open-settings"
  );

  const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);

  const deleteConfirmationDialogState = useConfirmationDialogState<{
    widget: WidgetModel;
  }>();

  const { dashboardApi } = useRestApiProvider();

  const lastUpdateRequestRef = useRef<ReturnType<typeof updateWidget> | null>();

  const {
    data: widgetData,
    isLoading: isWidgetDataLoading,
    params: getWidgetDataParams,
  } = useDashboardWidgetData({
    entityId,
    dashboardId: detailedDashboard.id,
    widgetId: widget.id,
  });

  const [updateWidget, { isLoading: isUpdatingWidget }] =
    dashboardApi.useUpdateWidgetMutation();

  const { t } = useTranslations();

  const canManageWidget = useMemo(
    () => (detailedDashboard ? isDashboardOwner(detailedDashboard) : false),
    [detailedDashboard, isDashboardOwner]
  );

  const adaptedWidgetFilters = useMemo(
    () => adaptTaskFiltersToInternalTaskFilters(widget.filters),
    [widget.filters]
  );

  const hasActiveFilter = useMemo(
    () => !isEqual(adaptedWidgetFilters, INITIAL_FILTERS_STATE),
    [adaptedWidgetFilters]
  );

  const handleUpdateWidget = (attributes: UpdateWidgetAttributes) => {
    if (lastUpdateRequestRef.current) {
      lastUpdateRequestRef.current.abort();
    }

    const request = updateWidget({
      entityId,
      dashboardId: detailedDashboard.id,
      widgetId: widget.id,
      attributes,
      getWidgetDataParams,
    });

    lastUpdateRequestRef.current = request;

    request.finally(() => {
      lastUpdateRequestRef.current = null;
    });
  };

  const handleUpdateWidgetFilters = (updatedFilters: InternalTaskFilters) => {
    handleUpdateWidget({
      filters: adaptInternalTaskFiltersToTaskFilters(updatedFilters),
    });
  };

  useEffect(() => {
    if (afterOpenAction === "open-filters") {
      window.setTimeout(() => {
        setIsFiltersDialogOpen(true);
      }, DRAWER_TRANSITION_DURATION_MS / 2);
    }
  }, [afterOpenAction]);

  return (
    <>
      <BottomCenteredDrawer.Content className="flex flex-col">
        <TasksPageLayout.ControlBar>
          <div className="flex w-full items-center gap-4">
            {(canManageWidget || hasActiveFilter) && (
              <ControlBarButton
                startSlot={
                  <span className="relative">
                    <FilterIcon />
                    {hasActiveFilter && (
                      <div
                        className={cx(
                          "bg-gradients-success absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-[1.5px] border-solid border-white"
                        )}
                      />
                    )}
                  </span>
                }
                endSlot={
                  hasActiveFilter && canManageWidget ? (
                    <PlainButton
                      className="hover:bg-grey-300 rounded-full bg-[#EEF2F5] transition-colors"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleUpdateWidgetFilters(INITIAL_FILTERS_STATE);
                      }}
                    >
                      <ResetFiltersIcon />
                    </PlainButton>
                  ) : undefined
                }
                isActive={isFiltersDialogOpen || hasActiveFilter}
                label={t({
                  id: "dashboard-page.filters",
                  defaultMessage: "Filters",
                })}
                onClick={() => setIsFiltersDialogOpen(true)}
              />
            )}
            <div className="grow" />
            {canManageWidget && (
              <>
                <ControlBarButton
                  startSlot={
                    <SettingsIcon
                      className={
                        isSettingsSidebarOpen
                          ? "text-messages-chatBubbleSender"
                          : "text-dark-500"
                      }
                    />
                  }
                  onClick={() => {
                    setIsSettingsSidebarOpen((prev) => !prev);
                  }}
                  isActive={isSettingsSidebarOpen}
                />
                <Menu
                  placement="bottom-start"
                  sections={[
                    [
                      {
                        id: "delete",
                        label: t({
                          id: "dashboard-page.delete-chart",
                          defaultMessage: "Delete chart",
                        }),
                        icon: <TrashIcon />,
                        className: "font-secondary",
                        onSelect: (_, close) => {
                          deleteConfirmationDialogState.open({
                            metadata: { widget },
                          });
                          close();
                        },
                      },
                    ],
                  ]}
                  renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                    <Trigger
                      ref={triggerRef}
                      as={ControlBarButton}
                      startSlot={<MenuIcon />}
                      isActive={isOpen}
                    />
                  )}
                />
              </>
            )}
          </div>
        </TasksPageLayout.ControlBar>
        <div className="flex max-w-full flex-1 overflow-hidden">
          <div className="grow bg-white p-4">
            {/* To make HighChart chart resizable in the flex container, 
          it has to be rendered in the absolutely positioned container,
          therefore the relatively positioned wrapper is needed.
          For ref: https://stackoverflow.com/a/19367079 */}
            <div
              className={cx(
                styles.dashboardWidgetDialogContent,
                "relative h-full w-full"
              )}
            >
              {isWidgetDataLoading || !widgetData ? (
                <div className="flex h-full w-full items-center justify-center">
                  <LoadingAnimation size="2xl" />
                </div>
              ) : (
                <>
                  <div
                    className={cx(
                      "absolute inset-0 z-20 flex items-center justify-center bg-white/80 transition-opacity",
                      isUpdatingWidget
                        ? "opacity-100"
                        : "pointer-events-none opacity-0"
                    )}
                  >
                    <LoadingAnimation size="2xl" />
                  </div>
                  <DashboardWidgetChartErrorBoundary>
                    <DashboardWidgetChart
                      widgetConfig={widget.config}
                      widgetData={widgetData}
                      backgroundColor="white"
                      containerClassName="absolute z-10"
                    />
                  </DashboardWidgetChartErrorBoundary>
                </>
              )}
            </div>
          </div>
          <DashboardWidgetDialogSettingsSidebar
            isOpen={isSettingsSidebarOpen}
            widget={widget}
            onUpdateWidget={handleUpdateWidget}
          />
        </div>
      </BottomCenteredDrawer.Content>
      <DashboardWidgetFiltersDialog
        isOpen={isFiltersDialogOpen}
        isReadonly={!canManageWidget}
        filters={adaptedWidgetFilters}
        onChangeFilters={handleUpdateWidgetFilters}
        onClose={() => setIsFiltersDialogOpen(false)}
      />
      <DashboardWidgetDeleteConfirmationDialog
        isOpen={deleteConfirmationDialogState.isOpen}
        widget={deleteConfirmationDialogState.metadata?.widget || null}
        onClose={deleteConfirmationDialogState.close}
        onAfterDelete={onDialogClose}
      />
    </>
  );
};
