import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { ConditionallyRenderedPortal } from "@jugl-web/utils/utils/ConditionallyRenderedPortal";
import { FC, ReactNode } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as ReorderIcon } from "./assets/reorder.svg";

export interface DraggableFieldBoxContainerProps {
  id: string;
  index: number;
  children: ReactNode;
  isDragDisabled?: boolean;
  className?: string;
}

export const DraggableFieldBoxContainer: FC<
  DraggableFieldBoxContainerProps
> = ({ id, index, children, isDragDisabled, className }) => (
  <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
    {(provided, snapshot) => (
      <ConditionallyRenderedPortal isEnabled={snapshot.isDragging}>
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={cx("relative", className)}
        >
          {!isDragDisabled && (
            <PlainButton
              className="absolute -left-0.5 top-1/2 -translate-x-full -translate-y-1/2 cursor-grab"
              onClick={(event) => event.stopPropagation()}
            >
              <ReorderIcon />
            </PlainButton>
          )}
          {children}
        </div>
      </ConditionallyRenderedPortal>
    )}
  </Draggable>
);
