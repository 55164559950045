import { Checkbox, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx, onEnter } from "@jugl-web/utils";
import { Highlightify } from "@jugl-web/utils/utils/Highlightify";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { mentionifyString } from "@jugl-web/utils/utils/Mentionify";
import { FC, MouseEvent } from "react";
import { AssigneePickerButton } from "./AssigneePickerButton";
import { DueDatePickerButton } from "./DueDatePickerButton";
import { TaskChecklistItemContainer } from "./TaskChecklistItemContainer";
import { TaskChecklistItemNumber } from "./TaskChecklistItemNumber";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { TaskChecklistItemMenuPopover } from "./components/TaskChecklistItemMenuPopover";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import { TaskChecklistItem } from "./types";
import { SpecificOrderCompletionState } from "./utils";

export interface ViewTaskChecklistItemProps {
  item: TaskChecklistItem;
  index: number;
  specificOrderCompletionState: SpecificOrderCompletionState;
  onUpdate: (updatedItem: TaskChecklistItem) => void;
  onToggleComplete: () => void;
  onEditModeChange: (isEditing: boolean) => void;
  onDelete: (itemId: string) => void;
}

export const ViewTaskChecklistItem: FC<ViewTaskChecklistItemProps> = ({
  item,
  index,
  specificOrderCompletionState,
  onUpdate,
  onToggleComplete,
  onEditModeChange,
  onDelete,
}) => {
  const {
    entityId,
    displayDueDateAs,
    searchQuery,
    onlyReportees,
    isManageable,
    isCompletable,
    isAssignable,
    isSelectMode,
    selectedChecklistIds,
    onSelectedChecklistIdsChange,
    canEditItem,
    onTriggerDeleteConfirmationDialog,
  } = useTaskChecklistContext();

  const handleClick = (event: MouseEvent) => {
    if (isSelectMode) {
      if (isItemSelected) {
        onSelectedChecklistIdsChange(
          selectedChecklistIds.filter((itemId) => itemId !== item.id)
        );
        return;
      }
      onSelectedChecklistIdsChange([...selectedChecklistIds, item.id]);
      return;
    }

    if (isItemEditable) {
      const isLink = !!(event.target as HTMLElement).closest("a");

      if (!isLink) {
        onEditModeChange(true);
      }
    }
  };

  const handleDeleteWithConfirmation = (itemId: string) => {
    onTriggerDeleteConfirmationDialog({
      onConfirm: () => onDelete(itemId),
      metadata: { item },
    });
  };

  const isItemEditable = isManageable || canEditItem(item);
  const isItemSelected = selectedChecklistIds.includes(item.id);

  return (
    <TaskChecklistItemContainer
      className={cx(
        "relative flex items-center transition-all duration-300",
        (isItemEditable || isSelectMode) &&
          "hover:shadow-task-checklist-item cursor-pointer",
        isItemSelected &&
          isSelectMode &&
          "bg-grey-200 drop-shadow-[-4px_0px_0px_rgb(31,135,229)]"
      )}
      tabIndex={isItemEditable ? 0 : -1}
      onClick={handleClick}
      onKeyDown={
        isItemEditable && !isSelectMode
          ? onEnter(() => onEditModeChange(true))
          : undefined
      }
    >
      <TaskChecklistItemNumber
        index={index}
        isHighlighted={specificOrderCompletionState.isCurrent}
        className="mr-4"
      />
      <div className="flex min-w-[40%] grow flex-col gap-1.5 self-center">
        <div
          className={cx(
            "whitespace-pre-wrap break-words text-sm leading-[21px]",
            item.isCompleted
              ? "font-normal text-[#828282] line-through"
              : "font-normal text-black"
          )}
        >
          <Linkify>
            <Highlightify
              highlightClassName="text-primary bg-primary-50 font-semibold"
              searchWord={searchQuery}
            >
              {mentionifyString(item.text)}
            </Highlightify>
          </Linkify>
        </div>
        {(item.assigneeId || item.dueDate) && (
          <div className="flex items-center gap-3">
            {isAssignable && item.assigneeId && (
              <AssigneePickerButton
                itemMode="normal"
                isDisabled={!isItemEditable || isSelectMode}
                entityId={entityId}
                assigneeId={item.assigneeId}
                onlyReportees={onlyReportees}
                onAssigneeIdChange={(assigneeId) =>
                  onUpdate({ ...item, assigneeId })
                }
              />
            )}
            {item.dueDate && (
              <DueDatePickerButton
                itemMode="normal"
                displayAs={displayDueDateAs}
                isDisabled={!isItemEditable || isSelectMode}
                dueDate={item.dueDate}
                onDueDateChange={(dueDate) => onUpdate({ ...item, dueDate })}
              />
            )}
          </div>
        )}
      </div>
      <div className="ml-2 flex items-center gap-4">
        {isManageable && !isSelectMode && (
          <div className="leading-[0]">
            <TaskChecklistItemMenuPopover
              placement="left-start"
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={PlainButton}
                  className={cx(
                    "hover:bg-grey-200 shrink-0 rounded-full transition-colors",
                    isOpen && "bg-grey-200"
                  )}
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuIcon />
                </Trigger>
              )}
              itemId={item.id}
              onDelete={handleDeleteWithConfirmation}
            />
          </div>
        )}
        {isCompletable && isItemEditable && (
          <Checkbox
            isChecked={item.isCompleted}
            isDisabled={specificOrderCompletionState.isDisabled || isSelectMode}
            onClick={(event) => event.stopPropagation()}
            onKeyDown={onEnter((event) => {
              event.stopPropagation();
              onToggleComplete();
            })}
            onChange={onToggleComplete}
          />
        )}
      </div>
    </TaskChecklistItemContainer>
  );
};
