import { Alert, AlertProps } from "@jugl-web/ui-components";
import { FC, useMemo } from "react";
import Lottie from "react-lottie";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import animationData from "./assets/animation.json";

export type PlanLimitsReachedAlertProps = {
  isOpen: boolean;
  type?: "orderForms" | "clientUpdates" | "tasks" | "default" | "importTasks";
  isAdmin?: boolean;
  onRequestClose: () => void;
};

export const PlanLimitsReachedAlert: FC<PlanLimitsReachedAlertProps> = ({
  isOpen,
  type,
  isAdmin,
  onRequestClose,
}) => {
  const { navigateToPage } = useNavigation();
  let $content = (
    <div>Update your plan to be able to use all Features unlimited✨</div>
  );
  if (type === "orderForms") {
    $content = (
      <div>
        <div>
          Looks like there is no more free Order form creation. You already
          created <span className="text-red-500">1/1 Order Form</span>, based on
          your plan.
        </div>
        <div className="text-primary-500 mt-4  text-sm">
          Update your plan to be able to use all Features unlimited✨
        </div>
      </div>
    );
  } else if (type === "clientUpdates") {
    $content = (
      <div>
        <div>
          Looks like there is no more free Task shares. You already shared{" "}
          <span className="text-red-500">100/100 Tasks</span>, based on your
          plan.
        </div>
        <div className="text-primary-500 mt-4  text-sm">
          Update your plan to be able to use all Features unlimited✨
        </div>
      </div>
    );
  } else if (type === "tasks") {
    $content = (
      <div>
        <div>
          Please pay attention that this month You already created{" "}
          <span className="text-red-500">100/100 Tasks</span>. Based on your
          plan, you have reached the limit, please reach out to support to
          extend the capacity.
        </div>
        <div className="text-primary-500 mt-4 text-sm">
          Update your plan to be able to use all Features unlimited✨
        </div>
      </div>
    );
  } else if (type === "importTasks") {
    $content = (
      <div>
        <div>
          Please pay attention that this month You already imported{" "}
          <span className="text-red-500">100/100 Tasks</span> to the Workspace.
          Based on your plan, you have reached the limit, please reach out to
          support to extend the capacity.
        </div>
        <div className="text-primary-500 mt-4 text-sm">
          Update your plan to be able to use all Features unlimited✨
        </div>
      </div>
    );
  }

  const buttons = useMemo(() => {
    const result: AlertProps["buttons"] = [
      {
        text: "Okay",
        onClick: onRequestClose,
      },
    ];
    if (isAdmin) {
      result.push({
        text: "See Other Plans",
        variant: "text",
        onClick: () => {
          navigateToPage("workspaceSelectSubscription");
          onRequestClose();
        },
      });
    }
    return result;
  }, [isAdmin, navigateToPage, onRequestClose]);

  return (
    <Alert
      isOpen={isOpen}
      img={<Lottie options={{ animationData }} height={240} width={240} />}
      onRequestClose={onRequestClose}
      content={$content}
      buttons={buttons}
      buttonsAlignment="vertical"
      header="Subscription"
      title="You reached plan limits"
    />
  );
};
