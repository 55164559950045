import { UserRole } from "@jugl-web/rest-api";

interface CanManageTemplateOptions {
  role: UserRole;
  meId: string;
  templateCreatorId?: string;
}

export const canManageTemplate = ({
  role,
  meId,
  templateCreatorId,
}: CanManageTemplateOptions) =>
  role === UserRole.admin ||
  role === UserRole.taskManager ||
  meId === templateCreatorId;
