import { shortenTaskTitle } from "@jugl-web/domain-resources/tasks";
import { ManageTaskFieldsDialog } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog";
import { ManageTaskFieldsDialogScreenToParametersMap } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog/types";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { Screen } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { ManageFieldsTopBarButton } from "@web-src/components/ManageFieldsTopBarButton";
import { useTaskBreadcrumbs } from "@web-src/hooks/useTaskBreadcrumbs";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { FC, useMemo, useState } from "react";
import { useTaskDetailsContext } from "../../hooks/useTaskDetailsContext";

export const TaskDetailsTopBar: FC = () => {
  const { task, isTaskLoading, tasksSource } = useTaskDetailsContext();
  const { hasEmployeeLikeRole } = useUserRole();

  const [manageFieldsDialogScreen, setManageFieldsDialogScreen] =
    useState<Screen<ManageTaskFieldsDialogScreenToParametersMap> | null>(null);

  const isManageFieldsDialogOpen = !!manageFieldsDialogScreen;

  const initialManageFieldsDialogScreen = useMemo(
    () => manageFieldsDialogScreen || ({ name: "entry" } as const),
    [manageFieldsDialogScreen]
  );

  const { entityId } = useEntitySelectedProvider();

  const { getTaskListBreadcrumbs } = useTaskBreadcrumbs({ entityId });

  const { t } = useTranslations();

  const breadcrumbs = useMemo(() => {
    const listBreadcrumbs = getTaskListBreadcrumbs(tasksSource);

    if (!task || task._is_deleted) {
      return [
        ...listBreadcrumbs,
        {
          title: t({
            id: "tasks-page.task-details",
            defaultMessage: "Task details",
          }),
          isHidden: isTaskLoading,
        },
      ];
    }

    return [
      ...listBreadcrumbs,
      {
        title: shortenTaskTitle(task.name, 80),
        isHidden: isTaskLoading,
      },
    ];
  }, [getTaskListBreadcrumbs, tasksSource, isTaskLoading, t, task]);

  return (
    <>
      <HeaderBreadcrumbs
        items={breadcrumbs}
        rightContent={
          <>
            {!hasEmployeeLikeRole && (
              <ManageFieldsTopBarButton
                onClick={() => setManageFieldsDialogScreen({ name: "entry" })}
              />
            )}
          </>
        }
      />
      <ManageTaskFieldsDialog
        isOpen={isManageFieldsDialogOpen}
        entityId={entityId}
        initialScreen={initialManageFieldsDialogScreen}
        onClose={() => setManageFieldsDialogScreen(null)}
      />
    </>
  );
};
