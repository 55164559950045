import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import { AppVariant, cx, useTranslations } from "@jugl-web/utils";
import { FC, memo, useCallback, useMemo } from "react";
import { UserGeneralProfile } from "@jugl-web/rest-api";
import Highlighter from "react-highlight-words";
import {
  InteractiveContainer,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components";
import { ReactComponent as MoreIcon } from "./images/more.svg";
import { ReactComponent as CheckIcon } from "./images/check.svg";
import { ReactComponent as InfoIcon } from "./images/info.svg";
import { ReactComponent as CrossIcon } from "./images/cross.svg";
import { ReactComponent as AdminIcon } from "./images/admin.svg";
import { useUserGeneralProfile } from "../../hooks/useUserGeneralProfile";
import { useUserRoleName } from "../../hooks/useUserRoleName";

interface UserListItemParams {
  entityId: string;
  variant: AppVariant;
  userId: string;
  isMe?: boolean;
  className?: string;
  mode?: "display" | "check" | "radio" | "delete";
  isSelected?: boolean;
  isDisabled?: boolean;
  isAdmin?: boolean;
  onSelect?: (profile: UserGeneralProfile) => void;
  subTitle?: string;
  defaultSubTitle?: "department" | "role";
  highlightText?: string;
  options?: {
    text: string;
    icon?: JSX.Element;
    onClick: (profile: UserGeneralProfile) => void;
  }[];
  disabledInfo?: string;
}

export const UserListItem: FC<UserListItemParams> = memo(
  ({
    variant,
    userId,
    isSelected,
    isMe,
    className,
    onSelect,
    entityId,
    mode = "display",
    subTitle,
    defaultSubTitle = "department",
    highlightText,
    isDisabled,
    disabledInfo,
    options,
    isAdmin,
  }) => {
    const { profile } = useUserGeneralProfile({ userId, entityId });
    const isMobile = variant === "mobile";
    const isAvatarSelected = isSelected && isMobile;
    const { t } = useTranslations();
    const { getRoleName } = useUserRoleName();
    const isNotRegistered = profile && !profile?.hasProfile;

    const handleSelect = useCallback(
      (selectedProfile: UserGeneralProfile) => {
        if (mode === "display") {
          return;
        }
        onSelect?.(selectedProfile);
      },
      [mode, onSelect]
    );

    const title = useMemo(
      () =>
        `${profile?.displayName || ""}${isMe ? " (You)" : ""}${
          isNotRegistered ? ` (Not registered)` : ""
        }`,
      [isMe, isNotRegistered, profile?.displayName]
    );

    const subTitleText = useMemo(() => {
      if (subTitle) {
        return subTitle;
      }
      if (defaultSubTitle === "department") {
        return (
          profile?.department?.name ||
          t({
            id: "common.no-team",
            defaultMessage: "No team",
          })
        );
      }
      return getRoleName(profile?.role);
    }, [
      getRoleName,
      defaultSubTitle,
      profile?.department?.name,
      profile?.role,
      subTitle,
      t,
    ]);

    return (
      <InteractiveContainer
        className={cx(
          "flex w-full items-center justify-between rounded-xl border-none py-2 px-3",
          isSelected && mode !== "delete" ? "bg-[#EFF8FF]" : "bg-white",
          isNotRegistered && "opacity-50",
          mode === "display" && "cursor-default",
          className
        )}
        onClick={profile ? () => handleSelect(profile) : undefined}
        isDisabled={isDisabled}
      >
        <div className="flex items-center gap-3 truncate">
          <div className="relative">
            <Avatar
              size="lg"
              username={profile?.displayName || ""}
              imageUrl={profile?.avatar}
              isSelected={isAvatarSelected}
              key={profile?.id}
            />
            {isAdmin && <AdminIcon className="absolute bottom-0.5 right-0" />}
          </div>
          <div className="truncate text-left">
            <div
              className={cx(
                "text-dark font-secondary mb-0.5 block truncate text-left text-base leading-4",
                isDisabled && "text-dark-500"
              )}
            >
              {highlightText ? (
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary"
                  highlightTag="span"
                  textToHighlight={title}
                  searchWords={[highlightText]}
                />
              ) : (
                title
              )}
            </div>
            <div
              className={cx(
                "truncate text-left text-sm font-normal leading-[140%] text-[#828282]",
                {
                  capitalize: !subTitle && defaultSubTitle === "role",
                  "text-dark-300": isDisabled,
                }
              )}
            >
              {subTitleText}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {!isDisabled ? (
            <div className="w-[18px]">
              {(() => {
                if (mode === "radio") {
                  return (
                    <Radio readOnly tabIndex={-1} isChecked={isSelected} />
                  );
                }

                if (mode === "check" && isSelected) {
                  return <CheckIcon />;
                }

                if (mode === "delete") {
                  return <CrossIcon />;
                }

                return null;
              })()}
            </div>
          ) : disabledInfo ? (
            <Tooltip
              renderTrigger={({ props, ref }) => (
                <PlainButton
                  ref={ref}
                  {...props}
                  className="flex h-8 w-8 items-center justify-center"
                >
                  <InfoIcon />
                </PlainButton>
              )}
            >
              {disabledInfo}
            </Tooltip>
          ) : null}
          {!!options?.length && profile ? (
            <Menu
              placement="bottom-start"
              autoClose
              hasBackdrop
              sections={[
                options.map((item, idx) => ({
                  id: idx.toString(),
                  label: item.text,
                  icon: item.icon,
                  onSelect: () => item.onClick(profile),
                })),
              ]}
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  as={PlainButton}
                  ref={triggerRef}
                  className="flex h-8 w-8 items-center justify-center rounded-full transition-all hover:bg-gray-100"
                >
                  <MoreIcon />
                </Trigger>
              )}
            />
          ) : null}
        </div>
      </InteractiveContainer>
    );
  }
);
