import React, { useState, memo } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  UserProfile,
  UserProfileEducations,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import { useTranslations } from "@jugl-web/utils";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import {
  InteractiveContainer,
  Popover,
  Button,
  Divider,
  LoadingAnimation,
  FormControlLabel,
  TextField,
  ConfirmationPopup,
} from "@jugl-web/ui-components/cross-platform";
import { Accordion } from "@jugl-web/ui-components/cross-platform/Accordion";
import { ReactComponent as EducationIcon } from "./assets/education.svg";
import { useProfileUpdateToast } from "../../hooks/useProfileUpdateToast";
import { DateButton } from "../DateButton/DateButton";

type Inputs = {
  name_degree: string;
  school: string;
  start_date: Date | undefined;
  end_date: Date | undefined;
};

const schema = yup.object().shape({
  name_degree: yup.string().required(),
  school: yup.string().required(),
  start_date: yup.date().required(),
  end_date: yup.date().required(),
});

const EditEducationFormContainer = memo(() => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  return <EditEducationFormComponent {...{ data }} />;
});

const EditEducationFormComponent: React.FC<{
  data: UserProfile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <div className="w-1/2">
      {!data ? (
        <LoadingAnimation />
      ) : data.educations.length === 0 ? (
        <div className="pt-10">
          <span className="pl-5 font-medium">
            {t({
              id: "edit-education-form-page.add-new-education",
              defaultMessage: "Add New Education",
            })}
          </span>
          <EditEducationForm type="add" />
        </div>
      ) : (
        <EditEducationList {...{ data }} />
      )}
    </div>
  );
};

const EditEducationList: React.FC<{ data: UserProfile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <div className="pt-5">
      <span className="pl-5 font-medium">
        {t({
          id: "edit-education-form-page.your-education",
          defaultMessage: "Your Education",
        })}
      </span>
      {data.educations.map((el, idx) => (
        <>
          <EditEducationListItem key={el.id} data={el} />
          {data.educations.length - 1 > idx && <Divider />}
        </>
      ))}
      {!displayForm && (
        <Button
          className="ml-5 mt-5 mb-10 w-1/2"
          variant="outlined"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-education-point",
            defaultMessage: "Add New Education Point",
          })}
        </Button>
      )}
      {displayForm && (
        <>
          <span className="ml-5 font-medium">
            {t({
              id: "edit-education-form-page.add-education-point",
              defaultMessage: "Add New Education Point",
            })}
          </span>
          <EditEducationForm
            type="add"
            onSuccess={() => setDisplayForm(false)}
          />
        </>
      )}
    </div>
  );
};

const EditEducationListItem: React.FC<{ data: UserProfileEducations }> = ({
  data,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { usersApi } = useRestApiProvider();
  const [deleteEducation, { isLoading }] =
    usersApi.useDeleteUserProfileSectionMutation();
  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const { t } = useTranslations();

  const handleDeleteEducation = async () => {
    const res = await deleteEducation({
      type: "education",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        variant="web"
        title={
          <div className="flex w-full items-center gap-5 px-5 py-3">
            <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[rgba(34,197,158,0.1)]">
              <EducationIcon />
            </div>
            <div className="flex grow flex-col gap-1">
              <span>{data.name}</span>
              {data.start && (
                <span className="text-grey text-sm">
                  {`${format(
                    isoToLocalDate(data.start),
                    "MMMM/yyyy"
                  )} - ${format(isoToLocalDate(data.end), "MMMM/yyyy")}`}
                </span>
              )}
            </div>
            <Button
              color="tertiary"
              className="text-sm font-medium uppercase"
              isDisabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              variant="text"
              iconStart={<BinIcon />}
            >
              {t({
                id: "common.delete",
                defaultMessage: "Delete",
              })}
            </Button>
          </div>
        }
        className="flex-row-reverse px-4"
      >
        {({ onClose }) => (
          <EditEducationForm {...{ data }} type="update" onSuccess={onClose} />
        )}
      </Accordion>
      {openModal && (
        <ConfirmationPopup
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          hasCancelButton
          message={t({
            id: "edit-education-form-page.education-information-removal-confirmation",
            defaultMessage:
              "Do you really want to remove the education information?",
          })}
          variant="web"
          title={t({
            id: "delete-modal.delete-confirmation-title",
            defaultMessage: "Delete Confirmation",
          })}
          buttons={[
            {
              label: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              color: "tertiary",
              onClick: handleDeleteEducation,
              isDisabled: isLoading,
            },
          ]}
        />
      )}
    </>
  );
};

const EditEducationForm: React.FC<{
  data?: UserProfileEducations;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const { usersApi } = useRestApiProvider();
  const [addEducation, { isLoading }] =
    usersApi.useAddUserProfileSectionMutation();
  const [updateEducation, { isLoading: isLoadingUpdate }] =
    usersApi.useUpdateUserProfileSectionMutation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      name_degree: data?.name || "",
      school: data?.where || "",
      start_date: data?.start ? new Date(data.start) : undefined,
      end_date: data?.end ? new Date(data.end) : undefined,
    },
  });
  const { t } = useTranslations();

  const { showProfileUpdatedSnackbar } = useProfileUpdateToast();
  const startDateValue = watch("start_date");
  const endDateValue = watch("end_date");

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      name_degree: nameDegree,
      school,
      start_date: startDate,
      end_date: endDate,
    } = { ...params };
    let res;
    if (type === "add") {
      res = await addEducation({
        data: {
          type: "education",
          start: startDate,
          end: endDate,
          name: nameDegree,
          where: school,
        },
      });
    } else {
      res = await updateEducation({
        item_id: data?.id,
        data: {
          type: "education",
          start: startDate,
          end: endDate,
          name: nameDegree,
          where: school,
        },
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <div className="px-10 pb-10 pt-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full gap-[70px]">
          <div className="flex w-1/2 flex-col gap-12">
            <TextField
              isRequired
              label={t({
                id: "form-controls.degree-name.label",
                defaultMessage: "Name of degree / Course",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.name-degree.placeholder",
                defaultMessage: "Phd in Computer science engineering",
              })}
              {...register("name_degree")}
            />
            <Controller
              name="start_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Popover
                  className="w-[340px]"
                  placement="bottom-start"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger ref={triggerRef} as={InteractiveContainer}>
                      <FormControlLabel isRequired className="ml-2.5">
                        {t({
                          id: "form-controls.start-date.label",
                          defaultMessage: "Start Date",
                        })}
                      </FormControlLabel>
                      <DateButton date={value} />
                    </Trigger>
                  )}
                >
                  {({ onClose }) => (
                    <DatePicker
                      onSubmit={(date) => {
                        onChange(date);
                        if (endDateValue && date && date > endDateValue) {
                          setValue("end_date", date, {
                            shouldValidate: true,
                          });
                        }
                      }}
                      initialDate={value}
                      onClose={onClose}
                      className="p-4"
                      dateTransformation="endOfDay"
                    />
                  )}
                </Popover>
              )}
            />
            <Button
              isDisabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
              fullWidth
              variant="contained"
              type="submit"
            >
              {t({
                id: "common.save",
                defaultMessage: "Save",
              })}
            </Button>
          </div>
          <div className="flex w-1/2 flex-col gap-12">
            <TextField
              isRequired
              label={t({
                id: "form-controls.school.label",
                defaultMessage: "School / College",
              })}
              isFullWidth
              placeholder={t({
                id: "form-controls.name-school.placeholder",
                defaultMessage: "Philips",
              })}
              {...register("school")}
            />
            <Controller
              name="end_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Popover
                  className="w-[340px]"
                  placement="bottom-start"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger ref={triggerRef} as={InteractiveContainer}>
                      <FormControlLabel isRequired className="ml-2.5">
                        {t({
                          id: "form-controls.end-date.label",
                          defaultMessage: "End Date",
                        })}
                      </FormControlLabel>
                      <DateButton date={value} />
                    </Trigger>
                  )}
                >
                  {({ onClose }) => (
                    <DatePicker
                      minDate={startDateValue}
                      onSubmit={onChange}
                      initialDate={value}
                      onClose={onClose}
                      className="p-4"
                      dateTransformation="endOfDay"
                    />
                  )}
                </Popover>
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditEducationFormContainer;
