import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useSendMessage } from "@web-src/modules/chats/hooks/useSendMessage";
import { v4 as uuid4 } from "uuid";
import {
  ChatMessage,
  ChatMessagePayloadCallAction,
  ChatMessageType,
  ChatCallType,
  ScenarioCallType,
} from "@web-src/modules/chats/types";
import { environment } from "@web-src/environments/environment";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "@web-src/modules/socket/providers/PhoenixSocket";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useToast, useTranslations } from "@jugl-web/utils";
import AgoraRTC, { useRTCClient, IAgoraRTCClient } from "agora-rtc-react";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSelector } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import { ChatMessagePayload } from "@jugl-web/rest-api";
import { useAgoraTokenMutation } from "../callsApi";
import { ActiveCallProps, CallStage } from "../types";
import { useActiveCall } from "../hooks/useActiveCall";

type CallsContextType = {
  initiateCall?: (chatId: string, type: ChatMessageType) => void;
  joinCall?: (params: {
    channel: string;
    callType?: ChatCallType;
    type?: ScenarioCallType;
    to?: string;
    isIncomingCall?: boolean;
    conferenceMessage?: ChatMessagePayload["conference"];
  }) => void;
  leaveCall?: () => void;
  client?: IAgoraRTCClient;
  activeCall?: ReturnType<typeof useActiveCall>;
  incomingCall?: ChatMessage;
  callMsgId?: string;
  setIncomingCall?: React.Dispatch<
    React.SetStateAction<ChatMessage | undefined>
  >;
  videoDevices?: MediaDeviceInfo[];
  audioDevices?: MediaDeviceInfo[];
  playbackDevices?: MediaDeviceInfo[];
  activeCallProps?: ActiveCallProps;
  callStage?: CallStage;
  setCallStage?: React.Dispatch<React.SetStateAction<CallStage>>;
};

const CallsContext = createContext<CallsContextType>({});

const CallsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const client = useRTCClient();
  const { me } = useMe();
  const { entity } = useEntity();
  const sendMessage = useSendMessage();
  const [activeCallProps, setActiveCallProps] = useState<ActiveCallProps>();
  const activeCall = useActiveCall(client, activeCallProps);
  const [agoraToken] = useAgoraTokenMutation();
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>();
  const [playbackDevices, setPlaybackDevices] = useState<MediaDeviceInfo[]>();
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>();
  const [incomingCall, setIncomingCall] = useState<ChatMessage>();
  const [callMsgId, setCallMsgId] = useState<string>();
  const [callStage, setCallStage] = useState<CallStage>("off");
  const { channel: phxChannel } = useContext(PhoenixSocketContext);
  const { isAuthenticated } = useSelector(selectAuthState);

  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const joinCall = useCallback(
    async ({
      channel,
      callType,
      type,
      to,
      isIncomingCall,
      conferenceMessage,
    }: {
      channel: string;
      callType?: ChatCallType;
      type?: ScenarioCallType;
      to?: string;
      isIncomingCall?: boolean;
      conferenceMessage?: ChatMessagePayload["conference"];
    }) => {
      const tokenData = await agoraToken({ channel });
      if (!("data" in tokenData)) {
        throw tokenData.error as Error;
      }
      setActiveCallProps({
        token: tokenData.data,
        channel,
        appId: environment.agoraAppId,
        video: callType === ChatCallType.video,
        type: type || "conference",
        to: to || undefined,
        entityId: isIncomingCall ? incomingCall?.entity_id : entity?.id,
        isIncomingCall,
        conferenceMessage: conferenceMessage || undefined,
      });
    },
    [agoraToken, entity?.id, incomingCall?.entity_id]
  );

  const leaveCall = useCallback(async () => {
    await activeCall?.leaveCall();
    setActiveCallProps(undefined);
    setAudioDevices(undefined);
    setPlaybackDevices(undefined);
    setVideoDevices(undefined);
  }, [activeCall]);

  useEffect(() => {
    if (activeCallProps?.channel === incomingCall?.payload.call_channel) {
      setIncomingCall(undefined);
    }
  }, [incomingCall, activeCallProps]);

  useEffect(() => {
    if (!isAuthenticated) {
      leaveCall();
      setIncomingCall(undefined);
      setCallStage("off");
    }
  }, [isAuthenticated, leaveCall]);

  useEffect(() => {
    if (!activeCall) return;
    Promise.all([
      AgoraRTC.getMicrophones(),
      AgoraRTC.getPlaybackDevices(),
      AgoraRTC.getCameras(),
    ])
      .then(([microphones, newPlaybackDevices, newCameras]) => {
        const microphonesUnchanged =
          JSON.stringify(microphones) === JSON.stringify(audioDevices);
        const playbackDevicesUnchanged =
          JSON.stringify(newPlaybackDevices) ===
          JSON.stringify(playbackDevices);
        const camerasUnchanged =
          JSON.stringify(newCameras) === JSON.stringify(videoDevices);
        if (
          microphonesUnchanged &&
          playbackDevicesUnchanged &&
          camerasUnchanged
        ) {
          return;
        }
        setPlaybackDevices(newPlaybackDevices);
        setAudioDevices(microphones);
        setVideoDevices(newCameras);
      })
      .catch(() => {});
  }, [activeCall, audioDevices, playbackDevices, t, toast, videoDevices]);

  useEffect(() => {
    const phxCallDeliveredResponse = phxChannel?.on(
      "phx_call_delivered",
      (message: PhxResponse<ChatMessage>) => {
        if (
          message.response.payload.call_action ===
          ChatMessagePayloadCallAction.call_delivered
        ) {
          setCallMsgId(message.response.msg_id);
        }
      }
    );
    const phxCallResponse = phxChannel?.on(
      "phx_call",
      (message: PhxResponse<ChatMessage>) => {
        const isFromMe = message.response.from === me?.id;
        if (
          isFromMe &&
          message.response.payload.call_action ===
            ChatMessagePayloadCallAction.call_invite
        ) {
          setCallMsgId(message.response.msg_id);
        }
        if (
          message?.response.payload.call_action ===
            ChatMessagePayloadCallAction.call_invite &&
          message.response.from !== me?.id
        ) {
          setCallMsgId(message.response.msg_id);
          setIncomingCall(message.response);
        }
      }
    );
    return () => {
      phxChannel?.off("phx_call", phxCallResponse);
      phxChannel?.off("phx_call_delivered", phxCallDeliveredResponse);
    };
  }, [me?.id, phxChannel]);

  const initiateCall = useCallback(
    async (chatId: string, chatMessageType: ChatMessageType) => {
      const channel = uuid4();
      const tokenData = await agoraToken({ channel });
      if (!("data" in tokenData)) {
        throw tokenData.error as Error;
      }
      await sendMessage({
        to: chatId,
        body: "",
        extraPayload: {
          conference: { channel },
        },
        type: chatMessageType,
      });
    },
    [agoraToken, sendMessage]
  );

  const value: CallsContextType = useMemo(
    () => ({
      initiateCall,
      activeCall,
      incomingCall,
      callMsgId,
      setIncomingCall,
      joinCall,
      client,
      leaveCall,
      audioDevices,
      videoDevices,
      playbackDevices,
      activeCallProps,
      callStage,
      setCallStage,
    }),
    [
      initiateCall,
      activeCall,
      incomingCall,
      callMsgId,
      joinCall,
      client,
      leaveCall,
      audioDevices,
      videoDevices,
      playbackDevices,
      activeCallProps,
      callStage,
    ]
  );

  return (
    <CallsContext.Provider value={value}>{children}</CallsContext.Provider>
  );
};

export { CallsContext, CallsProvider };
