import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  InternalTaskCustomField,
  InternalTaskFilterSet,
  InternalTaskFilters,
  TaskFilterSet,
  TaskLabel,
} from "@jugl-web/rest-api/tasks";
import { assert, reorder, useTranslations } from "@jugl-web/utils";
import { useCallback, useMemo } from "react";
import { useTaskFieldsQuery } from "./useTaskFieldsQuery";

export interface UseTaskFieldsOptions {
  entityId: string | undefined;
}

type NewTaskCustomField = Pick<
  InternalTaskCustomField,
  "name" | "type" | "isShownInCard" | "values"
>;

type NewTaskLabel = Pick<TaskLabel, "text" | "color">;

const EMPTY_ARRAY: [] = [];

export const useTaskFields = ({ entityId }: UseTaskFieldsOptions) => {
  const { tasksApi } = useRestApiProvider();
  const { t } = useTranslations();

  const { taskFields, isLoading, isFetching, isError, refetch } =
    useTaskFieldsQuery({ entityId });

  // #region custom fields
  const [createTaskCustomFieldMutation, { isLoading: isCreatingCustomField }] =
    tasksApi.useCreateTaskCustomFieldMutation();

  const [
    updateTaskCustomFieldsMutation,
    { isLoading: isUpdatingCustomFields },
  ] = tasksApi.useUpdateTaskCustomFieldsMutation();

  const customFields = taskFields?.customFieldsData.items || EMPTY_ARRAY;

  const customFieldsMap = useMemo<Map<string, InternalTaskCustomField>>(
    () => new Map(customFields.map((field) => [field.id, field])),
    [customFields]
  );

  const getCustomFieldById = useCallback(
    (fieldId: string) => customFieldsMap.get(fieldId),
    [customFieldsMap]
  );

  const createCustomField = useCallback(
    (newCustomField: NewTaskCustomField) =>
      new Promise<void>((resolve, reject) => {
        if (!entityId || !taskFields) {
          reject();
          return;
        }

        createTaskCustomFieldMutation({
          entityId,
          customFieldsId: taskFields.customFieldsData.id,
          customField: {
            field: newCustomField.name,
            field_type: newCustomField.type,
            show_in_card: newCustomField.isShownInCard,
            order: taskFields.customFieldsData.items.length + 1,
            values: newCustomField.values,
          },
        }).then((response) => {
          if ("data" in response) {
            resolve();
          } else {
            reject();
          }
        });
      }),
    [createTaskCustomFieldMutation, entityId, taskFields]
  );

  const updateCustomFields = useCallback(
    (
      callback: (
        previousCustomFields: InternalTaskCustomField[]
      ) => InternalTaskCustomField[]
    ) =>
      new Promise<void>((resolve, reject) => {
        if (!entityId || !taskFields) {
          reject();
          return;
        }

        const updatedCustomFields = callback(taskFields.customFieldsData.items);

        updateTaskCustomFieldsMutation({
          entityId,
          customFieldsId: taskFields.customFieldsData.id,
          customFields: updatedCustomFields.map((internalField, index) => ({
            id: internalField.id,
            field: internalField.name,
            field_type: internalField.type,
            show_in_card: internalField.isShownInCard,
            order: index + 1,
            values: internalField.values,
          })),
        }).then((response) => {
          if ("data" in response) {
            resolve();
          } else {
            reject();
          }
        });
      }),
    [entityId, taskFields, updateTaskCustomFieldsMutation]
  );

  const updateCustomField = useCallback(
    (updatedCustomField: InternalTaskCustomField) =>
      updateCustomFields((previousCustomFields) =>
        previousCustomFields.map((field) =>
          field.id === updatedCustomField.id ? updatedCustomField : field
        )
      ),
    [updateCustomFields]
  );

  const deleteCustomField = useCallback(
    (customFieldId: string) =>
      updateCustomFields((previousCustomFields) =>
        previousCustomFields.filter((field) => field.id !== customFieldId)
      ),
    [updateCustomFields]
  );

  const reorderCustomField = useCallback(
    (startIndex: number, endIndex: number) =>
      updateCustomFields((previousCustomFields) =>
        reorder(previousCustomFields, startIndex, endIndex)
      ),
    [updateCustomFields]
  );
  // #endregion

  // #region labels
  const [createTaskLabelMutation, { isLoading: isCreatingLabel }] =
    tasksApi.useCreateTaskLabelMutation();

  const [updateTaskLabelMutation, { isLoading: isUpdatingLabel }] =
    tasksApi.useUpdateTaskLabelMutation();

  const [updateTaskLabelsInBulkMutation] =
    tasksApi.useUpdateTaskLabelsInBulkMutation();

  const [deleteTaskLabelMutation, { isLoading: isDeletingLabel }] =
    tasksApi.useDeleteTaskLabelMutation();

  const labels = taskFields?.labels || EMPTY_ARRAY;

  const labelsMap = useMemo<Map<string, TaskLabel>>(
    () => new Map(labels.map((label) => [label.id, label])),
    [labels]
  );

  const noneLabel = useMemo<TaskLabel>(
    () => ({
      id: "none",
      text: t({ id: "tasks-page.none-label", defaultMessage: "None" }),
      color: "#D2D2D8",
      order: 0,
    }),
    [t]
  );

  const getLabelById = useCallback(
    (labelId: string) => labelsMap.get(labelId),
    [labelsMap]
  );

  const createLabel = useCallback(
    async (newLabel: NewTaskLabel) => {
      assert(!!entityId);

      return new Promise<void>((resolve, reject) => {
        createTaskLabelMutation({
          entityId,
          label: {
            ...newLabel,
            order: labels.length > 0 ? labels[labels.length - 1].order + 1 : 1,
          },
        }).then((response) => {
          if ("data" in response) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    [createTaskLabelMutation, entityId, labels]
  );

  const updateLabel = useCallback(
    (updatedLabel: TaskLabel) => {
      assert(!!entityId);
      return updateTaskLabelMutation({ entityId, label: updatedLabel });
    },
    [entityId, updateTaskLabelMutation]
  );

  const deleteLabel = useCallback(
    (labelId: string) => {
      assert(!!entityId);
      return deleteTaskLabelMutation({ entityId, labelId });
    },
    [entityId, deleteTaskLabelMutation]
  );

  const reorderLabel = useCallback(
    (startIndex: number, endIndex: number) => {
      assert(!!entityId);

      const updatedLabels = reorder(labels, startIndex, endIndex).map(
        (label, index) => ({ ...label, order: index + 1 })
      );

      updateTaskLabelsInBulkMutation({ entityId, labels: updatedLabels });
    },
    [entityId, labels, updateTaskLabelsInBulkMutation]
  );
  // #endregion

  // #region filter sets
  const [createTaskFilterSetMutation, { isLoading: isCreatingFilterSet }] =
    tasksApi.useCreateTaskFilterSetMutation();

  const [updateTaskFilterSetMutation, { isLoading: isUpdatingFilterSet }] =
    tasksApi.useUpdateTaskFilterSetMutation();

  const [deleteTaskFilterSetMutation, { isLoading: isDeletingFilterSet }] =
    tasksApi.useDeleteTaskFilterSetMutation();

  const filterSets = taskFields?.filterSets || EMPTY_ARRAY;

  const filterSetsMap = useMemo<Map<string, InternalTaskFilterSet>>(
    () => new Map(filterSets.map((filterSet) => [filterSet.id, filterSet])),
    [filterSets]
  );

  const getFilterSetById = useCallback(
    (filterSetId: string) => filterSetsMap.get(filterSetId),
    [filterSetsMap]
  );

  const createFilterSet = useCallback(
    (name: string, filters: InternalTaskFilters) => {
      assert(!!entityId);

      return new Promise<TaskFilterSet>((resolve, reject) => {
        createTaskFilterSetMutation({ entityId, name, filters }).then(
          (response) => {
            if ("data" in response) {
              resolve(response.data);
            } else {
              reject();
            }
          }
        );
      });
    },
    [createTaskFilterSetMutation, entityId]
  );

  const updateFilterSet = useCallback(
    (filterSetId: string, name: string, filters: InternalTaskFilters) => {
      assert(!!entityId);

      return new Promise<TaskFilterSet>((resolve, reject) => {
        updateTaskFilterSetMutation({
          entityId,
          filterSetId,
          name,
          filters,
        }).then((response) => {
          if ("data" in response) {
            resolve(response.data);
          } else {
            reject();
          }
        });
      });
    },
    [entityId, updateTaskFilterSetMutation]
  );

  const deleteFilterSet = useCallback(
    (filterSetId: string) => {
      assert(!!entityId);

      return new Promise<void>((resolve, reject) => {
        deleteTaskFilterSetMutation({ entityId, filterSetId }).then(
          (response) => {
            if ("data" in response) {
              resolve();
            } else {
              reject();
            }
          }
        );
      });
    },
    [deleteTaskFilterSetMutation, entityId]
  );
  // #endregion

  return {
    // Custom fields
    customFields,
    getCustomFieldById,
    createCustomField,
    updateCustomField,
    deleteCustomField,
    reorderCustomField,
    isCreatingCustomField,
    isUpdatingCustomFields,
    isDeletingCustomFields: isUpdatingCustomFields,

    // Labels
    labels,
    noneLabel,
    getLabelById,
    createLabel,
    updateLabel,
    deleteLabel,
    reorderLabel,
    isCreatingLabel,
    isUpdatingLabel,
    isDeletingLabel,

    // Filter sets
    filterSets,
    getFilterSetById,
    createFilterSet,
    updateFilterSet,
    deleteFilterSet,
    isCreatingFilterSet,
    isUpdatingFilterSet,
    isDeletingFilterSet,

    // Task fields in general
    isLoading,
    isFetching,
    isError,
    refetch,
  };
};
