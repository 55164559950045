import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { DashboardPage } from "./DashboardPage";

export type DashboardPagesNavigationParams = {
  dashboardDashboard: PageNavParams;
};

export const dashboardPagesNavigationConfig: ModulePagesConfig<DashboardPagesNavigationParams> =
  {
    dashboardDashboard: {
      element: <DashboardPage />,
      path: "dashboard",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.dashboard,
    },
  };
