import { TaskLabel } from "@jugl-web/rest-api/tasks";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useRef, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { DraggableFieldBoxContainer } from "../../../../custom-fields/components/DraggableFieldBoxContainer/DraggableFieldBoxContainer";
import { TASK_LABEL_COLORS } from "../../../consts";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { taskLabelValidator } from "../../../validators";
import { TaskLabelListItem } from "../TaskLabelListItem";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import { ReactComponent as LabelIcon } from "../icons/label.svg";
import { EmptyStateScreen } from "./EmptyStateScreen";

export interface LabelsScreenProps {
  entityId: string;
  searchQuery: string;
}

const TEMPORARY_TASK_LABEL: TaskLabel = {
  id: "__temporary",
  text: "",
  color: TASK_LABEL_COLORS[0],
  order: 0,
};

export const LabelsScreen: FC<LabelsScreenProps> = ({
  entityId,
  searchQuery,
}) => {
  const {
    labels,
    createLabel,
    updateLabel,
    deleteLabel,
    reorderLabel,
    isCreatingLabel,
  } = useTaskFields({ entityId });
  const { t } = useTranslations();

  const [editingLabelId, setEditingLabelId] = useState<string | null>(null);
  const [isNewLabelShown, setIsNewLabelShown] = useState(false);

  const visibleLabels = useMemo(() => {
    if (!searchQuery) {
      return labels;
    }

    return labels.filter((label) =>
      label.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [labels, searchQuery]);

  const isInSearchingMode = !!searchQuery;
  const isEmpty = visibleLabels.length === 0;

  const scrollToBottom = () => {
    endListAnchorRef.current?.scrollIntoView({ block: "center" });
  };

  const handleNewLabelClick = () => {
    setIsNewLabelShown(true);
    // Make sure that scroll happens after the new item is rendered
    window.setTimeout(scrollToBottom, 0);
  };

  const handleCreateLabel = async (label: TaskLabel) => {
    const isValid = taskLabelValidator(label.text);

    if (!isValid) {
      setIsNewLabelShown(false);
      return;
    }

    createLabel(label).then(() => {
      setIsNewLabelShown(false);
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    reorderLabel(source.index, destination.index);
  };

  const endListAnchorRef = useRef<HTMLDivElement | null>(null);

  if (isInSearchingMode && isEmpty) {
    return <EmptyStateScreen />;
  }

  return (
    <BottomCenteredDrawer.Content className="pb-8">
      {!isInSearchingMode && (
        <div
          className="px-12 pt-6 pb-4"
          style={{ boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.06)" }}
        >
          <Button
            variant="contained"
            color="grey"
            className="h-10 p-3 font-normal"
            iconStart={<LabelIcon />}
            iconEnd={<AddIcon className="ml-4" />}
            onClick={handleNewLabelClick}
          >
            {t({
              id: "tasks-page.create-new-label",
              defaultMessage: "Create new label",
            })}
          </Button>
        </div>
      )}
      {isEmpty && !isNewLabelShown && (
        <div className="mx-12 mt-8">
          <span className="text-grey-900 text-sm leading-[21px]">
            {t({
              id: "tasks-page.no-labels-created",
              defaultMessage: "You haven't created any labels yet",
            })}
          </span>
        </div>
      )}
      <div className="mt-6 px-12">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="labelList">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="flex flex-col"
                {...provided.droppableProps}
              >
                {visibleLabels.map((label, index) => (
                  <DraggableFieldBoxContainer
                    key={label.id}
                    id={label.id}
                    index={index}
                    className="mb-4"
                    isDragDisabled={isInSearchingMode}
                  >
                    <TaskLabelListItem
                      label={label}
                      isEditing={editingLabelId === label.id}
                      highlightedText={searchQuery}
                      onStartEditing={() => setEditingLabelId(label.id)}
                      onFinishEditing={() => setEditingLabelId(null)}
                      onUpdate={updateLabel}
                      onRemove={() => deleteLabel(label.id)}
                    />
                  </DraggableFieldBoxContainer>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isNewLabelShown && (
          <div
            className={cx(
              "transition-opacity",
              isCreatingLabel && "cursor-not-allowed opacity-50"
            )}
          >
            <TaskLabelListItem
              label={TEMPORARY_TASK_LABEL}
              isEditing
              onStartEditing={() => {}}
              onFinishEditing={handleCreateLabel}
              onUpdate={() => {}}
              onRemove={() => setIsNewLabelShown(false)}
              className={cx("mb-4", isCreatingLabel && "pointer-events-none")}
            />
          </div>
        )}
        <div ref={endListAnchorRef} />
      </div>
    </BottomCenteredDrawer.Content>
  );
};
