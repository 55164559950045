import { FC, useState, useEffect, useMemo } from "react";
import { OrderFormSubmission } from "@jugl-web/domain-resources/orders/components/OrderFormSubmission";
import { PlainButton, Tooltip } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { DiscountItemDataDto } from "@jugl-web/rest-api/inventory/models/DiscountItem";
import { ReactComponent as BackIcon } from "./assets/back-icon.svg";
import { useOrderForm } from "../../../../providers/OrderFormProvider";

export const OrderFormPreview: FC = () => {
  const {
    title,
    discounts,
    description,
    image,
    allFields,
    setIsPreviewMode,
    inventoryItemsCache,
  } = useOrderForm();
  const { t } = useTranslations();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { inventoryApi } = useRestApiProvider();
  const [discountsData, setDiscountsData] =
    useState<Record<string, DiscountItemDataDto>>();
  const [loadDiscount] = inventoryApi.useLazyGetDiscountItemQuery();
  const { entity } = useEntitySelectedProvider();

  useEffect(() => {
    const loadDiscounts = async () => {
      const discountIds = Object.values(discounts).map(
        (discount) => discount.discount
      );
      const requests = await Promise.all(
        discountIds.map((discountId) =>
          loadDiscount({
            entityId: entity.id,
            id: discountId,
          })
        )
      );
      const result = requests.reduce((acc, { data }) => {
        if (!data) return acc;
        return {
          ...acc,
          [data.id]: data,
        };
      }, {});
      setDiscountsData(result);
    };
    loadDiscounts();
  }, [discounts, entity.id, loadDiscount]);

  const enrichedDiscounts = useMemo(
    () =>
      Object.entries(discounts).reduce(
        (acc, [itemId, { discount }]) => ({
          ...acc,
          [itemId]: {
            discount,
            value: discountsData?.[discount]?.discount ?? 0,
          },
        }),
        {} as Record<string, { discount: string; value: number }>
      ),
    [discounts, discountsData]
  );
  return (
    <div className="relative flex justify-center gap-2">
      <Tooltip
        placement="bottom"
        renderTrigger={({ props, ref }) => (
          <PlainButton
            className={cx(
              "border-grey-300 hover:border-primary-200 sticky top-4 flex h-12 w-12 items-center justify-center rounded-xl border border-solid bg-white transition-all hover:border-2"
            )}
            onClick={() => setIsPreviewMode(false)}
            ref={ref}
            {...props}
          >
            <BackIcon />
          </PlainButton>
        )}
      >
        {t({
          id: "order-form-wizard-page.back-to-form-creation",
          defaultMessage: "Back to Form Creation",
        })}
      </Tooltip>
      <div className="flex w-full gap-2">
        <OrderFormSubmission
          title={title}
          entityId={entity.id}
          description={description}
          bannerImg={
            image && typeof image === "object"
              ? URL.createObjectURL(image)
              : image
          }
          discounts={enrichedDiscounts}
          fields={allFields}
          services={inventoryItemsCache}
          onSubmit={() => setIsSubmitted(true)}
          isSubmitted={isSubmitted}
          isPreview
          onAddAnotherAnswer={() => setIsSubmitted(false)}
          currency={entity.currency}
        />
      </div>
    </div>
  );
};
