/* eslint-disable react/destructuring-assignment */
import { forwardRef } from "react";
import { BlankSearchInput } from "./BlankSearchInput";
import { FilledSearchInput } from "./FilledSearchInput";
import { BaseInputProps } from "./types";

export type SearchInputProps = BaseInputProps &
  (
    | { variant: "blank" }
    | { variant: "filled"; color: "primary" | "grey" | "white" }
  );

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    if (props.variant === "blank") {
      const {
        onClear,
        alwaysShowClear,
        containerClassName,
        hasSettingsIndicator,
        onSettingsClick,
        ...inputProps
      } = props;

      return (
        <BlankSearchInput
          containerClassName={containerClassName}
          alwaysShowClear={alwaysShowClear}
          hasSettingsIndicator={hasSettingsIndicator}
          onSettingsClick={onSettingsClick}
          onClear={onClear}
          {...inputProps}
          ref={ref}
        />
      );
    }

    if (props.variant === "filled") {
      const {
        color,
        alwaysShowClear,
        containerClassName,
        hasSettingsIndicator,
        onClear,
        onSettingsClick,
        ...inputProps
      } = props;

      return (
        <FilledSearchInput
          color={color}
          alwaysShowClear={alwaysShowClear}
          hasSettingsIndicator={hasSettingsIndicator}
          onSettingsClick={onSettingsClick}
          containerClassName={containerClassName}
          onClear={onClear}
          {...inputProps}
          ref={ref}
        />
      );
    }

    throw new Error("Search input variant has to be specified");
  }
);
