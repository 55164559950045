import React, { useMemo } from "react";
import { ChatItem } from "@web-src/modules/chats/types";
import {
  Checkbox,
  Avatar,
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { Tooltip } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { ReactComponent as InfoIcon } from "./assets/info.svg";

export const ChatListPickerItem: React.FC<{
  chat: ChatItem;
  isSelected: boolean;
  shouldDisableBroadcastChat?: boolean;
  onSelect: (chat: ChatItem) => void;
}> = ({ chat, shouldDisableBroadcastChat, isSelected, onSelect }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { me } = useMe();
  const { profile } = useUserGeneralProfile({
    entityId,
    userId: chat.id,
    skip: chat.type !== "chat",
  });

  const unknownName = useMemo(() => {
    if (chat.type === "muc") {
      return "Unknown Group";
    }
    return "Unknown User";
  }, [chat]);

  const hasUserAccessToChat = profile
    ? hasRoleAccessToModule(profile.role, AppModule.chat)
    : true;

  const hasUserBroadcastChatRestriction =
    shouldDisableBroadcastChat &&
    chat.config?.admin_only_msg &&
    chat.role !== "admin";

  const isDisabled = !hasUserAccessToChat || hasUserBroadcastChatRestriction;

  return (
    <InteractiveContainer
      className="flex items-center gap-2 px-8 py-1"
      onClick={onSelect.bind(null, chat)}
      isDisabled={isDisabled}
    >
      <Avatar
        username={chat.title || unknownName}
        imageUrl={chat.img}
        className="h-10 w-10"
      />
      <p className="grow truncate">
        {chat.title
          ? `${chat.title}${me?.id === chat.id ? ` (You)` : ""}`
          : unknownName}
      </p>
      {!isDisabled ? (
        <Checkbox readOnly isChecked={isSelected} />
      ) : (
        <Tooltip
          renderTrigger={({ props, ref }) => (
            <PlainButton ref={ref} {...props}>
              <InfoIcon />
            </PlainButton>
          )}
        >
          {hasUserBroadcastChatRestriction
            ? t({
                id: "chats-page.conversation-is-admin-only-chat",
                defaultMessage: "The conversation is an Admin only chat 🙅‍♀️",
              })
            : t({
                id: "chats-page.role-doesnt-have-permissions-to-chats",
                defaultMessage:
                  "Due to Role Permissions, this user doesn’t have access to the Chats 🙅‍♀️",
              })}
        </Tooltip>
      )}
    </InteractiveContainer>
  );
};
