import { Button } from "@jugl-web/ui-components";
import React from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import alertAnimation from "../../../../assets/alert-animation.json";

export const DeleteConfirmationScreen: React.FC<{
  count: number;
  onCancel: () => void;
  onDelete: () => void;
}> = ({ count, onCancel, onDelete }) => {
  const { t } = useTranslations();

  return (
    <div className="px-4">
      <Lottie
        options={{ animationData: alertAnimation }}
        height={70}
        width={70}
      />
      <div className="text-dark font-secondary mt-4 text-center text-xl font-medium leading-5">
        {t({
          id: "common.confirm-action",
          defaultMessage: "Confirm action",
        })}
      </div>
      <div className="font-secondary mt-2 text-center text-sm leading-[21px] text-[#4F4F4F]">
        {t(
          {
            id: "order-form-submission-component.clear-cart-confirmation",
            defaultMessage:
              "Do you really want to clear your cart with {count} <highlightedText>{count, plural, one {item} other {items}}</highlightedText>?",
          },
          {
            count,
            highlightedText: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        )}
      </div>
      <div className="mt-12 mb-8 flex gap-4">
        <Button onClick={onCancel} color="grey" fullWidth>
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button onClick={onDelete} fullWidth>
          {t({
            id: "common.clear",
            defaultMessage: "Clear",
          })}
        </Button>
      </div>
    </div>
  );
};
