import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useState } from "react";
import { CustomFieldForm } from "../../../../custom-fields/components/CustomFieldForm/CustomFieldForm";
import { DropdownValueListItem } from "../../../../custom-fields/components/DropdownValueList/DropdownValueList";
import { useTaskFields } from "../../../hooks/useTaskFields";
import { taskCustomFieldNameValidator } from "../../../validators";
import { ManageTaskFieldsDialogScreenToParametersMap } from "../types";
import { CustomDropdownFieldValuesScreen } from "./CustomDropdownFieldValuesScreen";

interface EditCustomFieldScreenProps {
  entityId: string;
  field: InternalTaskCustomField;
}

export const EditCustomFieldScreen: FC<EditCustomFieldScreenProps> = ({
  entityId,
  field,
}) => {
  const { variant } = useAppVariant();

  const [internalFieldName, setInternalFieldName] = useState(field.name);
  const [internalIsShownInCard, setInternalIsShownInCard] = useState(
    field.isShownInCard
  );

  const [internalItems, setInternalItems] = useState<DropdownValueListItem[]>(
    () => {
      if (field.values) {
        return [...field.values]
          .sort((a, b) => a.order - b.order)
          .map((dropdownValue) => ({
            id: dropdownValue.id,
            text: dropdownValue.value,
          }));
      }

      return [];
    }
  );

  const { transitionTo } =
    useScreenTransitionManager<ManageTaskFieldsDialogScreenToParametersMap>();

  const { toast } = useToast({ variant });
  const { t } = useTranslations();

  const { updateCustomField, isUpdatingCustomFields } = useTaskFields({
    entityId,
  });

  const hasDropdownType = field.type === "dropdown";
  const isValid = taskCustomFieldNameValidator(internalFieldName);

  const handleSubmit = async () => {
    try {
      await updateCustomField({
        ...field,
        name: internalFieldName,
        isShownInCard: internalIsShownInCard,
        values: hasDropdownType
          ? internalItems.map((item, index) => ({
              id: item.id,
              value: item.text,
              order: index + 1,
            }))
          : undefined,
      });

      transitionTo({ name: "entry" }, { force: true });
      toast(
        t({
          id: "feedback.custom-field-updated",
          defaultMessage: "Custom field has been successfully updated",
        }),
        {
          variant: "success",
        }
      );
    } catch {
      toast(
        t({
          id: "feedback.custom-field-creation-failed",
          defaultMessage: "Failed to create a custom field",
        }),
        { variant: "error" }
      );
    }
  };

  const submitButtonConfig = {
    label: t({
      id: "common.save-changes",
      defaultMessage: "Save changes",
    }),
    isDisabled: !isValid || isUpdatingCustomFields,
    onClick: handleSubmit,
  };

  if (hasDropdownType) {
    return (
      <CustomDropdownFieldValuesScreen
        fieldName={internalFieldName}
        isShownInCard={internalIsShownInCard}
        items={internalItems}
        submitButton={submitButtonConfig}
        onChangeFieldName={setInternalFieldName}
        onChangeIsShownInCard={setInternalIsShownInCard}
        onChangeItems={setInternalItems}
        onCancel={() => transitionTo({ name: "entry" })}
      />
    );
  }

  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center py-6">
      <CustomFieldForm>
        <CustomFieldForm.Heading
          title={t({
            id: "tasks-page.edit-custom-field",
            defaultMessage: "Edit custom field",
          })}
          className="mb-8"
        />
        <CustomFieldForm.FieldInput
          label={t({
            id: "form-controls.custom-field-name.label",
            defaultMessage: "Field name",
          })}
          value={internalFieldName}
          placeholder={t({
            id: "form-controls.custom-field.placeholder",
            defaultMessage: "Enter field name",
          })}
          className="mb-6"
          onChange={setInternalFieldName}
        />
        <CustomFieldForm.IsShownInCardCheckbox
          isChecked={internalIsShownInCard}
          className="mb-10"
          onChange={setInternalIsShownInCard}
        />
        <CustomFieldForm.Actions
          submitButton={submitButtonConfig}
          onCancel={() => transitionTo({ name: "entry" })}
        />
      </CustomFieldForm>
    </BottomCenteredDrawer.Content>
  );
};
