import { FC, useEffect, useMemo } from "react";
import { ChatMessageModel, ChatMessagePayloadType } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { useInView } from "react-intersection-observer";
import useDebounce from "@web-src/hooks/useDebounce";
import { usePrevious } from "react-use";
import {
  ChatMessageProvider,
  ChatMessageProviderProps,
  useChatMessageProvider,
} from "./providers/ChatMessageProvider";
import { ChatMessageBubble } from "./components/ChatMessageBubble";
import { GroupInfoMessage } from "./components/GroupInfoMessage/GroupInfoMessage";
import { DigestBubble } from "./components/DigestBubble";

export type ChatMessageProps = Pick<
  ChatMessageProviderProps,
  | "message"
  | "chatId"
  | "readOnly"
  | "isSelectable"
  | "isSelected"
  | "onSelect"
  | "onTriggerAction"
  | "onReaction"
  | "hasContentProtectionRestriction"
  | "hasBroadcastChatRestriction"
  | "isMessageActionsDisabled"
  | "isFirstUnread"
  | "groupInfo"
> & {
  onShowOnScreen?: (message: ChatMessageModel) => void;
  onReplyPreviewClick?: (message: ChatMessageModel) => void;
  isHighlighted?: boolean;
  isPreview?: boolean;
};

const ChatMessageContent: FC<{
  isFirstUnread?: boolean;
  onShowOnScreen?: (message: ChatMessageModel) => void;
  onReplyPreviewClick?: (message: ChatMessageModel) => void;
  isHighlighted?: boolean;
  isPreview?: boolean;
}> = ({
  isFirstUnread,
  onShowOnScreen,
  onReplyPreviewClick,
  isHighlighted,
  isPreview,
}) => {
  const { message, isOutgoing } = useChatMessageProvider();
  const { t } = useTranslations();
  const { inView, ref } = useInView();
  const debouncedInView = useDebounce(inView, 100);
  const previousInView = usePrevious(debouncedInView);

  useEffect(() => {
    if (debouncedInView === previousInView) {
      return;
    }
    if (debouncedInView) {
      onShowOnScreen?.(message);
    }
  }, [debouncedInView, onShowOnScreen, message, previousInView]);
  const $content = useMemo(() => {
    switch (message.payload?.type) {
      case ChatMessagePayloadType.message:
        return (
          <ChatMessageBubble
            isHighlighted={isHighlighted}
            onReplyPreviewClick={onReplyPreviewClick}
            isPreview={isPreview}
          />
        );
      case ChatMessagePayloadType.group_info:
        return <GroupInfoMessage message={message} />;
      case ChatMessagePayloadType.digest:
        return (
          <div className="flex flex-col items-center gap-1.5 pt-1.5">
            {message.payload.digest?.map((digest) => (
              <DigestBubble
                title={digest.digest}
                type={digest.module}
                date={message.timestamp}
              />
            ))}
          </div>
        );
      default:
        return (
          <div className="px-10">
            <div className="flex h-[40px] items-center justify-center rounded-lg bg-gray-50 px-4 text-center text-sm text-gray-500">
              Message type temporarily unsupported
            </div>
          </div>
        );
    }
  }, [message, isHighlighted, isPreview, onReplyPreviewClick]);
  return (
    <div ref={ref}>
      {isFirstUnread && !isOutgoing && (
        <div className="flex w-full items-center justify-center gap-[6px] py-[36px]">
          <div className="bg-primary-50 h-[1px] w-[100px]" />
          <div className="text-primary-500 bg-primary-50 flex h-[22px] items-center justify-center whitespace-nowrap rounded-[34px] px-[8px] text-[12px] font-[500]">
            {t({
              id: "chats-page.unread-messages",
              defaultMessage: "Unread messages",
            })}
          </div>
          <div className="bg-primary-50 h-[1px] w-[100px]" />
        </div>
      )}
      {$content}
    </div>
  );
};

export const ChatMessage: FC<ChatMessageProps> = (props) => {
  const {
    isFirstUnread,
    onShowOnScreen,
    onReplyPreviewClick,
    isHighlighted,
    isPreview,
  } = props;
  return (
    <ChatMessageProvider {...props}>
      <ChatMessageContent
        isFirstUnread={isFirstUnread}
        onShowOnScreen={onShowOnScreen}
        onReplyPreviewClick={onReplyPreviewClick}
        isHighlighted={isHighlighted}
        isPreview={isPreview}
      />
    </ChatMessageProvider>
  );
};
