import { MobileDrawer } from "@jugl-web/ui-components";
import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import React, { useMemo } from "react";
import { MobileDrawerHeader } from "@jugl-web/ui-components/mobile/MobileDrawer/types";
import { SwipeableHeader } from "@jugl-web/ui-components/mobile/MobileDrawer/SwipeableHeader";
import { useTranslations } from "@jugl-web/utils";
import { MoveToTaskScreen } from "./MoveToTaskScreen";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { DeleteChecklistConfirmationScreen } from "./DeleteChecklistConfirmationScreen";
import { MoveChecklistConfirmationScreen } from "./MoveChecklistConfirmationScreen";
import { TaskChecklistSelectionScreenToParametersMap } from "./types";

interface TaskChecklistSelectionDrawerProps {
  entityId: string;
  taskId: string;
  isOpen: boolean;
  initialScreen: Screen<TaskChecklistSelectionScreenToParametersMap>;
  onClose: () => void;
}

const TaskChecklistSelectionDrawerContent: React.FC<
  Omit<TaskChecklistSelectionDrawerProps, "initialScreen">
> = ({ entityId, taskId, isOpen, onClose }) => {
  const { t } = useTranslations();
  const { meId } = useTaskChecklistContext();

  const { screen, renderContent, transitionTo } =
    useScreenTransitionManager<TaskChecklistSelectionScreenToParametersMap>();

  const header = useMemo(
    () =>
      renderContent<MobileDrawerHeader>({
        moveToTask: {
          title: t({
            id: "tasks-page.move-subtask",
            defaultMessage: "Move Subtask",
          }),
          hasCloseButton: true,
        },
        deleteConfirmation: {
          title: t({
            id: "tasks-page.delete-subtasks",
            defaultMessage: "Delete Subtasks",
          }),
        },
        moveChecklistConfirmation: {
          title: t({
            id: "common.confirm-action",
            defaultMessage: "Confirm action",
          }),
        },
      }),
    [renderContent, t]
  );

  const content = useMemo(
    () =>
      renderContent({
        moveToTask: (
          <MoveToTaskScreen
            entityId={entityId}
            taskId={taskId}
            meId={meId}
            onSelect={(task) =>
              transitionTo({
                name: "moveChecklistConfirmation",
                targetTask: task,
              })
            }
          />
        ),
        deleteConfirmation: (
          <DeleteChecklistConfirmationScreen
            entityId={entityId}
            taskId={taskId}
            onClose={onClose}
          />
        ),
        moveChecklistConfirmation: ({ targetTask }) => (
          <MoveChecklistConfirmationScreen
            targetTask={targetTask}
            onClose={onClose}
          />
        ),
      }),
    [renderContent, entityId, taskId, meId, onClose, transitionTo]
  );

  return (
    <MobileDrawer isOpen={isOpen} size="auto" animateHeight onClose={onClose}>
      <SwipeableHeader {...header} onClose={onClose} />
      <ScreenTransitionWrapper
        screenName={screen.name}
        transitionType="fade"
        transitionTime="fast"
      >
        {content}
      </ScreenTransitionWrapper>
    </MobileDrawer>
  );
};

export const TaskChecklistSelectionDrawer: React.FC<
  TaskChecklistSelectionDrawerProps
> = ({ initialScreen, ...props }) => (
  <ScreenTransitionManagerProvider initialScreen={initialScreen}>
    <TaskChecklistSelectionDrawerContent {...props} />
  </ScreenTransitionManagerProvider>
);
