import {
  DetailedTask,
  TaskChecklistItem,
  TaskDefaultStatusId,
} from "@jugl-web/rest-api/tasks";
import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { useTaskPermissions } from "./useTaskPermissions";

const useChecklistUpdateListener = (
  task: DetailedTask | undefined,
  onChecklistUpdate: (
    updatedChecklist: TaskChecklistItem[],
    previousChecklist: TaskChecklistItem[]
  ) => void
) => {
  const previousTask = useRef<DetailedTask | undefined>(task);

  useEffect(() => {
    if (task && previousTask.current) {
      if (!isEqual(task.checklist, previousTask.current.checklist)) {
        onChecklistUpdate(task.checklist, previousTask.current.checklist);
      }
    }

    previousTask.current = task;
  }, [task, onChecklistUpdate]);
};

interface UseCompleteTaskSuggestionDialogOptions {
  task: DetailedTask | undefined;
  entityId: string;
  meId: string;
  onComplete: () => void;
}

const OPEN_COMPLETE_TASK_SUGGESTION_DIALOG_DELAY = 200;

export const useCompleteTaskSuggestionDialog = ({
  task,
  entityId,
  meId,
  onComplete,
}: UseCompleteTaskSuggestionDialogOptions) => {
  const permissions = useTaskPermissions({ task, entityId, meId });

  const [
    isCompleteTaskSuggestionDismissed,
    setIsCompleteTaskSuggestionDismissed,
  ] = useState(false);

  const [
    isCompleteTaskSuggestionDialogOpen,
    setIsCompleteTaskSuggestionDialogOpen,
  ] = useState(false);

  const openCompleteTaskSuggestionDialogTimeoutRef = useRef<number>();

  const triggerDialogIfRelevant = (
    updatedChecklist: TaskChecklistItem[],
    previousChecklist: TaskChecklistItem[]
  ) => {
    if (
      !permissions.canEditStatus ||
      isCompleteTaskSuggestionDismissed ||
      !task ||
      task.status === TaskDefaultStatusId.completed ||
      !task.checklist.length
    ) {
      return;
    }

    const areAllItemsCompleted = updatedChecklist.every(
      (item) => item.is_completed
    );

    if (!areAllItemsCompleted) {
      return;
    }

    const wereAllItemsCompletedBefore = previousChecklist.every(
      (item) => item.is_completed
    );

    if (wereAllItemsCompletedBefore) {
      return;
    }

    openCompleteTaskSuggestionDialogTimeoutRef.current = window.setTimeout(
      () => setIsCompleteTaskSuggestionDialogOpen(true),
      OPEN_COMPLETE_TASK_SUGGESTION_DIALOG_DELAY
    );
  };

  useChecklistUpdateListener(task, triggerDialogIfRelevant);

  useEffect(
    () => () =>
      window.clearTimeout(openCompleteTaskSuggestionDialogTimeoutRef.current),
    []
  );

  return {
    dialogProps: {
      isOpen: isCompleteTaskSuggestionDialogOpen,
      onComplete,
      onClose: () => {
        setIsCompleteTaskSuggestionDismissed(true);
        setIsCompleteTaskSuggestionDialogOpen(false);
      },
    },
  };
};
