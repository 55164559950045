import React, { useMemo, useState } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import Highlighter from "react-highlight-words";
import { ChatMessageBodyToken } from "@web-src/modules/chats/types";
import ChatMessageMember from "./ChatMessageMember";

const ChatMessageBubbleBody: React.FC<{
  tokens: ChatMessageBodyToken[];
  outgoing: boolean;
  searchQuery?: string;
}> = ({ tokens, outgoing, searchQuery }) => {
  const [isReadingMore, setIsReadingMore] = useState(false);
  const { t } = useTranslations();
  const displayMessage = useMemo(() => {
    if (isReadingMore) {
      return tokens;
    }
    let messageLength = 0;
    let tokenNumber = 0;
    for (let i = 0; i < tokens.length - 1; i += 1) {
      messageLength +=
        tokens[i].humanizedString?.length || tokens[i].rawString?.length || 0;
      tokenNumber += 1;
      if (messageLength > 140) {
        return tokens.slice(0, tokenNumber);
      }
    }
    return tokens;
  }, [isReadingMore, tokens]);

  const textIsTooLong = useMemo(() => {
    const messageLength = tokens.reduce(
      (totalLength, token) =>
        totalLength +
        (token.humanizedString?.length || token.rawString?.length || 0),
      0
    );

    return messageLength > 140;
  }, [tokens]);

  return (
    <p className="m-0">
      {displayMessage.map((item, idx) => {
        const endSpace =
          idx !== tokens.length - 1 && item.rawString !== "\n" ? " " : "";
        switch (item.type) {
          case "mention":
            return (
              <>
                <span>
                  <ChatMessageMember
                    text={
                      <Highlighter
                        autoEscape
                        highlightClassName="bg-primary-200"
                        searchWords={[searchQuery || ""]}
                        textToHighlight={
                          item.value === MENTIONS_ALL_ID
                            ? t({
                                id: "common.all",
                                defaultMessage: "All",
                              })
                            : item.humanizedString || ""
                        }
                      />
                    }
                    userId={item.value}
                  />
                </span>
                {endSpace}
              </>
            );
          case "url":
            return (
              <>
                <a
                  href={item.rawString}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <Highlighter
                    autoEscape
                    highlightClassName="bg-primary-200"
                    searchWords={[searchQuery || ""]}
                    textToHighlight={
                      item.humanizedString || item.rawString || ""
                    }
                  />
                </a>
                {endSpace}
              </>
            );
          default:
            return (
              <span>
                <Highlighter
                  autoEscape
                  highlightClassName="bg-primary-200"
                  searchWords={[searchQuery || ""]}
                  textToHighlight={item.humanizedString || item.rawString || ""}
                />
                {endSpace}
              </span>
            );
        }
      })}
      {textIsTooLong &&
        (isReadingMore || displayMessage.length !== tokens.length) && (
          <span
            className={cx(
              "cursor-pointer font-medium",
              outgoing ? "text-white" : "text-primary-500"
            )}
            onClick={() => setIsReadingMore((prev) => !prev)}
          >
            {" "}
            <span
              className={cx("text-sm font-bold leading-[18px]", {
                "rounded bg-[#0F72C2] px-1": outgoing,
              })}
            >
              {isReadingMore ? (
                <>
                  {t({
                    id: "common.read-less",
                    defaultMessage: "Read Less",
                  })}
                </>
              ) : (
                <>
                  ...
                  {t({
                    id: "common.read-more",
                    defaultMessage: "Read More",
                  })}
                </>
              )}
            </span>
          </span>
        )}
    </p>
  );
};

export default ChatMessageBubbleBody;
