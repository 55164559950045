import { FC, useState } from "react";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import Lottie from "react-lottie";
import { cx } from "@jugl-web/utils";
import animationData from "./assets/loading-animation.json";
import { ReactComponent as ImagePlaceholder } from "./assets/image-placeholder.svg";
import { ReactComponent as PlayIcon } from "./assets/play-icon.svg";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";

const MAX_SIZE = 300;
const getDimensions = (
  width?: number,
  height?: number
): { width: number; height: number } | null => {
  if (!width || !height) {
    return null;
  }
  let reduceBy = 1;
  if (width >= height && width > MAX_SIZE) {
    reduceBy = MAX_SIZE / width;
  } else if (width <= height && height > MAX_SIZE) {
    reduceBy = MAX_SIZE / height;
  }
  return {
    width: Math.round(width * reduceBy),
    height: Math.round(height * reduceBy),
  };
};

export const MediaPreviewAttachment: FC = () => {
  const {
    getAttachmentUrls,
    attachment,
    isMessageActionsDisabled,
    hasContentProtectionRestriction,
  } = useChatMessageProvider();
  if (
    !attachment ||
    ![
      ChatMessageAttachmentType.image,
      ChatMessageAttachmentType.video,
      ChatMessageAttachmentType.gif,
    ].includes(attachment.type)
  ) {
    throw new Error(
      `Message doesn't have attachment or attachment type "${attachment?.type}" is not supported`
    );
  }

  const { previewFile } = useFilePreview();
  const [previewState, setPreviewState] = useState<
    "loading" | "error" | "loaded"
  >("loading");

  if (!attachment) {
    return null;
  }

  const dimensions = getDimensions(attachment.width, attachment.height);
  const { stream: streamUrl, preview: previewUrl } =
    getAttachmentUrls(attachment);

  return (
    <div
      className="cursor-pointer overflow-hidden rounded"
      style={{
        background: "linear-gradient(180deg, #F4F6F7 0%, #CDE2F2 100%)",
      }}
      onClick={() => {
        if (
          isMessageActionsDisabled ||
          attachment.type === ChatMessageAttachmentType.gif
        ) {
          return;
        }
        previewFile({
          url: streamUrl,
          // TODO: i18n
          name: attachment.name || attachment.caption || "Chat Image",
          mimeType: attachment.mimetype,
          onDownload: !hasContentProtectionRestriction
            ? () => window.open(streamUrl, "_blank")
            : undefined,
        });
      }}
    >
      <div
        className={cx("relative mx-auto overflow-hidden rounded", {
          "cursor-pointer": attachment.type !== ChatMessageAttachmentType.gif,
        })}
        style={{
          width: `${dimensions?.width || MAX_SIZE}px`,
          height: `${dimensions?.height || MAX_SIZE}px`,
        }}
      >
        {previewState === "loading" && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Lottie options={{ animationData }} height={32} width={32} />
          </div>
        )}
        {previewState === "error" && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer">
            {attachment.type === ChatMessageAttachmentType.video ? (
              <PlayIcon />
            ) : (
              <ImagePlaceholder />
            )}
          </div>
        )}
        {previewState === "loaded" &&
          attachment.type === ChatMessageAttachmentType.video && (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer">
              <PlayIcon />
            </div>
          )}
        <img
          onLoad={() => setPreviewState("loaded")}
          onError={() => setPreviewState("error")}
          className={cx("mx-auto block", {
            invisible: previewState !== "loaded",
          })}
          style={
            dimensions
              ? {
                  width: `${dimensions.width}px`,
                  height: `${dimensions.height}px`,
                }
              : {
                  height: "100%",
                  maxWidth: `${MAX_SIZE}px`,
                  maxHeight: `${MAX_SIZE}px`,
                }
          }
          src={
            attachment.type === ChatMessageAttachmentType.gif
              ? streamUrl
              : previewUrl
          }
          alt={attachment.name}
        />
      </div>
    </div>
  );
};
