import { PlainButton } from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import {
  cx,
  priceDisplay,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { ServiceCartItem } from "../../types";
import { ReactComponent as ImageIcon } from "../../assets/image.svg";
import { ReactComponent as MinusIcon } from "../../assets/minus.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";
import { OrderFormInventorySelectValueItem } from "../../../OrderFormInventorySelect";

export const ServiceCartList: React.FC<{
  items: ServiceCartItem[];
  isPreview: boolean;
  currency: string;
  discounts: Record<string, { discount: string; value: number }>;
  value: OrderFormInventorySelectValueItem[];
  onUpdateService: (serivceId: string, newValue: number) => void;
  onRemoveService: (serivceId: string) => void;
}> = ({
  items,
  isPreview,
  currency,
  discounts,
  value,
  onUpdateService,
  onRemoveService,
}) => {
  const { inputProps, reset, searchQuery } = useSearchInput();
  const { t } = useTranslations();

  const filteredItems = useMemo(
    () =>
      items.filter((item) =>
        item.item?.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [items, searchQuery]
  );

  return (
    <>
      <div className="py-4 px-7">
        <SearchInput
          variant="filled"
          color="grey"
          onReset={reset}
          onClear={reset}
          {...inputProps}
        />
      </div>
      <div
        className={cx(
          "jugl__custom-scrollbar flex h-[350px] flex-col overflow-y-auto py-4"
        )}
      >
        {filteredItems.length ? (
          filteredItems.map((item, idx) => {
            if (!item.item) return null;

            const img =
              item.item.img?.find((el) => el.order === 1)?.path ||
              item.item.img?.[0]?.path;

            const qty = value.find(
              (el) => el.inventory_id === item.itemId
            )?.qty;

            const discount = isPreview
              ? discounts[item.itemId]?.value
              : item.item.discount?.discount;

            return (
              <React.Fragment key={item.itemId}>
                <div className="flex shrink-0 flex-col gap-3 py-4 px-7">
                  <div className="flex items-center justify-between gap-3">
                    <div className="flex items-center gap-3 overflow-hidden">
                      {img ? (
                        <div className="h-6 w-6 shrink-0 overflow-hidden rounded-[4px]">
                          <img
                            src={img}
                            alt={item.item.name}
                            className="h-full w-full object-cover"
                            title={item.item.name}
                          />
                        </div>
                      ) : (
                        <div className="border-grey-400 flex h-6 w-6 shrink-0 items-center justify-center rounded-[4px] border border-solid bg-[#F2F2F4]">
                          <ImageIcon />
                        </div>
                      )}
                      <span className="text-dark font-secondary overflow-hidden break-words text-sm font-medium leading-[140%] tracking-[0.14px]">
                        <Highlighter
                          autoEscape
                          highlightClassName="text-primary bg-transparent"
                          searchWords={[searchQuery || ""]}
                          textToHighlight={item.item.name}
                        />
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      {discount && (
                        <span className="text-gradients-danger font-secondary text-sm font-medium leading-[140%] tracking-[0.14px]">
                          {!!qty &&
                            priceDisplay(
                              parseFloat(item.item.price) *
                                qty *
                                100 *
                                (1 - discount / 100),
                              currency
                            )}
                        </span>
                      )}
                      <span
                        className={cx(
                          "text-dark font-secondary font-medium leading-[140%]",
                          discount
                            ? "text-xs tracking-[0.12px] line-through"
                            : "text-sm tracking-[0.14px]"
                        )}
                      >
                        {!!qty &&
                          priceDisplay(
                            parseFloat(item.item.price) * qty * 100,
                            currency,
                            !!discount
                          )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-[5px]">
                    <PlainButton
                      className="border-dark-100 hover:bg-grey-100 flex h-8 w-8 items-center justify-center rounded-lg border border-solid outline-none transition-colors"
                      onClick={() => {
                        if (!qty || qty === 1 || !item.item?.unit) {
                          onRemoveService(item.itemId);
                          return;
                        }
                        onUpdateService(item.itemId, qty - 1);
                      }}
                    >
                      {!qty || qty === 1 || !item.item.unit ? (
                        <TrashIcon />
                      ) : (
                        <MinusIcon />
                      )}
                    </PlainButton>
                    {item.item.unit && (
                      <>
                        <input
                          className="border-dark-100 text-dark font-secondary flex h-8 w-20 items-center justify-center rounded-lg border border-solid text-center text-sm outline-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                          type="number"
                          onWheel={(e) => e.currentTarget.blur()}
                          value={qty === 0 ? undefined : qty}
                          onKeyDown={(e) => {
                            const notAllowedCharacters = ["e", "E", "+", "-"];
                            if (notAllowedCharacters.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            let qtyValue = e.target.value
                              ? parseInt(e.target.value as string, 10)
                              : 0;
                            if (qtyValue && qtyValue < 0) {
                              qtyValue *= -1;
                            }
                            onUpdateService(item.itemId, qtyValue);
                          }}
                          onBlur={() => {
                            if (!qty) {
                              onRemoveService(item.itemId);
                            }
                          }}
                        />
                        <PlainButton
                          className="border-dark-100 hover:bg-grey-100 flex h-8 w-8 items-center justify-center rounded-lg border border-solid outline-none transition-colors"
                          onClick={() => {
                            if (!qty) {
                              onRemoveService(item.itemId);
                              return;
                            }
                            onUpdateService(item.itemId, qty + 1);
                          }}
                        >
                          <PlusIcon />
                        </PlainButton>
                      </>
                    )}
                  </div>
                </div>
                {idx !== filteredItems.length - 1 && (
                  <div className="bg-grey-200 my-1 h-px w-full shrink-0" />
                )}
              </React.Fragment>
            );
          })
        ) : value.length ? (
          <span className="font-secondary my-6 text-center text-sm leading-[160%] text-[#828282]">
            {t({
              id: "order-form-submission-component.no-results",
              defaultMessage: "No results 😔",
            })}
          </span>
        ) : null}
      </div>
    </>
  );
};
