import { CustomFieldForm } from "@jugl-web/domain-resources/custom-fields/components/CustomFieldForm/CustomFieldForm";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useEffect, useState } from "react";
import {
  UserCustomField,
  UserCustomFieldType,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { DropdownValueListItem } from "@jugl-web/domain-resources/custom-fields/components/DropdownValueList/DropdownValueList";
import { ManageProfileAttributesDialogScreenToParametersMap } from "../types";
import { UserCustomDropdownFieldValuesScreen } from "./UserCustomDropdownFieldValuesScreen";
import { USER_CUSTOM_FIELD_TYPES } from "../consts";

interface EditUserCustomFieldScreenProps {
  propId: string;
  userCustomFieldId: string;
  userCustomFields: UserCustomField[];
}

export const EditUserCustomFieldScreen: FC<EditUserCustomFieldScreenProps> = ({
  propId,
  userCustomFieldId,
  userCustomFields,
}) => {
  const [internalFieldName, setInternalFieldName] = useState("");
  const [internalFieldType, setInternalFieldType] =
    useState<UserCustomFieldType>("text");
  const [internalItems, setInternalItems] = useState<DropdownValueListItem[]>(
    []
  );

  const { transitionTo } =
    useScreenTransitionManager<ManageProfileAttributesDialogScreenToParametersMap>();

  const hasDropdownType = internalFieldType === "dropdown";

  useEffect(() => {
    // We search for the field we want to edit and retrieve its data
    // If not found, we redirect to the main screen

    const editedField = userCustomFields.find(
      (field) => field.id === userCustomFieldId
    );

    if (editedField) {
      setInternalFieldName(editedField.name);
      setInternalFieldType(editedField.type);
      if (editedField.type === "dropdown") {
        setInternalItems(
          editedField.values.map((item) => ({
            id: item.id,
            text: item.value,
          }))
        );
      }
      return;
    }

    transitionTo({ name: "entry" });
  }, [userCustomFieldId, userCustomFields, transitionTo]);

  const { usersApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();

  const [updateUserCustomFields, { isLoading }] =
    usersApi.useUpdateUserCustomFieldsMutation();

  const { toast } = useToast();
  const { t } = useTranslations();

  const handleSubmit = async () => {
    const response = await updateUserCustomFields({
      entityId,
      propId,
      value: userCustomFields.map((userCustomField) => {
        if (userCustomField.id === userCustomFieldId) {
          if (userCustomField.type === "dropdown") {
            return {
              ...userCustomField,
              name: internalFieldName,
              values: internalItems.map((item, idx) => ({
                id: item.id,
                order: idx + 1,
                value: item.text,
              })),
            };
          }

          return {
            ...userCustomField,
            name: internalFieldName,
          };
        }

        return userCustomField;
      }),
    });

    if (response && "data" in response) {
      toast(
        t({
          id: "feedback.custom-field-updated",
          defaultMessage: "Custom field has been successfully updated",
        })
      );
      transitionTo({ name: "entry" }, { force: true });
    }
  };

  if (hasDropdownType) {
    return (
      <UserCustomDropdownFieldValuesScreen
        fieldName={internalFieldName}
        items={internalItems}
        submitButton={{
          label: t({
            id: "common.save",
            defaultMessage: "Save",
          }),
          isDisabled:
            !internalFieldName.length || isLoading || !internalItems.length,
          onClick: handleSubmit,
        }}
        onChangeFieldName={setInternalFieldName}
        onChangeItems={setInternalItems}
        onCancel={() => transitionTo({ name: "entry" })}
      />
    );
  }

  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center py-6">
      <CustomFieldForm>
        <CustomFieldForm.Heading
          title={t({
            id: "cpanel-page.edit-custom-field",
            defaultMessage: "Edit custom field",
          })}
          subtitle={t({
            id: "cpanel-page.not-possible-tochange-field-type",
            defaultMessage:
              "Once Field is created, it is not possible to change the Field type",
          })}
          className="mb-8"
        />
        <CustomFieldForm.FieldInput
          label={t({
            id: "form-controls.custom-field-name.label",
            defaultMessage: "Field name",
          })}
          value={internalFieldName}
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          className="mb-6"
          onChange={(value) => setInternalFieldName(value.trimStart())}
        />
        <CustomFieldForm.FieldTypeSelect
          fieldTypes={USER_CUSTOM_FIELD_TYPES}
          selectedType={internalFieldType}
          className="mb-6"
        />
        <CustomFieldForm.Actions
          submitButton={{
            label: t({
              id: "common.save",
              defaultMessage: "Save",
            }),
            isDisabled: !internalFieldName.length || isLoading,
            onClick: handleSubmit,
          }}
          onCancel={() => transitionTo({ name: "entry" })}
        />
      </CustomFieldForm>
    </BottomCenteredDrawer.Content>
  );
};
