import { useRestApiProvider } from "@jugl-web/rest-api";
import { WidgetModel } from "@jugl-web/rest-api/dashboard/models/Widget";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { useToast, useTranslations } from "@jugl-web/utils";
import { assert } from "@jugl-web/utils/assert";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC } from "react";
import { useDashboardContext } from "../../hooks/useDashboardContext";

interface DashboardWidgetDeleteConfirmationDialogProps {
  isOpen: boolean;
  widget: WidgetModel | null;
  onClose: () => void;
  onAfterDelete?: () => void;
}

export const DashboardWidgetDeleteConfirmationDialog: FC<
  DashboardWidgetDeleteConfirmationDialogProps
> = ({ isOpen, widget, onClose, onAfterDelete }) => {
  const { entityId } = useEntitySelectedProvider();
  const { detailedDashboard } = useDashboardContext();

  assert(!!detailedDashboard, "detailedDashboard is required at this point");

  const { dashboardApi } = useRestApiProvider();

  const [deleteWidget, { isLoading }] = dashboardApi.useDeleteWidgetMutation();

  const { t } = useTranslations();
  const { toast } = useToast();

  const handleDelete = async () => {
    if (!widget) {
      return;
    }

    const response = await deleteWidget({
      entityId,
      dashboardId: detailedDashboard.id,
      widgetId: widget.id,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.widget-deleted",
          defaultMessage: "Widget has been deleted",
        })
      );
      onClose();
      onAfterDelete?.();
    }
  };

  return (
    <Alert
      isOpen={isOpen}
      title={t({
        id: "dashboard-page.delete-widget-confirmation-title",
        defaultMessage: "Delete widget",
      })}
      content={t(
        {
          id: "dashboard-page.delete-widget-confirmation-description",
          defaultMessage: "Are you sure you want to delete widget {title}?",
        },
        {
          title: (
            <span className="text-primary-800 font-medium">
              {widget?.title}
            </span>
          ),
        }
      )}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          color: "tertiary",
          isDisabled: isLoading,
          onClick: (_, actions) => {
            handleDelete();
            actions.closeAlert();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
