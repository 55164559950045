import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { TaskDefaultStatusId } from "@jugl-web/rest-api/tasks";
import { isoToLocalDate, useTranslations } from "@jugl-web/utils";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { AddNewCustomerDialog } from "@web-src/modules/customers/components/AddNewCustomerDialog";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { FC, useState } from "react";
import { ReactComponent as AddCustomerIcon } from "@web-src/pages/TaskDetails/assets/add-customer.svg";
import { useLoadedTaskDetailsContext } from "../../../TaskDetails/hooks/useTaskDetailsContext";

export const TaskPreviewGeneralInfo: FC = () => {
  const { task, updateTask } = useLoadedTaskDetailsContext();

  const { entityId } = useEntitySelectedProvider();
  const { meId, isAdmin } = useLoadedMe();

  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const [isAddNewCustomerDialogOpen, setIsAddNewCustomerDialogOpen] =
    useState(false);

  const permissions = useTaskPermissions({ task, entityId, meId });

  const { timeZone } = useTimeZone();
  const { t } = useTranslations();

  const { hasAccess: hasBoardAccess } = useTaskBoards({ entityId });

  return (
    <>
      {(task.desc || isEditingDescription) && (
        <DescriptionBox
          description={task.desc || ""}
          isEditing={isEditingDescription}
          isEditable={permissions.canEditDescription}
          onChange={(value) => updateTask({ desc: value })}
          onStartEditing={() => setIsEditingDescription(true)}
          onFinishEditing={() => {
            setIsEditingDescription(false);
          }}
          isPreview
        />
      )}
      <TaskPropertiesPanel
        entityId={entityId}
        config={{
          board: {
            boardId: task.board_id,
            hasBoardAccess: task.board_id
              ? hasBoardAccess(task.board_id)
              : undefined,
            isReadonly: !permissions.canEditBoard,
            isHidden: task.is_self,
            shouldShowUpdateToast: true,
            onChange: (boardId) => updateTask({ board_id: boardId }),
          },
          description: {
            isHidden:
              isEditingDescription ||
              !!task.desc ||
              !permissions.canEditDescription,
            onClick: () => setIsEditingDescription(true),
          },
          status: {
            statusId: task.status,
            isReadonly: !permissions.canEditStatus,
            onChange: (value) => updateTask({ status: value }),
          },
          dueDate: {
            state: task.due_at
              ? {
                  date: isoToLocalDate(task.due_at),
                  timezone: task.tz || undefined,
                  reminderExecutionTime: task.reminder
                    ? isoToLocalDate(task.reminder.next_execution)
                    : undefined,
                }
              : null,
            defaultTimezone: timeZone.name,
            displayAs: "date",
            withAdvancedOptions: task.status !== TaskDefaultStatusId.completed,
            isReadonly: !permissions.canEditDueDate,
            onChange: (state) => {
              if (!state) {
                updateTask({ due_at: null, tz: null, reminder: null });
                return;
              }

              updateTask({
                due_at: state.date.toISOString(),
                tz: state.timezone || null,
                reminder: state.reminderExecutionTime
                  ? {
                      next_execution: state.reminderExecutionTime.toISOString(),
                    }
                  : null,
              });
            },
          },
          assignees: {
            assigneeIds: task.assignees,
            isReadonly: !permissions.canEditAssignees,
            onChange: (assigneeIds) => updateTask({ assignees: assigneeIds }),
            isHidden: task.is_self,
          },
          priority: {
            priority: task.priority,
            isReadonly: !permissions.canEditPriority,
            onChange: (priority) => updateTask({ priority }),
          },
          label: {
            labelId: task.label_id,
            isReadonly: !permissions.canEditLabel,
            onChange: (labelId) => updateTask({ label_id: labelId }),
          },
          customer: {
            customerId: task.cust_id,
            isReadonly: !permissions.canEditCustomer,
            shouldWarnBeforeUpdate: true,
            isHidden: task.is_self,
            addButton: isAdmin
              ? {
                  label: t({
                    id: "tasks-page.add-new-customer",
                    defaultMessage: "Add customer",
                  }),
                  onClick: () => setIsAddNewCustomerDialogOpen(true),
                  startIcon: <AddCustomerIcon />,
                }
              : undefined,
            onChange: async (customer) =>
              updateTask({
                cust_id: customer?.id || null,
              }),
          },
          timeSpent: {
            duration: task.duration,
            isReadonly: !permissions.canEditTimeSpentOnTask,
            onChange: (duration) => updateTask({ duration }),
          },
          recurrence: {
            state: task.recurrence,
            defaultTimezone: timeZone.name,
            shouldWarnBeforeRemoving: true,
            isReadonly: !permissions.canEditRecurrence,
            onChange: (recurrence) => updateTask({ recurrence }),
          },
          customFields: {
            onFieldChange: (fieldId, rawValue) =>
              updateTask({
                custom_fields: {
                  ...task.custom_fields,
                  [fieldId]: rawValue || "",
                },
              }),
            valuesById: task.custom_fields || {},
            isReadonly: !permissions.canEditCustomFields,
          },
        }}
        className="mt-7"
      />
      <TaskExtraPropertiesPanel
        entityId={entityId}
        config={{
          privateTask: {
            isHidden: !task.is_self,
            type: "presentational",
          },
          completeChecklistInOrder: {
            isChecked: task.has_chklist_chk,
            isHidden: !permissions.canManageCompleteInSpecificOrderOption,
            onChange: (isChecked) => updateTask({ has_chklist_chk: isChecked }),
          },
        }}
        className="mt-8"
      />
      <AddNewCustomerDialog
        isOpen={isAddNewCustomerDialogOpen}
        onClose={() => setIsAddNewCustomerDialogOpen(false)}
        onSuccess={(customer) => updateTask({ cust_id: customer.id })}
      />
    </>
  );
};
