import { ManageTaskBoardDialog } from "@jugl-web/domain-resources/tasks/components/ManageTaskBoardDialog";
import {
  InteractiveContainer,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useTranslations } from "@jugl-web/utils";
import { useSpotlight } from "@jugl-web/utils/hooks/useSpotlight";
import { CREATE_BOARD_SPOTLIGHT_KEY } from "@jugl-web/utils/storage";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { FC, useCallback, useState } from "react";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import useEntity from "../../hooks/useEntity";
import { useMe } from "../../hooks/useMe";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { ReactComponent as CreateBoardIcon } from "./assets/create-board.svg";
import { Divider } from "./components/Divider";
import { EntitiesUpdatesPopup } from "./components/EntitiesUpdatesPopup";
import { SidebarItemComponent } from "./components/SidebarItemComponent";
import { SidebarLinkButton } from "./components/SidebarLinkButton";
import styles from "./HomeSidebar.module.css";
import { useEntitiesUpdatesPopup } from "./hooks/useEntitiesUpdatesPopup";
import { useHomeSidebar } from "./hooks/useHomeSidebar";
import { SidebarItem } from "./types";

export const HomeSidebar: FC = () => {
  const [isNewBoardDialogOpen, setIsNewBoardDialogOpen] = useState(false);

  const { entity, list } = useEntity();
  const { hasEmployeeLikeRole, isMember } = useUserRole();

  const { navigateToPage } = useNavigation();
  const { me } = useMe();
  const { t } = useTranslations();
  const { tcx, customTheme } = useTheme();

  const {
    sidebarItems,
    taskBoardSidebarItems,
    isSidebarExpanded,
    updateIsSidebarExpanded,
  } = useHomeSidebar();

  const {
    isEntitiesUpdatesPopupOpen,
    lastUpdatedEntityId,
    onCloseEntitiesUpdatesPopup,
  } = useEntitiesUpdatesPopup({ entityId: entity?.id });

  const { isActive: isSpotlightActive, markAsSeen: markSpotlightAsSeen } =
    useSpotlight({
      id: CREATE_BOARD_SPOTLIGHT_KEY,
      delay: 200,
    });

  const hasTaskBoardsAccess =
    !!entity && hasRoleAccessToModule(entity.role, AppModule.tasks);
  const canCreateTaskBoard = !hasEmployeeLikeRole && !isMember;

  const renderSidebarItems = useCallback(
    (items: SidebarItem[]) =>
      items.map((item) => (
        <SidebarItemComponent
          key={item.title}
          item={item}
          isExpanded={isSidebarExpanded}
          onSideBarExpand={() => updateIsSidebarExpanded(true)}
          itemsRenderer={renderSidebarItems}
        />
      )),
    [isSidebarExpanded, updateIsSidebarExpanded]
  );

  return (
    <>
      {entity?.id && me?.id && (
        <ManageTaskBoardDialog
          entityId={entity.id}
          meId={me.id}
          isOpen={isNewBoardDialogOpen}
          onClose={() => setIsNewBoardDialogOpen(false)}
          onAfterCreateBoard={(boardId) =>
            navigateToPage("tasksTasks", { boardId })
          }
        />
      )}
      <MultiSectionLayout>
        <div
          className={tcx(
            "group/homeSideBar flex h-full flex-col overflow-x-hidden border-0 border-r-[1px] border-solid border-[#D6D6DE] transition-[width] duration-300",
            isSidebarExpanded ? "w-[210px]" : "w-[80px]",
            {
              blue: "bg-primary-700",
              dark: "bg-[#333333]",
              light: "bg-white",
            }
          )}
        >
          <div className="relative flex shrink-0 flex-col">
            <InteractiveContainer
              className={tcx(
                "bg-primary-600 fixed top-[48px] z-[51] flex h-6 w-6 translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-[1.5px] border-solid border-white text-white opacity-0 transition-all duration-300 group-hover/homeSideBar:opacity-100",
                isSidebarExpanded && "-rotate-180",
                isSidebarExpanded ? "left-[185px]" : "left-[52px]",
                {
                  blue: "bg-primary-600",
                  dark: "bg-[#3E3F40]",
                  light: "bg-[#42A4F5]",
                }
              )}
              onClick={() => {
                markSpotlightAsSeen();
                updateIsSidebarExpanded(!isSidebarExpanded);
              }}
            >
              <ChevronRightIcon />
            </InteractiveContainer>
          </div>
          <div
            className={tcx(
              "mr-[1px] flex grow flex-col gap-0.5 overflow-y-auto overflow-x-hidden pt-3 pb-4",
              isSidebarExpanded ? "items-start" : "items-center",
              styles.homeSideBarScrollbar,
              {
                blue: styles.homeSideBarScrollbarBlue,
                dark: styles.homeSideBarScrollbarDark,
                light: styles.homeSideBarScrollbarLight,
              }[customTheme]
            )}
          >
            {renderSidebarItems(sidebarItems)}
            {hasTaskBoardsAccess && (
              <>
                <div className="w-full py-4">
                  <Divider />
                </div>
                {canCreateTaskBoard && (
                  <SpotlightTooltip
                    isOpen={isSpotlightActive}
                    onDismiss={markSpotlightAsSeen}
                    placement="right-end"
                    className="z-[20]"
                    tip={t({
                      id: "home-sidebar-component.tap-to-create-new-task-board",
                      defaultMessage: "Tap here to create new Tasks Board ✨",
                    })}
                    showButton
                    renderTrigger={({ ref, props }) => (
                      <div ref={ref} {...props}>
                        <SidebarLinkButton
                          icon={
                            <CreateBoardIcon
                              className={tcx("fill-white", {
                                light: "fill-primary-700",
                              })}
                            />
                          }
                          text={t({
                            id: "home-sidebar-component.create-board",
                            defaultMessage: "Create Board",
                          })}
                          isSidebarExpanded={isSidebarExpanded}
                          tooltip={
                            !isSpotlightActive
                              ? t({
                                  id: "home-sidebar-component.create-board",
                                  defaultMessage: "Create Board",
                                })
                              : undefined
                          }
                          onClick={() => {
                            markSpotlightAsSeen();
                            setIsNewBoardDialogOpen(true);
                          }}
                        />
                      </div>
                    )}
                  />
                )}
                {taskBoardSidebarItems.map((item) => (
                  <SidebarItemComponent
                    key={item.title}
                    item={item}
                    isExpanded={isSidebarExpanded}
                    onSideBarExpand={() => updateIsSidebarExpanded(true)}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </MultiSectionLayout>
      <EntitiesUpdatesPopup
        isOpen={isEntitiesUpdatesPopupOpen}
        entities={list}
        lastUpdatedEntityId={lastUpdatedEntityId}
        onClose={onCloseEntitiesUpdatesPopup}
      />
    </>
  );
};
