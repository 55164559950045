import {
  HeadlessUsersList,
  HeadlessUsersListHandle,
} from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import React, { useCallback, useRef, useState } from "react";
import {
  UserEntityStatus,
  UserGeneralProfile,
  UserWorkspaceRole,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { setOpenedUserProfile } from "@web-src/modules/chats/store/chatsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { HeadlessUserItem } from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { getUserRoleFlags } from "@jugl-web/domain-resources/users/utils/getUserRoleFlags";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as ProfileIcon } from "./assets/profile.svg";
import { ReactComponent as AdminIcon } from "./assets/admin.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ChatInfoLoading } from "../ChatInfoLoading";
import { ChatInfoError } from "../ChatInfoError";
import { ChatInfoEmptyContent } from "../ChatInfoEmptyContent";
import { InfoTabId } from "../../types";
import { GroupAdminManagementAlert } from "../../../GroupAdminManagementAlert";
import { SelectMembersDrawer } from "../../../SelectMembersDrawer";
import { RemoveMemberAlert } from "./components/RemoveMemberAlert";
import { RestrictionMemberAlert } from "./components/RestrictionMemberAlert";

export const ChatInfoMembers: React.FC<{
  chatId: string;
  isActive: boolean;
}> = ({ chatId, isActive }) => {
  const { chat } = useActiveChat();
  const { entity } = useEntity();
  const { hasEmployeeLikeRole } = useUserRole();
  const { inputProps, searchQuery, reset } = useSearchInput();
  const [isSelectMembersDrawerOpen, setIsSelectMembersDrawerOpen] =
    useState(false);
  const $headlessUsersList = useRef<HeadlessUsersListHandle | null>(null);
  const { t } = useTranslations();
  const { workspacesApi } = useRestApiProvider();
  const [
    deleteWorkspaceParticipant,
    { isLoading: isDeleteWorkspaceParticipantLoading },
  ] = workspacesApi.useDeleteWorkspaceParticipantMutation();
  const [changeRoleWorkspaceParticipants] =
    workspacesApi.useChangeWorkspaceParticipantsRoleMutation();
  const dispatch = useDispatch();
  const meId = useSelector(selectUserId);
  const groupAdminManagementAlertState = useConfirmationDialogState<{
    userId: string;
    type: "assign" | "remove";
  }>();
  const removeMemberAlertState = useConfirmationDialogState<{
    name: string;
  }>();
  const restrictionMemberAlertState = useConfirmationDialogState<{
    type: "access" | "restrictions" | "suspended";
  }>();
  const [addWorkspaceParticipants, { isLoading }] =
    workspacesApi.useAddWorkspaceParticipantsMutation();

  const handleAddWorkspaceParticipants = useCallback(
    async (users: UserGeneralProfile[]) => {
      if (!chatId || !entity?.id) {
        return;
      }
      const response = await addWorkspaceParticipants({
        participants: users.map(({ id }) => id),
        entityId: entity.id,
        id: chatId,
      });
      if ("data" in response) {
        setTimeout(() => {
          $headlessUsersList.current?.refetch();
        }, 300);
        setIsSelectMembersDrawerOpen(false);
      }
    },
    [entity?.id, setIsSelectMembersDrawerOpen, addWorkspaceParticipants, chatId]
  );

  const handleRemoveFromGroup = useCallback(
    (user: UserGeneralProfile) => {
      if (!chatId || !entity?.id) {
        return;
      }
      removeMemberAlertState.open({
        metadata: {
          name: user.displayName,
        },
        onConfirm: async () => {
          const response = await deleteWorkspaceParticipant({
            id: chatId,
            entityId: entity.id,
            userId: user.id,
          });

          if (response && "data" in response) {
            setTimeout(() => {
              $headlessUsersList.current?.refetch();
            }, 1000);

            removeMemberAlertState.close();
          }
        },
      });
    },
    [entity?.id, deleteWorkspaceParticipant, chatId, removeMemberAlertState]
  );

  const handleChangeUserWorkspaceRole = useCallback(
    (user: HeadlessUserItem, role: UserWorkspaceRole) => {
      if (!chatId || !entity?.id) {
        return;
      }
      changeRoleWorkspaceParticipants({
        entityId: entity.id,
        workspaceId: chatId,
        users: [user.id],
        role,
      }).then(() => {
        setTimeout(() => {
          $headlessUsersList.current?.refetch();
        }, 1000);
      });
    },
    [entity?.id, chatId, changeRoleWorkspaceParticipants]
  );

  const handleOpenUserProfile = useCallback(
    (user: UserGeneralProfile) => {
      dispatch(setOpenedUserProfile(user.id));
    },
    [dispatch]
  );

  const menuItems = useCallback(
    (user: HeadlessUserItem) => {
      if (chat?.deleted || user.id === meId) {
        return undefined;
      }

      const isUserActive = user.status === UserEntityStatus.active;

      const items: {
        text: string;
        icon?: JSX.Element;
        onClick: (profile: UserGeneralProfile) => void;
      }[] = [];

      if (isUserActive) {
        items.push({
          text: t({
            id: "chats-page.open-profile",
            defaultMessage: "Open profile",
          }),
          icon: <ProfileIcon />,
          onClick: handleOpenUserProfile,
        });
      }

      if (chat?.role !== "admin") {
        return items;
      }

      const isUserGroupAdmin = user.groupRole === "admin";
      const isUserEmployee = getUserRoleFlags(user.role).hasEmployeeLikeRole;
      const hasUserChatAccess = hasRoleAccessToModule(
        user.role,
        AppModule.chat
      );

      if (isUserGroupAdmin) {
        if (isUserActive) {
          items.push({
            text: t({
              id: "chats-page.remove-admin-role",
              defaultMessage: "Remove Admin role",
            }),
            icon: <AdminIcon />,
            onClick: () =>
              groupAdminManagementAlertState.open({
                onConfirm: () => handleChangeUserWorkspaceRole(user, null),
                metadata: {
                  type: "remove",
                  userId: user.id,
                },
              }),
          });
        }
      } else {
        items.push({
          text: t({
            id: "chats-page.assign-admin-role",
            defaultMessage: "Assign Admin role",
          }),
          icon: <AdminIcon />,
          onClick: () => {
            if (!isUserActive) {
              restrictionMemberAlertState.open({
                metadata: {
                  type: "suspended",
                },
              });
              return;
            }

            if (!hasUserChatAccess) {
              restrictionMemberAlertState.open({
                metadata: {
                  type: "access",
                },
              });
              return;
            }

            if (isUserEmployee) {
              restrictionMemberAlertState.open({
                metadata: {
                  type: "restrictions",
                },
              });
              return;
            }

            groupAdminManagementAlertState.open({
              onConfirm: () => handleChangeUserWorkspaceRole(user, "admin"),
              metadata: {
                type: "assign",
                userId: user.id,
              },
            });
          },
        });
      }

      items.push({
        text: t({
          id: "chats-page.remove-from-group",
          defaultMessage: "Remove from group",
        }),
        icon: <DeleteIcon />,
        onClick: handleRemoveFromGroup,
      });

      return items;
    },
    [
      restrictionMemberAlertState,
      handleChangeUserWorkspaceRole,
      groupAdminManagementAlertState,
      handleRemoveFromGroup,
      meId,
      t,
      handleOpenUserProfile,
      chat,
    ]
  );

  if (!isActive) return null;
  return (
    <>
      <div className="flex h-full flex-col py-6">
        <div className="flex flex-col gap-6 px-8 pb-[11px]">
          <SearchInput
            {...inputProps}
            variant="filled"
            color="grey"
            onReset={reset}
            onClear={reset}
          />
          {!chat?.deleted && chat?.role === "admin" && !hasEmployeeLikeRole && (
            <InteractiveContainer
              className="bg-grey-100 hover:bg-grey-200 flex w-full items-center justify-between gap-4 rounded-xl py-2 px-3 transition-colors"
              isDisabled={isLoading}
              onClick={() => setIsSelectMembersDrawerOpen(true)}
            >
              <div className="flex items-center gap-3">
                <div className="bg-primary flex h-[38px] w-[38px] items-center justify-center rounded-full">
                  <UserIcon />
                </div>
                <span className="text-dark font-secondary leading-4">
                  {t({
                    id: "chats-page.add-members",
                    defaultMessage: "Add members",
                  })}
                </span>
              </div>
              <PlusIcon />
            </InteractiveContainer>
          )}
        </div>
        <div className="h-full overflow-y-auto pb-px">
          <HeadlessUsersList
            ref={$headlessUsersList}
            fetchParams={{
              entityId: entity?.id || "",
              workspaceId: chatId,
              searchQuery,
            }}
            userRenderer={(user) => (
              <div className="px-8">
                <UserListItem
                  isMe={user.id === meId}
                  options={menuItems(user)}
                  entityId={entity?.id || ""}
                  userId={user.id}
                  variant="web"
                  subTitle={
                    user.status !== UserEntityStatus.active
                      ? t({
                          id: "chats-page.suspended",
                          defaultMessage: "Suspended",
                        })
                      : undefined
                  }
                  highlightText={searchQuery}
                  isAdmin={user.groupRole === "admin"}
                  isDisabled={
                    chat?.role !== "admin" &&
                    user.status !== UserEntityStatus.active
                  }
                  disabledInfo={t({
                    id: "chats-page.user-is-suspended-by-workspace-admin",
                    defaultMessage:
                      "This user has been suspended by Admin of the Workspace 🚫",
                  })}
                />
              </div>
            )}
            moreLoadingErrorContent={(retry) => (
              <ChatInfoError onRefresh={retry} isMembersSection />
            )}
            emptyContent={
              <ChatInfoEmptyContent tab={InfoTabId.workspaceMembers} />
            }
            errorContent={(retry) => (
              <div className="relative flex grow flex-col">
                <div className="grow" />
                <ChatInfoError onRefresh={retry} isMembersSection />
              </div>
            )}
            loadingContent={<ChatInfoLoading isInitialLoading />}
            moreLoadingContent={<ChatInfoLoading />}
          />
        </div>
      </div>
      <SelectMembersDrawer
        isOpen={isSelectMembersDrawerOpen}
        onRequestClose={() => setIsSelectMembersDrawerOpen(false)}
        currentGroupUsersCount={chat?.userCount}
        workspaceId={chat?.id}
        onSelectUsers={handleAddWorkspaceParticipants}
        isDisabled={isLoading}
      />
      <GroupAdminManagementAlert
        isOpen={groupAdminManagementAlertState.isOpen}
        onRequestClose={groupAdminManagementAlertState.close}
        onConfirm={() =>
          groupAdminManagementAlertState.confirm({ closeOnceConfirmed: true })
        }
        type={groupAdminManagementAlertState.metadata?.type}
        userId={groupAdminManagementAlertState.metadata?.userId}
      />
      <RemoveMemberAlert
        isOpen={removeMemberAlertState.isOpen}
        onRemove={removeMemberAlertState.confirm}
        onRequestClose={removeMemberAlertState.close}
        name={removeMemberAlertState.metadata?.name}
        isDisabled={isDeleteWorkspaceParticipantLoading}
      />
      <RestrictionMemberAlert
        isOpen={restrictionMemberAlertState.isOpen}
        onRequestClose={restrictionMemberAlertState.close}
        type={restrictionMemberAlertState.metadata?.type}
      />
    </>
  );
};
