import { AppModule } from "@jugl-web/domain-resources/common/types";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import {
  WITHOUT_USER_ITEM_ID,
  useUserListBox,
} from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { FilterSectionProps } from "../../types";
import { AssigneeFilterPill } from "../AssigneeFilterPill";
import { FilterSectionLayout } from "../FilterSectionLayout";

export const AssigneeFilterSection: FC<FilterSectionProps> = ({
  entityId,
  source,
  isReadonly,
}) => {
  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const selectedUserIds = useMemo(() => {
    const result: string[] = filtersState.assignees.filter(
      (item) => !!item && item !== WITHOUT_USER_ITEM_ID
    ) as string[];
    return result;
  }, [filtersState.assignees]);
  const { getListBoxProps, getTitleProps, clearSearchQuery, clearFilter } =
    useUserListBox({
      entityId,
      withoutUserItem: {
        label: t({
          id: "tasks-page.without-assignee",
          defaultMessage: "Without assignee",
        }),
      },
      onlyReportees: source.type === "boardTasks" && source.boardId === "team",
      module: AppModule.tasks,
      showDepartmentFilters: true,
      topUsers: selectedUserIds,
    });

  const isFilterEmpty = filtersState.assignees.length === 0;
  const isSectionHidden = isReadonly && isFilterEmpty;

  if (isSectionHidden) {
    return null;
  }

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-assignee-filter",
          defaultMessage: "By Assignee",
        })}
        endSlot={
          !isReadonly ? (
            <ResourcePickerPopover
              placement="bottom-end"
              className="w-[315px]"
              isHeightFixed
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
              )}
              onSubmit={(ids) =>
                updateFiltersState(
                  "assignees",
                  ids.map((id) => (id === WITHOUT_USER_ITEM_ID ? null : id))
                )
              }
              onUnmount={() => {
                clearSearchQuery();
                clearFilter();
              }}
              {...getListBoxProps({
                selectionBehavior: { mode: "multiple" },
                defaultSelectedIds: filtersState.assignees.map(
                  (assigneeId) => assigneeId || WITHOUT_USER_ITEM_ID
                ),
                maxVisibleItems: 5,
              })}
              {...getTitleProps()}
            />
          ) : undefined
        }
      />
      {!isFilterEmpty && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.assignees.map((assigneeId) => (
            <AssigneeFilterPill
              key={assigneeId}
              entityId={entityId}
              assigneeId={assigneeId}
              onRemove={
                !isReadonly
                  ? () =>
                      updateFiltersState("assignees", (previousAssignees) =>
                        previousAssignees.filter((a) => a !== assigneeId)
                      )
                  : undefined
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
