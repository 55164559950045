import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { useTranslations } from "@jugl-web/utils";
import { useSpotlightTourContext } from "@jugl-web/utils/hooks/useSpotlight";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { useMemo } from "react";
import { DashboardSpotlightTourStep } from "../../consts";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { AddChartButton } from "../AddChartButton";
import { DashboardDateRangePicker } from "../DashboardDateRangePicker";
import { DashboardRefreshButton } from "../DashboardRefreshButton";
import { hasDashboardReachedWidgetLimit } from "../../utils";

export const DashboardControlBar = () => {
  const {
    detailedDashboard,
    isDashboardOwner,
    searchQuery,
    changeSearchQuery,
  } = useDashboardContext();

  const spotlightTourContext =
    useSpotlightTourContext<DashboardSpotlightTourStep>();

  const { t } = useTranslations();

  const canManageDashboard = useMemo(
    () => !!detailedDashboard && isDashboardOwner(detailedDashboard),
    [detailedDashboard, isDashboardOwner]
  );

  const isWidgetCountLimitReached = detailedDashboard
    ? hasDashboardReachedWidgetLimit(detailedDashboard)
    : false;

  return (
    <TasksPageLayout.ControlBar>
      <ExpandableSearchBar
        color="white"
        size="lg"
        isAlwaysExpanded
        onSearch={changeSearchQuery}
        defaultValue={searchQuery}
      />
      <div className="flex items-center gap-4">
        <DashboardDateRangePicker />
        <DashboardRefreshButton />
        {canManageDashboard && (
          <SpotlightTooltip
            isOpen={
              spotlightTourContext.isStepActive(
                DashboardSpotlightTourStep.createWidget
              ) && !isWidgetCountLimitReached
            }
            placement="bottom"
            className="w-[282px]"
            onDismiss={() =>
              spotlightTourContext.markStepAsSeen(
                DashboardSpotlightTourStep.createWidget
              )
            }
            tip={t({
              id: "dashboard-page.tap-to-add-chart-hint",
              defaultMessage:
                "Tap here to add a new Chart to your Dashboard ✨",
            })}
            showButton
            renderTrigger={({ ref, props }) => (
              <AddChartButton
                ref={ref}
                popoverPlacement="bottom-end"
                {...props}
              />
            )}
          />
        )}
      </div>
    </TasksPageLayout.ControlBar>
  );
};
