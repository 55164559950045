import { DropdownValueListItem } from "@jugl-web/domain-resources/custom-fields/components/DropdownValueList/DropdownValueList";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useState } from "react";
import { UserCustomField, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ManageProfileAttributesDialogScreenToParametersMap } from "../types";
import { UserCustomDropdownFieldValuesScreen } from "./UserCustomDropdownFieldValuesScreen";
import { useCPanelPageContext } from "../../../CPanelPageProvider";

export const NewUserCustomDropdownFieldValuesScreen: FC<{
  propId: string;
  fieldName: string;
  userCustomFields: UserCustomField[];
}> = ({ userCustomFields, fieldName, propId }) => {
  const [internalFieldName, setInternalFieldName] = useState(fieldName);
  const [items, setItems] = useState<DropdownValueListItem[]>([]);
  const { refetchUsers$ } = useCPanelPageContext();

  const { entityId } = useEntitySelectedProvider();
  const { usersApi } = useRestApiProvider();

  const [appendUserCustomFields, { isLoading }] =
    usersApi.useAppendUserCustomFieldsMutation();

  const { toast } = useToast();
  const { t } = useTranslations();

  const { transitionTo } =
    useScreenTransitionManager<ManageProfileAttributesDialogScreenToParametersMap>();

  const handleSubmit = async () => {
    const order = userCustomFields.length
      ? Math.max(
          ...userCustomFields.map((userCustomField) => userCustomField.order)
        ) + 1
      : 1;

    const response = await appendUserCustomFields({
      entityId,
      propId,
      value: [
        {
          name: internalFieldName,
          order,
          type: "dropdown",
          values: items.map((item, idx) => ({
            id: item.id,
            value: item.text,
            order: idx + 1,
          })),
        },
      ],
    });

    if (response && "data" in response) {
      refetchUsers$.next();
      toast(
        t({
          id: "feedback.custom-field-created",
          defaultMessage: "Custom field has been successfully created",
        })
      );
      transitionTo({ name: "entry" }, { force: true });
    }
  };

  return (
    <UserCustomDropdownFieldValuesScreen
      fieldName={internalFieldName}
      items={items}
      submitButton={{
        label: t({
          id: "common.create",
          defaultMessage: "Create",
        }),
        isDisabled: !internalFieldName.length || isLoading || !items.length,
        onClick: handleSubmit,
      }}
      onChangeFieldName={setInternalFieldName}
      onChangeItems={setItems}
      onCancel={() =>
        transitionTo({
          name: "newUserCustomField",
          initialFieldName: internalFieldName,
          initialFieldType: "dropdown",
        })
      }
    />
  );
};
