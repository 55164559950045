import { assert, cx, useTranslations } from "@jugl-web/utils";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Button } from "../../cross-platform/Button";
import {
  ListBox,
  ListBoxHandle,
  ListBoxItem,
  ListBoxProps,
  UnsafeListBoxItem,
} from "../../cross-platform/ListBox";
import { MobileDrawerProps, MobileDrawer } from "../MobileDrawer";

export interface ResourcePickerDrawerProps<TValue>
  extends Omit<ListBoxProps<TValue>, "onSelect">,
    Pick<
      MobileDrawerProps,
      "isOpen" | "animateHeight" | "onClose" | "onTransitionEnd"
    > {
  title: ReactNode;
  onSubmit?: (ids: string[], items: UnsafeListBoxItem<TValue>[]) => void;
  onSelect?: (props: {
    item: ListBoxItem<TValue>;
    isSelected: boolean;
    onClose: () => void;
  }) => void;
}

export const ResourcePickerDrawer = <TValue,>({
  isOpen,
  title,
  animateHeight,
  onSubmit,
  onSelect,
  onSelectionChange,
  onClose,
  onTransitionEnd,
  ...listBoxProps
}: ResourcePickerDrawerProps<TValue>) => {
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);

  const listBoxRef = useRef<ListBoxHandle<TValue> | null>(null);
  const timeoutIdRef = useRef<number>();

  const { t } = useTranslations();

  const hasActionsSection = !!onSubmit;

  const shouldShowSelectedItemsCounter =
    listBoxProps.selectionBehavior.mode === "multiple" &&
    selectedItemsCount > 0;

  const handleSubmit = () => {
    assert(!!listBoxRef.current, "ListBox ref is not set");
    assert(!!onSubmit, "onSubmit handler is not set");

    onSubmit(
      listBoxRef.current.getSelectedIds(),
      listBoxRef.current.getSelectedItems()
    );

    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      // Wait a while to let the drawer open and the ListBox to render
      timeoutIdRef.current = window.setTimeout(() => {
        setSelectedItemsCount(listBoxRef.current?.getSelectedIds().length || 0);
      }, 10);
    }

    return () => {
      window.clearTimeout(timeoutIdRef.current);
    };
  }, [isOpen]);

  return (
    <MobileDrawer
      isOpen={isOpen}
      header={{ title }}
      animateHeight={animateHeight}
      onClose={onClose}
      onTransitionEnd={onTransitionEnd}
    >
      <MobileDrawer.Content className={cx(hasActionsSection ? "pb-6" : "pb-8")}>
        <ListBox
          ref={listBoxRef}
          onSelectionChange={(ids, items) => {
            onSelectionChange?.(ids, items);
            setSelectedItemsCount(ids.length);
          }}
          onSelect={
            onSelect
              ? (item, isSelected) => onSelect({ item, isSelected, onClose })
              : undefined
          }
          {...listBoxProps}
        />
      </MobileDrawer.Content>
      {hasActionsSection && (
        <MobileDrawer.Actions className="pb-8">
          <div className="grid grid-cols-2 gap-4">
            <Button
              variant="contained"
              color="grey"
              className="font-secondary"
              onClick={() => {
                onClose();
              }}
            >
              {t({ id: "common.cancel", defaultMessage: "Cancel" })}
            </Button>
            <Button
              variant="contained"
              className="font-secondary"
              onClick={handleSubmit}
            >
              {shouldShowSelectedItemsCounter
                ? t(
                    {
                      id: "common.select-with-count",
                      defaultMessage: "Select {count}",
                    },
                    { count: selectedItemsCount }
                  )
                : t({ id: "common.select", defaultMessage: "Select" })}
            </Button>
          </div>
        </MobileDrawer.Actions>
      )}
    </MobileDrawer>
  );
};
