import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import React, { useMemo, useState } from "react";
import { cx } from "@jugl-web/utils";
import { useChatMessageProvider } from "../../../../../../providers/ChatMessageProvider";
import { getAttachmentIcon } from "./utils";
import { ReactComponent as PlayIcon } from "./assets/play.svg";

export const ReplyAttachmentPreview: React.FC = () => {
  const { message, isOutgoing, getAttachmentUrls } = useChatMessageProvider();
  const attachment = useMemo(
    () => message.payload.reply_attributes_map?.reply_attachments?.[0],
    [message.payload.reply_attributes_map]
  );
  if (!attachment) {
    throw new Error("Message doesn't have attachment.");
  }

  const { preview: previewUrl } = getAttachmentUrls(attachment);

  const isImageOrVideo =
    attachment.type === ChatMessageAttachmentType.image ||
    attachment.type === ChatMessageAttachmentType.video;

  const [previewState, setPreviewState] = useState<
    "loading" | "error" | "loaded"
  >(isImageOrVideo ? "loading" : "loaded");

  return (
    <div
      className={cx(
        "relative flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded",
        isOutgoing ? "bg-dark-100" : "bg-white"
      )}
    >
      {previewState === "loading" && <LoadingSpinner size="sm" />}
      {(!isImageOrVideo || previewState === "error") &&
        getAttachmentIcon(attachment.mimetype)}
      {previewState === "loaded" &&
        attachment.type === ChatMessageAttachmentType.video && (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(26,32,35,0.50)] backdrop-blur-[2.5px]">
            <PlayIcon />
          </div>
        )}
      {isImageOrVideo && (
        <img
          onLoad={() => setPreviewState("loaded")}
          onError={() => setPreviewState("error")}
          src={previewUrl}
          alt={attachment.name}
          className="h-full w-full object-cover"
          hidden={previewState !== "loaded"}
        />
      )}
    </div>
  );
};
