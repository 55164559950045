import React from "react";
import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import { VoiceMessageAudioPlayer } from "@jugl-web/ui-components/web";
import { normalizeAudioAmps } from "@jugl-web/utils";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";
import { ChatAudioPlayer } from "./components/ChatAudioPlayer";

export const AudioAttachment: React.FC = () => {
  const { attachment, isOutgoing, getAttachmentUrls } =
    useChatMessageProvider();
  if (
    !attachment ||
    ![
      ChatMessageAttachmentType.audio,
      ChatMessageAttachmentType.voice,
    ].includes(attachment.type)
  ) {
    throw new Error(
      "Message doesn't have attachment or attachment type is not supported"
    );
  }

  const { stream } = getAttachmentUrls(attachment);

  if (attachment.type === ChatMessageAttachmentType.audio) {
    return (
      <ChatAudioPlayer
        src={stream}
        name={attachment.name || "Audio file"}
        variant={isOutgoing ? "outgoing" : "incoming"}
      />
    );
  }

  return (
    <VoiceMessageAudioPlayer
      audioSrc={stream}
      waveform={normalizeAudioAmps(attachment.amps || [0])}
      duration={attachment.duration}
      variant={isOutgoing ? "outgoing" : "incoming"}
    />
  );
};
