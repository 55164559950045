import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { MobileDrawer } from "@jugl-web/ui-components";
import { SwipeableHeader } from "@jugl-web/ui-components/mobile/MobileDrawer/SwipeableHeader";
import {
  getUniqueId,
  useAppVariant,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { mapIndexesToOrder } from "@jugl-web/utils/array/mapIndexesToOrder";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUpdateEffect } from "react-use";
import { useTaskMentions } from "../../hooks/useTaskMentions";
import { TaskChecklistItemDeleteConfirmationDialog } from "../TaskChecklistItemDeleteConfirmationDialog";
import { AddNewItemButton } from "./AddNewItemButton";
import { EditingTaskChecklistItem } from "./EditingTaskChecklistItem";
import { MoveToTaskScreen } from "./MoveToTaskScreen";
import { SaveBeforeLeavingDialog } from "./SaveBeforeLeavingDialog";
import { TaskChecklistItemComponent } from "./TaskChecklistItem";
import { TaskChecklistProvider } from "./TaskChecklistProvider";
import { TaskChecklistSelectionFloatingPanel } from "./TaskChecklistSelectionFloatingPanel";
import { TaskChecklistSelectionPanel } from "./TaskChecklistSelectionPanel";
import {
  TaskChecklistHandle,
  TaskChecklistItem,
  TaskChecklistProps,
} from "./types";
import { useTaskChecklistDnd } from "./useTaskChecklistDnd";
import {
  getSpecificOrderCompletionState,
  saveTaskChecklist$,
  sortItemsByCompleted,
} from "./utils";

const MIN_ITEMS_TO_RENDER_ADD_BUTTON = 4;

const noop = () => {};

export const TaskChecklist = forwardRef<
  TaskChecklistHandle,
  TaskChecklistProps
>(
  (
    {
      entityId,
      meId,
      items,
      taskCreatorId,
      taskId,
      isSelectable = false,
      taskAssigneeIds = [],
      isCompleteInSpecificOrder = false,
      areCompletedItemsHidden = false,
      searchQuery = "",
      emptyStateContent,
      noSearchResultsContent,
      onlyReportees = false,
      displayDueDateAs = "date",
      className,
      isManageable = true,
      isCompletable = true,
      isAssignable = true,
      onNavigateToTask,
      canEditItem = () => false,
      onAddItem = noop,
      onUpdateItem = noop,
      onDeleteItem = noop,
      onReorderItems = noop,
    },
    ref
  ) => {
    const [editingItemId, setEditingItemId] = useState<string | null>(null);

    const [newItemState, setNewItemState] = useState<TaskChecklistItem | null>(
      null
    );

    const [isSelectMode, setIsSelectMode] = useState(false);
    const [selectedChecklistIds, setSelectedChecklistIds] = useState<string[]>(
      []
    );

    const [isMobileMoveToTaskDrawerOpen, setIsMobileMoveToTaskDrawerOpen] =
      useState(false);
    const { toast, closeToast } = useToast();
    const { t } = useTranslations();
    const { isWeb } = useAppVariant();

    const { tasksApi } = useRestApiProvider();

    const [moveChecklistItems, { isLoading: isMoveChecklistItemsLoading }] =
      tasksApi.useMoveChecklistItemsMutation();

    const handleMoveChecklist = useCallback(
      async (targetTaskId?: string) => {
        if (!taskId) {
          return false;
        }

        if (!targetTaskId) {
          if (!isWeb) {
            setIsMobileMoveToTaskDrawerOpen(true);
            return false;
          }
          return false;
        }

        const response = await moveChecklistItems({
          entityId,
          itemIds: selectedChecklistIds,
          targetTaskId,
          taskId,
        });

        if (response && "data" in response) {
          const canNavigateToTask = !!onNavigateToTask;

          if (canNavigateToTask) {
            const toastId = toast(
              t(
                {
                  id: "tasks-page.subtasks-has-been-moved-tap-to-open",
                  defaultMessage:
                    "{count, plural, one {Subtask} other {{count} Subtasks}} has been moved. Tap to open it",
                },
                {
                  count: selectedChecklistIds.length,
                }
              ),
              {
                SnackbarProps: {
                  style: {
                    cursor: "pointer",
                    userSelect: "none",
                  },
                  onClick: () => {
                    onNavigateToTask(targetTaskId);
                    closeToast(toastId);
                  },
                },
              }
            );
          } else {
            toast(
              t(
                {
                  id: "tasks-page.subtasks-has-been-moved",
                  defaultMessage:
                    "{count, plural, one {Subtask} other {{count} Subtasks}} has been moved",
                },
                {
                  count: selectedChecklistIds.length,
                }
              )
            );
          }
          return true;
        }
        return false;
      },
      [
        isWeb,
        taskId,
        moveChecklistItems,
        entityId,
        onNavigateToTask,
        selectedChecklistIds,
        toast,
        t,
        closeToast,
      ]
    );

    useEffect(() => {
      if (isSelectMode) {
        setSelectedChecklistIds((prev) =>
          prev.filter((checklistId) =>
            items.some((item) => item.id === checklistId)
          )
        );
      }
    }, [items, isSelectMode]);

    const { mentions } = useTaskMentions({
      entityId,
      meId,
      taskAssigneeIds,
      taskCreatorId,
    });

    const { dndContextProps } = useTaskChecklistDnd({ items, onReorderItems });

    const saveBeforeLeavingDialogState = useConfirmationDialogState();
    const deleteConfirmationDialogState = useConfirmationDialogState<{
      item: TaskChecklistItem;
    }>();

    const endListAnchorRef = useRef<HTMLDivElement | null>(null);

    const indexedItems = useMemo(
      () => items.map((item, index) => ({ ...item, primaryIndex: index })),
      [items]
    );

    const visibleItems = useMemo(() => {
      let baseItems = indexedItems;

      if (areCompletedItemsHidden) {
        baseItems = baseItems.filter((item) => !item.isCompleted);
      }

      if (searchQuery) {
        baseItems = baseItems.filter((item) =>
          item.text.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      return baseItems;
    }, [areCompletedItemsHidden, indexedItems, searchQuery]);

    const isEmpty = visibleItems.length === 0 && !newItemState;
    const isFiltering = searchQuery || areCompletedItemsHidden;

    const shouldShowAddButton =
      isManageable &&
      !newItemState &&
      items.length >= MIN_ITEMS_TO_RENDER_ADD_BUTTON;

    const handleSelectModeChange = useCallback(
      (isSelecting: boolean) => {
        if (isSelecting && editingItemId) {
          saveTaskChecklist$.next({
            subtaskId: editingItemId,
          });
          setEditingItemId(null);
        }
        setIsSelectMode(isSelecting);
      },
      [editingItemId]
    );

    const scrollToBottom = () => {
      endListAnchorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    };

    const getNewItemOrder = () => {
      if (items.length === 0) {
        return 1;
      }

      const lastItem = items[items.length - 1];

      return lastItem.order + 1;
    };

    // #region Public API
    const addItem = () => {
      const newItem: TaskChecklistItem = {
        id: getUniqueId(),
        text: "",
        isCompleted: false,
        completedAt: null,
        assigneeId: null,
        dueDate: null,
        order: getNewItemOrder(),
      };

      setNewItemState(newItem);

      // Make sure that scroll happens after the new item is rendered
      window.setTimeout(scrollToBottom, 0);
    };

    useImperativeHandle(ref, () => ({ addItem }));
    // #endregion

    useUpdateEffect(() => {
      if (isCompleteInSpecificOrder) {
        const reorderedItems = mapIndexesToOrder(sortItemsByCompleted(items));
        onReorderItems(reorderedItems);
      }
    }, [isCompleteInSpecificOrder]);

    return (
      <TaskChecklistProvider
        value={{
          entityId,
          meId,
          taskId,
          mentions,
          isCompleteInSpecificOrder,
          searchQuery,
          onlyReportees,
          displayDueDateAs,
          isManageable,
          isCompletable,
          isAssignable,
          isSelectable,
          canEditItem,
          onTriggerSaveBeforeLeavingDialog: saveBeforeLeavingDialogState.open,
          onTriggerDeleteConfirmationDialog: deleteConfirmationDialogState.open,
          isSelectMode,
          selectedChecklistIds,
          onSelectedChecklistIdsChange: setSelectedChecklistIds,
          onSelectModeChange: handleSelectModeChange,
          onMoveChecklist: handleMoveChecklist,
          isMoveChecklistItemsLoading,
        }}
      >
        {isEmpty ? (
          isFiltering ? (
            noSearchResultsContent
          ) : (
            emptyStateContent
          )
        ) : (
          <>
            <div className={className}>
              <DndContext {...dndContextProps}>
                <SortableContext
                  items={visibleItems}
                  strategy={verticalListSortingStrategy}
                  disabled={!isManageable}
                >
                  {visibleItems.map((item) => {
                    const specificOrderCompletionState =
                      getSpecificOrderCompletionState({
                        isCompleteInSpecificOrder,
                        item,
                        previousItem: indexedItems[item.primaryIndex - 1],
                        nextItem: indexedItems[item.primaryIndex + 1],
                      });

                    return (
                      <TaskChecklistItemComponent
                        key={item.id}
                        index={item.primaryIndex}
                        item={item}
                        specificOrderCompletionState={
                          specificOrderCompletionState
                        }
                        onUpdate={onUpdateItem}
                        onDelete={onDeleteItem}
                        isEditing={editingItemId === item.id}
                        onEditModeChange={(isEditing) => {
                          if (isEditing && editingItemId) {
                            saveTaskChecklist$.next({
                              subtaskId: editingItemId,
                            });
                          }
                          setEditingItemId(isEditing ? item.id : null);
                        }}
                      />
                    );
                  })}
                </SortableContext>
              </DndContext>
              {newItemState && (
                <EditingTaskChecklistItem
                  item={newItemState}
                  isNew
                  onSaveChanges={async (item) => {
                    await onAddItem(item);
                    setNewItemState(null);
                  }}
                  onCancel={() => setNewItemState(null)}
                  onDelete={() => setNewItemState(null)}
                />
              )}
              {shouldShowAddButton && <AddNewItemButton onClick={addItem} />}
            </div>
            <div ref={endListAnchorRef} />
            <SaveBeforeLeavingDialog
              isOpen={saveBeforeLeavingDialogState.isOpen}
              onSave={() =>
                saveBeforeLeavingDialogState.confirm({
                  closeOnceConfirmed: true,
                })
              }
              onDiscard={() =>
                saveBeforeLeavingDialogState.cancel({
                  closeOnceCanceled: true,
                })
              }
              onCancel={saveBeforeLeavingDialogState.close}
            />
            <TaskChecklistItemDeleteConfirmationDialog
              isOpen={deleteConfirmationDialogState.isOpen}
              itemContent={deleteConfirmationDialogState.metadata?.item.text}
              onDelete={() =>
                deleteConfirmationDialogState.confirm({
                  closeOnceConfirmed: true,
                })
              }
              onClose={deleteConfirmationDialogState.close}
            />
          </>
        )}
        {(() => {
          if (taskId && isSelectMode) {
            if (isWeb) {
              return (
                <TaskChecklistSelectionFloatingPanel
                  onClose={() => {
                    setIsSelectMode(false);
                    setSelectedChecklistIds([]);
                  }}
                  entityId={entityId}
                  taskId={taskId}
                />
              );
            }
            return (
              <TaskChecklistSelectionPanel
                onClose={() => {
                  setIsSelectMode(false);
                  setSelectedChecklistIds([]);
                }}
                entityId={entityId}
                taskId={taskId}
              />
            );
          }

          return null;
        })()}
        <MobileDrawer
          isOpen={isMobileMoveToTaskDrawerOpen}
          onClose={() => {
            setIsMobileMoveToTaskDrawerOpen(false);
            setSelectedChecklistIds([]);
          }}
          size="auto"
          animateHeight
          className="fixed select-none"
        >
          <SwipeableHeader
            onClose={() => {
              setIsMobileMoveToTaskDrawerOpen(false);
              setSelectedChecklistIds([]);
            }}
            title={t({
              id: "tasks-page.move-subtask",
              defaultMessage: "Move Subtask",
            })}
            hasCloseButton
          />
          <MoveToTaskScreen
            entityId={entityId}
            taskId={taskId as string}
            meId={meId}
            onSelect={(targetTask) => {
              setIsMobileMoveToTaskDrawerOpen(false);
              handleMoveChecklist(targetTask.id);
            }}
          />
        </MobileDrawer>
      </TaskChecklistProvider>
    );
  }
);
