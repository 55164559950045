import { TaskFilters } from "../../../tasks";
import { WidgetConfig } from "./WidgetDto";

export const SUPPORTED_CHART_SORTINGS = [
  "x-axis-asc",
  "x-axis-desc",
  "y-axis-asc",
  "y-axis-desc",
] as const;

export type WidgetChartSorting = (typeof SUPPORTED_CHART_SORTINGS)[number];

export const SUPPORTED_CHART_TYPES = [
  "line",
  "area",
  "column",
  "bar",
  "pie",
  "donut",
  "treemap",
  "radar",
  "waterfall",
  "pyramid",
  "funnel",
] as const;

export type WidgetChartType = (typeof SUPPORTED_CHART_TYPES)[number];

export const SUPPORTED_CHART_Y_AXIS_FORMATS = ["value", "percent"] as const;

export type WidgetChartYAxisFormat =
  (typeof SUPPORTED_CHART_Y_AXIS_FORMATS)[number];

export interface ExpectedWidgetMetadata {
  y_axis_format: WidgetChartYAxisFormat;
  sorting: WidgetChartSorting;
  color_palette: string[];
  show_unassigned_categories: boolean;
}

export interface WidgetModel {
  id: string;
  title: string;
  type: "chart" | "table";
  data_src: "task";
  filters: TaskFilters;
  config: WidgetConfig<WidgetChartType, ExpectedWidgetMetadata>;
  dashboard_id: string;
  updated_at: string;
}
