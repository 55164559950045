import React from "react";
import { displayIcon } from "@web-src/utils/displayIcon";
import { useTranslations } from "@jugl-web/utils";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  DirectoryListItem,
  DriveItemDisplayType,
} from "@jugl-web/rest-api/drive/types";
import {
  AvatarStack,
  ImageWithFallback,
  InteractiveContainer,
  Menu,
  MenuSections,
} from "@jugl-web/ui-components/cross-platform";
import Highlighter from "react-highlight-words";
import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as Icon } from "./assets/icon.svg";

const DriveObject: React.FC<{
  title: string;
  firstSubtitle: string;
  search: string;
  secondSubtitle: string;
  image?: string;
  icon: string;
  display: DriveItemDisplayType;
  allowedUsersIds?: string[];
  onClick: () => void;
  displayFileMenuElements: MenuSections;
  fileType: "shared" | "private" | "public" | false;
  meta?: DirectoryListItem["meta"];
}> = ({
  title,
  search,
  firstSubtitle,
  secondSubtitle,
  image,
  icon,
  display,
  onClick,
  displayFileMenuElements,
  fileType,
  allowedUsersIds = [],
  meta,
}) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();

  const { profiles: usersProfiles } = useMultipleUserProfiles({
    entityId,
    userIds: allowedUsersIds,
    skip: fileType !== "shared",
  });

  const options = displayFileMenuElements.length ? (
    <Menu
      placement="bottom-end"
      autoClose
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={InteractiveContainer}
          onClick={(e) => e.stopPropagation()}
          className="absolute top-1/2 right-2 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full bg-white transition-colors hover:bg-gray-100"
        >
          <Icon className="text-grey-900" />
        </Trigger>
      )}
      sections={displayFileMenuElements}
    />
  ) : null;

  return (
    <>
      {display === DriveItemDisplayType.tiles ? (
        <button
          className="relative flex h-[142px] w-[190px] cursor-pointer flex-col items-center justify-center rounded-lg border-none bg-white bg-transparent p-3 outline-none"
          onClick={onClick}
          type="button"
        >
          <div className="relative flex h-[64px] w-[170px] items-center justify-items-center overflow-hidden rounded-lg">
            {image ? (
              <ImageWithFallback
                src={image}
                className="h-full w-full object-cover"
                alt=""
              />
            ) : (
              <>
                <img
                  src={displayIcon(icon, fileType, { isSystem: meta?.sys_dir })}
                  className="h-full w-full"
                  alt=""
                />
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
                  {fileType === "shared" && (
                    <AvatarStack
                      members={usersProfiles.map((profile) => ({
                        firstName: profile.firstName || "",
                        lastName: profile.lastName || "",
                        imageUrl: profile.avatar || "",
                      }))}
                      maxCount={3}
                      size="xs"
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="mt-3 flex h-12 w-full flex-col justify-center">
            <Text variant="body2" className="truncate">
              <Highlighter
                autoEscape
                highlightClassName="text-primary font-semibold"
                highlightTag="span"
                textToHighlight={title}
                searchWords={[search]}
              />
            </Text>
            <Text variant="body2" className="text-grey-900 mt-2 truncate">
              {firstSubtitle?.concat(" • ", secondSubtitle)}
            </Text>
          </div>
          {options}
        </button>
      ) : (
        <button
          className="relative flex h-[72px] w-full cursor-pointer items-center rounded-xl border-none bg-white bg-transparent px-5 outline-none"
          onClick={onClick}
          type="button"
        >
          <div className="flex w-[40px] items-center">
            {image ? (
              <img
                src={image}
                className="h-[40px] w-[40px] rounded-lg object-cover"
                alt=""
              />
            ) : (
              <img
                src={displayIcon(icon, fileType, { isSystem: meta?.sys_dir })}
                className="h-[40px] w-[40px]"
                alt=""
              />
            )}
          </div>
          <div className="ml-4 flex w-[300px] items-center">
            <Text variant="body1" className=" truncate">
              <Highlighter
                autoEscape
                highlightClassName="text-primary font-semibold"
                highlightTag="span"
                textToHighlight={title}
                searchWords={[search]}
              />
              {meta?.sys_dir && (
                <span className="ml-2 text-sm text-gray-500">
                  {t({
                    id: "drive-page.view-only",
                    defaultMessage: "View only",
                  })}
                </span>
              )}
            </Text>
          </div>
          <div className="ml-4 flex w-[200px] items-center">
            <Text variant="body2" className="truncate">
              {firstSubtitle}
            </Text>
          </div>
          <div className="ml-4 flex w-[200px] items-center">
            <Text variant="body2" className="truncate">
              {secondSubtitle}
            </Text>
          </div>
          {options}
        </button>
      )}
    </>
  );
};

export default DriveObject;
