import {
  RenderTooltipTriggerProps,
  Tooltip,
  TooltipProps,
} from "@jugl-web/ui-components/cross-platform/Tooltip";
import { ClickAwayListener, cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

export interface SpotlightTooltipProps {
  isOpen: boolean;
  tip: React.ReactNode;
  placement: TooltipProps["placement"];
  showButton?: boolean;
  className?: string;
  renderTrigger: (props: RenderTooltipTriggerProps) => JSX.Element;
  onDismiss: () => void;
}

export const SpotlightTooltip: FC<SpotlightTooltipProps> = ({
  isOpen,
  tip,
  placement,
  className,
  showButton = true,
  renderTrigger,
  onDismiss,
}) => {
  const { t } = useTranslations();

  return (
    <Tooltip
      isOpen={isOpen}
      placement={placement}
      renderTrigger={renderTrigger}
      className={cx("bg-dark/90 w-[250px] px-4 py-[14px]", className)}
    >
      <ClickAwayListener onClickAway={onDismiss}>
        <span className="text-base leading-[22px]">{tip}</span>
        {showButton && (
          <button
            type="button"
            className="text-primary-200 font-primary mt-[18px] block cursor-pointer border-0 bg-transparent p-0 text-base font-bold leading-3"
            onClick={onDismiss}
          >
            {t({ id: "common.okay", defaultMessage: "Okay" })}
          </button>
        )}
      </ClickAwayListener>
    </Tooltip>
  );
};
