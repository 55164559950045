import { FeedbackBox } from "@jugl-web/ui-components/cross-platform/FeedbackBox";
import { ErrorBoundary } from "@jugl-web/utils/utils/ErrorBoundary";
import { FC, ReactNode } from "react";

interface DashboardWidgetChartErrorBoundaryProps {
  children: ReactNode;
}

export const DashboardWidgetChartErrorBoundary: FC<
  DashboardWidgetChartErrorBoundaryProps
> = ({ children }) => (
  <ErrorBoundary
    fallback={
      <div className="flex h-full w-full items-center justify-center">
        <FeedbackBox
          iconType="unknown-error"
          title="Something went wrong"
          subtitle={
            <>
              We couldn&apos;t generate the chart for this widget. <br />
              Please try again later.
            </>
          }
        />
      </div>
    }
  >
    {children}
  </ErrorBoundary>
);
