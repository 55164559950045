import { priceDisplay } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import Lottie from "react-lottie";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import {
  PAYMENTS_YEARLY_BENEFITS_SHOWN,
  getStorageItemWithFallback,
  saveItemToStorage,
} from "@jugl-web/utils/storage";
import {
  PaymentSummarySideBar,
  PaymentSummarySideBarItem,
} from "../../../PaymentSummarySideBar";
import { useSelectSubscriptionFormProvider } from "../../SelectSubscriptionFormProvider";
import yearlySubscriptionAnimation from "./assets/yearlySubscription.json";

export const SummarySideBar: FC = () => {
  const {
    selectedPlan,
    additionalSeats,
    confirmPurchase,
    selectSubscriptionInterval,
    appliedPromoCodes,
    addPromoCode,
    removePromoCode,
    defaultCurrency,
    isDealMirrorMode,
  } = useSelectSubscriptionFormProvider();
  const [isYearlyCTAOpen, setIsYearlyCTAOpen] = useState<boolean>(false);

  const handlePurchase = () => {
    if (
      isDealMirrorMode ||
      selectedPlan?.intervalType === "year" ||
      getStorageItemWithFallback(PAYMENTS_YEARLY_BENEFITS_SHOWN, false)
    ) {
      confirmPurchase();
      return;
    }
    saveItemToStorage(PAYMENTS_YEARLY_BENEFITS_SHOWN, true);
    setIsYearlyCTAOpen(true);
  };

  const summaryItems: PaymentSummarySideBarItem[] = useMemo(() => {
    if (!selectedPlan) {
      return [];
    }
    const result: PaymentSummarySideBarItem[] = [
      {
        title: selectedPlan.name,
        summary: [
          selectedPlan.intervalType === "year" ? (
            <>
              {priceDisplay(selectedPlan.price, selectedPlan.currency)} x 12
              months
            </>
          ) : (
            <>
              {priceDisplay(selectedPlan.price, selectedPlan.currency)} x 1
              month
            </>
          ),
        ],
        amount: selectedPlan.price,
      },
    ];
    if (additionalSeats) {
      result.push({
        title: `Additional seats`,
        summary: [
          <>
            {priceDisplay(
              selectedPlan.additionalSeatPrice,
              selectedPlan.currency
            )}{" "}
            x {additionalSeats} seats x{" "}
            {selectedPlan.intervalType === "year" ? `12 months` : `1 month`}
          </>,
        ],
        amount: additionalSeats * selectedPlan.additionalSeatPrice,
      });
    }
    return result;
  }, [additionalSeats, selectedPlan]);
  return (
    <>
      <PaymentSummarySideBar
        items={summaryItems}
        onConfirm={handlePurchase}
        allowPromoCode
        currency={selectedPlan?.currency || defaultCurrency}
        emptyMessage="Select the Plan to see summary 💸"
        onApplyPromoCode={addPromoCode}
        onRemovePromoCode={removePromoCode}
        appliedPromoCodes={appliedPromoCodes}
      />
      <Alert
        isOpen={isYearlyCTAOpen}
        onRequestClose={() => setIsYearlyCTAOpen(false)}
        title="Save up to 20%"
        content="Yearly subscription can save you up to 20% of total payments, which is equal to 2 Free months of usage 😍"
        img={
          <Lottie
            options={{ animationData: yearlySubscriptionAnimation }}
            width={200}
            height={173}
          />
        }
        buttons={[
          {
            text: "Later",
            color: "grey",
            onClick: () => {
              handlePurchase();
              setIsYearlyCTAOpen(false);
            },
          },
          {
            text: "Sure",
            onClick: () => {
              setIsYearlyCTAOpen(false);
              selectSubscriptionInterval("year");
            },
          },
        ]}
      />
    </>
  );
};
