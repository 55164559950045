import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { sortCollectionAlphabetically } from "@jugl-web/utils/array/sortCollectionAlphabetically";
import { useCallback, useMemo } from "react";

export const useTaskBoards = ({ entityId }: { entityId: string }) => {
  const { tasksApi } = useRestApiProvider();

  const { data, isLoading, isFetching, isError, refetch } =
    tasksApi.useGetTaskBoardsQuery({
      entityId,
    });

  const boards = useMemo(() => {
    if (!data) {
      return [];
    }

    return sortCollectionAlphabetically(data, (board) => board.name);
  }, [data]);

  const boardsMap = useMemo<Map<string, TaskBoardModel>>(
    () => new Map(boards.map((board) => [board.id, board])),
    [boards]
  );

  const getBoardById = useCallback(
    (boardId: string) => boardsMap.get(boardId),
    [boardsMap]
  );

  const getBoardByName = useCallback(
    (name: string) => boards.find((board) => board.name === name),
    [boards]
  );

  const hasAccess = useCallback(
    (boardId: string) => !!getBoardById(boardId),
    [getBoardById]
  );

  return {
    boards,
    isLoading,
    isFetching,
    isError,
    refetch,
    getBoardById,
    getBoardByName,
    hasAccess,
  };
};
