import { useMemo, useState } from "react";
import Loader from "@web-src/components/Loader";
import useDebounce from "@web-src/hooks/useDebounce";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  Button,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { CreateWorkspaceDrawer } from "@web-src/modules/chats/components/CreateWorkspaceDrawer";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { CreatePersonalChatDrawer } from "@web-src/modules/chats/components/CreatePersonalChatDrawer";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import { ChatListItem } from "./components/ChatListItem";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as CreateGroupIcon } from "./assets/create-group.svg";
import { ReactComponent as CreatePersonalChatIcon } from "./assets/create-personal-chat.svg";

export const ChatList = () => {
  const { subscriptionRequiredAction } = useEntityProvider();
  const { conversations, isConversationsLoading } = useConversations();
  const { setActiveChat, chat: activeChat } = useActiveChat();
  const [createWorkspaceModalOpened, setCreateWorkspaceModalOpened] =
    useState<boolean>(false);
  const [createPersonalChatModalOpened, setCreatePersonalChatModalOpened] =
    useState<boolean>(false);
  const { reset, inputProps, searchQuery } = useSearchInput();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const { t } = useTranslations();
  const { openSpotlight, openedSpotlight, isOnboardingActive } =
    useOnboarding();
  const { hasEmployeeLikeRole } = useUserRole();

  const visibleConversations = useMemo(() => {
    if (!debouncedSearchQuery) {
      return conversations;
    }
    return conversations.filter(
      (item) =>
        item.data?.title
          ?.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase()) ||
        item.data?.id
          ?.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
    );
  }, [debouncedSearchQuery, conversations]);

  return (
    <>
      <div className="border-dark-200 flex h-full w-[32vw] min-w-[320px] max-w-[460px] flex-col border-0 border-r-[1px] border-solid bg-white">
        <div className=" border-grey-200 flex h-[62px] items-center justify-between border-0 border-b-[1px] border-solid px-8">
          <span className="text-dark-900 text-lg font-medium">
            {t({ id: "chats-page.all-chats", defaultMessage: "All Chats" })}
          </span>
          {!hasEmployeeLikeRole && (
            <SpotlightTooltip
              isOpen={openedSpotlight === "chat"}
              placement="bottom"
              tip={t({
                id: "edit-profile-page.create-chat-onboarding-hint",
                defaultMessage: "Tap here to Start Your first Chat ✨",
              })}
              showButton={false}
              renderTrigger={({ ref, props }) => (
                <div ref={ref} {...props}>
                  <Menu
                    placement="right-end"
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        as={PlainButton}
                        ref={triggerRef}
                        className="bg-primary h-8 w-8 rounded-lg"
                        onClick={() => openSpotlight(undefined)}
                      >
                        <AddIcon className="text-white" />
                      </Trigger>
                    )}
                    onSelect={(_item, _event, close) => close()}
                    sections={[
                      [
                        {
                          id: "new-group",
                          label: t({
                            id: "chats-page.new-group",
                            defaultMessage: "New Group",
                          }),
                          onSelect: subscriptionRequiredAction(
                            setCreateWorkspaceModalOpened.bind(null, true)
                          ),
                          icon: <CreateGroupIcon />,
                        },
                        {
                          id: "new-chat",
                          label: t({
                            id: "chats-page.new-chat",
                            defaultMessage: "New Chat",
                          }),
                          onSelect: subscriptionRequiredAction(
                            setCreatePersonalChatModalOpened.bind(null, true)
                          ),
                          icon: <CreatePersonalChatIcon />,
                        },
                      ],
                    ]}
                  />
                </div>
              )}
              onDismiss={() => openSpotlight(undefined)}
            />
          )}
        </div>
        <div className="border-grey-200 border-0 border-b-[1px] border-solid py-6 px-8">
          <SearchInput
            variant="filled"
            color="grey"
            onReset={reset}
            {...inputProps}
            onClear={reset}
          />
        </div>
        <div className="flex-1 overflow-y-auto">
          {isConversationsLoading ? (
            <Loader />
          ) : (
            <div className="h-full overflow-y-auto">
              {visibleConversations?.map((item) => (
                <ChatListItem
                  key={item.id}
                  isSelected={activeChat?.id === item.id}
                  searchQuery={searchQuery}
                  onClick={setActiveChat?.bind(null, item.id, true)}
                  chat={item.data}
                />
              ))}
              {conversations.length === 1 && !searchQuery && (
                <div className="mx-auto mt-40 flex w-full max-w-[340px] flex-col items-center justify-center gap-2 p-5 text-center">
                  <span className="text-dark font-secondary text-lg font-medium leading-[27px]">
                    {t({
                      id: "chats-page.welcome-to-chats",
                      defaultMessage: "Welcome to Chats! 👋",
                    })}
                  </span>
                  <span className="text-grey font-secondary text-sm leading-[21px]">
                    {t({
                      id: "chats-page.chat-list-notifications-description",
                      defaultMessage:
                        "You will get a notification once you receive your first message",
                    })}
                  </span>
                </div>
              )}
              {searchQuery && visibleConversations.length === 0 && (
                <div className="mx-auto mt-40 flex w-full max-w-[240px] flex-col items-center justify-center gap-2.5 p-5 text-center">
                  <span className="text-dark font-secondary text-lg font-medium leading-4">
                    {t({
                      id: "common.no-results",
                      defaultMessage: "No results",
                    })}
                  </span>
                  <span className="font-secondary text-sm leading-[140%] text-[#828282]">
                    {t({
                      id: "chats-page.no-chat-was-found",
                      defaultMessage:
                        "No Chat was found for your search request",
                    })}
                  </span>
                  <Button className="mt-[22px] h-10" onClick={() => reset()}>
                    {t({
                      id: "common.clear-search",
                      defaultMessage: "Clear search",
                    })}
                  </Button>
                </div>
              )}
            </div>
          )}
          {isOnboardingActive && <OnboardingProgressBar />}
        </div>
      </div>
      <CreateWorkspaceDrawer
        isOpen={createWorkspaceModalOpened}
        onRequestClose={setCreateWorkspaceModalOpened.bind(null, false)}
      />
      <CreatePersonalChatDrawer
        isOpen={createPersonalChatModalOpened}
        onRequestClose={setCreatePersonalChatModalOpened.bind(null, false)}
      />
    </>
  );
};
