import { PaginationItem } from "@jugl-web/utils";
import { environment } from "@web-src/environments/environment";
import { ChatMessage } from "@web-src/modules/chats/types";

export const transformMessageAttachment = (
  item: PaginationItem<ChatMessage>,
  entityId: string
): PaginationItem<ChatMessage> => {
  const messagePaginationItem: PaginationItem<ChatMessage> = JSON.parse(
    JSON.stringify(item)
  );
  const message = messagePaginationItem.data;
  // Convert attachments
  // TODO: dirty!
  const attachment = message.payload?.attachments?.[0];
  if (
    attachment &&
    (!attachment.uid || attachment.url?.includes("amazonaws.com"))
  ) {
    const uid = attachment.url?.split("/").pop();
    attachment.uid = uid || "";
    attachment._preview_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
    attachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
  } else if (attachment?.uid) {
    attachment._preview_url = `${environment.apiUrl}/api/auth/attachment/preview?file=${entityId}/${attachment.uid}`;
    attachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${entityId}/${attachment.uid}`;
  }

  const replyAttachment =
    message.payload?.reply_attributes_map?.reply_attachments?.[0];
  if (
    replyAttachment &&
    (!replyAttachment.uid || replyAttachment.url?.includes("amazonaws.com"))
  ) {
    const uid = replyAttachment.url?.split("/").pop();
    replyAttachment.uid = uid || "";
    replyAttachment._preview_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
    replyAttachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${uid}`;
  } else if (replyAttachment?.uid) {
    replyAttachment._preview_url = `${environment.apiUrl}/api/auth/attachment/preview?file=${entityId}/${replyAttachment.uid}`;
    replyAttachment._stream_url = `${environment.apiUrl}/api/auth/attachment/stream?file=${entityId}/${replyAttachment.uid}`;
  }
  return messagePaginationItem;
};
