import { LoadingSpinner, SidebarDrawer, Tabs } from "@jugl-web/ui-components";
import React, { useCallback, useMemo, useState } from "react";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import {
  EntitySpaceType,
  useRestApiProvider,
  UserGeneralProfile,
} from "@jugl-web/rest-api";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useUserRolePermissionWarning } from "@jugl-web/domain-resources/users/hooks/useUserRolePermissionWarning";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { useNavigateToChat } from "@web-src/modules/chats/hooks/useNavigateToChat";
import { ReactComponent as MessageIcon } from "./assets/message.svg";

export const CreatePersonalChatDrawer: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();
  const navigateToChat = useNavigateToChat();
  const [selectedTab, setSelectedTab] = useState("all");
  const { entitySpacesApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { searchQuery, inputProps, reset } = useSearchInput();
  const meId = useSelector(selectUserId);
  const getUserRolePermissionWarning = useUserRolePermissionWarning();

  const onClose = useCallback(() => {
    onRequestClose();
    setSelectedTab("all");
    reset();
  }, [onRequestClose, reset]);

  const { data: spaces, isLoading } =
    entitySpacesApi.use_DEPRECATED_allEntitySpacesListQuery({
      entityId: entity.id,
      params: {
        type: EntitySpaceType.dept,
      },
    });

  const tabs = useMemo(
    () => [
      {
        id: "all",
        label: t({
          id: "common.all",
          defaultMessage: "All",
        }),
      },
      ...(spaces
        ? spaces.map((space) => ({ id: space.id, label: space.info.title }))
        : []),
    ],
    [spaces, t]
  );

  const handleSubmit = useCallback(
    (profile: UserGeneralProfile) => {
      navigateToChat(profile.id);
      onClose();
    },
    [navigateToChat, onClose]
  );

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex items-center gap-3">
          <MessageIcon />
          <span>
            {t({
              id: "chats-page.new-private-chat",
              defaultMessage: "New Private Chat",
            })}
          </span>
        </div>
      }
    >
      <SidebarDrawer.Content className="mb-8 flex flex-col pt-6">
        <div className="px-8">
          <SearchInput
            variant="filled"
            color="grey"
            {...inputProps}
            onReset={reset}
            onClear={reset}
          />
          <Tabs isLoading={isLoading} className="mt-3 shrink-0" color="grey">
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab.id}
                color="grey"
                fontSize="sm"
                label={tab.label}
                isActive={selectedTab === tab.id}
                onClick={() => setSelectedTab(tab.id)}
              />
            ))}
          </Tabs>
        </div>
        <div className="mt-6 h-full">
          <HeadlessUsersList
            fetchParams={{
              entityId: entity.id,
              searchQuery,
              excludeUsers: meId,
              departments: selectedTab !== "all" ? selectedTab : "",
              sortBy: "name",
            }}
            userRenderer={(user) => (
              <div className="mb-2.5 px-8">
                <UserListItem
                  entityId={entity.id}
                  userId={user.id}
                  variant="web"
                  mode="check"
                  onSelect={handleSubmit}
                  defaultSubTitle="department"
                  highlightText={searchQuery}
                  isDisabled={!hasRoleAccessToModule(user.role, AppModule.chat)}
                  disabledInfo={getUserRolePermissionWarning(AppModule.chat)}
                />
              </div>
            )}
            emptyContent={
              <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                {t({
                  id: "common.no-users-found",
                  defaultMessage: "No users found",
                })}
              </div>
            }
            loadingContent={
              <div className="flex h-full w-full items-center justify-center">
                <LoadingSpinner />
              </div>
            }
            virtualized
          />
        </div>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
