import { SubscriptionPlanModel } from "@jugl-web/rest-api/entities/models/SubscriptionPlan/SubscriptionPlanModel";
import { priceDisplay } from "@jugl-web/utils";
import { useCallback } from "react";

export const usePaymentStrings = () => {
  const getAdditionalUserPriceNote = useCallback(
    (plan: SubscriptionPlanModel) => (
      <>
        Each additional user -{" "}
        {priceDisplay(plan.additionalSeatPrice, plan.currency)}/
        {plan.intervalType === "year" ? "year" : "month"}
      </>
    ),
    []
  );
  return { getAdditionalUserPriceNote };
};
