import { HookOutOfContextError } from "@jugl-web/utils";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type TaskDetailsTabId =
  | "subtasks"
  | "attachments"
  | "order-details"
  | "history"
  | "task-info"
  | "feedback"
  | "comments";

interface TabSearchPersistenceContextValue {
  getInitialSearchQuery: (tabId: TaskDetailsTabId) => string;
  saveSearchQuery: (tabId: TaskDetailsTabId, searchQuery: string) => void;
}

const TabSearchPersistenceContext =
  createContext<TabSearchPersistenceContextValue | null>(null);

interface TabSearchPersistenceProviderProps {
  children: ReactNode;
}

export const TabSearchPersistenceProvider: FC<
  TabSearchPersistenceProviderProps
> = ({ children }) => {
  const [state, setState] = useState<Record<TaskDetailsTabId, string>>({
    subtasks: "",
    attachments: "",
    "order-details": "",
    history: "",
    "task-info": "",
    feedback: "",
    comments: "",
  });

  const getInitialSearchQuery = useCallback(
    (tabId: TaskDetailsTabId) => state[tabId],
    [state]
  );

  const saveSearchQuery = useCallback(
    (tabId: TaskDetailsTabId, searchQuery: string) => {
      setState((prevState) => ({ ...prevState, [tabId]: searchQuery }));
    },
    []
  );

  const contextValue = useMemo<TabSearchPersistenceContextValue>(
    () => ({ getInitialSearchQuery, saveSearchQuery }),
    [getInitialSearchQuery, saveSearchQuery]
  );

  return (
    <TabSearchPersistenceContext.Provider value={contextValue}>
      {children}
    </TabSearchPersistenceContext.Provider>
  );
};

export const useTabSearchPersistenceContext = () => {
  const context = useContext(TabSearchPersistenceContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTabSearchPersistenceContext",
      "TabSearchPersistenceContext"
    );
  }

  return context;
};
