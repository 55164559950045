import { cx, joinReactNodes } from "@jugl-web/utils";
import { FC, ReactNode, useMemo } from "react";
import {
  DraggableFieldBoxContainer,
  DraggableFieldBoxContainerProps,
} from "../../../custom-fields/components/DraggableFieldBoxContainer";
import {
  FieldBox,
  FieldBoxProps,
} from "../../../custom-fields/components/FieldBox";

export interface TaskFieldBoxProps
  extends Pick<FieldBoxProps, "title" | "customEndSlot" | "onClick"> {
  subtitle: {
    icon: ReactNode;
    label: string;
    valuesCount?: number;
    defaultValuesCount?: number;
  };
  isDefault?: boolean;
}

export const TaskFieldBox: FC<TaskFieldBoxProps> = ({
  subtitle,
  isDefault = false,
  ...fieldBoxProps
}) => {
  const subtitleParts = useMemo<ReactNode[]>(() => {
    const parts = [
      <div className="flex items-center gap-1.5">
        {subtitle.icon}
        <span className={cx(isDefault && "text-primary-800 font-medium")}>
          {subtitle.label}
        </span>
      </div>,
    ];

    if (typeof subtitle.defaultValuesCount === "number") {
      parts.push(
        <span className="text-xs text-[#828282]">
          {subtitle.defaultValuesCount} default values
        </span>
      );
    }

    if (typeof subtitle.valuesCount === "number") {
      parts.push(
        <span className="text-xs text-[#828282]">
          {subtitle.valuesCount} {isDefault ? "custom values" : "values"}
        </span>
      );
    }

    return parts;
  }, [subtitle, isDefault]);

  return (
    <FieldBox
      subtitle={joinReactNodes(
        subtitleParts,
        <div className="h-4 w-px bg-[#E0E0E0]" />
      )}
      {...fieldBoxProps}
    />
  );
};

export type DraggableTaskFieldBoxProps = Pick<
  DraggableFieldBoxContainerProps,
  "id" | "index"
> &
  TaskFieldBoxProps;

export const DraggableTaskFieldBox: FC<DraggableTaskFieldBoxProps> = ({
  id,
  index,
  ...fieldBoxProps
}) => (
  <DraggableFieldBoxContainer id={id} index={index} className="mb-6">
    <TaskFieldBox {...fieldBoxProps} />
  </DraggableFieldBoxContainer>
);
