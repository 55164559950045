import React, { useMemo } from "react";
import { getMessageReactionsSummary } from "@web-src/modules/chats/components/DeprecatedChatMessageBubble/utils";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";
import { ReactionsPopover } from "./components";

export const Reactions: React.FC = () => {
  const { message } = useChatMessageProvider();

  const sortedReactions = useMemo(
    () => getMessageReactionsSummary(message),
    [message]
  );

  const visibleReactions = useMemo(
    () => sortedReactions.slice(0, 5),
    [sortedReactions]
  );

  return (
    <>
      <div className="absolute left-0 bottom-0 flex h-[26px] translate-y-5 cursor-pointer items-center gap-1 rounded-xl bg-white px-1.5 py-1 shadow">
        {visibleReactions.map((item, idx) => (
          <ReactionsPopover
            key={item.reaction}
            reactionItem={item}
            moreReactions={idx === 4 && sortedReactions.length > 5}
            reactionsLength={sortedReactions.length}
          />
        ))}
        <span className="text-grey-600 text-xs font-medium">
          {message.reactions?.length}
        </span>
      </div>
    </>
  );
};
