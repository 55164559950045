import React, { useMemo } from "react";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import { useMatch } from "react-router-dom";
import { ChatWindow } from "./components/ChatWindow";
import { ChatList } from "./components/ChatList";
import {
  ChatsPageProvider,
  useChatsPageProvider,
} from "./providers/ChatsPageProvider";

const ChatsPageContent: React.FC = () => {
  const { activeChatId } = useChatsPageProvider();
  const match = useMatch(`/:entityId/chats/:activeChatId`);
  const $content = useMemo(() => {
    if (match?.params.activeChatId || activeChatId) {
      return activeChatId ? <ChatWindow /> : null;
    }
    return (
      <div className="text-dark-600 flex h-full w-full items-center justify-center">
        Select a chat to start conversation
      </div>
    );
  }, [activeChatId, match?.params.activeChatId]);
  return (
    <MultiSectionLayout leftSide={<ChatList />}>{$content}</MultiSectionLayout>
  );
};

const ChatsPage: React.FC = () => (
  <ChatsPageProvider>
    <ChatsPageContent />
  </ChatsPageProvider>
);

export default ChatsPage;
