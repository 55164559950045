import { InteractiveContainer, StepperLayout } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useSearchParams } from "react-router-dom";
import { OrderFormStep } from "../../types";
import { useOrderForm } from "../../providers/OrderFormProvider";
import { ReactComponent as EyeIcon } from "./assets/eye.svg";
import { OrderFormPreview } from "./components/OrderFormPreview";
import { removeDiscountFlow } from "../../consts";

export const OrderFormStepper: React.FC<{
  children: React.ReactNode;
  className?: string;
  onCreateForm?: () => void;
  onMoveToDetails?: () => void;
}> = ({ children, className, onMoveToDetails, onCreateForm }) => {
  const { t } = useTranslations();
  const {
    orderStep,
    changeOrderStep,
    isPreviewMode,
    setIsPreviewMode,
    formId,
  } = useOrderForm();
  const { navigateToPage } = useNavigation();
  const [searchParams] = useSearchParams();
  const isDiscountFlow = searchParams.get("flow") === removeDiscountFlow;

  const forwardButtonLabel = useMemo(() => {
    switch (orderStep) {
      case OrderFormStep.form:
        return t({
          id: "order-form-wizard-page.next-step",
          defaultMessage: "Next step",
        });
      case OrderFormStep.details:
        return formId
          ? t({
              id: "order-form-wizard-page.save-form",
              defaultMessage: "Save Form",
            })
          : t({
              id: "order-form-wizard-page.create-form",
              defaultMessage: "Create Form",
            });
      default:
        throw new Error("Unsupported order form step");
    }
  }, [orderStep, t, formId]);

  const onBackButtonClick = useMemo(() => {
    switch (orderStep) {
      case OrderFormStep.form:
        return undefined;
      case OrderFormStep.details:
        return () => changeOrderStep(OrderFormStep.form);
      default:
        throw new Error("Unsupported order form step");
    }
  }, [orderStep, changeOrderStep]);

  const rightContentButtons = useMemo(
    () => [
      // TEMPORARILY HIDDEN
      // {
      //   id: "theme",
      //   label: t({
      //     id: "order-form-wizard-page.theme",
      //     defaultMessage: "Theme",
      //   }),
      //   icon: <PaletteIcon />,
      //   onClick: () => {},
      // },
      {
        id: "preview",
        label: t({
          id: "order-form-wizard-page.preview",
          defaultMessage: "Preview",
        }),
        icon: <EyeIcon />,
        onClick: () => setIsPreviewMode(true),
      },
    ],
    [setIsPreviewMode, t]
  );

  return (
    <StepperLayout
      activeStep={orderStep}
      hideFooter={isPreviewMode}
      hideSteps={isPreviewMode}
      steps={[
        {
          id: OrderFormStep.form,
          label: formId
            ? t({
                id: "order-form-wizard-page.edit-form",
                defaultMessage: "Edit Form",
              })
            : t({
                id: "order-form-wizard-page.create-form",
                defaultMessage: "Create Form",
              }),
          onClick: () => changeOrderStep(OrderFormStep.form),
        },
        {
          id: OrderFormStep.details,
          label: t({
            id: "order-form-wizard-page.set-task-details",
            defaultMessage: "Set Task Details",
          }),
          onClick: onMoveToDetails,
        },
      ]}
      breadcrumbProps={{
        items: [
          {
            title: isDiscountFlow
              ? t({
                  id: "order-form-wizard-page.inventory",
                  defaultMessage: "Inventory",
                })
              : t({
                  id: "order-form-wizard-page.forms",
                  defaultMessage: "Forms",
                }),
            onClick: () =>
              isDiscountFlow
                ? navigateToPage("inventoryDiscounts")
                : navigateToPage("orderForms"),
          },
          {
            title: formId
              ? t({
                  id: "order-form-wizard-page.edit-form",
                  defaultMessage: "Edit Form",
                })
              : t({
                  id: "order-form-wizard-page.new-form",
                  defaultMessage: "New Form",
                }),
          },
          ...(isPreviewMode
            ? [
                {
                  title: t({
                    id: "order-form-wizard-page.preview",
                    defaultMessage: "Preview",
                  }),
                },
              ]
            : []),
        ],
      }}
      buttons={[
        {
          className: cx(
            "w-[140px] h-10 font-secondary",
            !onBackButtonClick && "hidden"
          ),
          color: "grey",
          onClick: onBackButtonClick,
          text: t({
            id: "common.back",
            defaultMessage: "Back",
          }),
        },
        {
          className: "w-[200px] h-10 font-secondary",
          onClick:
            orderStep === OrderFormStep.form ? onMoveToDetails : onCreateForm,
          text: forwardButtonLabel,
        },
      ]}
      rightContent={
        !isPreviewMode && (
          <div className="flex items-center gap-6">
            {rightContentButtons.map((el) => (
              <InteractiveContainer
                className="bg-grey-100 hover:bg-grey-200 flex items-center gap-2.5 rounded-lg py-[5px] px-4 transition-colors"
                key={el.id}
                onClick={el.onClick}
              >
                <span className="font-secondary text-dark-700 leading-[160%]">
                  {el.label}
                </span>
                {el.icon}
              </InteractiveContainer>
            ))}
          </div>
        )
      }
    >
      <div
        className={cx(
          "flex min-h-full min-w-full flex-col items-center bg-[#E8EDF4] p-8",
          className
        )}
      >
        <div className="flex w-full max-w-[900px] grow flex-col">
          <div className="flex grow flex-col">
            {!isPreviewMode ? children : <OrderFormPreview />}
          </div>
        </div>
      </div>
    </StepperLayout>
  );
};
