import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";

export const ConfirmationSettingsChangeAlert: React.FC<{
  isOpen: boolean;
  type?:
    | "admins-channel-enable"
    | "admins-channel-disable"
    | "content-protection-enable"
    | "content-protection-disable";
  onConfirm: () => void;
  onRequestClose: () => void;
}> = ({ isOpen, type, onConfirm, onRequestClose }) => {
  const { t } = useTranslations();

  const content = useMemo(() => {
    switch (type) {
      case "content-protection-enable":
        return t(
          {
            id: "chats-page.enable-content-protection-confirmation",
            defaultMessage:
              "Do you really want to enable <highlightedText>Content protection</highlightedText>? Members will not be able to save, forward or copy content from this Group ",
          },
          {
            highlightedText: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        );
      case "content-protection-disable":
        return t(
          {
            id: "chats-page.disable-content-protection-confirmation",
            defaultMessage:
              "Do you really want to disable <highlightedText>Content protection</highlightedText>? Members will be able to save, forward or copy content from this Group",
          },
          {
            highlightedText: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        );
      case "admins-channel-enable":
        return t(
          {
            id: "chats-page.enable-broadcast-chat-confirmation",
            defaultMessage:
              "Do you really want to enable <highlightedText>Broadcast Chat</highlightedText>? This is a Admins-to-many channel, members will not be able to send messages",
          },
          {
            highlightedText: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        );
      default:
        return t(
          {
            id: "chats-page.disable-broadcast-chat-confirmation",
            defaultMessage:
              "Do you really want to turn off setting of <highlightedText>Broadcast Chat</highlightedText>? If you confirm, all members of the chat will be able to message each other inside the chat",
          },
          {
            highlightedText: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        );
    }
  }, [t, type]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm Action",
      })}
      content={content}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text:
            type === "content-protection-enable"
              ? t({
                  id: "common.enable",
                  defaultMessage: "Enable",
                })
              : t({
                  id: "common.confirm",
                  defaultMessage: "Confirm",
                }),
          onClick: () => {
            onConfirm();
            onRequestClose();
          },
        },
      ]}
    />
  );
};
