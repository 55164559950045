import { RtkEmptySplitApi } from "../../types";
import { ChatMessageModel } from "./models/ChatMessage";
import { ChatMessagesApiFetchParams } from "./payloads";

export const addChatsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [],
  });

  const chatsApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      messages: builder.query<
        { data: ChatMessageModel[]; remaining_item: boolean },
        {
          params: ChatMessagesApiFetchParams;
        }
      >({
        query: ({ params }) => ({
          url: `/api/auth/messages`,
          params: {
            ...params,
            time: params.time ? params.time.replace("T", " ") : undefined,
          },
          transformResponse: (response: string) => {
            const parsedResponse: {
              data: ChatMessageModel[];
              remaining_item: boolean;
            } = JSON.parse(response);
            return {
              ...parsedResponse,
              data: parsedResponse.data.filter(
                (item) => item.payload?.type !== "call"
              ),
            };
          },
        }),
      }),
    }),
  });

  return chatsApi;
};

export type ChatsApi = ReturnType<typeof addChatsApi>;
