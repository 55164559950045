import {
  Alert,
  InteractiveContainer,
  PlainButton,
  Popover,
} from "@jugl-web/ui-components";
import { priceDisplay, useTranslations } from "@jugl-web/utils";
import React, { useState } from "react";
import Lottie from "react-lottie";
import { ServiceCartList } from "../ServiceCartList";
import { ReactComponent as CartIcon } from "../../assets/cart.svg";
import alertAnimation from "../../assets/alert-animation.json";
import { OrderFormInventorySelectValueItem } from "../../../OrderFormInventorySelect";
import { ServiceCartItem } from "../../types";

interface ServiceCartPopoverProps {
  items: ServiceCartItem[];
  isPreview: boolean;
  currency: string;
  discounts: Record<string, { discount: string; value: number }>;
  value: OrderFormInventorySelectValueItem[];
  total: number;
  onUpdateService: (serivceId: string, newValue: number) => void;
  onRemoveService: (serivceId: string) => void;
  onChange: (value: OrderFormInventorySelectValueItem[]) => void;
}

export const ServiceCartPopover: React.FC<ServiceCartPopoverProps> = ({
  items,
  isPreview,
  currency,
  discounts,
  value,
  total,
  onUpdateService,
  onRemoveService,
  onChange,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t } = useTranslations();

  return (
    <>
      {!!value.length && (
        <Popover
          placement="top-start"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              ref={triggerRef}
              as={PlainButton}
              className="border-grey/10 hover:bg-grey-100 sticky top-[calc(100%-208px)] flex h-20 w-20 shrink-0 items-center justify-center rounded-2xl border-4 border-solid bg-white shadow-[0px_12px_16px_rgba(25,25,25,0.14)] outline-none transition-colors"
            >
              <div className="relative h-max w-max">
                <CartIcon />
                <div className="bg-gradients-danger absolute -right-1 -top-1 rounded-[10px] border border-solid border-white py-0.5 px-2">
                  <span className="font-secondary text-[10px] font-bold leading-[15px] text-white">
                    {items.length}
                  </span>
                </div>
              </div>
            </Trigger>
          )}
          className="border-dark-100 overflow-hidden rounded-xl border border-solid shadow-[0_24px_24px_rgba(53,54,56,0.10)]"
        >
          <div className="flex w-[375px] flex-col">
            <div className="bg-grey-100 flex items-center justify-between px-6 py-[14px]">
              <span className="font-secondary text-lg font-medium text-[#383838]">
                {t({
                  id: "order-form-submission-component.order",
                  defaultMessage: "Order",
                })}
              </span>
              <InteractiveContainer
                className="text-gradients-danger font-secondary text-xs leading-[140%] tracking-[0.12px] underline"
                onClick={() => setIsAlertOpen(true)}
              >
                {t({
                  id: "order-form-submission-component.clear-all",
                  defaultMessage: "Clear All",
                })}
              </InteractiveContainer>
            </div>
            <ServiceCartList
              currency={currency}
              discounts={discounts}
              isPreview={isPreview}
              items={items}
              value={value}
              onRemoveService={onRemoveService}
              onUpdateService={onUpdateService}
            />
            <div className="bg-grey-100 flex justify-end px-6 py-[14px]">
              <span className="text-dark text-lg font-medium leading-[140%] tracking-[0.18px]">
                {t(
                  {
                    id: "order-form-submission-component.total-cost",
                    defaultMessage: "Total: {cost}",
                  },
                  { cost: priceDisplay(total * 100, currency) }
                )}
              </span>
            </div>
          </div>
        </Popover>
      )}
      <Alert
        isOpen={isAlertOpen}
        onRequestClose={() => setIsAlertOpen(false)}
        img={
          <Lottie
            options={{ animationData: alertAnimation }}
            height={70}
            width={70}
          />
        }
        title={t({
          id: "common.confirm-action",
          defaultMessage: "Confirm action",
        })}
        content={t(
          {
            id: "order-form-submission-component.clear-cart-confirmation",
            defaultMessage:
              "Do you really want to clear your cart with {count} <highlightedText>{count, plural, one {item} other {items}}</highlightedText>?",
          },
          {
            count: items.length,
            highlightedText: (text: (string | JSX.Element)[]) => (
              <span className="text-primary-800 font-medium">{text}</span>
            ),
          }
        )}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.clear",
              defaultMessage: "Clear",
            }),
            onClick: () => {
              setIsAlertOpen(false);
              onChange([]);
            },
          },
        ]}
      />
    </>
  );
};
