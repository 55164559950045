import { cx, useTranslations } from "@jugl-web/utils";
import { Tab } from "@jugl-web/ui-components/web/TabsLayout/components/Tab";
import { FC, useMemo, useState } from "react";
import { TaskPreviewGeneralInfo } from "@web-src/pages/Tasks/components/TaskPreviewSideBar/TaskPreviewGeneralInfo";
import { TaskPreviewComments } from "@web-src/pages/Tasks/components/TaskPreviewSideBar/TaskPreviewComments";
import { TaskPreviewFeedback } from "@web-src/pages/Tasks/components/TaskPreviewSideBar/TaskPreviewFeedback";
import { HorizontalScrollableContainer } from "@jugl-web/ui-components";
import { TabSearchPersistenceProvider } from "@jugl-web/domain-resources/tasks";
import { TaskDetailsTabId } from "../../types";
import { TaskDetailsAttachmentsTabContent } from "../TaskDetailsAttachmentsTabContent";
import { TaskDetailsHistoryTabContent } from "../TaskDetailsHistoryTabContent/TaskDetailsHistoryTabContent";
import { TaskDetailsOrderTabContent } from "../TaskDetailsOrderTabContent";
import { TaskDetailsSubtasksTabContent } from "../TaskDetailsSubtasksTabContent";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";

interface TaskDetailsTab {
  id: TaskDetailsTabId;
  title: React.ReactNode;
  Component: FC<{ isPreview?: boolean }>;
  count?: number;
  isPreview?: boolean;
  isHidden?: boolean;
}

interface TaskDetailsTabsProps {
  isPreview?: boolean;
}

export const TaskDetailsTabs: FC<TaskDetailsTabsProps> = ({ isPreview }) => {
  const [selectedTab, setSelectedTab] = useState<TaskDetailsTabId>(
    isPreview ? "task-info" : "subtasks"
  );
  const { task } = useLoadedTaskDetailsContext();

  const { t } = useTranslations();
  const tabs = useMemo<TaskDetailsTab[]>(
    () => [
      ...(isPreview
        ? [
            {
              id: "task-info",
              title: t({
                id: "task-preview-sidebar.task-info-tab-title",
                defaultMessage: "Task Info",
              }),
              Component: TaskPreviewGeneralInfo,
              isPreview: true,
            } as TaskDetailsTab,
          ]
        : []),
      {
        id: "subtasks",
        title: t({
          id: "tasks-page.subtasks",
          defaultMessage: "Subtasks",
        }),
        count: task.checklist.length,
        Component: TaskDetailsSubtasksTabContent,
      },
      {
        id: "attachments",
        title: t({
          id: "tasks-page.attachments",
          defaultMessage: "Attachments",
        }),
        count: task.attachment_count,
        Component: TaskDetailsAttachmentsTabContent,
      },
      ...(task.order_resp
        ? [
            {
              id: "order-details",
              title: t({
                id: "tasks-page.order-details",
                defaultMessage: "Order details",
              }),
              count: task.order_count,
              Component: TaskDetailsOrderTabContent,
            } as TaskDetailsTab,
          ]
        : []),
      ...(isPreview
        ? [
            {
              id: "comments",
              title: (
                <div className="flex items-center justify-center gap-1.5">
                  {t({
                    id: "task-preview-sidebar.comments-tab-title",
                    defaultMessage: "Comments",
                  })}
                  {task.cmnt_unread && (
                    <div className="bg-gradients-danger h-2 w-2 rounded-full" />
                  )}
                </div>
              ),
              Component: TaskPreviewComments,
              isPreview: true,
            } as TaskDetailsTab,
            {
              id: "feedback",
              title: (
                <div className="flex items-center justify-center gap-1.5">
                  {t({
                    id: "task-preview-sidebar.customer-feedback-tab-title",
                    defaultMessage: "Customer Feedback",
                  })}
                  {task.feedback_unread && (
                    <div className="bg-gradients-danger h-2 w-2 rounded-full" />
                  )}
                </div>
              ),
              Component: TaskPreviewFeedback,
              isPreview: true,
            } as TaskDetailsTab,
          ]
        : []),
      {
        id: "history",
        title: t({
          id: "tasks-page.history",
          defaultMessage: "History",
        }),
        count: task.history_count,
        Component: TaskDetailsHistoryTabContent,
      },
    ],
    [t, task, isPreview]
  );
  const activeTab = useMemo(
    () => tabs.find((tab) => tab.id === selectedTab) ?? tabs[0],
    [tabs, selectedTab]
  );

  const $tabsContent = (
    <div
      className={cx(
        "border-b-grey-300 flex items-center border border-l-0 border-r-0 border-t-0 border-solid",
        {
          "px-8": isPreview,
        }
      )}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          title={tab.title}
          isSelected={selectedTab === tab.id}
          count={tab.count}
          className="w-auto"
          onClick={() => setSelectedTab(tab.id)}
        />
      ))}
    </div>
  );

  return (
    <div
      className={cx({
        "mt-12 h-[calc(100%-92px)]": !isPreview,
        "flex flex-1 flex-col": isPreview,
      })}
    >
      {isPreview ? (
        <HorizontalScrollableContainer>
          {$tabsContent}
        </HorizontalScrollableContainer>
      ) : (
        $tabsContent
      )}
      <TabSearchPersistenceProvider>
        <div
          key={activeTab.id}
          className={cx("animate-fade-in grow", {
            "h-[calc(100%-92px)] pt-8": !isPreview,
            "px-8 pt-8":
              isPreview &&
              !["comments", "feedback", "history"].includes(activeTab.id),
          })}
        >
          <activeTab.Component isPreview={activeTab.isPreview} />
        </div>
      </TabSearchPersistenceProvider>
    </div>
  );
};
