import * as yup from "yup";
import { WidgetDto } from "./WidgetDto";
import {
  SUPPORTED_CHART_SORTINGS,
  SUPPORTED_CHART_TYPES,
  SUPPORTED_CHART_Y_AXIS_FORMATS,
  WidgetChartSorting,
  WidgetChartType,
  WidgetChartYAxisFormat,
  WidgetModel,
} from "./WidgetModel";

const seriesSchema = yup
  .array()
  .min(1)
  .of(
    yup.object({
      y_axis: yup.string().required(),
      type: yup
        .string()
        .required()
        .transform((value) => {
          if (!SUPPORTED_CHART_TYPES.includes(value)) {
            return "line" as WidgetChartType;
          }

          return value as WidgetChartType;
        }),
    })
  )
  .required();

const metadataSchema = yup.object({
  y_axis_format: yup
    .string()
    .default("value" as WidgetChartYAxisFormat)
    .transform((value) => {
      if (!SUPPORTED_CHART_Y_AXIS_FORMATS.includes(value)) {
        return "value" as WidgetChartYAxisFormat;
      }

      return value as WidgetChartYAxisFormat;
    }),
  sorting: yup
    .string()
    .default("x-axis-asc" as WidgetChartSorting)
    .transform((value) => {
      if (!SUPPORTED_CHART_SORTINGS.includes(value)) {
        return "x-axis-asc" as WidgetChartSorting;
      }

      return value as WidgetChartSorting;
    }),
  color_palette: yup.array().of(yup.string()).default([]),
  show_unassigned_categories: yup.boolean().default(false),
});

export const transformWidgetDtoToModel = (dto: WidgetDto): WidgetModel =>
  ({
    ...dto,
    config: {
      ...dto.config,
      series: seriesSchema.validateSync(dto.config.series),
      meta: metadataSchema.validateSync(dto.config.meta),
    },
  } as WidgetModel);
