import { UserCustomFieldType } from "@jugl-web/rest-api";

export const MAX_USER_CUSTOM_FIELDS_COUNT = 10;
export const MAX_USER_BOOKMARKS_COUNT = 3;
export const MAX_USER_CUSTOM_FIELD_VALUE_LENGTH = 100;

export const USER_CUSTOM_FIELD_TYPES: UserCustomFieldType[] = [
  "text",
  "dropdown",
  "email",
  "number",
  "phone",
];
