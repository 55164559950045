import { InternalTaskCustomField, PreviewTask } from "@jugl-web/rest-api/tasks";
import {
  PILL_BASE_BG_COLOR,
  PILL_BASE_TEXT_COLOR,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { colord } from "colord";
import format from "date-fns/format";
import { TaskCardField } from "./TaskCard";

export const adaptCustomFieldToTaskCardField = (
  customField: InternalTaskCustomField,
  value: string,
  dateLocale?: Locale
): TaskCardField | null => {
  // Format value if necessary
  if (value === "") {
    return null;
  }

  if (customField.type === "date" && value) {
    value = format(new Date(value), "d MMMM, yyyy, hh:mm a", {
      locale: dateLocale,
    });
  } else if (customField.type === "dropdown") {
    const dropdownValue = customField.values?.find(
      (v) => v.id === value
    )?.value;

    if (!dropdownValue) {
      return null;
    }

    value = dropdownValue;
  }

  return {
    id: customField.id,
    value: `${customField.name}: ${value}`,
    hint: (
      <>
        {customField.name}: <b>{value}</b>
      </>
    ),
    textColor: PILL_BASE_TEXT_COLOR,
    backgroundColor: PILL_BASE_BG_COLOR,
  };
};

export const adaptCustomFieldsToTaskCardFields = (
  customFields: PreviewTask["custom_fields"],
  getCustomFieldById: (id: string) => InternalTaskCustomField | undefined,
  dateLocale?: Locale
): TaskCardField[] => {
  if (!customFields) {
    return [];
  }

  const matchingCustomFields = Object.keys(customFields).reduce<
    InternalTaskCustomField[]
  >((acc, customFieldId) => {
    const customField = getCustomFieldById(customFieldId);

    if (!customField || !customField.isShownInCard) {
      return acc;
    }

    return [...acc, customField];
  }, []);

  return matchingCustomFields
    .sort((a, b) => a.order - b.order)
    .reduce<TaskCardField[]>((acc, customField) => {
      const taskCardField = adaptCustomFieldToTaskCardField(
        customField,
        customFields[customField.id],
        dateLocale
      );

      if (!taskCardField) {
        return acc;
      }

      return [...acc, taskCardField];
    }, []);
};

export class TaskCardFieldsManager {
  private fields: TaskCardField[] = [];

  appendIfRelevant(...fields: (TaskCardField | null)[]) {
    fields.forEach((field) => {
      if (field) {
        this.fields.push(field);
      }
    });
  }

  toArray() {
    return this.fields;
  }
}

export const getBoardFieldColors = (boardColor: string) => {
  const brightness = colord(boardColor).brightness();
  const isDark = brightness < 0.6;

  // If the board color is dark, we need to lighten the background color
  // and darken a bit the text color to make sure it's readable
  if (isDark) {
    return {
      backgroundColor: colord(boardColor)
        // Based on the brightness, we need to lighten more or less
        .lighten(0.85 - brightness)
        .toHex(),
      textColor: colord(boardColor).darken(0.1).toHex(),
    };
  }

  // If the board color is light, we need to darken the text color
  // and put a semi-transparent background color
  return {
    backgroundColor: colord(boardColor).alpha(0.4).toHex(),
    textColor: colord(boardColor).darken(0.8).toHex(),
  };
};
