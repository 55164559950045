import { useRestApiProvider } from "@jugl-web/rest-api";
import { assert, getUniqueId, useMemoCompare } from "@jugl-web/utils";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomerDetails, getCustomerDetails } from "../utils";

interface UseMultipleCustomersOptions {
  entityId: string | undefined;
  customerIds: string[];
  skipLoading?: boolean;
}

export const useMultipleCustomers = ({
  entityId,
  customerIds,
  skipLoading,
}: UseMultipleCustomersOptions) => {
  const [customers, setCustomers] = useState<CustomerDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { customersApi } = useRestApiProvider();
  const [getCustomer] = customersApi.useLazyCustomerQuery();
  const batchRequestIdRef = useRef(getUniqueId());

  const memoizedCustomerIds = useMemoCompare(customerIds, isEqual);

  const customerDetailsById = useMemo<Record<string, CustomerDetails>>(() => {
    const result: Record<string, CustomerDetails> = {};

    customers.forEach((customer) => {
      result[customer.id] = customer;
    });

    return result;
  }, [customers]);

  const loadCustomers = useCallback(async () => {
    assert(!!entityId, "entityId is required");

    setIsLoading(true);

    const batchRequestId = batchRequestIdRef.current;

    const responses = await Promise.all(
      memoizedCustomerIds.map((customerId) =>
        getCustomer({ entityId, customerId }, true)
      )
    );

    if (batchRequestId !== batchRequestIdRef.current) {
      return;
    }

    setCustomers(
      responses.reduce<CustomerDetails[]>(
        (acc, response) =>
          response.data
            ? [...acc, getCustomerDetails(response.data.data)]
            : acc,
        []
      )
    );

    setIsLoading(false);
  }, [entityId, getCustomer, memoizedCustomerIds]);

  useEffect(() => {
    if (!entityId || skipLoading) {
      return undefined;
    }

    loadCustomers();

    return () => {
      batchRequestIdRef.current = getUniqueId();
    };
  }, [entityId, loadCustomers, skipLoading]);

  return { customers, customerDetailsById, isLoading };
};
