export const getUsernameInitials = (username: string) => {
  const nameParts = username.split(" ");

  if (nameParts.length > 1) {
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];

    const firstLetter = firstName ? Array.from(firstName)[0] : "";
    const lastLetter = lastName ? Array.from(lastName)[0] : "";

    return firstLetter.concat(lastLetter);
  }

  const [name] = nameParts;
  return name.substring(0, 2);
};
