import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskCreator } from "@jugl-web/domain-resources/tasks/components/TaskCreator";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskInfoAccessButton } from "@jugl-web/domain-resources/tasks/components/TaskInfoAccessButton";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { TaskDefaultStatusId } from "@jugl-web/rest-api/tasks";
import { TaskHoverableButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskHoverableButton";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import {
  isoToLocalDate,
  omit,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { AddNewCustomerDialog } from "@web-src/modules/customers/components/AddNewCustomerDialog";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { FC, useCallback, useState } from "react";
import { ReactComponent as AddCustomerIcon } from "../../assets/add-customer.svg";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { ReactComponent as RecurringIcon } from "../../assets/recurring.svg";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";
import { TaskDetailsMoreMenu } from "../TaskDetailsMoreMenu";

export const TaskDetailsGeneralInfo: FC = () => {
  const { task, updateTask } = useLoadedTaskDetailsContext();

  const { entityId } = useEntitySelectedProvider();
  const { meId, isAdmin } = useLoadedMe();

  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const [isAddNewCustomerDialogOpen, setIsAddNewCustomerDialogOpen] =
    useState(false);

  const permissions = useTaskPermissions({ task, entityId, meId });

  const { timeZone } = useTimeZone();
  const { toast } = useToast();
  const { t } = useTranslations();

  const { hasAccess: hasBoardAccess } = useTaskBoards({ entityId });

  const handleCopyTaskLink = () => {
    navigator.clipboard.writeText(task.short_url).then(() => {
      toast(
        t({
          id: "feedback.task-link-copied",
          defaultMessage: "Task link has been copied to clipboard",
        })
      );
    });
  };

  const handleUpdateCustomFields = useCallback(
    (fieldId: string, rawValue: string) => {
      if (!rawValue) {
        updateTask({
          custom_fields: omit(task.custom_fields || {}, fieldId),
        });

        return;
      }

      updateTask({
        custom_fields: {
          ...task.custom_fields,
          [fieldId]: rawValue,
        },
      });
    },
    [task, updateTask]
  );

  return (
    <>
      <div className="flex items-start gap-4">
        <div className="flex flex-1 flex-col">
          <div className="max-w-[900px]">
            <TaskCreator
              entityId={entityId}
              userId={task.created_by}
              createdAt={task.created_at}
              className="mb-2"
            />

            <div className="flex items-center gap-3">
              {task.recurrence && (
                <Tooltip
                  placement="bottom"
                  renderTrigger={({ ref, props }) => (
                    <div
                      ref={ref}
                      className="bg-tertiary-100 flex h-8 w-8 shrink-0 items-center justify-center rounded-md"
                      {...props}
                    >
                      <RecurringIcon />
                    </div>
                  )}
                >
                  {t({
                    id: "tasks-page.recurring-task",
                    defaultMessage: "Recurring task",
                  })}
                </Tooltip>
              )}
              <div className="grow">
                <TitleBox
                  prefix={task.order_id || undefined}
                  isOrder={!!task.order_id}
                  title={task.name}
                  isEditable={permissions.canEditTitle}
                  onChange={(title) => updateTask({ name: title })}
                  classes={{
                    titleText: "py-2 mb-1",
                    editingBox: "mb-1",
                  }}
                />
              </div>
            </div>
            {(task.desc || isEditingDescription) && (
              <DescriptionBox
                description={task.desc || ""}
                isEditing={isEditingDescription}
                isEditable={permissions.canEditDescription}
                onChange={(value) => updateTask({ desc: value })}
                onStartEditing={() => setIsEditingDescription(true)}
                onFinishEditing={() => {
                  setIsEditingDescription(false);
                }}
              />
            )}
            <TaskPropertiesPanel
              entityId={entityId}
              config={{
                board: {
                  boardId: task.board_id,
                  hasBoardAccess: task.board_id
                    ? hasBoardAccess(task.board_id)
                    : undefined,
                  isReadonly: !permissions.canEditBoard,
                  isHidden: task.is_self,
                  shouldShowUpdateToast: true,
                  onChange: (boardId) => updateTask({ board_id: boardId }),
                },
                description: {
                  isHidden:
                    isEditingDescription ||
                    !!task.desc ||
                    !permissions.canEditDescription,
                  onClick: () => setIsEditingDescription(true),
                },
                status: {
                  statusId: task.status,
                  isReadonly: !permissions.canEditStatus,
                  onChange: (value) => updateTask({ status: value }),
                },
                dueDate: {
                  state: task.due_at
                    ? {
                        date: isoToLocalDate(task.due_at),
                        timezone: task.tz || undefined,
                        reminderExecutionTime: task.reminder
                          ? isoToLocalDate(task.reminder.next_execution)
                          : undefined,
                      }
                    : null,
                  defaultTimezone: timeZone.name,
                  displayAs: "date",
                  withAdvancedOptions:
                    task.status !== TaskDefaultStatusId.completed,
                  isReadonly: !permissions.canEditDueDate,
                  onChange: (state) => {
                    if (!state) {
                      updateTask({ due_at: null, tz: null, reminder: null });
                      return;
                    }

                    updateTask({
                      due_at: state.date.toISOString(),
                      tz: state.timezone || null,
                      reminder: state.reminderExecutionTime
                        ? {
                            next_execution:
                              state.reminderExecutionTime.toISOString(),
                          }
                        : null,
                    });
                  },
                },
                assignees: {
                  assigneeIds: task.assignees,
                  isReadonly: !permissions.canEditAssignees,
                  onChange: (assigneeIds) =>
                    updateTask({ assignees: assigneeIds }),
                  isHidden: task.is_self,
                },
                priority: {
                  priority: task.priority,
                  isReadonly: !permissions.canEditPriority,
                  onChange: (priority) => updateTask({ priority }),
                },
                label: {
                  labelId: task.label_id,
                  isReadonly: !permissions.canEditLabel,
                  onChange: (labelId) => updateTask({ label_id: labelId }),
                },
                customer: {
                  customerId: task.cust_id,
                  isReadonly: !permissions.canEditCustomer,
                  shouldWarnBeforeUpdate: true,
                  isHidden: task.is_self,
                  addButton: isAdmin
                    ? {
                        label: t({
                          id: "tasks-page.add-new-customer",
                          defaultMessage: "Add customer",
                        }),
                        onClick: () => setIsAddNewCustomerDialogOpen(true),
                        startIcon: <AddCustomerIcon />,
                      }
                    : undefined,
                  onChange: async (customer) =>
                    updateTask({
                      cust_id: customer?.id || null,
                    }),
                },
                timeSpent: {
                  duration: task.duration,
                  isReadonly: !permissions.canEditTimeSpentOnTask,
                  onChange: (duration) => updateTask({ duration }),
                },
                recurrence: {
                  state: task.recurrence,
                  defaultTimezone: timeZone.name,
                  shouldWarnBeforeRemoving: true,
                  isReadonly: !permissions.canEditRecurrence,
                  onChange: (recurrence) => updateTask({ recurrence }),
                },
                customFields: {
                  valuesById: task.custom_fields || {},
                  onFieldChange: handleUpdateCustomFields,
                  isReadonly: !permissions.canEditCustomFields,
                },
              }}
              className="mt-7"
            />
            <TaskExtraPropertiesPanel
              entityId={entityId}
              config={{
                privateTask: {
                  isHidden: !task.is_self,
                  type: "presentational",
                },
                completeChecklistInOrder: {
                  isChecked: task.has_chklist_chk,
                  isHidden: !permissions.canManageCompleteInSpecificOrderOption,
                  onChange: (isChecked) =>
                    updateTask({ has_chklist_chk: isChecked }),
                },
              }}
              className="mt-8"
            />
          </div>
        </div>
        <div className="flex shrink-0 items-center gap-4">
          {meId && (
            <TaskInfoAccessButton task={task} entityId={entityId} meId={meId} />
          )}
          <Tooltip
            className="w-[110px] text-center"
            renderTrigger={({ ref, props }) => (
              <TaskHoverableButton
                ref={ref}
                className="w-8"
                onClick={handleCopyTaskLink}
                {...props}
              >
                <CopyIcon />
              </TaskHoverableButton>
            )}
          >
            {t({
              id: "tasks-page.copy-task-link",
              defaultMessage: "Copy task link",
            })}
          </Tooltip>
          <TaskDetailsMoreMenu />
        </div>
      </div>
      <AddNewCustomerDialog
        isOpen={isAddNewCustomerDialogOpen}
        onClose={() => setIsAddNewCustomerDialogOpen(false)}
        onSuccess={(customer) => updateTask({ cust_id: customer.id })}
      />
    </>
  );
};
