import { DragOverlay, useDraggable } from "@dnd-kit/core";
import { Portal } from "@headlessui/react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { cx, useTranslations, useUniqueId } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import format from "date-fns/format";
import { FC, ReactNode, useMemo } from "react";
import Highlighter from "react-highlight-words";
import { TaskCardDraggableData } from "../../types";
import { shortenTaskTitle } from "../../utils";
import { ReactComponent as OrderIcon } from "./assets/order-icon.svg";
import { ReactComponent as RecurringIcon } from "./assets/recurring.svg";
import { TaskCardField, TaskCardProps } from "./TaskCard";
import { useTaskCalendarCardAttributes } from "./useTaskCalendarCardAttributes";

interface TaskCalendarCardPillProps {
  label?: ReactNode;
  hint?: ReactNode;
  startIcon?: ReactNode;
  className?: string;
}

const TaskCalendarCardPill: FC<TaskCalendarCardPillProps> = ({
  label,
  hint,
  startIcon,
  className,
}) => (
  <Tooltip
    placement="bottom"
    isDisabled={!hint}
    className="font-secondary px-3 text-sm leading-3"
    renderTrigger={({ ref, props }) => (
      <div
        ref={ref}
        className={cx(
          "flex h-[22px] w-max max-w-full items-center gap-1.5 truncate rounded-md bg-white/70 px-2",
          className
        )}
        {...props}
      >
        {startIcon && <div className="shrink-0 leading-[0]">{startIcon}</div>}
        {label && (
          <span className="text-dark-700 leading-2 truncate text-xs">
            {label}
          </span>
        )}
      </div>
    )}
  >
    {hint}
  </Tooltip>
);

export const TaskCalendarCard: FC<TaskCardProps> = (props) => {
  const {
    taskId,
    isUnread,
    title,
    dueDate,
    isOverdue,
    isCompleted,
    checklist,
    timeSpent,
    fields,
    highlightedText,
    className,
    style,
    draggable,
    recurrence,
    columnId,
    orderId,
    onClick,
    onTaskPreview,
    openFutureTaskDialog,
    task,
    ...divProps
  } = props;

  const uniqueId = useUniqueId();

  const { t } = useTranslations();

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `task-${taskId}-${uniqueId}`,
    disabled: !draggable,
    data: {
      type: "task",
      taskId,
      columnId,
      isFutureTask: !!recurrence?.isFutureTask,
    } satisfies TaskCardDraggableData,
  });

  const statusBasedAttributes = useTaskCalendarCardAttributes(props);

  const { dateLocale } = useLanguage();

  const shortenedTitle = shortenTaskTitle(title);

  const { boardField, labelField, customerField } = useMemo(() => {
    let internalBoardField: TaskCardField | undefined;
    let internalLabelField: TaskCardField | undefined;
    let internalCustomerField: TaskCardField | undefined;

    fields.forEach((field) => {
      if (field.id === "board") {
        internalBoardField = field;
      } else if (field.id === "label") {
        internalLabelField = field;
      } else if (field.id === "customer") {
        internalCustomerField = field;
      }
    });

    return {
      boardField: internalBoardField,
      labelField: internalLabelField,
      customerField: internalCustomerField,
    };
  }, [fields]);

  const shouldRenderFieldsSection =
    !!recurrence || !!boardField || !!labelField || !!customerField;

  const openTaskPreview = () => {
    if (task?._future_task && openFutureTaskDialog) {
      openFutureTaskDialog(task);
      return;
    }

    if (onTaskPreview) {
      onTaskPreview(taskId);
    }
  };

  return (
    <>
      {isDragging && (
        <Portal>
          <DragOverlay>
            <TaskCalendarCard
              {...props}
              draggable={false}
              className="border-primary-600 border-px rotate-2 border-dashed opacity-80"
            />
          </DragOverlay>
        </Portal>
      )}
      <InteractiveContainer
        ref={setNodeRef}
        className={cx(
          "relative flex touch-pan-y flex-col gap-2.5 overflow-hidden rounded-lg px-2 py-3 transition-colors",
          statusBasedAttributes.backgroundClassName,
          className
        )}
        onClick={openTaskPreview}
        {...listeners}
        {...attributes}
        {...divProps}
      >
        {isDragging && <div className="bg-dark-200 absolute inset-0 z-20" />}
        <div className="flex items-center gap-1">
          {statusBasedAttributes.icon}
          <span
            className={cx(
              "font-secondary text-[10px] leading-[15px]",
              statusBasedAttributes.textClassName
            )}
          >
            {statusBasedAttributes.statusLabel}
          </span>
        </div>
        <div className="flex flex-col">
          <div
            className={cx(
              "font-secondary break-words text-sm leading-[21px] tracking-[0.14px]",
              isCompleted ? "text-dark-700" : "text-black"
            )}
          >
            {dueDate && (
              <span
                className={cx(
                  "mr-2 font-semibold underline",
                  isCompleted ? "text-grey" : "text-dark-800"
                )}
              >
                {format(dueDate, "hh:mm a", { locale: dateLocale })}
              </span>
            )}
            <span onClick={onClick}>
              {highlightedText ? (
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary bg-primary-50"
                  highlightTag="span"
                  textToHighlight={shortenedTitle}
                  searchWords={[highlightedText]}
                />
              ) : (
                <span>{shortenedTitle}</span>
              )}
            </span>
          </div>
        </div>
        {!!orderId && (
          <div className="mt-0.5 flex items-center gap-1">
            <OrderIcon />
            <span className="text-xs tracking-[0.12px] text-[#828282]">
              {orderId}
            </span>
          </div>
        )}
        {shouldRenderFieldsSection && (
          <div className="mt-0.5 flex flex-wrap items-center gap-1">
            {recurrence && (
              <TaskCalendarCardPill
                hint={t({
                  id: "tasks-page.recurring-task",
                  defaultMessage: "Recurring task",
                })}
                className={cx(
                  "w-[22px] shrink-0 justify-center px-0",
                  recurrence.isPaused && "bg-gradients-danger"
                )}
                startIcon={
                  <RecurringIcon
                    className={cx(recurrence.isPaused && "[&>path]:fill-white")}
                  />
                }
              />
            )}
            {boardField && (
              <TaskCalendarCardPill
                startIcon={boardField.startIcon}
                label={boardField.value}
                hint={boardField.hint}
              />
            )}
            {labelField && (
              <TaskCalendarCardPill
                startIcon={
                  <div
                    className="h-1.5 w-1.5 rounded-full"
                    style={{ backgroundColor: labelField.textColor }}
                  />
                }
                label={labelField.value}
                hint={labelField.hint}
              />
            )}
            {customerField && (
              <TaskCalendarCardPill
                label={
                  <span className="flex items-center">
                    {customerField.value}
                  </span>
                }
                hint={customerField.hint}
              />
            )}
          </div>
        )}
      </InteractiveContainer>
    </>
  );
};
