import { DEFAULT_AUDIO_WAVEFORM_LENGTH } from "./consts";

export const normalizeAudioAmps = (
  waveform: number[],
  targetLength = DEFAULT_AUDIO_WAVEFORM_LENGTH
): number[] => {
  const currentLength = waveform.length;

  if (currentLength === targetLength) {
    return waveform;
  }

  // Create an array of the target indices
  const targetIndices: number[] = Array.from(
    { length: targetLength },
    (_, i) => (i * (currentLength - 1)) / (targetLength - 1)
  );

  // Interpolate to find the new waveform values at the target indices
  const normalizedWaveform: number[] = targetIndices.map((index) => {
    const lowerIndex = Math.floor(index);
    const upperIndex = Math.ceil(index);
    const weight = index - lowerIndex;

    if (upperIndex >= currentLength) {
      return waveform[lowerIndex];
    }

    return waveform[lowerIndex] * (1 - weight) + waveform[upperIndex] * weight;
  });

  return normalizedWaveform;
};
