import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import { FC, useMemo } from "react";
import { AssigneeFilterSection } from "./components/AssigneeFilterSection";
import { BoardFilterSection } from "./components/BoardFilterSection";
import { CustomFieldFilterSection } from "./components/CustomFieldFilterSection";
import { CustomerFilterSection } from "./components/CustomerFilterSection";
import { LabelFilterSection } from "./components/LabelFilterSection";
import { PriorityFilterSection } from "./components/PriorityFilterSection";
import { StatusFilterSection } from "./components/StatusFilterSection";
import { TypeFilterSection } from "./components/TypeFilterSection";
import { FilterSectionProps } from "./types";

export const DEFAULT_FILTER_SECTIONS_VISIBILITY = {
  byType: true,
  byBoard: true,
  byLabel: true,
  byPriority: true,
  byStatus: true,
  byAssignee: true,
  byCustomer: true,
  byCustomField: true,
};

type FilterSectionVisibility = typeof DEFAULT_FILTER_SECTIONS_VISIBILITY;

const filterSectionComponentByVisibility: Record<
  Exclude<keyof FilterSectionVisibility, "byCustomField">,
  FC<FilterSectionProps>
> = {
  byType: TypeFilterSection,
  byBoard: BoardFilterSection,
  byLabel: LabelFilterSection,
  byPriority: PriorityFilterSection,
  byStatus: StatusFilterSection,
  byAssignee: AssigneeFilterSection,
  byCustomer: CustomerFilterSection,
};

interface TaskFilterSectionsProps {
  entityId: string;
  source: TasksSource;
  customVisibility?: Partial<FilterSectionVisibility>;
  isReadonly?: boolean;
}

export const TaskFilterSections: FC<TaskFilterSectionsProps> = ({
  entityId,
  source,
  customVisibility,
  isReadonly = false,
}) => {
  const visibility = customVisibility || DEFAULT_FILTER_SECTIONS_VISIBILITY;

  const { customFields } = useTaskFields({ entityId });

  const dropdownCustomFields = useMemo(
    () => customFields.filter((customField) => customField.type === "dropdown"),
    [customFields]
  );

  const visibleFilterSections = useMemo(
    () =>
      Object.entries(visibility).reduce<FC<FilterSectionProps>[]>(
        (components, entry) => {
          const key = entry[0] as keyof FilterSectionVisibility;
          const isVisible = entry[1];

          if (key === "byCustomField") {
            return components;
          }

          if (isVisible) {
            components.push(filterSectionComponentByVisibility[key]);
          }

          return components;
        },
        []
      ),
    [visibility]
  );

  return (
    <div className="flex flex-col">
      {visibleFilterSections.map((FilterSectionComponent) => (
        <FilterSectionComponent
          key={FilterSectionComponent.name}
          entityId={entityId}
          source={source}
          isReadonly={isReadonly}
        />
      ))}
      {visibility.byCustomField && (
        <>
          {dropdownCustomFields.map((customField) => (
            <CustomFieldFilterSection
              key={customField.id}
              customField={customField}
              entityId={entityId}
              source={source}
              isReadonly={isReadonly}
            />
          ))}
        </>
      )}
    </div>
  );
};
