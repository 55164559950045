import { Alert, CountryPicker } from "@jugl-web/ui-components";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useTranslations, cx, useToast } from "@jugl-web/utils";
import { useForm, Controller } from "react-hook-form";
import { Country } from "@jugl-web/ui-components/cross-platform/PhoneInput/types";
import { useEffect, useState, useRef } from "react";
import countries from "countries-phone-masks";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { WarehouseItemDataDto } from "@jugl-web/rest-api/inventory/models/WarehouseItem";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import { validateEmail } from "@web-src/utils/helper";
import { ReactComponent as WarehouseIcon } from "./assets/warehouse.svg";
import { ReactComponent as ChevronIcon } from "./assets/chevron-down.svg";
import {
  EMAIL_MAX_LENGTH,
  PHONE_INPUT_MAX_LENGTH,
  WAREHOUSE_NAME_MAX_LENGTH,
} from "../../conts";

export const AddWarehouseDialog = ({
  isOpen,
  warehouse,
  onClose,
  onSubmitSuccess,
}: {
  isOpen: boolean;
  warehouse?: WarehouseItemDataDto;
  onClose: () => void;
  onSubmitSuccess: (data: WarehouseItemDataDto, isEdit: boolean) => void;
}) => {
  const { t } = useTranslations();
  const { entityId, entity } = useEntitySelectedProvider();
  const {
    handleSubmit,
    control,
    watch,
    reset: resetFormState,
    register,
    formState: { errors, isSubmitting },
  } = useForm<{
    warehouseName: string;
    state: string;
    country: Country | null;
    city: string;
    zipCode: string;
    address: string;
    phoneInput: string;
    email: string;
  }>({
    defaultValues: {
      warehouseName: undefined,
      state: undefined,
      zipCode: undefined,
      city: undefined,
      country: undefined,
      address: undefined,
      phoneInput: undefined,
      email: undefined,
    },
  });

  const inProgress = useRef(false);
  const [isDiscardAlertOpen, setIsDiscardAlertOpen] = useState(false);
  const formParams = watch();
  const isEdit = warehouse !== undefined;
  const { inventoryApi } = useRestApiProvider();
  const [createWarehouse, { isLoading: isCreatingWarehouse }] =
    inventoryApi.useAddWarehouseMutation();
  const [updateWarehouse, { isLoading: isUpdatingWarehouse }] =
    inventoryApi.useUpdateWarehouseMutation();

  const handleSubmitForm = handleSubmit(async (data) => {
    if (inProgress.current) return;
    inProgress.current = true;
    const response = isEdit
      ? await updateWarehouse({
          entityId,
          warehouseId: warehouse?.id,
          params: {
            name: data.warehouseName,
            address: {
              city: data.city,
              country: data.country?.iso || "IN",
              state: data.state,
              zipcode: data.zipCode,
              address: data.address,
            },
            mobile: data.phoneInput,
            email: data.email,
          },
        })
      : await createWarehouse({
          entityId,
          params: {
            name: data.warehouseName,
            address: {
              city: data.city,
              country: data.country?.iso || "IN",
              state: data.state,
              zipcode: data.zipCode,
              address: data.address,
            },
            mobile: data.phoneInput,
            email: data.email,
          },
        });
    if (!("error" in response)) {
      toast(
        isEdit
          ? t({
              id: "feedback.warehouse-info-updated",
              defaultMessage: "Warehouse info was updated",
            })
          : t({
              id: "feedback.new-warehouse-created",
              defaultMessage: "New Warehouse has been created",
            })
      );
      onSubmitSuccess(response.data, isEdit);
      onClose();
    }
    if ("error" in response) {
      inProgress.current = false;
    }
  });

  const { toast } = useToast({
    variant: "web",
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (isOpen) {
      inProgress.current = false;
    }

    if (warehouse) {
      resetFormState({
        warehouseName: warehouse.name,
        state: warehouse.address.state,
        zipCode: warehouse.address.zipcode,
        city: warehouse.address.city,
        country: {
          name: countries.find(
            (c: Country) => c.iso === warehouse.address.country
          )?.name,
          iso: warehouse.address.country,
        },
        address: warehouse.address.address,
        phoneInput: warehouse.mobile,
        email: warehouse.email,
      });
    } else {
      const entityCountry = countries.find(
        (country) => country.iso === entity.country
      );

      resetFormState({
        warehouseName: undefined,
        state: undefined,
        zipCode: undefined,
        city: undefined,
        country: {
          name: entityCountry?.name,
          iso: entityCountry?.iso,
        },
        address: undefined,
        phoneInput: undefined,
        email: undefined,
      });
    }
  }, [entity.country, isOpen, resetFormState, warehouse]);

  return (
    <>
      <Alert
        className="z-50 w-[660px]"
        isCloseButtonVisible
        onRequestClose={() => setIsDiscardAlertOpen(true)}
        buttonsContainerClassName="flex justify-center [&>button]:flex-none"
        contentContainerClassName="px-[56px] py-[32px]"
        content={
          <div className={cx("flex w-full flex-col gap-6 text-left")}>
            <TextInput
              {...register("warehouseName", {
                maxLength: WAREHOUSE_NAME_MAX_LENGTH,
                required: true,
                setValueAs: (value) => value?.trim(),
              })}
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              errorMessage={
                (formParams.warehouseName?.length >
                  WAREHOUSE_NAME_MAX_LENGTH - 11 &&
                  `${formParams.warehouseName.length}/${WAREHOUSE_NAME_MAX_LENGTH}`) ||
                errors.warehouseName?.message
              }
              isRequired
              label={t({
                id: "inventory-warehouses-page.warehouse-name",
                defaultMessage: "Warehouse Name",
              })}
              isInvalid={!!errors.warehouseName}
            />

            <div className="flex gap-6">
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <CountryPicker
                    onSelect={(item) => field.onChange(item)}
                    placement="bottom"
                    countryIso={field.value?.iso}
                    className="z-[100]"
                  >
                    {(isCountryPickerOpen) => (
                      <div className="relative">
                        <TextInput
                          value={formParams.country?.name}
                          isRequired
                          label={t({
                            id: "common.country",
                            defaultMessage: "Country",
                          })}
                          classNames={{
                            inputWrapperClassName: "w-[262px]",
                            inputClassName: "w-10/12 truncate cursor-pointer",
                            endContentWrapperClassName: "bg-white",
                          }}
                          endContent={
                            <ChevronIcon
                              className={cx(
                                "absolute right-5 top-[52px] transform transition-transform",
                                isCountryPickerOpen && "rotate-180"
                              )}
                            />
                          }
                        />
                      </div>
                    )}
                  </CountryPicker>
                )}
              />
              <TextInput
                {...register("state", {
                  setValueAs: (value) => value?.trim(),
                })}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                classNames={{
                  inputWrapperClassName: "w-[262px]",
                }}
                label={t({
                  id: "inventory-warehouses-page.state",
                  defaultMessage: "State",
                })}
              />
            </div>
            <div className="flex gap-6">
              <TextInput
                {...register("city", {
                  setValueAs: (value) => value?.trim(),
                })}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                classNames={{
                  inputWrapperClassName: "w-[262px]",
                }}
                label={t({
                  id: "inventory-warehouses-page.city",
                  defaultMessage: "City",
                })}
              />
              <TextInput
                {...register("zipCode", {
                  setValueAs: (value) => value?.trim(),
                })}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                classNames={{
                  inputWrapperClassName: "w-[262px]",
                }}
                label={t({
                  id: "inventory-warehouses-page.zip-code",
                  defaultMessage: "ZIP Code",
                })}
              />
            </div>
            <label
              className="font-secondary text-dark-800 text-left text-[15px]"
              htmlFor="address"
            >
              <TextAreaInput
                label={t({
                  id: "inventory-warehouses-page.address",
                  defaultMessage: "Address",
                })}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                {...register("address", {
                  setValueAs: (value) => value?.trim(),
                })}
              />
            </label>
            <div className="flex gap-6">
              <TextInput
                onKeyDown={(e) => {
                  const notAllowedCharacters = ["e", "E", "+", "-"];
                  if (notAllowedCharacters.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                type="number"
                {...register("phoneInput", {
                  maxLength: PHONE_INPUT_MAX_LENGTH,
                  setValueAs: (value) => value?.trim(),
                  validate: (value) => {
                    if (!value) return true;

                    const phoneRegex =
                      /^[+]?[(]?[0-9]+[)]?[-\s.]?[0-9]+[-\s.]?[0-9]+$/;

                    if (!phoneRegex.test(value)) {
                      return t({
                        id: "inventory-warehouses-page.invalid-phone-number",
                        defaultMessage: "Invalid phone number format",
                      });
                    }

                    return true;
                  },
                })}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                classNames={{
                  inputClassName:
                    "[-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none",
                  inputWrapperClassName: "w-[262px]",
                }}
                label={t({
                  id: "common.phone-number",
                  defaultMessage: "Phone Number",
                })}
                errorMessage={
                  (formParams.phoneInput?.length > PHONE_INPUT_MAX_LENGTH - 2 &&
                    `${formParams.phoneInput.length}/${PHONE_INPUT_MAX_LENGTH}`) ||
                  errors.phoneInput?.message
                }
                isInvalid={!!errors.phoneInput}
              />
              <TextInput
                {...register("email", {
                  maxLength: EMAIL_MAX_LENGTH,
                  setValueAs: (value) => value?.trim(),
                  validate: (value) => {
                    if (!value) return true;

                    if (!validateEmail(value)) {
                      return t({
                        id: "inventory-warehouses-page.invalid-email",
                        defaultMessage: "Invalid email",
                      });
                    }

                    return true;
                  },
                })}
                classNames={{
                  inputWrapperClassName: "w-[262px]",
                }}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                label={t({
                  id: "common.email",
                  defaultMessage: "Email",
                })}
                errorMessage={
                  (formParams.email?.length > EMAIL_MAX_LENGTH - 11 &&
                    `${formParams.email.length}/${EMAIL_MAX_LENGTH}`) ||
                  errors.email?.message
                }
                isInvalid={!!errors.email}
              />
            </div>
          </div>
        }
        header={
          <div className="flex items-center gap-3">
            <WarehouseIcon />
            {isEdit
              ? t({
                  id: "inventory-warehouses-page.edit-warehouse-info",
                  defaultMessage: "Edit Warehouse Info",
                })
              : t({
                  id: "inventory-warehouses-page.new-warehouse",
                  defaultMessage: "New Warehouse",
                })}
          </div>
        }
        isOpen={isOpen}
        buttons={[
          {
            className: cx("w-[300px]"),
            text: t({
              id: "common.save",
              defaultMessage: "Save",
            }),
            isDisabled:
              isCreatingWarehouse ||
              isUpdatingWarehouse ||
              isSubmitting ||
              inProgress.current,
            color: "primary",
            onClick: () => {
              handleSubmitForm();
            },
          },
        ]}
      />
      <Alert
        isOpen={isDiscardAlertOpen}
        title={t({
          id: "common.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "inventory-item-form-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: "Cancel",
            role: "close",
            onClick: () => {
              setIsDiscardAlertOpen(false);
            },
          },
          {
            text: "Discard",
            color: "tertiary",
            onClick: () => {
              setIsDiscardAlertOpen(false);
              onClose();
            },
          },
        ]}
        onRequestClose={() => setIsDiscardAlertOpen(false)}
      />
    </>
  );
};
