import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { SidebarDrawer } from "@jugl-web/ui-components/web/SidebarDrawer";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useForm, FieldValues, FieldError } from "react-hook-form";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { AvatarSelect } from "@jugl-web/ui-components/web";
import { useToast, useTranslations } from "@jugl-web/utils";
import { Customer } from "@jugl-web/rest-api/customer";
import { processNewCustomerData } from "@web-src/modules/customers/utils/processNewCustomerData";
import { phoneStringToInputValue } from "@jugl-web/ui-components";
import { ReactComponent as LogoPlaceholderIcon } from "./assets/logo-placeholder.svg";
import CustomerFormField, {
  OLD_PHONE_NUMBER_FORMAT_REGEXP,
} from "../CustomerFormField/CustomerFormField";
import { CustomizeFieldsDialog } from "../CustomizeFieldsDialog/CustomizeFieldsDialog";
import { sortFormFields } from "../../utils/sortFormFields";

export const ManageCustomerDialog: React.FC<{
  isOpen: boolean;
  customerId?: string;
  onClose: () => void;
  onSuccess?: (data: Customer, isEdit?: boolean) => void;
}> = ({ isOpen, customerId, onClose, onSuccess }) => {
  const { customersApi, customersFormFieldApi } = useRestApiProvider();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { entity } = useEntity();
  const { data: customer } = customersApi.useCustomerQuery(
    entity?.id && customerId ? { entityId: entity.id, customerId } : skipToken
  );
  const [addNewCustomer, { isLoading: isCreatingCustomer }] =
    customersApi.useAddCustomerMutation();
  const [updateCustomer, { isLoading: isUpdatingCustomer }] =
    customersApi.useUpdateCustomerMutation();
  const [logoFile, setLogoFile] = useState<File>();
  const [isCustomizeFieldsOpen, setCustomizeFieldsOpen] = useState(false);

  const { data: formFields } = customersFormFieldApi.useFormFieldsQuery(
    entity?.id ? { entityId: entity.id } : skipToken
  );

  const {
    reset,
    resetField,
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors, dirtyFields, submitCount },
  } = useForm({ mode: "onChange" });
  const formParams = watch();
  useEffect(() => {
    if (customerId) {
      const values: { [key: string]: string | object } = {};
      customer?.data.fields?.forEach((item) => {
        if (item.value) {
          values[item.field.id] = item.value;
        }
      });
      reset(values);
    } else {
      reset({});
      setLogoFile(undefined);
    }
  }, [customer, reset, isOpen, customerId]);

  const onSubmit = async (data: FieldValues) => {
    if (entity && data && formFields?.data) {
      const newCustomer = processNewCustomerData(formFields?.data, data);
      const response = await addNewCustomer({
        entityId: entity.id,
        fields: { fields: newCustomer },
        logoFile,
      });

      if (response && "data" in response) {
        toast(
          t({
            id: "feedback.customer-added",
            defaultMessage: "Customer has been added",
          })
        );
        onClose();
        reset({});
        if (onSuccess) {
          onSuccess(response.data.data);
        }
      }
    }
  };
  const onUpdate = async (data: FieldValues) => {
    const dirtyData = Object.fromEntries(
      Object.entries(data).filter(([key]) => dirtyFields[key])
    );

    const hasAnyDirtyField = Object.keys(dirtyData).length > 0;
    const hasLogoChanged = !!logoFile;

    if (!hasAnyDirtyField && !hasLogoChanged) {
      onClose();
      return;
    }

    if (entity && data && customerId && formFields?.data) {
      const newCustomer = processNewCustomerData(formFields.data, dirtyData);
      const response = await updateCustomer({
        customerId,
        entityId: entity.id,
        fields: { fields: newCustomer },
        logoFile,
      });

      if (response && "data" in response) {
        toast(
          t({
            id: "feedback.customer-updated",
            defaultMessage: "Customer has been updated",
          })
        );
        onSuccess?.(response.data.data, true);
        onClose();
        reset({});
      }
    }
  };
  const onLogoSave = (value?: File) => {
    if (value) {
      setLogoFile(value);
    }
  };

  const isFieldInvalid = useCallback(
    (fieldId: string) => fieldId in errors,
    [errors]
  );

  const mobilePersonalField = useMemo(
    () =>
      formFields?.data.find(
        (field) =>
          field.name === "phone_number" &&
          field.is_cust_field === false &&
          field.section === "personal"
      ),
    [formFields]
  );
  const emailPersonalField = useMemo(
    () =>
      formFields?.data.find(
        (field) =>
          field.name === "email" &&
          field.is_cust_field === false &&
          field.section === "personal"
      ),
    [formFields]
  );
  const mobilePersonalValue = useMemo(() => {
    const inputsValue =
      mobilePersonalField?.id && formParams[mobilePersonalField.id];
    if (OLD_PHONE_NUMBER_FORMAT_REGEXP.test(inputsValue)) {
      return phoneStringToInputValue(inputsValue).phone;
    }
    const [, , phoneNumber]: string[] = inputsValue?.split(",") || [];
    return phoneNumber;
  }, [formParams, mobilePersonalField?.id]);

  const managedFields = useMemo(() => {
    const emailPersonalValue =
      emailPersonalField?.id && formParams[emailPersonalField.id];
    const sortedFields = sortFormFields(formFields?.data || []);
    const fields = sortedFields.map((field) => {
      if (field.id === mobilePersonalField?.id) {
        return {
          ...field,
          required: !emailPersonalValue,
        };
      }
      if (field.id === emailPersonalField?.id) {
        return {
          ...field,
          required: !mobilePersonalValue,
        };
      }
      return field;
    });
    return fields;
  }, [
    emailPersonalField?.id,
    formFields?.data,
    formParams,
    mobilePersonalField?.id,
    mobilePersonalValue,
  ]);

  const shouldShowAddPhoneOrMail = useMemo(() => {
    const emailPersonalValue =
      emailPersonalField?.id && formParams[emailPersonalField.id];
    if (submitCount && !emailPersonalValue && !mobilePersonalValue) {
      return true;
    }
    return false;
  }, [emailPersonalField?.id, formParams, mobilePersonalValue, submitCount]);

  return (
    <SidebarDrawer
      isOpen={isOpen}
      title={
        customerId
          ? t({
              id: "customers-page.edit-customer",
              defaultMessage: "Edit Customer",
            })
          : t({
              id: "customers-page.add-new-customer",
              defaultMessage: "Add New Customer",
            })
      }
      onClose={() => {
        onClose();
        reset({});
      }}
    >
      <SidebarDrawer.Content className="relative">
        <div className="border-bottom border-grey-100 border-b-1 flex flex-col items-center gap-3 border-t-0 border-l-0 border-r-0 border-solid py-6">
          <div className="border-grey-200 rounded-full border-solid">
            <AvatarSelect
              value={logoFile}
              onSave={onLogoSave}
              defaultSrc={(customerId && customer?.data?.logo) || undefined}
              placeholder={<LogoPlaceholderIcon />}
            />
          </div>
          <Text variant="body3">
            {t({
              id: "customers-page.upload-logo",
              defaultMessage: "Upload Company/User Logo",
            })}
          </Text>
        </div>
        <div className="px-8 py-6">
          <div className="mb-3 flex flex-row items-center justify-between">
            <Text variant="body2" className="font-medium">
              {t({
                id: "customers-page.contact-info",
                defaultMessage: "Contact Info",
              })}
            </Text>
          </div>
          <div className="flex flex-col gap-6">
            {managedFields?.map((field) => (
              <>
                <CustomerFormField
                  key={field.id}
                  field={field}
                  section="personal"
                  register={register}
                  control={control}
                  value={formParams[field.id]}
                  invalid={isFieldInvalid(field.id)}
                  error={errors[field.id] as FieldError}
                  resetField={resetField}
                />
                {shouldShowAddPhoneOrMail &&
                  (field.id === mobilePersonalField?.id ||
                    field.id === emailPersonalField?.id) && (
                    <span className="text-gradients-danger text-xs">
                      {t({
                        id: "tasks-details-page.add-phone-or-mail-to-proceed",
                        defaultMessage: "Add phone number or email to proceed",
                      })}
                    </span>
                  )}
                {field.id === mobilePersonalField?.id &&
                  isFieldInvalid(field.id) && (
                    <span className="text-gradients-danger text-xs">
                      {t({
                        id: "feedback.invalid-phone-number",
                        defaultMessage: "Invalid phone number",
                      })}
                    </span>
                  )}
              </>
            ))}
          </div>
          <div className="mt-3 mb-3 flex flex-row items-center justify-between">
            <Text variant="body2" className="font-medium">
              {t({
                id: "customers-page.company-info",
                defaultMessage: "Company Info",
              })}
            </Text>
          </div>
          <div className="flex flex-col gap-6">
            {managedFields?.map((field) => (
              <CustomerFormField
                key={field.id}
                field={field}
                section="company"
                register={register}
                control={control}
                value={formParams[field.id]}
                invalid={isFieldInvalid(field.id)}
                error={errors[field.id] as FieldError}
                resetField={resetField}
              />
            ))}
          </div>
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex w-full flex-col gap-4">
        <Button
          className="uppercase"
          onClick={customerId ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
          isDisabled={isCreatingCustomer || isUpdatingCustomer}
        >
          {customerId
            ? t({
                id: "customers-page.update-customer",
                defaultMessage: "Update Customer",
              })
            : t({
                id: "customers-page.add-customer",
                defaultMessage: "Add Customer",
              })}
        </Button>
        <Button
          variant="contained"
          className="bg-grey-800/10 text-dark-800 uppercase hover:bg-slate-800/20"
          onClick={() => setCustomizeFieldsOpen(true)}
        >
          {t({
            id: "customers-page.customize-fields",
            defaultMessage: "Customize Fields",
          })}
        </Button>
      </SidebarDrawer.Actions>
      <CustomizeFieldsDialog
        isOpen={isCustomizeFieldsOpen}
        onGoBack={() => setCustomizeFieldsOpen(false)}
        onClose={() => {
          onClose();
          reset({});
          setCustomizeFieldsOpen(false);
        }}
      />
    </SidebarDrawer>
  );
};
