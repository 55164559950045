import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import {
  WorkspacesApiTag,
  useRestApiProvider,
  ChatMessagePayloadAction,
  ChatMessageType,
  ChatMessageModel,
} from "@jugl-web/rest-api";
import { useMe } from "@web-src/features/app/hooks/useMe";

export const useGroupInfoChatMessageHandler = () => {
  const dispatch = useDispatch();
  const { updateItem, conversations } = useConversations();
  const { entity } = useEntity();
  const { workspacesApi } = useRestApiProvider();
  const [loadWorkspace] = workspacesApi.useLazyGetWorkspaceQuery();
  const { me } = useMe();
  const handler = useCallback(
    async (message: ChatMessageModel, skipConfigUpdateFromMe?: boolean) => {
      if (
        message.type === ChatMessageType.muc &&
        (message.payload?.action === "updated" ||
          message.payload?.action === "role_updated") &&
        entity
      ) {
        if (message.from === me?.id && skipConfigUpdateFromMe) {
          return;
        }
        const response = await loadWorkspace({
          entityId: entity.id,
          workspaceId: message.to,
        });
        if (response?.data) {
          updateItem(
            {
              id: message.to,
              data: {
                title: response.data.title,
                img: response.data.display_picture,
                config: response.data.configs,
                role: response.data.role,
              },
            },
            true
          );
        }
      }
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action &&
        [
          ChatMessagePayloadAction.added,
          ChatMessagePayloadAction.removed,
          ChatMessagePayloadAction.left,
        ].includes(message.payload?.action)
      ) {
        const conversation = conversations.find(
          (item) => item.id === message.to
        );
        if (conversation) {
          const updatedUsers =
            message.payload.action === ChatMessagePayloadAction.added
              ? [
                  ...(message.payload.participants || []),
                  ...(conversation.data.users || []),
                ]
              : conversation.data.users?.filter(
                  (item) => item !== message.payload.action_for
                ) || [];
          updateItem(
            {
              id: message.to,
              data: {
                users: updatedUsers,
                userCount: updatedUsers.length,
              },
            },
            true
          );
        }

        dispatch(
          workspacesApi.util.invalidateTags([
            {
              type: WorkspacesApiTag.participants,
              id: message.to,
            },
          ])
        );
      }
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action === ChatMessagePayloadAction.deleted &&
        entity
      ) {
        updateItem(
          {
            id: message.to,
            data: {
              deleted: true,
            },
          },
          true
        );
      }
    },
    [
      entity,
      loadWorkspace,
      updateItem,
      dispatch,
      workspacesApi.util,
      conversations,
      me,
    ]
  );
  return handler;
};
