import { Placement } from "@floating-ui/react-dom";
import {
  Popover,
  ListBox,
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations, cx } from "@jugl-web/utils";
import { useMemo } from "react";
import { CountryPickerValue } from "./types";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries = require("countries-phone-masks");

export type CountryPickerProps = {
  children: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  countryIso?: string;
  placement?: Placement;
  isDisabled?: boolean;
  className?: string;
  onSelect: (country: CountryPickerValue) => void;
};

export const CountryPicker: React.FC<CountryPickerProps> = ({
  children,
  countryIso,
  placement = "bottom",
  isDisabled,
  className,
  onSelect,
}) => {
  const { t } = useTranslations();

  const preparedCountries = useMemo(() => {
    const newArray = [...countries];
    const indexes: number[] = [];

    newArray.forEach((item, index) => {
      if (item.iso === "IN" || item.iso === "US" || item.iso === "SV") {
        indexes.push(index);
      }
    });

    indexes.forEach((index) => {
      const item = newArray.splice(index, 1)[0];
      newArray.unshift(item);
    });

    return newArray.map((el) => ({
      id: el.iso,
      value: el,
    }));
  }, []);

  return (
    <Popover
      placement={placement}
      className={cx("p-6", className)}
      isDisabled={isDisabled}
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger ref={triggerRef} as={InteractiveContainer}>
          {typeof children === "function" ? children(isOpen) : children}
        </Trigger>
      )}
    >
      {({ onClose }) => (
        <div className="flex flex-col gap-4">
          <span className="flex items-center justify-between font-semibold text-[#383838]">
            <div>
              {t({
                id: "country-picker-component.select-country",
                defaultMessage: "Select country",
              })}
            </div>
            <PlainButton onClick={onClose}>
              <CloseIcon />
            </PlainButton>
          </span>
          <ListBox
            selectionBehavior={{ mode: "single", canToggle: false }}
            isHeightFixed
            className="w-[375px]"
            itemSize="sm"
            items={preparedCountries}
            renderLabel={(item) => `${item.value.name} (${item.value.iso})`}
            renderStartIcon={(item) => (
              <img
                src={item.value.flag}
                alt={item.value.iso}
                loading="lazy"
                className="h-[20px] w-[25px]"
              />
            )}
            maxVisibleItems={6}
            hasSearch
            onSelect={(item) => {
              onSelect(item.value);
              onClose();
            }}
            defaultSelectedIds={countryIso ? [countryIso] : undefined}
          />
        </div>
      )}
    </Popover>
  );
};
