import { useId, useMemo } from "react";
import { Menu } from "../../Menu";
import { FormGroup, FormGroupProps } from "../FormGroup";
import { SelectTrigger } from "../SelectTrigger";

export interface SelectItem {
  name: string;
  value: string;
  triggerClassName?: string;
}

export type SelectProps = FormGroupProps & {
  items: SelectItem[];
  value?: string;
  placeholder?: string;
  onSelect: (value: string) => void;
};

export const Select = ({
  items,
  value,
  placeholder,
  onSelect,
  ...formGroupProps
}: SelectProps) => {
  const id = useId();

  const menuSections = useMemo(() => {
    if (!items) return [];
    return [
      items.map((item) => ({
        id: item.value,
        label: item.name,
        isSelected: item.value === value,
        labelClassName: "text-base text-dark px-2 py-2.5 truncate",
        triggerClassName: item.triggerClassName,
        onSelect: () => onSelect(item.value),
      })),
    ];
  }, [items, value, onSelect]);

  const selectedValue = useMemo(
    () => items.find((item) => item.value === value)?.name,
    [items, value]
  );

  return (
    <FormGroup labelFor={id} {...formGroupProps}>
      <Menu
        placement="bottom-end"
        adjustToTriggerWidth
        sections={menuSections}
        autoClose
        renderTrigger={({ Trigger, triggerRef, isOpen }) => {
          const pageElement = document.querySelectorAll(
            '[data-component="page"]'
          );
          if (isOpen) {
            pageElement?.forEach((el) => {
              el.classList.add("overflow-y-hidden");
            });
          } else {
            pageElement?.forEach((el) => {
              el.classList.remove("overflow-y-hidden");
            });
          }
          return (
            <Trigger
              ref={triggerRef}
              as={SelectTrigger}
              label={selectedValue || placeholder}
              isOpen={isOpen}
              hasValue={!!value}
              isDisabled={formGroupProps.isDisabled}
              isInvalid={formGroupProps.isInvalid}
              className={formGroupProps.classNames?.wrapperClassName}
            />
          );
        }}
      />
    </FormGroup>
  );
};
