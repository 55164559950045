/* eslint-disable react/no-this-in-sfc */
import { produceSafeCustomerDetails } from "@jugl-web/domain-resources/customers/hooks/useCustomer";
import { useMultipleCustomers } from "@jugl-web/domain-resources/customers/hooks/useMultipleCustomers";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskPriorities } from "@jugl-web/domain-resources/tasks/hooks/useTaskPriorities";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { produceSafeProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import {
  WidgetChartType,
  WidgetModel,
} from "@jugl-web/rest-api/dashboard/models/Widget";
import { WidgetDataModel } from "@jugl-web/rest-api/dashboard/models/WidgetData";
import {
  ExpectedTaskCustomDropdownFieldValue,
  TaskPriority,
} from "@jugl-web/rest-api/tasks";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import { cx, useTranslations } from "@jugl-web/utils";
import { isValidDate } from "@jugl-web/utils/date-time/isValidDate";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { withLeadingZero } from "@jugl-web/utils/utils/withLeadingZero";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import parseISO from "date-fns/parseISO";
import * as Highcharts from "highcharts";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import { FC, useCallback, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useDashboardWidgetChartConfig } from "../../hooks/useDashboardWidgetChartConfig";
import { useUnassignedCategoryLabel } from "../../hooks/useUnassignedCategoryLabel";
import { DashboardWidgetEmptyState } from "../DashboardWidgetEmptyState";

import "highcharts/highcharts-more";
import "highcharts/modules/funnel";
import "highcharts/modules/heatmap";
import "highcharts/modules/treemap";

interface DashboardWidgetChartProps {
  widgetConfig: WidgetModel["config"];
  widgetData: WidgetDataModel;
  backgroundColor?: "white" | "grey";
  containerClassName?: string;
}

interface ZippedAxisValue {
  xLabel: string;
  yValue: number;
  rawXValue: string | null;
  rawYValue: number;
}

interface ZipAxisValuesOptions {
  xLabels: ZippedAxisValue["xLabel"][];
  yValues: ZippedAxisValue["yValue"][];
  rawXValues: ZippedAxisValue["rawXValue"][];
  rawYValues: ZippedAxisValue["rawYValue"][];
}

interface ZipAxisValuesFn {
  (options: ZipAxisValuesOptions): ZippedAxisValue[];
}

const formatMillisecondsToTimeString = (milliseconds: number) => {
  const { hours, minutes } = convertMillisecondsToTimeComponents(milliseconds);

  return `${withLeadingZero(hours)}:${withLeadingZero(minutes)}`;
};

const formatNumericValueToPercent = (value: number) =>
  `${value.toFixed(value > 0 && value < 1 ? 1 : 0)}%`;

const jsxToHtml = ReactDOMServer.renderToStaticMarkup;

export const DashboardWidgetChart: FC<DashboardWidgetChartProps> = ({
  widgetConfig,
  widgetData,
  backgroundColor,
  containerClassName,
}) => {
  const { entityId } = useEntitySelectedProvider();

  const assigneeOrCustomerIdsToFetch = useMemo(() => {
    if (
      widgetConfig.x_axis !== "assignee" &&
      widgetConfig.x_axis !== "customer"
    ) {
      return [];
    }

    return widgetData.x_values.filter(
      (value): value is string => value !== null
    );
  }, [widgetConfig.x_axis, widgetData.x_values]);

  const { profileByUserId, isLoading: areAssigneesLoading } =
    useMultipleUserProfiles({
      entityId,
      userIds: assigneeOrCustomerIdsToFetch,
      skip: widgetConfig.x_axis !== "assignee",
    });

  const { customerDetailsById, isLoading: areCustomersLoading } =
    useMultipleCustomers({
      entityId,
      customerIds: assigneeOrCustomerIdsToFetch,
      skipLoading: widgetConfig.x_axis !== "customer",
    });

  const { getLabelById, getCustomFieldById } = useTaskFields({
    entityId,
  });
  const { getPriorityDetailsById } = useTaskPriorities();
  const { getStatusById } = useTaskStatuses({ entityId });
  const { getBoardById, hasAccess: hasBoardAccess } = useTaskBoards({
    entityId,
  });

  const { getChartYAxisItemById, getChartColorPalette } =
    useDashboardWidgetChartConfig();

  const {
    unassignedCategoryLabelByXAxisType,
    getCustomFieldUnassignedCategoryLabel,
    getUnassignedCategoryLabelByXAxisType,
  } = useUnassignedCategoryLabel({ entityId });

  const chartRef = useRef<HighchartsReactRefObject | null>(null);

  const { localeAwareFormat } = useFormattedDate();
  const { t } = useTranslations();

  const noDueDateLabel = useMemo(
    () =>
      t({
        id: "dashboard-page.no-due-chart-label",
        defaultMessage: "No due date",
      }),
    [t]
  );

  const colorPalette = useMemo(
    () => getChartColorPalette(widgetConfig),
    [getChartColorPalette, widgetConfig]
  );

  const {
    x_axis: xAxisType,
    series,
    meta,
    show_empty: shouldShowEmptyValues,
    include_nodue: shouldIncludeNoDueTasks,
    include_overdue: shouldIncludeOverdueTasks,
  } = widgetConfig;
  const { type: chartType, y_axis: yAxisType } = series[0];
  const {
    y_axis_format: yAxisFormat,
    sorting,
    show_unassigned_categories: shouldShowUnassignedCategories,
  } = meta;

  const getXAxisLabels = useCallback(
    (xAxisValues: WidgetDataModel["x_values"]): string[] => {
      switch (xAxisType) {
        case "due_date":
          return xAxisValues.map((value) => {
            if (value === null) {
              return noDueDateLabel;
            }

            return value;
          });

        case "board":
          return xAxisValues.map((value) => {
            if (value === null) {
              return unassignedCategoryLabelByXAxisType.board;
            }

            const matchingBoard = getBoardById(value);

            if (!matchingBoard) {
              return t({
                id: "dashboard-page.unknown-board-chart-label",
                defaultMessage: "Unknown board",
              });
            }

            return matchingBoard.name;
          });

        case "status":
          return xAxisValues.map((value) => {
            if (value === null) {
              return t({
                id: "dashboard-page.unknown-status-chart-label",
                defaultMessage: "Unknown status",
              });
            }

            const matchingStatus = getStatusById(value);

            return matchingStatus.text;
          });

        case "label":
          return xAxisValues.map((value) => {
            if (value === null) {
              return unassignedCategoryLabelByXAxisType.label;
            }

            const matchingLabel = getLabelById(value);

            if (!matchingLabel) {
              return t({
                id: "dashboard-page.unknown-label-chart-label",
                defaultMessage: "Unknown label",
              });
            }

            return matchingLabel.text;
          });

        case "priority":
          return xAxisValues.map((value) => {
            if (value === null) {
              return t({
                id: "dashboard-page.unknown-priority-chart-label",
                defaultMessage: "Unknown priority",
              });
            }

            const matchingPriority = getPriorityDetailsById(
              value as TaskPriority
            );

            return matchingPriority.shortLabel;
          });

        case "assignee":
          return xAxisValues.map((value) => {
            if (value === null) {
              return unassignedCategoryLabelByXAxisType.assignee;
            }

            const matchingProfile = profileByUserId[value];

            if (!matchingProfile) {
              const safeProfile = produceSafeProfile({
                t,
                isLoading: areAssigneesLoading,
              });

              return safeProfile.displayName;
            }

            return matchingProfile.displayName;
          });

        case "customer":
          return xAxisValues.map((value) => {
            if (value === null) {
              return unassignedCategoryLabelByXAxisType.customer;
            }

            const matchingCustomer = customerDetailsById[value];

            if (!matchingCustomer) {
              const safeCustomerDetails = produceSafeCustomerDetails({
                t,
                isLoading: areCustomersLoading,
              });

              return safeCustomerDetails.fullName;
            }

            return matchingCustomer.fullName;
          });

        default: {
          const maybeCustomField = getCustomFieldById(xAxisType);

          if (!maybeCustomField || maybeCustomField.type !== "dropdown") {
            // As a fallback, return values as they are
            return xAxisValues.map(
              (value) =>
                value ||
                t({ id: "common.no-value", defaultMessage: "No value" })
            );
          }

          const customFieldValuesById = (
            maybeCustomField.values as ExpectedTaskCustomDropdownFieldValue[]
          ).reduce<Record<string, ExpectedTaskCustomDropdownFieldValue>>(
            (acc, value) => {
              acc[value.id] = value;
              return acc;
            },
            {}
          );

          return xAxisValues.map((value) => {
            if (value === null) {
              return getCustomFieldUnassignedCategoryLabel(
                maybeCustomField.name
              );
            }

            const matchingValue = customFieldValuesById[value];

            if (!matchingValue) {
              return t({
                id: "dashboard-page.unknown-custom-field-value-chart-label",
                defaultMessage: "Unknown value",
              });
            }

            return matchingValue.value;
          });
        }
      }
    },
    [
      areAssigneesLoading,
      areCustomersLoading,
      customerDetailsById,
      getBoardById,
      getCustomFieldById,
      getCustomFieldUnassignedCategoryLabel,
      getLabelById,
      getPriorityDetailsById,
      getStatusById,
      noDueDateLabel,
      profileByUserId,
      t,
      unassignedCategoryLabelByXAxisType,
      xAxisType,
    ]
  );

  const getYAxisValues = useCallback(
    (yAxisValues: WidgetDataModel["series_data"][number]): number[] => {
      switch (yAxisFormat) {
        case "value":
          return [...yAxisValues];
        case "percent": {
          const total = yAxisValues.reduce((acc, value) => acc + value, 0);
          return yAxisValues.map((value) => (value / total) * 100);
        }
        default:
          return [...yAxisValues];
      }
    },
    [yAxisFormat]
  );

  const zipAxisValues = useCallback<ZipAxisValuesFn>(
    ({ xLabels, yValues, rawXValues, rawYValues }) =>
      Array.from({ length: xLabels.length }, (_, index) => ({
        xLabel: xLabels[index],
        yValue: yValues[index],
        rawXValue: rawXValues[index],
        rawYValue: rawYValues[index],
      })),
    []
  );

  const unzipAxisValues = useCallback(
    (
      zippedAxisValues: ZippedAxisValue[]
    ): [xLabels: string[], yValues: number[]] => [
      zippedAxisValues.map(({ xLabel }) => xLabel),
      zippedAxisValues.map(({ yValue }) => yValue),
    ],
    []
  );

  const filterAxisValues = useCallback(
    (zippedAxisValues: ZippedAxisValue[]): ZippedAxisValue[] => {
      const unassignedCategoryLabel =
        getUnassignedCategoryLabelByXAxisType(xAxisType);

      const filteredValues = zippedAxisValues.filter(
        ({ xLabel, rawXValue }) => {
          let isIncluded = true;

          // Filter out unassigned categories (like none label, none priority, without board, etc.) if needed
          if (unassignedCategoryLabel && xLabel === unassignedCategoryLabel) {
            isIncluded = shouldShowUnassignedCategories;
          }

          // Filter out board IDs that the user doesn't have access to
          if (xAxisType === "board" && rawXValue !== null) {
            const boardId = rawXValue;
            isIncluded = hasBoardAccess(boardId);
          }

          return isIncluded;
        }
      );

      return filteredValues;
    },
    [
      getUnassignedCategoryLabelByXAxisType,
      hasBoardAccess,
      shouldShowUnassignedCategories,
      xAxisType,
    ]
  );

  const sortAxisValues = useCallback(
    (zippedAxisValues: ZippedAxisValue[]): ZippedAxisValue[] => {
      const categoryLabelWithSortPriority =
        xAxisType === "due_date"
          ? noDueDateLabel
          : getUnassignedCategoryLabelByXAxisType(xAxisType);

      const sortedValues = [...zippedAxisValues].sort(
        ({ xLabel: xA, yValue: yA }, { xLabel: xB, yValue: yB }) => {
          switch (sorting) {
            case "x-axis-asc":
              if (xA === categoryLabelWithSortPriority) {
                return -1;
              }

              if (xB === categoryLabelWithSortPriority) {
                return 1;
              }

              if (
                xAxisType === "due_date" &&
                isValidDate(xA) &&
                isValidDate(xB)
              ) {
                const timestampA = parseISO(xA).getTime();
                const timestampB = parseISO(xB).getTime();

                return timestampA - timestampB;
              }

              return xA.localeCompare(xB, undefined, {
                numeric: true,
              });

            case "x-axis-desc":
              if (xA === categoryLabelWithSortPriority) {
                return 1;
              }

              if (xB === categoryLabelWithSortPriority) {
                return -1;
              }

              if (
                xAxisType === "due_date" &&
                isValidDate(xA) &&
                isValidDate(xB)
              ) {
                const timestampA = parseISO(xA).getTime();
                const timestampB = parseISO(xB).getTime();

                return timestampB - timestampA;
              }

              return xB.localeCompare(xA, undefined, {
                numeric: true,
              });

            case "y-axis-asc":
              return yA - yB;

            case "y-axis-desc":
              return yB - yA;

            default:
              return 0;
          }
        }
      );

      return sortedValues;
    },
    [getUnassignedCategoryLabelByXAxisType, noDueDateLabel, sorting, xAxisType]
  );

  const formatXAxisLabel = useCallback(
    (value: string | number) => {
      if (typeof value === "number") {
        return value.toString();
      }

      if (xAxisType === "due_date" && isValidDate(value)) {
        const date = parseISO(value);
        return localeAwareFormat(date, "d MMM yy");
      }

      return value;
    },
    [localeAwareFormat, xAxisType]
  );

  const formatYAxisValue = useCallback(
    (value: string | number | undefined | null) => {
      if (typeof value !== "number") {
        return value;
      }

      if (yAxisFormat === "percent") {
        return formatNumericValueToPercent(value);
      }

      if (yAxisType === "sum_duration") {
        return formatMillisecondsToTimeString(value);
      }

      return value;
    },
    [yAxisFormat, yAxisType]
  );

  const transformedWidgetData = useMemo(() => {
    const rawXValues = widgetData.x_values;
    const rawYValues = widgetData.series_data[0];

    const zippedAxisValues = zipAxisValues({
      xLabels: getXAxisLabels(rawXValues),
      yValues: getYAxisValues(rawYValues),
      rawXValues,
      rawYValues,
    });

    const filteredAxisValues = filterAxisValues(zippedAxisValues);
    const sortedAxisValues = sortAxisValues(filteredAxisValues);

    return unzipAxisValues(sortedAxisValues);
  }, [
    filterAxisValues,
    getXAxisLabels,
    getYAxisValues,
    sortAxisValues,
    unzipAxisValues,
    widgetData.series_data,
    widgetData.x_values,
    zipAxisValues,
  ]);

  const chartOptions = useMemo<Highcharts.Options>(() => {
    const [xLabels, yValues] = transformedWidgetData;
    const yAxisName = getChartYAxisItemById(yAxisType)?.name;

    const commonOptions: Highcharts.Options = {
      chart: {
        backgroundColor: backgroundColor === "grey" ? "#F9F9F9" : "#FFFFFF",
        spacing: [40, 24, 16, 16],
      },
      colors: colorPalette,
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: xLabels,
        labels: {
          formatter() {
            return jsxToHtml(
              <span className="font-secondary text-xs text-black">
                {formatXAxisLabel(this.value)}
              </span>
            );
          },
        },
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: jsxToHtml(
            <span className="font-secondary text-[13px] text-black">
              {yAxisName}
            </span>
          ),
        },
        labels: {
          formatter() {
            return jsxToHtml(
              <span className="font-secondary text-[10px] text-black">
                {formatYAxisValue(this.value)}
              </span>
            );
          },
        },
      },
      tooltip: {
        followPointer: false,
        followTouchMove: false,
        formatter() {
          return jsxToHtml(
            <>
              <span className="font-medium">
                {formatXAxisLabel(this.name || this.category)}
              </span>
              <br />
              <span className="mr-1.5">{yAxisName}: </span>
              <span className="font-medium">
                {formatYAxisValue(this.y ?? this.value)}
              </span>
            </>
          );
        },
        backgroundColor: "#1A2023CC",
        borderRadius: 8,
        shadow: false,
        padding: 12,
        style: {
          fontFamily: "Poppins",
          fontSize: "14px",
          color: "#FFFFFF",
        },
      },
    };

    switch (chartType) {
      case "line":
        return {
          ...commonOptions,
          series: [
            {
              type: "line",
              name: yAxisName,
              data: yValues,
              lineWidth: 2,
            },
          ],
        };

      case "area":
        return {
          ...commonOptions,
          series: [
            {
              type: "area",
              name: yAxisName,
              data: yValues,
              lineWidth: 2,
            },
          ],
        };

      case "column":
        return {
          ...commonOptions,
          plotOptions: {
            column: { colorByPoint: true },
          },
          series: [
            {
              type: "column",
              name: yAxisName,
              data: yValues,
            },
          ],
        };

      case "bar":
        return {
          ...commonOptions,
          plotOptions: {
            bar: { colorByPoint: true },
          },
          series: [
            {
              type: "bar",
              name: yAxisName,
              data: yValues,
            },
          ],
        };

      case "pie":
      case "donut":
        return {
          ...commonOptions,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                formatter() {
                  return jsxToHtml(
                    <span className="font-secondary text-xs">
                      <span className="mr-[5px] text-black">{this.name}: </span>
                      <span className="text-dark-700">
                        {formatYAxisValue(this.y)}
                      </span>
                    </span>
                  );
                },
              },
              innerSize: chartType === "donut" ? "50%" : undefined,
            },
          },
          series: [
            {
              type: "pie",
              name: yAxisName,
              data: yValues.map((yValue, index) => ({
                name: xLabels[index],
                y: yValue,
              })),
              colorByPoint: true,
            },
          ],
        };

      case "pyramid":
      case "funnel":
        return {
          ...commonOptions,
          plotOptions: {
            pyramid: {
              center: ["50%", "50%"],
              width: "70%",
            },
            funnel: {
              center: ["50%", "50%"],
              width: "70%",
            },
          },
          series: [
            {
              type: chartType,
              name: yAxisName,
              data: yValues.map((yValue, index) => ({
                name: xLabels[index],
                y: yValue,
              })),
            },
          ],
        };

      case "waterfall":
        return {
          ...commonOptions,
          xAxis: {
            ...commonOptions.xAxis,
            categories: undefined,
            type: "category",
          },
          series: [
            {
              type: "waterfall",
              name: yAxisName,
              data: [
                ...yValues.map((yValue, index) => ({
                  name: xLabels[index],
                  y: yValue,
                })),
                {
                  name: t({ id: "common.total", defaultMessage: "Total" }),
                  isSum: true,
                },
              ],
              colorByPoint: true,
              dataLabels: {
                enabled: true,
                formatter() {
                  return formatYAxisValue(this.y);
                },
              },
            },
          ],
        };

      case "radar":
        return {
          ...commonOptions,
          chart: {
            ...commonOptions.chart,
            polar: true,
            type: "line",
          },
          xAxis: {
            ...commonOptions.xAxis,
            tickmarkPlacement: "on",
            lineWidth: 0,
          },
          yAxis: {
            ...commonOptions.yAxis,
            title: undefined,
            gridLineInterpolation: "polygon",
            lineWidth: 0,
            min: 0,
          },
          pane: {
            size: "80%",
          },
          series: [
            {
              type: "area",
              pointPlacement: "on",
              name: yAxisName,
              data: yValues,
              lineWidth: 2,
            },
          ],
        };

      case "treemap":
        return {
          ...commonOptions,
          colorAxis: {
            minColor: "#FFFFFF",
            maxColor: colorPalette[0],
          },
          series: [
            {
              type: "treemap",
              layoutAlgorithm: "squarified",
              clip: false,
              data: yValues.map((yValue, index) => ({
                name: xLabels[index],
                value: yValue,
                colorValue: yValue,
              })),
            },
          ],
        };

      default:
        return commonOptions;
    }
  }, [
    backgroundColor,
    chartType,
    colorPalette,
    formatXAxisLabel,
    formatYAxisValue,
    getChartYAxisItemById,
    t,
    transformedWidgetData,
    yAxisType,
  ]);

  const hasNoData = useMemo(() => {
    const [xLabels, yValues] = transformedWidgetData;

    const hasNoXLabel = xLabels.length === 0;

    if (hasNoXLabel) {
      return true;
    }

    const hasPositiveYValue = yValues.some((value) => value > 0);

    if (hasPositiveYValue) {
      return false;
    }

    const chartTypesWithoutNoValueSupport: WidgetChartType[] = [
      "pyramid",
      "funnel",
      "treemap",
    ];

    return chartTypesWithoutNoValueSupport.includes(chartType);
  }, [chartType, transformedWidgetData]);

  const fingerprint = `${xAxisType}-${yAxisType}-${chartType}-${yAxisFormat}-${sorting}-${shouldShowEmptyValues}-${shouldIncludeNoDueTasks}-${shouldIncludeOverdueTasks}-${shouldShowUnassignedCategories}`;

  if (hasNoData) {
    return <DashboardWidgetEmptyState />;
  }

  return (
    <HighchartsReact
      key={fingerprint}
      containerProps={{ className: cx("h-full w-full", containerClassName) }}
      ref={chartRef}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};
