import { UserRole } from "@jugl-web/rest-api";
import { appModulesToRequiredUserRoles } from "../../common/consts";
import { AppModule } from "../../common/types";

export const hasRoleAccessToModule = (
  userRole: UserRole,
  module: AppModule
) => {
  const requiredRoles = appModulesToRequiredUserRoles[module];
  return requiredRoles.includes(userRole);
};
