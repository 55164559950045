import {
  AvatarMember,
  AvatarStack,
  AvatarStackProps,
} from "@jugl-web/ui-components";
import { FC, useMemo } from "react";
import { useMultipleUserProfiles } from "../../hooks/useMultipleUserProfiles";

interface UsersAvatarStackProps extends Omit<AvatarStackProps, "members"> {
  entityId: string;
  userIds: string[];
}

export const UsersAvatarStack: FC<UsersAvatarStackProps> = ({
  entityId,
  userIds,
  ...avatarStackProps
}) => {
  const { profiles } = useMultipleUserProfiles({ entityId, userIds });

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      (profiles || []).map((profile) => ({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        imageUrl: profile.avatar || "",
      })),
    [profiles]
  );

  return (
    <AvatarStack members={profilesAsAvatarMembers} {...avatarStackProps} />
  );
};
