import { LiveUpdateEvent } from "@jugl-web/domain-resources/live-updates";
import {
  TaskCommentInput,
  TaskCommentsProvider,
  useTaskCommentsProvider,
} from "@jugl-web/domain-resources/tasks-comments";
import { useManageCommentsListener } from "@jugl-web/domain-resources/tasks-comments/hooks/useManageCommentsListener";
import {
  isTaskClientFeedbackCreatedEvent,
  isTaskClientFeedbackDeletedEvent,
  isTaskClientFeedbackUpdatedEvent,
  TaskClientFeedbackLiveUpdateEvent,
  useTaskLiveUpdatesContext,
} from "@jugl-web/domain-resources/tasks/components/TaskLiveUpdatesProvider";
import { useTaskActivities } from "@jugl-web/domain-resources/tasks/hooks/useTaskActivities";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import {
  LoadingSpinner,
  Menu,
  parseActivityType,
  PlainButton,
  TaskActivityItem,
} from "@jugl-web/ui-components";
import {
  cx,
  isoToLocalDate,
  PaginationComponent,
  PaginationComponentHandle,
  useTranslations,
} from "@jugl-web/utils";
import { tasksApi, tasksCommentsApi } from "@web-src/features/api/createApi";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useTaskUnreadIndicator } from "@jugl-web/domain-resources/tasks/hooks/useTaskUnreadIndicator";
import { MY_TASKS_SOURCE } from "@jugl-web/domain-resources/tasks";
import { useTaskDetailsContext } from "@web-src/pages/TaskDetails/hooks/useTaskDetailsContext";
import { ReactComponent as CommentMenuIcon } from "../../../TaskDetails/assets/comment-menu.svg";

interface TaskDetailsCommentsSidebarProps {
  onCollapse: () => void;
}

const InnerTaskDetailsCommentsSidebar: FC<TaskDetailsCommentsSidebarProps> = ({
  onCollapse,
}) => {
  const { taskId, task } = useTaskDetailsContext();
  const { me, meId } = useLoadedMe();
  const { entityId } = useEntitySelectedProvider();
  const { readTask } = useTaskUnreadIndicator({
    entityId,
    source: MY_TASKS_SOURCE,
  });

  const {
    activities,
    isLoading,
    isInitialLoading,
    loadMore,
    addActivity,
    modifyActivity,
    deleteActivity,
  } = useTaskActivities({
    meUser: me,
    entityId,
    taskId: task?.id,
    initialFilters: ["guest"],
    sorting: "asc",
  });

  const { manageComments$, setCommentAction } = useTaskCommentsProvider();
  const { taskClientFeedbackEvents$ } = useTaskLiveUpdatesContext();
  const permissions = useTaskPermissions({ task, entityId, meId });

  const paginationRef = useRef<PaginationComponentHandle | null>(null);

  const { t } = useTranslations();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<ThunkDispatch<any, unknown, AnyAction>>();

  useManageCommentsListener({
    taskId,
    entityId,
    tasksCommentsApi,
    manageComments$,
  });

  const isLiveUpdateEventForCurrentTask = useCallback(
    <TEvent extends LiveUpdateEvent<string, { task_id: string }>>(
      event: TEvent
    ) => event.data.task_id === taskId,
    [taskId]
  );

  const handleTaskClientFeedbackLiveUpdateEvent = useCallback(
    (event: TaskClientFeedbackLiveUpdateEvent) => {
      if (
        isTaskClientFeedbackCreatedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        addActivity(event.data);
        readTask(event.data.task_id, "feedback");
        return;
      }

      if (
        isTaskClientFeedbackUpdatedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        modifyActivity(event.data);
        return;
      }

      if (
        isTaskClientFeedbackDeletedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        deleteActivity(event.data.id);
      }
    },
    [
      readTask,
      addActivity,
      deleteActivity,
      isLiveUpdateEventForCurrentTask,
      modifyActivity,
    ]
  );

  useEffect(() => {
    const clientFeedbackSubscription = taskClientFeedbackEvents$.subscribe(
      handleTaskClientFeedbackLiveUpdateEvent
    );

    return () => {
      clientFeedbackSubscription.unsubscribe();
    };
  }, [handleTaskClientFeedbackLiveUpdateEvent, taskClientFeedbackEvents$]);

  // Scroll to the bottom when new comment is added
  useEffect(() => {
    const virtuoso = paginationRef.current?.getVirtuosoRef();

    if (!virtuoso) {
      return;
    }

    virtuoso.scrollToIndex({
      index: activities.length - 1,
      align: "end",
    });
  }, [activities.length]);

  const isEmpty = activities.length === 0;
  const hasAssociatedCustomer = !!task?.cust_id;

  useEffect(() => {
    if (task && task.feedback_unread) {
      dispatch(
        tasksApi.util.updateQueryData(
          "getTask",
          { entityId, taskId },
          (taskDraft) => {
            taskDraft.feedback_unread = false;
          }
        )
      );
      readTask(task.id, "feedback");
    }
  }, [readTask, task, dispatch, entityId, taskId]);

  return (
    <div className="relative flex h-full flex-col items-stretch rounded-tl-3xl bg-white transition-all">
      <PlainButton
        className="hover:bg-grey-100 absolute top-3 left-[18px] flex h-8 w-8 items-center justify-center rounded transition"
        onClick={onCollapse}
      />
      <div className="animate-fade-in flex-1">
        {isInitialLoading ? (
          <LoadingSpinner className="mt-6" />
        ) : isEmpty ? (
          <div className="p-[80px] text-center">
            <span className="font-secondary text-sm leading-[22px] text-[#828282]">
              {hasAssociatedCustomer
                ? t({
                    id: "tasks-page.no-feedback",
                    defaultMessage: "No feedback",
                  })
                : t(
                    {
                      id: "tasks-page.empty-customer-feedback-column-message",
                      defaultMessage:
                        "<b>Associate and share</b> the task with the customer to receive feedback",
                    },
                    {
                      b: (mention: (string | JSX.Element)[]) => (
                        <span className="text-primary-800">{mention}</span>
                      ),
                    }
                  )}
            </span>
          </div>
        ) : (
          <PaginationComponent
            ref={paginationRef}
            items={activities}
            isLoading={isLoading}
            endReached={loadMore}
            reverse
            extraVirtuosoParams={{
              alignToBottom: false,
              className: "jugl__custom-scrollbar",
            }}
            renderer={(index, item) => {
              const { data: parsedActivity } = item;

              const isComment =
                item.data.raw.action_details.action === "commented" ||
                item.data.raw.action_details.action === "guest_comment_reply";

              const canManageComment =
                isComment &&
                permissions.canEditOrDeleteComment(
                  item.data.raw.action_by.user_id
                );

              const isFirst = index === 0;
              const isLast = activities.length - 1 === index;

              return (
                <div
                  className={cx(
                    isFirst && "mt-3",
                    !isLast &&
                      "border-grey-200 border-t-0 border-b border-r-0 border-l-0 border-solid"
                  )}
                >
                  <TaskActivityItem
                    variant="web"
                    action={parsedActivity.raw.action_details.action}
                    type={parseActivityType(
                      parsedActivity.raw.action_details.action
                    )}
                    isExternalClient={parsedActivity.user.isExternalClient}
                    username={parsedActivity.user.username}
                    userImageUrl={parsedActivity.user.avatarUrl}
                    message={parsedActivity.message}
                    date={isoToLocalDate(parsedActivity.raw.inserted_at)}
                    ratingRate={parsedActivity.rating?.rate}
                    ratingTags={parsedActivity.rating?.tags}
                    contextMenuSlot={
                      canManageComment && (
                        <Menu
                          placement="bottom-end"
                          hasBackdrop
                          backdropStyle="transparent"
                          className="min-w-[150px]"
                          sections={[
                            [
                              {
                                id: "edit",
                                label: t({
                                  id: "common.edit",
                                  defaultMessage: "Edit",
                                }),
                                onSelect: (_, close) => {
                                  setCommentAction({
                                    type: "edit",
                                    comment: item.data.raw,
                                  });
                                  close();
                                },
                              },
                              {
                                id: "delete",
                                label: t({
                                  id: "common.delete",
                                  defaultMessage: "Delete",
                                }),
                                onSelect: (_, close) => {
                                  setCommentAction({
                                    type: "delete",
                                    comment: item.data.raw,
                                  });
                                  manageComments$.next({
                                    action: "delete",
                                    taskId,
                                    comment: item.data.raw,
                                  });
                                  close();
                                },
                              },
                            ],
                          ]}
                          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                            <Trigger
                              as={PlainButton}
                              ref={triggerRef}
                              className={cx(
                                "hover:bg-grey-100 flex h-8 w-8 items-center justify-center rounded-md transition",
                                isOpen && "bg-grey-100"
                              )}
                            >
                              <CommentMenuIcon />
                            </Trigger>
                          )}
                        />
                      )
                    }
                  />
                </div>
              );
            }}
          />
        )}
      </div>
      <div
        className="flex shrink-0 items-center justify-center gap-2 bg-white py-6 px-4"
        style={{ boxShadow: "0px -2px 0px 0px #ECEFF0" }}
      >
        <TaskCommentInput
          entityId={entityId}
          meId={me?.id || ""}
          apiAction="guest_comment_reply"
        />
      </div>
    </div>
  );
};

export const TaskPreviewFeedback: FC<TaskDetailsCommentsSidebarProps> = (
  props
) => {
  const { entityId } = useEntitySelectedProvider();
  const { meId } = useLoadedMe();
  const { taskId, task } = useTaskDetailsContext();

  return (
    <TaskCommentsProvider
      userId={meId}
      entityId={entityId}
      taskId={taskId}
      task={task}
    >
      <InnerTaskDetailsCommentsSidebar {...props} />
    </TaskCommentsProvider>
  );
};
