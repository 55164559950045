import { ChatItem } from "@web-src/modules/chats/types";
import React, { useCallback, useEffect, useMemo } from "react";
import sortBy from "lodash/sortBy";
import { useTranslations } from "@jugl-web/utils";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { ChatListPickerItem } from "./ChatListPickerItem";

type CategoryChat = {
  chats: ChatItem[];
  title: string;
  id: string;
};

export const ChatListPicker: React.FC<{
  chats: ChatItem[];
  value: ChatItem[];
  shouldDisableBroadcastChat?: boolean;
  onSelect: React.Dispatch<React.SetStateAction<ChatItem[]>>;
}> = ({ chats, value, shouldDisableBroadcastChat, onSelect }) => {
  const { t } = useTranslations();
  const { conversations } = useConversations();

  const segregatedChats = useMemo(() => {
    const recentChats: CategoryChat = {
      chats: [],
      id: "recentchats",
      title: t({
        id: "chats-page.recent-chats",
        defaultMessage: "Recent Chats",
      }),
    };
    const allContacts: CategoryChat = {
      chats: [],
      id: "allcontacts",
      title: t({
        id: "chats-page.all-contacts",
        defaultMessage: "All Contacts",
      }),
    };
    chats.forEach((chat, idx) => {
      if (idx < 4) {
        recentChats.chats.push(chat);
      } else {
        allContacts.chats.push(chat);
      }
    });
    const allContactsSorted = {
      chats: sortBy(allContacts.chats, (item) => item.title?.toLowerCase()),
      id: allContacts.id,
      title: allContacts.title,
    };

    return [recentChats, allContactsSorted].filter(
      (segregatedChat) => segregatedChat.chats.length !== 0
    );
  }, [chats, t]);

  const usersSelectState = useMemo(() => {
    const obj: { [key: string]: boolean } = {};
    value?.forEach((el) => {
      obj[el.id as string] = true;
    });
    return obj;
  }, [value]);

  const handleChatSelect = useCallback(
    (chat: ChatItem) => {
      if (usersSelectState[chat.id as string]) {
        onSelect((prev) => prev.filter((chatItem) => chatItem.id !== chat.id));
      } else {
        onSelect((prev) => [...prev, chat]);
      }
    },
    [onSelect, usersSelectState]
  );

  useEffect(() => {
    Object.keys(usersSelectState).forEach((chatId) => {
      const selectedChat = conversations
        .map(({ data }) => data)
        .find((chat) => chat.id === chatId);
      if (
        !selectedChat ||
        (shouldDisableBroadcastChat &&
          selectedChat.config?.admin_only_msg &&
          selectedChat.role !== "admin")
      ) {
        onSelect((prev) => prev.filter((chatItem) => chatItem.id !== chatId));
      }
    });
  }, [conversations, onSelect, usersSelectState, shouldDisableBroadcastChat]);

  return (
    <>
      {segregatedChats.map((item) => (
        <div key={item.id}>
          <div className="bg-grey-100 py-[10px] pl-8">
            <p className="text-grey-1000 m-0 text-sm font-medium">
              {item.title}
            </p>
          </div>
          {item.chats.map((chat) => (
            <div key={chat.id}>
              <ChatListPickerItem
                chat={chat}
                isSelected={usersSelectState[chat.id as string] || false}
                onSelect={handleChatSelect.bind(null, chat)}
                shouldDisableBroadcastChat={shouldDisableBroadcastChat}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
