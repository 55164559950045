import { ChatMessagePayloadAction } from "./ChatMessagePayloadAction";
import { ChatMessagePayloadAttachment } from "./ChatMessagePayloadAttachment";
import { ChatMessagePayloadCallAction } from "./ChatMessagePayloadCallAction";
import { ChatMessagePayloadType } from "./ChatMessagePayloadType";
import { EntityNotificationMessageType } from "./EntityNotificationMessageType";

export enum ChatMessagePayloadPushType {
  silent = "silent",
  normal = "normal",
}

export type ChatMessagePayload = {
  version?: number;
  title?: string;
  body?: string | null;

  attachments?: ChatMessagePayloadAttachment[] | null;

  reply_attributes_map?: {
    reply_msg_id: string;
    reply_msg_body?: ChatMessagePayload["body"];
    reply_msg_user: string;
    reply_user_name?: string;
    reply_conference?: ChatMessagePayload["conference"];
    reply_attachments?: ChatMessagePayload["attachments"];
  } | null;

  participants?: string[];

  // === old

  push_type?: ChatMessagePayloadPushType;
  type?: ChatMessagePayloadType;
  group_id?: string;
  sender_name?: string;
  edited?: boolean;
  forwarded?: boolean;
  deleted?: boolean;
  msg_id_to_delete?: string;
  source_msg_id?: string;
  attributes_map?: {
    mentions?: string;
  };

  // Digest
  digest?: {
    digest: string;
    module: EntityNotificationMessageType;
  }[];
  task_reminder?: {
    due_at: string;
    due_in: string;
    id: string;
    name: string;
  };

  // // Actions
  action?: ChatMessagePayloadAction;
  action_for?: "workspace" | string;
  action_from?: string;
  workspace_id?: string;
  changes?: {
    title?: string;
    admin_only_msg?: boolean;
    content_protected?: boolean;
    display_picture?: string;
  };

  // // Calls
  call_action?: ChatMessagePayloadCallAction;
  call_channel?: string;
  call_type?: string;

  conference?: {
    channel: string;
    name?: string;
    date?: string;
    time?: string;
  };
  duration?: string;

  // // Custom
  client_msg_id?: string;
};
