import {
  UserCPanelModel,
  UserCustomField,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import {
  Button,
  DataLoadingWrapper,
  InteractiveContainer,
  PhoneInput,
  TextInput,
  customerPhoneStringToInputValue,
  inputValueToCustomerPhoneString,
  inputValueToPhoneString,
  phoneStringToInputValue,
} from "@jugl-web/ui-components/cross-platform";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { isValidEmail, useToast, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { Select } from "@jugl-web/ui-components/cross-platform/forms/Select";
import { ReactComponent as TrashIconSvg } from "./assets/trash-icon.svg";
import { useCPanelPageContext } from "../../CPanelPageProvider";
import { MAX_USER_CUSTOM_FIELD_VALUE_LENGTH } from "../ManageProfileAttributesDialog/consts";

type InvitationFormType = {
  name: string;
  emails: { value: string }[];
  mobiles: { value: string }[];
  profileAttributes: {
    id: string;
    item: UserCustomField;
    value: string;
  }[];
};

export const UserInvitationSidebarContent: React.FC<{
  user?: UserCPanelModel;
  userCustomFields?: UserCustomField[];
  onRequestClose: () => void;
}> = ({ user, userCustomFields, onRequestClose }) => {
  const { completeOnboardingStep, isOnboardingActive } = useOnboarding();
  const { usersApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();

  const {
    register,
    control,
    handleSubmit,
    formState: invitationFormState,
    watch,
  } = useForm<InvitationFormType>({
    mode: "all",
    defaultValues: {
      name: user?.username,
      emails: user?.emails?.length
        ? user.emails.map((item) => ({ value: item }))
        : [{ value: "" }],
      mobiles: user?.mobiles?.length
        ? user.mobiles.map((item) => ({ value: item }))
        : [{ value: "" }],
      profileAttributes:
        userCustomFields?.map((item) => ({
          id: item.id,
          item,
          value: user?.work_info.find((el) => el.key === item.id)?.value || "",
        })) || [],
    },
  });

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "emails",
    keyName: "id",
  });

  const {
    fields: mobileFields,
    append: appendMobile,
    remove: removeMobile,
  } = useFieldArray({
    control,
    name: "mobiles",
    keyName: "id",
  });

  const { fields: profileAttributesFields } = useFieldArray({
    control,
    name: "profileAttributes",
    keyName: "id",
  });

  const [sendEntityInvitation, { isLoading: isSendLoading }] =
    usersApi.useSendEntityInvitationMutation();
  const [resendEntityInvitation, { isLoading: isResendLoading }] =
    usersApi.useResendEntityInvitationMutation();

  const { refetchUsers$ } = useCPanelPageContext();

  const { toast } = useToast({ variant: "web" });

  const handleFormSubmit = handleSubmit(async (formData) => {
    const data = {
      username: formData.name,
      emails: formData.emails
        .map((item) => item.value)
        .filter((item) => !!item),
      mobiles: formData.mobiles
        .map((item) => item.value)
        .filter((item) => !!item && phoneStringToInputValue(item).isValid),
      work_info: formData.profileAttributes.reduce<
        Record<string, string | null>
      >((acc, item) => {
        acc[item.id] = item.value || null;
        return acc;
      }, {}),
    };
    if (user) {
      const resentResp = await resendEntityInvitation({
        entityId,
        entityRelId: user.entity_rel_id,
        data,
      });
      if ("data" in resentResp && resentResp.data) {
        toast("Invitation re-sent successfully");
        onRequestClose();
        refetchUsers$.next();
      }
      return;
    }
    const sendResp = await sendEntityInvitation({
      entityId,
      data,
    });
    if ("data" in sendResp && sendResp.data) {
      toast("Invitation sent successfully");
      if (isOnboardingActive) {
        completeOnboardingStep("people");
      }
      onRequestClose();
      refetchUsers$.next();
    }
  });

  const hasEmails = watch("emails").some((email) => !!email.value);
  const hasPhones = watch("mobiles").some((phone) => !!phone.value);

  return (
    <>
      <SidebarDrawer.Content className="flex flex-col gap-6 p-8">
        <TextInput
          label={t({
            id: "cpanel-page.user-name",
            defaultMessage: "User Name",
          })}
          isRequired
          placeholder={t({
            id: "cpanel-page.enter-name",
            defaultMessage: "Enter Name",
          })}
          {...register(`name`, {
            required: true,
          })}
        />
        <div className="flex flex-col gap-6">
          {mobileFields.map((item, idx) => (
            <div key={item.id}>
              <div className="flex w-full items-end gap-1">
                <Controller
                  control={control}
                  name={`mobiles.${idx}.value`}
                  rules={{
                    validate: (mobileValue) => {
                      const obj = phoneStringToInputValue(mobileValue);
                      if (!obj.phone && hasEmails) {
                        return true;
                      }
                      return obj.isValid;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <div className="w-full space-y-2">
                      <label className="font-secondary text-dark-700 ml-[10px] text-[15px] leading-[22px]">
                        {t({
                          id: "cpanel-page.phone-number",
                          defaultMessage: "Phone number",
                        })}
                        {idx === 0 && !hasEmails && (
                          <span className="text-gradients-danger text-base">
                            {" "}
                            *
                          </span>
                        )}
                      </label>
                      <PhoneInput
                        wrapperClassName="border-grey-400 rounded-[10px] border-[1px] border-solid h-[56px] focus-within:border-[#90C9F9] hover:border-[#90C9F9]"
                        listButtonClassName="text-xs py-2 pl-2"
                        placeholder={t({
                          id: "common.phone-number",
                          defaultMessage: "Phone number",
                        })}
                        value={
                          value ? phoneStringToInputValue(value) : undefined
                        }
                        onChange={(obj) => {
                          onChange(
                            obj.phone ? inputValueToPhoneString(obj) : ""
                          );
                        }}
                      />
                    </div>
                  )}
                />
                {mobileFields.length > 1 && (
                  <InteractiveContainer
                    className="p-4"
                    onClick={() => removeMobile(idx)}
                  >
                    <TrashIconSvg />
                  </InteractiveContainer>
                )}
              </div>
              {idx === mobileFields.length - 1 && (
                <InteractiveContainer
                  className="text-primary-500 px-2 py-2 text-sm"
                  onClick={() => appendMobile({ value: "" })}
                >
                  +{" "}
                  {t({
                    id: "cpanel-page.add-phone",
                    defaultMessage: "Add phone",
                  })}
                </InteractiveContainer>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-6">
          {emailFields.map((item, idx) => (
            <div key={item.id}>
              <div className="flex w-full items-end gap-1">
                <TextInput
                  label={t({
                    id: "cpanel-page.email",
                    defaultMessage: "Email",
                  })}
                  placeholder={t({
                    id: "cpanel-page.enter-email",
                    defaultMessage: "Enter Email",
                  })}
                  classNames={{
                    wrapperClassName: "w-full",
                  }}
                  isRequired={idx === 0 && !hasPhones}
                  {...register(`emails.${idx}.value`, {
                    validate: (value) => {
                      if (!value && hasPhones) {
                        return true;
                      }
                      return isValidEmail(value);
                    },
                  })}
                />
                {emailFields.length > 1 && (
                  <InteractiveContainer
                    className="h-[56px] p-4"
                    onClick={() => removeEmail(idx)}
                  >
                    <TrashIconSvg />
                  </InteractiveContainer>
                )}
              </div>
              {idx === emailFields.length - 1 && (
                <InteractiveContainer
                  className="text-primary-500 px-2 py-2 text-sm"
                  onClick={() => appendEmail({ value: "" })}
                >
                  +{" "}
                  {t({
                    id: "cpanel-page.add-email",
                    defaultMessage: "Add email",
                  })}
                </InteractiveContainer>
              )}
            </div>
          ))}
        </div>
        {profileAttributesFields.map((field, idx) => {
          if (field.item.type === "text") {
            return (
              <Controller
                key={field.id}
                control={control}
                name={`profileAttributes.${idx}.value`}
                rules={{
                  maxLength: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                }}
                render={({ field: { value, ...props } }) => (
                  <TextInput
                    label={field.item.name}
                    placeholder={t({
                      id: "common.enter",
                      defaultMessage: "Enter",
                    })}
                    {...props}
                    value={value}
                    lengthIndicator={{
                      current: value.length,
                      max: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                    }}
                  />
                )}
              />
            );
          }

          if (field.item.type === "email") {
            return (
              <Controller
                key={field.id}
                control={control}
                name={`profileAttributes.${idx}.value`}
                rules={{
                  maxLength: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                  validate: (value) => (value ? isValidEmail(value) : true),
                }}
                render={({ field: { value, ...props } }) => (
                  <TextInput
                    label={field.item.name}
                    placeholder={t({
                      id: "common.enter",
                      defaultMessage: "Enter",
                    })}
                    {...props}
                    value={value}
                    lengthIndicator={{
                      current: value.length,
                      max: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                    }}
                  />
                )}
              />
            );
          }

          if (field.item.type === "number") {
            return (
              <Controller
                key={field.id}
                control={control}
                name={`profileAttributes.${idx}.value`}
                rules={{
                  maxLength: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                }}
                render={({ field: { value, ...props } }) => (
                  <TextInput
                    classNames={{
                      inputClassName:
                        "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
                    }}
                    type="number"
                    placeholder={t({
                      id: "common.enter",
                      defaultMessage: "Enter",
                    })}
                    label={field.item.name}
                    onKeyDown={(e) => {
                      const notAllowedCharacters = ["e", "E", "+", "-"];
                      if (notAllowedCharacters.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    {...props}
                    value={value}
                    lengthIndicator={{
                      current: value.length,
                      max: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                    }}
                  />
                )}
              />
            );
          }

          if (field.item.type === "dropdown") {
            return (
              <Controller
                key={field.id}
                control={control}
                name={`profileAttributes.${idx}.value`}
                render={({ field: { value, onChange } }) => (
                  <Select
                    items={(field.item.type === "dropdown"
                      ? field.item.values
                      : []
                    ).map((el) => ({
                      name: el.value,
                      value: el.value,
                    }))}
                    value={value}
                    onSelect={onChange}
                    label={field.item.name}
                    placeholder={t({
                      id: "common.select",
                      defaultMessage: "Select",
                    })}
                  />
                )}
              />
            );
          }

          if (field.item.type === "phone") {
            return (
              <div className="space-y-2" key={field.id}>
                <label className="font-secondary text-dark-700 ml-[10px] text-[15px] leading-[22px]">
                  {field.item.name}
                </label>
                <Controller
                  control={control}
                  name={`profileAttributes.${idx}.value`}
                  rules={{
                    maxLength: MAX_USER_CUSTOM_FIELD_VALUE_LENGTH,
                    validate: (value) =>
                      value
                        ? customerPhoneStringToInputValue(value).isValid
                        : true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <PhoneInput
                      wrapperClassName="border-grey-400 rounded-[10px] border-[1px] border-solid h-[56px] focus-within:border-[#90C9F9] hover:border-[#90C9F9]"
                      listButtonClassName="text-xs py-2 pl-2"
                      placeholder={t({
                        id: "common.phone-number",
                        defaultMessage: "Phone number",
                      })}
                      value={
                        value
                          ? customerPhoneStringToInputValue(value)
                          : undefined
                      }
                      onChange={(phoneNumber) => {
                        onChange(
                          phoneNumber.phone
                            ? inputValueToCustomerPhoneString(phoneNumber)
                            : ""
                        );
                      }}
                    />
                  )}
                />
              </div>
            );
          }

          return null;
        })}
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          fullWidth
          onClick={handleFormSubmit}
          isDisabled={
            // !invitationFormState?.isDirty ||
            !invitationFormState?.isValid || isSendLoading || isResendLoading
          }
        >
          {t({
            id: "common.submit",
            defaultMessage: "Submit",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </>
  );
};

export const UserInvitationSidebar: React.FC<{
  isOpen: boolean;
  user?: UserCPanelModel;
  onRequestClose: () => void;
}> = ({ isOpen, user, onRequestClose }) => {
  const { usersApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const { data, isFetching, isError, refetch } =
    usersApi.useGetUserCustomFieldsQuery({
      entityId,
    });

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      hasBackdrop
      title={user ? "Update Invitation" : "Add Member"}
    >
      <DataLoadingWrapper
        isLoading={isFetching}
        isError={isError}
        onRetry={refetch}
      >
        <UserInvitationSidebarContent
          user={user}
          onRequestClose={onRequestClose}
          userCustomFields={data?.value}
        />
      </DataLoadingWrapper>
    </SidebarDrawer>
  );
};
