import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskPriorities } from "@jugl-web/domain-resources/tasks/hooks/useTaskPriorities";
import { SupportedXAxis } from "@jugl-web/rest-api/dashboard/models/Widget";
import { TaskPriority } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { useCallback, useMemo } from "react";

interface UseUnassignedCategoryLabelOptions {
  entityId: string;
}

export const useUnassignedCategoryLabel = ({
  entityId,
}: UseUnassignedCategoryLabelOptions) => {
  const { noneLabel, getCustomFieldById } = useTaskFields({ entityId });
  const { getPriorityDetailsById } = useTaskPriorities();

  const { t } = useTranslations();

  const unassignedCategoryLabelByXAxisType = useMemo(
    () =>
      ({
        board: t({
          id: "dashboard-page.without-board-chart-label",
          defaultMessage: "Without board",
        }),
        label: noneLabel.text,
        priority: getPriorityDetailsById(TaskPriority.none).shortLabel,
        assignee: t({
          id: "dashboard-page.without-assignee-chart-label",
          defaultMessage: "Without assignee",
        }),
        customer: t({
          id: "dashboard-page.without-customer-chart-label",
          defaultMessage: "Without customer",
        }),
      } satisfies Partial<Record<SupportedXAxis, string>>),
    [getPriorityDetailsById, noneLabel.text, t]
  );

  const getCustomFieldUnassignedCategoryLabel = useCallback(
    (fieldName: string) =>
      t(
        {
          id: "dashboard-page.without-custom-field-name-chart-label",
          defaultMessage: "Without {customFieldName}",
        },
        { customFieldName: fieldName }
      ),
    [t]
  );

  const getUnassignedCategoryLabelByXAxisType = useCallback(
    (xAxisType: SupportedXAxis): string | undefined => {
      if (xAxisType in unassignedCategoryLabelByXAxisType) {
        return unassignedCategoryLabelByXAxisType[
          xAxisType as keyof typeof unassignedCategoryLabelByXAxisType
        ];
      }

      const maybeCustomField = getCustomFieldById(xAxisType);

      if (!maybeCustomField) {
        return undefined;
      }

      return getCustomFieldUnassignedCategoryLabel(maybeCustomField.name);
    },
    [
      getCustomFieldById,
      getCustomFieldUnassignedCategoryLabel,
      unassignedCategoryLabelByXAxisType,
    ]
  );

  return {
    unassignedCategoryLabelByXAxisType,
    getCustomFieldUnassignedCategoryLabel,
    getUnassignedCategoryLabelByXAxisType,
  };
};
