import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { TaskDetails } from "@web-src/pages/TaskDetails";
import TasksPage from "@web-src/pages/Tasks";
import CustomersPage from "./CustomersPage";

export type CustomersPagesNavigationParams = {
  customersCustomers: PageNavParams;
  customersTasks: PageNavParams<{ customerId: string }>;
  customersTaskDetails: PageNavParams<{ customerId: string; taskId: string }>;
};

export const customersPagesNavigationConfig: ModulePagesConfig<CustomersPagesNavigationParams> =
  {
    customersCustomers: {
      element: <CustomersPage />,
      path: "customers",
      isPrivate: true,
      requiredSubscriptionModule: SubscriptionPlanModuleId.clients,
      requiredUserRoles: appModulesToRequiredUserRoles.clients,
    },
    customersTasks: {
      element: <TasksPage />,
      path: "/customers/:customerId/tasks",
      isPrivate: true,
      requiredSubscriptionModule: SubscriptionPlanModuleId.clients,
      requiredUserRoles: appModulesToRequiredUserRoles.clients,
    },
    customersTaskDetails: {
      element: <TaskDetails />,
      path: "/customers/:customerId/tasks/details/:taskId",
      isPrivate: true,
      requiredSubscriptionModule: SubscriptionPlanModuleId.clients,
      requiredUserRoles: appModulesToRequiredUserRoles.clients,
    },
  };
