import React from "react";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

export const OrderNameTile: React.FC<{
  title: string;
  img?: string;
  discount?: number | null;
}> = ({ title, img, discount }) => (
  <div className="flex w-full items-center justify-between">
    <div className="flex min-w-[188px] items-center gap-3">
      {img ? (
        <div className="h-8 w-8 shrink-0 overflow-hidden rounded-[10px]">
          <img
            src={img}
            alt={title}
            className="h-full w-full object-cover"
            title={title}
          />
        </div>
      ) : (
        <div className="border-grey-400 flex h-8 w-8 shrink-0 items-center justify-center rounded-[10px] border border-solid bg-[#F2F2F4]">
          <ImageIcon />
        </div>
      )}
      <span
        className="text-dark-800 font-secondary text-nowrap truncate font-medium leading-[140%] tracking-[0.16px]"
        title={title}
      >
        {title}
      </span>
    </div>
    {discount && (
      <div className="font-secondary bg-gradients-danger text-nowrap flex h-[25px] min-w-fit items-center rounded-lg px-2 py-1 text-xs text-white">
        -{discount}%
      </div>
    )}
  </div>
);
