import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import { AddChartButton } from "../AddChartButton";
import emptyStateAnimation from "./assets/empty-state-animation.json";

interface DashboardEmptyStateProps {
  canManageDashboard: boolean;
}

export const DashboardEmptyState: FC<DashboardEmptyStateProps> = ({
  canManageDashboard,
}) => {
  const { t } = useTranslations();

  return (
    <div className="animate-fade-in flex h-full items-center justify-center">
      <div className="flex flex-col items-center">
        <Lottie
          options={{ animationData: emptyStateAnimation }}
          height={300}
          width={300}
          style={{ marginTop: "-15%" }}
        />
        <h2 className="font-secondary mt-0 mb-2.5 font-medium text-black">
          {canManageDashboard
            ? t({
                id: "dashboard-page.empty-state-title",
                defaultMessage:
                  "Explore data from various Boards using Charts ✨",
              })
            : t({
                id: "dashboard-page.empty-state-member-title",
                defaultMessage: "Dashboard is empty",
              })}
        </h2>
        <span className="font-secondary mb-8 text-base text-[#828282]">
          {canManageDashboard
            ? t({
                id: "dashboard-page.empty-state-description",
                defaultMessage:
                  "Create custom dashboards to visualize data from your Boards 📈",
              })
            : t({
                id: "dashboard-page.empty-state-member-description",
                defaultMessage:
                  "Owner of this Dashboard has not added any widgets yet",
              })}
        </span>
        {canManageDashboard && <AddChartButton popoverPlacement="bottom" />}
      </div>
    </div>
  );
};
