import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import {
  ChatMessageModel,
  ChatMessageAttachmentType,
  ChatType,
  ChatMessagePayload,
} from "@jugl-web/rest-api";
import { logger } from "@web-src/utils/logger";
import cx from "classnames";
import { usePrevious, useTranslations } from "@jugl-web/utils";
import {
  MentionTextarea,
  MentionTextareaHandle,
} from "@jugl-web/domain-resources/common/components/RichTextarea";
import { useFileSelect } from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useMe } from "@web-src/features/app/hooks/useMe";
import ChatMessageCall from "@web-src/modules/chats/components/DeprecatedChatMessageBubble/ChatMessageCall";
import { PlainButton } from "@jugl-web/ui-components";
import {
  ChatInputAudioRecorder,
  ChatInputAudioRecorderHandle,
} from "./components/ChatInputAudioRecorder";
import { ReactComponent as MicrophoneIcon } from "./assets/microphone.svg";
import ChatInputFilePreview from "./ChatInputFilePreview";
import ChatInputFileSelect from "./ChatInputFileSelect";
import ChatInputButton from "./ChatInputButton";
import ChatInputToolbar, {
  ActionType,
  ChatInputToolbarParams,
} from "./ChatInputToolbar";
import useChatInputState from "./useChatInputState";
import { ChatAddConferenceSidebar } from "./ChatAddConferenceSidebar";

const MAX_FILE_SIZE_IN_BYTES = 75 * 1024 * 1024;

const ALLOWED_FILE_TYPES = "*";

export type ChatInputHandle = {
  clear: () => void;
  setValue: (body: string, message?: ChatMessageModel) => void;
  focus: () => void;
};

export type ChatInputParams = {
  isLoading?: boolean;
  attachmentAccept?: string;
  onSubmit: (params: {
    body: string;
    file?: File;
    gif?: string;
    attachmentType?: ChatMessageAttachmentType;
    conference?: ChatMessagePayload["conference"];
  }) => void;
  disableAudioMessage?: boolean;
  disableAttachments?: boolean;
  chatId: string;
  chatType: ChatType;
};

export const ChatInput = forwardRef<ChatInputHandle, ChatInputParams>(
  (
    {
      isLoading = false,
      attachmentAccept = "*",
      onSubmit,
      disableAudioMessage,
      disableAttachments,
      chatId,
      chatType,
    },
    ref
  ) => {
    const { entity } = useEntitySelectedProvider();
    const { me } = useMe();
    const { moduleRequiredAction } = useEntitySelectedProvider();
    const [file, setFile] = useState<File | undefined>();

    const [conference, setConference] = useState<
      ChatMessagePayload["conference"] | undefined
    >(undefined);
    const [isAddConfernceSidebarOpen, setIsAddConfernceSidebarOpen] =
      useState<boolean>(false);

    const [isValid, setIsValid] = useState(false);
    const [recordingAudio, setRecordingAudio] = useState<boolean>(false);
    useEffect(() => {
      setRecordingAudio(false);
      audioRecorder$?.current?.stop();
    }, [chatId]);
    const $fileInput = useRef<HTMLInputElement>(null);
    const $bodyInput = useRef<MentionTextareaHandle | null>(null);
    const previousChatId = usePrevious(chatId);
    const { chatsState, updateChatState } = useChatInputState();
    const { t } = useTranslations();
    const { logEvent } = useFCM();
    const { selectFile } = useFileSelect({
      maxFileSize: MAX_FILE_SIZE_IN_BYTES,
      multiple: false,
      acceptTypes: ALLOWED_FILE_TYPES,
    });

    useEffect(() => {
      if (previousChatId === chatId) {
        return;
      }
      const chatsStateBody = chatsState[chatId]?.body;
      if (chatsStateBody) {
        $bodyInput.current?.readFromJSON(chatsStateBody);
      } else {
        $bodyInput.current?.setRichText("");
      }
      setFile(chatsState[chatId]?.file || undefined);
    }, [previousChatId, chatId, chatsState]);

    useEffect(() => $bodyInput.current?.focus(), [chatId]);

    const validator = useCallback(
      (text?: string) => !!text?.trim().length || !!file || !!conference,
      [file, conference]
    );

    useEffect(() => {
      updateChatState?.(chatId, {
        body: $bodyInput.current?.saveToJSON(),
        file,
      });
      setIsValid(validator($bodyInput.current?.getRichText()));
    }, [file, validator, updateChatState, chatId]);

    const handleRawTextChange = (rawText: string) => {
      updateChatState?.(chatId, {
        body: $bodyInput.current?.saveToJSON(),
        file,
      });
      setIsValid(validator(rawText));
    };

    const { completeOnboardingStep, isOnboardingActive } = useOnboarding();

    const handleSubmit = useCallback(() => {
      onSubmit({
        file,
        body: $bodyInput.current?.getRichText() || "",
        conference,
      });
      if (isOnboardingActive) {
        completeOnboardingStep("chat");
      }
      setConference(undefined);
      setTimeout(() => {
        $bodyInput.current?.focus();
      });
      logEvent("action_chat_send");
    }, [
      conference,
      file,
      logEvent,
      onSubmit,
      completeOnboardingStep,
      isOnboardingActive,
    ]);

    const handleFileRemoveClick = useCallback(() => {
      setFile(undefined);
      if ($fileInput.current) {
        $fileInput.current.value = "";
      }
    }, []);

    const handleEnter = useCallback(() => {
      if (isValid) {
        handleSubmit();
      }
    }, [handleSubmit, isValid]);

    const handleFileInputPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
      if (e.clipboardData.files?.length) {
        setFile(e.clipboardData.files[0]);
      }
    };

    const audioRecorder$ = useRef<ChatInputAudioRecorderHandle>(null);
    const handleAudioRecorderCancel = useCallback(() => {
      setRecordingAudio(false);
    }, []);

    const handleAudioRecorderSend = useCallback(
      async (e: File) => {
        try {
          await onSubmit({
            file: e,
            body: "",
            attachmentType: ChatMessageAttachmentType.voice,
          });
          setRecordingAudio(false);
        } catch (error) {
          logger.error(error);
        }
      },
      [onSubmit]
    );

    useImperativeHandle(ref, () => ({
      clear: () => {
        $bodyInput.current?.setRichText("");
        setFile(undefined);
        if ($fileInput.current) {
          $fileInput.current.value = "";
        }
      },
      setValue: async (newBody: string, chatMessage?: ChatMessageModel) => {
        let bodyToSet = newBody;
        if (chatMessage && chatMessage.payload.attributes_map?.mentions) {
          try {
            const messageMentions: { id: string; name: string }[] = JSON.parse(
              chatMessage.payload.attributes_map.mentions
            );
            messageMentions.forEach(({ id, name }) => {
              if (bodyToSet.includes(`@${id}`)) {
                bodyToSet = bodyToSet.replaceAll(
                  new RegExp(`@${id}`, "g"),
                  `@${id}%%#${name.replace("@", "")}#%%`
                );
              }
            });
          } catch {
            logger.info("bad mentions", chatMessage);
          }
        }
        $bodyInput.current?.setRichText(bodyToSet);
      },
      focus: () => {
        $bodyInput.current?.focus();
      },
    }));
    const handleSelectFile = useCallback(async () => {
      const files = await selectFile();
      if (files && files.length > 0) {
        setFile(files[0]);
      }
    }, [selectFile]);
    const handleChatInputToolbarClick: ChatInputToolbarParams["onAction"] =
      useCallback(
        ({ type, emoji, gif }) => {
          switch (type) {
            case ActionType.attachment:
              handleSelectFile();
              break;
            case ActionType.emoji:
              if (emoji) {
                $bodyInput?.current?.focus();
                $bodyInput.current?.insertTextAtSelection(emoji);
              }
              break;
            case ActionType.gif:
              onSubmit?.({
                gif,
                body: "",
                attachmentType: ChatMessageAttachmentType.gif,
              });
              break;
            case ActionType.videoMessage:
              moduleRequiredAction(SubscriptionPlanModuleId.conference, () => {
                setIsAddConfernceSidebarOpen(true);
              });
              break;
            default:
          }
        },
        [onSubmit, handleSelectFile, moduleRequiredAction]
      );

    return (
      <>
        {!file && !disableAttachments && (
          <ChatInputFileSelect
            onChange={setFile}
            attachmentAccept={attachmentAccept}
            ref={$fileInput}
          />
        )}
        <div className="flex flex-col rounded-[16px] bg-gray-50 py-4 px-6">
          <ChatAddConferenceSidebar
            isOpen={isAddConfernceSidebarOpen}
            onClose={() => setIsAddConfernceSidebarOpen(false)}
            setConference={setConference}
          />
          {file && (
            <ChatInputFilePreview
              file={file}
              isLoading={isLoading}
              onRemoveClick={handleFileRemoveClick}
            />
          )}
          {conference && (
            <ChatMessageCall
              conference={conference}
              isOutgoing
              preview
              onCancel={() => setConference(undefined)}
            />
          )}
          <div className="flex items-center">
            {recordingAudio && (
              <div className="flex-1">
                <ChatInputAudioRecorder
                  ref={audioRecorder$}
                  onSend={handleAudioRecorderSend}
                  onCancel={handleAudioRecorderCancel}
                  loading={isLoading}
                />
              </div>
            )}
            <div
              className={cx("flex-1", recordingAudio ? "hidden" : undefined)}
            >
              {me?.id && (
                <MentionTextarea
                  ref={$bodyInput}
                  mentions={{
                    type: "remote",
                    fetchParams: {
                      workspaceId: chatType === "muc" ? chatId : undefined,
                      entityId: entity.id,
                      sortBy: "name",
                    },
                    meId: me?.id,
                  }}
                  placeholder={t({
                    id: "chats-page.type-new-message",
                    defaultMessage: "Type a new message",
                  })}
                  onRawTextChange={handleRawTextChange}
                  onEnter={handleEnter}
                  onPaste={handleFileInputPaste}
                  classes={{
                    placeholder: "text-grey-900",
                    contentEditable:
                      "outline-none max-h-[300px] overflow-y-auto",
                  }}
                />
              )}
            </div>
            {!recordingAudio && (
              <div className="mt-auto flex items-center">
                {!disableAudioMessage && (
                  <PlainButton
                    disabled={!!$bodyInput.current?.getRichText() || !!file}
                    className="m-0 mr-4 block h-[40px] w-[40px] cursor-pointer rounded-[20px] border-none bg-transparent p-0 transition-all hover:bg-gray-200 disabled:pointer-events-none disabled:opacity-50"
                    onClick={() => {
                      setRecordingAudio(true);
                      logEvent("action_chat_voice");
                    }}
                  >
                    <MicrophoneIcon />
                  </PlainButton>
                )}
                <ChatInputButton
                  loading={isLoading}
                  disabled={!isValid || isLoading}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 pl-[16px]">
          <ChatInputToolbar
            onAction={handleChatInputToolbarClick}
            disabledAttachments={disableAttachments}
            disabled={!!recordingAudio}
          />
        </div>
      </>
    );
  }
);
