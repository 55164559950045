import { TaskCustomFieldType } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { FC, SVGProps } from "react";
import { UserCustomFieldType } from "@jugl-web/rest-api";
import { ReactComponent as CustomDateFieldBlankIcon } from "./assets/custom-date-field-blank.svg";
import { ReactComponent as CustomDropdownFieldBlankIcon } from "./assets/custom-dropdown-field-blank.svg";
import { ReactComponent as CustomNumberFieldBlankIcon } from "./assets/custom-number-field-blank.svg";
import { ReactComponent as CustomTextFieldBlankIcon } from "./assets/custom-text-field-blank.svg";
import { ReactComponent as DateIcon } from "./assets/date.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown.svg";
import { ReactComponent as NumberIcon } from "./assets/number.svg";
import { ReactComponent as TextIcon } from "./assets/text.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { ReactComponent as EmailIcon } from "./assets/email.svg";

interface CustomFieldTypeDetails {
  color?: string;
  iconBackgroundColor: string;
  primaryIcon: FC<SVGProps<SVGSVGElement>>;
  secondaryIcon: FC<SVGProps<SVGSVGElement>>;
  shortLabel: string;
  fullLabel: string;
}

export type SupportedCustomFieldType =
  | TaskCustomFieldType
  | UserCustomFieldType;

export const useCustomFieldTypes = () => {
  const { t } = useTranslations();

  const customFieldTypeToDetails: Record<
    SupportedCustomFieldType,
    CustomFieldTypeDetails
  > = {
    text: {
      color: "#EBFFF5",
      iconBackgroundColor: "#22C59E",
      primaryIcon: CustomTextFieldBlankIcon,
      secondaryIcon: TextIcon,
      shortLabel: t({
        id: "custom-field.text",
        defaultMessage: "Text",
      }),
      fullLabel: t({
        id: "custom-field.text-field",
        defaultMessage: "Text field",
      }),
    },
    date: {
      color: "#FFF7EB",
      iconBackgroundColor: "#F15C5C",
      primaryIcon: CustomDateFieldBlankIcon,
      secondaryIcon: DateIcon,
      shortLabel: t({
        id: "custom-field.date",
        defaultMessage: "Date",
      }),
      fullLabel: t({
        id: "custom-field.date-field",
        defaultMessage: "Date field",
      }),
    },
    dropdown: {
      color: "#E3F2FD",
      iconBackgroundColor: "#F33A9E",
      primaryIcon: CustomDropdownFieldBlankIcon,
      secondaryIcon: DropdownIcon,
      shortLabel: t({
        id: "custom-field.dropdown",
        defaultMessage: "Dropdown",
      }),
      fullLabel: t({
        id: "custom-field.dropdown-field",
        defaultMessage: "Dropdown field",
      }),
    },
    number: {
      color: "#FDEBFF",
      iconBackgroundColor: "#AB59E4",
      primaryIcon: CustomNumberFieldBlankIcon,
      secondaryIcon: NumberIcon,
      shortLabel: t({
        id: "custom-field.number",
        defaultMessage: "Number",
      }),
      fullLabel: t({
        id: "custom-field.number-field",
        defaultMessage: "Numeric field",
      }),
    },
    email: {
      iconBackgroundColor: "#56CCF2",
      primaryIcon: EmailIcon,
      secondaryIcon: EmailIcon,
      shortLabel: t({
        id: "custom-field.email",
        defaultMessage: "Email",
      }),
      fullLabel: t({
        id: "custom-field.email-field",
        defaultMessage: "Email Field",
      }),
    },
    phone: {
      iconBackgroundColor: "#2FB819",
      primaryIcon: PhoneIcon,
      secondaryIcon: PhoneIcon,
      shortLabel: t({
        id: "custom-field.phone-number",
        defaultMessage: "Phone number",
      }),
      fullLabel: t({
        id: "custom-field.phone-number-field",
        defaultMessage: "Phone number field",
      }),
    },
  };

  return {
    customFieldTypeToDetails,
  };
};
