import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { cx } from "@jugl-web/utils";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { SidebarItem } from "../../types";
import { SidebarAccordion } from "../SidebarAccordion";
import { UnreadCountBox } from "../UnreadCountBox";

interface SidebarItemComponentProps {
  item: SidebarItem;
  isExpanded: boolean;
  onSideBarExpand: () => void;
  itemsRenderer?: (items: SidebarItem[]) => ReactNode[];
}

export const SidebarItemComponent: FC<SidebarItemComponentProps> = ({
  item,
  isExpanded,
  onSideBarExpand,
  itemsRenderer,
}) => {
  const { isModuleAvailable, moduleRequiredAction } = useEntityProvider();
  const { tcx } = useTheme();

  const hasIcon = !!item.SvgIcon || !!item.customIcon;

  const itemContent = (
    <>
      <div
        className={cx(
          "flex items-center",
          hasIcon ? "gap-2" : "gap-7",
          isExpanded ? "px-2.5" : "justify-center"
        )}
      >
        <div className="relative mb-[2px] flex h-full items-center leading-[0]">
          {item.SvgIcon ? (
            <item.SvgIcon
              className={tcx("transition-transform duration-300", {
                light: "fill-primary-700",
                dark: "fill-white",
                blue: "fill-white",
              })}
            />
          ) : (
            item.customIcon
          )}
          {!isExpanded && (item.unreadCount ?? 0) > 0 && (
            <UnreadCountBox
              count={item.unreadCount ?? 0}
              className="absolute right-[6px] top-1/2 -translate-y-1/2 translate-x-full"
            />
          )}
        </div>
        <div
          className={cx(
            isExpanded
              ? "flex w-full items-center justify-between gap-0.5 truncate"
              : "hidden"
          )}
        >
          <span className="font-secondary truncate text-sm leading-[21px]">
            {item.title}
          </span>
          {(item.unreadCount ?? 0) > 0 && (
            <UnreadCountBox count={item.unreadCount ?? 0} />
          )}
        </div>
      </div>
    </>
  );

  const itemClasses = tcx(
    "group mx-2.5 flex shrink-0 select-none flex-col justify-center rounded-lg text-white no-underline transition-colors duration-300 h-[41px] w-[60px]",
    isExpanded ? "w-[190px]" : "w-[60px]",
    {
      light: [
        "hover:bg-[#ECF5FC] text-dark",
        {
          "bg-primary-50 text-[#133C84]": item.active,
        },
      ],
      dark: [
        "hover:bg-[#3E3F40]",
        {
          "bg-[#4D4D4D]": item.active,
        },
      ],
      blue: [
        "hover:bg-[#2F7DDA]",
        {
          "bg-primary-800": item.active,
        },
      ],
    },
    item.active ? "font-semibold" : ""
  );

  if (item.type === "accordion") {
    return (
      <Tooltip
        isDisabled={isExpanded}
        placement="right"
        renderTrigger={({ props, ref }) => (
          <SidebarAccordion
            ref={ref}
            label={
              <div className="flex flex-col overflow-hidden">
                <div
                  className={cx(
                    "flex items-center gap-2",
                    isExpanded ? "px-2.5" : "justify-center"
                  )}
                >
                  {item.SvgIcon ? (
                    <item.SvgIcon
                      className={cx(
                        "mb-[2px] shrink-0 transition-transform duration-300"
                      )}
                    />
                  ) : (
                    item.customIcon
                  )}
                  <span
                    className={cx(
                      "font-secondary truncate text-sm",
                      isExpanded ? "block" : "hidden"
                    )}
                  >
                    {item.title}
                  </span>
                </div>
              </div>
            }
            isHomeSidebarExpanded={isExpanded}
            onSideBarExpand={onSideBarExpand}
            onClick={item.onClick}
            {...props}
          >
            {itemsRenderer?.(item.items)}
          </SidebarAccordion>
        )}
      >
        {item.title}
      </Tooltip>
    );
  }

  if (item.type === "link") {
    return (
      <Tooltip
        isDisabled={isExpanded}
        placement="right"
        renderTrigger={({ props, ref }) => (
          <Link
            ref={ref}
            className={itemClasses}
            to={item.href}
            onClick={(e) => {
              if (
                item.requiredSubscriptionModule &&
                !isModuleAvailable(item.requiredSubscriptionModule)
              ) {
                e.preventDefault();
                moduleRequiredAction(item.requiredSubscriptionModule);
              }

              item.onClick?.();
            }}
            {...props}
          >
            {itemContent}
          </Link>
        )}
      >
        {item.title}
      </Tooltip>
    );
  }

  return (
    <InteractiveContainer
      className={itemClasses}
      onClick={() =>
        item.requiredSubscriptionModule
          ? moduleRequiredAction(item.requiredSubscriptionModule, item.onClick)
          : item.onClick?.()
      }
    >
      {itemContent}
    </InteractiveContainer>
  );
};
