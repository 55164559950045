import { FC, ReactNode } from "react";
import { Button, Input } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/web";
import { ReactComponent as PlusIcon } from "./assets/plus-icon.svg";
import { ReactComponent as MinusIcon } from "./assets/minus-icon.svg";

export type SeatCountSelectProps = {
  value: number;
  onChange: (value: number) => void;
  bottomLabel?: ReactNode;
  minValue?: number;
  minDisabledTooltip?: "seatsTaken";
  isDisabled?: boolean;
};

export const SeatCountSelect: FC<SeatCountSelectProps> = ({
  value,
  onChange,
  minValue = 0,
  bottomLabel,
  minDisabledTooltip,
  isDisabled,
}) => {
  const isInvalid = value < minValue;
  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="flex items-center justify-center gap-1">
        <Tooltip
          placement="left"
          isDisabled={!minDisabledTooltip || value > minValue}
          renderTrigger={({ ref, props }) => (
            <Button
              ref={ref}
              color="grey"
              className="h-10 w-10 p-0"
              onClick={() => onChange((value || 0) - 1)}
              isDisabled={isDisabled || value <= minValue}
              {...props}
            >
              <MinusIcon />
            </Button>
          )}
        >
          {(() => {
            switch (minDisabledTooltip) {
              case "seatsTaken":
                return (
                  <div className="flex flex-col gap-0.5 p-4">
                    <div className="font-secondary text-sm font-bold text-white">
                      Seats are taken
                    </div>
                    <div className="text-sm leading-[20px]">
                      You cant remove more seats, because they are already taken
                      by Workspace users
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })()}
        </Tooltip>
        <div>
          <Input
            className="jugl__hide-number-arrows h-10 w-[112px] bg-white text-center"
            variant="outlined"
            type="text"
            value={parseInt(String(value), 10).toString()}
            disabled={isDisabled}
            onChange={(e) => {
              if (!e.target.value) {
                onChange(0);
                return;
              }
              if (!/^\d+$/.test(e.target.value)) {
                onChange(value);
                return;
              }
              onChange(parseInt(e.target.value, 10));
            }}
          />
        </div>
        <Button
          color="grey"
          className="h-10 w-10 p-0"
          isDisabled={isDisabled}
          onClick={() => onChange((value || 0) + 1)}
        >
          <PlusIcon />
        </Button>
      </div>
      {!!isInvalid && (
        <div className="font-primary text-center text-sm text-red-500">
          You cant remove more seats, because they are already taken by
          Workspace users
        </div>
      )}
      {!!bottomLabel && (
        <div className="text-grey-700 font-primary text-center text-sm">
          {bottomLabel}
        </div>
      )}
    </div>
  );
};
