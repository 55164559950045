import { FC, ReactNode, useMemo } from "react";
import { SubscriptionPlanModel } from "@jugl-web/rest-api/entities/models/SubscriptionPlan/SubscriptionPlanModel";
import { apiUTCToLocalDateTime, cx, priceDisplay } from "@jugl-web/utils";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { usePaymentStrings } from "@web-src/modules/workspace/hooks/usePaymentStrings";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import format from "date-fns/format";
import { Tooltip } from "@jugl-web/ui-components/web";
import { Divider } from "./components/Divider";
import { FeatureList } from "./components/FeatureList";
import { ReactComponent as CheckIcon } from "./assets/check-icon.svg";
import { useSelectSubscriptionFormProvider } from "../../SelectSubscriptionFormProvider";

export type PlanItemProps = {
  onSelect: (plan: SubscriptionPlanModel) => void;
  onRequestAdditionalSeats?: () => void;
  isSelected?: boolean;
  plan: SubscriptionPlanModel;
  isDealMirror?: boolean;
};

type UIConfig = {
  textColor: string;
  primaryColor: string;
  selectedColor: string;
  priceText: ReactNode;
  priceInfo: ReactNode;
  priceInfoSecondary: ReactNode;
};

export const PlanItem: FC<PlanItemProps> = ({
  plan,
  onSelect,
  isSelected,
  onRequestAdditionalSeats,
  isDealMirror,
}) => {
  const { subscriptionInfo } = useEntitySelectedProvider();
  const { additionalSeats } = useSelectSubscriptionFormProvider();
  const isCurrentPlan =
    subscriptionInfo.planId === plan.id &&
    !subscriptionInfo.isInTrial &&
    !subscriptionInfo.isDealMirror &&
    subscriptionInfo.status !== "canceled";
  const { getAdditionalUserPriceNote } = usePaymentStrings();
  const config: UIConfig = useMemo(() => {
    let textColor = "#16833B";
    let primaryColor = "#38C6A4";
    let selectedColor = "#38C6A4";
    let priceInfo: UIConfig["priceInfo"] = "";
    let priceInfoSecondary: UIConfig["priceInfoSecondary"] = "";

    switch (plan.planType) {
      case "pro": {
        priceInfo = `Fixed price for ${plan.seatsCount} first users/${
          plan.intervalType === "year" ? "year" : "month"
        }`;
        priceInfoSecondary = getAdditionalUserPriceNote(plan);
        break;
      }
      case "enterprise": {
        textColor = "#83167F";
        primaryColor = "#D28BEC";
        selectedColor = "#D28BEC";
        priceInfo = "Special offer to manage your business";
        priceInfoSecondary = "Contact us for more";
        break;
      }
      case "basic":
      default: {
        textColor = "#831651";
        primaryColor = "#F97EC0";
        selectedColor = "#E95CA8";
        priceInfo = `For a Team with up to ${plan.seatsCount} members`;
        priceInfoSecondary = isDealMirror
          ? "Lifetime Initial Plan"
          : "Free Initial Plan";
        break;
      }
    }

    return {
      primaryColor,
      textColor,
      priceText:
        plan.planType === "enterprise"
          ? "Get a quote"
          : priceDisplay(plan.price, plan.currency),
      priceInfo,
      priceInfoSecondary,
      selectedColor,
    };
  }, [plan, getAdditionalUserPriceNote, isDealMirror]);

  const $actionContent = useMemo(() => {
    if (plan.planType === "enterprise") {
      return (
        <div className="flex h-[40px] w-[200px] items-center justify-center rounded-lg bg-[#F2F2F4] px-8 text-sm font-medium tracking-[-0.14px]">
          Contact Us
        </div>
      );
    }
    if (!isSelected && !isCurrentPlan) {
      return (
        <div className="flex h-[40px] w-[200px] items-center justify-center rounded-lg bg-[#F2F2F4] px-8 text-sm font-medium tracking-[-0.14px]">
          {!subscriptionInfo.isInTrial &&
          plan.planType === "basic" &&
          subscriptionInfo.status !== "canceled"
            ? "Downgrade"
            : "Select"}
        </div>
      );
    }
    if (!isCurrentPlan) {
      return (
        <>
          {plan.seatsUpdateAvailable ? (
            <div className="flex h-[40px] w-full items-center justify-between text-sm font-medium leading-[150%]">
              <div>
                <div>Up to {plan.seatsCount} users</div>
                {!!additionalSeats && (
                  <div className="text-dark-600 font-normal">
                    + {additionalSeats} additional seats
                  </div>
                )}
              </div>
              <Tooltip
                placement="bottom-end"
                className="p-4"
                delay="none"
                renderTrigger={({ ref, props }) => (
                  <InteractiveContainer
                    ref={ref}
                    {...props}
                    className="text-primary-800 underline"
                    onClick={onRequestAdditionalSeats}
                  >
                    Add more
                  </InteractiveContainer>
                )}
              >
                You can add additional seats for your Workspace here 🚀
              </Tooltip>
            </div>
          ) : (
            <div className="font-secondary flex h-[40px] items-center justify-center font-medium leading-[150%]">
              Up to {plan.seatsCount} users 😍
            </div>
          )}
        </>
      );
    }
    return (
      <div className="font-secondary flex h-[40px] items-center justify-center font-medium leading-[150%]">
        Renew on{" "}
        {format(
          apiUTCToLocalDateTime(subscriptionInfo.planTill),
          "MMM dd, yyyy"
        )}
      </div>
    );
  }, [
    additionalSeats,
    isCurrentPlan,
    isSelected,
    onRequestAdditionalSeats,
    plan.planType,
    plan.seatsCount,
    plan.seatsUpdateAvailable,
    subscriptionInfo.isInTrial,
    subscriptionInfo.planTill,
    subscriptionInfo.status,
  ]);

  return (
    <div
      onClick={!isCurrentPlan ? () => onSelect(plan) : undefined}
      className={cx(
        "relative w-[340px] overflow-hidden rounded-xl bg-white px-4 pt-14 transition-all",
        isSelected ? "pb-[72px]" : "cursor-pointer pb-14"
      )}
      style={{ boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05)" }}
    >
      <div
        className="absolute top-0 left-0 z-10 h-3 w-full"
        style={{ backgroundColor: config.primaryColor }}
      />
      {isSelected === true && (
        <div
          className="absolute top-0 left-0 z-20 flex gap-1 rounded-br-xl px-3 py-2"
          style={{ backgroundColor: config.selectedColor }}
        >
          <span className="font-secondary text-xs font-semibold leading-[18px] text-white">
            Selected Plan
          </span>
          <CheckIcon />
        </div>
      )}
      {isCurrentPlan && (
        <div
          className="absolute top-0 left-0 z-20 flex gap-1 rounded-br-xl px-3 py-2"
          style={{ backgroundColor: config.selectedColor }}
        >
          <span className="font-secondary text-xs font-semibold leading-[18px] text-white">
            Current Plan
          </span>
          <CheckIcon />
        </div>
      )}
      <div className="flex flex-col items-center gap-8">
        <div
          className="font-secondary mb-1 text-center text-3xl font-semibold leading-[150%]"
          style={{ color: config.textColor }}
        >
          {plan.name}
        </div>
        <div className="text-center">
          <div className="font-primary text-[40px] font-semibold leading-[150%] tracking-[-0.4px]">
            {config.priceText}
          </div>
          <div className="mb-2 mt-1 text-sm font-medium leading-[150%] tracking-[-0.14px]">
            {config.priceInfo}
          </div>
          <div className="text-dark-600 text-sm leading-[150%] tracking-[-0.14px]">
            {config.priceInfoSecondary}
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex justify-center">{$actionContent}</div>
      <Divider />
      <FeatureList
        features={plan.features}
        isEnterprise={plan.planType === "enterprise"}
      />
    </div>
  );
};
