import { InventoryItem } from "@jugl-web/rest-api";
import React, { useCallback, useMemo } from "react";
import { useAppVariant } from "@jugl-web/utils";
import { ServiceCartDrawer } from "./components/ServiceCartDrawer";
import { ServiceCartPopover } from "./components/ServiceCartPopover";
import { OrderFormInventorySelectValueItem } from "../OrderFormInventorySelect";

export const ServiceCart: React.FC<{
  services: (InventoryItem & { discount?: { discount: number } })[];
  items: string[];
  isPreview?: boolean;
  discounts: Record<string, { discount: string; value: number }>;
  value: OrderFormInventorySelectValueItem[];
  onChange: (value: OrderFormInventorySelectValueItem[]) => void;
  currency: string;
}> = ({ items, services, value, currency, discounts, isPreview, onChange }) => {
  const { isMobile } = useAppVariant();

  const handleRemoveService = useCallback(
    (serivceId: string) =>
      onChange(value.filter((v) => v.inventory_id !== serivceId)),
    [value, onChange]
  );

  const handleUpdateService = useCallback(
    (serivceId: string, newValue: number) =>
      onChange(
        value.map((v) =>
          v.inventory_id === serivceId ? { ...v, qty: newValue } : v
        )
      ),
    [value, onChange]
  );

  const mappedItems = useMemo(
    () =>
      items
        .map((itemId) => ({
          itemId,
          item: services.find((service) => service.id === itemId),
        }))
        .filter((item) =>
          value.some(({ inventory_id }) => item.itemId === inventory_id)
        ),
    [items, services, value]
  );

  const totalCost = useMemo(
    () =>
      value.reduce((result, v) => {
        const item = services.find((i) => i.id === v.inventory_id);
        if (!item) {
          return result;
        }

        const discountValue = isPreview
          ? discounts[item?.id]?.value
          : item?.discount?.discount;

        if (discountValue) {
          result += parseFloat(item.price) * v.qty * (1 - discountValue / 100);
        } else {
          result += parseFloat(item.price) * v.qty;
        }

        return result;
      }, 0),
    [value, services, isPreview, discounts]
  );

  if (isMobile) {
    return (
      <ServiceCartDrawer
        currency={currency}
        discounts={discounts}
        isPreview={!!isPreview}
        items={mappedItems}
        value={value}
        total={totalCost}
        onRemoveService={handleRemoveService}
        onUpdateService={handleUpdateService}
        onChange={onChange}
      />
    );
  }

  return (
    <ServiceCartPopover
      currency={currency}
      discounts={discounts}
      isPreview={!!isPreview}
      items={mappedItems}
      value={value}
      total={totalCost}
      onRemoveService={handleRemoveService}
      onUpdateService={handleUpdateService}
      onChange={onChange}
    />
  );
};
