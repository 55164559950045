import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import {
  ChatMessageModel,
  ChatMessageType,
  ChatType,
} from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { useDispatch, useSelector } from "react-redux";
import { ChatMessageInfoDrawer } from "@web-src/modules/chats/components/ChatMessageInfoDrawer";
import {
  useEntityProvider,
  useEntitySelectedProvider,
} from "@web-src/modules/entities/providers/EntityProvider";
import { ChatInfoSideBar } from "@web-src/modules/chats/components/ChatInfoSideBar/ChatInfoSideBar";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import {
  selectOpenedUserProfile,
  setOpenedUserProfile,
} from "@web-src/modules/chats/store/chatsSlice";
import { useSendMessage } from "@web-src/modules/chats/hooks/useSendMessage";
import { useEditChatMessage } from "@web-src/modules/chats/hooks/useEditChatMessage";
import { ForwardChatMessageSideBar } from "@web-src/modules/chats/components/ForwardChatMessageSideBar";
import { DeleteChatMessageSideBar } from "@web-src/modules/chats/components/DeleteChatMessageSideBar";
import { ChatMessageSearchSidebar } from "@web-src/modules/chats/pages/ChatsPage/components/ChatMessageSearchSidebar";
import { ChatMessageBubbleAction } from "@web-src/modules/chats/components/ChatMessage/types/ChatMessageBubbleAction";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import { ChatMessageReactionsSideBar } from "@web-src/modules/chats/components/ChatMessageReactionsSideBar";
import { PeopleInfoSidebar } from "@web-src/modules/people/components/PeopleInfoSidebar";
import { ChatMessageProvider } from "@web-src/modules/chats/components/ChatMessage/providers/ChatMessageProvider";
import { ChatHeader } from "./components/ChatHeader";
import {
  ChatMessagesWindow,
  ChatMessagesWindowHandle,
  ChatMessagesWindowProvider,
} from "./components/ChatMessagesWindow";
import {
  ChatInput,
  ChatInputHandle,
  ChatInputParams,
} from "./components/ChatInput";
import { StickyIndexContextProvider } from "./contexts/StickyItemContext";
import { useChatsPageWithSelectedChatProvider } from "../../providers/ChatsPageProvider";
import { ReactComponent as BullhornIcon } from "./assets/bullhorn.svg";
import { MessageActionItem } from "./components/MessageActionItem";

export const ChatWindow: React.FC = () => {
  const dispatch = useDispatch();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();

  const openedUserProfile = useSelector(selectOpenedUserProfile);

  const { chat, isParticipant } = useActiveChat();
  const { subscriptionInfo } = useEntityProvider();
  const { addNewMessage } = useConversations();
  const sendMessage = useSendMessage();
  const editMessage = useEditChatMessage();

  const $inputRef = useRef<ChatInputHandle>(null);
  const $chatWindowRef = useRef<ChatMessagesWindowHandle>(null);
  const [isSending, setIsSending] = useState<boolean>();

  const {
    activeChatState,
    resetMessageAction,
    activeChatId,
    closeMessageSideBar,
    openSideBar,
    closeSideBar,
  } = useChatsPageWithSelectedChatProvider();
  const chatMessageAction = activeChatState?.messageAction;
  useEffect(() => {
    // Finish the message action if the user is in the
    // middle of it and the chat settings have been updated
    if (chat?.role === "admin" || !activeChatState?.messageAction) return;

    const broadcastChatRestrictedActions = [
      ChatMessageBubbleAction.DELETE,
      ChatMessageBubbleAction.EDIT,
      ChatMessageBubbleAction.REPLY,
    ];

    const contentProtectionRestrictedActions = [
      ChatMessageBubbleAction.FORWARD,
    ];

    if (
      (chat?.config?.admin_only_msg &&
        broadcastChatRestrictedActions.includes(
          activeChatState.messageAction.type
        )) ||
      (chat?.config?.content_protected &&
        contentProtectionRestrictedActions.includes(
          activeChatState.messageAction.type
        ))
    ) {
      resetMessageAction(activeChatId);
    }
  }, [activeChatId, activeChatState, chat, resetMessageAction]);

  useEffect(() => {
    // Focus the input field if the user is in the middle of an action
    if (!activeChatState?.messageAction) {
      return;
    }
    const actionType = activeChatState.messageAction.type;
    const actionMessage = activeChatState.messageAction.messages[0];
    if (actionType === ChatMessageBubbleAction.EDIT && actionMessage) {
      $inputRef.current?.setValue(
        actionMessage.payload?.body ||
          actionMessage.payload?.attachments?.[0]?.caption ||
          ""
      );
    }
    if (
      [ChatMessageBubbleAction.EDIT, ChatMessageBubbleAction.REPLY].includes(
        actionType
      )
    ) {
      $inputRef.current?.focus();
    }
  }, [activeChatState]);

  const handleSendClick: ChatInputParams["onSubmit"] = useCallback(
    async ({ body, file, gif, attachmentType, conference }) => {
      const chatId = chat?.id;
      if (!chatId) {
        return;
      }
      if (
        chatMessageAction?.type === ChatMessageBubbleAction.EDIT &&
        chatMessageAction.messages[0]
      ) {
        const messageToEdit = chatMessageAction.messages[0];
        setIsSending(true);
        editMessage({
          msgId: messageToEdit.msg_id,
          body,
          attachment: messageToEdit.payload?.attachments?.[0],
          chatId,
        })
          .then(() => {
            resetMessageAction(chatId);
            $inputRef?.current?.clear();
          })
          .finally(() => {
            setIsSending(false);
          });
        return;
      }
      sendMessage({
        to: chatId,
        type:
          chat.type === ChatType.muc
            ? ChatMessageType.muc
            : ChatMessageType.chat,
        body,
        file,
        replyToMessage:
          chatMessageAction?.type === ChatMessageBubbleAction.REPLY
            ? chatMessageAction.messages[0]
            : undefined,
        gif,
        attachmentType,
        conference,
      }).then((item) => {
        if (item.type === "success") {
          if (!chat.id) {
            return;
          }
          addNewMessage?.({
            id: chat.id,
            message: item.message as ChatMessageModel,
            outgoing: true,
          });
          $chatWindowRef.current?.scrollToBottom();
        }
      });
      if (subscriptionInfo?.actionsAllowed) {
        $inputRef?.current?.clear();
      }
      if (chatMessageAction) {
        resetMessageAction(chatId);
      }
      setTimeout(() => {
        $chatWindowRef.current?.scrollToBottom();
      }, 100);
    },
    [
      addNewMessage,
      chat,
      chatMessageAction,
      editMessage,
      resetMessageAction,
      sendMessage,
      subscriptionInfo?.actionsAllowed,
    ]
  );

  const { profile } = useUserGeneralProfile({
    entityId,
    userId: activeChatId,
    skip: !chat?.id || chat.type !== ChatType.chat,
  });

  const hasNotUserAccessToChats =
    profile && chat?.type === ChatType.chat
      ? !hasRoleAccessToModule(profile.role, AppModule.chat)
      : false;

  const hasBroadcastChatRestriction =
    chat?.config?.admin_only_msg && chat.role !== "admin";

  const isChatDisabled = hasNotUserAccessToChats || hasBroadcastChatRestriction;

  const closeUserProfile = useCallback(() => {
    dispatch(setOpenedUserProfile());
  }, [dispatch]);

  const showChatSideBar = useMemo(
    () =>
      activeChatState?.openedSideBar?.type === "chatInfo" &&
      (chat?.type === ChatType.chat ||
        (chat?.type === ChatType.muc && isParticipant)),
    [activeChatState?.openedSideBar?.type, chat?.type, isParticipant]
  );

  const $restrictionBottomInfo = useMemo(() => {
    if (hasNotUserAccessToChats) {
      return (
        <div className="flex min-h-[69px] w-full items-center justify-center px-5 py-2 text-center">
          <span className="text-dark font-secondary text-sm leading-[21px]">
            {t(
              {
                id: "chats-page.role-permission-chat-no-access-description",
                defaultMessage:
                  "📛 Due to <highlightedText>Roles Permissions</highlightedText>, this user don't have access to the Chats and cant receive any New Messages or Calls 🙅‍♀️",
              },
              {
                highlightedText: (text: (string | JSX.Element)[]) => (
                  <span className="text-primary-800 font-semibold">{text}</span>
                ),
              }
            )}
          </span>
        </div>
      );
    }

    if (hasBroadcastChatRestriction) {
      return (
        <div className="flex w-full items-center justify-center gap-2 px-4 py-2 text-center">
          <BullhornIcon />
          <span className="text-dark-800 font-secondary text-sm leading-[21px]">
            {t({
              id: "chats-page.only-admins-can-send-messages-description",
              defaultMessage: "Only Admins can send messages in Broadcast Chat",
            })}
          </span>
        </div>
      );
    }

    return null;
  }, [hasNotUserAccessToChats, hasBroadcastChatRestriction, t]);

  if (!chat) {
    return null;
  }
  return (
    <>
      <MultiSectionLayout
        className="flex-1"
        classNames={{ content: "bg-gray-100 relative" }}
        header={
          <ChatHeader onClick={() => openSideBar(activeChatId, "chatInfo")} />
        }
        footer={
          <>
            {!chat.deleted &&
              chat?.id &&
              (chat.type === ChatType.chat || isParticipant) && (
                <div className="border-t-1 border-0 border-t border-solid border-gray-200 px-4 py-2 shadow">
                  {activeChatState?.messageAction &&
                  activeChatState.messageAction.messages.length &&
                  [
                    ChatMessageBubbleAction.REPLY,
                    ChatMessageBubbleAction.EDIT,
                  ].includes(activeChatState.messageAction.type) ? (
                    <ChatMessageProvider
                      message={activeChatState.messageAction.messages[0]}
                      chatId={chat.id}
                      forceIncomingMode
                      isMessageActionsDisabled
                    >
                      <MessageActionItem
                        message={activeChatState.messageAction.messages[0]}
                        action={activeChatState.messageAction.type}
                        onCancel={() => {
                          resetMessageAction(activeChatId);
                          if (
                            activeChatState?.messageAction?.type ===
                            ChatMessageBubbleAction.EDIT
                          ) {
                            $inputRef.current?.clear();
                          }
                        }}
                      />
                    </ChatMessageProvider>
                  ) : undefined}
                  {chat?.id && !isChatDisabled && (
                    <ChatInput
                      chatId={chat.id}
                      chatType={chat.type}
                      onSubmit={handleSendClick}
                      ref={$inputRef}
                      isLoading={isSending}
                      disableAttachments={
                        chatMessageAction?.type === ChatMessageBubbleAction.EDIT
                      }
                      disableAudioMessage={
                        chatMessageAction?.type === ChatMessageBubbleAction.EDIT
                      }
                    />
                  )}
                  {$restrictionBottomInfo}
                </div>
              )}
          </>
        }
        rightSide={
          <>
            {(() => {
              if (chatMessageAction?.type === ChatMessageBubbleAction.DELETE) {
                return (
                  <DeleteChatMessageSideBar
                    messages={chatMessageAction.messages}
                    onRequestClose={() => {
                      resetMessageAction(activeChatId);
                    }}
                  />
                );
              }
              if (chatMessageAction?.type === ChatMessageBubbleAction.FORWARD) {
                return (
                  <ForwardChatMessageSideBar
                    messages={chatMessageAction.messages}
                    onRequestClose={() => {
                      resetMessageAction(activeChatId);
                    }}
                  />
                );
              }
              if (activeChatState?.openedSideBar?.type === "search") {
                return <ChatMessageSearchSidebar />;
              }
              if (showChatSideBar && !chat.deleted) {
                return (
                  <ChatInfoSideBar onClose={() => closeSideBar(activeChatId)} />
                );
              }
              return null;
            })()}
          </>
        }
      >
        <StickyIndexContextProvider>
          <ChatMessagesWindowProvider>
            <ChatMessagesWindow
              key={chat.id}
              ref={$chatWindowRef}
              chat={chat}
              disableMessageActions={chat?.type === "muc" && !isParticipant}
            />
          </ChatMessagesWindowProvider>
        </StickyIndexContextProvider>
      </MultiSectionLayout>
      <ChatMessageInfoDrawer
        message={activeChatState?.openedMessageSideBar?.message}
        isOpen={activeChatState?.openedMessageSideBar?.type === "messageInfo"}
        groupId={chat?.type === ChatType.muc ? chat?.id : undefined}
        onRequestClose={closeMessageSideBar}
      />
      <ChatMessageReactionsSideBar
        message={activeChatState?.openedMessageSideBar?.message}
        isOpen={activeChatState?.openedMessageSideBar?.type === "reactions"}
        onRequestClose={closeMessageSideBar}
      />
      <PeopleInfoSidebar
        userId={openedUserProfile}
        isOpen={!!openedUserProfile}
        onRequestClose={closeUserProfile}
      />
    </>
  );
};
