import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import AdminReportsPage from "./AdminReportsPage";
import ScheduledReportsFormPage from "./ScheduledReportsFormPage";
import ScheduledReportsPage from "./ScheduledReportsPage";

export type ReportsPagesNavigationParams = {
  reportsTasks: PageNavParams;
  reportsTimeClock: PageNavParams;
  reportsScheduled: PageNavParams;
  reportsScheduledForm: PageNavParams;
  reportsScheduledFormEdit: PageNavParams<{ reportId: string }>;
};

export const reportsPagesNavigationConfig: ModulePagesConfig<ReportsPagesNavigationParams> =
  {
    reportsTasks: {
      element: <AdminReportsPage />,
      path: "reports/tasks",
      isPrivate: true,
      requiredSubscriptionModule: SubscriptionPlanModuleId.reports,
      requiredUserRoles: appModulesToRequiredUserRoles.taskReports,
    },
    reportsTimeClock: {
      element: <AdminReportsPage />,
      path: "reports/timeclock",
      isPrivate: true,
      requiredSubscriptionModule: SubscriptionPlanModuleId.reports,
      requiredUserRoles: appModulesToRequiredUserRoles.timeClockReports,
    },
    reportsScheduled: {
      element: <ScheduledReportsPage />,
      path: "scheduled-reports",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.scheduledReports,
    },
    reportsScheduledForm: {
      element: <ScheduledReportsFormPage />,
      path: "scheduled-reports-form",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.scheduledReports,
    },
    reportsScheduledFormEdit: {
      element: <ScheduledReportsFormPage />,
      path: "scheduled-reports-form/:reportId",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.scheduledReports,
    },
  };
