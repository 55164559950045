import { useCallback, useContext } from "react";
import {
  PheonixPushAction,
  SendMessagePheonixResponse,
} from "@web-src/modules/chats/types";
import {
  ChatType,
  ChatMessagePayloadAttachment,
  ChatMessagePayload,
  ChatMessageModel,
  ChatMessagePayloadPushType,
  ChatMessagePayloadType,
  ChatMessageType,
  ChatMessageAttachmentType,
} from "@jugl-web/rest-api";
import { PhoenixSocketContext } from "@web-src/modules/socket/providers/PhoenixSocket";
import { useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";
import { selectUserId } from "@web-src/features/auth/authSlice";
import {
  getFileSizeByUrl,
  getVideoDimensionsByUrl,
  getImageDimensionsByUrl,
  useTranslations,
  getAudioWaveform,
} from "@jugl-web/utils";
import { useTusUpload } from "@jugl-web/domain-resources/files/hooks/useTusUpload";
import { FilesModule } from "@jugl-web/domain-resources/files/types";
import { useUpdateChatMessages } from "@web-src/modules/chats/hooks/useUpdateChatMessages";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  getAttachmentTypeByMimeType,
  messageToConversationLastMessage,
} from "@web-src/modules/chats/utils";
import { getAudioDuration } from "@jugl-web/utils/utils/files";

type SendMessageProps = {
  to: string;
  body?: string;
  clientMsgId?: string;
  type: ChatMessageType;
  extraPayload?: Partial<ChatMessageModel["payload"]>;
  file?: File;
  replyToMessage?: ChatMessageModel;
  forwarded?: boolean;
  gif?: string;
  attachmentType?: ChatMessageAttachmentType;
  conference?: ChatMessagePayload["conference"];
};

type SendMessageResponseType =
  | "success"
  | "error"
  | "timeout"
  | "not-allowed"
  | "file-upload-error";

interface SendMessageResponse {
  type: SendMessageResponseType;
  message?: Partial<ChatMessageModel>;
  retryFileUpload?: () => void;
  resend?: (timeout: number) => void;
  error?: unknown;
}

export const useSendMessage = () => {
  const { channel } = useContext(PhoenixSocketContext);
  const { entity, showWriteRestrictedAlert, subscriptionInfo } =
    useEntityProvider();
  const meId = useSelector(selectUserId);
  const { profile } = useMe();
  const { addMessages, changeMessageId, updateMessage } =
    useUpdateChatMessages();
  const { conversations, updateItem } = useConversations();
  const { uploadFile } = useTusUpload({
    entityId: entity?.id,
    module: FilesModule.attachment,
  });
  const { t } = useTranslations();

  const sendMessage = useCallback(
    async (props: SendMessageProps): Promise<SendMessageResponse> => {
      if (!subscriptionInfo?.actionsAllowed) {
        showWriteRestrictedAlert();
        return { type: "not-allowed" };
      }
      const {
        to,
        body,
        type,
        extraPayload,
        clientMsgId,
        file,
        replyToMessage,
        gif,
        attachmentType,
        conference,
      } = props;
      const chat = conversations.find((item) => item.id === to)?.data;
      const chatName =
        chat?.type === ChatType.chat ? profile?.displayName || "" : chat?.title;

      if (!entity?.id) {
        throw new Error("No active entity");
      }
      if (!channel) {
        throw new Error("No channel");
      }

      let attachment: ChatMessagePayloadAttachment | undefined;
      if (file) {
        const attachmentUrl = URL.createObjectURL(file);
        const fileType =
          attachmentType || getAttachmentTypeByMimeType(file.type);
        const amps =
          fileType === ChatMessageAttachmentType.audio ||
          fileType === ChatMessageAttachmentType.voice
            ? await getAudioWaveform(file)
            : undefined;
        attachment = {
          type: attachmentType || getAttachmentTypeByMimeType(file.type),
          name: file.name,
          size: file.size,
          amps,
          mimetype: file.type,
          caption: body,
          uid: "",
          _progress: 0,
          _local_url: URL.createObjectURL(file),
        };
        if (file.type.startsWith("image")) {
          const dimensions = await getImageDimensionsByUrl(attachmentUrl);
          attachment.width = dimensions.width;
          attachment.height = dimensions.height;
        } else if (file.type.startsWith("video")) {
          const dimensions = await getVideoDimensionsByUrl(attachmentUrl);
          attachment.width = dimensions.width;
          attachment.height = dimensions.height;
        } else if (file.type.startsWith("audio")) {
          try {
            attachment.duration = await getAudioDuration(file);
          } catch (error) {
            // skip
          }
        }
      }
      if (gif) {
        attachment = {
          url: gif,
          type: ChatMessageAttachmentType.gifDirectUrl,
          name: "gif",
          size: await getFileSizeByUrl(gif),
          mimetype: "image/gif",
          ...(await getImageDimensionsByUrl(gif)),
          uid: "",
        };
      }

      const tempMsgId = clientMsgId || `temp-${uuid4()}`;
      const messageToSend: Partial<ChatMessageModel> = {
        to,
        from: meId,
        type,
        entity_id: entity?.id,
        payload: {
          version: 1,
          push_type: ChatMessagePayloadPushType.silent,
          title:
            chatName ||
            t({
              id: "chats-page.new-message",
              defaultMessage: "New Message",
            }),
          type: ChatMessagePayloadType.message,
          body: attachment?.caption ? undefined : body,
          attachments: attachment ? [attachment] : undefined,
          ...extraPayload,
          client_msg_id: tempMsgId,
        },
      };

      if (replyToMessage && messageToSend.payload) {
        messageToSend.payload.reply_attributes_map = {
          reply_msg_id: replyToMessage.msg_id,
          reply_msg_body: replyToMessage.payload.body,
          reply_msg_user: replyToMessage.from,
          reply_conference: replyToMessage.payload.conference,
          reply_attachments: replyToMessage.payload.attachments?.map((item) => {
            // Remove local stuff
            const result = { ...item };
            delete result?._local_url;
            delete result?._progress;
            delete result?._preview_url;
            delete result?._stream_url;
            return result;
          }),
        };
      }

      if (conference && conference.channel && messageToSend.payload) {
        messageToSend.payload.conference = conference;
      }

      if (chat?.id && messageToSend?.payload?.type !== "call") {
        addMessages({
          chatId: chat.id,
          skipIfNotInitialized: true,
          itemsToAdd: [
            {
              id: tempMsgId,
              data: {
                // Copy to be able to modify inner objects later
                ...JSON.parse(JSON.stringify(messageToSend)),
                timestamp: new Date().toISOString().replace("T", " "),
                is_read_by_self: true,
                _pending: true,
              },
            },
          ],
        });
      }

      const messageAttachment = messageToSend.payload?.attachments?.[0];
      if (file && messageAttachment) {
        try {
          const fileResponse = await uploadFile({
            file,
            addPath: true,
            onProgress: (bytesSend, bytesTotal) => {
              if (!messageAttachment) {
                return;
              }
              if (chat?.id) {
                updateMessage({
                  chatId: chat.id,
                  merge: true,
                  item: {
                    id: tempMsgId,
                    data: {
                      payload: {
                        client_msg_id: tempMsgId,
                        attachments: [
                          {
                            ...messageAttachment,
                            _progress: Math.round(
                              (bytesSend / bytesTotal) * 100
                            ),
                          },
                        ],
                      },
                    },
                  },
                });
              }
            },
          });
          if (
            messageAttachment.type !== ChatMessageAttachmentType.gifDirectUrl
          ) {
            messageAttachment.url = fileResponse.url;
            messageAttachment.uid = fileResponse.uid;
          }
        } catch (error) {
          if (chat?.id) {
            updateMessage({
              chatId: chat.id,
              merge: true,
              item: {
                id: tempMsgId,
                data: {
                  payload: {
                    client_msg_id: tempMsgId,
                    attachments: [
                      {
                        ...messageAttachment,
                        _error: true,
                      },
                    ],
                  },
                  _error: true,
                },
              },
            });
          }
          return {
            type: "file-upload-error",
            message: messageToSend,
            retryFileUpload: () =>
              sendMessage({ ...props, clientMsgId: tempMsgId }),
          };
        }
      }

      if (messageToSend.payload?.attachments) {
        // Remove local stuff
        messageToSend.payload.attachments =
          messageToSend.payload.attachments.map((item) => {
            const result = { ...item };
            delete result?._local_url;
            delete result?._progress;
            delete result?._preview_url;
            delete result?._stream_url;
            return result;
          });
      }

      const message = channel.push(PheonixPushAction.transport, messageToSend);

      return new Promise((resolve) => {
        message.receive("ok", (e: SendMessagePheonixResponse) => {
          if (chat?.id) {
            updateItem(
              {
                id: chat.id,
                data: {
                  lastMessage: messageToConversationLastMessage(
                    messageToSend as ChatMessageModel
                  ),
                },
              },
              true
            );
            changeMessageId({
              chatId: chat.id,
              oldId: tempMsgId,
              newId: e.msg_id,
            });
          }
          if (chat?.id) {
            updateMessage({
              chatId: chat.id,
              merge: true,
              item: {
                id: e.msg_id,
                data: { msg_id: e.msg_id, _pending: false, _error: false },
              },
            });
          }
          resolve({
            type: "success",
            message: messageToSend,
            resend: message.resend,
          });
        });
        const handleError = () => {
          if (chat?.id) {
            updateMessage({
              chatId: chat.id,
              merge: true,
              item: {
                id: tempMsgId,
                data: { _pending: false, _error: true },
              },
            });
          }
        };
        message.receive("error", (error) => {
          handleError();
          resolve({
            type: "error",
            error,
            message: messageToSend,
            resend: message.resend,
          });
        });
        message.receive("timeout", (error) => {
          handleError();
          resolve({
            type: "timeout",
            error,
            message: messageToSend,
            resend: message.resend,
          });
        });
      });
    },
    [
      t,
      entity,
      conversations,
      channel,
      meId,
      showWriteRestrictedAlert,
      addMessages,
      uploadFile,
      updateMessage,
      updateItem,
      changeMessageId,
      profile,
      subscriptionInfo?.actionsAllowed,
    ]
  );
  return sendMessage;
};
