import { cx } from "@jugl-web/utils";
import { forwardRef, ReactNode } from "react";
import { PlainButton } from "../../PlainButton";
import { ReactComponent as ArrowUpIcon } from "./assets/chevron.svg";

export interface SelectTriggerProps {
  label: ReactNode;
  isOpen?: boolean;
  hasValue?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  customIcon?: ReactNode;
  className?: string;
}

export const SelectTrigger = forwardRef<HTMLButtonElement, SelectTriggerProps>(
  (
    {
      label,
      className,
      isOpen,
      hasValue,
      isDisabled,
      isInvalid,
      customIcon,
      ...props
    },
    ref
  ) => (
    <PlainButton
      {...props}
      ref={ref}
      disabled={isDisabled}
      className={cx(
        "border-grey-400 flex w-full items-center justify-between gap-2 overflow-hidden rounded-[10px] border border-solid px-5 py-4 transition-colors focus-within:border-transparent",
        "focus-within:border-[#90C9F9] focus-within:outline-none focus-within:ring-1 focus-within:ring-[#90C9F9] hover:border-[#90C9F9] hover:ring-1 hover:ring-[#90C9F9] focus:border-[#90C9F9] focus:outline-none",
        isOpen && "border-[#90C9F9]",
        isDisabled &&
          "bg-grey-[#FAFAFA] border-grey-400 hover:border-grey-400 border-dashed",
        isInvalid &&
          "border-gradients-danger focus-within:ring-gradients-danger hover:border-gradients-danger focus-within:border-gradients-danger border-solid",
        className
      )}
    >
      <span
        className={cx(
          "font-secondary truncate text-base leading-[24px]",
          !hasValue && "text-dark-600"
        )}
      >
        {label}
      </span>
      {customIcon || (
        <ArrowUpIcon
          className={cx(
            "shrink-0 transition-transform",
            !isOpen && "rotate-180"
          )}
        />
      )}
    </PlainButton>
  )
);
