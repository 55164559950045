import { format } from "date-fns";

import {
  PaginatedResponse,
  PaginatedRequestParams,
  RtkEmptySplitApi,
  RtkBaseQuery,
} from "../../types";
import { DiscountItem, InventoryItemParams, WarehouseItem } from "./types";
import {
  InventoryItemDataDto,
  transformInventoryItemDtoToModel,
} from "./models/InventoryItem";
import { InventoryApiTags } from "./tags";
import {
  DiscountItemDataDto,
  transformDiscountItemDtoToModel,
} from "./models/DiscountItem";
import { stringifyDimensionValue } from "./utils";
import { fetchAllPages } from "../../utils";
import {
  transformWarehouseItemDtoToModel,
  WarehouseItemDataDto,
} from "./models/WarehouseItem";
import {
  InventoryLogDataDto,
  transformInventoryLogsDtoToModel,
} from "./models/InventoryLogs";

export const addInventoryApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [
      InventoryApiTags.inventory,
      InventoryApiTags.discount,
      InventoryApiTags.warehouse,
    ],
  });
  const inventoryApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getInventoryItemsList: build.query<
        PaginatedResponse<InventoryItemDataDto>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            search?: string;
            invoice_id?: string;
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory`,
          params,
        }),
        transformResponse: (
          response: PaginatedResponse<InventoryItemDataDto>
        ) => ({
          ...response,
          data: response.data.map((item) =>
            transformInventoryItemDtoToModel(item)
          ),
        }),
        providesTags: [InventoryApiTags.inventory],
      }),
      getInventoryItem: build.query<
        InventoryItemDataDto,
        {
          entityId: string;
          itemId: string;
          silentError?: boolean;
        }
      >({
        query: ({ entityId, itemId, silentError }) => ({
          url: `/api/auth/${entityId}/inventory/${itemId}`,
          silentError,
        }),
        transformResponse: (response: InventoryItemDataDto) => ({
          ...response,
          data: transformInventoryItemDtoToModel(response),
        }),
        providesTags: (e1, e2, { itemId }) => [
          { type: InventoryApiTags.inventory, id: itemId },
        ],
      }),
      addInventoryItem: build.mutation<
        InventoryItemDataDto,
        { entityId: string; params: Partial<InventoryItemParams> }
      >({
        query: ({ entityId, params }) => {
          const formData = new FormData();
          const isItem = params.category === "item";
          formData.set(
            "inventory",
            JSON.stringify({
              name: params.inventoryItemName,
              category: params.category,
              price: params.price?.value,
              unit: params.unit,
              desc: params.description,
              info: {
                dim: isItem ? stringifyDimensionValue(params.dimensions) : null,
                weight:
                  params.category === "item" && params.weight?.value
                    ? `${params.weight?.value} ${params.weight?.unit}`
                    : null,
                sku: isItem ? params.sku : null,
                upc: isItem ? params.upc : null,
                mpn: isItem ? params.mpn : null,
                ean: isItem ? params.ean : null,
                isbn: isItem ? params.isbn : null,
              },
              stock:
                params.category === "item"
                  ? {
                      count: params.stock?.toString()
                        ? Number(params.stock)
                        : undefined,
                      notes: "opening_stock",
                      reorder_point: params.reorderPoint?.toString()
                        ? Number(params.reorderPoint)
                        : undefined,
                    }
                  : undefined,
              warehouse: params.warehouse?.id,
            })
          );

          if (params.files) {
            if (Array.isArray(params.files)) {
              params.files.forEach((file) => {
                formData.append(`files[]`, file);
              });
            } else {
              formData.append("files[]", params.files);
            }
          }
          return {
            url: `/api/auth/${entityId}/inventory`,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          };
        },
        transformResponse: (response: InventoryItemDataDto) => ({
          ...response,
          data: transformInventoryItemDtoToModel(response),
        }),
      }),
      updateInventoryItem: build.mutation<
        InventoryItemDataDto,
        {
          entityId: string;
          params: Partial<InventoryItemParams>;
          id: string;
          isAdjustStock?: boolean;
          isItem?: boolean;
        }
      >({
        query: ({ entityId, params, id, isAdjustStock, isItem }) => {
          const formData = new FormData();
          const keys = Object.keys(params);

          const itemInfo = {
            dim: keys.includes("dimensions")
              ? stringifyDimensionValue(params.dimensions)
              : undefined,

            weight:
              keys.includes("weight") && params.weight?.value?.toString()
                ? `${params.weight?.value} ${params.weight?.unit}`
                : keys.includes("weight") && !params.weight?.value
                ? null
                : undefined,
            sku: keys.includes("sku") ? params.sku || null : undefined,
            upc: keys.includes("upc") ? params.upc || null : undefined,
            mpn: keys.includes("mpn") ? params.mpn || null : undefined,
            ean: keys.includes("ean") ? params.ean || null : undefined,
            isbn: keys.includes("isbn") ? params.isbn || null : undefined,
          };
          const infoHasAnyValue = Object.values(itemInfo).some(
            (value) => value !== undefined
          );

          formData.set(
            "inventory",
            JSON.stringify({
              name: params.inventoryItemName,
              category: params.category,
              price: params.price?.value,
              unit: params.unit,
              desc: params.description,
              info: isAdjustStock
                ? undefined
                : isItem && infoHasAnyValue
                ? itemInfo
                : undefined,
              stock:
                (isItem || isAdjustStock) &&
                (params.stock?.toString() || params.reorderPoint?.toString())
                  ? {
                      count: params.stock?.toString()
                        ? Number(params.stock)
                        : undefined,
                      notes:
                        params.stock?.toString() ||
                        params.reorderPoint?.toString()
                          ? `updated on ${format(new Date(), "MMM dd")}`
                          : undefined,
                      reorder_point: params.reorderPoint?.toString()
                        ? Number(params.reorderPoint)
                        : undefined,
                    }
                  : undefined,
              warehouse: params.warehouse?.id,
            })
          );

          if (params.files) {
            if (Array.isArray(params.files)) {
              params.files.forEach((file) => {
                formData.append(`files[]`, file);
              });
            } else {
              formData.append("files[]", params.files);
            }
          }
          return {
            url: `/api/auth/${entityId}/inventory/${id}`,
            method: "PUT",
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          };
        },
        invalidatesTags: (e0, e1, { id }) => [
          InventoryApiTags.inventory,
          { type: InventoryApiTags.inventory, id },
        ],
      }),
      deleteInventoryItem: build.mutation<
        void,
        {
          entityId: string;
          itemId: string;
        }
      >({
        query: ({ entityId, itemId }) => ({
          url: `/api/auth/${entityId}/inventory/${itemId}`,
          method: "DELETE",
        }),
      }),
      updateAttachmentsOrder: build.mutation<
        void,
        {
          entityId: string;
          params: {
            ids: { id: string; order: number }[];
          };
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory/img/order`,
          method: "POST",
          data: params,
        }),
      }),
      deleteInventoryAttachment: build.mutation<
        void,
        {
          entityId: string;
          imgId: string;
        }
      >({
        query: ({ entityId, imgId }) => ({
          url: `/api/auth/${entityId}/inventory/img/${imgId}`,
          method: "DELETE",
        }),
      }),
      addDiscount: build.mutation<
        DiscountItem,
        {
          entityId: string;
          params: {
            name: string;
            discount: number;
            desc: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory/discount`,
          method: "POST",
          data: params,
        }),
      }),
      updateDiscount: build.mutation<
        DiscountItem,
        {
          entityId: string;
          itemId: string;
          params: Partial<{
            name: string;
            discount: number;
            desc: string;
            status: "active" | "inactive";
          }>;
        }
      >({
        query: ({ entityId, itemId, params }) => ({
          url: `/api/auth/${entityId}/inventory/discount/${itemId}`,
          method: "PUT",
          data: params,
        }),
      }),
      getDiscountItem: build.query<
        DiscountItemDataDto,
        {
          entityId: string;
          id: string;
        }
      >({
        query: ({ entityId, id }) => ({
          url: `/api/auth/${entityId}/inventory/discount/${id}`,
        }),
        transformResponse: (response: DiscountItemDataDto) =>
          transformDiscountItemDtoToModel(response),
        providesTags: [InventoryApiTags.inventory],
      }),
      getDiscountItemsList: build.query<
        PaginatedResponse<DiscountItemDataDto>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            search?: string;
            status?: "active" | "inactive";
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory/discount`,
          params,
        }),
        transformResponse: (
          response: PaginatedResponse<DiscountItemDataDto>
        ) => ({
          ...response,
          data: response.data.map((item) =>
            transformDiscountItemDtoToModel(item)
          ),
        }),
        providesTags: [InventoryApiTags.inventory],
      }),
      deleteDiscountItem: build.mutation<
        void,
        {
          entityId: string;
          itemId: string;
        }
      >({
        query: ({ entityId, itemId }) => ({
          url: `/api/auth/${entityId}/inventory/discount/${itemId}`,
          method: "DELETE",
        }),
      }),

      getAllDiscounts: build.query<
        DiscountItemDataDto[],
        {
          entityId: string;
        }
      >({
        queryFn: async ({ entityId }, _queryApi, _extraOptions, baseQuery) => {
          try {
            const getAllDiscounts = await fetchAllPages<DiscountItemDataDto>(
              `/api/auth/${entityId}/inventory/discount`,
              {
                page: 1,
                page_size: 10,
              },
              baseQuery as unknown as RtkBaseQuery
            );
            return {
              data: getAllDiscounts.map(transformDiscountItemDtoToModel),
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            return { error };
          }
        },
      }),
      getWarehouseList: build.query<
        PaginatedResponse<WarehouseItemDataDto>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            search?: string;
            status?: "active" | "inactive";
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory/warehouse`,
          params,
        }),
        transformResponse: (
          response: PaginatedResponse<WarehouseItemDataDto>
        ) => ({
          ...response,
          data: response.data.map((item) =>
            transformWarehouseItemDtoToModel(item)
          ),
        }),
        providesTags: [InventoryApiTags.warehouse],
      }),
      addWarehouse: build.mutation<
        WarehouseItemDataDto,
        { entityId: string; params: Omit<WarehouseItem, "status" | "id"> }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory/warehouse`,
          method: "POST",
          data: params,
        }),
        transformResponse: (response: WarehouseItemDataDto) =>
          transformWarehouseItemDtoToModel(response),
      }),
      updateWarehouse: build.mutation<
        WarehouseItemDataDto,
        {
          entityId: string;
          warehouseId: string;
          params: Partial<Omit<WarehouseItem, "id">>;
        }
      >({
        query: ({ entityId, warehouseId, params }) => ({
          url: `/api/auth/${entityId}/inventory/warehouse/${warehouseId}`,
          method: "PUT",
          data: params,
        }),
        transformResponse: (response: WarehouseItemDataDto) =>
          transformWarehouseItemDtoToModel(response),
      }),
      deleteWarehouse: build.mutation<
        void,
        {
          entityId: string;
          warehouseId: string;
        }
      >({
        query: ({ entityId, warehouseId }) => ({
          url: `/api/auth/${entityId}/inventory/warehouse/${warehouseId}`,
          method: "DELETE",
        }),
      }),
      getInventoryLogsList: build.query<
        PaginatedResponse<InventoryLogDataDto>,
        {
          entityId: string;
          itemId: string;
          params: PaginatedRequestParams;
        }
      >({
        query: ({ entityId, itemId, params }) => ({
          url: `/api/auth/${entityId}/inventory/logs/${itemId}`,
          params,
        }),
        transformResponse: (
          response: PaginatedResponse<InventoryLogDataDto>
        ) => ({
          ...response,
          data: response.data.map((item) =>
            transformInventoryLogsDtoToModel(item)
          ),
        }),
        providesTags: [InventoryApiTags.warehouse],
      }),
      getAllWarehouses: build.query<
        WarehouseItemDataDto[],
        {
          entityId: string;
        }
      >({
        queryFn: async ({ entityId }, _queryApi, _extraOptions, baseQuery) => {
          try {
            const getAllWarehouses = await fetchAllPages<WarehouseItemDataDto>(
              `/api/auth/${entityId}/inventory/warehouse`,
              {
                page: 1,
                page_size: 10,
              },
              baseQuery as unknown as RtkBaseQuery
            );
            return {
              data: getAllWarehouses.map(transformWarehouseItemDtoToModel),
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            return { error };
          }
        },
      }),
    }),
  });

  return inventoryApi;
};

export type InventoryApi = ReturnType<typeof addInventoryApi>;
