import { Alert } from "@jugl-web/ui-components/cross-platform";
import { FC, ReactNode, useEffect, useState } from "react";
import { SeatCountSelect } from "../SeatCountSelect";

export type SeatsUpdateAlertProps = {
  isOpen: boolean;
  onSubmit: (count: number) => void;
  onRequestClose: () => void;
  title?: string;
  defaultCount?: number;
  minCount?: number;
  priceLabel?: ReactNode;
};

export const SeatsUpdateAlertContent: FC<
  Pick<SeatsUpdateAlertProps, "minCount" | "priceLabel"> & {
    currentCount: number;
    onChange: (value: number) => void;
  }
> = ({ minCount, onChange, currentCount, priceLabel }) => (
  <div>
    <SeatCountSelect
      value={currentCount}
      onChange={onChange}
      minValue={minCount}
      bottomLabel={priceLabel}
    />
  </div>
);

export const SeatsUpdateAlert: FC<SeatsUpdateAlertProps> = ({
  isOpen,
  title,
  onRequestClose,
  defaultCount = 0,
  onSubmit,
  ...contentProps
}) => {
  const header = title || "Manage additional seats";
  const [currentCount, setCurrentCount] = useState<number>(defaultCount);
  useEffect(() => {
    setCurrentCount(defaultCount);
  }, [defaultCount]);
  return (
    <Alert
      isOpen={isOpen}
      header={title || header}
      onRequestClose={onRequestClose}
      isCloseButtonVisible
      content={
        <SeatsUpdateAlertContent
          {...contentProps}
          currentCount={currentCount}
          onChange={setCurrentCount}
        />
      }
      buttons={[
        !currentCount && !defaultCount
          ? { role: "close", text: "Close", onClick: onRequestClose }
          : {
              text: currentCount ? `Add ${currentCount} seats` : "Save",
              isDisabled:
                !!contentProps.minCount && currentCount < contentProps.minCount,
              onClick: () => onSubmit(currentCount),
            },
      ]}
    />
  );
};
