import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import {
  Button,
  InteractiveContainer,
  LoadingSpinner,
  Popover,
} from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import React, { useCallback, useState } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import {
  UserEntityStatus,
  useRestApiProvider,
  UserGeneralProfile,
} from "@jugl-web/rest-api";
import { getUserRoleFlags } from "@jugl-web/domain-resources/users/utils/getUserRoleFlags";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

export const ChatAdminsPopover: React.FC<{
  chatId: string;
  onSuccess: () => void;
}> = ({ chatId, onSuccess }) => {
  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, inputProps, reset } = useSearchInput();
  const { t } = useTranslations();
  const [selectedUsers, setSelectedUsers] = useState<UserGeneralProfile[]>([]);

  const handleUserSelect = useCallback((selectedUser: UserGeneralProfile) => {
    setSelectedUsers((prev) =>
      prev.find((item) => item.id === selectedUser.id)
        ? prev.filter((item) => item.id !== selectedUser.id)
        : [...prev, selectedUser]
    );
  }, []);

  const { workspacesApi } = useRestApiProvider();

  const [changeRoleWorkspaceParticipants, { isLoading }] =
    workspacesApi.useChangeWorkspaceParticipantsRoleMutation();

  return (
    <Popover
      placement="bottom-end"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={InteractiveContainer}
          className="bg-primary hover:bg-primary-600 flex h-8 w-8 items-center justify-center rounded-lg border border-solid border-white transition-colors"
        >
          <PlusIcon />
        </Trigger>
      )}
      onUnmount={() => {
        setSelectedUsers([]);
        reset();
      }}
    >
      {({ onClose }) => (
        <div className="border-dark-100 flex w-[375px] flex-col gap-7 rounded-xl border border-solid bg-white py-6 drop-shadow-[0px_24px_24px_rgba(53,54,56,0.10)]">
          <div className="px-4">
            <SearchInput
              variant="filled"
              color="grey"
              {...inputProps}
              onReset={reset}
              onClear={reset}
            />
          </div>
          <div className="h-[300px]">
            <HeadlessUsersList
              fetchParams={{
                entityId,
                workspaceId: chatId,
                role: "member",
                searchQuery,
              }}
              userRenderer={(user) => {
                const isUserEmployee = getUserRoleFlags(
                  user.role
                ).hasEmployeeLikeRole;

                return (
                  <div className="mb-3 px-4">
                    <UserListItem
                      mode="check"
                      isSelected={
                        !!selectedUsers.find((item) => item.id === user.id)
                      }
                      onSelect={handleUserSelect}
                      entityId={entityId}
                      userId={user.id}
                      variant="web"
                      highlightText={searchQuery}
                      defaultSubTitle="department"
                      isDisabled={
                        isUserEmployee ||
                        user.status !== UserEntityStatus.active
                      }
                      disabledInfo={
                        isUserEmployee
                          ? t({
                              id: "feedback.role-cannot-be-admin",
                              defaultMessage:
                                "Due to role permissions, this user cannot be an admin 🙅‍♀️",
                            })
                          : t({
                              id: "feedback.user-is-suspended-and-cant-be-admin",
                              defaultMessage:
                                "This user has been suspended and can't be assigned as Group Admin 🚫",
                            })
                      }
                    />
                  </div>
                );
              }}
              emptyContent={
                <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                  {t({
                    id: "common.no-users-found",
                    defaultMessage: "No users found",
                  })}
                </div>
              }
              loadingContent={
                <div className="flex h-full w-full items-center justify-center">
                  <LoadingSpinner />
                </div>
              }
              virtualized
            />
          </div>
          <div className="flex items-center gap-4 px-4">
            <Button fullWidth color="grey" onClick={onClose} className="h-10">
              {t({
                id: "common.cancel",
                defaultMessage: "Cancel",
              })}
            </Button>
            <Button
              fullWidth
              className="h-10"
              isDisabled={!selectedUsers.length || isLoading}
              onClick={async () => {
                const response = await changeRoleWorkspaceParticipants({
                  entityId,
                  workspaceId: chatId,
                  role: "admin",
                  users: selectedUsers.map((user) => user.id),
                });

                if (response && "data" in response) {
                  onClose();
                  onSuccess();
                }
              }}
            >
              {selectedUsers.length
                ? t(
                    {
                      id: "common.select-with-count",
                      defaultMessage: "Select {count}",
                    },
                    {
                      count: selectedUsers.length,
                    }
                  )
                : t({
                    id: "common.select",
                    defaultMessage: "Select",
                  })}
            </Button>
          </div>
        </div>
      )}
    </Popover>
  );
};
