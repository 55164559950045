import { WidgetModel } from "@jugl-web/rest-api/dashboard/models/Widget";
import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLProps } from "react";
import { OpenWidgetDialogFn } from "../../types";
import { DashboardWidgetBody } from "./DashboardWidgetBody";
import { DashboardWidgetTopBar } from "./DashboardWidgetTopBar";
import styles from "./DashboardWidget.module.css";

interface DashboardWidgetProps extends HTMLProps<HTMLDivElement> {
  widget: WidgetModel;
  isDragging: boolean;
  canManageWidget: boolean;
  isLayoutFrozen: boolean;
  onOpenWidgetDialog: OpenWidgetDialogFn;
}

export const DashboardWidget = forwardRef<HTMLDivElement, DashboardWidgetProps>(
  (
    {
      widget,
      isDragging,
      canManageWidget,
      isLayoutFrozen,
      className,
      children,
      onOpenWidgetDialog,
      ...props
    },
    ref
  ) => (
    <div ref={ref} className={cx(className, "group/widget")} {...props}>
      <div
        className={cx(
          styles.dashboardWidget,
          "outline-grey-300 relative flex h-full w-full flex-col rounded-2xl outline outline-1 transition-all",
          !isLayoutFrozen &&
            "group-hover/widget:outline-primary-200 group-hover/widget:outline-2",
          isDragging && "rotate-2 shadow-[0_8px_16px_0_rgba(53,54,56,0.16)]"
        )}
      >
        <DashboardWidgetTopBar
          widget={widget}
          isLayoutFrozen={isLayoutFrozen}
          canManageWidget={canManageWidget}
          onOpenWidgetDialog={onOpenWidgetDialog}
        />
        <DashboardWidgetBody widget={widget} />
        {/* Passing children is necessary to place the resize handle properly */}
        {children}
      </div>
    </div>
  )
);
