/* eslint-disable prefer-template */
import { useTranslations } from "@jugl-web/utils";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalTypeaheadMenuPlugin } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $createTextNode, TextNode } from "lexical";
import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { createPortal } from "react-dom";
import { useInView } from "react-intersection-observer";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform/LoadingSpinner";
import {
  useHeadlessUsersList,
  HeadlessUsersListHookParams,
} from "../../../../users/hooks/useHeadlessUsersList";
import { CaretPinnedMentionsList } from "../components/CaretPinnedMentionsList";
import { ContainerPinnedMentionsList } from "../components/ContainerPinnedMentionsList";
import { MentionMenuItem } from "../components/MentionMenuItem";
import { $createMentionNode, MentionNode } from "../nodes/MentionNode";
import { MentionListType } from "../types";
import { MentionMenuOption } from "../utils";
import { useMultipleUserProfiles } from "../../../../users/hooks/useMultipleUserProfiles";

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";

const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNCTUATION + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNCTUATION +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

const MENTIONS_REGEX = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}}))$`
);

export const checkForAtSignMentions = (
  text: string,
  minMatchLength: number
) => {
  const match = MENTIONS_REGEX.exec(text);

  if (!match) {
    return null;
  }

  // The strategy ignores leading whitespace but we need to know it's
  // length to add it to the leadOffset
  const maybeLeadingWhitespace = match[1];

  const matchingString = match[3];

  if (matchingString.length >= minMatchLength) {
    return {
      leadOffset: match.index + maybeLeadingWhitespace.length,
      matchingString,
      replaceableString: match[2],
    };
  }

  return null;
};

interface RemoteMentionsPluginProps {
  fetchParams: HeadlessUsersListHookParams;
  listType: MentionListType;
  containerRef: RefObject<HTMLDivElement>;
  meId: string;
}

export const RemoteMentionsPlugin: FC<RemoteMentionsPluginProps> = ({
  fetchParams,
  listType,
  containerRef,
  meId,
}) => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState("");
  const { t } = useTranslations();
  const { ref: moreMentionsLoadingRef, inView } = useInView();

  const {
    users,
    loadMore: loadMoreUsers,
    reachedEnd: usersEndReached,
    isLoading: isLoadingUsers,
  } = useHeadlessUsersList({
    ...fetchParams,
    searchQuery: queryString,
  });

  const workspaceUsers = useMemo(
    () => (fetchParams.workspaceId ? users : undefined),
    [fetchParams.workspaceId, users]
  );

  const { profiles, isLoading: isLoadingProfiles } = useMultipleUserProfiles({
    entityId: fetchParams.entityId,
    userIds: workspaceUsers?.map((user) => user.id) || [],
  });

  const suggestions = useMemo(() => {
    if (!workspaceUsers) return [];
    const usersWithoutMe = workspaceUsers.filter((user) => user.id !== meId);
    const result = usersWithoutMe.map((item) => {
      const userProfile = profiles.find((profile) => profile.id === item.id);

      return new MentionMenuOption(
        item.id,
        userProfile?.displayName || "",
        userProfile?.avatar || null
      );
    });

    const queryMatchAll = t({ id: "common.all", defaultMessage: "All" })
      .toLocaleLowerCase()
      .includes(queryString);

    if (result.length > 0 || queryMatchAll) {
      let newSuggestionsState = result;
      if (queryMatchAll || !queryString) {
        newSuggestionsState = [
          new MentionMenuOption(
            MENTIONS_ALL_ID,
            t({ id: "common.all", defaultMessage: "All" }),
            null
          ),
          ...result,
        ];
      }
      return newSuggestionsState;
    }
    return result;
  }, [meId, profiles, queryString, t, workspaceUsers]);

  const onSelectOption = useCallback(
    (
      selectedOption: MentionMenuOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.id,
          selectedOption.name
        );

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        const emptySpaceNode = $createTextNode(" ");

        mentionNode.insertAfter(emptySpaceNode);
        emptySpaceNode.select();

        closeMenu();
      });
    },
    [editor]
  );

  useEffect(() => {
    if (!editor.hasNode(MentionNode)) {
      throw new Error("MentionsPlugin: MentionNode not registered on editor");
    }
  }, [editor]);

  useEffect(() => {
    if (inView) {
      loadMoreUsers?.();
    }
  }, [inView, loadMoreUsers]);

  return (
    <LexicalTypeaheadMenuPlugin<MentionMenuOption>
      onQueryChange={(query) => {
        setQueryString(query ?? "");
      }}
      onSelectOption={onSelectOption}
      triggerFn={(text) => checkForAtSignMentions(text, 0)}
      options={suggestions}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) => {
        const anchorElement =
          listType === "container-pinned"
            ? containerRef.current
            : anchorElementRef.current;

        if (!anchorElement || suggestions.length === 0) {
          return null;
        }

        const ListComponent =
          listType === "container-pinned"
            ? ContainerPinnedMentionsList
            : CaretPinnedMentionsList;

        return createPortal(
          <ListComponent>
            {suggestions.map((option, index) => (
              <MentionMenuItem
                key={option.id}
                option={option}
                isSelected={index === selectedIndex}
                onClick={() => {
                  setHighlightedIndex(index);
                  selectOptionAndCleanUp(option);
                }}
                onMouseEnter={() => {
                  setHighlightedIndex(index);
                }}
              />
            ))}
            {!usersEndReached && !isLoadingProfiles && !isLoadingUsers && (
              <div ref={moreMentionsLoadingRef} />
            )}
            {(isLoadingUsers || isLoadingProfiles) && <LoadingSpinner />}
          </ListComponent>,
          anchorElement
        );
      }}
    />
  );
};
