import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedDashboardModel } from "@jugl-web/rest-api/dashboard/models/DetailedDashboard";
import { PreviewDashboardModel } from "@jugl-web/rest-api/dashboard/models/PreviewDashboard";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { SELECTED_DASHBOARD_ID_KEY } from "@jugl-web/utils/storage";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { WidgetDateRange } from "../../types";
import { getInitialWidgetDateRange } from "../../utils";

type IsDashboardOwnerFn = <
  TDashboard extends Pick<PreviewDashboardModel, "users">
>(
  dashboard: TDashboard
) => boolean;

interface DashboardContextValue {
  dashboards: PreviewDashboardModel[];
  areDashboardsLoading: boolean;
  detailedDashboard: DetailedDashboardModel | undefined;
  widgetDateRange: WidgetDateRange;
  isDetailedDashboardLoading: boolean;
  selectedDashboardId: string | null;
  searchQuery: string;
  refetchDetailedDashboard: () => Promise<void>;
  setWidgetDateRange: (dateRange: WidgetDateRange) => void;
  setSelectedDashboardId: (dashboardId: string) => void;
  isDashboardOwner: IsDashboardOwnerFn;
  changeSearchQuery: (query: string) => void;
}

export const DashboardContext = createContext<DashboardContextValue | null>(
  null
);

interface DashboardProviderProps {
  children: ReactNode;
}

export const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
  const { entityId } = useEntitySelectedProvider();
  const { id: meId } = useMe();

  const [widgetDateRange, setWidgetDateRange] = useState<WidgetDateRange>(
    getInitialWidgetDateRange
  );

  const [selectedDashboardId, setSelectedDashboardId] = useLocalStorage<
    string | null
  >(SELECTED_DASHBOARD_ID_KEY, null);

  const [searchQuery, setSearchQuery] = useState("");

  const { dashboardApi } = useRestApiProvider();

  const { data: dashboardsResponse, isLoading: areDashboardsLoading } =
    dashboardApi.useGetDashboardListQuery({ entityId });

  const {
    data: detailedDashboard,
    isLoading: isDetailedDashboardLoading,
    refetch: refetchDetailedDashboard,
  } = dashboardApi.useGetDashboardQuery(
    selectedDashboardId
      ? {
          entityId,
          dashboardId: selectedDashboardId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const refetchDetailedDashboardFn = useCallback(async () => {
    await refetchDetailedDashboard();
  }, [refetchDetailedDashboard]);

  const dashboards = useMemo<PreviewDashboardModel[]>(() => {
    if (!dashboardsResponse) {
      return [];
    }

    return dashboardsResponse.data;
  }, [dashboardsResponse]);

  const isDashboardOwner = useCallback<IsDashboardOwnerFn>(
    (dashboard) =>
      dashboard.users.some(
        (user) => user.user_id === meId && user.role === "owner"
      ),
    [meId]
  );

  // Analyze the dashboard list every time it changes
  // and redefine `selectedDashboardId` if necessary
  useLayoutEffect(() => {
    if (!dashboardsResponse) {
      return;
    }

    if (dashboards.length === 0) {
      setSelectedDashboardId(null);
      return;
    }

    const firstDashboardId = dashboards[0].id;

    if (!selectedDashboardId) {
      setSelectedDashboardId(firstDashboardId);
      return;
    }

    const doesSelectedDashboardExist = dashboards.some(
      (dashboard) => dashboard.id === selectedDashboardId
    );

    if (!doesSelectedDashboardExist) {
      setSelectedDashboardId(firstDashboardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardsResponse]);

  const contextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboards,
      areDashboardsLoading,
      detailedDashboard,
      widgetDateRange,
      isDetailedDashboardLoading,
      selectedDashboardId,
      refetchDetailedDashboard: refetchDetailedDashboardFn,
      searchQuery,
      setWidgetDateRange,
      setSelectedDashboardId,
      isDashboardOwner,
      changeSearchQuery: setSearchQuery,
    }),
    [
      areDashboardsLoading,
      dashboards,
      detailedDashboard,
      isDashboardOwner,
      isDetailedDashboardLoading,
      searchQuery,
      selectedDashboardId,
      refetchDetailedDashboardFn,
      setSearchQuery,
      setSelectedDashboardId,
      widgetDateRange,
    ]
  );

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};
