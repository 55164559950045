import { TaskTemplateFoldersHintDialog } from "@jugl-web/domain-resources/tasks/components/TaskTemplateFoldersHintDialog";
import { UserRole } from "@jugl-web/rest-api";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import { useSpotlight } from "@jugl-web/utils/hooks/useSpotlight";
import { TASK_TEMPLATES_SPOTLIGHT_TOUR_KEY } from "@jugl-web/utils/storage";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { TaskTemplateFoldersContent } from "@web-src/features/tasks/TaskTemplateFoldersContent";
import { TaskTemplateFoldersControlBar } from "@web-src/features/tasks/TaskTemplateFoldersControlBar";
import { TaskTemplateFoldersPageContextProvider } from "@web-src/features/tasks/TaskTemplateFoldersPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const TaskTemplateFolders = () => {
  const { entity } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);

  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  const { isActive, markAsSeen } = useSpotlight({
    id: TASK_TEMPLATES_SPOTLIGHT_TOUR_KEY,
    delay: 500,
  });

  const canManageTemplates =
    entity.role === UserRole.admin || entity.role === UserRole.taskManager;

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(
    () => [
      {
        title: t({
          id: "tasks-page.templates",
          defaultMessage: "Templates",
        }),
        href: generateUrl("tasksTemplates"),
      },
    ],
    [generateUrl, t]
  );

  return (
    <TaskTemplateFoldersPageContextProvider>
      <TasksPageLayout breadcrumbs={breadcrumbs}>
        {meId && (
          <>
            <TaskTemplateFoldersControlBar
              entityId={entity.id}
              canManageTemplates={canManageTemplates}
            />
            <TaskTemplateFoldersContent
              entityId={entity.id}
              meId={meId}
              canManageTemplates={canManageTemplates}
            />
          </>
        )}
      </TasksPageLayout>
      <TaskTemplateFoldersHintDialog isOpen={isActive} onClose={markAsSeen} />
    </TaskTemplateFoldersPageContextProvider>
  );
};
