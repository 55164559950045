import { HookOutOfContextError } from "@jugl-web/utils/errors";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  memo,
  useCallback,
} from "react";
import { ChatMessageModel } from "@jugl-web/rest-api/chats/models/ChatMessage";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import {
  ChatMessageAttachmentType,
  ChatMessagePayloadAttachment,
  ChatType,
} from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { environment } from "@web-src/environments/environment";
import { ChatItem } from "@web-src/modules/chats/types";
import { tokenizeMessageBody } from "@web-src/modules/chats/utils";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { ChatMessageBubbleAction } from "../../types/ChatMessageBubbleAction";

interface ChatMessageContextValue {
  message: ChatMessageModel;
  chat: ChatItem;
  isOutgoing: boolean;
  forceIsOutgoing?: boolean;
  isMediaMessageOnly: boolean;
  messageTextBody?: string;
  readOnly: boolean;
  isGroupChat: boolean;
  entityId: string;
  attachment?: ChatMessagePayloadAttachment;
  previewUrl?: string;
  getAttachmentUrls: (attachment: ChatMessagePayloadAttachment) => {
    preview: string;
    stream: string;
  };
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: (messageId: string, isSelected: boolean) => void;
  triggerAction: (action: ChatMessageBubbleAction) => void;
  triggerReaction: (reaction: string) => void;

  hasContentProtectionRestriction?: boolean;
  hasBroadcastChatRestriction?: boolean;
  isMessageActionsDisabled?: boolean;
  groupInfo?: {
    isFirst?: boolean;
    isLast?: boolean;
  };
}

const ChatMessageContext = createContext<ChatMessageContextValue | null>(null);

export type ChatMessageProviderProps = {
  message: ChatMessageModel;
  chatId: string;
  readOnly?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: (messageId: string, isSelected: boolean) => void;
  onTriggerAction?: (
    action: ChatMessageBubbleAction,
    message: ChatMessageModel
  ) => void;
  onReaction?: (reaction: string) => void;
  hasContentProtectionRestriction?: boolean;
  hasBroadcastChatRestriction?: boolean;
  isMessageActionsDisabled?: boolean;
  isFirstUnread?: boolean;
  forceIncomingMode?: boolean;
  groupInfo?: {
    isFirst?: boolean;
    isLast?: boolean;
  };
};

export const ChatMessageProvider: FC<
  PropsWithChildren<ChatMessageProviderProps>
> = memo(
  ({
    children,
    message,
    chatId,
    readOnly,
    isSelectable,
    isSelected,
    onSelect,
    onTriggerAction,
    onReaction,
    isMessageActionsDisabled,
    groupInfo,
    hasContentProtectionRestriction,
    forceIncomingMode,
    hasBroadcastChatRestriction,
  }) => {
    const { entityId } = useEntitySelectedProvider();
    const { conversations } = useConversations();
    const chat = useMemo(
      () => conversations.find((item) => item.id === chatId)?.data,
      [chatId, conversations]
    );

    const attachment = message.payload?.attachments?.[0];
    const messageTextBody = message.payload?.body || attachment?.caption;

    if (!chat) {
      throw new Error(`Chat <${chatId}> not found`);
    }

    const meId = useSelector(selectUserId);

    const getAttachmentUrls: ChatMessageContextValue["getAttachmentUrls"] =
      useCallback(
        (messageAttachment) => ({
          preview: messageAttachment._local_url
            ? messageAttachment._local_url
            : `${environment.apiUrl}/api/auth/attachment/preview?file=${entityId}/${messageAttachment.uid}`,
          stream: messageAttachment._local_url
            ? messageAttachment._local_url
            : `${environment.apiUrl}/api/auth/attachment/stream?file=${entityId}/${messageAttachment.uid}`,
        }),
        [entityId]
      );

    const previewUrl = useMemo(() => {
      if (!messageTextBody) return undefined;
      return tokenizeMessageBody(messageTextBody).find(
        (item) => item.type === "url"
      )?.rawString;
    }, [messageTextBody]);

    const handleTriggerAction = useCallback(
      (action: ChatMessageBubbleAction) => {
        onTriggerAction?.(action, message);
      },
      [message, onTriggerAction]
    );

    const handleTriggerReaction = useCallback(
      (reaction: string) => {
        onReaction?.(reaction);
      },
      [onReaction]
    );

    const hasConferenceDetails = useMemo(
      () =>
        message?.payload?.conference?.date ||
        message?.payload?.conference?.name ||
        message?.payload?.conference?.time,
      [message.payload.conference]
    );

    const value: ChatMessageContextValue = useMemo(
      () => ({
        message,
        chat,
        isOutgoing: forceIncomingMode ? false : message.from === meId,
        isGroupChat: chat.type === ChatType.muc,
        readOnly: !!readOnly,
        entityId,
        attachment,
        messageTextBody,
        previewUrl,
        isMediaMessageOnly:
          !message.deleted &&
          !messageTextBody &&
          ((!!attachment &&
            [
              ChatMessageAttachmentType.image,
              ChatMessageAttachmentType.gifDirectUrl,
              ChatMessageAttachmentType.gif,
              ChatMessageAttachmentType.video,
            ].includes(attachment.type)) ||
            (!!message.payload?.conference && !hasConferenceDetails)),
        getAttachmentUrls,
        isSelectable,
        isSelected,
        onSelect,
        triggerAction: handleTriggerAction,
        triggerReaction: handleTriggerReaction,
        isMessageActionsDisabled: isMessageActionsDisabled || message._pending,
        groupInfo,
        hasContentProtectionRestriction,
        hasBroadcastChatRestriction,
        hasConferenceDetails,
      }),
      [
        message,
        chat,
        forceIncomingMode,
        meId,
        readOnly,
        entityId,
        attachment,
        messageTextBody,
        previewUrl,
        hasConferenceDetails,
        getAttachmentUrls,
        isSelectable,
        isSelected,
        onSelect,
        handleTriggerAction,
        handleTriggerReaction,
        isMessageActionsDisabled,
        groupInfo,
        hasContentProtectionRestriction,
        hasBroadcastChatRestriction,
      ]
    );

    return (
      <ChatMessageContext.Provider value={value}>
        {children}
      </ChatMessageContext.Provider>
    );
  }
);

export const useChatMessageProvider = () => {
  const context = useContext(ChatMessageContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useChatMessageProvider",
      "ChatMessageContext"
    );
  }

  return context;
};
