import React, { useState, useMemo } from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations, cx, useToast } from "@jugl-web/utils";
import {
  Button,
  EmptyListContent,
  TableGrid,
  Menu,
  PlainButton,
  Checkbox,
  InteractiveContainer,
} from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEffectOnce } from "react-use";
import { WarehouseItemDataDto } from "@jugl-web/rest-api/inventory/models/WarehouseItem";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import countries from "countries-phone-masks";
import Highlighter from "react-highlight-words";
import { InventoryTabsLayout } from "../components/InventoryTabsLayout";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as RedDeleteIcon } from "./assets/red-delete.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as GreenDotIcon } from "./assets/green-dot.svg";
import { ReactComponent as RedDotIcon } from "./assets/red-dot.svg";
import emptyAnimation from "./assets/empty-warehouse.json";
import { useInventoryTableCheckboxState } from "../hooks/useInventoryTableCheckboxState";
import { ConfirmRemoveWarehouseAlert } from "./components/ConfirmRemoveWarehouseAlert";
import { AddWarehouseDialog } from "./components/AddWarehouseDialog";

export const InventoryWarehousesPage: React.FC = () => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast();
  const { inputProps, reset, searchQuery } = useSearchInput({
    debounce: 500,
    onSearch: (value) => {
      if (value) {
        handleLoadMoreWarehouseItems(true, false, value);
      }
      if (value === "" || !value) {
        handleLoadMoreWarehouseItems(true, true, undefined);
      }
    },
  });
  const { inventoryApi } = useRestApiProvider();
  const [loadWarehouseList] = inventoryApi.useLazyGetWarehouseListQuery();
  const [updateWarehouseStatus] = inventoryApi.useUpdateWarehouseMutation();
  const [internalIsLoading, setInternalIsLoading] = useState(false);

  const [totalEntries, setTotalEntries] = useState<number>();

  const [data, setData] = useState<{
    items: WarehouseItemDataDto[];
    page: number;
    isInitialized: boolean;
    hasMore: boolean;
  }>({
    items: [],
    page: 0,
    isInitialized: false,
    hasMore: true,
  });

  const [isConfirmRemoveWarehouseOpen, setIsConfirmRemoveWarehouseOpen] =
    useState(false);
  const [singleWarehouseIdToRemove, setSingleWarehouseIdToRemove] =
    useState<string>();
  const [warehouseDialogState, setWarehouseDialogState] = useState<{
    isOpen: boolean;
    warehouseToEdit?: WarehouseItemDataDto;
  }>({
    isOpen: false,
    warehouseToEdit: undefined,
  });

  const warehouseIds = React.useMemo(
    () => data.items.map(({ id }) => id),
    [data.items]
  );

  const {
    checkedList,
    isAllChecked,
    numbersOfChecked,
    handleCheckboxChange,
    handleClearAll,
    handleSelectAll,
    handleRemoveIds,
    isIdChecked,
  } = useInventoryTableCheckboxState({
    ids: warehouseIds || [],
    totalEntries,
  });

  const handleLoadMoreWarehouseItems = async (
    shouldReset?: boolean,
    clearSearchValue?: boolean,
    searchValue?: string
  ) => {
    if (!shouldReset && (!data.hasMore || internalIsLoading)) {
      return;
    }

    setInternalIsLoading(true);

    const response = await loadWarehouseList({
      params: {
        page: shouldReset ? 1 : data.page + 1,
        page_size: 10,
        search: clearSearchValue
          ? undefined
          : searchValue || searchQuery || undefined,
      },
      entityId: entity.id,
    });
    if (response.data && response.data.data) {
      if (!searchQuery && !searchValue && !totalEntries) {
        setTotalEntries(response.data.total_entries);
      }
      const hasMore = response.data.total_pages > response.data.page_number;
      const items = () => {
        if (shouldReset) return response.data?.data || [];
        if (!response.data) return data.items;
        const uniqueItems = new Map(
          [...data.items, ...response.data.data].map((item) => [item.id, item])
        );
        return Array.from(uniqueItems.values());
      };
      setData((prev) => ({
        ...prev,
        items: items(),
        page: response.data?.page_number || 1,
        isInitialized: true,
        hasMore,
      }));
    }
    setInternalIsLoading(false);
  };

  const handleWarehouseFormSuccess = (
    warehouse: WarehouseItemDataDto,
    isEdit: boolean
  ) => {
    if (isEdit) {
      const newWarehouseItemsState = data.items.filter(
        (item) => item.id !== warehouse.id
      );
      setData((prev) => ({
        ...prev,
        items: [warehouse, ...newWarehouseItemsState],
      }));
    } else {
      setData((prev) => ({
        ...prev,
        items: [warehouse, ...prev.items],
      }));
      setTotalEntries(totalEntries ? totalEntries + 1 : 1);
    }
  };

  const handleRemoveWarehousesSuccess = (warehouseIdToRemove: string[]) => {
    if (!warehouseIdToRemove?.length) {
      return;
    }
    setData((prev) => ({
      ...prev,
      items: prev.items.filter(
        (item) => !warehouseIdToRemove.includes(item.id)
      ),
    }));
    setTotalEntries(
      totalEntries ? totalEntries - warehouseIdToRemove.length : 0
    );
    handleRemoveIds(warehouseIdToRemove);

    handleClearAll();
  };

  const handleToggleStatus = async (
    id: string,
    status: "inactive" | "active"
  ) => {
    const response = await updateWarehouseStatus({
      entityId: entity.id,
      warehouseId: id,
      params: {
        status,
      },
    });
    if ("data" in response && response.data) {
      setData((prev) => ({
        ...prev,
        items: prev.items.map((item) =>
          item.id === id ? { ...item, status } : item
        ),
      }));
      toast(
        t({
          id: "feedback.warehouse-status-updated",
          defaultMessage: "Warehouse status was updated",
        })
      );
    }
  };

  const idsOfIWarehousesToRemove = useMemo(
    () => Object.keys(checkedList).filter((key) => checkedList[key]),
    [checkedList]
  );

  useEffectOnce(() => {
    handleLoadMoreWarehouseItems();
  });

  return (
    <InventoryTabsLayout
      selectedTab="inventory-warehouses"
      rightContent={
        <div className="flex items-center gap-4">
          <SearchInput
            className="w-[280px]"
            variant="filled"
            color="white"
            onClear={reset}
            {...inputProps}
          />
          {numbersOfChecked === 0 && (
            <Button
              className="h-10"
              iconEnd={<AddIcon />}
              onClick={() =>
                setWarehouseDialogState({
                  isOpen: true,
                })
              }
            >
              {t({
                id: "inventory-warehouses-page.add-warehouse",
                defaultMessage: "Add Warehouse",
              })}
            </Button>
          )}
          {numbersOfChecked > 0 && (
            <Button
              iconStart={<RedDeleteIcon />}
              className="font-secondary text-dark h-10 font-normal"
              color="white"
              onClick={() => {
                setIsConfirmRemoveWarehouseOpen(true);
              }}
            >
              {t(
                {
                  id: "inventory-warehouses-page.delete-item",
                  defaultMessage:
                    "Delete {count} {count, plural, one {Item} other {Items}}",
                },
                {
                  count: numbersOfChecked,
                }
              )}
            </Button>
          )}
          {numbersOfChecked > 0 && (
            <PlainButton
              onClick={() => {
                handleClearAll();
              }}
            >
              <CloseIcon />
            </PlainButton>
          )}
        </div>
      }
    >
      {data.isInitialized && (data.items.length || searchQuery) && (
        <div className="w-full min-w-fit p-8">
          <TableGrid
            unstyled
            data={data.items}
            className="overflow-hidden rounded-xl border-[1px] border-solid border-[#EEF2F5]"
            headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5] flex items-center"
            cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-4 border-0 border-t border-r border-solid border-[#EEF2F5] leading-[140%] tracking-[0.14px] hover:bg-grey-200"
            rowHoverClassName="bg-grey-100"
            activeRowColorInHex="#F9F9F9"
            isItemActive={(item) => isIdChecked(item.id)}
            onReachEnd={handleLoadMoreWarehouseItems}
            columns={[
              {
                title: (
                  <Checkbox
                    size="sm"
                    isChecked={isAllChecked}
                    onChange={() => {
                      if (isAllChecked) {
                        handleClearAll();
                      } else {
                        handleSelectAll();
                      }
                    }}
                  />
                ),
                grow: false,
                width: 50,
                headerClassName: isAllChecked
                  ? "bg-grey-100 rounded-tl-lg"
                  : undefined,
                content: ({ id }) => (
                  <Checkbox
                    size="sm"
                    isChecked={isIdChecked(id)}
                    onChange={() => {
                      handleCheckboxChange(id);
                    }}
                  />
                ),
              },
              {
                title: t({
                  id: "inventory-warehouses-page.warehouse-name",
                  defaultMessage: "Warehouse Name",
                }),
                grow: true,
                width: 320,
                content: ({ name }) => (
                  <div
                    className="font-secondary flex h-full w-11/12 items-center truncate text-base font-medium"
                    title={name}
                  >
                    <Highlighter
                      autoEscape
                      highlightClassName="text-primary bg-transparent"
                      className="truncate"
                      textToHighlight={name}
                      searchWords={[searchQuery || ""]}
                    />
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.status",
                  defaultMessage: "Status",
                }),
                grow: false,
                className: "p-[1px]",
                width: 90,
                content: ({ id, status }) => (
                  <Menu
                    placement="bottom-end"
                    className="flex w-[120px] min-w-[0] flex-col gap-2 p-2"
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        ref={triggerRef}
                        as={InteractiveContainer}
                        className={cx(
                          "flex h-[52px] w-[86px] items-center justify-center capitalize",
                          status === "active"
                            ? "bg-secondary-50 text-secondary-800"
                            : "bg-tertiary-50 text-tertiary-800"
                        )}
                      >
                        {status}
                      </Trigger>
                    )}
                    sections={[
                      [
                        {
                          id: "active",
                          label: t({
                            id: "common.active",
                            defaultMessage: "Active",
                          }),
                          className: cx(
                            "h-[40px] rounded-xl text-sm font-secondary text-dark",
                            status === "active" ? "bg-[#E3F2FD]" : ""
                          ),
                          icon: <GreenDotIcon />,
                          triggerClassName: `p-2 ${
                            status === "active" ? "cursor-not-allowed" : ""
                          }`,
                          onSelect: (_, close) => {
                            if (status === "active") return;
                            handleToggleStatus(id, "active");
                            close();
                          },
                        },
                        {
                          id: "inactive",
                          label: t({
                            id: "common.inactive",
                            defaultMessage: "Inactive",
                          }),
                          className: cx(
                            "h-[40px] rounded-xl text-sm font-secondary text-dark",
                            status === "inactive" ? "bg-[#E3F2FD]" : ""
                          ),
                          triggerClassName: `p-2 ${
                            status === "inactive" ? "cursor-not-allowed" : ""
                          }`,
                          onSelect: (_, close) => {
                            if (status === "inactive") return;
                            handleToggleStatus(id, "inactive");
                            close();
                          },
                          icon: <RedDotIcon />,
                        },
                      ],
                    ]}
                  />
                ),
              },
              {
                title: t({
                  id: "common.country",
                  defaultMessage: "Country",
                }),
                grow: false,
                width: 150,
                content: ({ address }) => {
                  const fullCountryName = countries.find(
                    (country) => country.iso === address.country
                  )?.name;
                  return (
                    <div className="w-11/12 truncate" title={fullCountryName}>
                      {fullCountryName}
                    </div>
                  );
                },
              },
              {
                title: t({
                  id: "common.state",
                  defaultMessage: "State",
                }),
                grow: false,
                width: 150,
                content: ({ address }) => (
                  <div className="w-11/12 truncate" title={address.state}>
                    {address.state}
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.city",
                  defaultMessage: "City",
                }),
                grow: false,
                width: 150,
                content: ({ address }) => (
                  <div className="w-11/12 truncate" title={address.city}>
                    {address.city}
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.zipcode",
                  defaultMessage: "ZIP code",
                }),
                grow: false,
                width: 100,
                content: ({ address }) => (
                  <div className="w-11/12 truncate" title={address.zipcode}>
                    {address.zipcode}
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.address",
                  defaultMessage: "Address",
                }),
                grow: true,
                className: "min-w-[200px]",
                content: ({ address }) => (
                  <div className="w-11/12 truncate" title={address.address}>
                    {address.address}
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.mobile-number",
                  defaultMessage: "Mobile Number",
                }),
                grow: false,
                width: 150,
                content: ({ mobile }) => (
                  <div className="w-11/12 truncate" title={mobile}>
                    {mobile}
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.email",
                  defaultMessage: "Email",
                }),
                grow: false,
                width: 200,
                content: ({ email }) => (
                  <div className="w-11/12 truncate" title={email}>
                    {email}
                  </div>
                ),
              },
              {
                title: t({
                  id: "order-forms-page.settings",
                  defaultMessage: "Settings",
                }),
                className: "flex justify-center w-full border-r-0",
                width: 100,
                grow: false,
                headerClassName: "border-r-0",
                content: (warehouse) => (
                  <Menu
                    placement="bottom-end"
                    autoClose
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        as={PlainButton}
                        ref={triggerRef}
                        className="mt-1"
                      >
                        <MoreIcon />
                      </Trigger>
                    )}
                    className="py-2"
                    sections={[
                      [
                        {
                          id: "edit",
                          label: t({
                            id: "inventory-warehouses-page.edit-warehouse",
                            defaultMessage: "Edit Warehouse",
                          }),
                          icon: <EditIcon />,
                          onSelect: () =>
                            setWarehouseDialogState({
                              isOpen: true,
                              warehouseToEdit: warehouse,
                            }),
                        },
                        {
                          id: "delete",
                          label: t({
                            id: "inventory-warehouses-page.delete-warehouse",
                            defaultMessage: "Delete Warehouse",
                          }),
                          onSelect: () =>
                            setSingleWarehouseIdToRemove(warehouse.id),
                          icon: <DeleteIcon />,
                        },
                      ],
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      )}
      {!data.items.length && data.isInitialized && !searchQuery && (
        <EmptyListContent
          type="custom"
          className="w-[400px]"
          customImg={
            <Lottie
              options={{ animationData: emptyAnimation }}
              height={300}
              width={300}
            />
          }
          customTitle={t({
            id: "inventory-warehouses-page.warehouse-storage",
            defaultMessage: "Warehouse Storage ✨",
          })}
          customSubtitle={t({
            id: "inventory-warehouses-page.warehouse-benefits-message",
            defaultMessage:
              "Add warehouses to streamline inventory management and optimize storage",
          })}
          customButton={{
            text: t({
              id: "inventory-warehouses-page.add-warehouse",
              defaultMessage: "Add Warehouse",
            }),
            iconEnd: <AddIcon />,
            fullWidth: false,
            className: "h-10",
            onClick: () => setWarehouseDialogState({ isOpen: true }),
          }}
        />
      )}
      <ConfirmRemoveWarehouseAlert
        isOpen={
          Boolean(singleWarehouseIdToRemove) || isConfirmRemoveWarehouseOpen
        }
        checkedList={checkedList}
        numberOfChecked={numbersOfChecked}
        itemIds={
          singleWarehouseIdToRemove
            ? [singleWarehouseIdToRemove]
            : idsOfIWarehousesToRemove
        }
        onSuccess={handleRemoveWarehousesSuccess}
        onClose={() => {
          setIsConfirmRemoveWarehouseOpen(false);
          setSingleWarehouseIdToRemove(undefined);
        }}
      />
      <AddWarehouseDialog
        isOpen={warehouseDialogState.isOpen}
        warehouse={warehouseDialogState.warehouseToEdit}
        onSubmitSuccess={(warehouse, isEdit) =>
          handleWarehouseFormSuccess(warehouse, isEdit)
        }
        onClose={() => setWarehouseDialogState({ isOpen: false })}
      />
      {!internalIsLoading && searchQuery && data.items.length === 0 && (
        <div className="mt-[80px] flex justify-center">
          {t({
            id: "inventory-items-page.no-results",
            defaultMessage: "No results 😔",
          })}
        </div>
      )}
    </InventoryTabsLayout>
  );
};
