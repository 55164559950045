import { UserRole } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";

export const useUserRoleName = () => {
  const { t } = useTranslations();

  const getRoleName = useCallback(
    (role?: UserRole) => {
      switch (role) {
        case UserRole.admin:
          return t({
            id: "cpanel-page.admin",
            defaultMessage: "Admin",
          });
        case UserRole.taskEmployee:
          return t({
            id: "cpanel-page.tasks-employee",
            defaultMessage: "Tasks Employee",
          });
        case UserRole.taskManager:
          return t({
            id: "cpanel-page.tasks-manager",
            defaultMessage: "Tasks Manager",
          });
        case UserRole.member:
          return t({
            id: "cpanel-page.member",
            defaultMessage: "Member",
          });
        case UserRole.travelLogEmployee:
          return t({
            id: "cpanel-page.travel-log-employee",
            defaultMessage: "Travel Log Employee",
          });
        case UserRole.chatManager:
          return t({
            id: "cpanel-page.chat-manager",
            defaultMessage: "Chat Manager",
          });
        case UserRole.chatEmployee:
          return t({
            id: "cpanel-page.chat-employee",
            defaultMessage: "Chat Employee",
          });
        default:
          return t({
            id: "cpanel-page.unknown-role",
            defaultMessage: "Unknown role",
          });
      }
    },
    [t]
  );

  return {
    getRoleName,
  };
};
