import { useRestApiProvider } from "@jugl-web/rest-api";
import { SafeUserGeneralProfile } from "@jugl-web/rest-api/users";
import {
  getImagePathForSharedLibrary,
  TFunction,
  useTranslations,
} from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import juglLogo from "./assets/jugl-logo.webp";

interface ProduceSafeProfileOptions {
  t: TFunction;
  isLoading: boolean;
}

export const produceSafeProfile = ({
  t,
  isLoading,
}: ProduceSafeProfileOptions): SafeUserGeneralProfile => ({
  displayName: isLoading
    ? t({ id: "common.jugl-user", defaultMessage: "Jugl User" })
    : t({
        id: "common.former-user",
        defaultMessage: "Former User",
      }),
  avatar: getImagePathForSharedLibrary(juglLogo),
});

export interface UseUserGeneralProfileOptions {
  userId?: string;
  entityId: string;
  skip?: boolean;
}

export const useUserGeneralProfile = ({
  userId,
  entityId,
  skip,
}: UseUserGeneralProfileOptions) => {
  const { t } = useTranslations();
  const { usersApi } = useRestApiProvider();

  const {
    data: profile,
    status,
    refetch,
    isLoading,
  } = usersApi.useGetUserGeneralProfileQuery(
    userId && entityId && !skip
      ? {
          entityId,
          params: { user_id: userId },
        }
      : skipToken
  );

  const safeProfile = useMemo<SafeUserGeneralProfile>(() => {
    if (profile) {
      return {
        displayName: profile.displayName,
        avatar: profile.avatar || null,
      };
    }

    return produceSafeProfile({ t, isLoading });
  }, [isLoading, profile, t]);

  return {
    profile,
    safeProfile,
    status,
    refetch,
    isLoading,
  };
};
