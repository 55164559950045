import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store from "@web-src/store";
import { AppVariantProvider } from "@jugl-web/utils/providers/AppVariantProvider";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { CustomThemeProvider } from "@web-src/themes/CustomThemeProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SingletonHooksContainer } from "react-singleton-hook";
import { RestApiProvider } from "@jugl-web/rest-api";
import { FilePreviewProvider } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { FileSelectProvider } from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import { BrowserRouter as Router } from "react-router-dom";
import App from "@web-src/app/app";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "./index.css";
import "@jugl-web/utils/styles/util-styles.css";
import { PhoenixSocketProvider } from "@web-src/modules/socket/providers/PhoenixSocket";
import { AuthTokenProvider } from "@jugl-web/domain-resources/auth/providers/AuthTokenProvider";
import { PreferencesProvider } from "@jugl-web/domain-resources/preferences";
import { DownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { EnhancedIntlProvider } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { pdfjs } from "react-pdf";
import AgoraRTC from "agora-rtc-react";
import {
  CallsProvider,
  CallsClient,
} from "@web-src/modules/conference/pages/ConferencePage/providers";
import { environment } from "./environments/environment";
import {
  tasksApi,
  usersApi,
  workspacesApi,
  customerApi as customersApi,
  customerFormFieldApi as customersFormFieldApi,
  tasksCommentsApi,
  dashboardApi,
  driveApi,
  tasksTemplatesApi,
  supersetApi,
  moduleNotificationsApi,
  authApi,
  entitiesApi,
  entitySpacesApi,
  reportsApi,
  linksApi,
  ordersApi,
  inventoryApi,
  integrationsApi,
  chatsApi,
} from "./features/api/createApi";
import { getJwtToken } from "./features/auth/utils";
import { InAppNotificationSnackbar } from "./modules/notifications/components/InAppNotificationSnackbar";
import {
  InAppNotification,
  NotificationsProvider,
} from "./modules/notifications/providers/NotificationsProvider";
import { DeprecatedFilePreviewProvider } from "./features/app/providers/DeprecatedFilePreviewProvider";
import { TimeZoneProvider } from "./modules/settings/providers/TimeZoneProvider";
import { EntityProvider } from "./modules/entities/providers/EntityProvider";
import { UserAuthProvider } from "./modules/auth/providers/UserAuthProvider/UserAuthProvider";
import { EntitySubscriptionProvider } from "./modules/entities/providers/EntitySubscriptionProvider";
import { IntercomProvider } from "./components/IntercomProvider";
import "./polyfills/mp3RecorderPolyfill";
import { SentryUserSetter } from "./modules/common/utils/sentrySetup";
import { OnboardingProvider } from "./modules/preferences/providers";

AgoraRTC.setLogLevel(4);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const generateClassName = createGenerateClassName();

// NOTE: cleanup tus related stuff from user's localStorage
// TODO: to be removed in next release
(() => {
  const cleanupDoneKey = "jugl::tusCleanupDone";
  const cleanupDone = localStorage.getItem(cleanupDoneKey);
  if (cleanupDone) {
    return;
  }
  localStorage.setItem(cleanupDoneKey, "1");
  Object.keys(localStorage).forEach((key) => {
    if (!key.startsWith("tus::")) {
      return;
    }
    localStorage.removeItem(key);
  });
})();
//

const renderApp = () => {
  const $rootElement = document.getElementById("root");
  if (!$rootElement) {
    return;
  }
  const root = ReactDOM.createRoot($rootElement);
  root.render(
    <AppVariantProvider variant="web">
      <PreferencesProvider>
        <Provider store={store}>
          <RestApiProvider
            apiBaseUrl={environment.apiUrl}
            apis={{
              tasksApi,
              tasksCommentsApi,
              tasksTemplatesApi,
              usersApi,
              workspacesApi,
              customersApi,
              customersFormFieldApi,
              supersetApi,
              dashboardApi,
              driveApi,
              moduleNotificationsApi,
              authApi,
              entitiesApi,
              entitySpacesApi,
              reportsApi,
              linksApi,
              ordersApi,
              inventoryApi,
              integrationsApi,
              chatsApi,
            }}
          >
            <Router>
              <EnhancedIntlProvider saveLangToLocalStorage>
                <EntityProvider>
                  <CustomThemeProvider>
                    <SnackbarProvider
                      maxSnack={3}
                      Components={{
                        inAppNotification: InAppNotificationSnackbar,
                      }}
                    >
                      <NotificationsProvider>
                        <TimeZoneProvider>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <StylesProvider
                              generateClassName={generateClassName}
                            >
                              <FilePreviewProvider>
                                <FileSelectProvider>
                                  <DeprecatedFilePreviewProvider>
                                    <AuthTokenProvider
                                      getter={() =>
                                        getJwtToken()?.accessToken || null
                                      }
                                    >
                                      <DownloadManagerProvider>
                                        <PhoenixSocketProvider>
                                          <CallsClient>
                                            <CallsProvider>
                                              <UserAuthProvider>
                                                <EntitySubscriptionProvider>
                                                  <IntercomProvider>
                                                    <OnboardingProvider>
                                                      <SentryUserSetter />
                                                      <App />
                                                    </OnboardingProvider>
                                                  </IntercomProvider>
                                                </EntitySubscriptionProvider>
                                              </UserAuthProvider>
                                              <SingletonHooksContainer />
                                            </CallsProvider>
                                          </CallsClient>
                                        </PhoenixSocketProvider>
                                      </DownloadManagerProvider>
                                    </AuthTokenProvider>
                                  </DeprecatedFilePreviewProvider>
                                </FileSelectProvider>
                              </FilePreviewProvider>
                            </StylesProvider>
                          </LocalizationProvider>
                        </TimeZoneProvider>
                      </NotificationsProvider>
                    </SnackbarProvider>
                  </CustomThemeProvider>
                </EntityProvider>
              </EnhancedIntlProvider>
            </Router>
          </RestApiProvider>
        </Provider>
      </PreferencesProvider>
    </AppVariantProvider>
  );
};

renderApp();

// TODO: move out
declare module "notistack" {
  interface VariantOverrides {
    inAppNotification: {
      notification?: InAppNotification;
    };
  }
}
