export enum DashboardSpotlightTourStep {
  switchOrCreateDashboard = "switchOrCreateDashboard",
  createWidget = "createWidget",
}

export const DEFAULT_CHART_COLOR_PALETTE = [
  "#5D89DF",
  "#64BDC6",
  "#EECA34",
  "#FE6A35",
  "#B021F3",
  "#F33A9E",
  "#2FB819",
  "#2196F3",
];

export const WIDGET_PER_DASHBOARD_LIMIT = 10;
