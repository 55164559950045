import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  getImagePathForSharedLibrary,
  TFunction,
  useTranslations,
} from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import { CustomerDetails, getCustomerDetails } from "../../utils";
import memberAvatar from "./assets/member.webp";

type SafeCustomerDetails = Pick<CustomerDetails, "fullName" | "avatarUrl">;

interface ProduceSafeCustomerDetailsOptions {
  t: TFunction;
  isLoading: boolean;
}

export const produceSafeCustomerDetails = ({
  t,
  isLoading,
}: ProduceSafeCustomerDetailsOptions): SafeCustomerDetails => ({
  fullName: isLoading
    ? t({
        id: "common.jugl-customer",
        defaultMessage: "Jugl Customer",
      })
    : t({
        id: "tasks-page.former-customer",
        defaultMessage: "Former Customer",
      }),
  avatarUrl: getImagePathForSharedLibrary(memberAvatar),
});

export interface UseCustomerOptions {
  entityId: string;
  customerId?: string | null;
  skipLoading?: boolean;
}

export const useCustomer = ({
  entityId,
  customerId,
  skipLoading,
}: UseCustomerOptions) => {
  const { customersApi } = useRestApiProvider();
  const { t } = useTranslations();

  const { data: customer, isLoading } = customersApi.useCustomerQuery(
    customerId && !skipLoading ? { entityId, customerId } : skipToken
  );

  const customerDetails = useMemo<CustomerDetails | null>(() => {
    if (!customer) {
      return null;
    }

    return getCustomerDetails(customer.data);
  }, [customer]);

  const safeCustomerDetails = useMemo<SafeCustomerDetails>(() => {
    if (customerDetails) {
      return customerDetails;
    }

    return produceSafeCustomerDetails({ t, isLoading });
  }, [customerDetails, isLoading, t]);

  return {
    customerDetails,
    safeCustomerDetails,
    isLoading,
  };
};
