import { getFileTypeByMime, FileType } from "@jugl-web/utils";
import { ReactComponent as CsvIcon } from "../assets/csv.svg";
import { ReactComponent as DocIcon } from "../assets/doc.svg";
import { ReactComponent as FileIcon } from "../assets/file.svg";
import { ReactComponent as GifIcon } from "../assets/gif.svg";
import { ReactComponent as ImageIcon } from "../assets/image.svg";
import { ReactComponent as VideoIcon } from "../assets/video.svg";
import { ReactComponent as PdfIcon } from "../assets/pdf.svg";
import { ReactComponent as PptIcon } from "../assets/ppt.svg";
import { ReactComponent as TxtIcon } from "../assets/txt.svg";
import { ReactComponent as AudioIcon } from "../assets/audio.svg";
import { ReactComponent as XlsIcon } from "../assets/xls.svg";

const fileTypeIconMap: { [key in FileType]: React.ReactNode } = {
  [FileType.txt]: <TxtIcon />,
  [FileType.csv]: <CsvIcon />,
  [FileType.audio]: <AudioIcon />,
  [FileType.image]: <ImageIcon />,
  [FileType.gif]: <GifIcon />,
  [FileType.video]: <VideoIcon />,
  [FileType.pdf]: <PdfIcon />,
  [FileType.doc]: <DocIcon />,
  [FileType.xls]: <XlsIcon />,
  [FileType.ppt]: <PptIcon />,
  [FileType.other]: <FileIcon />,
};

export const getAttachmentIcon = (mimetype: string) => {
  const fileType = getFileTypeByMime(mimetype);
  return fileTypeIconMap[fileType];
};
