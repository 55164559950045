import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useToast, useTranslations } from "@jugl-web/utils";
import { FC, useCallback, useMemo, useState } from "react";
import {
  UserCustomField,
  UserCustomFieldType,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { CustomFieldForm } from "@jugl-web/domain-resources/custom-fields/components/CustomFieldForm/CustomFieldForm";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { ManageProfileAttributesDialogScreenToParametersMap } from "../types";
import {
  MAX_USER_CUSTOM_FIELDS_COUNT,
  USER_CUSTOM_FIELD_TYPES,
} from "../consts";
import { useCPanelPageContext } from "../../../CPanelPageProvider";

export const NewUserCustomFieldScreen: FC<{
  propId: string;
  initialFieldName?: string;
  initialFieldType?: UserCustomFieldType;
  userCustomFields: UserCustomField[];
}> = ({
  propId,
  userCustomFields,
  initialFieldName = "",
  initialFieldType = "text",
}) => {
  const { t } = useTranslations();
  const { toast } = useToast();
  const { entityId } = useEntitySelectedProvider();
  const { usersApi } = useRestApiProvider();
  const [appendUserCustomFields, { isLoading }] =
    usersApi.useAppendUserCustomFieldsMutation();
  const { transitionTo } =
    useScreenTransitionManager<ManageProfileAttributesDialogScreenToParametersMap>();
  const { refetchUsers$ } = useCPanelPageContext();

  const [fieldName, setFieldName] = useState(initialFieldName);
  const [fieldType, setFieldType] =
    useState<UserCustomFieldType>(initialFieldType);

  const hasDropdownType = fieldType === "dropdown";

  const handleCreateUserCustomField = useCallback(async () => {
    if (hasDropdownType) return;
    const order = userCustomFields.length
      ? Math.max(
          ...userCustomFields.map((userCustomField) => userCustomField.order)
        ) + 1
      : 1;

    const response = await appendUserCustomFields({
      entityId,
      propId,
      value: [{ name: fieldName, order, type: fieldType }],
    });

    if (response && "data" in response) {
      refetchUsers$.next();
      toast(
        t({
          id: "cpanel-page.custom-field-successfully-created",
          defaultMessage: "Custom Field successfully created",
        })
      );
      transitionTo({ name: "entry" });
    }
  }, [
    refetchUsers$,
    hasDropdownType,
    propId,
    appendUserCustomFields,
    entityId,
    fieldName,
    fieldType,
    t,
    toast,
    transitionTo,
    userCustomFields,
  ]);

  const isNameDuplicateError = useMemo(
    () =>
      userCustomFields.some(
        (customField) =>
          customField.name.toLowerCase().trim() ===
          fieldName.toLowerCase().trim()
      ),
    [fieldName, userCustomFields]
  );

  return (
    <BottomCenteredDrawer.Content className="flex flex-col items-center justify-center py-6">
      <CustomFieldForm>
        <CustomFieldForm.Heading
          title={t({
            id: "cpanel-page.new-custom-field",
            defaultMessage: "New custom field",
          })}
          subtitle={t(
            {
              id: "cpanel-page.new-user-custom-field-description",
              defaultMessage:
                "Create up to {maxAttributesCount} Custom fields that will be presented in Users Profile Info ✍️",
            },
            { maxAttributesCount: MAX_USER_CUSTOM_FIELDS_COUNT }
          )}
          className="mb-12"
        />
        <CustomFieldForm.FieldInput
          label={t({
            id: "form-controls.field-name",
            defaultMessage: "Field Name",
          })}
          value={fieldName}
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          className="mb-6"
          onChange={(value) => setFieldName(value.trimStart())}
          errorMessage={
            isNameDuplicateError
              ? t({
                  id: "cpanel-page.field-name-duplicate",
                  defaultMessage: "Field with indicated name is already exist",
                })
              : undefined
          }
        />
        <CustomFieldForm.FieldTypeSelect
          fieldTypes={USER_CUSTOM_FIELD_TYPES}
          selectedType={fieldType}
          className="mb-6"
          onSelect={setFieldType}
        />
        <CustomFieldForm.Actions
          submitButton={{
            label: hasDropdownType
              ? t({ id: "common.next", defaultMessage: "Next" })
              : t({ id: "common.create", defaultMessage: "Create" }),
            isDisabled: !fieldName.length || isLoading || isNameDuplicateError,
            onClick: () =>
              hasDropdownType
                ? transitionTo({
                    name: "newUserCustomDropdownFieldValues",
                    fieldName,
                  })
                : handleCreateUserCustomField(),
          }}
          onCancel={() => transitionTo({ name: "entry" })}
        />
      </CustomFieldForm>
    </BottomCenteredDrawer.Content>
  );
};
