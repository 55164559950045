import { apiUTCToLocalDateTime, cx } from "@jugl-web/utils";
import { FC } from "react";
import format from "date-fns/format";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { ReactComponent as EyeOutlineIcon } from "./assets/eye-outline.svg";
import { ReactComponent as SandClockIcon } from "./assets/sand-clock.svg";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";

export const ChatMessageFooter: FC = () => {
  const { isOutgoing, message, isMediaMessageOnly } = useChatMessageProvider();
  const timeStr = format(apiUTCToLocalDateTime(message.timestamp), "p");
  return (
    <div
      className={cx("flex items-center justify-end gap-1 text-xs", {
        "text-[#707577]": !isOutgoing && !isMediaMessageOnly,
        "text-white": isOutgoing || isMediaMessageOnly,
        "bg-[rgba(26, 32, 35, 0.30)] absolute bottom-2 right-[6px] rounded px-1 py-[2px] text-white":
          isMediaMessageOnly,
      })}
      style={{
        background: isMediaMessageOnly ? `rgba(26, 32, 35, 0.30)` : undefined,
      }}
    >
      {message.edited && (
        <>
          {/* TODO: i18n */}
          <span>Edited</span>
          <span
            className={cx("h-[2px] w-[2px] rounded-full", {
              "bg-[#707577]": !isOutgoing && !isMediaMessageOnly,
              "bg-white": isOutgoing || isMediaMessageOnly,
            })}
          />
        </>
      )}
      <span>{timeStr}</span>
      {isOutgoing &&
        (() => {
          if (message._pending) {
            return <SandClockIcon />;
          }
          if (message.read) {
            return <EyeOutlineIcon />;
          }
          return <CheckIcon />;
        })()}
    </div>
  );
};
