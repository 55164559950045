import mime from "mime";

export const getVideoDimensionsByUrl = (
  url: string
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.addEventListener(
      "loadedmetadata",
      () => {
        resolve({ height: video.videoHeight, width: video.videoWidth });
      },
      false
    );
    video.addEventListener("error", reject);
    video.src = url;
  });

export const getImageDimensionsByUrl = (
  url: string
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = url;
  });

export const getFileSizeByUrl = (url: string): Promise<number> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      const blob = xhr.response;
      resolve(blob.size);
    };
    xhr.onerror = reject;
    xhr.send();
  });

export function getAudioDuration(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File)) {
      reject(new Error("Input is not a valid File object"));
      return;
    }

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const result = event.target?.result;
      if (typeof result !== "string") {
        reject(new Error("Failed to read file as data URL"));
        return;
      }

      const audio = new Audio();
      audio.src = result;

      audio.addEventListener("loadedmetadata", () => {
        if (Number.isNaN(audio.duration)) {
          reject(new Error("Could not determine audio duration"));
        } else {
          resolve(Math.floor(audio.duration)); // duration in seconds
        }
      });

      audio.onerror = () => {
        reject(new Error("Error loading audio file"));
      };
    };

    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    reader.readAsDataURL(file);
  });
}

export const isImageUrl = (url: string) =>
  !!mime.getType(url)?.startsWith("image");

export function isVideoUrl(url: string) {
  return [".mp4", ".webm"].find((type) => url?.toLowerCase().endsWith(type));
}

export function isPdfUrl(url: string) {
  return [".pdf"].find((type) => url?.toLowerCase().endsWith(type));
}

export function isDocUrl(url: string) {
  return [".docx", ".doc"].find((type) => url?.toLowerCase().endsWith(type));
}

export function getFileNameFromUrl(url: string) {
  const encodedName = url?.split("/").pop();
  try {
    return decodeURIComponent(encodedName || "");
  } catch {
    return encodedName || "";
  }
}

export function getFileTypeByUrl(url: string) {
  if (isImageUrl(url)) {
    return "image";
  }
  if (isVideoUrl(url)) {
    return "video";
  }
  if (isPdfUrl(url)) {
    return "pdf";
  }
  if (isDocUrl(url)) {
    return "doc";
  }
  return "file";
}

export const getFileSizeLabel = (sizeInBytes: number, decimals = 0) => {
  if (!+sizeInBytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));

  return `${parseFloat((sizeInBytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
