import { useRestApiProvider } from "@jugl-web/rest-api";
import { GetWidgetDataParams } from "@jugl-web/rest-api/dashboard/types";
import { useMemo } from "react";
import { useDashboardContext } from "./useDashboardContext";

interface UseDashboardWidgetDataOptions {
  entityId: string;
  dashboardId: string;
  widgetId: string;
}

export const useDashboardWidgetData = ({
  entityId,
  dashboardId,
  widgetId,
}: UseDashboardWidgetDataOptions) => {
  const { widgetDateRange } = useDashboardContext();

  const { dashboardApi } = useRestApiProvider();

  const params = useMemo<GetWidgetDataParams>(
    () => ({
      from: widgetDateRange.from.toISOString(),
      to: widgetDateRange.to.toISOString(),
      date_type: "due_at",
    }),
    [widgetDateRange]
  );

  const { data, isLoading } = dashboardApi.useGetWidgetDataQuery({
    entityId,
    dashboardId,
    widgetId,
    params,
  });

  return { data, isLoading, params };
};
