import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { BoardAvatar } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as BoardIcon } from "../../assets/board.svg";
import { FilterPill } from "../FilterPill";

interface BoardFilterPillProps {
  entityId: string;
  boardId: InternalTaskFilters["boards"][number];
  onRemove?: () => void;
}

export const BoardFilterPill: FC<BoardFilterPillProps> = ({
  entityId,
  boardId,
  onRemove,
}) => {
  const { getBoardById } = useTaskBoards({ entityId });
  const { t } = useTranslations();

  if (boardId === null) {
    return (
      <FilterPill
        label={t({
          id: "tasks-page.without-board",
          defaultMessage: "Without board",
        })}
        startIcon={<BoardIcon />}
        onRemove={onRemove}
      />
    );
  }

  const matchingBoard = getBoardById(boardId);

  return (
    <FilterPill
      label={
        matchingBoard?.name ||
        t({
          id: "tasks-page.unknown-board",
          defaultMessage: "Unknown board",
        })
      }
      startIcon={
        matchingBoard ? (
          <BoardAvatar
            name={matchingBoard.name}
            color={matchingBoard.color}
            size="sm"
          />
        ) : undefined
      }
      onRemove={onRemove}
    />
  );
};
