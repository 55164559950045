import {
  RichTextarea,
  RichTextareaHandle,
} from "@jugl-web/domain-resources/common/components/RichTextarea";
import { WhatsAppTemplateEditorToolbar } from "@jugl-web/domain-resources/integrations/components/WhatsAppTemplateEditorToolbar";
import { SUPPORTED_WHATS_APP_VARIABLES } from "@jugl-web/domain-resources/integrations/consts";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  WhatsappTemplateCategory,
  WhatsappTemplatePayload,
  WhatsappTemplateValues,
} from "@jugl-web/rest-api/integrations/types";
import {
  TaskCustomStatus,
  TaskDefaultStatusId,
} from "@jugl-web/rest-api/tasks";
import {
  Alert,
  Button,
  EmptyListContent,
  FormGroup,
  HeaderBreadcrumbs,
  InteractiveContainer,
  ListBoxItem,
  MultiSectionLayout,
  PageLoaderFull,
  TextInput,
} from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import {
  cx,
  isWhatsappApiError,
  useBlockNavigation,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  UPDATES_AUTOMATION_DIALOG_SHOWN,
} from "@jugl-web/utils/storage";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DiscardChangesDialog } from "@web-src/modules/common/components";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import { rawBodyToWhatsappBody } from "../../utils/rawBodyToWhatsappBody";
import { whatsappBodyToRawBody } from "../../utils/whatsappBodyToRawBody";
import alertAnimation from "./assets/alert-animation.json";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { AutomationUpdatesDialog } from "./components/AutomationUpdatesDialog";
import { SectionHeader } from "./components/SectionHeader";
import { TemplatePreview } from "./components/TemplatePreview";
import { getWhatsappTemplateComponentsList } from "./utils/getWhatsappTemplateComponentsList";

export const WhatsappTemplateFormPage: React.FC = () => {
  const { generateUrl, navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);
  const confirmNavigation = useRef<(() => void) | null>(null);
  const skipNavigationAlert = useRef(false);
  const richTextareaRef = useRef<RichTextareaHandle | null>(null);
  const { templateCategory } = useParams<{
    templateCategory: WhatsappTemplateCategory;
  }>();

  useBlockNavigation(
    setIsDiscardChangesDialogOpen,
    confirmNavigation,
    skipNavigationAlert
  );

  const { entityId } = useEntitySelectedProvider();
  const { integrationsApi } = useRestApiProvider();
  const [createWhatsappTemplate, { isLoading }] =
    integrationsApi.useCreateWhatsappTemplateMutation();
  const [
    updateWhatsappTemplate,
    { isLoading: isUpdateWhatsappTemplateLoading },
  ] = integrationsApi.useUpdateWhatsappTemplateMutation();
  const [isAutomationUpdatesDialogOpen, setIsAutomationUpdatesDialogOpen] =
    useState(
      !getStorageItemWithFallback(UPDATES_AUTOMATION_DIALOG_SHOWN, false)
    );
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { templateId } = useParams<{ templateId: string }>();

  const {
    data: whatsappTemplate,
    isLoading: isWhatsappTemplateLoading,
    isError: isWhatsappTemplateError,
    isFetching: isWhatsappTemplateFetching,
    refetch: refetchWhatsappTemplate,
  } = integrationsApi.useGetWhatsappTemplateQuery(
    templateId
      ? {
          entityId,
          templateId,
        }
      : skipToken
  );

  const handleCreateTemplate = async ({
    name,
    footer,
    action,
    header,
    bodyPlain,
  }: WhatsappTemplateValues) => {
    if (!richTextareaRef.current) {
      return;
    }

    const whatsappBody = rawBodyToWhatsappBody(bodyPlain);

    const data: WhatsappTemplatePayload = {
      name,
      action,
      category: templateCategory,
      template: {
        components: getWhatsappTemplateComponentsList({
          footer,
          header,
          body: whatsappBody.body,
          variables: whatsappBody.variables,
        }),
      },
    };

    const response = templateId
      ? await updateWhatsappTemplate({
          entityId,
          data,
          templateId,
        })
      : await createWhatsappTemplate({
          entityId,
          data,
        });

    if (response && "data" in response) {
      if (templateId) {
        toast(
          t({
            id: "whatsapp-template-form-page.whatsapp-template-was-updated-and-sent-to-approval",
            defaultMessage: "WhatsApp Update was updated and sent to Approval",
          })
        );
      } else {
        toast(
          t({
            id: "whatsapp-template-form-page.whatsapp-template-was-created-and-sent-to-approval",
            defaultMessage: "WhatsApp Update was created and sent to Approval",
          })
        );
      }

      skipNavigationAlert.current = true;
      if (templateCategory) {
        navigateToPage("integrationsWhatsappTemplates", {
          templateCategory,
        });
      }
    }
    if (response && "error" in response && isWhatsappApiError(response.error)) {
      if ("error" in response.error.data) {
        toast(response.error.data.error.error_user_msg, { variant: "error" });
        return;
      }
      const errorObj = response.error.data.errors?.[0];
      if (errorObj) {
        if (errorObj.field === "entity_id") {
          setIsAlertOpen(true);
          return;
        }
        toast(errorObj.message, { variant: "error" });
      }
    }
  };

  const { allStatusesSorted, getStatusById } = useTaskStatuses({
    entityId,
  });

  const statusesAsListItems = useMemo<ListBoxItem<TaskCustomStatus>[]>(
    () => allStatusesSorted.map((status) => ({ id: status.id, value: status })),
    [allStatusesSorted]
  );

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm<WhatsappTemplateValues>({
    mode: "all",
    defaultValues: {
      bodyPlain: "",
      bodyHtml: "",
      footer: "",
      header: "",
      name: "",
      action:
        templateCategory === WhatsappTemplateCategory.tasks
          ? TaskDefaultStatusId.pending
          : undefined,
    },
  });

  useEffect(() => {
    if (whatsappTemplate) {
      const rawBody = whatsappBodyToRawBody(whatsappTemplate);
      setValue("name", whatsappTemplate.name);
      setValue("action", whatsappTemplate.action);
      setValue("header", whatsappTemplate.header);
      setValue("bodyPlain", whatsappTemplate.body);
      setValue("footer", whatsappTemplate.footer);
      richTextareaRef.current?.readFromWhatsAppMarkdown(rawBody, {
        supportedVariables: whatsappTemplate.variables,
      });
    }
  }, [whatsappTemplate, setValue]);

  const bodyPlain = watch("bodyPlain");
  const bodyHtml = watch("bodyHtml");
  const header = watch("header");
  const footer = watch("footer");
  const status = watch("action");

  return (
    <>
      <PageLoaderFull
        isActive={
          isLoading ||
          isWhatsappTemplateLoading ||
          isWhatsappTemplateFetching ||
          isUpdateWhatsappTemplateLoading
        }
        isTransparent
      />
      <MultiSectionLayout
        header={
          <HeaderBreadcrumbs
            items={[
              {
                title: t({
                  id: "whatsapp-template-form-page.whatsapp-integration",
                  defaultMessage: "WhatsApp Integration",
                }),
                href: templateCategory
                  ? generateUrl("integrationsWhatsappTemplates", {
                      templateCategory,
                    })
                  : "",
              },
              {
                title: (() => {
                  if (templateCategory === WhatsappTemplateCategory.message) {
                    return templateId
                      ? t({
                          id: "whatsapp-template-form-page.edit-message-template",
                          defaultMessage: "Edit Message Template",
                        })
                      : t({
                          id: "whatsapp-template-form-page.new-message-template",
                          defaultMessage: "New Message Template",
                        });
                  }
                  return templateId
                    ? t({
                        id: "whatsapp-template-form-page.edit-trigger",
                        defaultMessage: "Edit trigger",
                      })
                    : t({
                        id: "whatsapp-template-form-page.new-trigger",
                        defaultMessage: "New trigger",
                      });
                })(),
              },
            ]}
          />
        }
      >
        {isWhatsappTemplateError ? (
          <EmptyListContent type="error" onRefetch={refetchWhatsappTemplate} />
        ) : (
          <div className="flex h-full flex-col overflow-hidden">
            <div className="jugl__custom-scrollbar overflow-auto py-[50px]">
              <div className="flex flex-col gap-[50px]">
                <div className="px-[60px]">
                  <div className="w-[calc(100%-352px)] min-w-[320px]">
                    <SectionHeader
                      number={1}
                      title={
                        templateCategory === WhatsappTemplateCategory.tasks
                          ? t({
                              id: "whatsapp-template-form-page.name-and-action-trigger",
                              defaultMessage: "Name & Action Trigger",
                            })
                          : t({
                              id: "whatsapp-template-form-page.template-name",
                              defaultMessage: "Template Name",
                            })
                      }
                    />
                    {templateCategory === WhatsappTemplateCategory.message && (
                      <div className="text-dark-700 border-dark-400 font-secondary mt-8 border-y-0 border-l border-r-0 border-solid pl-[10px]">
                        {t(
                          {
                            id: "whatsapp-template-form-page.message-name-info-string",
                            defaultMessage: `Message Templates can be <boldText>manually sent</boldText> to the Client through <boldText>‘Client Support’</boldText> Chats module. This action can be done any time, without any additional trigger`,
                          },
                          {
                            boldText: (text: (string | JSX.Element)[]) => (
                              <span className="text-bold text-primary-800">
                                {text}
                              </span>
                            ),
                          }
                        )}
                      </div>
                    )}
                    <Controller
                      control={control}
                      name="name"
                      rules={{
                        required: true,
                        maxLength: 60,
                      }}
                      disabled={!!templateId}
                      render={({ field: { onChange, ...props } }) => (
                        <div className="mt-8">
                          <TextInput
                            label={t({
                              id: "whatsapp-template-form-page.name",
                              defaultMessage: "Name",
                            })}
                            isRequired
                            lengthIndicator={{
                              max: 60,
                              current: props.name.length,
                            }}
                            isInvalid={!!errors.name}
                            autoComplete="off"
                            {...props}
                            onChange={(e) =>
                              setValue(
                                "name",
                                e.target.value
                                  .trimStart()
                                  .toLowerCase()
                                  .replace(/\s+/g, "_"),
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                }
                              )
                            }
                          />
                        </div>
                      )}
                    />
                    {templateCategory === WhatsappTemplateCategory.tasks && (
                      <div className="mt-10">
                        <FormGroup
                          isRequired
                          label={t({
                            id: "whatsapp-template-form-page.when-status-set-to",
                            defaultMessage: "When Status set to",
                          })}
                        >
                          <Controller
                            name="action"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              const selectedTriggerLabel = value
                                ? getStatusById(value).text
                                : undefined;

                              return (
                                <>
                                  <ResourcePickerPopover
                                    placement="bottom-end"
                                    items={statusesAsListItems}
                                    hasSearch
                                    shouldFilterOnSearch
                                    selectionBehavior={{
                                      mode: "single",
                                      canToggle: false,
                                    }}
                                    maxVisibleItems={5}
                                    defaultSelectedIds={
                                      value ? [value] : undefined
                                    }
                                    itemSize="sm"
                                    spaceBetweenItems="compact"
                                    renderTrigger={({
                                      Trigger,
                                      triggerRef,
                                      isOpen,
                                    }) => (
                                      <Trigger
                                        ref={triggerRef}
                                        as={InteractiveContainer}
                                        className={cx(
                                          "flex items-center justify-between rounded-lg border border-solid border-black/20 p-2.5 text-base font-[400] leading-4 text-[#363636] outline-none transition-colors",
                                          isOpen && "border-primary-300"
                                        )}
                                      >
                                        <span>{selectedTriggerLabel}</span>
                                        <ArrowIcon
                                          className={cx(
                                            "transition-transform",
                                            isOpen && "rotate-180"
                                          )}
                                        />
                                      </Trigger>
                                    )}
                                    renderLabel={(item) => item.value.text}
                                    onSelect={({ item, onClose }) => {
                                      onChange(item.id);
                                      onClose();
                                    }}
                                  />
                                  <div className="border-grey-400 text-dark-700 font-secondary mt-[18px] w-[600px] border-0 border-l border-solid pl-2.5 text-sm leading-[21px]">
                                    {t(
                                      {
                                        id: "whatsapp-template-form-page.trigger-description",
                                        defaultMessage:
                                          "When Order (Task) Status will be set to “{trigger}” - WhatsApp update will be sent to the Customer, which was indicated in Task details.",
                                      },
                                      { trigger: selectedTriggerLabel }
                                    )}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </FormGroup>
                      </div>
                    )}
                  </div>
                </div>
                <div className="h-0.5 w-full shrink-0 bg-[#EEF2F5]" />
                <div className="px-[60px]">
                  <SectionHeader
                    number={2}
                    title={t({
                      id: "whatsapp-template-form-page.message-content",
                      defaultMessage: "Message Content",
                    })}
                  />
                  <div className="mt-8 flex w-full items-start gap-8">
                    <div className="flex grow flex-col gap-8">
                      <Controller
                        control={control}
                        name="header"
                        rules={{
                          maxLength: 60,
                        }}
                        render={({ field: { onChange, ...props } }) => (
                          <TextInput
                            label={t({
                              id: "whatsapp-template-form-page.header",
                              defaultMessage: "Header",
                            })}
                            lengthIndicator={{
                              max: 60,
                              current: header.length,
                            }}
                            isInvalid={!!errors.header}
                            onChange={(e) =>
                              onChange(e.target.value.trimStart())
                            }
                            {...props}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="bodyPlain"
                        rules={{
                          required: true,
                          maxLength: 1024,
                        }}
                        render={({ field: { ref } }) => (
                          <FormGroup
                            label={t({
                              id: "whatsapp-template-form-page.body",
                              defaultMessage: "Body",
                            })}
                            isRequired
                            isInvalid={!!errors.bodyPlain}
                            errorMessage={errors.bodyPlain?.message}
                            lengthIndicator={{
                              max: 1024,
                              current: bodyPlain.length,
                            }}
                          >
                            <RichTextarea
                              ref={(e) => {
                                ref(e);
                                richTextareaRef.current = e;
                              }}
                              namespace="whatsapp-template-editor"
                              onEachUpdate={() => {
                                if (!richTextareaRef.current) {
                                  return;
                                }

                                setValue(
                                  "bodyPlain",
                                  richTextareaRef.current.saveToWhatsAppMarkdown(
                                    {
                                      supportedVariables:
                                        SUPPORTED_WHATS_APP_VARIABLES,
                                    }
                                  ),
                                  { shouldDirty: true, shouldValidate: true }
                                );

                                setValue(
                                  "bodyHtml",
                                  richTextareaRef.current.saveToHTML()
                                );
                              }}
                              classes={{
                                container: "w-full h-[160px]",
                                contentEditable:
                                  "h-full overflow-y-auto text-sm text-base font-secondary leading-4 px-5 py-4 border border-solid border-grey-400 rounded-[10px] focus:outline focus:outline-2 focus:outline-primary-200",
                              }}
                              endSlot={
                                <WhatsAppTemplateEditorToolbar
                                  hideVariables={
                                    templateCategory ===
                                    WhatsappTemplateCategory.message
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        name="footer"
                        rules={{
                          maxLength: 60,
                        }}
                        render={({ field: { onChange, ...props } }) => (
                          <TextInput
                            label={t({
                              id: "whatsapp-template-form-page.footer",
                              defaultMessage: "Footer",
                            })}
                            lengthIndicator={{
                              max: 60,
                              current: footer.length,
                            }}
                            isInvalid={!!errors.footer}
                            onChange={(e) =>
                              onChange(e.target.value.trimStart())
                            }
                            {...props}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <FormGroup
                        label={t({
                          id: "whatsapp-template-form-page.preview",
                          defaultMessage: "Preview",
                        })}
                      >
                        <TemplatePreview
                          bodyPlain={bodyPlain}
                          bodyHtml={bodyHtml}
                          footer={footer}
                          header={header}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-2.5 border-0 border-t-2 border-solid border-[#EEF2F5] py-6 px-[70px]">
              <Button
                color="grey"
                className="h-10 w-[140px]"
                onClick={() =>
                  templateCategory
                    ? navigateToPage("integrationsWhatsappTemplates", {
                        templateCategory,
                      })
                    : null
                }
              >
                {t({
                  id: "common.cancel",
                  defaultMessage: "Cancel",
                })}
              </Button>
              <Button
                className="h-10 w-[200px]"
                onClick={handleSubmit(handleCreateTemplate)}
                isDisabled={!!templateId && !isDirty}
              >
                {t({
                  id: "common.save",
                  defaultMessage: "Save",
                })}
              </Button>
            </div>
          </div>
        )}
      </MultiSectionLayout>
      <AutomationUpdatesDialog
        isOpen={isAutomationUpdatesDialogOpen}
        onRequestClose={() => {
          saveItemToStorage(UPDATES_AUTOMATION_DIALOG_SHOWN, true);
          setIsAutomationUpdatesDialogOpen(false);
        }}
      />
      <Alert
        isOpen={isAlertOpen}
        onRequestClose={() => setIsAlertOpen(false)}
        img={
          <Lottie
            options={{ animationData: alertAnimation }}
            width={70}
            height={70}
          />
        }
        title={t({
          id: "whatsapp-template-form-page.status-is-taken",
          defaultMessage: "Status is taken",
        })}
        content={t(
          {
            id: "whatsapp-template-form-page.template-status-already-assigned",
            defaultMessage:
              "Status {status} already assigned to WhatsApp Update. You can assign Status only to one WhatsApp Update at a time",
          },
          {
            status: status ? (
              <span className="text-primary-800 font-medium">
                {getStatusById(status).text}
              </span>
            ) : null,
          }
        )}
        buttons={[
          {
            text: t({ id: "common.okay", defaultMessage: "Okay" }),
            onClick: () => setIsAlertOpen(false),
          },
        ]}
      />
      <DiscardChangesDialog
        isOpen={isDiscardChangesDialogOpen}
        onClose={() => {
          setIsDiscardChangesDialogOpen(false);
          confirmNavigation.current = null;
        }}
        content={t({
          id: "whatsapp-template-form-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        onSubmit={() => confirmNavigation.current?.()}
      />
    </>
  );
};
