import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import ChatsPage from "./ChatsPage";

export type ChatsPagesNavigationParams = {
  chatsChats: PageNavParams;
  chatsActiveChat: PageNavParams<{ activeChatId: string }>;
};

export const chatsPagesNavigationConfig: ModulePagesConfig<ChatsPagesNavigationParams> =
  {
    chatsChats: {
      element: <ChatsPage />,
      path: "chats",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.chat,
    },
    chatsActiveChat: {
      element: <ChatsPage />,
      path: "chats/:activeChatId",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.chat,
    },
  };
