import { UserRole } from "@jugl-web/rest-api";
import { AppModule } from "./types";

export const appModulesToRequiredUserRoles: Record<AppModule, UserRole[]> = {
  [AppModule.dashboard]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.taskEmployee,
  ],
  [AppModule.chat]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.taskEmployee,
    UserRole.chatManager,
    UserRole.chatEmployee,
  ],
  [AppModule.people]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.chatManager,
  ],
  [AppModule.tasks]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.taskEmployee,
  ],
  [AppModule.drive]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.taskEmployee,
    UserRole.chatManager,
    UserRole.chatEmployee,
  ],
  [AppModule.calls]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.taskEmployee,
    UserRole.chatManager,
    UserRole.chatEmployee,
  ],
  [AppModule.travelLog]: [UserRole.admin, UserRole.travelLogEmployee],
  [AppModule.clients]: [UserRole.admin],
  [AppModule.integrations]: [UserRole.admin],
  [AppModule.orderForms]: [
    UserRole.admin,
    UserRole.member,
    UserRole.taskManager,
    UserRole.taskEmployee,
    UserRole.chatManager,
    UserRole.chatEmployee,
    UserRole.travelLogEmployee,
  ],
  [AppModule.workspace]: [UserRole.admin],
  [AppModule.inventory]: [UserRole.admin],
  [AppModule.taskReports]: [UserRole.admin],
  [AppModule.timeClockReports]: [UserRole.admin],
  [AppModule.scheduledReports]: [UserRole.admin],
};
