import {
  stripTaskDescriptionPrefix,
  UpdatedActionChanges,
} from "@jugl-web/rest-api/tasks";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import {
  assert,
  isoToLocalDate,
  joinReactNodes,
  NonUndefined,
  trimHTML,
  useTranslations,
} from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { joinReactNodesInHumanReadableForm } from "@jugl-web/utils/utils/joinReactNodes";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { ReactNode, useCallback, useMemo } from "react";
import { MessageDescriptor } from "react-intl";
import { useFormattedCustomFieldValue } from "./useFormattedCustomFieldValue";
import { useTaskFields } from "./useTaskFields";
import { useTaskPriorities } from "./useTaskPriorities";
import { useTaskStatuses } from "./useTaskStatuses";

type EnsuredUpdatedActionChangeValue<TKey extends keyof UpdatedActionChanges> =
  NonUndefined<UpdatedActionChanges[TKey]>;

type ChangeKeyToMessageRendererMap = {
  [Key in keyof UpdatedActionChanges]: (
    arg: EnsuredUpdatedActionChangeValue<Key>
  ) => ReactNode;
};

interface UseTaskActivityMessageRenderersOptions {
  entityId: string | undefined;
}

export const useTaskActivityMessageRenderers = ({
  entityId,
}: UseTaskActivityMessageRenderersOptions) => {
  const { getCustomFieldById } = useTaskFields({ entityId });
  const { getStatusById } = useTaskStatuses({ entityId });
  const { getPriorityDetailsById } = useTaskPriorities();
  const { formatCustomFieldValue } = useFormattedCustomFieldValue();

  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const taskCreatedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-created-task-activity",
          defaultMessage: "{username} created this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskCreatedByExternalUserMessageRenderer = useCallback(
    () =>
      t(
        {
          id: "tasks-page.external-user-created-task-activity",
          defaultMessage:
            "{username} created this task by submitting the order form",
        },
        {
          username: (
            <b>
              {t({
                id: "common.external-client",
                defaultMessage: "External client",
              })}
            </b>
          ),
        }
      ),
    [t]
  );

  // #region Task update message renderer utils
  const changeKeyToMessageRendererMap: ChangeKeyToMessageRendererMap = useMemo(
    () => ({
      name: (name) =>
        t(
          {
            id: "tasks-page.updated-task-name-activity",
            defaultMessage: "updated task name to {name}",
          },
          { name: <b>{name}</b> }
        ),
      desc: (description) =>
        description
          ? t(
              {
                id: "tasks-page.updated-task-description-activity",
                defaultMessage: "updated task description to {description}",
              },
              {
                description: (
                  <b>{trimHTML(stripTaskDescriptionPrefix(description))}</b>
                ),
              }
            )
          : t({
              id: "tasks-page.removed-task-description-activity",
              defaultMessage: "removed task description",
            }),
      priority: (priority) =>
        t(
          {
            id: "tasks-page.updated-task-priority-activity",
            defaultMessage: "updated task priority to {priority}",
          },
          { priority: <b>{getPriorityDetailsById(priority).shortLabel}</b> }
        ),
      board: (board) =>
        board
          ? t(
              {
                id: "tasks-page.moved-task-activity",
                defaultMessage: "moved task to {board}",
              },
              { board: <b>{board.name}</b> }
            )
          : t({
              id: "tasks-page.removed-task-board-activity",
              defaultMessage: "removed task from board",
            }),
      label: (label) =>
        label
          ? t(
              {
                id: "tasks-page.updated-task-label-activity",
                defaultMessage: "updated task label to {label}",
              },
              { label: <b>{label.text}</b> }
            )
          : t({
              id: "tasks-page.removed-task-label-activity",
              defaultMessage: "removed task label",
            }),
      due_at: (dueDate) =>
        dueDate
          ? t(
              {
                id: "tasks-page.updated-due-date-activity",
                defaultMessage: "updated due date to {dueDate}",
              },
              {
                dueDate: (
                  <b>
                    {localeAwareFormat(
                      isoToLocalDate(dueDate),
                      "MMM dd, hh:mm a"
                    )}
                  </b>
                ),
              }
            )
          : t({
              id: "tasks-page.removed-due-date-activity",
              defaultMessage: "removed due date",
            }),
      status: (statusId) =>
        t(
          {
            id: "tasks-page.updated-task-status-activity",
            defaultMessage: "updated task status to {status}",
          },
          { status: <b>{getStatusById(statusId).text}</b> }
        ),
      duration: (duration) => {
        const { hours, minutes } =
          convertMillisecondsToTimeComponents(duration);

        return t(
          {
            id: "tasks-page.updated-time-spent-activity",
            defaultMessage:
              "updated time spent on a task to <b>{hours}h {minutes}min</b>",
          },
          {
            hours,
            minutes,
            b: (text: (string | JSX.Element)[]) => <b>{text}</b>,
          }
        );
      },
      assignee_added: (assignees) =>
        t(
          {
            id: "tasks-page.assigned-users-activity",
            defaultMessage: "assigned {users} to the task",
          },
          {
            users: joinReactNodesInHumanReadableForm(
              assignees.map((assignee) => (
                <b>
                  {assignee.first_name} {assignee.last_name}
                </b>
              ))
            ),
          }
        ),
      assignee_removed: (assignees) =>
        t(
          {
            id: "tasks-page.unassigned-users-activity",
            defaultMessage: "unassigned {users} from the task",
          },
          {
            users: joinReactNodesInHumanReadableForm(
              assignees.map((assignee) => (
                <b>
                  {assignee.first_name} {assignee.last_name}
                </b>
              ))
            ),
          }
        ),
      checklist_added: (items) =>
        t(
          {
            id: "tasks-page.added-subtasks-activity",
            defaultMessage:
              "added {count} {count, plural, one {subtask} other {subtasks}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      checklist_updated: (items) =>
        t(
          {
            id: "tasks-page.updated-subtasks-activity",
            defaultMessage:
              "updated {count} {count, plural, one {subtask} other {subtasks}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.name || item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      checklist_rearranged: () =>
        t({
          id: "tasks-page.rearranged-subtasks-activity",
          defaultMessage: "has rearranged subtasks",
        }),
      checklist_deleted: (items) =>
        t(
          {
            id: "tasks-page.removed-subtasks-activity",
            defaultMessage:
              "removed {count} {count, plural, one {subtask} other {subtasks}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      attachment_added: (attachment) =>
        t(
          {
            id: "tasks-page.added-attachment-activity",
            defaultMessage: "added attachment {name}",
          },
          {
            name: <b>{attachment.file_name}</b>,
          }
        ),
      attachment_deleted: (attachment) =>
        t(
          {
            id: "tasks-page.removed-attachment-activity",
            defaultMessage: "removed attachment {name}",
          },
          {
            name: <b>{attachment.file_name}</b>,
          }
        ),
      attachment_renamed: (attachment) =>
        t(
          {
            id: "tasks-page.renamed-attachment-activity",
            defaultMessage: "renamed attachment {oldName} to {newName}",
          },
          {
            oldName: <b>{attachment.old_file_name}</b>,
            newName: <b>{attachment.file_name}</b>,
          }
        ),
      customer: () =>
        t({
          id: "tasks-page.updated-task-customer-activity",
          defaultMessage: "updated task customer",
        }),
      reminder: () =>
        t({
          id: "tasks-page.updated-task-reminder-activity",
          defaultMessage: "updated task reminder",
        }),
      recurrence: () =>
        t({
          id: "tasks-page.updated-task-recurrence-settings-activity",
          defaultMessage: "updated task recurrence settings",
        }),
      has_chklist_chk: (isInSpecificOrder) =>
        isInSpecificOrder
          ? t({
              id: "tasks-page.updated-subtasks-specific-order-activity",
              defaultMessage:
                "updated subtasks to be completed in specific order",
            })
          : t({
              id: "tasks-page.updated-subtasks-any-order-activity",
              defaultMessage: "updated subtasks to be completed in any order",
            }),
      custom_fields: () =>
        t({
          id: "tasks-page.updated-custom-field-activity",
          defaultMessage: "updated custom field",
        }),
    }),
    [getPriorityDetailsById, getStatusById, localeAwareFormat, t]
  );

  const baseChangeRenderer = useCallback(
    <
      TKey extends keyof UpdatedActionChanges,
      TValue extends EnsuredUpdatedActionChangeValue<TKey>
    >(
      key: TKey,
      value: TValue
    ): ReactNode | undefined => changeKeyToMessageRendererMap[key]?.(value),
    [changeKeyToMessageRendererMap]
  );

  const customFieldUpdatedMessageRenderer = useCallback(
    (fieldId: string, rawValue: string) => {
      const matchingField = getCustomFieldById(fieldId);

      if (!matchingField) {
        return undefined;
      }

      return t(
        {
          id: "tasks-page.updated-custom-field-detailed-activity",
          defaultMessage: "updated {fieldName} to {value}",
        },
        {
          fieldName: <b>{matchingField.name}</b>,
          value: (
            <b>
              <Linkify>
                {formatCustomFieldValue(matchingField, rawValue)}
              </Linkify>
            </b>
          ),
        }
      );
    },
    [formatCustomFieldValue, getCustomFieldById, t]
  );

  const customFieldRemovedMessageRenderer = useCallback(
    (fieldId: string) => {
      const matchingField = getCustomFieldById(fieldId);

      if (!matchingField) {
        return undefined;
      }

      return t(
        {
          id: "tasks-page.removed-custom-field-activity",
          defaultMessage: "removed {fieldName}",
        },
        {
          fieldName: <b>{matchingField.name}</b>,
        }
      );
    },
    [getCustomFieldById, t]
  );

  const customFieldsChangeRenderer = useCallback(
    (
      newCustomFields: Record<string, string>,
      oldCustomFields: Record<string, string>
    ) => {
      const newKeys = Object.keys(newCustomFields);
      const oldKeys = Object.keys(oldCustomFields);

      const addedKey = newKeys.find((key) => !oldKeys.includes(key));

      if (addedKey) {
        return customFieldUpdatedMessageRenderer(
          addedKey,
          newCustomFields[addedKey]
        );
      }

      const removedKey = oldKeys.find((key) => !newKeys.includes(key));

      if (removedKey) {
        return customFieldRemovedMessageRenderer(removedKey);
      }

      const editedKey = newKeys.find(
        (key) =>
          oldKeys.includes(key) && newCustomFields[key] !== oldCustomFields[key]
      );

      if (editedKey) {
        const editedValue = newCustomFields[editedKey];

        // If the edited value is falsy, we consider it as a removal
        if (!editedValue) {
          return customFieldRemovedMessageRenderer(editedKey);
        }

        return customFieldUpdatedMessageRenderer(
          editedKey,
          newCustomFields[editedKey]
        );
      }

      return undefined;
    },
    [customFieldRemovedMessageRenderer, customFieldUpdatedMessageRenderer]
  );

  const checklistMovedChangeRenderer = useCallback(
    (
      items: NonUndefined<UpdatedActionChanges["checklist_moved"]>,
      transferContext: Pick<UpdatedActionChanges, "from_task" | "to_task">
    ) => {
      assert(
        !!(transferContext.from_task || transferContext.to_task),
        "Either from_task or to_task must be present"
      );

      const isMovedFrom = !!transferContext.from_task;

      const taskName = (
        isMovedFrom
          ? transferContext.from_task?.name
          : transferContext.to_task?.name
      ) as string;

      const messageDescriptor: MessageDescriptor = isMovedFrom
        ? {
            id: "tasks-page.moved-subtasks-from-task-activity",
            defaultMessage:
              "moved {count} {count, plural, one {subtask} other {subtasks}}: {items} from {taskName}",
          }
        : {
            id: "tasks-page.moved-subtasks-to-task-activity",
            defaultMessage:
              "moved {count} {count, plural, one {subtask} other {subtasks}}: {items} to {taskName}",
          };

      return t(messageDescriptor, {
        count: items.length,
        items: joinReactNodesInHumanReadableForm(
          items.map((item) => (
            <Linkify>
              <Mentionify>
                <b>{item.c_name}</b>
              </Mentionify>
            </Linkify>
          ))
        ),
        taskName: <b>{taskName}</b>,
      });
    },
    [t]
  );

  const fallbackUpdatedMessageRenderer = useCallback(
    () =>
      t({
        id: "tasks-page.updated-task-activity",
        defaultMessage: "updated this task",
      }),
    [t]
  );

  const extractMessageFromChanges = useCallback(
    (changes: UpdatedActionChanges) => {
      const changeMessages = Object.keys(changes).reduce<ReactNode[]>(
        (acc, key) => {
          const changeKey = key as keyof UpdatedActionChanges;

          const changeKeysToBeSkipped: (keyof UpdatedActionChanges)[] = [
            "old_custom_fields", // it's used while processing `custom_fields` change to get the diff between new and old fields
            "from_task", // it's used while processing `checklist_moved` change
            "to_task", // it's used while processing `checklist_moved` change
          ];

          if (changeKeysToBeSkipped.includes(changeKey)) {
            return acc;
          }

          const changeValue = changes[
            changeKey
          ] as EnsuredUpdatedActionChangeValue<keyof UpdatedActionChanges>;

          let changeMessage: ReactNode;

          try {
            if (changeKey === "custom_fields") {
              assert(
                !!changes.custom_fields && !!changes.old_custom_fields,
                "Both new and old custom fields must be present"
              );

              changeMessage = customFieldsChangeRenderer(
                changes.custom_fields,
                changes.old_custom_fields
              );
            } else if (changeKey === "checklist_moved") {
              assert(
                !!changes.checklist_moved,
                "Checklist items must be present"
              );

              changeMessage = checklistMovedChangeRenderer(
                changes.checklist_moved,
                {
                  from_task: changes.from_task,
                  to_task: changes.to_task,
                }
              );
            } else {
              changeMessage = baseChangeRenderer(changeKey, changeValue);
            }
          } catch {
            // Do nothing
          }

          if (!changeMessage) {
            // eslint-disable-next-line no-console
            console.warn(
              "Couldn't parse the following task module log change",
              { changeKey, changeValue }
            );

            return acc;
          }

          return [...acc, changeMessage];
        },
        []
      );

      if (changeMessages.length === 0) {
        return fallbackUpdatedMessageRenderer();
      }

      return joinReactNodesInHumanReadableForm(changeMessages);
    },

    [
      customFieldsChangeRenderer,
      checklistMovedChangeRenderer,
      baseChangeRenderer,
      fallbackUpdatedMessageRenderer,
    ]
  );
  // #endregion

  const taskUpdatedMessageRenderer = useCallback(
    (username: string, changes: UpdatedActionChanges) =>
      joinReactNodes(
        [<b>{username}</b>, extractMessageFromChanges(changes)],
        " "
      ),
    [extractMessageFromChanges]
  );

  const taskDeletedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-removed-task-activity",
          defaultMessage: "{username} removed this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskCommentedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-commented-task-activity",
          defaultMessage: "{username} commented on this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskCommentRenderer = useCallback(
    (content: string) => (
      <Linkify>
        <Mentionify>{content}</Mentionify>
      </Linkify>
    ),
    []
  );

  const taskViewedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-viewed-task-activity",
          defaultMessage: "{username} viewed this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskArchivedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-archived-task-activity",
          defaultMessage: "{username} archived this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskRestoredMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-restored-task-activity",
          defaultMessage: "{username} restored this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskDownloadedMessageRenderer = useCallback(
    (username: string, fileName: string) =>
      t(
        {
          id: "tasks-page.attachment-downloaded-activity",
          defaultMessage: "{username} downloaded attachment {fileName}",
        },
        {
          username: <b>{username}</b>,
          fileName: <b>{fileName}</b>,
        }
      ),
    [t]
  );

  const memoizedRenderers = useMemo(
    () => ({
      taskCreatedMessageRenderer,
      taskCreatedByExternalUserMessageRenderer,
      taskUpdatedMessageRenderer,
      taskDeletedMessageRenderer,
      taskCommentedMessageRenderer,
      taskCommentRenderer,
      taskViewedMessageRenderer,
      taskArchivedMessageRenderer,
      taskRestoredMessageRenderer,
      taskDownloadedMessageRenderer,
    }),
    [
      taskCreatedMessageRenderer,
      taskCreatedByExternalUserMessageRenderer,
      taskUpdatedMessageRenderer,
      taskDeletedMessageRenderer,
      taskCommentedMessageRenderer,
      taskCommentRenderer,
      taskViewedMessageRenderer,
      taskArchivedMessageRenderer,
      taskRestoredMessageRenderer,
      taskDownloadedMessageRenderer,
    ]
  );

  return memoizedRenderers;
};
