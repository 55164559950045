import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import {
  Avatar,
  DataLoadingWrapper,
  LoadingSpinner,
  Menu,
  PlainButton,
  SidebarDrawer,
} from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useMemo } from "react";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useSelector } from "react-redux";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useCustomFieldTypes } from "@jugl-web/domain-resources/custom-fields/hooks/useCustomFieldTypes";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { useNavigateToChat } from "@web-src/modules/chats/hooks/useNavigateToChat";
import {
  PersonalInfoElement,
  PersonalInfoElementProps,
} from "./components/PersonalInfoElement";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as ChatIcon } from "./assets/chat.svg";
import { ReactComponent as CallIcon } from "./assets/call.svg";
import { ReactComponent as EducationIcon } from "./assets/education.svg";
import { ReactComponent as CertificateIcon } from "./assets/certificate.svg";
import { ReactComponent as AwardIcon } from "./assets/award.svg";
import { ReactComponent as ServicesIcon } from "./assets/services.svg";
import { ReactComponent as WorkExperienceIcon } from "./assets/work-experience.svg";
import { ReactComponent as TrophyLineIcon } from "./assets/trophy-line.svg";
import { ReactComponent as LocationPinIcon } from "./assets/location-pin.svg";
import { ReactComponent as UserLocationLineIcon } from "./assets/user-location-line.svg";
import backgroundImage from "./assets/background.png";

export const PeopleInfoSidebar: React.FC<{
  isOpen: boolean;
  userId?: string;
  onRequestClose: () => void;
}> = ({ isOpen, userId, onRequestClose }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);
  const { hasEmployeeLikeRole } = useUserRole();
  const { call } = useCallInvitation();
  const navigateToChat = useNavigateToChat();
  const { usersApi } = useRestApiProvider();
  const { customFieldTypeToDetails } = useCustomFieldTypes();

  const { data: userCustomFields } = usersApi.useGetUserCustomFieldsQuery({
    entityId,
  });

  const { data: profile, isFetching } = usersApi.useGetUserProfileQuery(
    { params: { user_id: userId || "", entity_id: entityId } },
    { skip: !isOpen || !userId, refetchOnMountOrArgChange: true }
  );

  const bookmarks = useMemo(
    () =>
      userCustomFields?.value
        .map((field) => {
          const foundItem = profile?.work_info?.find(
            (item) => Object.values(item)[0] === field.id
          );

          return {
            ...field,
            value: foundItem ? Object.values(foundItem)[1] : null,
          };
        })
        .filter((item) => item.bookmark > 0 && item.value)
        .map((item) => {
          if (item.type === "phone" && item.value) {
            const [, code, number] = item.value.split(",");
            return {
              ...item,
              value: `(${code}) ${number}`,
            };
          }

          return item;
        }) || [],
    [userCustomFields, profile?.work_info]
  );

  const { profile: userProfile, isLoading } = useUserGeneralProfile({
    entityId,
    userId,
    skip: !isOpen || !userId,
  });
  const personalInfoItems: (PersonalInfoElementProps & {
    id: string;
    isHidden: boolean;
  })[] = useMemo(
    () =>
      profile
        ? [
            {
              id: "education",
              icon: <EducationIcon />,
              backgroundColor: "green",
              primaryText: t({
                id: "people-info-sidebar-component.education",
                defaultMessage: "Education",
              }),
              items: profile.educations.map((item) => ({
                id: item.id,
                primaryText: item.name,
                secondaryText: item.where,
                startDate: item.start,
                endDate: item.end,
              })),
              isHidden: !profile.educations.length,
            },
            {
              id: "current-city",
              icon: <LocationPinIcon />,
              backgroundColor: "green",
              primaryText: t({
                id: "people-info-sidebar-component.current-city",
                defaultMessage: "Current City",
              }),
              items: profile.locations.map((item) => ({
                id: item.id,
                primaryText: [item.city, item.state, item.country]
                  .filter(Boolean)
                  .join(", "),
                startDate: item.moved_in,
                endDate: item.moved_out,
              })),
              isHidden: !profile.locations.length,
            },
            {
              id: "birth-place",
              icon: <UserLocationLineIcon />,
              backgroundColor: "green",
              primaryText: t({
                id: "people-info-sidebar-component.birth-place",
                defaultMessage: "Native/Birth Place",
              }),
              secondaryText: [
                profile.native?.city,
                profile.native?.state,
                profile.native?.country,
              ]
                .filter(Boolean)
                .join(", "),
              isHidden: !profile.native,
            },
            {
              id: "awards",
              icon: <TrophyLineIcon />,
              backgroundColor: "pink",
              primaryText: t({
                id: "people-info-sidebar-component.awards",
                defaultMessage: "Awards",
              }),
              items: profile.awards.map((item) => ({
                id: item.id,
                primaryText: item.name,
                secondaryText: item.awarded_by,
                startDate: item.date,
              })),
              isHidden: !profile.awards.length,
            },
            {
              id: "certifications",
              icon: <CertificateIcon />,
              backgroundColor: "pink",
              primaryText: t({
                id: "people-info-sidebar-component.certifications",
                defaultMessage: "Certifications",
              }),
              items: profile.certificates.map((item) => ({
                id: item.id,
                primaryText: item.name,
                secondaryText: item.issued_by,
                startDate: item.completion,
                endDate: item.expires_on,
              })),
              isHidden: !profile.certificates.length,
            },
            {
              id: "achievements",
              icon: <AwardIcon />,
              backgroundColor: "pink",
              primaryText: t({
                id: "people-info-sidebar-component.achievements",
                defaultMessage: "Achievements",
              }),
              items: profile.achievements.map((item) => ({
                id: item.id,
                primaryText: item.title,
                secondaryText: <Linkify>{item.ref}</Linkify>,
                startDate: item.date,
              })),
              isHidden: !profile.achievements.length,
            },
            {
              id: "service",
              icon: <ServicesIcon />,
              backgroundColor: "pink",
              primaryText: t({
                id: "people-info-sidebar-component.service",
                defaultMessage: "Service",
              }),
              items: profile.services.map((item) => ({
                id: item.id,
                primaryText: item.service_type,
                secondaryText: <Linkify>{item.ref}</Linkify>,
                startDate: item.since,
                endDate: item.until,
              })),
              isHidden: !profile.services.length,
            },
            {
              id: "experience",
              icon: <WorkExperienceIcon />,
              backgroundColor: "purple",
              primaryText: t({
                id: "people-info-sidebar-component.experience",
                defaultMessage: "Experience",
              }),
              items: profile.careers.map((item) => ({
                id: item.id,
                primaryText: item.title,
                secondaryText: item.org,
                startDate: item.start,
                endDate: item.end,
                current: item.current,
              })),
              isHidden: !profile.careers.length,
            },
          ]
        : [],
    [profile, t]
  );

  const filteredPersonalInfoItems = useMemo(
    () => personalInfoItems.filter((item) => !item.isHidden),
    [personalInfoItems]
  );

  const shouldShowMenu =
    userId !== meId &&
    userProfile &&
    userId &&
    !hasEmployeeLikeRole &&
    (hasRoleAccessToModule(userProfile.role, AppModule.chat) ||
      hasRoleAccessToModule(userProfile.role, AppModule.calls));

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      customHeader={
        <div className="flex items-center justify-between py-2.5 px-8 shadow-[2px_-6px_24px_rgba(18,22,34,0.16)]">
          <span className="text-dark font-secondary font-medium leading-4">
            {t({
              id: "people-info-sidebar-component.member-info",
              defaultMessage: "Member Info",
            })}
          </span>
          <PlainButton onClick={onRequestClose}>
            <CloseIcon />
          </PlainButton>
        </div>
      }
    >
      <DataLoadingWrapper isLoading={isLoading}>
        {userProfile ? (
          <>
            <div
              className="flex shrink-0 items-center justify-between gap-2 truncate bg-[length:178%] bg-[position:-49px_-139px] bg-no-repeat p-8"
              style={{
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <div className="flex items-center gap-[14px] truncate">
                <Avatar
                  username={userProfile.displayName}
                  imageUrl={userProfile.avatar}
                  size="4xl"
                />
                <div className="font-secondary flex flex-col gap-1 truncate">
                  <span className="truncate text-lg font-medium leading-[140%] text-[#333]">
                    {userProfile.displayName}
                  </span>
                  <span className="text-sm text-[#69757C]">
                    {userProfile.department?.name ||
                      t({
                        id: "common.no-department",
                        defaultMessage: "No department",
                      })}
                  </span>
                </div>
              </div>
              {shouldShowMenu && (
                <Menu
                  placement="bottom-end"
                  autoClose
                  sections={[
                    [
                      {
                        id: "chat",
                        label: t({
                          id: "people-info-sidebar-component.open-chat",
                          defaultMessage: "Open chat",
                        }),
                        icon: <ChatIcon />,
                        onSelect: () => {
                          navigateToChat(userId);
                          onRequestClose();
                        },
                        isHidden: !hasRoleAccessToModule(
                          userProfile.role,
                          AppModule.chat
                        ),
                      },
                      {
                        id: "call",
                        label: t({
                          id: "people-info-sidebar-component.start-voice-call",
                          defaultMessage: "Start a Voice Call",
                        }),
                        icon: <CallIcon />,
                        onSelect: () => {
                          call({ callType: "audio", userId });
                          onRequestClose();
                        },
                        isHidden: !hasRoleAccessToModule(
                          userProfile.role,
                          AppModule.calls
                        ),
                      },
                    ],
                  ]}
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger
                      ref={triggerRef}
                      as={PlainButton}
                      className="hover:bg-dark/5 flex h-8 w-8 items-center justify-center rounded-full transition-colors"
                    >
                      <MoreIcon />
                    </Trigger>
                  )}
                />
              )}
            </div>
            {profile?.user_id === userId && (
              <div className="jugl__custom-scrollbar overflow-y-auto">
                {bookmarks.length > 0 && (
                  <>
                    <div className="flex flex-col py-6 px-8">
                      <span className="text-grey font-secondary mb-6 text-sm font-medium leading-[21px]">
                        {t({
                          id: "people-info-sidebar-component.workspace-info",
                          defaultMessage: "Workspace Info",
                        })}
                      </span>
                      {bookmarks.map((bookmark, idx) => (
                        <React.Fragment key={bookmark.id}>
                          <PersonalInfoElement
                            primaryText={bookmark.name}
                            secondaryText={
                              bookmark.type === "email" ? (
                                <Linkify className="text-[#828282] underline">
                                  {bookmark.value}
                                </Linkify>
                              ) : (
                                bookmark.value
                              )
                            }
                            backgroundColor="blue"
                            icon={
                              <div className="flex items-center justify-center">
                                {React.createElement(
                                  customFieldTypeToDetails[bookmark.type]
                                    .primaryIcon,
                                  {
                                    className: "w-5 h-5 text-primary-600",
                                  }
                                )}
                              </div>
                            }
                          />
                          {idx !== bookmarks.length - 1 && (
                            <div className="bg-grey-200 my-5 h-px w-full shrink-0" />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="bg-grey-200 h-2 w-full" />
                  </>
                )}
                {isFetching && <LoadingSpinner className="mt-10" />}
                {filteredPersonalInfoItems.length > 0 && !isFetching && (
                  <div className="flex flex-col py-6 px-8">
                    <span className="text-grey font-secondary mb-6 text-sm font-medium leading-[21px]">
                      {t({
                        id: "people-info-sidebar-component.personal",
                        defaultMessage: "Personal",
                      })}
                    </span>
                    {filteredPersonalInfoItems.map((item, idx) => (
                      <React.Fragment key={item.id}>
                        <PersonalInfoElement {...item} />
                        {idx !== filteredPersonalInfoItems.length - 1 && (
                          <div className="bg-grey-200 my-5 h-px w-full shrink-0" />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="text-dark font-secondary flex h-full w-full items-center justify-center text-sm font-medium">
            {t({
              id: "people-info-sidebar-component.profile-not-found",
              defaultMessage: "Profile not found",
            })}
          </div>
        )}
      </DataLoadingWrapper>
    </SidebarDrawer>
  );
};
