import {
  AvatarSelect,
  AvatarSelectSize,
  Button,
  InteractiveContainer,
  SidebarDrawer,
  TextInput,
} from "@jugl-web/ui-components";
import { useRestApiProvider, UserGeneralProfile } from "@jugl-web/rest-api";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import { useToast, useTranslations } from "@jugl-web/utils";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ReactComponent as PeopleIcon } from "./assets/people.svg";
import { SelectMembersDrawer } from "../SelectMembersDrawer";

export const CreateWorkspaceDrawer: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const [file, setFile] = useState<File>();
  const [name, setName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<UserGeneralProfile[]>([]);
  const [isSelectMembersDrawerOpen, setIsSelectMembersDrawerOpen] =
    useState(false);

  const { workspacesApi } = useRestApiProvider();
  const { setActiveChat } = useActiveChat();
  const { toast } = useToast();

  const [createWorkspace, { isLoading }] =
    workspacesApi.useCreateWorkspaceMutation();

  const resetState = useCallback(() => {
    setIsSelectMembersDrawerOpen(false);
    setSelectedUsers([]);
    setFile(undefined);
    setName("");
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen, resetState]);

  const handleCreateWorkspace = useCallback(async () => {
    if (!selectedUsers.length) {
      toast(
        t({
          id: "chats-page.select-group-members-to-proceed",
          defaultMessage: "Select Group Members to proceed",
        }),
        { variant: "error" }
      );
      return;
    }

    const workspace = await createWorkspace({
      entityId: entity.id,
      data: {
        opts: {
          title: name,
        },
        participants: selectedUsers.map((user) => user.id),
        display_pic_file: file,
      },
    });
    if ("data" in workspace) {
      // TODO: type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setActiveChat((workspace.data as any).id, true);
      onRequestClose();
    }
  }, [
    t,
    createWorkspace,
    setActiveChat,
    onRequestClose,
    toast,
    name,
    selectedUsers,
    file,
    entity,
  ]);

  return (
    <>
      <SidebarDrawer
        isOpen={isOpen}
        onClose={onRequestClose}
        title={
          <div className="flex items-center gap-3">
            <PeopleIcon />
            <span>
              {t({
                id: "chats-page.new-group-chat",
                defaultMessage: "New Group Chat",
              })}
            </span>
          </div>
        }
      >
        <SidebarDrawer.Content className="flex flex-col pt-6">
          <div className="flex w-full flex-col items-center px-8">
            <AvatarSelect
              showRemoveButton
              value={file}
              onSave={setFile}
              size={AvatarSelectSize.xl}
            />
            <TextInput
              label={t({
                id: "chats-page.group-name",
                defaultMessage: "Group Name",
              })}
              placeholder={t({
                id: "chats-page.group-name",
                defaultMessage: "Group Name",
              })}
              isRequired
              classNames={{
                wrapperClassName: "mt-4 w-full",
              }}
              value={name}
              onChange={(e) => setName(e.currentTarget.value.trimStart())}
            />
            <InteractiveContainer
              className="bg-grey-100 hover:bg-grey-200 mt-6 flex w-full items-center justify-between gap-4 rounded-xl py-2 px-3 transition-colors"
              onClick={() => setIsSelectMembersDrawerOpen(true)}
            >
              <div className="flex items-center gap-3">
                <div className="bg-primary flex h-[38px] w-[38px] items-center justify-center rounded-full">
                  <UserIcon />
                </div>
                <span className="text-dark font-secondary leading-4">
                  {t({
                    id: "chats-page.add-members",
                    defaultMessage: "Add members",
                  })}
                </span>
              </div>
              <PlusIcon />
            </InteractiveContainer>
          </div>
          <div className="jugl__custom-scrollbar mt-2.5 flex w-full flex-col gap-2.5 overflow-y-auto px-8">
            {selectedUsers.map((user) => (
              <UserListItem
                key={user.id}
                entityId={entity.id}
                userId={user.id}
                variant="web"
                mode="delete"
                isSelected
                onSelect={() =>
                  setSelectedUsers((prev) =>
                    prev.filter((selectedUser) => selectedUser.id !== user.id)
                  )
                }
                defaultSubTitle="department"
              />
            ))}
          </div>
        </SidebarDrawer.Content>
        <SidebarDrawer.Actions className="flex items-center justify-center py-8">
          <Button
            className="h-10 w-[300px]"
            isDisabled={!name || isLoading}
            onClick={handleCreateWorkspace}
          >
            {t({
              id: "chats-page.create-group",
              defaultMessage: "Create group",
            })}
          </Button>
        </SidebarDrawer.Actions>
      </SidebarDrawer>
      <SelectMembersDrawer
        isOpen={isSelectMembersDrawerOpen}
        users={selectedUsers}
        onSelectUsers={setSelectedUsers}
        onRequestClose={() => setIsSelectMembersDrawerOpen(false)}
      />
    </>
  );
};
