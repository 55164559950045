import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as MessageIcon } from "../../assets/message.svg";

interface TaskCommentsFabProps {
  isVisible: boolean;
  hasUnreadItems: boolean;
  className?: string;
  onClick: () => void;
}

export const TaskCommentsFab: FC<TaskCommentsFabProps> = ({
  isVisible,
  hasUnreadItems,
  className,
  onClick,
}) => (
  <button
    type="button"
    className={cx(
      "border-grey-100 flex h-[74px] w-[74px] cursor-pointer items-center justify-center rounded-2xl border-4 border-solid bg-white shadow-[0px_12px_16px_0px_rgba(25,25,25,0.14)] transition",
      isVisible
        ? "scale-100 opacity-100 hover:scale-110 active:scale-[1.2]"
        : "scale-0 opacity-0",
      className
    )}
    onClick={onClick}
  >
    <div className="relative">
      <MessageIcon />
      {hasUnreadItems && (
        <div className="bg-gradients-danger absolute right-0.5 -top-px h-4 w-4 rounded-full border border-solid border-white" />
      )}
    </div>
  </button>
);
