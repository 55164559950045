import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskSearchQueryFiltersPanel } from "@jugl-web/domain-resources/tasks/components/TaskSearchQueryFiltersPanel";
import {
  SearchQueryFilter,
  useTaskSearchQueryFilters,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskSearchQueryFilters";
import { useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useCallback } from "react";

export const TaskSearchQueryFiltersPopover: React.FC = () => {
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const { searchQueryFilters, changeSearchQueryFilters } = useTaskFiltering();
  const { tasksSource } = useTasksPageContext();
  const { taskSearchQueryFilters } = useTaskSearchQueryFilters({
    entityId,
    source: tasksSource,
  });

  const onFilterSelect = useCallback(
    (searchQueryFilter: SearchQueryFilter) => {
      const isFilterSelected = searchQueryFilters.includes(
        searchQueryFilter.id
      );
      if (searchQueryFilter.id === "all") {
        if (searchQueryFilters.length === taskSearchQueryFilters.length - 2) {
          changeSearchQueryFilters([]);
          return;
        }
        changeSearchQueryFilters(
          taskSearchQueryFilters
            .filter((el) => el.id !== "all" && el.id !== "name")
            .map(({ id }) => id)
        );
        return;
      }
      if (isFilterSelected) {
        changeSearchQueryFilters(
          searchQueryFilters.filter(
            (filterId) => filterId !== searchQueryFilter.id
          )
        );
        return;
      }
      changeSearchQueryFilters([...searchQueryFilters, searchQueryFilter.id]);
    },
    [changeSearchQueryFilters, searchQueryFilters, taskSearchQueryFilters]
  );

  return (
    <div className="flex w-[320px] flex-col gap-4 rounded-xl bg-white p-4 drop-shadow-[0_5px_16px_rgba(0,0,0,0.16)]">
      <span className="text-dark font-secondary text-sm font-medium leading-[140%]">
        {t({
          id: "tasks-page.search-task-by",
          defaultMessage: "Search Task by",
        }).concat(":")}
      </span>
      <TaskSearchQueryFiltersPanel
        entityId={entityId}
        onFilterSelect={onFilterSelect}
        searchQueryFilters={searchQueryFilters}
        source={tasksSource}
      />
    </div>
  );
};
