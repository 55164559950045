import * as yup from "yup";
import { PreviewDashboardDto } from "./PreviewDashboardDto";
import {
  ExpectedDashboardLayoutConfig,
  PreviewDashboardModel,
} from "./PreviewDashboardModel";

const layoutConfigEntrySchema = yup.object({
  x: yup.number().required(),
  y: yup.number().required(),
  w: yup.number().required(),
  h: yup.number().required(),
});

const hasValidLayoutConfig = (
  layoutConfig: unknown
): layoutConfig is ExpectedDashboardLayoutConfig => {
  if (typeof layoutConfig !== "object" || layoutConfig === null) {
    return false;
  }

  let isValid = true;

  Object.keys(layoutConfig).forEach((key) => {
    const entry = layoutConfig[key as keyof typeof layoutConfig];

    if (!layoutConfigEntrySchema.isValidSync(entry)) {
      isValid = false;
    }
  });

  return isValid;
};

export const transformPreviewDashboardDtoToModel = (
  dto: PreviewDashboardDto
): PreviewDashboardModel => {
  const { layout_config: layoutConfig, ...rest } = dto;

  if (!hasValidLayoutConfig(layoutConfig)) {
    throw new Error("Invalid layout config");
  }

  return {
    ...rest,
    layout_config: layoutConfig,
  };
};
