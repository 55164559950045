import { FC } from "react";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { ReactComponent as AddCallIcon } from "./assets/plus.svg";

export const NewCallPicker: FC = () => {
  const { t } = useTranslations();
  const { me } = useMe();
  const { entity } = useEntitySelectedProvider();
  const { getListBoxProps, users } = useUserListBox({
    entityId: entity.id,
    module: AppModule.calls,
  });
  const { call } = useCallInvitation();
  const itemsWithtoutMe = users
    .filter((user) => user.id !== me?.id)
    .map((user) => ({ id: user.id, value: user }));
  return (
    <ResourcePickerPopover
      placement="bottom-end"
      onSelect={({ item, onClose }) => {
        call({ callType: "audio", userId: item.id });
        onClose();
      }}
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger ref={triggerRef} as={Button} className="h-10 capitalize">
          {t({
            id: "calls-page.start-call",
            defaultMessage: "Start Call",
          })}
          <AddCallIcon />
        </Trigger>
      )}
      {...getListBoxProps({
        items: itemsWithtoutMe,
      })}
    />
  );
};
