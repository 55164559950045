import { cx } from "@jugl-web/utils";
import { ReactNode, HTMLAttributes, forwardRef } from "react";

/**
 * Props for the FlexibleLayout component.
 */
export interface MultiSectionLayoutProps
  extends HTMLAttributes<HTMLDivElement> {
  /** The content to be rendered in the header section. */
  header?: ReactNode;
  /** The main content to be rendered. */
  children?: ReactNode;
  /** The content to be rendered in the left side section. */
  leftSide?: ReactNode;
  /** The content to be rendered in the right side section. */
  rightSide?: ReactNode;
  /** The content to be rendered in the footer section. */
  footer?: ReactNode;
  classNames?: {
    rightSide?: string;
    leftSide?: string;
    header?: string;
    footer?: string;
    content?: string;
  };
}

/**
 * A multi layout component with sections for header, content, left side, right side, and footer.
 * @param {FlexibleLayoutProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const MultiSectionLayout = forwardRef<
  HTMLDivElement,
  MultiSectionLayoutProps
>(
  (
    {
      header,
      leftSide,
      rightSide,
      footer,
      children,
      className,
      classNames,
      ...rest
    },
    ref
  ) => (
    <div ref={ref} {...{ ...rest, className: cx("flex h-full", className) }}>
      {leftSide && (
        <div className={cx("flex-none", classNames?.leftSide)}>{leftSide}</div>
      )}
      <div className="flex h-full w-full flex-auto flex-col">
        {header && (
          <div className={cx("w-full", classNames?.header)}>{header}</div>
        )}
        <div
          className={cx("h-full flex-1 overflow-y-auto", classNames?.content)}
        >
          {children}
        </div>
        {footer && (
          <div className={cx("w-full", classNames?.footer)}>{footer}</div>
        )}
      </div>
      {rightSide && (
        <div
          className={cx(
            "h-full flex-none overflow-y-auto",
            classNames?.rightSide
          )}
        >
          {rightSide}
        </div>
      )}
    </div>
  )
);
