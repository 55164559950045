import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { BoardAvatar } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  SIDEBAR_EXPANDED_KEY,
} from "@jugl-web/utils/storage";
import { useUnreadIndicators } from "@web-src/components/UnreadIndicatorsProvider";
import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { useCallback, useMemo, useState } from "react";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { TabRoute } from "../../../types";
import { SidebarItem } from "../types";

import { ReactComponent as DashboardIconLight } from "../assets/dashboard-light.svg";
import { ReactComponent as DashboardIconDark } from "../assets/dashboard-dark.svg";
import { ReactComponent as DashboardIconBlue } from "../assets/dashboard-blue.svg";

import { ReactComponent as CallsIconBlue } from "../assets/calls-blue.svg";
import { ReactComponent as CallsIconDark } from "../assets/calls-dark.svg";
import { ReactComponent as CallsIconLight } from "../assets/calls-light.svg";

import { ReactComponent as ChatsIconBlue } from "../assets/chats-blue.svg";
import { ReactComponent as ChatsIconDark } from "../assets/chats-dark.svg";
import { ReactComponent as ChatsIconLight } from "../assets/chats-light.svg";

import { ReactComponent as PeopleIconBlue } from "../assets/people-blue.svg";
import { ReactComponent as PeopleIconDark } from "../assets/people-dark.svg";
import { ReactComponent as PeopleIconLight } from "../assets/people-light.svg";

import { ReactComponent as ClientsIconBlue } from "../assets/clients-blue.svg";
import { ReactComponent as ClientsIconDark } from "../assets/clients-dark.svg";
import { ReactComponent as ClientsIconLight } from "../assets/clients-light.svg";

import { ReactComponent as DriveIconBlue } from "../assets/drive-blue.svg";
import { ReactComponent as DriveIconDark } from "../assets/drive-dark.svg";
import { ReactComponent as DriveIconLight } from "../assets/drive-light.svg";

import { ReactComponent as OrderFormsIconBlue } from "../assets/order-forms-blue.svg";
import { ReactComponent as OrderFormsIconDark } from "../assets/order-forms-dark.svg";
import { ReactComponent as OrderFormsIconLight } from "../assets/order-forms-light.svg";

import { ReactComponent as ReportsIconBlue } from "../assets/reports-blue.svg";
import { ReactComponent as ReportsIconDark } from "../assets/reports-dark.svg";
import { ReactComponent as ReportsIconLight } from "../assets/reports-light.svg";

import { ReactComponent as MoreIconBlue } from "../assets/more-blue.svg";
import { ReactComponent as MoreIconDark } from "../assets/more-dark.svg";
import { ReactComponent as MoreIconLight } from "../assets/more-light.svg";

import { ReactComponent as ScheduleIconBlue } from "../assets/schedule-blue.svg";
import { ReactComponent as ScheduleIconDark } from "../assets/schedule-dark.svg";
import { ReactComponent as ScheduleIconLight } from "../assets/schedule-light.svg";

import { ReactComponent as WorkspaceIconBlue } from "../assets/workspace-blue.svg";
import { ReactComponent as WorkspaceIconDark } from "../assets/workspace-dark.svg";
import { ReactComponent as WorkspaceIconLight } from "../assets/workspace-light.svg";

import { ReactComponent as IntegrationsIconLight } from "../assets/integrations-light.svg";
import { ReactComponent as IntegrationsIconDark } from "../assets/integrations-dark.svg";
import { ReactComponent as IntegrationsIconBlue } from "../assets/integrations-blue.svg";

import { ReactComponent as InventoryIconBlue } from "../assets/inventory-blue.svg";
import { ReactComponent as InventoryIconDark } from "../assets/inventory-dark.svg";
import { ReactComponent as InventoryIconLight } from "../assets/inventory-light.svg";
import { getAccessibleSidebarItems } from "../utils";

const HOME_SIDEBAR_VISIBLE_ITEMS = 7;

export const useHomeSidebar = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(() =>
    getStorageItemWithFallback(SIDEBAR_EXPANDED_KEY, true)
  );

  const { entity } = useEntitySelectedProvider();
  const { customTheme } = useTheme();

  const { boards } = useTaskBoards({ entityId: entity.id });
  const { moduleUnreadIndicators } = useUnreadIndicators();

  const { hasEmployeeLikeRole } = useUserRole();

  const { logEvent } = useFCM();
  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  // TODO: come up with a better way to handle this
  const activeTab = window.location.pathname.split("/")[2] || "";
  const activePath = window.location.pathname.split("/").slice(2).join("/");

  const updateIsSidebarExpanded = useCallback((isExpanded: boolean) => {
    setIsSidebarExpanded(isExpanded);
    saveItemToStorage(SIDEBAR_EXPANDED_KEY, isExpanded);
  }, []);

  const sidebarItems = useMemo<SidebarItem[]>(() => {
    const dashboardItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: DashboardIconLight,
        dark: DashboardIconDark,
        blue: DashboardIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.dashboard",
        defaultMessage: "Dashboard",
      }),
      active: activeTab === "dashboard",
      requiredUserRoles: appModulesToRequiredUserRoles.dashboard,
      href: `/${entity.id}/dashboard`,
    };

    const chatItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: ChatsIconLight,
        dark: ChatsIconDark,
        blue: ChatsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.chats",
        defaultMessage: "Chats",
      }),
      active: activeTab === "chats",
      unreadCount: moduleUnreadIndicators?.chat,
      requiredUserRoles: appModulesToRequiredUserRoles.chat,
      href: `/${entity.id}/chats`,
      onClick: () => {
        logEvent("navigation_chat");
      },
    };

    const peopleItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: PeopleIconLight,
        dark: PeopleIconDark,
        blue: PeopleIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.people",
        defaultMessage: "People",
      }),
      active: activeTab === "people",
      unreadCount: moduleUnreadIndicators?.people,
      requiredUserRoles: appModulesToRequiredUserRoles.people,
      href: `/${entity?.id}/people`,
    };

    const driveItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: DriveIconLight,
        dark: DriveIconDark,
        blue: DriveIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.drive",
        defaultMessage: "Drive",
      }),
      active: activeTab === "drive",
      unreadCount: moduleUnreadIndicators?.drive,
      requiredUserRoles: appModulesToRequiredUserRoles.drive,
      href: `/${entity.id}/drive`,
    };

    const callsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: CallsIconLight,
        dark: CallsIconDark,
        blue: CallsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.calls",
        defaultMessage: "Calls",
      }),
      active: activeTab === "calls",
      unreadCount: moduleUnreadIndicators?.call,
      requiredUserRoles: appModulesToRequiredUserRoles.calls,
      href: `/${entity.id}/calls`,
    };

    const travelLogItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: ScheduleIconLight,
        dark: ScheduleIconDark,
        blue: ScheduleIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.travel-log",
        defaultMessage: "Travel Log",
      }),
      active: activeTab === "travel-log",
      requiredUserRoles: appModulesToRequiredUserRoles.travelLog,
      href: `/${entity.id}/travel-log`,
      onClick: () => {
        logEvent("navigation_travel_log");
      },
    };

    const clientsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: ClientsIconLight,
        dark: ClientsIconDark,
        blue: ClientsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.Customers",
        defaultMessage: "Customers",
      }),
      active: activeTab === "customers",
      unreadCount: moduleUnreadIndicators?.customer,
      requiredUserRoles: appModulesToRequiredUserRoles.clients,
      requiredSubscriptionModule: SubscriptionPlanModuleId.clients,
      href: `/${entity.id}/customers`,
    };

    const integrationsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: IntegrationsIconLight,
        dark: IntegrationsIconDark,
        blue: IntegrationsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.integrations",
        defaultMessage: "Integrations",
      }),
      active: activeTab === "integrations",
      requiredUserRoles: appModulesToRequiredUserRoles.integrations,
      href: generateUrl("integrationsWhatsappAccount"),
    };

    const orderFormsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: OrderFormsIconLight,
        dark: OrderFormsIconDark,
        blue: OrderFormsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.forms",
        defaultMessage: "Forms",
      }),
      active: activeTab === "orders",
      requiredUserRoles: appModulesToRequiredUserRoles.orderForms,
      href: `/${entity.id}/orders/forms`,
    };

    const workspaceItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: WorkspaceIconLight,
        dark: WorkspaceIconDark,
        blue: WorkspaceIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.workspace",
        defaultMessage: "Workspace",
      }),
      active: activeTab.includes("workspace"),
      requiredUserRoles: appModulesToRequiredUserRoles.workspace,
      href: `/${entity.id}/workspace/settings`,
    };

    const inventoryItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: InventoryIconLight,
        dark: InventoryIconDark,
        blue: InventoryIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.inventory",
        defaultMessage: "Inventory",
      }),
      active: activeTab === "inventory",
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
      href: generateUrl("inventoryItems"),
    };

    const reportsItem: SidebarItem = {
      type: "accordion",
      SvgIcon: {
        light: ReportsIconLight,
        dark: ReportsIconDark,
        blue: ReportsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.reports",
        defaultMessage: "Reports",
      }),
      items: [
        {
          type: "link",
          title: t({
            id: "home-sidebar-component.tasks",
            defaultMessage: "Tasks",
          }),
          active: activePath === "reports/tasks",
          requiredUserRoles: appModulesToRequiredUserRoles.taskReports,
          requiredSubscriptionModule: SubscriptionPlanModuleId.reports,
          href: `/${entity.id}/reports/tasks`,
        },
        {
          type: "link",
          title: t({
            id: "home-sidebar-component.time-clock",
            defaultMessage: "Time Clock",
          }),
          active: activePath === "reports/timeclock",
          requiredUserRoles: appModulesToRequiredUserRoles.timeClockReports,
          requiredSubscriptionModule: SubscriptionPlanModuleId.reports,
          href: `/${entity.id}/reports/timeclock`,
        },
        {
          type: "link",
          title: t({
            id: "home-sidebar-component.scheduled-reports",
            defaultMessage: "Scheduled Reports",
          }),
          active:
            activeTab === TabRoute.scheduledReports ||
            activeTab === TabRoute.scheduledReportsForm,
          requiredUserRoles: appModulesToRequiredUserRoles.scheduledReports,
          requiredSubscriptionModule: SubscriptionPlanModuleId.reports,
          href: `/${entity.id}/scheduled-reports`,
        },
      ],
    };

    const debugItem: SidebarItem = {
      type: "accordion",
      customIcon: <>⚙️</>,
      title: "Debug",
      items: [
        {
          type: "link",
          title: "User settings",
          active: activePath === "debug/user-settings",
          href: `/${entity.id}/debug/user-settings`,
        },
        {
          type: "link",
          title: "Subscription",
          active: activePath === "debug/subscription",
          href: `/${entity.id}/debug/subscription`,
        },
        {
          type: "link",
          title: "Mobile app",
          active: activePath === "debug/mobile-app",
          href: `/${entity.id}/debug/mobile-app`,
        },
      ],
    };

    const devModeItems = isAppInDevMode() ? [debugItem] : [];

    const items = [
      dashboardItem,
      chatItem,
      inventoryItem,
      peopleItem,
      orderFormsItem,
      driveItem,
      integrationsItem,
      callsItem,
      clientsItem,
      travelLogItem,
      reportsItem,
      workspaceItem,
      ...devModeItems,
    ];

    const accessibleItems = getAccessibleSidebarItems(items, entity.role);

    if (accessibleItems.length > HOME_SIDEBAR_VISIBLE_ITEMS) {
      return [
        ...accessibleItems.slice(0, HOME_SIDEBAR_VISIBLE_ITEMS),
        {
          type: "accordion",
          SvgIcon: {
            light: MoreIconLight,
            dark: MoreIconDark,
            blue: MoreIconBlue,
          }[customTheme],
          title: t({
            id: "home-sidebar-component.more",
            defaultMessage: "More",
          }),
          items: accessibleItems.slice(HOME_SIDEBAR_VISIBLE_ITEMS),
        },
      ];
    }

    return accessibleItems;
  }, [
    customTheme,
    t,
    activeTab,
    moduleUnreadIndicators,
    entity,
    generateUrl,
    activePath,
    logEvent,
  ]);

  const taskBoardSidebarItems = useMemo<SidebarItem[]>(
    () => [
      {
        type: "link",
        href: generateUrl("tasksTasks", { boardId: "my" }),
        title: t({
          id: "home-sidebar-component.my-tasks",
          defaultMessage: "My Tasks",
        }),
        active: activeTab === TabRoute.tasks && activePath === "tasks/board/my",
        customIcon: <BoardAvatar size="md" name="M" color="#AB59E4" />,
        unreadCount: moduleUnreadIndicators?.task.my,
      },
      ...(!hasEmployeeLikeRole
        ? [
            {
              type: "link",
              href: generateUrl("tasksTasks", { boardId: "team" }),
              title: t({
                id: "home-sidebar-component.team-tasks",
                defaultMessage: "Team Tasks",
              }),
              active:
                activeTab === TabRoute.tasks &&
                activePath === "tasks/board/team",
              customIcon: <BoardAvatar size="md" name="T" color="#F36021" />,
            } as SidebarItem,
          ]
        : []),
      ...boards.map((board) => ({
        type: "link" as const,
        href: generateUrl("tasksTasks", { boardId: board.id }),
        title: board.name,
        active:
          activeTab === TabRoute.tasks &&
          activePath === `tasks/board/${board.id}`,
        customIcon: (
          <BoardAvatar size="md" name={board.name} color={board.color} />
        ),
        unreadCount: moduleUnreadIndicators?.task[board.id],
      })),
    ],
    [
      t,
      activeTab,
      activePath,
      moduleUnreadIndicators?.task,
      boards,
      hasEmployeeLikeRole,
      generateUrl,
    ]
  );

  return {
    sidebarItems,
    taskBoardSidebarItems,
    isSidebarExpanded,
    setIsSidebarExpanded,
    updateIsSidebarExpanded,
  };
};
