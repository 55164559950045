import { AppModule } from "@jugl-web/domain-resources/common/types";
import { UsersAvatarStack } from "@jugl-web/domain-resources/users/components/UsersAvatarStack";
import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { PreviewDashboardModel } from "@jugl-web/rest-api/dashboard/models/PreviewDashboard";
import { CreateDashboardAttributes } from "@jugl-web/rest-api/dashboard/types";
import { Alert, FormGroup } from "@jugl-web/ui-components";
import { Segment } from "@jugl-web/ui-components/cross-platform/forms/Segment";
import { SelectTrigger } from "@jugl-web/ui-components/cross-platform/forms/SelectTrigger";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { assert, useToast, useTranslations } from "@jugl-web/utils";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { FC, useEffect, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as DashboardIcon } from "./assets/dashboard.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

interface FormValues {
  name: string;
  isPrivate: boolean;
  members: string[];
}

const transformFormValuesToDashboardDtoAttributes = (
  values: FormValues,
  meId: string
): CreateDashboardAttributes => ({
  name: values.name,
  type: values.isPrivate ? "private" : "public",
  users: [
    { user_id: meId, role: "owner" },
    ...(values.isPrivate
      ? values.members.map((userId) => ({
          user_id: userId,
          role: "member" as const,
        }))
      : []),
  ],
});

const transformDashboardModelAttributesToFormValues = (
  dashboard: PreviewDashboardModel
): FormValues => ({
  name: dashboard.name,
  isPrivate: dashboard.type === "private",
  members: dashboard.users
    .filter((user) => user.role === "member")
    .map((user) => user.user_id),
});

const defaultFormValues: FormValues = {
  name: "",
  isPrivate: false,
  members: [],
};

interface ManageDashboardDialogProps {
  entityId: string;
  meId: string;
  isOpen: boolean;
  editingDashboard?: PreviewDashboardModel;
  onClose: () => void;
  onAfterCreate?: (dashboard: PreviewDashboardModel) => void;
}

const MIN_DASHBOARD_NAME_LENGTH = 6;
const MAX_DASHBOARD_NAME_LENGTH = 100;

export const ManageDashboardDialog: FC<ManageDashboardDialogProps> = ({
  entityId,
  meId,
  isOpen,
  editingDashboard,
  onClose,
  onAfterCreate,
}) => {
  const isEditMode = !!editingDashboard;

  const formId = useId();
  const { formState, control, register, watch, reset, setFocus, handleSubmit } =
    useForm<FormValues>({
      defaultValues: defaultFormValues,
    });

  const discardChangesConfirmationDialogState = useConfirmationDialogState();

  const { dashboardApi } = useRestApiProvider();

  const [createDashboard, { isLoading: isCreatingDashboard }] =
    dashboardApi.useCreateDashboardMutation();

  const [updateDashboard, { isLoading: isUpdatingDashboard }] =
    dashboardApi.useUpdateDashboardMutation();

  const { getListBoxProps, clearSearchQuery } = useUserListBox({
    entityId,
    excludeUsers: [meId],
    module: AppModule.dashboard,
  });

  const { toast } = useToast();
  const { t } = useTranslations();

  const name = watch("name");
  const isPrivate = watch("isPrivate");

  const handleCreateDashboard = async (values: FormValues) => {
    const response = await createDashboard({
      entityId,
      attributes: transformFormValuesToDashboardDtoAttributes(values, meId),
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.dashboard-created",
          defaultMessage: "Dashboard has been created",
        })
      );
      onClose();
      onAfterCreate?.(response.data);
    }
  };

  const handleUpdateDashboard = async (values: FormValues) => {
    assert(!!editingDashboard, "Editing dashboard is required");

    const response = await updateDashboard({
      entityId,
      attributes: transformFormValuesToDashboardDtoAttributes(values, meId),
      dashboardId: editingDashboard.id,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.dashboard-updated",
          defaultMessage: "Dashboard has been updated",
        })
      );
      onClose();
    }
  };

  const handleSafeClose = () => {
    if (formState.isDirty) {
      discardChangesConfirmationDialogState.open({ onConfirm: onClose });
      return;
    }

    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      window.setTimeout(() => {
        setFocus("name");
      }, 50);
    }
  }, [isOpen, reset, setFocus]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (isEditMode) {
      reset(transformDashboardModelAttributesToFormValues(editingDashboard));
      return;
    }

    reset(defaultFormValues);
  }, [isOpen, isEditMode, reset, editingDashboard]);

  return (
    <>
      <Alert
        isOpen={isOpen}
        onRequestClose={handleSafeClose}
        isCloseButtonVisible
        header={
          <div className="flex items-center gap-2.5">
            <DashboardIcon />
            <span>
              {isEditMode
                ? t({
                    id: "dashboard-page.edit-dashboard",
                    defaultMessage: "Edit Dashboard",
                  })
                : t({
                    id: "dashboard-page.new-dashboard",
                    defaultMessage: "New Dashboard",
                  })}
            </span>
          </div>
        }
        buttons={[
          {
            type: "submit",
            form: formId,
            color: "primary",
            isDisabled: isCreatingDashboard || isUpdatingDashboard,
            className: "max-w-[300px] h-10 mx-auto",
            text: isEditMode
              ? t({ id: "common.save", defaultMessage: "Save" })
              : t({ id: "common.create", defaultMessage: "Create" }),
          },
        ]}
        content={
          <form
            id={formId}
            className="flex flex-col gap-8 text-left"
            onSubmit={handleSubmit(
              isEditMode ? handleUpdateDashboard : handleCreateDashboard
            )}
          >
            <TextInput
              label={t({
                id: "form-controls.dashboard-name.label",
                defaultMessage: "Dashboard Name",
              })}
              isRequired
              isInvalid={!!formState.errors.name}
              lengthIndicator={
                name.length >= 90
                  ? { max: MAX_DASHBOARD_NAME_LENGTH }
                  : undefined
              }
              errorMessage={formState.errors.name?.message}
              {...register("name", {
                required: true,
                minLength: {
                  value: MIN_DASHBOARD_NAME_LENGTH,
                  message: t(
                    {
                      id: "form-controls.dashboard-name.min-length-error-message",
                      defaultMessage:
                        "Name should contain at least {count} characters",
                    },
                    { count: MIN_DASHBOARD_NAME_LENGTH }
                  ),
                },
                maxLength: MAX_DASHBOARD_NAME_LENGTH,
              })}
            />
            <div className="flex flex-col gap-4">
              <Controller
                control={control}
                name="isPrivate"
                render={({ field }) => (
                  <Segment
                    isRequired
                    label={t({
                      id: "form-controls.dashboard-privacy.label",
                      defaultMessage: "Privacy",
                    })}
                    items={[
                      {
                        id: "general",
                        title: t({
                          id: "form-controls.dashboard-privacy.general-option",
                          defaultMessage: "General",
                        }),
                      },
                      {
                        id: "private",
                        title: t({
                          id: "form-controls.dashboard-privacy.private-option",
                          defaultMessage: "Private",
                        }),
                      },
                    ]}
                    selectedItem={field.value ? "private" : "general"}
                    onChange={(id) => field.onChange(id === "private")}
                  />
                )}
              />
              <div className="border-grey-400 border-b-0 border-l border-r-0 border-t-0 border-solid pl-2.5">
                <span className="font-secondary text-dark-700 text-sm leading-3">
                  {isPrivate
                    ? t(
                        {
                          id: "form-controls.dashboard-privacy.private-option-description",
                          defaultMessage:
                            "Dashboard with the Private Privacy type will be <b>visible only to you and selected users</b>",
                        },
                        {
                          b: (chunks: (JSX.Element | string)[]) => (
                            <span className="text-primary-800">{chunks}</span>
                          ),
                        }
                      )
                    : t(
                        {
                          id: "form-controls.dashboard-privacy.general-option-description",
                          defaultMessage:
                            "Dashboard with the General Privacy type will be <b>visible to everyone in this workspace</b>",
                        },
                        {
                          b: (chunks: (JSX.Element | string)[]) => (
                            <span className="text-primary-800">{chunks}</span>
                          ),
                        }
                      )}
                </span>
              </div>
            </div>
            {isPrivate && (
              <Controller
                control={control}
                name="members"
                render={({ field }) => (
                  <FormGroup
                    label={t({
                      id: "form-controls.dashboard-share.label",
                      defaultMessage: "Share with",
                    })}
                  >
                    <ResourcePickerPopover
                      placement="right"
                      className="w-[375px]"
                      renderTrigger={({
                        Trigger,
                        triggerRef,
                        isOpen: isPopoverOpen,
                      }) => (
                        <Trigger
                          ref={triggerRef}
                          as={SelectTrigger}
                          label={
                            field.value.length > 0
                              ? t(
                                  {
                                    id: "dashboard-page.members-count",
                                    defaultMessage:
                                      "{count} {count, plural, one {member} other {members}}",
                                  },
                                  { count: field.value.length }
                                )
                              : t({
                                  id: "common.tap-to-select",
                                  defaultMessage: "Tap to select",
                                })
                          }
                          isOpen={isPopoverOpen}
                          customIcon={
                            field.value.length > 0 ? (
                              <UsersAvatarStack
                                entityId={entityId}
                                userIds={field.value}
                                maxCount={5}
                                size="lg"
                              />
                            ) : (
                              <PlusIcon />
                            )
                          }
                          className="h-[58px] w-full"
                        />
                      )}
                      onSubmit={(ids) => {
                        field.onChange(ids);
                        clearSearchQuery();
                      }}
                      onUnmount={clearSearchQuery}
                      {...getListBoxProps({
                        selectionBehavior: { mode: "multiple" },
                        defaultSelectedIds: field.value,
                      })}
                    />
                  </FormGroup>
                )}
              />
            )}
          </form>
        }
        className="z-50 w-[560px]"
      />
      <Alert
        isOpen={discardChangesConfirmationDialogState.isOpen}
        title={t({
          id: "dashboard-page.discard-changes-warning-title",
          defaultMessage: "Discard changes",
        })}
        content={t({
          id: "dashboard-page.discard-changes-warning-title",
          defaultMessage:
            "If you discard, the dashboard information won't be saved",
        })}
        buttons={[
          {
            text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
            role: "close",
          },
          {
            text: t({ id: "common.discard", defaultMessage: "Discard" }),
            color: "tertiary",
            onClick: () =>
              discardChangesConfirmationDialogState.confirm({
                closeOnceConfirmed: true,
              }),
          },
        ]}
        onRequestClose={discardChangesConfirmationDialogState.close}
      />
    </>
  );
};
