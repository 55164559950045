import { useRestApiProvider, UserGeneralProfile } from "@jugl-web/rest-api";
import { assert, getUniqueId, useMemoCompare } from "@jugl-web/utils";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface UseMultipleUserProfilesOptions {
  entityId: string | undefined;
  userIds: string[];
  skip?: boolean;
}

export const useMultipleUserProfiles = ({
  entityId,
  userIds,
  skip,
}: UseMultipleUserProfilesOptions) => {
  const [profiles, setProfiles] = useState<UserGeneralProfile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { usersApi } = useRestApiProvider();
  const [getUserGeneralProfile] = usersApi.useLazyGetUserGeneralProfileQuery();
  const batchRequestIdRef = useRef(getUniqueId());

  const memoizedUserIds = useMemoCompare(userIds, isEqual);

  const profileByUserId = useMemo<Record<string, UserGeneralProfile>>(() => {
    const result: Record<string, UserGeneralProfile> = {};

    profiles.forEach((profile) => {
      result[profile.id] = profile;
    });

    return result;
  }, [profiles]);

  const loadProfiles = useCallback(async () => {
    assert(!!entityId, "entityId is required");

    setIsLoading(true);

    const batchRequestId = batchRequestIdRef.current;

    const responses = await Promise.all(
      memoizedUserIds.map((userId) =>
        getUserGeneralProfile({ entityId, params: { user_id: userId } }, true)
      )
    );

    if (batchRequestId !== batchRequestIdRef.current) {
      return;
    }

    setProfiles(
      responses.reduce<UserGeneralProfile[]>(
        (acc, response) => (response.data ? [...acc, response.data] : acc),
        []
      )
    );

    setIsLoading(false);
  }, [entityId, getUserGeneralProfile, memoizedUserIds]);

  useEffect(() => {
    if (!entityId || skip) {
      return undefined;
    }

    loadProfiles();

    return () => {
      batchRequestIdRef.current = getUniqueId();
    };
  }, [entityId, loadProfiles, skip]);

  return { profiles, profileByUserId, isLoading };
};
