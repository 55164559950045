import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChatItem } from "@web-src/modules/chats/types";
import { isoToLocalDate, useToast, useTranslations } from "@jugl-web/utils";
import useDebounce from "@web-src/hooks/useDebounce";
import { useMessages } from "@web-src/modules/chats/hooks/useMessages";
import { useSendMessageReadReceipt } from "@web-src/modules/chats/hooks/useSendMessageReadRecept";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  DataLoadingWrapper,
  LoadingAnimation,
  MultiSectionLayout,
} from "@jugl-web/ui-components";
import { VList, VListHandle } from "virtua";
import { ChatMessageModel } from "@jugl-web/rest-api";
import isToday from "date-fns/isToday";
import isThisYear from "date-fns/isThisYear";
import { useMe } from "@web-src/features/app/hooks/useMe";
import format from "date-fns-tz/format";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { usePrevious } from "react-use";
import { chatsApi } from "@web-src/features/api/createApi";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { ChatDateHeader } from "./components/ChatDateHeader";
import { ReactComponent as ChevronsDownIcon } from "./assets/chevrons-down.svg";
import { transformMessageAttachment } from "./utils/transformMessageAttachments";
import { useStickyIndexContext } from "../../contexts/StickyItemContext";
import { getInitFromMessage } from "./utils/getInitFromMessage";
import { ChatMessagesItem } from "./components/ChatMessagesItem";
import { useChatsPageWithSelectedChatProvider } from "../../../../providers/ChatsPageProvider";
import { useChatMessagesWindow } from "./ChatMessagesWindowProvider";

function useMemoOnce<T>(getter: () => T, deps: unknown[]) {
  const value = useRef<T>();
  const memorizedValue = useMemo(() => {
    if (value.current) {
      return value.current;
    }
    value.current = getter();
    return value.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return memorizedValue;
}

export interface ChatMessagesWindowHandle {
  scrollToBottom: () => void;
}

type ChatMessageItem =
  | { type: "message"; value: ChatMessageModel }
  | { type: "date"; value: string };

export const ChatMessagesWindow = forwardRef<
  ChatMessagesWindowHandle,
  {
    chat: ChatItem;
    disableMessageActions?: boolean;
  }
>(({ chat, disableMessageActions }, ref) => {
  const { toast } = useToast();
  const { entity } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const { me } = useMe();
  const { dateLocale } = useLanguage();
  const { activeChatState, activeChatId, setSearchedMessage } =
    useChatsPageWithSelectedChatProvider();
  const vListRef = useRef<VListHandle>(null);
  const { highlightMessage, highlightedMessageId } = useChatMessagesWindow();

  const { setActiveIndex: setActiveStickyIndex } = useStickyIndexContext();

  const [atBottom, setAtBottom] = useState(true);
  const debouncedAtBottom = useDebounce(atBottom, 500);

  const initFromMessageDetails = useMemoOnce(
    () => ({ current: getInitFromMessage(chat) }),
    [chat]
  );

  const [loadMessages] = chatsApi.useLazyMessagesQuery();

  const [scrollToMsgId, setScrollToMsgId] = useState<string | undefined>();
  // #region search and go to reply functionality
  const [messageIdToHighlight, setMessageIdToHighlight] = useState<string>();
  const initMsg = useRef<{
    id: string;
    created: string;
  } | null>(getInitFromMessage(chat) || null);

  const { updateItem } = useConversations();
  const {
    messages,
    load,
    isMessagesLoading,
    resetPagination,
    resetToBottom,
    newLastLoaded,
    oldLastLoaded,
    isMessagesError,
  } = useMessages({
    entityId: entity?.id,
    chatId: chat?.id,
    initFromMessage: initMsg.current || undefined,
  });
  const reset = useCallback(
    (
      toBottom?: boolean,
      initMsgFrom?: {
        id: string;
        created: string;
      }
    ) => {
      initMsg.current = initMsgFrom || getInitFromMessage(chat) || null;
      setScrollToMsgId(undefined);
      updateItem(
        { id: activeChatId, data: { isChatInitialized: false } },
        true
      );
      if (toBottom) {
        resetToBottom();
      } else {
        resetPagination();
      }
      initFromMessageDetails.current = undefined;
      isInitialScrollInitialized.current = null;
    },
    [
      initFromMessageDetails,
      resetPagination,
      resetToBottom,
      chat,
      updateItem,
      activeChatId,
    ]
  );

  const loadingMoreDirection = useRef<"new" | "old">();
  const previousMessagesIsLoading = usePrevious(isMessagesLoading);
  // #region set chat is initialized
  useEffect(() => {
    if (newLastLoaded) {
      updateItem({ id: activeChatId, data: { isChatInitialized: true } }, true);
    }
  }, [activeChatId, newLastLoaded, updateItem]);
  // #endregion

  useEffect(() => {
    if (
      previousMessagesIsLoading &&
      !isMessagesLoading &&
      loadingMoreDirection.current
    ) {
      loadingMoreDirection.current = undefined;
    }
  }, [isMessagesLoading, previousMessagesIsLoading]);

  const scrollToMsgWithId = useCallback(
    (messageId: string) => {
      setScrollToMsgId(messageId);
    },
    [setScrollToMsgId]
  );
  useEffect(() => {
    if (activeChatState?.searchedMessage?.id) {
      setSearchedMessage(activeChatId, null);
      scrollToMsgWithId(activeChatState.searchedMessage.id);
    }
  }, [
    activeChatState?.searchedMessage?.id,
    activeChatId,
    setSearchedMessage,
    scrollToMsgWithId,
  ]);

  const { chatMessageItems, stickyIndexes } = useMemo(() => {
    const result: ChatMessageItem[] = [];
    const addedDates: string[] = [];
    const stickyIndexesResult: number[] = [];

    messages?.forEach((message) => {
      const date = isoToLocalDate(message.data.timestamp);
      const dateStrAlt = format(date, "yyyy-MM-dd");
      if (!addedDates.includes(dateStrAlt)) {
        addedDates.push(dateStrAlt);
        const dateStr = isToday(date)
          ? t({
              id: "common.today",
              defaultMessage: "Today",
            })
          : format(date, isThisYear(date) ? "d MMMM" : "d MMMM yyyy", {
              locale: dateLocale,
            });
        stickyIndexesResult.push(result.length);
        result.push({ type: "date", value: dateStr });
      }
      result.push({
        type: "message",
        value: transformMessageAttachment(message, entity?.id || "").data,
      });
    });
    return { chatMessageItems: result, stickyIndexes: stickyIndexesResult };
  }, [dateLocale, entity?.id, messages, t]);

  useEffect(() => {
    if (!scrollToMsgId) {
      return;
    }
    const showMessageNotFound = () =>
      toast(
        t({
          id: "chats-page.message-not-found",
          defaultMessage: "Message not found",
        }),
        { variant: "error" }
      );
    const currentMessageFindIndex = chatMessageItems?.findIndex(
      (messageItem) =>
        messageItem.type === "message" &&
        messageItem.value.msg_id === scrollToMsgId
    );
    if (currentMessageFindIndex !== -1) {
      if (chatMessageItems[currentMessageFindIndex].type === "message") {
        highlightMessage(
          (chatMessageItems[currentMessageFindIndex].value as ChatMessageModel)
            .msg_id
        );
      }
      vListRef.current?.scrollToIndex(currentMessageFindIndex, {
        align: "center",
        smooth: true,
      });
      setScrollToMsgId(undefined);
      return;
    }
    loadMessages({
      params: {
        entity_id: entity?.id,
        limit: 1,
        chat_id: activeChatId,
        message_id: scrollToMsgId,
      },
    })
      .unwrap()
      .then((response) => {
        const messageToScroll = response?.data[0];
        if (!messageToScroll) {
          showMessageNotFound();
          return;
        }
        setMessageIdToHighlight(messageToScroll.msg_id);
        setScrollToMsgId(undefined);
        reset(false, {
          created: messageToScroll.timestamp,
          id: messageToScroll.msg_id,
        });
      })
      .catch(() => {
        // pass, handled globally
      });
  }, [
    activeChatId,
    chatMessageItems,
    entity?.id,
    loadMessages,
    messages,
    reset,
    scrollToMsgId,
    t,
    toast,
    highlightMessage,
  ]);
  // #endregion

  const isFirstUnreadMsgIdInitialized = useRef<boolean>(false);
  const firstUnreadMsgRef = useRef<string | undefined>();
  const firstUnreadMsgId = useMemo(() => {
    if (firstUnreadMsgRef.current) {
      return firstUnreadMsgRef.current;
    }
    if (!messages?.length || isFirstUnreadMsgIdInitialized.current) {
      return undefined;
    }
    isFirstUnreadMsgIdInitialized.current = true;
    let firstUnreadMsgIdResult: string | undefined;
    if (chat.lastReadMessage) {
      const lastReadMsgIdx = messages?.findIndex(
        (item) => item.data.msg_id === chat.lastReadMessage?.msgId
      );
      if (lastReadMsgIdx !== -1) {
        firstUnreadMsgIdResult = messages
          .slice(lastReadMsgIdx + 1)
          .find((item) => item.data.from !== me?.id)?.data.msg_id;
      }
    } else if (chat.firstUnreadMessage) {
      firstUnreadMsgIdResult = messages.find(
        (item) => item.data.msg_id === chat.firstUnreadMessage?.msgId
      )?.data.msg_id;
    }
    firstUnreadMsgRef.current = firstUnreadMsgIdResult;
    return firstUnreadMsgIdResult;
  }, [chat, me?.id, messages]);

  // #region initial scroll init
  const isInitialScrollInitialized = useRef<boolean | null>(null);
  const isInitialScrollInitializationInProgress = useRef<boolean | null>(null);
  useEffect(() => {
    if (
      isMessagesLoading ||
      !chatMessageItems?.length ||
      isInitialScrollInitialized.current ||
      isInitialScrollInitializationInProgress.current
    ) {
      return;
    }
    isInitialScrollInitializationInProgress.current = true;
    if (initMsg.current) {
      const firstItemIdx = chatMessageItems?.findIndex(
        (item) =>
          item.type === "message" && initMsg.current?.id === item.value.msg_id
      );
      if (firstItemIdx !== -1) {
        vListRef.current?.scrollToIndex(firstItemIdx, { align: "center" });
        setTimeout(() => {
          isInitialScrollInitialized.current = true;
          isInitialScrollInitializationInProgress.current = false;
        }, 500);
        return;
      }
    }
    vListRef.current?.scrollToIndex(chatMessageItems.length - 1, {
      align: "end",
    });
    setTimeout(() => {
      isInitialScrollInitialized.current = true;
      isInitialScrollInitializationInProgress.current = false;
    }, 500);
  }, [chatMessageItems, initMsg, isMessagesLoading, messages]);
  // #endregion

  // #region read receipt
  const sendMessageReadReceipt = useSendMessageReadReceipt();
  const [messageToSendReadReceipt, setMessageToSendReadReceipt] =
    useState<ChatMessageModel>();
  const [sendingReadReceipt, setSendingReadReceipt] = useState<boolean>();
  const handleMessageShow = useCallback(
    (message: ChatMessageModel) => {
      if (message.msg_id === messageIdToHighlight) {
        highlightMessage(message.msg_id);
        setMessageIdToHighlight(undefined);
      }
      if (
        message.read ||
        !message.msg_receipt_id ||
        !chat?.id ||
        (me && me.id === message.from) ||
        (chat.lastReadMessage?.createdAt &&
          chat.lastReadMessage.createdAt >= message.timestamp) ||
        messageToSendReadReceipt?.msg_id === message.msg_id ||
        (messageToSendReadReceipt &&
          messageToSendReadReceipt.timestamp >= message.timestamp)
      ) {
        return;
      }
      setMessageToSendReadReceipt(message);
    },
    [messageIdToHighlight, chat, me, messageToSendReadReceipt, highlightMessage]
  );
  useEffect(() => {
    if (sendingReadReceipt || !messageToSendReadReceipt) {
      return undefined;
    }
    const debounceTimeout = setTimeout(() => {
      setSendingReadReceipt(true);
      if (!messageToSendReadReceipt || !chat.id) {
        return;
      }
      sendMessageReadReceipt({
        bulk: true,
        msgId: messageToSendReadReceipt.msg_id,
        receiptId: messageToSendReadReceipt.msg_receipt_id,
        chatId: chat.id,
        chatType: chat.type,
        msgTimestamp: messageToSendReadReceipt.timestamp,
      }).finally(() => {
        // TODO: error handling?
        setMessageToSendReadReceipt((current) => {
          if (current?.msg_id === messageToSendReadReceipt.msg_id) {
            return undefined;
          }
          return current;
        });
        setSendingReadReceipt(false);
      });
    }, 200);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    messageToSendReadReceipt,
    sendingReadReceipt,
    chat.id,
    chat.type,
    sendMessageReadReceipt,
  ]);
  // #endregion

  const handleScrollToBottom = useCallback(() => {
    if (newLastLoaded) {
      vListRef.current?.scrollToIndex(chatMessageItems.length - 1, {
        align: "end",
      });
      return;
    }
    setSearchedMessage(activeChatId, null);
    reset(true);
  }, [
    activeChatId,
    chatMessageItems.length,
    newLastLoaded,
    reset,
    setSearchedMessage,
  ]);

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => handleScrollToBottom(),
  }));

  const handleVListOnScroll = useCallback(
    (offset: number) => {
      if (!vListRef.current) {
        return;
      }
      if (isMessagesLoading || !isInitialScrollInitialized.current) {
        return;
      }
      const bottomOffset =
        offset - vListRef.current.scrollSize + vListRef.current.viewportSize;
      setAtBottom(bottomOffset > -5);

      const start = vListRef.current.findStartIndex();
      const activeStickyIndexToSet =
        [...stickyIndexes].reverse().find((index) => start >= index) || -1;
      setActiveStickyIndex(activeStickyIndexToSet);

      if (offset < 150 && !oldLastLoaded) {
        load({ loadDireaction: "old" });
        loadingMoreDirection.current = "old";
        return;
      }
      if (bottomOffset > -150 && !newLastLoaded) {
        load({ loadDireaction: "new" });
        loadingMoreDirection.current = "new";
      }
    },
    [
      isMessagesLoading,
      load,
      newLastLoaded,
      oldLastLoaded,
      setActiveStickyIndex,
      stickyIndexes,
    ]
  );

  const handleOnReplyPreviewClick = useCallback(
    async (message: ChatMessageModel) => {
      if (!message.payload.reply_attributes_map?.reply_msg_id) {
        return;
      }
      scrollToMsgWithId(message.payload.reply_attributes_map?.reply_msg_id);
    },
    [scrollToMsgWithId]
  );

  if ((isMessagesLoading || isMessagesError) && !chatMessageItems.length) {
    return (
      <DataLoadingWrapper
        isLoading={isMessagesLoading}
        isError={isMessagesError}
      />
    );
  }

  if (!chatMessageItems.length) {
    return (
      <div className="text-primary-800 font-secondary mt-6 flex w-full items-center justify-center text-center text-sm font-medium leading-[21px]">
        {t({
          id: "chats-page.no-chat-history-yet",
          defaultMessage: "No Chat History yet 👋",
        })}
      </div>
    );
  }

  return (
    <MultiSectionLayout className="bg-gray-100">
      <VList
        overscan={10}
        className="flex-1 pb-6"
        reverse
        shift={loadingMoreDirection.current === "old"}
        ref={vListRef}
        onScroll={handleVListOnScroll}
      >
        {isMessagesLoading && loadingMoreDirection.current === "old" && (
          <div className="flex justify-center py-2">
            <LoadingAnimation size="md" />
          </div>
        )}
        {chatMessageItems.map((item) => {
          if (item.type === "message") {
            return (
              <ChatMessagesItem
                key={item.value.msg_id}
                message={item.value}
                disableMessageActions={disableMessageActions}
                messages={messages}
                chat={chat}
                onReplyPreviewClick={handleOnReplyPreviewClick}
                onShowOnScreen={handleMessageShow}
                firstUnreadMsgId={firstUnreadMsgId}
                isHighlighted={
                  !!highlightedMessageId &&
                  highlightedMessageId === item.value.msg_id
                }
              />
            );
          }
          return <ChatDateHeader key={item.value} date={item.value} />;
        })}
        {isMessagesLoading && loadingMoreDirection.current === "new" && (
          <div className="flex justify-center py-2">
            <LoadingAnimation size="md" />
          </div>
        )}
      </VList>
      {(!debouncedAtBottom || !newLastLoaded) && (
        <div
          className="absolute bottom-[20px] right-[30px] z-50 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[18px] bg-black"
          onClick={handleScrollToBottom}
        >
          {chat.unreadCount && chat.unreadCount > 0 ? (
            <div className="bg-secondary-500 absolute top-[-10px] flex h-[16px] min-w-[20px] items-center justify-center rounded-[10px] p-[1px] text-[12px] font-bold text-white">
              {chat.unreadCount}
            </div>
          ) : undefined}
          <ChevronsDownIcon />
        </div>
      )}
    </MultiSectionLayout>
  );
});
