import {
  useTranslations,
  useBlockNavigation,
  useToast,
  cx,
  useAutoResizedTextarea,
} from "@jugl-web/utils";
import {
  HeaderBreadcrumbs,
  Button,
  Alert,
  InteractiveContainer,
  LoadingAnimation,
} from "@jugl-web/ui-components";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useForm, Controller } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import { InventoryItemCategory, useRestApiProvider } from "@jugl-web/rest-api";
import { useParams } from "react-router-dom";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { SectionDivider } from "../InventoryItemFormPage/components/SectionDivider/SectionDivider";
import { ReactComponent as NoImgIcon } from "./assets/no-img.svg";
import { WarehousePicker } from "../../components/WarehousePicker/WarehousePicker";
import { ReactComponent as ChevronIcon } from "./assets/chevron-down.svg";
import {
  MAX_NUMBER_VALUE,
  DESCRIPTION_MAX_LENGTH,
  MIN_NUMBER_VALUE,
} from "./consts";
import { ReactComponent as StockInputMinus } from "./assets/stock-input-minus.svg";
import { ReactComponent as StockInputPlus } from "./assets/stock-input-plus.svg";

export const InventoryItemEditStockFormPage = () => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const { entity } = useEntitySelectedProvider();
  const { inventoryApi } = useRestApiProvider();
  const { id } = useParams();
  const [loadItemData, { data }] = inventoryApi.useLazyGetInventoryItemQuery();
  const [updateInventoryItem, { isLoading: isUpdatingInventoryItem }] =
    inventoryApi.useUpdateInventoryItemMutation();
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
    register,
    watch,
  } = useForm<{
    warehouse: {
      id: string;
      name: string;
    };
    price: {
      value: string;
    };
    stock: number;
    description: string;
    stockValueChange: number;
    reorderPoint: number;
    category: InventoryItemCategory;
  }>({
    mode: "onChange",
    defaultValues: {
      warehouse: undefined,
      price: {
        value: undefined,
      },
      stock: undefined,
      description: undefined,
      stockValueChange: 0,
      reorderPoint: undefined,
      category: undefined,
    },
  });
  const { toast } = useToast();
  const description = watch("description");
  const stockValue = watch("stock");
  const stockValueChange = watch("stockValueChange");
  const descriptionTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps: descriptionTextareaProps } = useAutoResizedTextarea(
    descriptionTextareaRef
  );

  const confirmNavigation = useRef<(() => void) | null>(null);
  const skipNavigationAlert = useRef(false);

  useBlockNavigation(
    setIsDiscardChangesDialogOpen,
    confirmNavigation,
    skipNavigationAlert
  );

  const handleSubmitForm = handleSubmit(
    async (submittedData) => {
      if (!id) {
        return;
      }
      Object.keys(submittedData).forEach((key) => {
        const typedKey = key as keyof typeof submittedData;
        if (!dirtyFields[typedKey]) {
          delete submittedData[typedKey];
        }
      });

      if (submittedData.stockValueChange) {
        submittedData.stock = Number(stockValue) + Number(stockValueChange);
      }
      const response = await updateInventoryItem({
        entityId: entity.id,
        id,
        params: submittedData,
        isAdjustStock: true,
      });
      if ("data" in response) {
        toast(
          t(
            {
              id: "feedback.inventory-info-was-updated",
              defaultMessage: "{category} info was updated",
            },
            {
              category: "Item",
            }
          ),
          {
            variant: "success",
          }
        );
        skipNavigationAlert.current = true;
        navigateToPage("inventoryItems");
      }
    },
    (submitErrors) => {
      const mandatoryFieldsCount = Object.entries(submitErrors).filter(
        (err) =>
          err[1].message === "Mandatory field" ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          err[1]?.value?.message === "Mandatory field"
      ).length;
      if (mandatoryFieldsCount > 0) {
        toast(
          t(
            {
              id: "feedback.inventory-mandatory-fields-error",
              defaultMessage:
                "{count} Mandatory {count, plural, one {field} other {fields}} should be filled to proceed",
            },
            {
              count: mandatoryFieldsCount,
            }
          ),
          {
            variant: "error",
          }
        );
      }
    }
  );

  const mainImage = data?.img?.find((img) => img.order === 1) || data?.img?.[0];

  const handleStockChange = (
    type: "inc" | "dec" | "input",
    currentChangeValue: number,
    inputValue?: number
  ) => {
    if (type === "input") {
      return inputValue;
    }
    if (type === "dec") {
      return MIN_NUMBER_VALUE < Number(currentChangeValue) - 1
        ? Number(currentChangeValue) - 1
        : currentChangeValue;
    }
    return Number(currentChangeValue) + 1;
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    loadItemData({
      itemId: id,
      entityId: entity?.id,
    });
  }, [entity?.id, id, loadItemData]);

  useEffect(() => {
    reset({
      warehouse: data?.warehouse,
      price: {
        value: data?.price,
      },
      stock: data?.stock?.stock,
      reorderPoint: data?.stock?.reorder_point || undefined,
      description: data?.desc,
      category: data?.category,
      stockValueChange: 0,
    });
  }, [data, reset]);

  if (!!id && !data?.category) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingAnimation size="xl" />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <HeaderBreadcrumbs
        items={[
          {
            title: t({
              id: "inventory-item-form-page.inventory",
              defaultMessage: "Inventory",
            }),
            onClick: () => navigateToPage("inventoryItems"),
          },
          {
            title: t({
              id: "inventory-item-stock-form-page.adjust-stock",
              defaultMessage: "Adjust Stock",
            }),
          },
        ]}
      />
      <div className="flex grow flex-col gap-10 overflow-auto px-10 pt-[40px] pb-[24px]">
        <div className="relative flex w-full items-center gap-8">
          {data?.img.length ? (
            <img
              src={mainImage?.path}
              alt=""
              className="h-[80px] w-[80px] rounded-[14px]"
            />
          ) : (
            <div className="bg-grey-200 flex h-[80px] w-[80px] items-center justify-center rounded-[14px]">
              <NoImgIcon className="rounded-[14px]" />
            </div>
          )}
          <div className="w-1/2 flex-col gap-1">
            <div
              className="font-secondary text-dark truncate text-xl font-semibold leading-5 tracking-[1%]"
              title={data?.name}
            >
              {data?.name}
            </div>
            <div className="font-secondary text-grey-800 text-sm">
              {t({
                id: "inventory-items-page.item",
                defaultMessage: "Item",
              })}
            </div>
          </div>
        </div>
        <SectionDivider />

        <div className="grid max-w-[1000px] grid-cols-3  gap-10">
          <Controller
            control={control}
            name="warehouse"
            render={({ field: { onChange, value } }) => (
              <WarehousePicker
                value={value}
                onChange={onChange}
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Trigger
                    ref={triggerRef}
                    as={InteractiveContainer}
                    className="text-left"
                  >
                    <TextInput
                      label={t({
                        id: "inventory-item-form-page.warehouse",
                        defaultMessage: "Warehouse",
                      })}
                      placeholder={t({
                        id: "inventory-item-form-page.select-warehouse",
                        defaultMessage: "Select Warehouse",
                      })}
                      errorMessage={errors.warehouse?.message}
                      classNames={{
                        inputClassName: "truncate",
                        wrapperClassName: "w-full h-[59px] cursor-pointer",
                        endContentWrapperClassName: "bg-white",
                      }}
                      value={value?.name || ""}
                      endContent={
                        <ChevronIcon
                          className={cx(
                            "absolute right-5 top-[52px] transform transition-transform",
                            isOpen && "rotate-180"
                          )}
                        />
                      }
                    />
                  </Trigger>
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="price.value"
            rules={{
              validate: (value) => {
                if (Number(value) > MAX_NUMBER_VALUE) {
                  return t(
                    {
                      id: "inventory-item-form-page.price-too-high",
                      defaultMessage: "Max value is {maxPriceValue}",
                    },
                    {
                      maxPriceValue: MAX_NUMBER_VALUE,
                    }
                  );
                }
                if (!value) {
                  return t({
                    id: "inventory-item-form-page.mandatory-field",
                    defaultMessage: "Mandatory field",
                  });
                }
                return true;
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextInput
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                onKeyDown={(e) => {
                  const notAllowedCharacters = ["e", "E", "+", "-"];
                  if (notAllowedCharacters.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                type="number"
                min={0}
                max={MAX_NUMBER_VALUE}
                step={0.5}
                onBlur={() => {
                  const isNaN = Number.isNaN(value);
                  if (value) {
                    onChange(isNaN ? undefined : parseFloat(value).toFixed(2));
                  }
                }}
                isInvalid={!!errors.price?.value}
                label={t({
                  id: "inventory-item-form-page.price-unit",
                  defaultMessage: "Price/unit",
                })}
                onChange={(e) => onChange(e.target.value)}
                isRequired
                errorMessage={errors.price?.value?.message}
                value={value}
                endContent={
                  <div className="font-secondary text-dark text-base">
                    {entity.currency}
                  </div>
                }
              />
            )}
          />
          <TextInput
            onKeyDown={(e) => {
              const notAllowedCharacters = ["e", "E", "+", "-", ",", "."];
              if (notAllowedCharacters.includes(e.key)) {
                e.preventDefault();
              }
            }}
            {...register("reorderPoint", {
              validate: (value) => {
                if (Number(value) > MAX_NUMBER_VALUE) {
                  return t(
                    {
                      id: "inventory-item-form-page.max-number-value",
                      defaultMessage: "Max value is {maxNumberValue}",
                    },
                    {
                      maxNumberValue: MAX_NUMBER_VALUE,
                    }
                  );
                }
                return true;
              },
            })}
            label={t({
              id: "inventory-item-form-page.reorder-point",
              defaultMessage: "Reorder Point",
            })}
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
            errorMessage={errors.reorderPoint?.message}
            type="number"
            isInvalid={!!errors.reorderPoint}
            min={0}
          />
        </div>
        <div className="grid max-w-[1000px] grid-cols-3 gap-10">
          <TextInput
            label={t({
              id: "inventory-item-stock-form-page.stock-on-hand",
              defaultMessage: "Stock on hand",
            })}
            value={
              stockValue +
              (data?.stock?.in_process || 0) +
              Number(stockValueChange)
            }
            classNames={{
              labelClassName: "text-dark-700",
              inputWrapperClassName: "bg-grey-100",
              endContentWrapperClassName: "bg-[#F5F5F7]",
            }}
            isDisabled
            endContent={
              <div className="font-secondary text-dark text-base">
                {data?.unit}
              </div>
            }
          />
          <TextInput
            label={t({
              id: "inventory-item-stock-form-page.processing-orders",
              defaultMessage: "Processing orders",
            })}
            value={data?.stock?.in_process}
            classNames={{
              labelClassName: "text-dark-700",
              inputWrapperClassName: "bg-grey-100",
              endContentWrapperClassName: "bg-[#F5F5F7]",
            }}
            isDisabled
            endContent={
              <div className="font-secondary text-dark text-base">
                {data?.unit}
              </div>
            }
          />
          <TextInput
            label={t({
              id: "inventory-item-stock-form-page.available-for-sale",
              defaultMessage: "Available for sale",
            })}
            value={stockValue + Number(stockValueChange)}
            classNames={{
              labelClassName: "text-dark-700",
              inputWrapperClassName: "bg-grey-100",
              endContentWrapperClassName: "bg-[#F5F5F7]",
            }}
            isDisabled
            endContent={
              <div className="font-secondary text-dark text-base">
                {data?.unit}
              </div>
            }
          />
        </div>
        <div className="w-[1000px]">
          <Controller
            control={control}
            name="stockValueChange"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={t({
                  id: "inventory-item-form-page.new-items",
                  defaultMessage: "New items",
                })}
                classNames={{
                  startContentWrapperClassName: "bg-transparent",
                  inputClassName: "pointer-events-none",
                }}
                startContent={
                  <div className="flex gap-1.5 py-3">
                    <div
                      className="border-dark-100 hover:border-grey-400 flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-lg border-[1px] border-solid"
                      onClick={() => onChange(handleStockChange("dec", value))}
                    >
                      <StockInputMinus />
                    </div>
                    <input
                      className="border-dark-100 text-dark font-secondary outline-none[-moz-appearance:_textfield] hover:border-grey-400 flex h-[32px] min-w-[43px] select-none items-center justify-center rounded-lg border-[1px] border-solid text-center text-sm outline-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                      style={{
                        width: `calc(43px + ${value.toString().length * 8}px)`,
                      }}
                      value={value}
                      onKeyDown={(e) => {
                        const notAllowedCharacters = ["e", "E", "+", ".", ","];
                        if (notAllowedCharacters.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (
                          Number(e.target.value) > MIN_NUMBER_VALUE &&
                          Number(e.target.value) < MAX_NUMBER_VALUE
                        ) {
                          onChange(e.target.value);
                        }
                      }}
                      type="number"
                      min={-99999}
                      max={MAX_NUMBER_VALUE}
                    />
                    <div
                      className="border-dark-100 hover:border-grey-400 flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-lg border-[1px] border-solid"
                      onClick={() => onChange(handleStockChange("inc", value))}
                    >
                      <StockInputPlus />
                    </div>
                  </div>
                }
                endContent={
                  <div className="font-secondary text-dark text-base">
                    {data?.unit}
                  </div>
                }
              />
            )}
          />
        </div>
        <div className="w-[1000px]">
          <TextAreaInput
            label={t({
              id: "inventory-item-form-page.description",
              defaultMessage: "Description",
            })}
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
            {...descriptionTextareaProps}
            isInvalid={!!errors.description}
            isRequired
            errorMessage={
              (description?.length > DESCRIPTION_MAX_LENGTH - 11 &&
                `${description?.length}/${DESCRIPTION_MAX_LENGTH}`) ||
              errors.description?.message
            }
            {...register("description", {
              maxLength: {
                value: DESCRIPTION_MAX_LENGTH,
                message: `${description?.length}/${DESCRIPTION_MAX_LENGTH}`,
              },
              setValueAs: (value) => value.trim(),
              required: {
                value: true,
                message: t({
                  id: "inventory-item-form-page.mandatory-field",
                  defaultMessage: "Mandatory field",
                }),
              },
              validate: (value) => !!value.trim(),
            })}
          />
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-2.5 border-0 border-t-2 border-solid border-[#EEF2F5] py-6 px-[70px]">
        <Button
          color="grey"
          className="h-10 w-[140px]"
          isDisabled={isUpdatingInventoryItem}
          onClick={() => navigateToPage("inventoryItems")}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          className="h-10 w-[200px]"
          onClick={handleSubmitForm}
          isDisabled={false}
        >
          {t({
            id: "inventory-item-stock-form-page.adjust-stock",
            defaultMessage: "Adjust Stock",
          })}
        </Button>
      </div>
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "common.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "inventory-item-form-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: "Cancel",
            role: "close",
            onClick: () => {
              confirmNavigation.current = null;
              setIsDiscardChangesDialogOpen(false);
            },
          },
          {
            text: "Discard",
            color: "tertiary",
            onClick: (_, actions) => {
              confirmNavigation.current?.();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsDiscardChangesDialogOpen(false)}
      />
    </div>
  );
};
