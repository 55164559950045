import { Checkbox, InteractiveContainer } from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import {
  cx,
  useAppVariant,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import {
  SearchQueryFilter,
  useTaskSearchQueryFilters,
} from "../../hooks/useTaskSearchQueryFilters";

export const TaskSearchQueryFiltersPanel: React.FC<{
  entityId: string;
  searchQueryFilters: string[];
  source: TasksSource;
  onFilterSelect: (searchQueryFilter: SearchQueryFilter) => void;
}> = ({ entityId, searchQueryFilters, source, onFilterSelect }) => {
  const { taskSearchQueryFilters } = useTaskSearchQueryFilters({
    entityId,
    source,
  });
  const { t } = useTranslations();
  const { isWeb } = useAppVariant();

  const { searchQuery, inputProps } = useSearchInput();

  const filteredSearchQueryFilters = useMemo(
    () =>
      searchQuery
        ? taskSearchQueryFilters.filter(
            (searchQueryFilter) =>
              searchQueryFilter.title
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) &&
              searchQueryFilter.id !== "all"
          )
        : taskSearchQueryFilters,
    [taskSearchQueryFilters, searchQuery]
  );

  return (
    <div className="flex flex-col gap-4">
      <SearchInput variant="filled" color="grey" {...inputProps} />
      <div
        className={cx(
          "jugl__custom-scrollbar flex max-h-[300px] flex-col gap-2 overflow-y-auto",
          isWeb ? "max-h-[300px]" : "max-h-[500px]"
        )}
      >
        {filteredSearchQueryFilters.length ? (
          filteredSearchQueryFilters.map((searchQueryFilter) => {
            const isFilterSelected = searchQueryFilters.includes(
              searchQueryFilter.id
            );
            const isFilterDefault = searchQueryFilter.isDefault;
            const areAllFiltersSelected =
              taskSearchQueryFilters.length - 2 === searchQueryFilters.length;
            const isAllFiltersOptionSelected =
              searchQueryFilter.id === "all" && areAllFiltersSelected;

            return (
              <InteractiveContainer
                className={cx(
                  "hover:bg-grey-100 flex items-center justify-between gap-3 rounded-lg p-3 transition-colors",
                  (isFilterSelected ||
                    isFilterDefault ||
                    isAllFiltersOptionSelected) &&
                    "bg-[#EFF8FF] hover:bg-[#EFF8FF]"
                )}
                key={searchQueryFilter.id}
                onClick={() => onFilterSelect(searchQueryFilter)}
                isDisabled={searchQueryFilter.id === "name"}
              >
                <div className="flex items-center gap-3 overflow-hidden">
                  <div
                    className={cx(
                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-full",
                      searchQueryFilter.backgroundColor
                    )}
                  >
                    {searchQueryFilter.icon}
                  </div>
                  <span
                    className={cx(
                      "text-dark font-secondary truncate text-sm leading-[140%]",
                      searchQueryFilter.id === "all" && "font-medium"
                    )}
                  >
                    <Highlighter
                      autoEscape
                      searchWords={[searchQuery]}
                      textToHighlight={searchQueryFilter.title}
                      highlightClassName="text-primary font-semibold bg-transparent"
                    />
                    {searchQueryFilter.id === "all" && (
                      <span className="text-grey-800 font-secondary text-sm leading-[140%]">
                        {" "}
                        {taskSearchQueryFilters.length - 1}
                      </span>
                    )}
                  </span>
                </div>
                {isFilterDefault ? (
                  <span className="text-grey-800 font-secondary text-xs leading-[140%]">
                    {t({
                      id: "tasks-page.default",
                      defaultMessage: "Default",
                    })}
                  </span>
                ) : (
                  <Checkbox
                    readOnly
                    isChecked={isFilterSelected || isAllFiltersOptionSelected}
                  />
                )}
              </InteractiveContainer>
            );
          })
        ) : (
          <span className="font-secondary text-center text-sm leading-[160%] text-[#828282]">
            {t({
              id: "tasks-page.no-results",
              defaultMessage: "No Results 😔",
            })}
          </span>
        )}
      </div>
    </div>
  );
};
