import {
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { FC, ReactNode } from "react";
import { cx } from "@jugl-web/utils";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";

export interface FieldBoxProps {
  title: ReactNode;
  subtitle: ReactNode;
  customEndSlot?: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const FieldBox: FC<FieldBoxProps> = ({
  title,
  subtitle,
  customEndSlot,
  className,
  onClick,
}) => (
  <InteractiveContainer
    className={cx(
      "bg-grey-100 flex items-center justify-between rounded-xl px-8 py-6 transition-colors hover:bg-[#F6F6F7]",
      className
    )}
    onClick={onClick}
  >
    <div className="flex flex-col gap-1.5">
      <span className="text-dark text-base font-medium">{title}</span>
      <div className="flex items-center gap-1.5 text-xs text-[#828282]">
        {subtitle}
      </div>
    </div>
    {customEndSlot || (
      <PlainButton className="hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-full transition-colors">
        <ChevronRightIcon />
      </PlainButton>
    )}
  </InteractiveContainer>
);
