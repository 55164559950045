import { Menu, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { ChatItem } from "@web-src/modules/chats/types";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { FC, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as NotificationsMuteAllIcon } from "./assets/notifications_mute_all.svg";
import { ReactComponent as NotificationsMuteNoneIcon } from "./assets/notifications_mute_none.svg";
import { ReactComponent as ThreeDotsVertical } from "./assets/three-dots-vertical.svg";
import { HeaderButton } from "../HeaderButton";

export const PersonalChatMoreMenu: FC<{ chat: ChatItem }> = ({ chat }) => {
  const { getChatNotificationsPreferences, setChatNotificationsPreferences } =
    useNotifications();
  const { t } = useTranslations();
  const chatNotificationPreferences = useMemo(
    () => chat?.id && getChatNotificationsPreferences(chat.id),
    [chat?.id, getChatNotificationsPreferences]
  );

  const $notificationsIcon = useMemo(() => {
    switch (chatNotificationPreferences) {
      case "all":
        return <NotificationsMuteAllIcon />;
      case "none":
      default:
        return <NotificationsMuteNoneIcon />;
    }
  }, [chatNotificationPreferences]);

  return (
    <Menu
      placement="bottom-end"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          as={PlainButton}
          ref={triggerRef}
          onClick={(e) => e.stopPropagation()}
        >
          <HeaderButton icon={<ThreeDotsVertical />} />
        </Trigger>
      )}
      sections={[
        [
          {
            id: "1",
            label: t({
              id: "chats-page.mute-chat",
              defaultMessage: "Mute chat",
            }),
            icon: $notificationsIcon,
            submenu: {
              placement: "left",
              sections: [
                [
                  {
                    id: "none",
                    isSelected: chatNotificationPreferences === "none",
                    value: "none",
                    label: t({
                      id: "chats-page.unmute",
                      defaultMessage: "Unmute",
                    }),
                    toggle: "radio",
                    icon: <NotificationsMuteNoneIcon />,
                    onSelect: (e, close) => {
                      if (!chat?.id) {
                        return;
                      }
                      setChatNotificationsPreferences(
                        chat.id,
                        chat.type,
                        "none"
                      );
                      close();
                    },
                  },
                  {
                    id: "all",
                    isSelected: chatNotificationPreferences === "all",
                    value: "all",
                    label: t({
                      id: "chats-page.mute",
                      defaultMessage: "Mute",
                    }),
                    toggle: "radio",
                    icon: <NotificationsMuteAllIcon />,
                    onSelect: (e, close) => {
                      if (!chat?.id) {
                        return;
                      }
                      setChatNotificationsPreferences(
                        chat.id,
                        chat.type,
                        "all"
                      );
                      close();
                    },
                  },
                ],
              ],
            },
          },
        ],
      ]}
    />
  );
};
