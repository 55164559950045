export * from "./unread-indicators";

// TODO: cleanup

export enum EntityParticipantGender {
  Male = "Male",
  Female = "Female",
}

export type EntityUserProfile = {
  dob: string;
  first_name: string;
  gender: EntityParticipantGender;
  img: null | string;
  last_name: string;
};

export interface EntityProp {
  id: string;
  value: string;
}

export enum EntityPropType {
  subject = "subject",
  user_fields = "user_fields",
}

export enum EntityParticipantStatus {
  active = "active",
  inactive = "inactive",
  suspended = "suspnd",
}
export type InvitationLinkResponse = {
  created_by: {
    id: string;
    img: string | null;
    name: string;
  };
  entity: {
    id: string;
    img: string | null;
    name: string;
  };
  id: string;
  short_url: string;
  valid_till: string;
};
