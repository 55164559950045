import React from "react";
import { cx, useTranslations } from "@jugl-web/utils";

export const ReactionTile: React.FC<{
  isSelected: boolean;
  title?: string;
  count?: number;
  onClick: (reaction?: string) => void;
}> = ({ isSelected, title, count, onClick }) => {
  const { t } = useTranslations();
  return (
    <div
      className={cx(
        "flex h-[32px] w-[54px] shrink-0 cursor-pointer flex-nowrap items-center justify-center gap-[7px] rounded-[8px] border-[1px] border-solid shadow-sm shadow-gray-300",
        isSelected ? "border-secondary-500" : "border-transparent"
      )}
      onClick={() => onClick(title)}
    >
      <span
        className={cx(!title ? "text-[14px] text-gray-900" : "text-[24px]")}
      >
        {title ||
          t({
            id: "common.all",
            defaultMessage: "All",
          })}
      </span>
      <span className="text-[12px] text-gray-600">{count}</span>
    </div>
  );
};
