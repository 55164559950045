import {
  Alert,
  Button,
  InteractiveContainer,
  PlainButton,
  Popover,
} from "@jugl-web/ui-components";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import React, { useCallback, useMemo, useState } from "react";
import {
  cx,
  joinReactNodes,
  reorder,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import { UserCustomField, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { DraggableFieldBoxContainer } from "@jugl-web/domain-resources/custom-fields/components/DraggableFieldBoxContainer";
import { FieldBox } from "@jugl-web/domain-resources/custom-fields/components/FieldBox";
import { useCustomFieldTypes } from "@jugl-web/domain-resources/custom-fields/hooks/useCustomFieldTypes";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import Highlighter from "react-highlight-words";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import bookmarkBannerImage from "../assets/bookmark-banner.png";
import { MAX_USER_BOOKMARKS_COUNT } from "../consts";
import { ReactComponent as CheckIcon } from "../assets/check.svg";

export const BookmarkScreen: React.FC<{
  propId: string;
  userCustomFields: UserCustomField[];
  bookmarks: UserCustomField[];
}> = ({ userCustomFields, bookmarks, propId }) => {
  const { usersApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const [selectedFields, setSelectedFields] = useState<UserCustomField[]>([]);
  const { customFieldTypeToDetails } = useCustomFieldTypes();
  const [updateUserCustomFields] = usersApi.useUpdateUserCustomFieldsMutation();
  const { searchQuery, inputProps, reset } = useSearchInput();
  const deselectBookmarkAlertState = useConfirmationDialogState<{
    bookmarkName: string;
  }>();

  const handleUpdateUserCustomFields = useCallback(
    (bookmarkIds: string[]) => {
      const existingBookmarks = bookmarks.filter((bookmark) =>
        bookmarkIds.includes(bookmark.id)
      );

      const newBookmarkSet = [
        ...existingBookmarks.map(({ id }) => id),
        ...bookmarkIds.filter(
          (bookmarkId) =>
            !existingBookmarks.some((bookmark) => bookmark.id === bookmarkId)
        ),
      ];

      updateUserCustomFields({
        entityId,
        propId,
        value: userCustomFields.map((field) => {
          const isBookmark = newBookmarkSet.includes(field.id);

          return {
            ...field,
            bookmark: isBookmark
              ? newBookmarkSet.findIndex(
                  (bookmarkId) => bookmarkId === field.id
                ) + 1
              : 0,
          };
        }),
      });
    },
    [entityId, propId, updateUserCustomFields, userCustomFields, bookmarks]
  );

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const reorderedBookmarks = reorder(
      bookmarks,
      source.index,
      destination.index
    );

    updateUserCustomFields({
      value: userCustomFields.map((field) => {
        if (field.bookmark > 0) {
          return {
            ...field,
            bookmark:
              reorderedBookmarks.findIndex(
                (bookmark) => bookmark.id === field.id
              ) + 1,
          };
        }
        return field;
      }),
      entityId,
      propId,
    });
  };

  const filteredCustomFields = useMemo(
    () =>
      userCustomFields.filter((field) =>
        field.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [userCustomFields, searchQuery]
  );

  return (
    <>
      <BottomCenteredDrawer.Content className="flex flex-col items-center gap-8 p-6 pb-28">
        <img src={bookmarkBannerImage} alt="" className="w-full" />
        <div className="flex w-[500px] flex-col gap-2 text-center">
          <span className="text-dark font-secondary text-[22px] font-medium leading-[33px]">
            {t({
              id: "cpanel-page.bookmarked-fields",
              defaultMessage: "Bookmarked fields",
            })}
          </span>
          <span className="text-dark-600 font-secondary text-sm leading-[21px]">
            {t(
              {
                id: "cpanel-page.bookmarked-fields-description",
                defaultMessage:
                  "In addition to see the Members Team, you can also select and bookmark #{maxUserBookmarks} Fields to be always visible in Employees list",
              },
              { maxUserBookmarks: MAX_USER_BOOKMARKS_COUNT }
            )}
          </span>
        </div>
        <Popover
          placement="bottom"
          onMount={() => setSelectedFields(bookmarks)}
          onUnmount={reset}
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={PlainButton}
              className={cx(
                "border-grey-400 hover:border-primary flex h-14 w-[500px] shrink-0 items-center justify-between rounded-[10px] border border-solid px-5 transition-colors",
                isOpen && "border-primary"
              )}
            >
              {bookmarks.length ? (
                <span className="text-dark font-secondary leading-4">
                  {t(
                    {
                      id: "cpanel-page.fields-selected-count",
                      defaultMessage:
                        "{count}/{maxBookmarksCount} {count, plural, one {Field} other {Fields}} selected",
                    },
                    {
                      count: bookmarks.length,
                      maxBookmarksCount: MAX_USER_BOOKMARKS_COUNT,
                    }
                  )}
                </span>
              ) : (
                <span className="text-dark-600 font-secondary leading-4">
                  {t({
                    id: "common.tap-to-select",
                    defaultMessage: "Tap to select",
                  })}
                </span>
              )}
              <ArrowIcon
                className={cx("transition-transform", isOpen && "rotate-180")}
              />
            </Trigger>
          )}
        >
          {({ onClose }) => (
            <div className="shadow-[0px_5px_16px_rgba(0 0,0 0.16)] flex max-h-[400px] w-[320px] flex-col gap-2 overflow-hidden rounded-xl py-4">
              <div className="px-4">
                <SearchInput
                  variant="filled"
                  color="grey"
                  {...inputProps}
                  onClear={reset}
                  onReset={reset}
                />
              </div>
              {selectedFields.length >= MAX_USER_BOOKMARKS_COUNT && (
                <span className="font-secondary leading-2 text-tertiary-700 px-4 text-xs">
                  {t(
                    {
                      id: "cpanel-page.bookmarks-limit-alert",
                      defaultMessage:
                        "Bookmarks have limit of {maxUserBookmarks} Fields maximum",
                    },
                    { maxUserBookmarks: MAX_USER_BOOKMARKS_COUNT }
                  )}
                </span>
              )}
              <div className="jugl__custom-scrollbar flex flex-col gap-2 overflow-y-auto px-4">
                {filteredCustomFields.length ? (
                  filteredCustomFields.map((field) => {
                    const isFieldSelected = selectedFields.some(
                      (selectedField) => selectedField.id === field.id
                    );

                    return (
                      <InteractiveContainer
                        isDisabled={
                          selectedFields.length >= MAX_USER_BOOKMARKS_COUNT &&
                          !isFieldSelected
                        }
                        key={field.id}
                        className={cx(
                          "flex items-center justify-between gap-4 rounded-lg p-3 transition-colors",
                          isFieldSelected && "bg-[#EFF8FF]"
                        )}
                        onClick={() => {
                          if (isFieldSelected) {
                            setSelectedFields((prev) =>
                              prev.filter(
                                (selectedField) => selectedField.id !== field.id
                              )
                            );
                            return;
                          }

                          setSelectedFields((prev) => [...prev, field]);
                        }}
                      >
                        <div className="flex items-center gap-3 overflow-hidden">
                          <div
                            className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-white"
                            style={{
                              backgroundColor:
                                customFieldTypeToDetails[field.type]
                                  .iconBackgroundColor,
                            }}
                          >
                            {React.createElement(
                              customFieldTypeToDetails[field.type].primaryIcon
                            )}
                          </div>
                          <span className="text-dark font-secondary truncate text-sm leading-[140%]">
                            <Highlighter
                              autoEscape
                              highlightClassName="text-primary font-medium"
                              highlightTag="span"
                              textToHighlight={field.name}
                              searchWords={[searchQuery || ""]}
                            />
                          </span>
                        </div>
                        {isFieldSelected && <CheckIcon className="shrink-0" />}
                      </InteractiveContainer>
                    );
                  })
                ) : (
                  <span className="font-secondary mt-4 mb-8 text-center text-base leading-4 text-[#828282]">
                    {t({
                      id: "cpanel-page.no-results",
                      defaultMessage: "No results 😔",
                    })}
                  </span>
                )}
              </div>
              <div className="flex items-center gap-4 px-4">
                <Button
                  fullWidth
                  color="grey"
                  className="h-10"
                  onClick={onClose}
                >
                  {t({
                    id: "common.cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
                <Button
                  fullWidth
                  className="h-10"
                  isDisabled={!bookmarks.length && !selectedFields.length}
                  onClick={() => {
                    handleUpdateUserCustomFields(
                      selectedFields.map(({ id }) => id)
                    );
                    onClose();
                  }}
                >
                  {selectedFields.length
                    ? t(
                        {
                          id: "common.select-with-count",
                          defaultMessage: "Select {count}",
                        },
                        { count: selectedFields.length }
                      )
                    : t({ id: "common.select", defaultMessage: "Select" })}
                </Button>
              </div>
            </div>
          )}
        </Popover>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="userCustomFieldBookmarks">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="flex w-[500px] flex-col gap-4"
                {...provided.droppableProps}
              >
                {bookmarks.map((bookmark, idx) => {
                  const subtitle =
                    bookmark.type === "dropdown"
                      ? t(
                          {
                            id: "cpanel-page.values-count",
                            defaultMessage: `{count} {count, plural, one {Value} other {Values}}`,
                          },
                          { count: bookmark.values.length }
                        )
                      : undefined;

                  return (
                    <DraggableFieldBoxContainer
                      key={bookmark.id}
                      id={bookmark.id}
                      index={idx}
                    >
                      <FieldBox
                        title={
                          <div className="flex items-center gap-2">
                            <div
                              className="flex h-6 w-6 items-center justify-center rounded-full text-white"
                              style={{
                                backgroundColor:
                                  customFieldTypeToDetails[bookmark.type]
                                    .iconBackgroundColor,
                              }}
                            >
                              {React.createElement(
                                customFieldTypeToDetails[bookmark.type]
                                  .primaryIcon
                              )}
                            </div>
                            <span>{bookmark.name}</span>
                          </div>
                        }
                        subtitle={joinReactNodes(
                          [
                            customFieldTypeToDetails[bookmark.type].fullLabel,
                            subtitle,
                          ],
                          <div className="h-4 w-px bg-[#E0E0E0]" />
                        )}
                        customEndSlot={
                          <PlainButton
                            onClick={() =>
                              deselectBookmarkAlertState.open({
                                metadata: {
                                  bookmarkName: bookmark.name,
                                },
                                onConfirm: () =>
                                  handleUpdateUserCustomFields(
                                    bookmarks
                                      .filter((el) => el.id !== bookmark.id)
                                      .map(({ id }) => id)
                                  ),
                              })
                            }
                          >
                            <DeleteIcon />
                          </PlainButton>
                        }
                      />
                    </DraggableFieldBoxContainer>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </BottomCenteredDrawer.Content>
      <Alert
        isOpen={deselectBookmarkAlertState.isOpen}
        onRequestClose={deselectBookmarkAlertState.close}
        title={t({
          id: "common.confirm-action",
          defaultMessage: "Confirm action",
        })}
        content={t(
          {
            id: "cpanel-page.",
            defaultMessage:
              "Do you really want to deselect Bookmark {bookmarkName}?",
          },
          {
            bookmarkName: (
              <span className="text-primary font-medium">
                {deselectBookmarkAlertState.metadata?.bookmarkName}
              </span>
            ),
          }
        )}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.confirm",
              defaultMessage: "Confirm",
            }),
            onClick: () =>
              deselectBookmarkAlertState.confirm({
                closeOnceConfirmed: true,
              }),
          },
        ]}
      />
    </>
  );
};
