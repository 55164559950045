export enum ChatMessagePayloadAction {
  // Group updated
  updated = "updated",
  // User added to group
  added = "added",
  // User removed from group
  removed = "removed",
  // User left the group
  left = "left",
  deleted = "deleted",
  edited = "edited",
  action = "action",
  // User role change
  role_updated = "role_updated",
}
