import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authSlice from "@web-src/features/auth/authSlice";
import chatsSlice from "@web-src/modules/chats/store/chatsSlice";
import appSlice from "@web-src/features/app/appSlice";
import driveSlice from "@web-src/modules/drive/pages/DrivePage/driveSlice";
import { emptySplitApi } from "@web-src/features/api/createApi";
import { paginationSlice } from "@jugl-web/utils";

const combinedReducers = combineReducers({
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  [appSlice.name]: appSlice.reducer,
  [chatsSlice.name]: chatsSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [driveSlice.name]: driveSlice.reducer,
  [paginationSlice.name]: paginationSlice.reducer,
});

type CombinedState = ReturnType<typeof combinedReducers>;

const rootReducer = (state: CombinedState | undefined, action: AnyAction) => {
  if (action.type === "auth/logout/fulfilled") {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const middleware = [emptySplitApi.middleware];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
