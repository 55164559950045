import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { reorder } from "@jugl-web/utils";
import { mapIndexesToOrder } from "@jugl-web/utils/array/mapIndexesToOrder";
import { ComponentProps, useCallback, useMemo } from "react";
import { TaskChecklistItem } from "./types";

interface UseTaskChecklistDndOptions {
  items: TaskChecklistItem[];
  onReorderItems: (items: TaskChecklistItem[]) => void;
}

export const useTaskChecklistDnd = ({
  items,
  onReorderItems,
}: UseTaskChecklistDndOptions) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const activeIndex = active.data.current?.index;
      const overIndex = over?.data.current?.index;

      if (
        typeof activeIndex !== "number" ||
        typeof overIndex !== "number" ||
        activeIndex === overIndex
      ) {
        return;
      }

      const reorderedItems = mapIndexesToOrder(
        reorder(items, activeIndex, overIndex)
      );

      onReorderItems(reorderedItems);
    },
    [items, onReorderItems]
  );

  const dndContextProps = useMemo<ComponentProps<typeof DndContext>>(
    () => ({ sensors, onDragEnd: handleDragEnd }),
    [sensors, handleDragEnd]
  );

  return { dndContextProps };
};
