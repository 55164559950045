import React, { useState, useMemo } from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations, cx, useToast } from "@jugl-web/utils";
import {
  Button,
  EmptyListContent,
  TableGrid,
  Menu,
  Checkbox,
  PlainButton,
  InteractiveContainer,
} from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { DiscountItemDataDto } from "@jugl-web/rest-api/inventory/models/DiscountItem";
import Highlighter from "react-highlight-words";
import { useEffectOnce } from "react-use";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { InventoryTabsLayout } from "../components/InventoryTabsLayout";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import emptyDiscountsAnimations from "./assets/empty-discounts-animation.json";
import { ManageDiscountDialog } from "./components/ManageDiscountDialog";
import { useInventoryTableCheckboxState } from "../hooks/useInventoryTableCheckboxState";
import { ReactComponent as GreenDotIcon } from "./assets/green-dot.svg";
import { ReactComponent as RedDotIcon } from "./assets/red-dot.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as DeleteItemsIcon } from "./assets/delete-items.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ConfirmDiscountActionPopover } from "./components/ConfirmDiscountActionPopover";

export const InventoryDiscountsPage: React.FC = () => {
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast();
  const [loadDiscountItems] = inventoryApi.useLazyGetDiscountItemsListQuery();
  const [updateDiscount] = inventoryApi.useUpdateDiscountMutation();
  const [deleteItem] = inventoryApi.useDeleteDiscountItemMutation();
  const [loadAllDiscounts] = inventoryApi.useLazyGetAllDiscountsQuery();

  const { inputProps, reset, searchQuery } = useSearchInput({
    debounce: 500,
    onSearch: (value) => {
      if (value) {
        handleLoadMoreDiscountItems(true, false, value);
      }
      if (value === "" || !value) {
        handleLoadMoreDiscountItems(true, true, undefined);
      }
    },
  });

  const [isManageDiscountDialogState, setIsManageDiscountDialogState] =
    useState<{
      isOpen: boolean;
      discountItemToEdit: DiscountItemDataDto | null;
    }>({
      isOpen: false,
      discountItemToEdit: null,
    });

  const [discountIdsToDeleteQueue, setDiscountIdsToDeleteQueue] = useState<
    {
      id: string;
      shouldRemove: boolean;
    }[]
  >([]);
  const [currentProcessingDiscountIndex, setCurrentProcessingDiscountIndex] =
    useState<number>();

  const [internalIsLoading, setInternalIsLoading] = useState(false);

  const [discountIdToChangeStatus, setDiscountIdToChangeStatus] =
    useState<string>();

  const [data, setData] = useState<{
    items: DiscountItemDataDto[];
    page: number;
    hasMore: boolean;
    isInitialized: boolean;
  }>({
    items: [],
    page: 0,
    hasMore: true,
    isInitialized: false,
  });

  const [totalEntries, setTotalEntries] = useState<number>();

  const itemIds = useMemo(() => data.items.map(({ id }) => id), [data.items]);

  const {
    isAllChecked,
    checkedList,
    numbersOfChecked,
    handleCheckboxChange,
    handleSelectAll,
    handleClearAll,
    handleRemoveIds,
    isIdChecked,
  } = useInventoryTableCheckboxState({
    ids: itemIds || [],
    totalEntries,
  });

  const idsOfItemsToRemove = useMemo(
    () => Object.keys(checkedList).filter((key) => checkedList[key]),
    [checkedList]
  );

  const handleLoadMoreDiscountItems = async (
    shouldReset?: boolean,
    clearSearchValue?: boolean,
    searchValue?: string
  ) => {
    if (!shouldReset && (!data.hasMore || internalIsLoading)) {
      return;
    }

    setInternalIsLoading(true);
    const response = await loadDiscountItems({
      params: {
        page: shouldReset ? 1 : data.page + 1,
        page_size: 10,
        search: clearSearchValue
          ? undefined
          : searchValue || searchQuery || undefined,
      },
      entityId: entity.id,
    });
    if (response.data && response.data.data) {
      if (!searchQuery && !searchValue && !totalEntries) {
        setTotalEntries(response.data.total_entries);
      }
      const hasMore = response.data.total_pages > response.data.page_number;
      const items = () => {
        if (shouldReset) return response.data?.data || [];
        if (!response.data) return data.items;
        const uniqueItems = new Map(
          [...data.items, ...response.data.data].map((item) => [item.id, item])
        );
        return Array.from(uniqueItems.values());
      };
      setData((prev) => ({
        ...prev,
        items: items(),
        page: response.data?.page_number || 1,
        isInitialized: true,
        hasMore,
      }));
    }
    setInternalIsLoading(false);
  };

  const handleRemoveDiscountsSuccess = (idsToRemove: string[]) => {
    setTotalEntries(totalEntries ? totalEntries - idsToRemove.length : 0);
    const newItemsState = data.items.filter(
      (item) => !idsToRemove.includes(item.id)
    );
    setData((prev) => ({
      ...prev,
      items: newItemsState,
    }));
    handleRemoveIds(idsToRemove);
  };

  useEffectOnce(() => {
    handleLoadMoreDiscountItems();
  });

  const handleDiscountDialogSuccess = (item: DiscountItemDataDto) => {
    const isEdit = data.items.some((prevItem) => prevItem.id === item.id);

    if (isEdit) {
      setData((prev) => ({
        ...prev,
        items: prev.items.map((prevItem) =>
          prevItem.id === item.id ? item : prevItem
        ),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        items: [{ ...item, status: "active" }, ...prev.items],
      }));
      setTotalEntries(totalEntries ? totalEntries + 1 : 1);
    }
  };

  const handleToggleStatus = async (
    id: string,
    status: "active" | "inactive",
    counter?: number
  ) => {
    const response = await updateDiscount({
      entityId: entity.id,
      itemId: id,
      params: {
        status,
      },
    });
    if ("data" in response) {
      setData((prev) => ({
        ...prev,
        items: prev.items.map((item) =>
          item.id === id ? response.data : item
        ),
      }));
      if (status === "active") {
        toast(
          t({
            id: "feedback.discount-status-was-updated",
            defaultMessage: "Discount status was updated",
          })
        );
      } else {
        toast(
          t(
            {
              id: "feedback.discount-status-was-deactivated",
              defaultMessage:
                "Discount was deactivated and removed from {count} {count, plural, one {Form} other {Forms}}",
            },
            {
              count: counter,
            }
          )
        );
      }
    }
  };

  const handleSetNewDeleteQueue = async () => {
    let newQueue = idsOfItemsToRemove.map((id) => ({
      id,
      shouldRemove: false,
    }));
    const checkedListTrueValuesNumber =
      Object.values(checkedList).filter(Boolean).length;
    if (checkedListTrueValuesNumber !== numbersOfChecked) {
      const allDiscountsList = await loadAllDiscounts({
        entityId: entity.id,
      });
      const visibleCheckboxIds = Object.keys(checkedList);
      const newItemsToRemoveList: string[] = [];
      allDiscountsList.data?.forEach((discount) => {
        if (
          !(
            visibleCheckboxIds.includes(discount.id) &&
            !checkedList[discount.id]
          )
        ) {
          newItemsToRemoveList.push(discount.id);
        }
      });

      newQueue = newItemsToRemoveList.map((id) => ({
        id,
        shouldRemove: false,
      }));
    }

    setDiscountIdsToDeleteQueue(newQueue);
    setCurrentProcessingDiscountIndex(0);
  };

  const handleRemoveDiscounts = async (
    counter: number,
    queue: typeof discountIdsToDeleteQueue
  ) => {
    const itemsToRemove = queue.filter((item) => item.shouldRemove);
    const promises = itemsToRemove.map((item) =>
      deleteItem({ entityId: entity.id, itemId: item.id })
    );

    const results = await Promise.all(promises);

    if (!results.some((res) => "error" in res)) {
      handleRemoveDiscountsSuccess(itemsToRemove.map((item) => item.id));
      if (itemsToRemove.length === 1) {
        if (counter) {
          toast(
            t(
              {
                id: "feedback.discount-was-deleted-and-removed-from-forms",
                defaultMessage:
                  "Discount was deleted and removed from {count} Form",
              },
              {
                count: counter,
              }
            )
          );
        } else {
          toast(
            t({
              id: "feedback.discount-was-deleted",
              defaultMessage: "Discount was deleted",
            })
          );
        }
      }
      if (itemsToRemove.length > 1) {
        toast(
          t(
            {
              id: "feedback.discounts-was-deleted",
              defaultMessage: "{count} Discounts was deleted",
            },
            {
              count: itemsToRemove.length,
            }
          )
        );
      }
      setDiscountIdsToDeleteQueue([]);
      setCurrentProcessingDiscountIndex(0);
    }
  };

  return (
    <InventoryTabsLayout
      selectedTab="inventory-discounts"
      rightContent={
        <div className="flex items-center gap-4">
          <SearchInput
            className="w-[280px]"
            variant="filled"
            color="white"
            onClear={reset}
            {...inputProps}
          />
          {numbersOfChecked > 0 && (
            <Button
              iconStart={<DeleteItemsIcon />}
              className="font-secondary text-dark h-10 font-normal"
              color="white"
              onClick={() => {
                handleSetNewDeleteQueue();
              }}
            >
              {t(
                {
                  id: "inventory-warehouses-page.delete-item",
                  defaultMessage:
                    "Delete {count} {count, plural, one {Item} other {Items}}",
                },
                {
                  count: numbersOfChecked,
                }
              )}
            </Button>
          )}
          {numbersOfChecked > 0 && (
            <PlainButton
              onClick={() => {
                handleClearAll();
              }}
            >
              <CloseIcon />
            </PlainButton>
          )}
          {numbersOfChecked === 0 && (
            <Button
              className="h-10"
              iconEnd={<AddIcon />}
              onClick={() =>
                setIsManageDiscountDialogState({
                  isOpen: true,
                  discountItemToEdit: null,
                })
              }
            >
              {t({
                id: "inventory-discounts-page.add-discount",
                defaultMessage: "Add Discount",
              })}
            </Button>
          )}
        </div>
      }
    >
      {data.isInitialized && (data.items.length || searchQuery) ? (
        <div className="w-full min-w-fit p-8">
          <TableGrid
            unstyled
            data={data.items}
            className="overflow-hidden rounded-xl border-[1px] border-solid border-[#EEF2F5]"
            headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5] flex items-center"
            cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-4 border-0 border-t border-r border-solid border-[#EEF2F5] leading-[140%] tracking-[0.14px] hover:bg-grey-200"
            rowHoverClassName="bg-grey-100"
            activeRowColorInHex="#F9F9F9"
            isItemActive={(item) => isIdChecked(item.id)}
            onReachEnd={handleLoadMoreDiscountItems}
            columns={[
              {
                title: (
                  <Checkbox
                    size="sm"
                    isChecked={isAllChecked}
                    onChange={() => {
                      if (isAllChecked) {
                        handleClearAll();
                      } else {
                        handleSelectAll();
                      }
                    }}
                  />
                ),
                grow: false,
                width: 50,
                headerClassName: isAllChecked
                  ? "bg-grey-100 rounded-tl-lg"
                  : "",
                content: ({ id }) => (
                  <Checkbox
                    size="sm"
                    isChecked={isIdChecked(id)}
                    onChange={() => {
                      handleCheckboxChange(id);
                    }}
                  />
                ),
              },
              {
                title: t({
                  id: "discounts-page.discount-name",
                  defaultMessage: "Discount Name",
                }),
                grow: false,
                width: 500,
                content: ({ name }) => (
                  <div
                    className="font-secondary flex h-full w-11/12 items-center truncate text-base font-medium"
                    title={name}
                  >
                    <Highlighter
                      autoEscape
                      className="flex truncate"
                      highlightClassName="text-primary bg-transparent"
                      highlightTag="div"
                      textToHighlight={name}
                      searchWords={[searchQuery || ""]}
                    />
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.status",
                  defaultMessage: "Status",
                }),
                grow: false,
                className: "p-[1px]",
                width: 90,
                content: ({ id, status }) => (
                  <Menu
                    placement="bottom-end"
                    className="flex w-[120px] min-w-[0] flex-col gap-2 p-2"
                    autoClose={false}
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        ref={triggerRef}
                        as={InteractiveContainer}
                        className={cx(
                          "flex h-[52px] w-[86px] items-center justify-center capitalize",
                          status === "active"
                            ? "bg-secondary-50 text-secondary-800"
                            : "bg-tertiary-50 text-tertiary-800"
                        )}
                      >
                        {status}
                      </Trigger>
                    )}
                    sections={[
                      [
                        {
                          id: "active",
                          label: t({
                            id: "common.active",
                            defaultMessage: "Active",
                          }),
                          className: cx(
                            "h-[40px] rounded-xl text-sm font-secondary text-dark",
                            status === "active" ? "bg-[#E3F2FD]" : ""
                          ),
                          icon: <GreenDotIcon />,
                          triggerClassName: `p-2 ${
                            status === "active" ? "cursor-not-allowed" : ""
                          }`,
                          onSelect: (_, close) => {
                            if (status === "active") return;
                            handleToggleStatus(id, "active");
                            close();
                          },
                        },
                        {
                          id: "inactive",
                          label: t({
                            id: "common.inactive",
                            defaultMessage: "Inactive",
                          }),
                          className: cx(
                            "h-[40px] rounded-xl text-sm font-secondary text-dark",
                            status === "inactive" ? "bg-[#E3F2FD]" : ""
                          ),
                          triggerClassName: `p-2 ${
                            status === "inactive" ? "cursor-not-allowed" : ""
                          }`,
                          onSelect: (_, close) => {
                            if (status === "inactive") return;
                            close();
                            setDiscountIdToChangeStatus(id);
                          },
                          icon: <RedDotIcon />,
                        },
                      ],
                    ]}
                  />
                ),
              },

              {
                title: t({
                  id: "discounts-page.discount-value",
                  defaultMessage: "Discount Value",
                }),
                grow: false,
                width: 300,
                className: "truncate",
                content: ({ discount }) => (
                  <div title={`${discount} %`}>{discount} %</div>
                ),
              },
              {
                title: t({
                  id: "common.description",
                  defaultMessage: "Description",
                }),
                grow: true,
                className: "truncate min-w-[600px]",
                content: ({ desc }) => (
                  <div className="w-11/12 truncate" title={desc}>
                    <Linkify>{desc}</Linkify>
                  </div>
                ),
              },
              {
                title: t({
                  id: "order-forms-page.settings",
                  defaultMessage: "Settings",
                }),
                className: "flex justify-center w-full border-r-0",
                width: 100,
                grow: false,
                headerClassName: "border-r-0",
                content: (discountItem) => (
                  <Menu
                    placement="bottom-end"
                    autoClose
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        as={PlainButton}
                        ref={triggerRef}
                        className="mt-1"
                      >
                        <MoreIcon />
                      </Trigger>
                    )}
                    className="py-2"
                    sections={[
                      [
                        {
                          id: "edit",
                          label: t({
                            id: "discounts-page.edit-discount",
                            defaultMessage: "Edit Discount",
                          }),
                          icon: <EditIcon />,
                          onSelect: () =>
                            setIsManageDiscountDialogState({
                              isOpen: true,
                              discountItemToEdit: discountItem,
                            }),
                        },
                        {
                          id: "delete",
                          label: t({
                            id: "discounts-page.delete-discount",
                            defaultMessage: "Delete Discount",
                          }),
                          onSelect: () => {
                            setDiscountIdsToDeleteQueue([
                              {
                                id: discountItem.id,
                                shouldRemove: false,
                              },
                            ]);
                            setCurrentProcessingDiscountIndex(0);
                          },
                          icon: <DeleteIcon />,
                        },
                      ],
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      ) : (
        <></>
      )}
      {!internalIsLoading && searchQuery && data.items.length === 0 && (
        <div className="mt-[80px] flex justify-center">
          {t({
            id: "inventory-items-page.no-results",
            defaultMessage: "No results 😔",
          })}
        </div>
      )}
      {!internalIsLoading &&
        data.isInitialized &&
        !searchQuery &&
        !data.items.length && (
          <EmptyListContent
            type="custom"
            className="w-[400px]"
            customImg={
              <Lottie
                options={{ animationData: emptyDiscountsAnimations }}
                height={300}
                width={300}
              />
            }
            customTitle={t({
              id: "inventory-discounts-page.discounts",
              defaultMessage: "Discounts ✨",
            })}
            customSubtitle={t({
              id: "inventory-discounts-page.discounts-empty-state-message",
              defaultMessage:
                "Create different type of discounts, to easily apply them to your services ",
            })}
            customButton={{
              text: t({
                id: "inventory-discounts-page.add-discount",
                defaultMessage: "Add Discount",
              }),
              iconEnd: <AddIcon />,
              fullWidth: false,
              className: "h-10",
              onClick: () =>
                setIsManageDiscountDialogState({
                  isOpen: true,
                  discountItemToEdit: null,
                }),
            }}
          />
        )}
      <ManageDiscountDialog
        isOpen={isManageDiscountDialogState.isOpen}
        onClose={() =>
          setIsManageDiscountDialogState({
            isOpen: false,
            discountItemToEdit: null,
          })
        }
        discountItem={isManageDiscountDialogState.discountItemToEdit}
        onSuccess={handleDiscountDialogSuccess}
      />

      <ConfirmDiscountActionPopover
        mode="status"
        isOpen={Boolean(discountIdToChangeStatus)}
        discountId={discountIdToChangeStatus}
        onClose={() => setDiscountIdToChangeStatus(undefined)}
        onConfirm={(counter) =>
          discountIdToChangeStatus &&
          handleToggleStatus(discountIdToChangeStatus, "inactive", counter)
        }
      />
      <ConfirmDiscountActionPopover
        mode="remove"
        isOpen={
          (currentProcessingDiscountIndex?.toString() &&
            Boolean(
              discountIdsToDeleteQueue[currentProcessingDiscountIndex]
            )) ||
          false
        }
        showSkipLabel={discountIdsToDeleteQueue.length > 1}
        discountId={
          (currentProcessingDiscountIndex?.toString() &&
            discountIdsToDeleteQueue[currentProcessingDiscountIndex]?.id) ||
          undefined
        }
        onClose={() => {
          if (!currentProcessingDiscountIndex?.toString()) {
            return;
          }
          setCurrentProcessingDiscountIndex(currentProcessingDiscountIndex + 1);
        }}
        onCancel={() => {
          if (
            currentProcessingDiscountIndex ===
            discountIdsToDeleteQueue.length - 1
          ) {
            handleRemoveDiscounts(0, discountIdsToDeleteQueue);
          }
        }}
        onConfirm={(counter) => {
          if (!currentProcessingDiscountIndex?.toString()) {
            return;
          }
          const newQueueState = discountIdsToDeleteQueue.map((item, i) =>
            i === currentProcessingDiscountIndex
              ? { ...item, shouldRemove: true }
              : item
          );
          setDiscountIdsToDeleteQueue(newQueueState);
          if (
            currentProcessingDiscountIndex ===
            discountIdsToDeleteQueue.length - 1
          ) {
            handleRemoveDiscounts(counter, newQueueState);
            return;
          }

          setCurrentProcessingDiscountIndex(currentProcessingDiscountIndex + 1);
        }}
      />
    </InventoryTabsLayout>
  );
};
