import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskTemplateFolder } from "@jugl-web/rest-api/tasks-templates";
import { Alert, FeedbackBox } from "@jugl-web/ui-components/cross-platform";
import { TaskTemplateFolderTile } from "@jugl-web/ui-components/cross-platform/tasks/TaskTemplateFolderTile";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import {
  TemplateFolderFilter,
  useTaskTemplateFoldersPageContext,
} from "@web-src/features/tasks/TaskTemplateFoldersPageContext";
import { TaskViewLoading } from "@web-src/features/tasks/TaskViewLoading";
import { FC, useMemo, useState } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ManageTaskTemplateFolderDialog } from "../ManageTaskTemplateFolderDialog";
import { TaskViewEmptyState } from "../TaskViewEmptyState";
import { ReactComponent as AddLargeIcon } from "./assets/add-large.svg";

interface TaskTemplateFoldersContentProps {
  entityId: string;
  meId: string;
  canManageTemplates: boolean;
}

export const TaskTemplateFoldersContent: FC<
  TaskTemplateFoldersContentProps
> = ({ entityId, meId, canManageTemplates }) => {
  const { searchQuery, filter, setIsNewFolderDialogOpen } =
    useTaskTemplateFoldersPageContext();

  const [bufferedFolder, setBufferedFolder] =
    useState<TaskTemplateFolder | null>(null);

  const [isEditFolderDialogOpen, setIsEditFolderDialogOpen] = useState(false);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  const { tasksTemplatesApi } = useRestApiProvider();

  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });

  const [deleteTemplateFolder, { isLoading: isDeletingTemplateFolder }] =
    tasksTemplatesApi.useDeleteTemplateFolderMutation();

  const handleDeleteTemplateFolder = async (folderId: string) => {
    const response = await deleteTemplateFolder({ entityId, folderId });

    if ("data" in response) {
      setIsDeleteConfirmationDialogOpen(false);
      toast(
        t({
          id: "feedback.template-folder-deleted",
          defaultMessage: "Template folder has been deleted",
        })
      );
    }
  };

  const {
    data: templateFolders,
    isLoading,
    isError,
  } = tasksTemplatesApi.useGetTemplateFoldersQuery(
    entityId ? { entityId } : skipToken
  );

  const visibleTemplateFolders = useMemo<TaskTemplateFolder[]>(() => {
    if (!templateFolders) {
      return [];
    }

    return templateFolders
      .filter((folder) => {
        const matchesSearchQuery = folder.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        if (!matchesSearchQuery) {
          return false;
        }

        if (filter === TemplateFolderFilter.MyFolders) {
          return folder.created_by === meId;
        }

        if (filter === TemplateFolderFilter.CreatedByJugl) {
          return folder.created_by === null;
        }

        if (filter === TemplateFolderFilter.CreatedByOthers) {
          return folder.created_by !== null && folder.created_by !== meId;
        }

        return true;
      })
      .sort((folderA, folderB) => {
        const isADefaultFolder = folderA.created_by === null;
        const isBDefaultFolder = folderB.created_by === null;

        if (isADefaultFolder && !isBDefaultFolder) {
          return -1;
        }

        if (!isADefaultFolder && isBDefaultFolder) {
          return 1;
        }

        return 0;
      });
  }, [templateFolders, searchQuery, filter, meId]);

  const hasAnyFilteringCriteria =
    searchQuery.length > 0 || filter !== TemplateFolderFilter.AllFolders;

  const isEmpty = visibleTemplateFolders.length === 0;

  if (isError) {
    return (
      <TasksPageLayout.Content>
        <FeedbackBox
          iconType="alert"
          title={t({
            id: "feedback.something-went-wrong",
            defaultMessage: "Something went wrong",
          })}
          subtitle={t({
            id: "feedback.error-occurred-try-again-message",
            defaultMessage: "We are trying to fix it, please try again",
          })}
          actionButton={{
            label: t({
              id: "common.refresh-page",
              defaultMessage: "Refresh page",
            }),
            onClick: () => window.location.reload(),
          }}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </TasksPageLayout.Content>
    );
  }

  if (isLoading) {
    return (
      <TasksPageLayout.Content>
        <TaskViewLoading
          label={t({
            id: "tasks-page.loading-template-folders",
            defaultMessage: "Loading template folders...",
          })}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </TasksPageLayout.Content>
    );
  }

  if (isEmpty && hasAnyFilteringCriteria) {
    return (
      <TasksPageLayout.Content>
        <TaskViewEmptyState className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
      </TasksPageLayout.Content>
    );
  }

  return (
    <TasksPageLayout.Content className="px-8 pt-10 pb-8">
      <div className="animate-fade-in flex flex-wrap items-center gap-[60px]">
        {canManageTemplates && !hasAnyFilteringCriteria && (
          <TaskTemplateFolderTile
            color="#E3F2FD"
            icon={<AddLargeIcon />}
            subtitle={t({
              id: "tasks-page.add-folder",
              defaultMessage: "Add folder",
            })}
            onClick={() => setIsNewFolderDialogOpen(true)}
          />
        )}
        {visibleTemplateFolders.map((folder) => (
          <TaskTemplateFolderTile
            key={folder.id}
            color={folder.color}
            icon={<span className="text-[32px]">{folder.img}</span>}
            subtitle={folder.name}
            highlightedText={searchQuery}
            menuConfig={
              canManageTemplates
                ? {
                    sections: [
                      [
                        {
                          id: "edit",
                          label: t({
                            id: "tasks-page.edit-folder",
                            defaultMessage: "Edit folder",
                          }),
                          onSelect: (event, close) => {
                            event.stopPropagation();
                            close();
                            setBufferedFolder(folder);
                            setIsEditFolderDialogOpen(true);
                          },
                        },
                        {
                          id: "delete",
                          label: t({
                            id: "tasks-page.delete-folder",
                            defaultMessage: "Delete folder",
                          }),
                          onSelect: (event, close) => {
                            event.stopPropagation();
                            close();
                            setBufferedFolder(folder);
                            setIsDeleteConfirmationDialogOpen(true);
                          },
                        },
                      ],
                    ],
                  }
                : undefined
            }
            onClick={() =>
              navigateToPage("tasksTemplatesFolder", { folderId: folder.id })
            }
          />
        ))}
      </div>
      <ManageTaskTemplateFolderDialog
        entityId={entityId}
        isOpen={isEditFolderDialogOpen}
        editingFolder={bufferedFolder}
        onClose={() => setIsEditFolderDialogOpen(false)}
      />
      <Alert
        isOpen={isDeleteConfirmationDialogOpen}
        title={t({
          id: "tasks-page.delete-template-folder",
          defaultMessage: "Delete template folder",
        })}
        content={t(
          {
            id: "tasks-page.confirm-delete-template-folder",
            defaultMessage:
              "Are you sure you want to delete template folder {folderName}?",
          },
          {
            folderName: (
              <span className="text-primary-800 font-semibold">
                {bufferedFolder?.name}
              </span>
            ),
          }
        )}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            color: "tertiary",
            isDisabled: isDeletingTemplateFolder,
            onClick: () => handleDeleteTemplateFolder(bufferedFolder?.id || ""),
          },
        ]}
        onRequestClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </TasksPageLayout.Content>
  );
};
