import React, { useMemo } from "react";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { cx, useTranslations } from "@jugl-web/utils";
import { ChatMessageModel } from "@jugl-web/rest-api";

interface Info {
  colorCssClass: string;
  tokens: { type: string; value?: string; suffix?: string }[];
}

export const GroupInfoMessage: React.FC<{
  message: ChatMessageModel;
}> = ({ message }) => {
  const { t } = useTranslations();
  const info: Info | null = useMemo(() => {
    if (!message) {
      return null;
    }
    const forUsername = message.payload?.action_for;
    const fromUsername = message.payload?.action_from;
    if (message.payload?.action === "removed") {
      return {
        tokens: [
          { type: "user", value: forUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.removed-from-group",
              defaultMessage: "has been removed from the group",
            }),
          },
        ],
        colorCssClass: "text-[#C94841]",
      };
    }
    // TODO: re-check with backend/design
    if (message.payload?.action === "deleted") {
      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.deleted-group",
              defaultMessage: "deleted the group",
            }),
          },
        ],
        colorCssClass: "text-[#C94841]",
      };
    }
    if (message.payload?.action === "added") {
      if (message.payload?.action_for === message.payload?.action_from) {
        return {
          tokens: [
            { type: "user", value: forUsername },
            {
              type: "string",
              value: t({
                id: "chats-page.created-group",
                defaultMessage: "created the group",
              }),
            },
          ],
          colorCssClass: "text-primary-700",
        };
      }
      let participantsToDisplay = message.payload.participants || [];
      let participantsMore = 0;
      if (participantsToDisplay.length > 4) {
        participantsMore = participantsToDisplay.length - 4;
        participantsToDisplay = participantsToDisplay.slice(0, 4);
      }
      return {
        tokens: [
          { type: "user", value: fromUsername },
          { type: "string", value: "added" },
          ...(participantsToDisplay.map((item, idx) => ({
            type: "user",
            value: item,
            suffix:
              message.payload.participants &&
              idx < message.payload.participants.length - 1
                ? ", "
                : "",
          })) || []),
          ...(participantsMore > 0
            ? [{ type: "string", value: `and ${participantsMore} more users` }]
            : []),
          { type: "user", value: forUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.to-group",
              defaultMessage: "to the group",
            }),
          },
        ],
        colorCssClass: "text-primary-700",
      };
    }
    if (message.payload?.action === "updated") {
      const [changeKey, changeValue] =
        Object.entries(message.payload.changes || {})[0] || [];

      let actionString = "";

      switch (changeKey) {
        case "title":
          actionString = t(
            {
              id: "chats-page.updated-group-title",
              defaultMessage: `updated group title to "{title}"`,
            },
            { title: changeValue }
          );
          break;
        case "display_picture":
          actionString = t({
            id: "chats-page.updated-group-image",
            defaultMessage: "updated group image",
          });
          break;
        case "content_protected":
          actionString = changeValue
            ? t({
                id: "chats-page.set-content-protection-in-group",
                defaultMessage: `set “Content protection” in the Group`,
              })
            : t({
                id: "chats-page.disabled-content-protection-in-group",
                defaultMessage: `disabled “Content protection” in the Group`,
              });
          break;
        case "admin_only_msg":
          actionString = changeValue
            ? t({
                id: "chats-page.switch-into-broadcast-chat-mode",
                defaultMessage: `switched Group Chat into “Broadcast Chat” mode`,
              })
            : t({
                id: "chats-page.turned-off-broadcast-chat-mode",
                defaultMessage: `turned off the “Broadcast Chat” mode`,
              });
          break;
        default:
          actionString = t({
            id: "chats-page.updated-group",
            defaultMessage: "updated the group",
          });
      }

      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: actionString,
          },
        ],
        colorCssClass: "text-primary-700",
      };
    }
    if (message.payload?.action === "role_updated") {
      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.updated-list-of-group-admins",
              defaultMessage: "updated the list of Group Admins",
            }),
          },
        ],
        colorCssClass: "text-primary-700",
      };
    }
    if (message.payload?.action === "left") {
      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.left-group",
              defaultMessage: "left the group",
            }),
          },
        ],
        colorCssClass: "text-[#C94841]",
      };
    }
    return null;
  }, [message, t]);

  return (
    <div className="flex items-end justify-center py-1">
      <span
        className={cx("text-center text-sm font-medium", info?.colorCssClass)}
      >
        {info?.tokens.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`${item.value}-${idx}`}>
            {item.type === "user"
              ? !!item.value && (
                  <UserProfileName userId={item.value} suffix={item.suffix} />
                )
              : item.value}{" "}
          </span>
        ))}
      </span>
    </div>
  );
};
