import { cx, useUniqueDOMId } from "@jugl-web/utils";
import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormGroupProps } from "../FormGroup";
import { UtilityTextInputBase } from "../UtilityTextInputBase";

export interface TextInputProps
  extends Pick<
      InputHTMLAttributes<HTMLInputElement>,
      | "placeholder"
      | "value"
      | "autoComplete"
      | "onBlur"
      | "onChange"
      | "onKeyDown"
      | "onFocus"
      | "type"
      | "id"
      | "autoFocus"
      | "min"
      | "max"
      | "step"
      | "readOnly"
      | "inputMode"
    >,
    FormGroupProps {
  mask?: string;
  classNames?: FormGroupProps["classNames"] & {
    inputClassName?: string;
    inputWrapperClassName?: string;
    endContentWrapperClassName?: string;
    startContentWrapperClassName?: string;
  };
  endContent?: ReactNode;
  startContent?: ReactNode;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const uniqueId = useUniqueDOMId();
    const {
      label,
      isInvalid,
      isRequired,
      mask,
      tooltip,
      errorMessage,
      isDisabled,
      autoFocus,
      endContent,
      startContent,
      classNames: {
        inputClassName,
        inputWrapperClassName,
        endContentWrapperClassName,
        startContentWrapperClassName,
      } = {},
      ...htmlInputProps
    } = props;
    const inputId = useMemo(
      () => htmlInputProps.id || uniqueId,
      [htmlInputProps.id, uniqueId]
    );
    const [internalInputValue, setInternalInputValue] = useState<string>(
      typeof htmlInputProps.value === "string" ? htmlInputProps.value : ""
    );
    useEffect(() => {
      if (typeof htmlInputProps.value === "string") {
        setInternalInputValue(htmlInputProps.value);
      }
    }, [htmlInputProps.value]);
    return (
      <UtilityTextInputBase
        {...{
          ...props,
          lengthIndicator: {
            ...props.lengthIndicator,
            current: internalInputValue.length,
          },
        }}
        inputWrapperClassName={inputWrapperClassName}
        inputId={inputId}
      >
        {startContent && (
          <div
            className={cx(
              "flex h-[56px] items-center bg-[#F5F5F7] px-4",
              startContentWrapperClassName
            )}
          >
            {startContent}
          </div>
        )}
        <input
          ref={ref}
          disabled={isDisabled}
          autoFocus={autoFocus}
          id={inputId}
          {...{
            ...htmlInputProps,
            className: cx(
              "w-full h-full flex-1 font-secondary text-base leading-4 outline-none py-4 px-5 border-none rounded-[10px] text-dark disabled:bg-transparent",
              inputClassName
            ),
            onChange: (e) => {
              setInternalInputValue(e.target.value || "");
              if (htmlInputProps.onChange) {
                htmlInputProps.onChange(e);
              }
            },
          }}
        />
        {endContent && (
          <div
            className={cx(
              "flex h-[56px] items-center bg-[#F5F5F7] px-4",
              endContentWrapperClassName
            )}
          >
            {endContent}
          </div>
        )}
      </UtilityTextInputBase>
    );
  }
);
