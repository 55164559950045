import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  PageLoaderFull,
  InteractiveContainer,
  Popover,
  ListBox,
} from "@jugl-web/ui-components/cross-platform";
import { Country } from "@jugl-web/ui-components/cross-platform/PhoneInput/types";
import {
  AvatarSelectSize,
  CountryPicker,
  Tooltip,
} from "@jugl-web/ui-components/web";
import {
  cx,
  useToast,
  useTranslations,
  currencyCodeToSymbol,
} from "@jugl-web/utils";
import { ResourceMainSettingsItem } from "@web-src/modules/common/components";
import {
  LeaveEntityConfirmationDialogsFlow,
  LeaveEntityConfirmationDialogsFlowHandle,
} from "@web-src/modules/entities/components/LeaveEntityConfirmationDialogsFlow";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import countries from "countries-phone-masks";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { WorkspaceEditableName } from "@web-src/modules/workspace/pages/WorkspaceSettingsPage/components/WorkspaceEditableName";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as EmailIcon } from "./assets/email.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as SeparatorDotIcon } from "./assets/separator-dot.svg";
import { WorkspaceEmailVerificationDialog } from "./components/WorkspaceEmailVerificationDialog";
import { SettingsTabsLayout } from "../../components/SettingsTabsLayout/SettingsTabsLayout";
import { getCurrenciesList } from "./utils/getCurrenciesList";
import { ConfirmCurrencyChange } from "./components/ConfirmCurrencyChange";

const WorkspaceSettingsPage: React.FC = () => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { entitiesApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const [avatar, setAvatar] = useState<File>();
  const [emailVerificationDialogIsOpen, setEmailVerificationDialogIsOpen] =
    useState(false);

  const [currencyToChange, setCurrencyToChange] = useState<string>();

  const leaveEntityConfirmationDialogsFlowRef =
    useRef<LeaveEntityConfirmationDialogsFlowHandle | null>(null);

  const [updateEntity, { isLoading }] = entitiesApi.useEditEntityMutation();

  const { localeAwareFormat } = useFormattedDate();

  const { selectFile } = useFileSelect(avatarFileSelectConfig);

  const handleAvatarChange = async (file?: File) => {
    const response = await updateEntity({
      data: {},
      entityId: entity.id,
      displayImg: file,
    });
    if (response && "data" in response) {
      setAvatar(file);
      toast(
        file
          ? t({
              id: "workspace-page.workspace-image-was-updated",
              defaultMessage: "Workspace image was updated",
            })
          : t({
              id: "workspace-page.workspace-image-was-removed",
              defaultMessage: "Workspace image was removed",
            })
      );
    }
  };

  const handleCopyWorkspaceIdToClipboard = useCallback(() => {
    navigator.clipboard.writeText(entity.uniqueId).then(() => {
      toast(
        t({
          id: "workspace-page.workspace-id-copied-to-clipboard",
          defaultMessage: "Workspace ID was copied to clipboard",
        })
      );
    });
  }, [toast, t, entity.uniqueId]);

  const handleCountryChange = async (country: Country) => {
    const response = await updateEntity({
      data: { country: country.iso },
      entityId: entity.id,
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "workspace-page.workspace-country-was-updated",
          defaultMessage: "Workspace country was updated",
        })
      );
    }
  };

  const handleCurrencyChange = async (currency: string) => {
    setCurrencyToChange(undefined);
    const response = await updateEntity({
      data: { currency },
      entityId: entity.id,
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "feedback.workspace-currency-was-updated",
          defaultMessage: "Workspace currency was updated",
        })
      );
    }
  };

  const country = useMemo(
    () => countries.find((countryObj) => countryObj.iso === entity.country),
    [entity.country]
  );

  const currency = useMemo(() => {
    if (entity.currency) {
      return getCurrenciesList().find(
        (currencyObj) => currencyObj.code === entity.currency
      );
    }
    return null;
  }, [entity.currency]);

  const currencyListItems = useMemo(
    () =>
      getCurrenciesList()
        .sort((a, b) => {
          if (a.country_code < b.country_code) return -1;
          if (a.country_code > b.country_code) return 1;
          return 0;
        })
        .map((item) => ({
          id: item.country_code,
          value: {
            code: item.code,
            label: item.name,
            flag: item.flag,
            symbol: item.symbol,
          },
        })),
    []
  );

  return (
    <SettingsTabsLayout selectedTab="settings">
      <PageLoaderFull isActive={isLoading} isTransparent />
      <div className="flex flex-col gap-10 p-12">
        <ResourceMainSettingsItem
          avatarSelectProps={{
            onSave: handleAvatarChange,
            size: AvatarSelectSize.xxl,
            value: avatar,
            defaultSrc: entity.logoImg || undefined,
            externalSelectFile: selectFile,
          }}
          title={<WorkspaceEditableName />}
          details={[
            {
              title: `${t({
                id: "workspace-page.workspace-id",
                defaultMessage: "Workspace ID",
              })}:`,
              content: entity.uniqueId,
              icon: <CopyIcon />,
              onClick: handleCopyWorkspaceIdToClipboard,
            },
            {
              title: `${t({
                id: "workspace-page.created-on",
                defaultMessage: "Created on",
              })}:`,
              content: localeAwareFormat(
                new Date(entity.__dto.inserted_at),
                "MMM d, uuuu"
              ),
            },
          ]}
          menuItems={[
            {
              id: "leave",
              label: t({
                id: "workspace-page.leave-workspace",
                defaultMessage: "Leave Workspace",
              }),
              icon: <LeaveIcon />,
              onSelect: (_, close) => {
                leaveEntityConfirmationDialogsFlowRef.current?.triggerLeaveEntityFlow(
                  entity
                );
                close();
              },
            },
            {
              id: "delete",
              label: t({
                id: "workspace-page.delete-workspace",
                defaultMessage: "Delete workspace",
              }),
              icon: <DeleteIcon />,
              onSelect: (_, close) => {
                leaveEntityConfirmationDialogsFlowRef.current?.triggerDeleteEntityFlow(
                  entity
                );
                close();
              },
            },
          ]}
        />
        <div className="flex items-center gap-10">
          <CountryPicker
            countryIso={country?.iso}
            onSelect={handleCountryChange}
          >
            <div className="group relative flex h-[260px] w-[288px] items-center justify-center rounded-xl bg-[#F7F8F9] p-6 outline-none duration-500 hover:drop-shadow-[0_4px_8px_rgba(0,0,0,0.12)]">
              <div className="flex flex-col items-center gap-3 duration-500 group-hover:mt-[-90px]">
                <div className="h-10 w-10">
                  <img
                    src={country?.flag}
                    className="h-full w-full"
                    alt={country?.name}
                  />
                </div>
                <div className="flex flex-col gap-[6px] text-center">
                  <span className="text-dark-800 font-medium">
                    {country?.name}
                  </span>
                  <span className="text-sm text-[#828282]">
                    {t({
                      id: "workspace-page.workspace-country",
                      defaultMessage: "Workspace country",
                    })}
                  </span>
                </div>
              </div>
              <div className="bg-primary absolute left-1/2 bottom-[50px] w-max -translate-x-1/2 rounded-lg py-3 px-6 text-sm font-semibold text-white opacity-0 duration-500 group-hover:opacity-100">
                {t({
                  id: "common.change",
                  defaultMessage: "Change",
                })}
              </div>
            </div>
          </CountryPicker>
          <InteractiveContainer
            onClick={() => setEmailVerificationDialogIsOpen(true)}
            className="group relative flex h-[260px] w-[288px] items-center justify-center rounded-xl bg-[#F7F8F9] p-6 outline-none duration-500 hover:drop-shadow-[0_4px_8px_rgba(0,0,0,0.12)]"
          >
            <div className="flex flex-col items-center gap-3 duration-500 group-hover:mt-[-90px]">
              <EmailIcon />
              <div className="flex flex-col gap-[6px] text-center">
                <span className="text-dark-800 font-medium">
                  {entity.billingEmail}
                </span>
                <span
                  className={cx(
                    "text-sm",
                    entity.isEmailVerified
                      ? "text-[#828282]"
                      : "text-gradients-danger"
                  )}
                >
                  {entity.isEmailVerified
                    ? t({
                        id: "workspace-page.workspace-email",
                        defaultMessage: "Workspace email",
                      })
                    : t({
                        id: "workspace-page.workspace-email-verification-needed",
                        defaultMessage: "Email verification needed",
                      })}
                </span>
              </div>
            </div>
            <div className="bg-primary absolute left-1/2 bottom-[50px] w-max -translate-x-1/2 rounded-lg py-3 px-6 text-sm font-semibold text-white opacity-0 duration-500 group-hover:opacity-100">
              {entity.isEmailVerified
                ? t({
                    id: "common.edit",
                    defaultMessage: "Edit",
                  })
                : t({
                    id: "common.verify",
                    defaultMessage: "Verify",
                  })}
            </div>
          </InteractiveContainer>
          <Popover
            placement="bottom"
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger as={InteractiveContainer} ref={triggerRef}>
                <div className="group relative flex h-[260px] w-[288px] items-center justify-center rounded-xl bg-[#F7F8F9] p-6 outline-none duration-500 hover:drop-shadow-[0_4px_8px_rgba(0,0,0,0.12)]">
                  <Tooltip
                    placement="top"
                    renderTrigger={({ props, ref }) => (
                      <div
                        className="absolute top-[23px] right-[23px] h-[18px] w-[18px] rounded-full bg-[#D2D2D8] text-center text-white hover:bg-[#6192BE]"
                        ref={ref}
                        {...props}
                      >
                        i
                      </div>
                    )}
                  >
                    <div className="text-center">
                      {t({
                        id: "workspace-page.workspace-currency-tooltip",
                        defaultMessage:
                          "Indicated Currency will be used in Services module 💸",
                      })}
                    </div>
                  </Tooltip>
                  <div className="flex flex-col items-center gap-3 duration-500 group-hover:mt-[-90px]">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#429760] font-bold text-white">
                      {currency?.code
                        ? currencyCodeToSymbol(currency?.code)
                        : null}
                    </div>
                    <div className="flex flex-col items-center gap-[6px] text-center">
                      {currency && (
                        <span className="text-dark-800 flex items-center gap-2.5 font-medium capitalize">
                          {currency?.code}
                          <SeparatorDotIcon />
                          {currency?.name}
                        </span>
                      )}
                      {!currency && (
                        <span className="text-dark-800 font-medium">
                          {t({
                            id: "workspace-page.workspace-currency",
                            defaultMessage: "Workspace Currency",
                          })}
                        </span>
                      )}
                      <span className="text-sm text-[#828282]">
                        {currency &&
                          t({
                            id: "workspace-page.workspace-currency",
                            defaultMessage: "Workspace Currency",
                          })}
                        {!currency &&
                          t({
                            id: "workspace-page.tap-to-set",
                            defaultMessage: "Tap to set",
                          })}
                      </span>
                    </div>
                  </div>
                  <div className="bg-primary absolute left-1/2 bottom-[50px] w-max -translate-x-1/2 rounded-lg py-3 px-6 text-sm font-semibold text-white opacity-0 duration-500 group-hover:opacity-100">
                    {currency
                      ? t({
                          id: "common.change",
                          defaultMessage: "Change",
                        })
                      : t({
                          id: "workspace-page.set",
                          defaultMessage: "Set",
                        })}
                  </div>
                </div>
              </Trigger>
            )}
          >
            {({ onClose }) => (
              <div className="p-4">
                <ListBox
                  className="w-[280px]"
                  selectionBehavior={{ mode: "single", canToggle: false }}
                  onSelect={(item) => {
                    setCurrencyToChange(item.value.code);
                    onClose();
                  }}
                  hasSearch
                  hasSearchAutoFocus={false}
                  renderSearchLabel={(item) =>
                    `${item.value.label} ${item.value.code}`
                  }
                  items={currencyListItems}
                  renderLabel={(item) => (
                    <div className="font-secondary flex flex-col">
                      <div className="text-dark text-base">{item.id}</div>
                      <div className="text-xs capitalize text-[#828282]">
                        {item.value.label}
                      </div>
                    </div>
                  )}
                  renderStartIcon={(item) => (
                    <img
                      src={item.value.flag}
                      alt="flag"
                      loading="lazy"
                      className="h-[20px] w-[28px]"
                    />
                  )}
                  renderEndIcon={(item) =>
                    currencyCodeToSymbol(item.value.code)
                  }
                  maxVisibleItems={5}
                  itemSize="lg"
                  spaceBetweenItems="compact"
                />
              </div>
            )}
          </Popover>
        </div>
      </div>
      <WorkspaceEmailVerificationDialog
        isOpen={emailVerificationDialogIsOpen}
        onRequestClose={() => setEmailVerificationDialogIsOpen(false)}
      />
      <LeaveEntityConfirmationDialogsFlow
        ref={leaveEntityConfirmationDialogsFlowRef}
      />
      <ConfirmCurrencyChange
        isOpen={Boolean(currencyToChange)}
        currencyCode={currencyToChange}
        onClose={() => setCurrencyToChange(undefined)}
        onSave={() => {
          if (currencyToChange) {
            handleCurrencyChange(currencyToChange);
          }
        }}
      />
    </SettingsTabsLayout>
  );
};

export default WorkspaceSettingsPage;
