import {
  InternalTaskFilters,
  PreviewTask,
  TaskDefaultStatusId,
} from "@jugl-web/rest-api/tasks";
import { isoToLocalDate } from "@jugl-web/utils";
import endOfDay from "date-fns/endOfDay";
import isPast from "date-fns/isPast";
import { TaskSorting } from "../../types";
import { UseTasksOptions } from "./types";

// #region Filtering
export const createSearchQueryFilterMatcher =
  (
    searchQuery: UseTasksOptions["searchQuery"],
    searchQueryFilters: ((task: PreviewTask, searchQuery: string) => boolean)[]
  ) =>
  (task: PreviewTask) => {
    if (!searchQuery) {
      return true;
    }

    const lowerCaseQuery = searchQuery.toLowerCase();
    return searchQueryFilters.some((searchQueryFilter) =>
      searchQueryFilter(task, lowerCaseQuery)
    );
  };

export const createAssigneesFilterMatcher =
  (assignees: InternalTaskFilters["assignees"]) => (task: PreviewTask) => {
    if (!assignees || assignees.length === 0) {
      return true;
    }

    if (assignees.includes(null) && task.assignees.length === 0) {
      return true;
    }

    return task.assignees.some((assigneeId) => assignees.includes(assigneeId));
  };

export const createCustomersFilterMatcher =
  (customers: InternalTaskFilters["customers"]) => (task: PreviewTask) => {
    if (!customers || customers.length === 0) {
      return true;
    }

    if (customers.includes(null) && task.cust_id === null) {
      return true;
    }

    return customers.includes(task.cust_id);
  };

export const createCustomFieldsFilterMatcher =
  (customFields: InternalTaskFilters["customFields"]) =>
  (task: PreviewTask) => {
    if (!customFields || Object.keys(customFields).length === 0) {
      return true;
    }

    return Object.entries(customFields).some(
      ([customFieldId, customFieldValues]) => {
        if (customFieldValues.length === 0) {
          return true;
        }

        const taskCustomFieldValue = task.custom_fields?.[customFieldId];

        if (!taskCustomFieldValue) {
          return customFieldValues.includes(null);
        }

        return customFieldValues.includes(taskCustomFieldValue);
      }
    );
  };

export const createIsOverdueFilterMatcher =
  (isOverdue: InternalTaskFilters["isOverdue"]) => (task: PreviewTask) => {
    if (!isOverdue) {
      return true;
    }

    if (task.due_at === null) {
      return false;
    }

    return (
      task.status !== TaskDefaultStatusId.completed &&
      isPast(new Date(task.due_at))
    );
  };

export const createBoardsFilterMatcher =
  (boards: InternalTaskFilters["boards"]) => (task: PreviewTask) => {
    if (!boards || boards.length === 0) {
      return true;
    }

    return boards.includes(task.board_id);
  };

export const createLabelsFilterMatcher =
  (labels: InternalTaskFilters["labels"]) => (task: PreviewTask) => {
    if (!labels || labels.length === 0) {
      return true;
    }

    return labels.includes(task.label_id);
  };

export const createPrioritiesFilterMatcher =
  (priorities: InternalTaskFilters["priorities"]) => (task: PreviewTask) => {
    if (!priorities || priorities.length === 0) {
      return true;
    }

    return priorities.includes(task.priority);
  };

export const createStatusesFilterMatcher =
  (statuses: InternalTaskFilters["statuses"], forceShowCompleted: boolean) =>
  (task: PreviewTask) => {
    if (!forceShowCompleted && task.status === TaskDefaultStatusId.completed) {
      return statuses.includes(TaskDefaultStatusId.completed);
    }

    if (!statuses || statuses.length === 0) {
      return true;
    }

    return statuses.includes(task.status);
  };
// #endregion

// #region Sorting
export const sortByLastUpdatedComparer = (
  taskA: PreviewTask,
  taskB: PreviewTask
) => (taskA.updated_at > taskB.updated_at ? -1 : 1);

export const getSortByDueDateComparer =
  (order: "asc" | "desc") => (taskA: PreviewTask, taskB: PreviewTask) => {
    const dateA = taskA.due_at ? new Date(taskA.due_at).valueOf() : Infinity;
    const dateB = taskB.due_at ? new Date(taskB.due_at).valueOf() : Infinity;

    if (dateA === Infinity && dateB === Infinity) {
      return 0;
    }

    return order === "asc" ? dateA - dateB : dateB - dateA;
  };

export const sortTasks = (tasks: PreviewTask[], sorting: TaskSorting) => {
  switch (sorting) {
    case "lastUpdated":
      return [...tasks].sort(sortByLastUpdatedComparer);
    case "dueDateAsc":
      return [...tasks].sort(getSortByDueDateComparer("asc"));
    case "dueDateDesc":
      return [...tasks].sort(getSortByDueDateComparer("desc"));
    default:
      throw new Error("Invalid sorting type");
  }
};
// #endregion

// #region Recurring tasks
export const generateTaskFutureOccurrences = (sourceTask: PreviewTask) => {
  if (sourceTask.recurrence?.enabled !== true) {
    return [];
  }

  const futureTasks: PreviewTask[] = [];

  sourceTask.recurrences.forEach((recurrenceDateString) => {
    const recurrenceDate = isoToLocalDate(recurrenceDateString);

    const futureTask: PreviewTask = {
      ...sourceTask,
      id: `${sourceTask.id}_${recurrenceDateString}`,
      due_at: endOfDay(recurrenceDate).toISOString(),
      status: TaskDefaultStatusId.pending,
      unread: false,
      _future_task: {
        source_task_id: sourceTask.id,
        recurrence_date: recurrenceDate,
      },
    };

    futureTasks.push(futureTask);
  });

  return futureTasks;
};

export const withFutureTasks = (tasks: PreviewTask[]) => {
  const tasksWithFutureTasks: PreviewTask[] = [];

  tasks.forEach((task) => {
    tasksWithFutureTasks.push(task, ...generateTaskFutureOccurrences(task));
  });

  return tasksWithFutureTasks;
};
// #endregion
