import { UserRole } from "@jugl-web/rest-api";
import { SidebarItem } from "./types";

export const getAccessibleSidebarItems = (
  items: SidebarItem[],
  userRole: UserRole
): SidebarItem[] =>
  items.reduce<SidebarItem[]>((accessibleItems, item) => {
    if (item.requiredUserRoles && !item.requiredUserRoles.includes(userRole)) {
      return accessibleItems;
    }

    if (item.type === "accordion") {
      const accessibleAccordionItems = getAccessibleSidebarItems(
        item.items,
        userRole
      );

      if (accessibleAccordionItems.length === 0) {
        return accessibleItems;
      }

      return [...accessibleItems, { ...item, items: accessibleAccordionItems }];
    }

    return [...accessibleItems, item];
  }, []);
