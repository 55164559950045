export const htmlToPlainText = (html: string): string => {
  // Replace <br> with newline
  html = html.replace(/<br\s*\/?>/gi, "\n");

  // Replace closing paragraph, div, and heading tags with newline
  html = html.replace(/<\/p\s*>/gi, "\n");
  html = html.replace(/<\/div\s*>/gi, "\n");
  html = html.replace(/<\/h[1-6]\s*>/gi, "\n");

  // Replace list items with dash and newline
  html = html.replace(/<li[^>]*>/gi, "- ");
  html = html.replace(/<\/li\s*>/gi, "\n");

  // Replace closing ul/ol with newline
  html = html.replace(/<\/(ul|ol)\s*>/gi, "\n");

  // Remove all remaining tags
  html = html.replace(/<[^>]+>/g, "");

  // Decode HTML entities
  const textarea = document.createElement("textarea");
  textarea.innerHTML = html;
  let text = textarea.value;

  // Normalize multiple newlines and trim
  text = text.replace(/\n{2,}/g, "\n").trim();

  return text;
};
