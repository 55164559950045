import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import Lottie from "react-lottie";
import alertAnimation from "./assets/alert.json";

export const RestrictionMemberAlert: React.FC<{
  isOpen: boolean;
  type?: "access" | "restrictions" | "suspended";
  onRequestClose: () => void;
}> = ({ isOpen, type, onRequestClose }) => {
  const { t } = useTranslations();

  const { title, content } = useMemo(() => {
    switch (type) {
      case "access":
        return {
          title: t({
            id: "chats-page.roles-restrictions",
            defaultMessage: "Roles Restrictions",
          }),
          content: t({
            id: "chats-page.chat-access-admin-alert",
            defaultMessage:
              "Due to Roles Permissions, this user don't have access to the Chats and can't be assigned as Group Admin 🙅‍♀️",
          }),
        };
      case "restrictions":
        return {
          title: t({
            id: "chats-page.roles-restrictions",
            defaultMessage: "Roles Restrictions",
          }),
          content: t({
            id: "chats-page.role-oermissions-admin-alert",
            defaultMessage:
              "Due to Roles Permissions, this user cannot be assigned as a Group Admin 🙅‍♀️",
          }),
        };
      default:
        return {
          title: t({
            id: "chats-page.suspended-user",
            defaultMessage: "Suspended user",
          }),
          content: t({
            id: "chats-page.user-status-admin-alert",
            defaultMessage:
              "This user has been suspended and can't be assigned as Group Admin",
          }),
        };
    }
  }, [type, t]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      img={
        <Lottie
          options={{
            animationData: alertAnimation,
          }}
          height={70}
          width={70}
        />
      }
      title={title}
      content={content}
      buttons={[
        {
          text: t({
            id: "common.okay",
            defaultMessage: "Okay",
          }),
          onClick: onRequestClose,
        },
      ]}
    />
  );
};
