import React, { memo, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ChatMessage } from "@web-src/modules/chats/types";
import { IconButton } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslations } from "@jugl-web/utils";
import {
  clearMessageBody,
  getChatMessageBody,
} from "@web-src/modules/chats/utils";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { ChatMessageBubbleAction } from "@web-src/modules/chats/components/ChatMessage/types/ChatMessageBubbleAction";
import { ChatMessageBubbleAttachment } from "@web-src/modules/chats/components/ChatMessage/components/ChatMessageBubble/components/ChatMessageBubbleAttachment";
import ChatMessageCall from "@web-src/modules/chats/components/DeprecatedChatMessageBubble/ChatMessageCall";

export const MessageActionItem: React.FC<{
  message?: ChatMessage;
  onCancel: () => void;
  action?: ChatMessageBubbleAction;
}> = memo(({ message, onCancel, action }) => {
  const { t } = useTranslations();
  const body = useMemo(
    () =>
      message?.payload?.body
        ? clearMessageBody(getChatMessageBody(message))
        : "",
    [message]
  );
  if (!message) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        pb: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {action === ChatMessageBubbleAction.EDIT && (
            <>
              <Typography variant="footnoteBold" color="primary">
                {t({
                  id: "common.edit",
                  defaultMessage: "Edit",
                })}
              </Typography>
            </>
          )}
          {action === ChatMessageBubbleAction.REPLY && (
            <>
              <Typography variant="footnoteBold" color="primary">
                <ReplyIcon sx={{ fontSize: "14px" }} />
                {t({
                  id: "chats-page.reply-to",
                  defaultMessage: "Reply to",
                })}
              </Typography>
              <Typography
                variant="footnoteBold"
                sx={{ display: "inline-block", marginLeft: "5px" }}
              >
                <UserProfileName userId={message?.from} />
              </Typography>
            </>
          )}
        </Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      {!!message.payload.attachments?.length && <ChatMessageBubbleAttachment />}
      {message.payload.conference && (
        <ChatMessageCall
          conference={message.payload.conference}
          isOutgoing={false}
        />
      )}
      <span className="text-dark line-clamp-2 jugl__break-word text-[15px] leading-[18px]">
        {body}
      </span>
    </Box>
  );
});
