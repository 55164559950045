import {
  UserCPanelModel,
  UserEntityStatus,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import {
  PageLoaderFull,
  Menu,
  MenuItem,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { assert, useToast, useTranslations } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useNavigateToChat } from "@web-src/modules/chats/hooks/useNavigateToChat";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import {
  LeaveEntityConfirmationDialogsFlow,
  LeaveEntityConfirmationDialogsFlowHandle,
} from "@web-src/modules/entities/components/LeaveEntityConfirmationDialogsFlow";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks/useCallInvitation";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { useCPanelPageContext } from "../../../../CPanelPageProvider";
import { ReactComponent as ActivateUserIcon } from "./assets/activate-user-icon.svg";
import { ReactComponent as BinIcon } from "./assets/bin.svg";
import { ReactComponent as EditIcon } from "./assets/edit-icon.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as MessageIcon } from "./assets/message.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { ReactComponent as ResendIcon } from "./assets/resend-icon.svg";
import { ReactComponent as UserLockIcon } from "./assets/user-lock.svg";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ReactComponent as VerticalDotsIcon } from "./assets/vertical-dots.svg";

export const UserContextMenu: React.FC<{
  user: UserCPanelModel;
  setUserInfo: (user: UserCPanelModel) => void;
  setIsOpenDeleteUserConfirmationPopup: (open: boolean) => void;
}> = ({ user, setUserInfo, setIsOpenDeleteUserConfirmationPopup }) => {
  const meId = useSelector(selectUserId);
  const { entity } = useEntity();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { setOpenedSideBar, updateUser$ } = useCPanelPageContext();

  const leaveEntityConfirmationDialogsFlowRef =
    useRef<LeaveEntityConfirmationDialogsFlowHandle | null>(null);

  const { usersApi } = useRestApiProvider();

  const [updateUsersApi, { isLoading: isUpdateUpdateLoading }] =
    usersApi.useUpdateUserMutation();
  const [resendInvitation, { isLoading: isResendInvitationLoading }] =
    usersApi.useResendEntityInvitationMutation();

  const isLoading = isUpdateUpdateLoading || isResendInvitationLoading;

  const { entityId } = useEntitySelectedProvider();
  const { call } = useCallInvitation();
  const navigateToChat = useNavigateToChat();

  const handleOpenUserProfileClick = useCallback(() => {
    setOpenedSideBar({ sideBar: "userProfile", user });
  }, [user, setOpenedSideBar]);

  const handleCallClick = useCallback(() => {
    if (!user.id) return;
    call({
      callType: "audio",
      userId: user.id,
    });
  }, [call, user.id]);

  const handleMessageClick = useCallback(() => {
    if (!user.id) return;
    navigateToChat(user.id);
  }, [navigateToChat, user.id]);

  const handleUserStatusChange = useCallback(
    async (status: UserEntityStatus) => {
      const response = await updateUsersApi({
        entityId,
        entityRelId: user.entity_rel_id,
        data: {
          status,
        },
      });
      if ("data" in response && response.data) {
        updateUser$.next({ id: user.entity_rel_id, data: { status } });
      }
    },
    [entityId, updateUser$, updateUsersApi, user.entity_rel_id]
  );

  const handleResendInvitation = useCallback(async () => {
    const resp = await resendInvitation({
      entityId,
      entityRelId: user.entity_rel_id,
      data: {
        emails: user.emails,
        mobiles: user.mobiles,
        username: user.username,
      },
    });
    if ("error" in resp && resp.error) {
      return;
    }
    toast(`Invitation was resend to ${user.display_name}`);
  }, [
    entityId,
    resendInvitation,
    toast,
    user.display_name,
    user.emails,
    user.entity_rel_id,
    user.mobiles,
    user.username,
  ]);

  const menuItems: MenuItem<unknown>[] = useMemo(() => {
    switch (user.status) {
      case UserEntityStatus.accDeleted:
        return [
          {
            id: "delete",
            isHidden: user.id === meId,
            icon: <BinIcon />,
            labelClassName: "text-red-500",
            label: t({
              id: "common.remove-user",
              defaultMessage: "Remove User",
            }),
            onSelect: (e, close) => {
              setUserInfo(user);
              setIsOpenDeleteUserConfirmationPopup(true);
              close();
            },
          },
        ];
      case UserEntityStatus.inactive:
        return [
          {
            id: "suspend",
            label: "Activate user",
            icon: <ActivateUserIcon />,
            onSelect: (e, close) => {
              handleUserStatusChange(UserEntityStatus.active);
              close();
            },
          },
          {
            id: "delete",
            isHidden: user.id === meId,
            icon: <BinIcon />,
            labelClassName: "text-red-500",
            label: t({
              id: "common.remove-user",
              defaultMessage: "Remove User",
            }),
            onSelect: (e, close) => {
              setUserInfo(user);
              setIsOpenDeleteUserConfirmationPopup(true);
              close();
            },
          },
        ];
      case UserEntityStatus.inviteRequested:
      case UserEntityStatus.requestDeclined:
        return [
          {
            id: "edit-invitation",
            icon: <EditIcon />,
            label: "Edit Invitation",
            onSelect: async (e, close) => {
              setOpenedSideBar({ sideBar: "invite", user });
              close();
            },
          },
          {
            id: "resend",
            icon: <ResendIcon />,
            label: "Resend Invitation",
            onSelect: async (e, close) => {
              handleResendInvitation();
              close();
            },
          },
          {
            id: "delete",
            isHidden: user.id === meId,
            icon: <BinIcon />,
            labelClassName: "text-red-500",
            label: t({
              id: "common.remove-user",
              defaultMessage: "Remove User",
            }),
            onSelect: (e, close) => {
              setUserInfo(user);
              setIsOpenDeleteUserConfirmationPopup(true);
              close();
            },
          },
        ] as MenuItem<unknown>[];
      case UserEntityStatus.left:
        return [
          {
            id: "delete",
            isHidden: user.id === meId,
            icon: <BinIcon />,
            labelClassName: "text-red-500",
            label: t({
              id: "common.remove-user",
              defaultMessage: "Remove User",
            }),
            onSelect: (e, close) => {
              setUserInfo(user);
              setIsOpenDeleteUserConfirmationPopup(true);
              close();
            },
          },
        ] as MenuItem<unknown>[];
      case UserEntityStatus.active:
        if (user.id === meId) {
          return [
            {
              id: "my-settings",
              icon: <EditIcon />,
              label: t({
                id: "cpanel-page.open-my-account-settings",
                defaultMessage: "Open my account settings",
              }),
              onSelect: (e, close) => {
                navigateToPage("settingsSettings");
                close();
              },
            },
            {
              id: "leave-workspace",
              icon: <LeaveIcon />,
              label: t({
                id: "cpanel-page.leave-workspace",
                defaultMessage: "Leave workspace",
              }),
              labelClassName: "text-red-500",
              onSelect: (_, close) => {
                assert(!!entity, "Entity is not defined");
                leaveEntityConfirmationDialogsFlowRef.current?.triggerLeaveEntityFlow(
                  entity
                );
                close();
              },
            },
          ];
        }
        return [
          {
            id: "open-profile",
            icon: <UserIcon />,
            label: "Open User Profile",
            onSelect: (e, close) => {
              handleOpenUserProfileClick();
              close();
            },
          },
          {
            id: "message",
            icon: <MessageIcon />,
            isHidden: !hasRoleAccessToModule(user.role, AppModule.chat),
            label: "Message",
            onSelect: (e, close) => {
              handleMessageClick();
              close();
            },
          },
          {
            id: "call",
            icon: <PhoneIcon />,
            isHidden: !hasRoleAccessToModule(user.role, AppModule.calls),
            label: "Call",
            onSelect: (e, close) => {
              handleCallClick();
              close();
            },
          },
          {
            id: "suspend",
            icon: <UserLockIcon />,
            label: "Suspend user",
            onSelect: (e, close) => {
              handleUserStatusChange(UserEntityStatus.inactive);
              close();
            },
          },
          {
            id: "remove",
            isHidden: user.id === meId,
            icon: <BinIcon />,
            label: "Remove user",
            labelClassName: "text-red-500",
            onSelect: (e, close) => {
              setUserInfo(user);
              setIsOpenDeleteUserConfirmationPopup(true);
              close();
            },
          },
        ] as MenuItem<unknown>[];
      default:
        return [];
    }
  }, [
    user,
    meId,
    t,
    setUserInfo,
    setIsOpenDeleteUserConfirmationPopup,
    handleUserStatusChange,
    setOpenedSideBar,
    handleResendInvitation,
    navigateToPage,
    entity,
    handleOpenUserProfileClick,
    handleMessageClick,
    handleCallClick,
  ]);
  return (
    <>
      <Menu
        sections={[menuItems]}
        placement="right"
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger
            as={PlainButton}
            ref={triggerRef}
            className="flex h-10 w-10 items-center justify-center transition-all  hover:brightness-95"
          >
            <VerticalDotsIcon />
          </Trigger>
        )}
      />
      <PageLoaderFull isTransparent isActive={isLoading} />
      <LeaveEntityConfirmationDialogsFlow
        ref={leaveEntityConfirmationDialogsFlowRef}
      />
    </>
  );
};
