import { cx, useTranslations } from "@jugl-web/utils";
import React from "react";
import format from "date-fns/format";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";

type PersonalInfoElementBackgroundColor = "green" | "pink" | "purple" | "blue";

const backgroundColorToClasses: Record<
  PersonalInfoElementBackgroundColor,
  string
> = {
  green: "bg-[rgba(34,197,158,0.1)]",
  pink: "bg-[rgba(238,90,138,0.1)]",
  purple: "bg-[rgba(171,89,228,0.1)]",
  blue: "bg-primary-600/10",
};

export type PersonalInfoElementProps = {
  icon: React.ReactNode;
  backgroundColor: PersonalInfoElementBackgroundColor;
  primaryText: React.ReactNode;
  secondaryText?: React.ReactNode;
  items?: {
    id: string;
    primaryText: React.ReactNode | null;
    secondaryText?: React.ReactNode | null;
    startDate?: string | null;
    endDate?: string | null;
    current?: boolean | null;
  }[];
};

export const PersonalInfoElement: React.FC<PersonalInfoElementProps> = ({
  icon,
  backgroundColor,
  primaryText,
  secondaryText,
  items,
}) => {
  const { dateLocale } = useLanguage();
  const { t } = useTranslations();

  return (
    <div className="relative flex w-full min-w-0 shrink-0 flex-col items-start overflow-hidden">
      <div
        className={cx(
          "z-10 flex w-full items-center gap-4 bg-white",
          items && "pb-1"
        )}
      >
        <div
          className={cx(
            "flex h-9 w-9 shrink-0 items-center justify-center rounded-full",
            backgroundColorToClasses[backgroundColor]
          )}
        >
          {icon}
        </div>
        <div className="flex min-w-0 flex-col gap-1">
          <span className="text-dark font-secondary truncate text-sm font-semibold leading-[140%]">
            {primaryText}
          </span>
          {secondaryText && (
            <span className="font-secondary truncate text-xs leading-[140%] text-[#828282]">
              {secondaryText}
            </span>
          )}
        </div>
      </div>
      {items?.map((item) => (
        <div
          className="relative mt-6 flex w-full shrink-0 flex-col gap-1 pl-8"
          key={item.id}
        >
          <div className="border-grey-300 pointer-events-none absolute bottom-[calc(100%-10px)] left-0 z-[-1] h-[150px] w-5 rounded-bl-full border-0 border-l border-b border-solid" />
          <span className="text-dark font-secondary truncate text-sm font-medium leading-[140%]">
            {item.primaryText}
          </span>
          {item.secondaryText && (
            <span className="text-dark-800 font-secondary truncate text-sm leading-[140%]">
              {item.secondaryText}
            </span>
          )}
          {(item.startDate || item.endDate) && (
            <span className="font-secondary text-xs leading-[140%] text-[#828282]">
              {item.startDate &&
                format(new Date(item.startDate), "MMM yyyy", {
                  locale: dateLocale,
                })}
              {item.current
                ? ` - ${t({
                    id: "common.present",
                    defaultMessage: "Present",
                  })}`
                : item.endDate
                ? ` - ${format(new Date(item.endDate), "MMM yyyy", {
                    locale: dateLocale,
                  })}`
                : null}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
