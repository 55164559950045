import { Alert, MobileDrawer, Button } from "@jugl-web/ui-components";
import {
  useTranslations,
  useAutoResizedTextarea,
  useToast,
} from "@jugl-web/utils";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useForm, Controller } from "react-hook-form";
import {
  InventoryItemCategory,
  InventoryItemUnit,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { Segment } from "@jugl-web/ui-components/cross-platform/forms/Segment";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import React, { useEffect, useRef, useMemo } from "react";
import { Select } from "@jugl-web/ui-components/cross-platform/forms/Select";
import { useInventoryFormOptions } from "../../../inventory/hooks/useInventoryFormOptions";
import {
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
  MAX_PRICE_VALUE,
  MAX_NUMBER_VALUE,
} from "./consts";
import { ReactComponent as BusinessIcon } from "./assets/business.svg";
import {
  hideInputArrowsClass,
  preventIncorrectNumberInputCharacters,
} from "../../../inventory/utils";

type FormParams = {
  inventoryItemName: string;
  category: InventoryItemCategory;
  description: string;
  unit: InventoryItemUnit;
  price: {
    value: string;
  };
  quantity: string;
  stock: number;
};
export const AddNewProductForm = ({
  entityId,
  currency,
  isOpen,
  isMobile,
  onSuccess,
  onClose,
}: {
  entityId: string;
  currency: string;
  isOpen: boolean;
  isMobile?: boolean;
  onSuccess: (newItem: { inventory_id: string; qty: number }) => void;
  onClose: () => void;
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormParams>({
    mode: "onChange",
    defaultValues: {
      inventoryItemName: undefined,
      category: InventoryItemCategory.item,
      description: undefined,
      price: {
        value: undefined,
      },
      quantity: undefined,
    },
  });
  const { inventoryApi } = useRestApiProvider();
  const [addInventoryItem] = inventoryApi.useAddInventoryItemMutation();
  const { categories, units } = useInventoryFormOptions();
  const { t } = useTranslations();
  const { toast } = useToast({
    variant: isMobile ? "mobile" : "web",
  });
  const descriptionTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps: descriptionTextareaProps } = useAutoResizedTextarea(
    descriptionTextareaRef
  );

  const name = watch("inventoryItemName");
  const description = watch("description");
  const isService = watch("category") === InventoryItemCategory.service;

  const handleSubmitForm = handleSubmit(
    async (data: Partial<FormParams>) => {
      const qty = parseFloat(data.quantity || "1");
      delete data.quantity;

      const response = await addInventoryItem({
        entityId,
        params: data,
      });

      if ("data" in response && response.data?.id) {
        onSuccess({
          inventory_id: response.data.id,
          qty: data.category === "service" ? 1 : qty,
        });
        onClose();
      }
    },
    (submitErrors) => {
      const mandatoryFieldsCount = Object.entries(submitErrors).filter(
        (err) => err[1].message === "Mandatory field"
      ).length;
      if (mandatoryFieldsCount > 0) {
        toast(
          t(
            {
              id: "feedback.inventory-mandatory-fields-error",
              defaultMessage:
                "{count} Mandatory {count, plural, one {field} other {fields}} should be filled to proceed",
            },
            {
              count: mandatoryFieldsCount,
            }
          ),
          {
            variant: "error",
          }
        );
      }
    }
  );

  useEffect(() => {
    if (!isOpen) {
      reset({
        inventoryItemName: undefined,
        category: InventoryItemCategory.item,
        description: undefined,
        price: {
          value: undefined,
        },
        quantity: undefined,
      });
    }
  }, [isOpen, reset]);

  const content = useMemo(
    () => (
      <div className="flex flex-col gap-6 text-left">
        <TextInput
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          {...register("inventoryItemName", {
            maxLength: {
              value: NAME_MAX_LENGTH,
              message: `${name?.length}/${NAME_MAX_LENGTH}`,
            },
            setValueAs: (value) => value.trim(),
            required: {
              value: true,
              message: t({
                id: "inventory-item-form-page.mandatory-field",
                defaultMessage: "Mandatory field",
              }),
            },
            validate: (value) => !!value.trim(),
          })}
          isRequired
          isInvalid={!!errors.inventoryItemName}
          label={
            isService
              ? t({
                  id: "inventory-item-form-page.service-name",
                  defaultMessage: "Service name",
                })
              : t({
                  id: "inventory-item-form-page.item-name",
                  defaultMessage: "Item name",
                })
          }
          errorMessage={
            (name?.length > NAME_MAX_LENGTH - 11 &&
              `${name?.length}/${NAME_MAX_LENGTH}`) ||
            errors.inventoryItemName?.message
          }
        />
        <Controller
          control={control}
          name="category"
          render={({ field: { value, onChange } }) => (
            <Segment
              label={t({
                id: "inventory-item-form-page.category",
                defaultMessage: "Category",
              })}
              classNames={{
                wrapperClassName: "h-[60px]",
              }}
              isRequired
              selectedItem={value}
              onChange={onChange}
              items={categories.map((category) => ({
                id: category.value,
                title: category.label,
              }))}
            />
          )}
        />
        <div className="pt-6">
          <TextAreaInput
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
            label={t({
              id: "inventory-item-form-page.description",
              defaultMessage: "Description",
            })}
            classNames={{
              inputClassName: "overflow-hidden",
            }}
            {...descriptionTextareaProps}
            isInvalid={!!errors.description}
            isRequired
            errorMessage={
              (description?.length > DESCRIPTION_MAX_LENGTH - 11 &&
                `${description?.length}/${DESCRIPTION_MAX_LENGTH}`) ||
              errors.description?.message
            }
            {...register("description", {
              maxLength: {
                value: DESCRIPTION_MAX_LENGTH,
                message: `${description?.length}/${DESCRIPTION_MAX_LENGTH}`,
              },
              setValueAs: (value) => value.trim(),
              required: {
                value: true,
                message: t({
                  id: "inventory-item-form-page.mandatory-field",
                  defaultMessage: "Mandatory field",
                }),
              },
              validate: (value) => !!value.trim(),
            })}
          />
        </div>
        {!isService && (
          <div className="w-full">
            <Controller
              control={control}
              name="unit"
              rules={{
                required: {
                  value: true,
                  message: t({
                    id: "inventory-item-form-page.mandatory-field",
                    defaultMessage: "Mandatory field",
                  }),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  isRequired
                  isInvalid={!!errors.unit}
                  errorMessage={errors.unit?.message}
                  items={units.map((unit) => ({
                    name: unit.value,
                    value: unit.id,
                    triggerClassName:
                      "px-2 py-0 h-[38px] flex items-center leading-[22px]",
                  }))}
                  placeholder={t({
                    id: "common.select",
                    defaultMessage: "Select",
                  })}
                  label={t({
                    id: "inventory-item-form-page.unit",
                    defaultMessage: "Unit",
                  })}
                  onSelect={onChange}
                  value={value || undefined}
                />
              )}
            />
          </div>
        )}
        <div className="flex w-full justify-evenly gap-4">
          <div className="w-full">
            <Controller
              control={control}
              name="price"
              rules={{
                validate: (value) => {
                  if (Number(value) > MAX_PRICE_VALUE) {
                    return t(
                      {
                        id: "inventory-item-form-page.price-too-high",
                        defaultMessage: "Max value is {maxPriceValue}",
                      },
                      {
                        maxPriceValue: MAX_PRICE_VALUE,
                      }
                    );
                  }
                  if (!value) {
                    return t({
                      id: "inventory-item-form-page.mandatory-field",
                      defaultMessage: "Mandatory field",
                    });
                  }
                  return true;
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  placeholder={t({
                    id: "common.enter",
                    defaultMessage: "Enter",
                  })}
                  type="text"
                  max={MAX_PRICE_VALUE}
                  onKeyDown={(e) =>
                    preventIncorrectNumberInputCharacters(e, true)
                  }
                  onBlur={() => {
                    const isNaN = Number.isNaN(value);
                    if (value) {
                      onChange({
                        value: isNaN
                          ? undefined
                          : parseFloat(value.value.replace(",", ".")).toFixed(
                              2
                            ),
                      });
                    }
                  }}
                  isInvalid={!!errors.price}
                  label={
                    isService
                      ? t({
                          id: "inventory-item-form-page.price",
                          defaultMessage: "Price",
                        })
                      : t({
                          id: "inventory-item-form-page.price-unit",
                          defaultMessage: "Price/unit",
                        })
                  }
                  onChange={(e) => {
                    const hasMultipleDecimals = [
                      ...e.target.value.matchAll(/[.,]/g),
                    ];
                    if (hasMultipleDecimals.length > 1) {
                      return;
                    }
                    onChange({
                      value: e.target.value,
                    });
                  }}
                  isRequired
                  errorMessage={errors.price?.message}
                  value={value?.value}
                  endContent={
                    <div className="font-secondary text-dark text-base">
                      {currency}
                    </div>
                  }
                  inputMode="decimal"
                />
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              control={control}
              name="quantity"
              rules={{
                validate: (value) => {
                  if (isService) {
                    return true;
                  }
                  if (Number(value) < 1) {
                    return t({
                      id: "feedback.value-must-be-at-least-1",
                      defaultMessage: "Value must be at least 1",
                    });
                  }
                  if (Number(value) > MAX_PRICE_VALUE) {
                    return t(
                      {
                        id: "inventory-item-form-page.price-too-high",
                        defaultMessage: "Max value is {maxPriceValue}",
                      },
                      {
                        maxPriceValue: MAX_PRICE_VALUE,
                      }
                    );
                  }
                  if (!value) {
                    return t({
                      id: "inventory-item-form-page.mandatory-field",
                      defaultMessage: "Mandatory field",
                    });
                  }
                  return true;
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  key={isService ? "service" : "item"}
                  placeholder={t({
                    id: "common.enter",
                    defaultMessage: "Enter",
                  })}
                  isDisabled={isService}
                  classNames={{
                    inputClassName: hideInputArrowsClass,
                    inputWrapperClassName: isService ? "bg-[#FAFAFA]" : "",
                  }}
                  onKeyDown={preventIncorrectNumberInputCharacters}
                  type="number"
                  inputMode="numeric"
                  min={1}
                  max={MAX_PRICE_VALUE}
                  onBlur={() => {
                    const isNaN = Number.isNaN(value);
                    if (value) {
                      onChange(
                        isNaN ? undefined : parseFloat(value).toFixed(0)
                      );
                    }
                  }}
                  isInvalid={!!errors.price}
                  label={t({
                    id: "common.quantity",
                    defaultMessage: "Quantity",
                  })}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  isRequired={!isService}
                  tooltip={
                    isService && !isMobile
                      ? t({
                          id: "inventory-item-form-page.add-service-quantity-tooltip",
                          defaultMessage:
                            "Quantity of One-Time Service cannot be adjusted ℹ️",
                        })
                      : undefined
                  }
                  errorMessage={errors.quantity?.message}
                  value={isService ? 1 : value}
                />
              )}
            />
          </div>
        </div>
        {!isService && (
          <Controller
            control={control}
            name="stock"
            rules={{
              required: {
                value: true,
                message: t({
                  id: "inventory-item-form-page.mandatory-field",
                  defaultMessage: "Mandatory field",
                }),
              },

              validate: (value) => {
                if (Number(value) > MAX_NUMBER_VALUE) {
                  return t(
                    {
                      id: "inventory-item-form-page.max-number-value",
                      defaultMessage: "Max value is {maxNumberValue}",
                    },
                    {
                      maxNumberValue: MAX_NUMBER_VALUE,
                    }
                  );
                }
                return true;
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextInput
                value={value}
                onKeyDown={preventIncorrectNumberInputCharacters}
                isRequired
                isInvalid={!!errors.stock}
                label={t({
                  id: "inventory-item-form-page.opening-stock",
                  defaultMessage: "Opening Stock",
                })}
                classNames={{
                  inputClassName: hideInputArrowsClass,
                }}
                type="number"
                inputMode="numeric"
                errorMessage={errors.stock?.message}
                onChange={(e) => {
                  onChange(e.target.value.toString());
                }}
                min={0}
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
              />
            )}
          />
        )}
      </div>
    ),
    [
      categories,
      control,
      currency,
      description?.length,
      descriptionTextareaProps,
      errors.description,
      errors.inventoryItemName,
      errors.price,
      errors.quantity?.message,
      errors.stock,
      errors.unit,
      isMobile,
      isService,
      name?.length,
      register,
      t,
      units,
    ]
  );
  if (isMobile) {
    return (
      <MobileDrawer
        key={isOpen ? "open" : "closed"}
        header={{
          title: t({
            id: "task-details-page.new-product-form-title",
            defaultMessage: "New Item / Service",
          }),
        }}
        isOpen={isOpen}
        initialFocus="none"
        size="xl"
        placement="bottom"
        hasBackdrop
        onClose={onClose}
      >
        <MobileDrawer.Content>{content}</MobileDrawer.Content>
        <MobileDrawer.Actions>
          <Button onClick={handleSubmitForm}>
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </MobileDrawer.Actions>
      </MobileDrawer>
    );
  }
  return (
    <Alert
      key={isOpen ? "open" : "closed"}
      isOpen={isOpen}
      isCloseButtonVisible
      onRequestClose={onClose}
      className="z-[1000] w-[560px]"
      buttonsContainerClassName="flex justify-center [&>button]:flex-none"
      buttons={[
        {
          text: t({
            id: "common.save",
            defaultMessage: "Save",
          }),
          className: "w-[300px]",
          onClick: handleSubmitForm,
        },
      ]}
      header={
        <div className="flex items-center gap-3">
          <BusinessIcon />
          {t({
            id: "task-details-page.new-product-form-title",
            defaultMessage: "New Item / Service",
          })}
        </div>
      }
      content={content}
    />
  );
};
