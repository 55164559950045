import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { DotIcon } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterPill } from "../FilterPill";

interface LabelFilterPillProps {
  entityId: string;
  labelId: InternalTaskFilters["labels"][number];
  onRemove?: () => void;
}

export const LabelFilterPill: FC<LabelFilterPillProps> = ({
  entityId,
  labelId,
  onRemove,
}) => {
  const { noneLabel, getLabelById } = useTaskFields({ entityId });
  const { t } = useTranslations();

  if (labelId === null) {
    return (
      <FilterPill
        label={noneLabel.text}
        startIcon={<DotIcon size="sm" color={noneLabel.color} />}
        onRemove={onRemove}
      />
    );
  }

  const matchingLabel = getLabelById(labelId);

  return (
    <FilterPill
      label={
        matchingLabel?.text ||
        t({
          id: "tasks-page.unknown-label",
          defaultMessage: "Unknown label",
        })
      }
      startIcon={
        <DotIcon size="sm" color={matchingLabel?.color || noneLabel.color} />
      }
      onRemove={onRemove}
    />
  );
};
