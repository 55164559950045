import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { TaskCustomStatus } from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { FilterSectionProps } from "../../types";
import { FilterSectionLayout } from "../FilterSectionLayout";
import { StatusFilterPill } from "../StatusFilterPill";

export const StatusFilterSection: FC<FilterSectionProps> = ({
  entityId,
  isReadonly,
}) => {
  const { allStatusesSorted } = useTaskStatuses({ entityId });

  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const statusesAsListItems = useMemo<ListBoxItem<TaskCustomStatus>[]>(
    () => allStatusesSorted.map((status) => ({ id: status.id, value: status })),
    [allStatusesSorted]
  );

  const isFilterEmpty = filtersState.statuses.length === 0;
  const isSectionHidden = isReadonly && isFilterEmpty;

  if (isSectionHidden) {
    return null;
  }

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-status-filter",
          defaultMessage: "By Status",
        })}
        endSlot={
          !isReadonly ? (
            <ResourcePickerPopover
              placement="bottom-end"
              title={t({
                id: "tasks-page.select-status",
                defaultMessage: "Select status",
              })}
              items={statusesAsListItems}
              selectionBehavior={{ mode: "multiple" }}
              hasSearch
              defaultSelectedIds={filtersState.statuses}
              maxVisibleItems={5}
              itemSize="md"
              spaceBetweenItems="compact"
              renderLabel={(item) => item.value.text}
              className="w-[315px]"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
              )}
              onSubmit={(ids) => updateFiltersState("statuses", ids)}
            />
          ) : undefined
        }
      />
      {!isFilterEmpty && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.statuses.map((statusId) => (
            <StatusFilterPill
              key={statusId}
              entityId={entityId}
              statusId={statusId}
              onRemove={
                !isReadonly
                  ? () =>
                      updateFiltersState("statuses", (previousStatuses) =>
                        previousStatuses.filter((s) => s !== statusId)
                      )
                  : undefined
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
