import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getStorageItemWithFallback, saveItemToStorage } from "../storage";
import { HookOutOfContextError } from "../errors";

// #region useSpotlight
interface UseSpotlightOptions {
  /**
   * Spotlight ID to persist the progress in the local storage
   */
  id: string;
  delay?: number;
  isDisabled?: boolean;
}

export const useSpotlight = ({
  id,
  delay = 0,
  isDisabled = false,
}: UseSpotlightOptions) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isSeen, setIsSeen] = useState(
    () => getStorageItemWithFallback<number>(id, 0) === -1
  );

  const markAsSeen = useCallback(() => {
    setIsOpen(false);
    setIsSeen(true);
    saveItemToStorage<number>(id, -1);
  }, [id]);

  useEffect(() => {
    if (isSeen || isDisabled) {
      return undefined;
    }

    const timeoutId = window.setTimeout(() => {
      setIsOpen(true);
    }, delay);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [delay, isSeen, isDisabled]);

  return {
    isActive: isOpen,
    markAsSeen,
  };
};
// #endregion

// #region useSpotlightTour
interface UseSpotlightTourOptions<TStep> {
  /**
   * Tour ID to persist the progress in the local storage
   */
  id: string;
  steps: TStep[];
  delayBeforeStep?: number;
  isDisabled?: boolean;
}

interface SpotlightTourContextValue<TStep> {
  isStepActive: (step: TStep) => boolean;
  markStepAsSeen: (step: TStep) => void;
}

export const useSpotlightTour = <TStep,>({
  id,
  steps,
  delayBeforeStep = 0,
  isDisabled,
}: UseSpotlightTourOptions<TStep>) => {
  if (steps.length === 0) {
    throw new Error("At least one step is required");
  }

  const timeoutIdRef = useRef<number | null>(null);

  const getInitialStepIndex = useCallback(
    () => Math.min(getStorageItemWithFallback<number>(id, 0), steps.length - 1),
    [id, steps.length]
  );

  const [currentStepIndex, setCurrentStepIndex] =
    useState<number>(getInitialStepIndex);

  const [isPaused, setIsPaused] = useState(() => {
    if (delayBeforeStep === 0) {
      return false;
    }

    timeoutIdRef.current = window.setTimeout(() => {
      setIsPaused(false);
    }, delayBeforeStep);

    return true;
  });

  const markStepAsSeen = useCallback(
    (step: TStep) => {
      if (steps[currentStepIndex] !== step) {
        return;
      }

      const wasLastStep = currentStepIndex === steps.length - 1;
      const nextIndex = wasLastStep ? -1 : currentStepIndex + 1;

      saveItemToStorage<number>(id, nextIndex);
      setCurrentStepIndex(nextIndex);

      if (delayBeforeStep > 0) {
        setIsPaused(true);

        timeoutIdRef.current = window.setTimeout(() => {
          setIsPaused(false);
        }, delayBeforeStep);
      }
    },
    [currentStepIndex, delayBeforeStep, id, steps]
  );

  const isStepActive = useCallback(
    (step: TStep) => {
      if (isDisabled || isPaused) {
        return false;
      }

      return steps[currentStepIndex] === step;
    },
    [currentStepIndex, isDisabled, isPaused, steps]
  );

  // reinitialize the current step index when the steps change
  useEffect(() => {
    setCurrentStepIndex(getInitialStepIndex());
  }, [getInitialStepIndex]);

  useEffect(
    () => () => {
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
      }
    },
    []
  );

  const context: SpotlightTourContextValue<TStep> = {
    isStepActive,
    markStepAsSeen,
  };

  return { ...context, context };
};
// #endregion

// #region Context-related stuff
const SpotlightTourContext =
  createContext<SpotlightTourContextValue<string> | null>(null);

interface SpotlightTourProviderProps<TStep> {
  children: ReactNode;
  context: SpotlightTourContextValue<TStep>;
}

export const SpotlightTourProvider = <TStep,>({
  children,
  context,
}: SpotlightTourProviderProps<TStep>) => {
  const TypedContext =
    SpotlightTourContext as Context<SpotlightTourContextValue<TStep> | null>;

  return (
    <TypedContext.Provider value={context}>{children}</TypedContext.Provider>
  );
};

export const useSpotlightTourContext = <TStep,>() => {
  const context = useContext(SpotlightTourContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useSpotlightTourContext",
      "SpotlightTourContext"
    );
  }

  return context as SpotlightTourContextValue<TStep>;
};
// #endregion
