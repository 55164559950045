import { TabsLayout } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, PropsWithChildren } from "react";

type TabId = "inventory-items" | "inventory-discounts" | "inventory-warehouses";

export const InventoryTabsLayout: FC<
  PropsWithChildren<{ selectedTab: TabId; rightContent?: React.ReactNode }>
> = ({ children, selectedTab, rightContent }) => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  return (
    <TabsLayout
      breadcrumbProps={{
        items: [
          {
            title: t({
              id: "inventory-tabs-layout-component.inventory",
              defaultMessage: "Inventory",
            }),
          },
        ],
      }}
      rightContent={rightContent}
      selectedTab={selectedTab}
      tabs={[
        {
          id: "inventory-items",
          title: t({
            id: "inventory-tabs-layout-component.items-services",
            defaultMessage: "Items / Services",
          }),
          onClick: () => navigateToPage("inventoryItems"),
        },
        {
          id: "inventory-discounts",
          title: t({
            id: "inventory-tabs-layout-component.discounts",
            defaultMessage: "Discounts",
          }),
          onClick: () => navigateToPage("inventoryDiscounts"),
        },
        {
          id: "inventory-warehouses",
          title: t({
            id: "inventory-tabs-layout-component.warehouses",
            defaultMessage: "Warehouses",
          }),
          onClick: () => navigateToPage("inventoryWarehouses"),
        },
      ]}
    >
      {children}
    </TabsLayout>
  );
};
