import React, { useEffect } from "react";
import {
  LoadingSpinner,
  PlainButton,
  InteractiveContainer,
} from "@jugl-web/ui-components";
import { InventoryItemCategory, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { priceDisplay, useTranslations, cx } from "@jugl-web/utils";
import { useOrderForm } from "@web-src/modules/orders/pages/OrderFormWizardPage/providers/OrderFormProvider";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { downloadFile } from "@jugl-web/utils/utils/downloadFile";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as ImageIcon } from "./assets/image.svg";
import { ReactComponent as DiscountIcon } from "./assets/discount.svg";
import { ReactComponent as RedDiscountIcon } from "./assets/red-discount.svg";
import { ReactComponent as RemoveDiscountIcon } from "./assets/remove-discount.svg";
import { ReactComponent as ThreeWhiteDotsIcon } from "./assets/three-white-dots.svg";

import { DiscountPicker } from "../../../DiscountPicker";

export const ServiceItem: React.FC<{
  id: string;
  itemIds: string[];
  onDelete?: () => void;
}> = ({ id, itemIds, onDelete }) => {
  const { inventoryApi } = useRestApiProvider();
  const { previewFiles } = useFilePreview();
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { discounts, addToInventoryItemsCache, onDiscountsChange } =
    useOrderForm();
  const { data, isFetching } = inventoryApi.useGetInventoryItemQuery({
    entityId,
    itemId: id,
    silentError: true,
  });
  const [loadDiscountData, { data: discountData }] =
    inventoryApi.useLazyGetDiscountItemQuery();

  const { entity } = useEntitySelectedProvider();
  useEffect(() => {
    if (data) {
      addToInventoryItemsCache(data);
    }
  }, [addToInventoryItemsCache, data]);

  useEffect(() => {
    if (data && discounts[id]?.discount) {
      loadDiscountData({
        entityId,
        id: discounts[id]?.discount,
      });
    }
  }, [data, discountData, discounts, entityId, id, loadDiscountData]);

  if (isFetching) {
    return (
      <div className="bg-grey-100 flex w-full animate-pulse items-center justify-center rounded-2xl p-4">
        <LoadingSpinner />
      </div>
    );
  }
  if (!data) return null;
  const mainImage =
    (data.img?.length && data.img.find((item) => item.order === 1)?.path) ||
    data.img[0]?.path;

  const handleSetDiscount = (ids: string[], setOnAll: boolean) => {
    if (setOnAll) {
      const newDiscountsState = { ...discounts };
      itemIds.forEach((itemId) => {
        newDiscountsState[itemId] = {
          discount: ids[0],
        };
      });
      onDiscountsChange(newDiscountsState);
      return;
    }
    if (ids.length === 0) {
      const newDiscountsState = { ...discounts };
      Object.keys(discounts).forEach((itemId) => {
        delete newDiscountsState[itemId];
      });
      onDiscountsChange(newDiscountsState);
    } else {
      onDiscountsChange({
        ...discounts,
        [id]: {
          discount: ids[0],
        },
      });
    }
  };

  const handleOpenProductImages = () => {
    if (!data?.img) return;
    const sortedFiles =
      data.img.length > 1
        ? [...data.img].sort((a, b) => (a.order || 0) - (b.order || 0))
        : data.img;
    const files = sortedFiles.map((img) => ({
      id: img.id,
      mimeType: "image/*",
      name: "",
      url: img.path,
      onDownload: async () => {
        downloadFile(img.path, img.path);
      },
    }));
    previewFiles(files);
  };
  const handleRemoveDiscount = () => {
    const newDiscountState = { ...discounts };
    delete newDiscountState[id];
    onDiscountsChange(newDiscountState);
  };

  return (
    <div className="border-grey-400 flex w-full items-center justify-between gap-5 overflow-hidden rounded-[10px] border border-dashed bg-[#FAFAFA] py-4 px-5">
      <div className="flex w-full items-center gap-5 overflow-hidden">
        <div className="border-grey-400 bg-grey-200 flex h-[72px] w-[72px] shrink-0 items-center justify-center overflow-hidden rounded-[10px] border border-solid">
          {mainImage ? (
            <div
              className="relative h-full w-full cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenProductImages();
              }}
            >
              <img
                src={mainImage}
                className="h-full w-full object-cover"
                alt={data.name}
              />
              {data.img?.length > 1 && (
                <ThreeWhiteDotsIcon className="absolute bottom-1 left-1/2 z-10 -translate-x-1/2 transform" />
              )}
            </div>
          ) : (
            <ImageIcon />
          )}
        </div>
        <div className="flex w-full flex-col gap-0.5 overflow-hidden">
          <span className="text-dark font-secondary break-words text-sm font-medium leading-[140%] tracking-[0.14px]">
            {data.name}
          </span>
          <span className="text-grey-800 font-secondary break-words text-xs leading-[140%]">
            {data.desc}
          </span>
          <div>
            <DiscountPicker
              value={discounts[id]?.discount}
              onSubmit={handleSetDiscount}
              renderTrigger={({ Trigger, triggerRef }) =>
                !discounts[id]?.discount ? (
                  <Trigger
                    as={InteractiveContainer}
                    className="bg-grey-200 hover:bg-dark-100 flex w-fit items-center gap-1 rounded-lg px-2 py-1"
                    ref={triggerRef}
                  >
                    <DiscountIcon />
                    <div className="font-secondary text-xs text-[#828282]">
                      {t({
                        id: "order-form-wizard-page.add-discount",
                        defaultMessage: "Add Discount",
                      })}
                    </div>
                  </Trigger>
                ) : (
                  <Trigger
                    as={InteractiveContainer}
                    className="bg-tertiary-50 hover:bg-dark-100 flex w-fit items-center gap-1 rounded-lg px-2 py-1"
                    ref={triggerRef}
                  >
                    <RedDiscountIcon />
                    <div className="font-secondary text-dark-700 text-xs">
                      {t({
                        id: "common.discount",
                        defaultMessage: "Discount",
                      })}
                      : {discountData?.discount}%
                    </div>
                    <RemoveDiscountIcon
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveDiscount?.();
                      }}
                    />
                  </Trigger>
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-4">
        <span className="text-dark flex shrink-0 items-center text-sm font-medium leading-[140%] tracking-[0.14px]">
          {discounts[id]?.discount && discountData?.discount && (
            <span className="text-gradients-danger pr-1 text-sm font-medium">
              {priceDisplay(
                parseFloat(data.price) *
                  100 *
                  (1 - discountData.discount / 100),
                entity.currency
              )}
            </span>
          )}
          <span
            className={cx({
              "line-through": discounts[id]?.discount,
            })}
          >
            {priceDisplay(
              parseFloat(data.price) * 100,
              entity.currency,
              !!discounts[id]?.discount
            )}
          </span>
          <span className="text-grey-700 font-normal">
            /
            {data.category === InventoryItemCategory.item
              ? data.unit
              : t({
                  id: "order-form-wizard-page.service",
                  defaultMessage: "service",
                })}
          </span>
        </span>
        {onDelete && (
          <PlainButton onClick={onDelete}>
            <DeleteIcon />
          </PlainButton>
        )}
      </div>
    </div>
  );
};
