import React, { useCallback, useEffect, useRef, useState } from "react";
import { useBlockNavigation, useTranslations } from "@jugl-web/utils";
import {
  Alert,
  ConfirmationPopup,
  InteractiveContainer,
} from "@jugl-web/ui-components";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { ReactComponent as CloseIcon } from "./icons/cancel.svg";
import { ReactComponent as TrashIcon } from "./icons/trash.svg";
import { ReactComponent as MoveIcon } from "./icons/move.svg";
import { MoveToTaskPopover } from "./MoveToTaskPopover";

interface TaskChecklistSelectionFloatingPanelProps {
  entityId: string;
  taskId: string;
  onClose: () => void;
}

export const TaskChecklistSelectionFloatingPanel: React.FC<
  TaskChecklistSelectionFloatingPanelProps
> = ({ onClose, entityId, taskId }) => {
  const {
    selectedChecklistIds,
    isMoveChecklistItemsLoading,
    onMoveChecklist,
    meId,
  } = useTaskChecklistContext();
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const [
    deleteChecklistItemsMutation,
    { isLoading: isDeleteChecklistItemsLoading },
  ] = tasksApi.useDeleteChecklistItemsMutation();

  const [isDeleteConfirmationAlertOpen, setIsDeleteConfirmationAlertOpen] =
    useState(false);

  const moveChecklistConfirmationDialogState = useConfirmationDialogState<{
    item: PreviewTask;
  }>();

  const handleDeleteChecklists = useCallback(async () => {
    await deleteChecklistItemsMutation({
      entityId,
      taskId,
      subtaskIds: selectedChecklistIds,
    });
  }, [deleteChecklistItemsMutation, selectedChecklistIds, entityId, taskId]);

  const confirmNavigation = useRef<(() => void) | null>(null);
  const skipNavigationAlert = useRef(false);

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);

  useEffect(() => {
    if (!selectedChecklistIds.length) {
      onClose();
    }
  }, [selectedChecklistIds, onClose]);

  useBlockNavigation(
    setIsDiscardChangesDialogOpen,
    confirmNavigation,
    skipNavigationAlert
  );

  return (
    <>
      <div
        className="absolute bottom-10 left-1/2 z-10 flex h-[70px] -translate-x-1/2 overflow-hidden rounded-xl bg-white drop-shadow-[0_8px_32px_rgba(0,0,0,0.16)]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-primary-600 flex w-20 items-center justify-center">
          <span className="font-secondary text-lg font-medium leading-[140%] text-white">
            {selectedChecklistIds.length}
          </span>
        </div>
        <MoveToTaskPopover
          entityId={entityId}
          taskId={taskId}
          meId={meId}
          onSelect={(selectedTaskId) => {
            moveChecklistConfirmationDialogState.open({
              metadata: { item: selectedTaskId },
              onConfirm: async () => {
                const isChecklistMovedSuccesfully = await onMoveChecklist(
                  selectedTaskId.id
                );
                if (isChecklistMovedSuccesfully) {
                  onClose();
                }
              },
            });
          }}
          renderTrigger={({ triggerRef, Trigger }) => (
            <Trigger
              as={InteractiveContainer}
              className="hover:bg-grey-100 flex w-[200px] flex-col items-center justify-center transition-colors"
              isDisabled={
                !selectedChecklistIds.length || isMoveChecklistItemsLoading
              }
              ref={triggerRef}
            >
              <MoveIcon />
              <span className="text-dark font-secondary text-sm leading-[150%]">
                {t({ id: "common.move", defaultMessage: "Move" })}
              </span>
            </Trigger>
          )}
        />
        <div className="bg-dark-100 h-full w-px" />
        <InteractiveContainer
          className="hover:bg-grey-100 flex w-[200px] flex-col items-center justify-center transition-colors"
          isDisabled={
            !selectedChecklistIds.length && !isDeleteChecklistItemsLoading
          }
          onClick={() => setIsDeleteConfirmationAlertOpen(true)}
        >
          <TrashIcon />
          <span className="text-dark font-secondary text-sm leading-[150%]">
            {t({ id: "common.delete", defaultMessage: "Delete" })}
          </span>
        </InteractiveContainer>
        <InteractiveContainer
          className="bg-grey-200 hover:bg-grey-300 flex w-20 items-center justify-center transition-colors"
          onClick={onClose}
        >
          <CloseIcon />
        </InteractiveContainer>
      </div>
      <Alert
        isOpen={isDeleteConfirmationAlertOpen}
        onRequestClose={() => setIsDeleteConfirmationAlertOpen(false)}
        title={t({
          id: "tasks-page.delete-subtasks",
          defaultMessage: "Delete subtasks",
        })}
        content={t(
          {
            id: "tasks-page.delete-subtasks-confirmation",
            defaultMessage:
              "Are you sure you want to delete {count, plural, one {selected subtask} other {{count} selected subtasks}}?",
          },
          { count: selectedChecklistIds.length }
        )}
        buttons={[
          {
            text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
            role: "close",
          },
          {
            text: t({ id: "common.delete", defaultMessage: "Delete" }),
            color: "tertiary",
            onClick: () => {
              setIsDeleteConfirmationAlertOpen(false);
              onClose();
              handleDeleteChecklists();
            },
          },
        ]}
      />
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "common.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "inventory-item-form-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: "Cancel",
            role: "close",
            onClick: () => {
              confirmNavigation.current = null;
              setIsDiscardChangesDialogOpen(false);
            },
          },
          {
            text: "Discard",
            color: "tertiary",
            onClick: (_, actions) => {
              confirmNavigation.current?.();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsDiscardChangesDialogOpen(false)}
      />
      <ConfirmationPopup
        hasCancelButton
        isOpen={moveChecklistConfirmationDialogState.isOpen}
        onRequestClose={moveChecklistConfirmationDialogState.close}
        title={t({
          id: "common.confirm-action",
          defaultMessage: "Confirm action",
        })}
        message={t(
          {
            id: "tasks-page.move-subtasks-confirmation",
            defaultMessage:
              "Do you really want to move {count} {count, plural, one {subtask} other {subtasks}} to the Task {taskName}?",
          },
          {
            count: selectedChecklistIds.length,
            taskName: (
              <span className="text-primary-800 font-medium">
                {moveChecklistConfirmationDialogState.metadata?.item.name}
              </span>
            ),
          }
        )}
        variant="web"
        buttons={[
          {
            label: t({
              id: "tasks-page.move",
              defaultMessage: "Move",
            }),
            onClick: () => moveChecklistConfirmationDialogState.confirm(),
          },
        ]}
      />
    </>
  );
};
