import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSnackbar } from "notistack";
import { useTranslations } from "@jugl-web/utils";
import {
  EntitySpaceModel,
  EntitySpaceType,
  UserGeneralProfile,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import {
  Button,
  FormControlLabel,
  InteractiveContainer,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { DepartmentSidebarSteps } from "./types";
import { useTeamsPageContext } from "../../TeamsPageContext";
import { ReactComponent as EditIcon } from "./assets/edit.svg";

type SelectedMembersState = {
  [userId: string]: {
    isChecked: boolean;
    modified?: boolean;
  };
};

export type FormInput = {
  departmentName: string;
};

export const DepartmentFormSidebar: React.FC<{
  isOpen: boolean;
  inEditMode?: boolean;
  onRequestClose: () => void;
  editedSpace?: EntitySpaceModel;
}> = ({ isOpen, onRequestClose, editedSpace, inEditMode }) => {
  const { entitySpacesApi } = useRestApiProvider();
  const { t } = useTranslations();
  const [step, setStep] = useState<DepartmentSidebarSteps>(
    DepartmentSidebarSteps.deptName
  );
  const [searchMembersTerm, setSearchMembersTerm] = useState<string>("");
  const [selectedMembers, setSelectedMembers] = useState<SelectedMembersState>(
    {}
  );
  const {
    register,
    formState: { isValid },
    getValues,
  } = useForm<FormInput>({
    defaultValues: {
      departmentName: editedSpace?.info.title,
    },
  });

  const { entity } = useEntity();
  const { entityId } = useEntitySelectedProvider();
  const { enqueueSnackbar } = useSnackbar();
  const [createSpace, { isLoading: isCreateLogin }] =
    entitySpacesApi.useCreateEntitySpaceMutation();
  const [updateSpace, { isLoading: isUpdateLoading }] =
    entitySpacesApi.useUpdateEntitySpaceMutation();

  const { usersApi } = useRestApiProvider();
  const [updateUsersDepartment, { isLoading: isUpdateUsersLoading }] =
    usersApi.useUpdateUsersDepartmentMutation();

  const isSubmitting = isCreateLogin || isUpdateLoading || isUpdateUsersLoading;

  const { refetchTrigger$ } = useTeamsPageContext();

  function handleSetStep(nextStep: DepartmentSidebarSteps) {
    setStep(nextStep);
  }

  function handleMemberSelect(profile: UserGeneralProfile) {
    setSelectedMembers((prev: SelectedMembersState) => {
      if (!profile.entityRelId) {
        return prev;
      }
      return {
        ...prev,
        [profile.entityRelId]: {
          isChecked: !selectedMembers[profile.entityRelId]?.isChecked,
          modified: true,
        },
      };
    });
  }

  async function handleSpaceCreation() {
    if (!entity?.id) return;

    try {
      const createSpaceResponse = await createSpace({
        data: {
          info: {
            batch: new Date().toISOString(),
            title: getValues().departmentName,
          },
          type: EntitySpaceType.dept,
        },
        entityId: entity.id,
      });

      if ("data" in createSpaceResponse && createSpaceResponse.data.id) {
        const assignementResponse = await handleMembersAssignment(
          createSpaceResponse.data.id
        );

        if (assignementResponse && "data" in assignementResponse) {
          enqueueSnackbar(
            `${getValues().departmentName} ${t({
              id: "feedback.successfully-created",
              defaultMessage: "succesffully created",
            })}`,
            {
              variant: "success",
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
            }
          );
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      }
    }
  }

  async function handleSpaceUpdate() {
    if (editedSpace) {
      const data = {
        ...editedSpace,
        info: {
          title: getValues().departmentName,
          batch: editedSpace.info.batch,
        },
      };
      try {
        let assignementResponse;
        if (data.info.title !== editedSpace.info.title && entity) {
          const updateSpaceResponse = await updateSpace({
            entityId: entity?.id,
            spaceId: editedSpace.id,
            data: {
              type: editedSpace.type,
              info: {
                title: getValues().departmentName,
                batch: editedSpace.info.batch,
              },
            },
          });

          if ("data" in updateSpaceResponse && editedSpace.id) {
            assignementResponse = await handleMembersAssignment(editedSpace.id);
          }
        }
        if (data.info.title === editedSpace.info.title && editedSpace.id) {
          assignementResponse = await handleMembersAssignment(editedSpace.id);
        }
        if (assignementResponse && "data" in assignementResponse) {
          enqueueSnackbar(
            `${data.info.title} ${t({
              id: "feedback.successfully-updated",
              defaultMessage: "succesfully updated",
            })}`,
            {
              variant: "success",
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
            }
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
        }
      }
    }
  }

  async function handleMembersAssignment(id: string) {
    const spaceMembers: string[] = [];
    Object.keys(selectedMembers).forEach((key) => {
      if (selectedMembers[key].isChecked) {
        spaceMembers.push(key);
      }
    });
    try {
      const response = await updateUsersDepartment({
        entityId,
        data: {
          entity_rel_ids: spaceMembers,
          dept_id: id,
        },
      });
      refetchTrigger$.next();
      onRequestClose();
      return response;
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      }
      return null;
    }
  }

  function getTitle() {
    switch (step) {
      case DepartmentSidebarSteps.chooseMembers:
        return inEditMode
          ? t({
              id: "department-sidebar.edit-team",
              defaultMessage: "Edit Team",
            })
          : t({
              id: "department-sidebar.add-new-team",
              defaultMessage: "Add New Team",
            });
      case DepartmentSidebarSteps.deptName:
        return inEditMode
          ? t({
              id: "department-sidebar.edit-team",
              defaultMessage: "Edit Team",
            })
          : t({
              id: "department-sidebar.add-new-team",
              defaultMessage: "Add New Team",
            });
      default:
        return t({
          id: "department-sidebar.add-new-team",
          defaultMessage: "Add New Team",
        });
    }
  }
  return (
    <SidebarDrawer title={getTitle()} isOpen={isOpen} onClose={onRequestClose}>
      <SidebarDrawer.Content>
        {step === DepartmentSidebarSteps.deptName && (
          <div className="border-grey-200 border-0 border-t border-solid px-8">
            <TextField
              isFullWidth
              isRequired
              className="mt-[18px]"
              label={t({
                id: "form-controls.team-name.label",
                defaultMessage: "Team Name",
              })}
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              {...register("departmentName", {
                required: true,
                validate: (value) => !!value.trim(),
              })}
            />
            <Button
              isDisabled={!isValid}
              fullWidth
              className="mt-10"
              onClick={() =>
                handleSetStep(DepartmentSidebarSteps.chooseMembers)
              }
            >
              {t({
                id: "form-controls.next-step.button",
                defaultMessage: "Next step",
              })}
            </Button>
          </div>
        )}
        {step === DepartmentSidebarSteps.chooseMembers && (
          <div className="h-full">
            <div className="border-grey-200 flex w-full items-center justify-between gap-2 border-0 border-b border-solid py-[30px] pr-6 pl-8">
              <span className="truncate text-lg font-semibold leading-[21px] text-[#363636]">
                {getValues().departmentName}
              </span>
              <InteractiveContainer
                onClick={() => setStep(DepartmentSidebarSteps.deptName)}
                className="flex"
              >
                <EditIcon />
              </InteractiveContainer>
            </div>
            <div className="border-grey-200 flex h-full flex-col border-0 border-t border-solid">
              <div className="mt-6 mb-2 flex flex-col gap-2 px-8">
                <FormControlLabel htmlFor="user">
                  {t({
                    id: "form-controls.select-members.label",
                    defaultMessage: "Select Members",
                  })}
                </FormControlLabel>
                <SearchInput
                  id="user"
                  variant="blank"
                  onChange={(e) => setSearchMembersTerm(e.currentTarget.value)}
                />
              </div>
              <div className="flex-1 px-8">
                <HeadlessUsersList
                  fetchParams={{
                    entityId,
                    searchQuery: searchMembersTerm,
                  }}
                  userRenderer={(user) => (
                    <UserListItem
                      variant="web"
                      userId={user.id}
                      mode="check"
                      entityId={entityId}
                      isSelected={
                        !!user.entityRelId &&
                        selectedMembers?.[user.entityRelId]?.isChecked
                      }
                      defaultSubTitle="role"
                      highlightText={searchMembersTerm}
                      onSelect={handleMemberSelect}
                      className="mb-2.5"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </SidebarDrawer.Content>
      {step === DepartmentSidebarSteps.chooseMembers && (
        <SidebarDrawer.Actions>
          <Button
            isDisabled={!isValid || isSubmitting}
            fullWidth
            onClick={() => {
              if (inEditMode) {
                handleSpaceUpdate();
              } else {
                handleSpaceCreation();
              }
            }}
          >
            {inEditMode
              ? t({
                  id: "form-controls.update-team.button",
                  defaultMessage: "Update team",
                })
              : t({
                  id: "form-controls.create-team.button",
                  defaultMessage: "Create team",
                })}
          </Button>
        </SidebarDrawer.Actions>
      )}
    </SidebarDrawer>
  );
};
