import LinkifyReact from "linkify-react";
import { FC, ReactNode } from "react";
import { cx } from "../cx";

export interface LinkifyProps {
  children: ReactNode;
  className?: string;
}

const TRUNCATED_LINK_LENGTH = 100;

export const Linkify: FC<LinkifyProps> = ({ children, className }) => (
  <LinkifyReact
    options={{
      className: cx("text-primary-500 no-underline hover:underline", className),
      target: "_blank",
      truncate: TRUNCATED_LINK_LENGTH,
      rel: "noreferrer",
    }}
  >
    {children}
  </LinkifyReact>
);
