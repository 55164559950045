import { cx, useTranslations } from "@jugl-web/utils";
import { forwardRef } from "react";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { BaseInputProps } from "./types";
import { ReactComponent as ClearIcon } from "./icons/clear.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { PlainButton } from "../PlainButton";

export const BlankSearchInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      className,
      alwaysShowClear,
      containerClassName,
      hasSettingsIndicator,
      onSettingsClick,
      onClear,
      ...inputProps
    },
    ref
  ) => {
    const { t } = useTranslations();

    const isClearButtonVisible =
      (onClear && inputProps.value) || alwaysShowClear || onSettingsClick;

    return (
      <div className={cx("relative", containerClassName)}>
        <SearchIcon className="pointer-events-none absolute left-4 top-1/2 -translate-y-1/2" />
        <input
          type="text"
          placeholder={`${t({
            id: "common.search",
            defaultMessage: "Search",
          })}...`}
          className={cx(
            "jugl__focusable-outline m-0 inline-block h-10 w-full flex-1 rounded-lg border-none p-0 pl-11",
            onSettingsClick ? "pr-20" : "pr-12",
            className
          )}
          {...inputProps}
          ref={ref}
        />
        {(onSettingsClick || isClearButtonVisible) && (
          <div
            className={cx(
              "absolute top-1/2 right-4 flex -translate-y-1/2 items-center gap-2.5"
            )}
          >
            {onSettingsClick && (
              <PlainButton onClick={onSettingsClick} className="relative">
                <SettingsIcon />
                {hasSettingsIndicator && (
                  <div className="bg-gradients-danger absolute -top-px -right-px h-2 w-2 rounded-full border border-solid border-white" />
                )}
              </PlainButton>
            )}
            {isClearButtonVisible && (
              <PlainButton>
                <ClearIcon onClick={onClear} />
              </PlainButton>
            )}
          </div>
        )}
      </div>
    );
  }
);
