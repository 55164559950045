import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { PeoplePage } from "./PeoplePage";
import { TeamsPage } from "./TeamsPage";

export type PeoplePagesNavigationParams = {
  peoplePeople: PageNavParams;
  peopleTeams: PageNavParams;
};

export const peoplePagesNavigationConfig: ModulePagesConfig<PeoplePagesNavigationParams> =
  {
    peoplePeople: {
      element: <PeoplePage />,
      path: "people",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.people,
    },
    peopleTeams: {
      element: <TeamsPage />,
      path: "people/teams",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.people,
    },
  };
