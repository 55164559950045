import { mentionifyHtml } from "@jugl-web/utils/utils/mentions";
import { useDispatch } from "react-redux";
import { driveApi } from "@web-src/features/api/createApi";
import { DriveApiTags } from "@jugl-web/rest-api";
import { useNavigateToChat } from "@web-src/modules/chats/hooks/useNavigateToChat";
import { useCallback } from "react";
import {
  InAppNotification,
  TriggerNotificationParams,
} from "@web-src/modules/notifications/providers/NotificationsProvider";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FirebaseNotification, FirebaseNotificationResult } from "../types";

// TODO: revisit
export const useProcessFCMNotification = () => {
  const { navigateToPage } = useNavigation();
  const { entity } = useEntity();
  const dispatch = useDispatch();
  const navigateToChat = useNavigateToChat();

  const invalidateDriveTag = useCallback(
    (parsedData: FirebaseNotificationResult) =>
      dispatch(
        driveApi.util.invalidateTags([
          {
            type: DriveApiTags.driveDirectoryContents,
            id: parsedData.parent_id,
          },
        ])
      ),
    [dispatch]
  );

  const processParsedDataForMessageNotification = useCallback(
    (parsedData: FirebaseNotificationResult) => {
      const params: TriggerNotificationParams = {};
      let senderId: string | undefined;
      params.body = parsedData.payload?.body;
      if (parsedData.type === "chat" || parsedData.type === "muc") {
        params.module = parsedData.type === "chat" ? "chats" : "groups";
        senderId = parsedData.type === "chat" ? parsedData.from : parsedData.to;
        params.onClick = () =>
          senderId ? navigateToChat(senderId) : undefined;
      } else if (parsedData.type === "call") {
        params.module = "calls";
        senderId = parsedData.from;
      }
      return {
        params,
        senderId,
      };
    },
    [navigateToChat]
  );

  const processFCMNotification: (
    e: FirebaseNotification
  ) => InAppNotification | undefined = useCallback(
    (e: FirebaseNotification) => {
      // TODO: revisit
      if (!e.data?.result) {
        return {
          title: e?.data?.title || e?.notification?.title || "",
          body: e?.data?.body || e?.notification?.body || "",
          clearBody: e?.data?.body || e?.notification?.body || "",
          entityId: entity?.id || "",
        };
      }
      let parsedData: FirebaseNotificationResult;
      try {
        parsedData = JSON.parse(e.data.result);
      } catch {
        return undefined;
      }
      let title: string | undefined = "";
      let senderId: string | undefined;
      let params: TriggerNotificationParams = {};
      let key: string | undefined;
      switch (e.data?.type) {
        case "drive":
          // title = e.data.title;
          params.body = e.data.body;
          params.module = "drive";
          params.onClick = parsedData.is_root
            ? () => navigateToPage("driveDrive", undefined)
            : () =>
                navigateToPage("driveDrive", undefined, {
                  queryParams: {
                    directoryId: parsedData.parent_id,
                    directoryName: parsedData.parent_dir_name,
                  },
                });
          invalidateDriveTag(parsedData);
          break;
        case "task":
          title = e.data.title;
          params.body = e.data.body;
          params.module = "task";
          params.onClick = () =>
            navigateToPage(
              "taskDetails",
              { taskId: parsedData.id || "" },
              { entityId: parsedData.entity_id }
            );
          break;
        case "user":
          title = "People Updates";
          params.body = e.data.body;
          params.module = "user";
          params.onClick = () =>
            navigateToPage("peoplePeople", undefined, {
              entityId: parsedData.entity_id,
            });
          break;
        case "message": {
          const { params: processedParams, senderId: processedSenderId } =
            processParsedDataForMessageNotification(parsedData);
          title = parsedData.payload?.title;
          senderId = processedSenderId;
          params = processedParams;
          key = parsedData.msg_id;
          break;
        }
        case "task_reminder":
          title = e.data.title;
          params.body = e.data.body;
          params.module = "task";
          params.onClick = () =>
            navigateToPage(
              "taskDetails",
              { taskId: parsedData.id || "" },
              { entityId: parsedData.entity_id }
            );
          break;
        default:
          return undefined;
      }

      return {
        key,
        title: title || "",
        module: params.module,
        senderId,
        body: params.body ? mentionifyHtml(params.body) : undefined,
        clearBody: params.body,
        onClick: params.onClick,
        // TODO: dirty
        entityId: parsedData.entity_id || entity?.id || "",
      };
    },
    [
      entity?.id,
      invalidateDriveTag,
      navigateToPage,
      processParsedDataForMessageNotification,
    ]
  );

  return processFCMNotification;
};
