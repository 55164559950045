import { WidgetModel } from "@jugl-web/rest-api/dashboard/models/Widget";
import { LoadingAnimation } from "@jugl-web/ui-components";
import { assert } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC } from "react";
import { useDashboardContext } from "../../hooks/useDashboardContext";
import { useDashboardWidgetData } from "../../hooks/useDashboardWidgetData";
import { DashboardWidgetChart } from "../DashboardWidgetChart";
import { DashboardWidgetChartErrorBoundary } from "../DashboardWidgetChartErrorBoundary";

interface DashboardWidgetBodyProps {
  widget: WidgetModel;
}

export const DashboardWidgetBody: FC<DashboardWidgetBodyProps> = ({
  widget,
}) => {
  const { entityId } = useEntitySelectedProvider();
  const { detailedDashboard } = useDashboardContext();

  assert(!!detailedDashboard, "Dashboard has to be loaded at this point");

  const { data: widgetData, isLoading: isWidgetDataLoading } =
    useDashboardWidgetData({
      entityId,
      dashboardId: detailedDashboard.id,
      widgetId: widget.id,
    });

  return (
    <div className="bg-grey-100 grow overflow-hidden rounded-b-2xl">
      {isWidgetDataLoading || !widgetData ? (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingAnimation size={{ custom: 60 }} />
        </div>
      ) : (
        <div className="animate-fade-in h-full w-full">
          <DashboardWidgetChartErrorBoundary>
            <DashboardWidgetChart
              widgetConfig={widget.config}
              widgetData={widgetData}
              backgroundColor="grey"
            />
          </DashboardWidgetChartErrorBoundary>
        </div>
      )}
    </div>
  );
};
