import { TableGrid, TableGridProps, Tooltip } from "@jugl-web/ui-components";
import React from "react";
import { priceDisplay, useTranslations, cx } from "@jugl-web/utils";
import {
  OrderResponseInvoice,
  OrderResponseInvoiceItem,
} from "@jugl-web/rest-api/orders";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OrderNameTile } from "./components/OrderNameTile";
import { SectionHeader } from "../SectionHeader";
import { ReactComponent as ItemsIcon } from "./assets/items.svg";
import { OrderCategoryTile } from "./components/OrderCategoryTile";
import { OrderInlineEditCell } from "./components/OrderInlineEditCell";
import { ReactComponent as TooltipIcon } from "./assets/tooltip.svg";
import { undiscountedPriceInCents } from "./utils";

export const OrderItemsTable: React.FC<{
  invoice: OrderResponseInvoice;
  enableEdit?: boolean;
  addButton?: React.ReactNode;
  tableActions?: TableGridProps<OrderResponseInvoiceItem>["columns"];
  onInlineEdit?: ({
    item,
    value,
    type,
  }: {
    item: OrderResponseInvoiceItem;
    value: number;
    type: "qty" | "price";
  }) => void;
}> = ({ invoice, enableEdit, addButton, tableActions, onInlineEdit }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();

  return (
    <div>
      <SectionHeader
        icon={<ItemsIcon />}
        title={t({
          id: "tasks-page.items-services",
          defaultMessage: "Items / Services",
        })}
        endContent={enableEdit && addButton}
      />
      <div className="overflow-x-auto">
        <TableGrid
          unstyled
          data={invoice.items}
          className="font-secondary mt-[18px] min-w-fit rounded-xl border border-solid border-[#EEF2F5]"
          headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5]"
          cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-3 border-0 border-t border-r border-solid border-[#EEF2F5] break-all leading-[140%] tracking-[0.14px] hover:bg-grey-200"
          rowHoverClassName="bg-grey-100"
          columns={[
            {
              title: t({
                id: "tasks-page.item-service-name",
                defaultMessage: "Item / service name",
              }),
              className: "min-w-[280px]",
              grow: true,
              content: ({ name, img, discount }) => (
                <OrderNameTile
                  title={name}
                  img={
                    img?.find((el) => el.order === 1)?.path || img?.[0]?.path
                  }
                  discount={discount?.discount}
                />
              ),
            },
            {
              title: t({
                id: "tasks-page.category",
                defaultMessage: "Category",
              }),
              className: "p-0.5 min-w-[90px]",
              grow: true,
              content: ({ category }) => (
                <OrderCategoryTile category={category} />
              ),
            },
            {
              title: t({
                id: "tasks-page.description",
                defaultMessage: "Description",
              }),
              className: "min-w-[120px]",
              grow: true,
              content: ({ desc }) => <>{desc}</>,
            },
            {
              title: (
                <div className="flex justify-between">
                  {t({
                    id: "common.price",
                    defaultMessage: "Price",
                  })}
                  <Tooltip
                    renderTrigger={({ props, ref }) => (
                      <TooltipIcon
                        className="cursor-pointer"
                        ref={ref}
                        {...props}
                      />
                    )}
                    className="w-[232px]"
                  >
                    {t({
                      id: "tasks-page.order-item-price-tooltip",
                      defaultMessage:
                        "Price Changes that was made inside the Order Details will not affect the Item in the Inventory Module ℹ️",
                    })}
                  </Tooltip>
                </div>
              ),
              className: `min-w-[200px] ${onInlineEdit ? "p-0" : ""}`,
              grow: true,
              content: (item) => (
                <OrderInlineEditCell
                  item={item}
                  type="price"
                  onEdit={(enableEdit && onInlineEdit) || undefined}
                />
              ),
            },
            {
              title: (
                <div className="flex justify-between">
                  {t({
                    id: "tasks-page.quantity",
                    defaultMessage: "Quantity",
                  })}
                  <Tooltip
                    renderTrigger={({ props, ref }) => (
                      <TooltipIcon
                        className="cursor-pointer"
                        ref={ref}
                        {...props}
                      />
                    )}
                    className="w-[232px]"
                  >
                    {t({
                      id: "tasks-page.order-quantity-tooltip",
                      defaultMessage:
                        "Quantity of One-Time Service cannot be adjusted ℹ️",
                    })}
                  </Tooltip>
                </div>
              ),
              className: `min-w-[140px] ${onInlineEdit ? "p-0" : ""}`,
              grow: true,
              content: ({ ...item }) => (
                <OrderInlineEditCell
                  item={item}
                  type="qty"
                  onEdit={(enableEdit && onInlineEdit) || undefined}
                />
              ),
            },
            {
              title: t({
                id: "tasks-page.subtotal",
                defaultMessage: "Subtotal",
              }),
              className: "min-w-[200px]",
              grow: true,
              content: ({ total, discount }) => (
                <span>
                  <span
                    className={cx({
                      "text-gradients-danger": discount?.discount,
                    })}
                  >
                    {priceDisplay(total * 100, entity.currency)}{" "}
                  </span>
                  {discount?.discount && (
                    <span className="text-grey-[#828282] text-xs font-medium line-through">
                      {priceDisplay(
                        undiscountedPriceInCents(discount.discount, total) || 0,
                        entity.currency
                      )}
                    </span>
                  )}
                </span>
              ),
            },
            ...(tableActions ?? []),
          ]}
        />
      </div>
      <div className="text-dark-800 font-secondary mt-4 grid grid-cols-5 font-medium leading-[140%] tracking-[0.16px]">
        <div className="col-span-3" />
        <span className="px-4">
          {t({
            id: "tasks-page.total",
            defaultMessage: "Total:",
          })}
        </span>
        <span className="flex items-center px-4">
          {priceDisplay(invoice.total * 100, entity.currency)}
        </span>
      </div>
    </div>
  );
};
