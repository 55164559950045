import { InteractiveContainer } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";
import { FC, ReactNode } from "react";

type CxArgument = Parameters<typeof cx>[number];

interface TabProps {
  title: ReactNode;
  isSelected: boolean;
  className?: string | ((isSelected: boolean) => CxArgument);
  labelClassName?: string;
  count?: number;
  indicatorClassName?: string | ((isSelected: boolean) => CxArgument);
  onClick?: () => void;
}

export const Tab: FC<TabProps> = ({
  title,
  isSelected,
  className,
  labelClassName,
  indicatorClassName,
  count,
  onClick,
}) => (
  <InteractiveContainer
    className={cx(
      "group flex w-full flex-col justify-between gap-3",
      isSelected ? "text-primary-800 font-medium" : " text-dark-700",
      typeof className === "function" ? className(isSelected) : className
    )}
    onClick={onClick}
  >
    <div className="flex items-center justify-center gap-1 px-4 pt-2">
      <span
        className={cx(
          "font-secondary whitespace-nowrap text-base",
          labelClassName
        )}
      >
        {title}
      </span>
      {typeof count === "number" && (
        <span className="bg-grey-200 text-dark-700 font-secondary h-[19px] shrink-0 rounded-full px-2 text-xs font-normal leading-[160%]">
          {count}
        </span>
      )}
    </div>

    <div
      className={cx(
        "h-1 w-full rounded-lg transition-colors",
        isSelected ? "bg-primary" : "group-hover:bg-grey-300 bg-transparent",
        typeof indicatorClassName === "function"
          ? indicatorClassName(isSelected)
          : indicatorClassName
      )}
    />
  </InteractiveContainer>
);
