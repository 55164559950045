import React, { useState, useMemo } from "react";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { useTranslations } from "@jugl-web/utils";
import { Popover } from "@jugl-web/ui-components/cross-platform";
import { MessageReactionSummary } from "@web-src/modules/chats/components/DeprecatedChatMessageBubble/types";
import { useChatMessageProvider } from "@web-src/modules/chats/components/ChatMessage/providers/ChatMessageProvider";
import { ChatMessageBubbleAction } from "@web-src/modules/chats/components/ChatMessage/types/ChatMessageBubbleAction";

export const ReactionsPopover: React.FC<{
  reactionItem: MessageReactionSummary;
  moreReactions: boolean;
  reactionsLength: number;
}> = ({ reactionItem, moreReactions, reactionsLength }) => {
  const { triggerAction } = useChatMessageProvider();
  const [displayAllReactions, setDisplayAllReactions] = useState(false);

  const displayReactions = useMemo(
    () =>
      displayAllReactions ? reactionItem.users : reactionItem.users.slice(0, 5),
    [displayAllReactions, reactionItem.users]
  );
  const { t } = useTranslations();

  return (
    <Popover
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          className="relative flex h-[18px] w-[18px] cursor-pointer items-center justify-center overflow-hidden rounded-sm border-none bg-transparent outline-none hover:bg-gray-200"
        >
          <span className="text-base">{reactionItem.reaction}</span>
          {moreReactions && (
            <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.3)]">
              <span className="text-xs font-medium text-white">
                +{reactionsLength - 5}
              </span>
            </div>
          )}
        </Trigger>
      )}
    >
      {({ onClose }) => (
        <div className="w-[250px] rounded-lg bg-white p-4 shadow-sm shadow-gray-300">
          <div className="flex items-center gap-2">
            <span>{reactionItem.reaction}</span>
            <span className="font-gray-500 text-sm">{reactionItem.count}</span>
          </div>
          <div className="my-4 h-px w-full bg-gray-200" />
          <div className="flex max-h-[181px] flex-col gap-3 overflow-y-auto">
            {displayReactions.map((item) => (
              <div className="flex items-center justify-between" key={item}>
                <div className="truncate text-sm">
                  <UserProfileName userId={item} />
                </div>
                <span className="ml-2 text-xl">{reactionItem.reaction}</span>
              </div>
            ))}
          </div>
          <div className="my-4 h-px w-full bg-gray-200" />
          <div className="flex-end flex items-center justify-end p-2">
            {!displayAllReactions && reactionItem.users.length > 5 && (
              <PlainButton
                className="text-primary-800 mr-auto cursor-pointer text-[14px]"
                onClick={() => setDisplayAllReactions(true)}
              >
                +
                {t(
                  {
                    id: "chats-page.users-more-reactions-count",
                    defaultMessage: "{usersCount} more",
                  },
                  {
                    usersCount: reactionItem.users.length - 5,
                  }
                )}
              </PlainButton>
            )}
            <PlainButton
              className="text-primary-300 cursor-pointer text-sm"
              onClick={() => {
                triggerAction(ChatMessageBubbleAction.SHOW_REACTIONS);
                onClose();
              }}
            >
              {t({
                id: "chats-page.see-all-reactions",
                defaultMessage: "Tap to see all",
              })}
            </PlainButton>
          </div>
        </div>
      )}
    </Popover>
  );
};
