import { DndContext } from "@dnd-kit/core";
import { useCustomers } from "@jugl-web/domain-resources/customers/hooks/useCustomers";
import { useTaskViewsContext } from "@jugl-web/domain-resources/tasks";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTasksQuery } from "@jugl-web/domain-resources/tasks/hooks/useTasksQuery";
import { useTasksViewColumns } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import {
  TaskViewType,
  useTasksViews,
} from "@jugl-web/domain-resources/tasks/hooks/useTasksViews";
import { useHeadlessUsersList } from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { MoreTasksLoading } from "../MoreTasksLoading";
import { TaskViewEmptyState } from "../TaskViewEmptyState";
import { TaskViewLoading } from "../TaskViewLoading";
import { TaskKanbanColumn } from "../TaskKanbanColumn";
import { useTasksPageContext } from "../TasksPageContext";
import { useTasksDnd } from "./hooks/useTasksDnd";

export const TaskKanbanView: FC = () => {
  const {
    tasksSource,
    tasksSourceInfo,
    openFutureTaskDialog,
    navigateToTaskDetailsPage,
    setPreviewTaskId,
  } = useTasksPageContext();

  const { searchQuery, filters, hasActiveFilter, searchQueryFilters } =
    useTaskFiltering();
  const { sorting } = useTaskSorting();

  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);

  const { getViewById } = useTasksViews({
    entityId,
    source: tasksSource,
  });

  const { selectedView } = useTaskViewsContext();
  const { dndContextProps } = useTasksDnd();

  const { ref: moreTasksLoadingRef, inView: isMoreTasksIndicatorInView } =
    useInView();

  const isCustomerView = selectedView.type === TaskViewType.customer;
  const isAssigneeView = selectedView.type === TaskViewType.assignee;
  const isUserBasedView = isAssigneeView || tasksSourceInfo.isTeamTasks;

  const {
    users,
    isLoading: areUsersLoading,
    loadMore: loadMoreUsers,
    reachedEnd: usersReachedEnd,
  } = useHeadlessUsersList({
    entityId,
    sortBy: "name",
    skipInitialLoading: !isUserBasedView,
    boardId:
      tasksSource.type === "boardTasks" && tasksSourceInfo.isUserBoardTasks
        ? tasksSource.boardId
        : undefined,
    onlyReportees: tasksSourceInfo.isTeamTasks,
  });

  const {
    customers,
    isLoading: areCustomersLoading,
    loadMore: loadMoreCustomers,
    reachedEnd: customersReachedEnd,
  } = useCustomers({
    entityId,
    skipInitialLoading: !isCustomerView,
  });

  const { isLoading } = useTasksQuery({ entityId, source: tasksSource });

  const columns = useTasksViewColumns({
    entityId,
    source: tasksSource,
    meId: meId || "",
    users,
    customers,
    searchQuery,
    filters,
    view: tasksSourceInfo.isTeamTasks
      ? getViewById(TaskViewType.reportee)
      : selectedView,
    sorting,
    searchQueryFilters,
  });

  const { t } = useTranslations();

  const hasNoColumn = columns.length === 0;
  const hasAppliedAnyFiltering = searchQuery.length > 0 || hasActiveFilter;

  const shouldShowMoreTasksLoading =
    (isUserBasedView && !usersReachedEnd) ||
    (isCustomerView && !customersReachedEnd);

  const handleTaskClick = (task: PreviewTask) => {
    if (task._future_task) {
      openFutureTaskDialog(task);
      return;
    }

    navigateToTaskDetailsPage(task.id);
  };

  const handleTaskPreviewClick = (task: PreviewTask) => {
    if (task._future_task) {
      openFutureTaskDialog(task);
      return;
    }
    setPreviewTaskId(task.id);
  };

  // Load more users (assignees or reportees)
  useEffect(() => {
    if (
      isAssigneeView &&
      isMoreTasksIndicatorInView &&
      !areUsersLoading &&
      !usersReachedEnd
    ) {
      loadMoreUsers();
    }
  }, [
    areUsersLoading,
    isMoreTasksIndicatorInView,
    isAssigneeView,
    loadMoreUsers,
    usersReachedEnd,
  ]);

  // Load more customers
  useEffect(() => {
    if (
      isCustomerView &&
      isMoreTasksIndicatorInView &&
      !areCustomersLoading &&
      !customersReachedEnd
    ) {
      loadMoreCustomers();
    }
  }, [
    areCustomersLoading,
    customersReachedEnd,
    isCustomerView,
    isMoreTasksIndicatorInView,
    loadMoreCustomers,
  ]);

  if (isLoading) {
    return (
      <TaskViewLoading
        label={t({
          id: "tasks-page.loading-tasks",
          defaultMessage: "Loading Tasks...",
        })}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    );
  }

  if (hasNoColumn) {
    return (
      <TaskViewEmptyState
        subtitle={
          hasAppliedAnyFiltering
            ? t({
                id: "tasks-page.no-tasks-with-selected-filters",
                defaultMessage: "No tasks with selected filters",
              })
            : t({
                id: "tasks-page.no-tasks-in-selected-view",
                defaultMessage: "No tasks in selected view",
              })
        }
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    );
  }

  return (
    <DndContext {...dndContextProps}>
      <div className="animate-fade-in flex h-full gap-6 px-8 pt-8 pb-3.5">
        {columns.map((column) => (
          <TaskKanbanColumn
            key={column.id}
            column={column}
            onTaskClick={handleTaskClick}
            onTaskPreview={handleTaskPreviewClick}
          />
        ))}
        {shouldShowMoreTasksLoading && (
          <MoreTasksLoading ref={moreTasksLoadingRef} className="mx-2" />
        )}
        {/* Hack to prevent the last column from being cut off when the scrollbar is visible */}
        <div className="w-px shrink-0" />
      </div>
    </DndContext>
  );
};
