import React, { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { useTranslations } from "@jugl-web/utils";
import { ChatItem } from "@web-src/modules/chats/types";
import {
  ChatMessageModel,
  ChatMessageType,
  ChatType,
} from "@jugl-web/rest-api";
import { useSendMessage } from "@web-src/modules/chats/hooks/useSendMessage";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { ChatListPicker } from "@web-src/components/ChatListPicker";
import { ChatSideBar } from "@web-src/modules/chats/components/ChatSideBar";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { getChatMessageBody } from "@web-src/modules/chats/utils";
import { useNavigateToChat } from "../../hooks/useNavigateToChat";

export const ForwardChatMessageSideBar: React.FC<{
  messages: ChatMessageModel[];
  onRequestClose: () => void;
}> = ({ messages, onRequestClose }) => {
  const { conversations } = useConversations();
  const navigateToChat = useNavigateToChat();
  const { chat } = useActiveChat();
  const [selectedChats, setSelectedChats] = useState<ChatItem[]>([]);
  const sendMessage = useSendMessage();
  const [search, setSearch] = useState("");
  const meId = useSelector(selectUserId);

  const sortedMessages = useMemo(
    () =>
      messages.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [messages]
  );

  const handleForwardClick = async () => {
    if (!chat) {
      return;
    }
    try {
      selectedChats.forEach((forwardChat) => {
        sortedMessages.forEach((messageItem) => {
          if (!forwardChat.id) {
            return;
          }
          sendMessage?.({
            to: forwardChat.id,
            type:
              forwardChat.type === ChatType.muc
                ? ChatMessageType.muc
                : ChatMessageType.chat,
            body: getChatMessageBody(messageItem),
            extraPayload: {
              conference: messageItem.payload?.conference,
              attachments: messageItem.payload?.attachments,
              forwarded: true,
            },
          });
        });
      });
      if (selectedChats.length === 1 && selectedChats[0].id) {
        navigateToChat(selectedChats[0].id);
      }
      onRequestClose();
    } catch (error) {
      // TODO: handle error
    }
  };

  const visibleChats = useMemo(
    () =>
      conversations.filter(
        (item) =>
          item.id !== chat?.id &&
          !item.data.deleted &&
          (item.data.type === ChatType.chat ||
            item.data.users?.includes(meId || "")) &&
          item.data.title?.toLowerCase().includes(search.toLocaleLowerCase())
      ),
    [conversations, chat, search, meId]
  );

  const chatsToDisplay = useMemo(
    () => visibleChats.map((el) => el.data),
    [visibleChats]
  );

  const { t } = useTranslations();

  return (
    <ChatSideBar
      title={t(
        {
          id: "chats-page.forward-messages",
          defaultMessage: "Forward {messagesCount} Messages to...",
        },
        {
          messagesCount: messages.length,
        }
      )}
      onRequestClose={onRequestClose}
      footer={
        <div className="p-4">
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            disabled={!messages.length || !selectedChats.length}
            onClick={handleForwardClick}
          >
            {t({
              id: "common.forward",
              defaultMessage: "Forward",
            })}
          </Button>
        </div>
      }
    >
      <div className="px-4">
        <SearchInput
          variant="blank"
          className="my-2"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </div>
      {!!conversations.length && !!search && !chatsToDisplay.length && (
        <div className="font-secondary mt-8 w-full text-center text-sm leading-[160%] text-[#828282]">
          {t({
            id: "chats-page.no-results",
            defaultMessage: "No Results 😔",
          })}
        </div>
      )}
      <ChatListPicker
        chats={chatsToDisplay}
        value={selectedChats}
        onSelect={setSelectedChats}
        shouldDisableBroadcastChat
      />
    </ChatSideBar>
  );
};
