import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import Lottie from "react-lottie";
import alertAnimation from "./assets/alert-animation.json";

export const LeaveGroupConfirmationAlert: React.FC<{
  isOpen: boolean;
  isDisabled: boolean;
  name?: string;
  type?: "leave" | "delete";
  onConfirm: () => void;
  onRequestClose: () => void;
}> = ({ isOpen, type, name, isDisabled, onRequestClose, onConfirm }) => {
  const { t } = useTranslations();

  const { title, content } = useMemo(() => {
    if (type === "leave") {
      return {
        title: t({
          id: "chats-page.leave-group",
          defaultMessage: "Leave Group",
        }),
        content: t(
          {
            id: "chats-page.leave-group-confirmation",
            defaultMessage: "Are you sure you want to leave the {name} Chat?",
          },
          {
            name: <span className="text-primary-800 font-medium">{name}</span>,
          }
        ),
      };
    }

    return {
      title: t({
        id: "common.confirm-action",
        defaultMessage: "Confirm Action",
      }),
      content: t(
        {
          id: "chats-page.delete-group-confirmation",
          defaultMessage:
            "Are you sure you want to delete the {name} Chat? This action cant be undone",
        },
        {
          name: <span className="text-primary-800 font-medium">{name}</span>,
        }
      ),
    };
  }, [type, name, t]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      img={
        <Lottie
          options={{ animationData: alertAnimation }}
          height={70}
          width={70}
        />
      }
      title={title}
      content={content}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text:
            type === "leave"
              ? t({
                  id: "common.leave",
                  defaultMessage: "Leave",
                })
              : t({
                  id: "common.delete",
                  defaultMessage: "Delete",
                }),
          color: type === "leave" ? "primary" : "tertiary",
          onClick: onConfirm,
          isDisabled,
        },
      ]}
    />
  );
};
