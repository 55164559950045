import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { InventoryDiscountsPage } from "./InventoryDiscountsPage";
import { InventoryItemFormPage } from "./InventoryItemFormPage";
import { InventoryItemEditStockFormPage } from "./InventoryItemEditStockFormPage";
import { InventoryItemsPage } from "./InventoryItemsPage";
import { InventoryWarehousesPage } from "./InventoryWarehousesPage";

export type InventoryPagesNavigationParams = {
  inventoryItems: PageNavParams;
  inventoryDiscounts: PageNavParams;
  inventoryWarehouses: PageNavParams;
  inventoryItemForm: PageNavParams;
  inventoryItemEditForm: PageNavParams<{
    id: string;
  }>;
  inventoryItemEditStockForm: PageNavParams<{
    id: string;
  }>;
};

export const inventoryPagesNavigationConfig: ModulePagesConfig<InventoryPagesNavigationParams> =
  {
    inventoryItems: {
      element: <InventoryItemsPage />,
      path: "inventory/items",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
    },
    inventoryItemForm: {
      element: <InventoryItemFormPage />,
      path: "/inventory/items/create",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
    },
    inventoryItemEditForm: {
      element: <InventoryItemFormPage />,
      path: "/inventory/items/edit/:id",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
    },
    inventoryItemEditStockForm: {
      element: <InventoryItemEditStockFormPage />,
      path: "/inventory/stock/edit/:id",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
    },
    inventoryDiscounts: {
      element: <InventoryDiscountsPage />,
      path: "inventory/discounts",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
    },
    inventoryWarehouses: {
      element: <InventoryWarehousesPage />,
      path: "inventory/warehouses",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.inventory,
    },
  };
