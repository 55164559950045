export enum FileType {
  ppt,
  xls,
  doc,
  csv,
  pdf,
  txt,
  video,
  image,
  audio,
  gif,
  other,
}

const exactMimeTypeMap: { [key: string]: FileType } = {
  "text/csv": FileType.csv,
  "image/gif": FileType.gif,
  "application/pdf": FileType.pdf,
  "application/msword": FileType.doc,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    FileType.doc,
  "application/vnd.ms-excel": FileType.xls,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    FileType.xls,
  "application/vnd.ms-powerpoint": FileType.ppt,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    FileType.ppt,
};

const broadMimeTypeMap: { [key: string]: FileType } = {
  text: FileType.txt,
  audio: FileType.audio,
  image: FileType.image,
  video: FileType.video,
};

export const getFileTypeByMime = (mime: string): FileType => {
  if (exactMimeTypeMap[mime]) {
    return exactMimeTypeMap[mime];
  }

  const foundEntry = Object.entries(broadMimeTypeMap).find(([category]) =>
    mime.startsWith(category)
  );

  return foundEntry?.[1] || FileType.other;
};
