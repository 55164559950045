import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { useTranslations } from "@jugl-web/utils";
import { InventoryItemParams } from "@jugl-web/rest-api";
import {
  DimensionsInput,
  NativeSelectUnstyled,
  TextInput,
  isNumeric,
} from "@jugl-web/ui-components/cross-platform";
import { stringifyDimensionValue } from "@jugl-web/rest-api/inventory/utils";
import { useInventoryFormOptions } from "@jugl-web/domain-resources/inventory/hooks/useInventoryFormOptions";
import { commonTextFieldProps } from "../../consts";
import { SectionHeader } from "../SectionHeader";
import {
  EAN_MAX_LENGTH,
  ISBN_MAX_LENGTH,
  MAX_WEIGHT_VALUE,
  MPN_MAX_LENGTH,
  SKU_MAX_LENGTH,
  UPC_MAX_LENGTH,
} from "../MainInfoSection/consts";

export const AdditionalInfoSection: React.FC<{
  errors: FieldErrors<InventoryItemParams>;
  control: Control<InventoryItemParams, unknown>;
  register: UseFormRegister<InventoryItemParams>;
  watch: UseFormWatch<InventoryItemParams>;
}> = ({ errors, control, register, watch }) => {
  const { t } = useTranslations();
  const { dimensionUnits, weightUnits } = useInventoryFormOptions();

  const sku = watch("sku");
  const upc = watch("upc");
  const mpn = watch("mpn");
  const ean = watch("ean");
  const isbn = watch("isbn");
  const category = watch("category");
  const weight = watch("weight");

  return (
    <div className="flex w-[1120px] flex-col gap-8 px-[60px]">
      <SectionHeader
        number={2}
        title={t({
          id: "inventory-item-form-page.additional-info",
          defaultMessage: "Additional Info",
        })}
      />
      <div className="grid grid-cols-3 gap-10">
        {category === "item" && (
          <Controller
            control={control}
            name="dimensions"
            render={({ field: { value, onChange } }) => (
              <DimensionsInput
                tooltip={t({
                  id: "inventory-item-form-page.dimensions-tooltip",
                  defaultMessage: "Length X Width X Height",
                })}
                label={t({
                  id: "inventory-item-form-page.dimensions",
                  defaultMessage: "Dimensions",
                })}
                value={stringifyDimensionValue(value) || undefined}
                errorMessage={errors.dimensions?.message}
                onChange={(event) => {
                  const [width, height, depth] = event.target.value.split(";");
                  onChange({
                    value: {
                      width,
                      height,
                      depth,
                    },
                    unit: value?.unit,
                  });
                }}
                {...commonTextFieldProps}
                endContent={
                  <NativeSelectUnstyled
                    placeholder="Unit"
                    value={value?.unit || "cm"}
                    onChange={(e) => {
                      onChange({
                        ...value,
                        unit: e.target.value,
                      });
                    }}
                    className="font-secondary text-dark text-base"
                  >
                    {dimensionUnits.map((unit) => (
                      <option key={unit.id}>{unit.value}</option>
                    ))}
                  </NativeSelectUnstyled>
                }
              />
            )}
          />
        )}

        {category === "item" && (
          <TextInput
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
            type="number"
            {...register("weight.value", {
              validate: (value) => {
                if (value === "") {
                  return true;
                }
                if (Number(value) > MAX_WEIGHT_VALUE) {
                  return t(
                    {
                      id: "inventory-item-form-page.max-input-value",
                      defaultMessage: "Max value is {maxInputValue}",
                    },
                    {
                      maxInputValue: MAX_WEIGHT_VALUE,
                    }
                  );
                }
                return true;
              },
              setValueAs: (value) => {
                if (value === "") {
                  return undefined;
                }
                if (isNumeric(value)) {
                  return value.trim();
                }
                return weight?.value || undefined;
              },
            })}
            onKeyDown={(e) => {
              const notAllowedCharacters = ["e", "E", "+", "-"];
              if (notAllowedCharacters.includes(e.key)) {
                e.preventDefault();
              }
            }}
            min={0}
            isInvalid={!!errors.weight}
            label={t({
              id: "inventory-item-form-page.weight",
              defaultMessage: "Weight",
            })}
            errorMessage={errors.weight?.value?.message}
            endContent={
              <NativeSelectUnstyled
                className="font-secondary text-dark text-base"
                {...register("weight.unit", {
                  setValueAs: (value) => value.trim(),
                })}
                value={weight?.unit || "kg"}
              >
                {weightUnits.map((unit) => (
                  <option key={unit.id}>{unit.value}</option>
                ))}
              </NativeSelectUnstyled>
            }
          />
        )}

        <TextInput
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          {...register("sku", {
            maxLength: {
              value: SKU_MAX_LENGTH,
              message: `${sku?.length}/${SKU_MAX_LENGTH}`,
            },
            setValueAs: (value) => value.trim(),
          })}
          isInvalid={!!errors.sku}
          label={t({
            id: "inventory-item-form-page.sku",
            defaultMessage: "SKU",
          })}
          errorMessage={
            (sku?.length > SKU_MAX_LENGTH - 11 &&
              `${sku?.length}/${SKU_MAX_LENGTH}`) ||
            errors.sku?.message
          }
        />

        <TextInput
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          {...register("upc", {
            maxLength: {
              value: UPC_MAX_LENGTH,
              message: `${upc?.length}/${UPC_MAX_LENGTH}`,
            },
            setValueAs: (value) => value.trim(),
          })}
          isInvalid={!!errors.upc}
          label={t({
            id: "inventory-item-form-page.upc",
            defaultMessage: "UPC",
          })}
          errorMessage={
            (upc?.length > UPC_MAX_LENGTH - 11 &&
              `${upc?.length}/${UPC_MAX_LENGTH}`) ||
            errors.upc?.message
          }
        />

        <TextInput
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          {...register("mpn", {
            maxLength: {
              value: MPN_MAX_LENGTH,
              message: `${mpn?.length}/${MPN_MAX_LENGTH}`,
            },
            setValueAs: (value) => value.trim(),
          })}
          isInvalid={!!errors.mpn}
          label={t({
            id: "inventory-item-form-page.mpn",
            defaultMessage: "MPN",
          })}
          errorMessage={
            (mpn?.length > MPN_MAX_LENGTH - 11 &&
              `${mpn?.length}/${MPN_MAX_LENGTH}`) ||
            errors.mpn?.message
          }
        />

        <TextInput
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          {...register("ean", {
            maxLength: {
              value: EAN_MAX_LENGTH,
              message: `${ean?.length}/${EAN_MAX_LENGTH}`,
            },
            setValueAs: (value) => value.trim(),
          })}
          isInvalid={!!errors.ean}
          label={t({
            id: "inventory-item-form-page.ean",
            defaultMessage: "EAN",
          })}
          errorMessage={
            (ean?.length > EAN_MAX_LENGTH - 11 &&
              `${ean?.length}/${EAN_MAX_LENGTH}`) ||
            errors.ean?.message
          }
        />

        <TextInput
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          {...register("isbn", {
            maxLength: {
              value: ISBN_MAX_LENGTH,
              message: `${isbn?.length}/${ISBN_MAX_LENGTH}`,
            },
            setValueAs: (value) => value.trim(),
          })}
          isInvalid={!!errors.isbn}
          label={t({
            id: "inventory-item-form-page.isbn",
            defaultMessage: "ISBN",
          })}
          errorMessage={
            (isbn?.length > ISBN_MAX_LENGTH - 11 &&
              `${isbn?.length}/${ISBN_MAX_LENGTH}`) ||
            errors.isbn?.message
          }
        />
      </div>
    </div>
  );
};
