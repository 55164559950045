export enum ChatMessagePayloadCallAction {
  call_declined = "call_declined",
  call_invite = "call_invite",
  call_duration = "call_duration",
  call_switch_voice_to_video = "call_switch_voice_to_video",
  call_hold = "call_hold",
  call_un_hold = "call_un_hold",
  call_answered = "call_answered",
  call_delivered = "call_delivered",
}
