import { FC, useCallback, useRef, useState } from "react";
import {
  Button,
  DataLoadingWrapper,
  EmptyListContent,
  HeaderBreadcrumbs,
  InteractiveContainer,
  Menu,
  MultiSectionLayout,
  PlainButton,
  TableGrid,
  Tooltip,
} from "@jugl-web/ui-components";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import Lottie from "react-lottie";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  useEntityProvider,
  useEntitySelectedProvider,
} from "@web-src/modules/entities/providers/EntityProvider";
import { useEffectOnce } from "react-use";
import { OrderListItemModel } from "@jugl-web/rest-api/orders/models/OrderListItem";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { DeleteOrderFormAlert } from "./components/DeleteOrderFormAlert";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import noOrdersFoundAnimation from "./assets/no-orders-found-animation.json";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as ShareIcon } from "./assets/share.svg";
import { ReactComponent as FillFormIcon } from "./assets/fill-form.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as NoInternalOrdersImage } from "./assets/no-internal-orders-found.svg";
import { OrderOldVersionAlert } from "./components/OrderOldVersionAlert";

type PageState = {
  pageNumber: number;
  requestId: number;
  isError?: boolean;
  hasMore?: boolean;
};

export const OrderFormsPage: FC = () => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const { ordersApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const [getOrders, { isLoading, isError, isFetching }] =
    ordersApi.useLazyGetOrdersQuery();
  const { subscriptionRequiredAction } = useEntityProvider();
  const [isOrderOldVersionAlertOpen, setIsOrderOldVersionAlertOpen] =
    useState(false);
  const [updateOrderForm] = ordersApi.useUpdateOrderFormMutation();
  const { isAdmin } = useUserRole();

  const currentPageState = useRef<PageState | null>(null);
  const [orders, setOrders] = useState<OrderListItemModel[]>([]);

  const loadNextPage = useCallback(async () => {
    let nextPageNumber = 1;
    if (currentPageState.current) {
      if (currentPageState.current.isError) {
        nextPageNumber = currentPageState.current.pageNumber;
      } else {
        nextPageNumber = currentPageState.current.pageNumber + 1;
      }
    }

    const nextPageState: PageState = {
      requestId: Math.random(),
      pageNumber: nextPageNumber,
    };

    currentPageState.current = nextPageState;

    const result = await getOrders({
      entityId: entity.id,
      page: nextPageNumber,
      page_size: 20,
      ...(!isAdmin && { form_type: "internal" }),
    });

    if (currentPageState.current?.requestId !== nextPageState.requestId) {
      return;
    }

    if ("error" in result && result.error) {
      nextPageState.isError = true;
      currentPageState.current = nextPageState;
      return;
    }

    if ("data" in result && result.data && result.data.data) {
      const responseData = result.data.data;
      setOrders((prev) =>
        nextPageNumber === 1 ? responseData : [...prev, ...responseData]
      );

      nextPageState.hasMore = result.data.total_pages > nextPageNumber;
      currentPageState.current = nextPageState;
    }
  }, [entity, getOrders, isAdmin]);

  useEffectOnce(() => {
    loadNextPage();
  });

  const [deletedOrderForm, setDeletedOrderForm] =
    useState<OrderListItemModel>();

  const handleCopyToClipboard = useCallback(
    (content: string, type: "link" | "code") => {
      navigator.clipboard.writeText(content).then(() => {
        toast(
          type === "link"
            ? t({
                id: "feedback.link-copied-to-clipboard",
                defaultMessage: "Link was copied to clipboard",
              })
            : t({
                id: "feedback.embed-code-copied-to-clipboard",
                defaultMessage: "Embed code was copied to clipboard",
              })
        );
      });
    },
    [toast, t]
  );

  const handleSwitchFormType = useCallback(
    async (
      formId: string,
      formType: "internal" | "external",
      status: "active" | "inactive"
    ) => {
      setOrders((prevOrders) => {
        const updatedOrders: OrderListItemModel[] = prevOrders.map((order) => {
          if (order.id === formId) {
            return {
              ...order,
              formType,
            };
          }
          return order;
        });

        return updatedOrders;
      });

      const response = await updateOrderForm({
        entityId: entity.id,
        formId,
        opts: {
          form_type: formType,
          status: formType === "internal" ? "active" : status,
        },
      });

      if (response && "error" in response) {
        setOrders((prevOrders) => {
          const updatedOrders: OrderListItemModel[] = prevOrders.map(
            (order) => {
              const oldFormType =
                formType === "internal" ? "external" : "internal";
              if (order.id === formId) {
                return {
                  ...order,
                  formType: oldFormType,
                };
              }

              return order;
            }
          );

          return updatedOrders;
        });

        toast(
          t({
            id: "order-forms-page.switch-visibility-error",
            defaultMessage: "An error occurred when switching form visibility",
          }),
          {
            variant: "error",
          }
        );
      }
    },
    [updateOrderForm, entity.id, toast, t]
  );

  return (
    <>
      <MultiSectionLayout
        header={
          <>
            <HeaderBreadcrumbs
              disableShadow={isAdmin}
              items={[
                {
                  title: t({
                    id: "orders-tabs-layout-component.forms",
                    defaultMessage: "Forms",
                  }),
                },
              ]}
            />
            {isAdmin && (
              <div className="flex h-[72px] items-center justify-end bg-[#EEF2F5] px-8">
                <Button
                  onClick={subscriptionRequiredAction(() =>
                    navigateToPage("orderFormCreate")
                  )}
                  iconEnd={<PlusIcon />}
                  className="h-10"
                >
                  {t({
                    id: "order-forms-page.create-form",
                    defaultMessage: "Create Form",
                  })}
                </Button>
              </div>
            )}
          </>
        }
      >
        <DataLoadingWrapper
          onRetry={loadNextPage}
          isError={isError}
          isLoading={
            isLoading ||
            (isFetching && currentPageState.current?.pageNumber === 1)
          }
          isFetching={isFetching}
        >
          {orders.length ? (
            <div className="px-8 py-10">
              <TableGrid
                unstyled
                data={orders}
                className="min-w-fit rounded-xl border border-solid border-[#EEF2F5]"
                headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5]"
                cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-3 border-0 border-t border-r border-solid border-[#EEF2F5] break-all leading-[140%] tracking-[0.14px] hover:bg-grey-200"
                rowHoverClassName="bg-grey-100"
                onReachEnd={() =>
                  currentPageState.current?.hasMore && !isLoading && !isFetching
                    ? loadNextPage()
                    : undefined
                }
                columns={[
                  {
                    title: t({
                      id: "order-forms-page.form-name",
                      defaultMessage: "Form name",
                    }),
                    grow: true,
                    className: "p-0",
                    content: ({ formName, id, version, formType }) => {
                      const isOldVerion = version === 1;

                      return (
                        <InteractiveContainer
                          className={cx(
                            "font-secondary flex h-full w-full items-center justify-between gap-2 px-4 py-3 text-base font-medium",
                            isOldVerion && "bg-tertiary-50"
                          )}
                          onClick={() =>
                            navigateToPage("orderFormSubmit", {
                              formId: id,
                              formType,
                            })
                          }
                        >
                          {formName}
                          {isOldVerion && (
                            <Tooltip
                              renderTrigger={({ props, ref }) => (
                                <PlainButton ref={ref} {...props}>
                                  <InfoIcon />
                                </PlainButton>
                              )}
                              placement="bottom"
                            >
                              {t({
                                id: "order-forms-page.old-version-order-form-description",
                                defaultMessage:
                                  "Form was created in Previous App Version. Outdated Form cannot be edited. Please create New Form and share with Clients",
                              })}
                            </Tooltip>
                          )}
                        </InteractiveContainer>
                      );
                    },
                  },
                  {
                    title: t({
                      id: "order-forms-page.task-name",
                      defaultMessage: "Task name",
                    }),
                    grow: true,
                    content: ({ taskName }) => <>{taskName}</>,
                  },
                  {
                    title: t({
                      id: "order-forms-page.form-id-order-quantity",
                      defaultMessage: "Form ID & Order quantity",
                    }),
                    grow: true,
                    content: ({ counter, prefix }) => (
                      <>{`${prefix} - ${counter}`}</>
                    ),
                  },
                  ...(isAdmin
                    ? [
                        {
                          title: (
                            <div className="flex justify-between">
                              <span>
                                {t({
                                  id: "order-forms-page.visible-internally",
                                  defaultMessage: "Visible Internally",
                                })}
                              </span>
                              <Tooltip
                                className="font-secondary w-[232px] px-4 py-2 text-left text-xs"
                                renderTrigger={({ props, ref }) => (
                                  <PlainButton ref={ref} {...props}>
                                    <InfoIcon />
                                  </PlainButton>
                                )}
                                placement="bottom"
                              >
                                {t({
                                  id: "order-forms-page.switch-visibility-tooltip",
                                  defaultMessage:
                                    "If Form is enabled to be visible Internally, all Employees of the Workspace will be able to see and submit this Form as well ✅",
                                })}
                              </Tooltip>
                            </div>
                          ),
                          content: (dataItem: OrderListItemModel) => (
                            <div className="flex w-[200px] justify-center">
                              <Switch
                                isChecked={dataItem.formType === "internal"}
                                className="shrink-0"
                                onChange={() =>
                                  handleSwitchFormType(
                                    dataItem.id,
                                    dataItem.formType === "internal"
                                      ? "external"
                                      : "internal",
                                    dataItem.status || "active"
                                  )
                                }
                              />
                            </div>
                          ),
                        },
                      ]
                    : []),
                  {
                    title: t({
                      id: "order-forms-page.share-link-directly",
                      defaultMessage: "Share link directly",
                    }),
                    content: (dataItem) => (
                      <InteractiveContainer
                        className="flex h-full w-full items-center gap-2 px-4 py-3"
                        onClick={() =>
                          handleCopyToClipboard(dataItem.shortUrl, "link")
                        }
                      >
                        <CopyIcon className="shrink-0" />
                        <span className="truncate whitespace-nowrap">
                          {dataItem.shortUrl}
                        </span>
                      </InteractiveContainer>
                    ),
                    className: "w-[220px] p-0",
                  },
                  {
                    title: t({
                      id: "order-forms-page.settings",
                      defaultMessage: "Settings",
                    }),
                    className: "flex justify-center w-full border-r-0",
                    headerClassName: "border-r-0",
                    isHidden: !isAdmin,
                    content: (orderForm) => (
                      <Menu
                        placement="bottom-end"
                        autoClose
                        renderTrigger={({ Trigger, triggerRef }) => (
                          <Trigger
                            as={PlainButton}
                            ref={triggerRef}
                            className="mt-1"
                          >
                            <MoreIcon />
                          </Trigger>
                        )}
                        className="py-2"
                        sections={[
                          [
                            {
                              id: "place-an-order",
                              label:
                                orderForm.formType === "external"
                                  ? t({
                                      id: "order-forms-page.place-an-order",
                                      defaultMessage: "Place an order",
                                    })
                                  : t({
                                      id: "order-forms-page.fill-the-form",
                                      defaultMessage: "Fill the form",
                                    }),
                              icon: <FillFormIcon />,
                              onSelect: () =>
                                navigateToPage("orderFormSubmit", {
                                  formId: orderForm.id,
                                  formType: orderForm.formType || "external",
                                }),
                            },
                            {
                              id: "edit-form",
                              label: t({
                                id: "order-forms-page.edit-form",
                                defaultMessage: "Edit Form",
                              }),
                              icon: <EditIcon />,
                              onSelect: subscriptionRequiredAction(() =>
                                orderForm.version === 1
                                  ? setIsOrderOldVersionAlertOpen(true)
                                  : navigateToPage("orderFormEdit", {
                                      formId: orderForm.id,
                                    })
                              ),
                              isHidden: !isAdmin,
                            },
                            {
                              id: "share-link-directly",
                              label: t({
                                id: "order-forms-page.share-link-directly",
                                defaultMessage: "Share link directly",
                              }),
                              icon: <ShareIcon />,
                              onSelect: () => {
                                handleCopyToClipboard(
                                  orderForm.shortUrl,
                                  "link"
                                );
                              },
                            },
                            {
                              id: "delete-form",
                              label: t({
                                id: "order-forms-page.delete-form",
                                defaultMessage: "Delete Form",
                              }),
                              onSelect: subscriptionRequiredAction(() =>
                                setDeletedOrderForm(orderForm)
                              ),
                              icon: <DeleteIcon />,
                            },
                          ],
                        ]}
                      />
                    ),
                  },
                ]}
              />
            </div>
          ) : (
            <>
              {isAdmin ? (
                <EmptyListContent
                  type="custom"
                  className="w-[400px]"
                  customImg={
                    <Lottie
                      options={{ animationData: noOrdersFoundAnimation }}
                      width={300}
                      height={300}
                    />
                  }
                  customTitle={t({
                    id: "order-forms-page.take-orders-with-form-builder",
                    defaultMessage: "Take orders easily with Form Builder ✨",
                  })}
                  customSubtitle={t({
                    id: "order-forms-page.collect-work-requests",
                    defaultMessage:
                      "Collect work requests from Customers using Jugl Order Form builder",
                  })}
                  customButton={{
                    text: t({
                      id: "order-forms-page.create-form",
                      defaultMessage: "Create Form",
                    }),
                    iconEnd: <PlusIcon />,
                    fullWidth: false,
                    className: "h-10",
                    onClick: subscriptionRequiredAction(() =>
                      navigateToPage("orderFormCreate")
                    ),
                  }}
                />
              ) : (
                <EmptyListContent
                  type="custom"
                  className="w-[400px]"
                  customImg={<NoInternalOrdersImage />}
                  customTitle={t({
                    id: "order-forms-page.internal-forms-splash-page-title",
                    defaultMessage: "Internal Forms for Team tickets ✨",
                  })}
                  customSubtitle={t({
                    id: "order-forms-page.internal-forms-splash-page-description",
                    defaultMessage:
                      "You will find all active Team Forms here, once Admin of this Workspace will create one. Team Tickets can be used for Leave Applications and other Team Requests",
                  })}
                />
              )}
            </>
          )}
        </DataLoadingWrapper>
      </MultiSectionLayout>
      <DeleteOrderFormAlert
        isOpen={!!deletedOrderForm}
        orderForm={deletedOrderForm}
        onClose={() => setDeletedOrderForm(undefined)}
        onSuccessfullyDelete={() => {
          setOrders([]);
          currentPageState.current = null;
          loadNextPage();
        }}
      />
      <OrderOldVersionAlert
        isOpen={isOrderOldVersionAlertOpen}
        onClose={() => setIsOrderOldVersionAlertOpen(false)}
      />
    </>
  );
};
