import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { cx } from "@jugl-web/utils/cx";
import {
  ReactNode,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  FC,
  HTMLAttributes,
} from "react";
import { LoadingAnimation } from "@jugl-web/ui-components/cross-platform";

type TabColor = "white" | "grey";

type TabFontSize = "xs" | "sm";

const tabFontSizeToClasses: Record<TabFontSize, string> = {
  xs: "text-xs",
  sm: "text-sm",
};

const colorToClasses: Record<TabColor, string> = {
  white: "bg-white",
  grey: "bg-grey-100",
};
export interface TabsProps {
  children: ReactNode;
  color?: TabColor;
  isLoading?: boolean;
  className?: string;
}

export interface TabProps {
  label: string | ReactNode;
  isActive: boolean;
  color?: TabColor;
  fontSize?: TabFontSize;
  itemsCount?: number;
  className?: string;
  onClick: () => void;
  isAutoScrollDisabled?: boolean;
}

export interface ButtonTabProps extends HTMLAttributes<HTMLDivElement> {
  icon: ReactNode;
  color?: TabColor;
  onClick: () => void;
}

export interface LoadingTabProps extends HTMLAttributes<HTMLDivElement> {
  color?: TabColor;
}

const TabsSkeleton = ({ color }: { color: TabColor }) => (
  <div className="flex w-full animate-pulse items-center gap-2">
    {Array.from({ length: 3 }).map((_, index) => (
      <div
        key={+index}
        className={cx("h-8 shrink-0 grow rounded-lg", colorToClasses[color])}
      />
    ))}
  </div>
);

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ children, isLoading, className, color = "grey" }, ref) => (
    <div
      ref={ref}
      className={cx(
        "jugl__hidden-scrollbar flex w-full items-center gap-2 overflow-y-auto",
        className
      )}
    >
      {isLoading ? <TabsSkeleton color={color} /> : children}
    </div>
  )
) as ForwardRefExoticComponent<TabsProps & RefAttributes<HTMLDivElement>> & {
  Tab: FC<TabProps>;
  ButtonTab: ForwardRefExoticComponent<
    ButtonTabProps & RefAttributes<HTMLElement>
  >;
  LoadingTab: ForwardRefExoticComponent<
    LoadingTabProps & RefAttributes<HTMLDivElement>
  >;
  LoadingState: FC;
};

Tabs.Tab = ({
  label,
  isActive,
  itemsCount,
  color = "grey",
  fontSize = "xs",
  className,
  onClick,
  isAutoScrollDisabled,
}) => (
  <InteractiveContainer
    onClick={(event) => {
      if (event.target instanceof HTMLElement && !isAutoScrollDisabled) {
        event.target.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }

      onClick();
    }}
    className={cx(
      "flex h-8 shrink-0 items-center gap-1 rounded-lg px-5 text-xs transition-colors",
      isActive ? "bg-primary-500" : colorToClasses[color],
      tabFontSizeToClasses[fontSize],
      className
    )}
  >
    <span
      className={cx(
        "max-w-[200px] truncate transition-colors",
        isActive ? "font-medium text-white" : "text-dark-700 font-normal"
      )}
    >
      {label}
    </span>
    {typeof itemsCount === "number" && (
      <span
        className={cx(
          "flex h-[18px] min-w-[18px] items-center justify-center rounded-full px-1 font-medium transition-colors",
          isActive ? "bg-primary-400 text-white" : "bg-grey-200 text-grey-800"
        )}
      >
        {itemsCount}
      </span>
    )}
  </InteractiveContainer>
);

Tabs.ButtonTab = forwardRef<HTMLElement, ButtonTabProps>(
  ({ icon, className, color = "grey", onClick, ...props }, ref) => (
    <InteractiveContainer
      ref={ref}
      onClick={onClick}
      className={cx(
        "flex h-8 shrink-0 items-center rounded-lg px-5",
        colorToClasses[color],
        className
      )}
      {...props}
    >
      {icon}
    </InteractiveContainer>
  )
);

Tabs.LoadingTab = forwardRef<HTMLDivElement, LoadingTabProps>(
  ({ color = "grey", ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        "flex flex-row items-center rounded-lg px-5 py-1",
        colorToClasses[color]
      )}
    >
      <LoadingAnimation size="sm" />
    </div>
  )
);
