import { ChatMessagesApiFetchParams } from "@jugl-web/rest-api";

export const getChatPaginationId = (
  chatId?: string,
  fetchParams?: Pick<ChatMessagesApiFetchParams, "attachment_type">
) => {
  if (!fetchParams || fetchParams.attachment_type !== undefined) {
    return `messages:${chatId}:attachment-${fetchParams?.attachment_type}`;
  }
  return `messages:${chatId}`;
};
