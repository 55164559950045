import React, { useEffect, useMemo, useState } from "react";
import { useMessageInfoQuery } from "@web-src/modules/chats/chatsApi";
import { dateTimeString } from "@web-src/utils/datetime";
import {
  DataLoadingWrapper,
  Tabs,
} from "@jugl-web/ui-components/cross-platform";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslations } from "@jugl-web/utils";
import uniq from "lodash/uniq";
import { ChatMessageModel, UserGeneralProfile } from "@jugl-web/rest-api";
import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { SidebarDrawer } from "@jugl-web/ui-components";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { ReactComponent as ReadIcon } from "./assets/read.svg";
import { ReactComponent as UnreadIcon } from "./assets/unread.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { ReactComponent as EyeIcon } from "./assets/eye.svg";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import { useActiveChat } from "../../hooks/useActiveChat";

type MessageInfoTab = "read" | "unread";

export const ChatMessageInfoDrawer: React.FC<{
  message?: ChatMessageModel;
  isOpen: boolean;
  groupId?: string;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose, message, groupId }) => {
  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);
  const { t } = useTranslations();
  const { chat } = useActiveChat();

  const [selectedTab, setSelectedTab] = useState<MessageInfoTab>("read");
  const [previewMessage, setPreviewMessage] = useState<ChatMessageModel>();

  useEffect(() => {
    if (message) {
      setPreviewMessage(message);
    }
  }, [message]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab("read");
    }
  }, [isOpen]);

  const timestampString = useMemo(
    () => (previewMessage ? dateTimeString(previewMessage.timestamp) : ""),
    [previewMessage]
  );

  const {
    data: messageInfo,
    isFetching: messageInfoIsFetching,
    isError: messageInfoIsError,
    refetch: messageInfoRefetch,
  } = useMessageInfoQuery(
    message?.msg_id ? { msgId: message.msg_id } : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const readString = useMemo(() => {
    if (
      !!groupId ||
      !messageInfo ||
      !messageInfo.data.read?.length ||
      !messageInfo.data.read[0].action_at
    ) {
      return null;
    }
    return dateTimeString(messageInfo.data.read[0].action_at);
  }, [groupId, messageInfo]);

  const { profiles, isLoading: profilesIsLoading } = useMultipleUserProfiles({
    entityId,
    skip: messageInfoIsFetching,
    userIds: uniq([
      ...(messageInfo?.data?.read?.map((item) => item.user_id) || []),
      ...(messageInfo?.data?.unread?.map((item) => item.user_id) || []),
    ]),
  });

  const profilesMap = useMemo(() => {
    const map: { [key: string]: UserGeneralProfile } = {};
    profiles.forEach((profile) => {
      map[profile.id] = profile;
    });
    return map;
  }, [profiles]);

  const usersList = useMemo(() => {
    if (!groupId || profilesIsLoading || !messageInfo?.data) {
      return null;
    }
    return {
      unread: messageInfo?.data?.unread
        ?.filter((item) => item.user_id !== meId && !!profilesMap[item.user_id])
        .map((item) => ({
          participant: profilesMap[item.user_id],
          at: item.action_at ? dateTimeString(item.action_at) : "",
        }))
        .sort((a, b) =>
          a.participant.displayName.localeCompare(b.participant.displayName)
        ),
      read: messageInfo?.data?.read
        ?.filter((item) => item.user_id !== meId && !!profilesMap[item.user_id])
        .sort(
          (a, b) =>
            new Date(b.action_at).getTime() - new Date(a.action_at).getTime()
        )
        .map((item) => ({
          participant: profilesMap[item.user_id],
          at: item.action_at ? dateTimeString(item.action_at) : "",
        })),
    };
  }, [groupId, profilesIsLoading, messageInfo?.data, meId, profilesMap]);

  const tabs: {
    id: MessageInfoTab;
    label: React.ReactNode;
    isActive: boolean;
    count: number;
    onClick: () => void;
  }[] = useMemo(
    () => [
      {
        id: "read",
        label: (
          <div className="flex items-center gap-1.5">
            <ReadIcon />
            <span>
              {t({
                id: "chats-page.read-by",
                defaultMessage: "Read by",
              })}
            </span>
          </div>
        ),
        isActive: selectedTab === "read",
        count: usersList?.read.length || 0,
        onClick: () => setSelectedTab("read"),
      },
      {
        id: "unread",
        label: (
          <div className="flex items-center gap-1.5">
            <UnreadIcon />
            <span>
              {t({
                id: "chats-page.unread-by",
                defaultMessage: "Unread by",
              })}
            </span>
          </div>
        ),
        isActive: selectedTab === "unread",
        count: usersList?.unread.length || 0,
        onClick: () => setSelectedTab("unread"),
      },
    ],
    [selectedTab, usersList, t]
  );

  return (
    <SidebarDrawer
      onClose={() => onRequestClose()}
      isOpen={isOpen}
      title={t({
        id: "chats-page.message-info",
        defaultMessage: "Message Info",
      })}
    >
      <DataLoadingWrapper
        isLoading={messageInfoIsFetching}
        isError={messageInfoIsError}
        onRetry={messageInfoRefetch}
      >
        <SidebarDrawer.Content className="jugl__custom-scrollbar flex flex-col gap-8 overflow-y-auto py-6 px-6">
          <div className="flex w-full justify-center">
            <div className="w-full">
              {previewMessage && chat?.id && (
                <ChatMessage
                  chatId={chat.id}
                  message={previewMessage}
                  isMessageActionsDisabled
                />
              )}
            </div>
          </div>
          {groupId ? (
            <>
              <div className="flex w-full items-center gap-2">
                {tabs.map((tab) => (
                  <Tabs.Tab
                    key={tab.id}
                    label={tab.label}
                    itemsCount={tab.count}
                    isActive={tab.isActive}
                    onClick={tab.onClick}
                    isAutoScrollDisabled
                    color="grey"
                    fontSize="sm"
                    className="grow basis-0 justify-center"
                  />
                ))}
              </div>
              <div className="flex flex-col gap-2">
                {selectedTab === "read" && (
                  <>
                    {usersList?.read.length ? (
                      usersList.read.map((item) => (
                        <UserListItem
                          key={item.participant.id}
                          entityId={entityId}
                          userId={item.participant.id}
                          subTitle={item.at}
                          variant="web"
                          mode="display"
                        />
                      ))
                    ) : (
                      <span className="px-6 text-sm leading-[130%] text-[#707577]">
                        {t({
                          id: "chats-page.no-one-read-message-yet",
                          defaultMessage: "No one Read this message yet",
                        })}
                      </span>
                    )}
                  </>
                )}

                {selectedTab === "unread" && (
                  <>
                    {usersList?.unread.length ? (
                      usersList.unread.map((item) => (
                        <UserListItem
                          key={item.participant.id}
                          entityId={entityId}
                          userId={item.participant.id}
                          subTitle={item.at}
                          variant="web"
                          mode="display"
                        />
                      ))
                    ) : (
                      <span className="px-6 text-sm leading-[130%] text-[#707577]">
                        {t({
                          id: "chats-page.every-one-read-message",
                          defaultMessage:
                            "Message was Read by all Group Members",
                        })}
                      </span>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-6">
              {readString && (
                <>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <EyeIcon />
                      <span className="text-dark font-secondary font-medium leading-[140%]">
                        {t({
                          id: "chats-page.read",
                          defaultMessage: "Read",
                        })}
                      </span>
                    </div>
                    <span className="text-sm leading-[130%] text-[#707577]">
                      {readString}
                    </span>
                  </div>
                  <div className="bg-grey-200 h-px w-full" />
                </>
              )}
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-dark font-secondary font-medium leading-[140%]">
                    {t({
                      id: "chats-page.sent",
                      defaultMessage: "Sent",
                    })}
                  </span>
                </div>
                <span className="text-sm leading-[130%] text-[#707577]">
                  {timestampString}
                </span>
              </div>
            </div>
          )}
        </SidebarDrawer.Content>
      </DataLoadingWrapper>
    </SidebarDrawer>
  );
};
