import { DeleteBoardConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/DeleteBoardConfirmationDialog";
import { ManageTaskBoardDialog } from "@jugl-web/domain-resources/tasks/components/ManageTaskBoardDialog";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { UsersAvatarStack } from "@jugl-web/domain-resources/users/components/UsersAvatarStack";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { Tooltip } from "@jugl-web/ui-components";
import { BoardAvatar } from "@jugl-web/ui-components/cross-platform/BoardAvatar";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { HeaderBreadcrumbsItemContainer } from "@jugl-web/ui-components/web/HeaderBreadcrumbs/components/HeaderBreadcrumbsItemContainer";
import { HeaderBreadcrumbsItemContent } from "@jugl-web/ui-components/web/HeaderBreadcrumbs/components/HeaderBreadcrumbsItemContent";
import { cx, useTranslations } from "@jugl-web/utils";
import { useSpotlight } from "@jugl-web/utils/hooks/useSpotlight";
import { EDIT_BOARD_SPOTLIGHT_KEY } from "@jugl-web/utils/storage";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, useMemo, useState } from "react";
import { useTasksPageContext } from "../TasksPageContext";
import { ReactComponent as EditIcon } from "./assets/edit.svg";

interface InteractiveUserBoardBreadcrumbItemProps {
  entityId: string;
  boardId: string;
}

export const InteractiveUserBoardBreadcrumbItem: FC<
  InteractiveUserBoardBreadcrumbItemProps
> = ({ entityId, boardId }) => {
  const { tasksSource } = useTasksPageContext();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  const [taskBoardDialogState, setTaskBoardDialogState] = useState<{
    isOpen: boolean;
    board?: TaskBoardModel;
  }>({ isOpen: false });

  const [
    isDeleteBoardConfirmationDialogOpen,
    setIsDeleteBoardConfirmationDialogOpen,
  ] = useState(false);

  const { getBoardById } = useTaskBoards({ entityId });

  const { id: meId } = useMe();

  const matchingBoard = getBoardById(boardId);

  const boardUserIds = useMemo(
    () => (matchingBoard?.users || []).map((u) => u.id),
    [matchingBoard?.users]
  );

  const isBoardOwner = useMemo(
    () => matchingBoard?.users.find((u) => u.id === meId)?.type === "owner",
    [matchingBoard?.users, meId]
  );

  const { isActive: isSpotlightActive, markAsSeen: markSpotlightAsSeen } =
    useSpotlight({
      id: EDIT_BOARD_SPOTLIGHT_KEY,
      delay: 200,
    });

  const handleAfterBoardDelete = (deletedBoardId: string) => {
    if (
      tasksSource.type === "boardTasks" &&
      tasksSource.boardId === deletedBoardId
    ) {
      navigateToPage("tasksTasks", { boardId: "my" });
    }
  };

  if (!matchingBoard) {
    return null;
  }

  return (
    <>
      <SpotlightTooltip
        isOpen={isSpotlightActive && isBoardOwner}
        placement="bottom"
        className="z-[20]"
        onDismiss={markSpotlightAsSeen}
        tip={t({
          id: "tasks-page.tap-to-edit-board-information",
          defaultMessage: "Tap here to edit Board Information ✨",
        })}
        showButton
        renderTrigger={({ ref, props }) => (
          <HeaderBreadcrumbsItemContainer
            ref={ref}
            isBold
            className={cx(
              "group -mx-3 rounded-lg px-3 py-1.5 transition-colors",
              isBoardOwner && "hover:bg-grey-200"
            )}
            onClick={
              isBoardOwner
                ? () => {
                    markSpotlightAsSeen();
                    setTaskBoardDialogState({
                      isOpen: true,
                      board: matchingBoard,
                    });
                  }
                : undefined
            }
            {...props}
          >
            <Tooltip
              isDisabled={isBoardOwner || isSpotlightActive}
              delay="none"
              placement="bottom"
              renderTrigger={({ props: tooltipProps, ref: tooltipRef }) => (
                <HeaderBreadcrumbsItemContent
                  ref={tooltipRef}
                  className={cx(
                    !isBoardOwner && !isSpotlightActive && "cursor-help"
                  )}
                  {...tooltipProps}
                >
                  <BoardAvatar
                    name={matchingBoard.name}
                    color={matchingBoard.color}
                    size="lg"
                  />
                  <span>{matchingBoard.name}</span>
                  <UsersAvatarStack
                    entityId={entityId}
                    userIds={boardUserIds}
                    maxCount={5}
                    size="sm"
                  />
                  {isBoardOwner && (
                    <EditIcon className="opacity-0 transition-opacity group-hover:opacity-100" />
                  )}
                </HeaderBreadcrumbsItemContent>
              )}
            >
              <div className="w-[220px] text-center">
                {t({
                  id: "tasks-page.board-breadcrumb-member-tooltip",
                  defaultMessage:
                    "You are the Member of this Board. Only Owners of the Board can manage it 🔑",
                })}
              </div>
            </Tooltip>
          </HeaderBreadcrumbsItemContainer>
        )}
      />
      {meId && (
        <ManageTaskBoardDialog
          entityId={entityId}
          meId={meId}
          isOpen={taskBoardDialogState.isOpen}
          board={taskBoardDialogState.board}
          onClose={() =>
            setTaskBoardDialogState((previousState) => ({
              ...previousState,
              isOpen: false,
            }))
          }
          onOpenDeleteBoardDialog={() =>
            setIsDeleteBoardConfirmationDialogOpen(true)
          }
        />
      )}
      {taskBoardDialogState.board && (
        <DeleteBoardConfirmationDialog
          entityId={entityId}
          isOpen={isDeleteBoardConfirmationDialogOpen}
          board={taskBoardDialogState.board}
          onClose={() => setIsDeleteBoardConfirmationDialogOpen(false)}
          onAfterDelete={handleAfterBoardDelete}
        />
      )}
    </>
  );
};
