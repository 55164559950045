import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ChatMessagePayloadAttachment } from "@jugl-web/rest-api";
import { getChatMessageAttachmentUrls } from "@web-src/modules/chats/utils/getChatMessageAttachmentUrls";
import React, { useMemo } from "react";

export const PhotoAttachmentItem: React.FC<{
  attachment: ChatMessagePayloadAttachment;
  onClick?: () => void;
}> = ({ attachment, onClick }) => {
  const { entity } = useEntity();
  const previewUrl = useMemo(
    () =>
      entity?.id
        ? getChatMessageAttachmentUrls(entity?.id, attachment).preview
        : // TODO: some default?
          "",
    [attachment, entity?.id]
  );
  return (
    <InteractiveContainer
      className={cx("relative", onClick && "hover:brightness-75")}
      onClick={onClick}
      isDisabled={!onClick}
    >
      <img
        src={previewUrl}
        className="bg-grey block aspect-square w-full bg-gradient-to-b from-[#CECED3] to-[#ECEFF0] object-cover"
        alt=""
      />
    </InteractiveContainer>
  );
};
