import {
  InternalTaskCustomField,
  stripTaskDescriptionPrefix,
  TaskDefaultStatusId,
  TaskPriority,
} from "@jugl-web/rest-api/tasks";
import { ExportedTaskChecklist } from "@jugl-web/rest-api/tasks/models/common-types/ExportedTaskChecklist";
import { ExportedTaskModel } from "@jugl-web/rest-api/tasks/models/ExportedTask";
import { htmlToPlainText, useTranslations } from "@jugl-web/utils";
import {
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import { useCallback } from "react";
import { Cell } from "write-excel-file";
import { useTaskBoards } from "../../useTaskBoards";
import { useTaskPriorities } from "../../useTaskPriorities";
import { useTaskStatuses } from "../../useTaskStatuses";
import { useOrdinalWords } from "./useOrdinalWords";
import {
  formatDate,
  formatUserName,
  formatUsersList,
  getOrdinal,
  parseMention,
} from "./utils";

const nameCellStyle: Cell = {
  backgroundColor: "#1A2023",
  fontWeight: "bold",
  color: "#ffffff",
  fontSize: 20,
  alignVertical: "center",
};

const headerCellStyle: Cell = {
  height: 20,
  alignVertical: "center",
  wrap: true,
  backgroundColor: "#ECEFF0",
  borderStyle: "medium",
  borderColor: "#D0D0D0",
  color: "#1A2023",
  fontWeight: "bold",
};

const cellStyle: Cell = {
  alignVertical: "center",
  color: "#1A2023",
  wrap: true,
};

const highlightedCellStyle: Cell = {
  ...cellStyle,
  color: "#ffffff",
  fontWeight: "bold",
};

const taskPriorityColorMap = {
  [TaskPriority.low]: "#22C59E",
  [TaskPriority.medium]: "#F2994A",
  [TaskPriority.high]: "#EA5353",
};

export const useExportedTableCellGenerator = ({
  entityId,
}: {
  entityId: string;
}) => {
  const { getPriorityDetailsById } = useTaskPriorities();
  const { getStatusById } = useTaskStatuses({ entityId });
  const { getBoardByName } = useTaskBoards({ entityId });
  const { t } = useTranslations();
  const { getOrdinalWord } = useOrdinalWords();
  const handleDateFormatting = useCallback((date: string) => {
    try {
      return formatDate(date);
    } catch (error) {
      return "";
    }
  }, []);

  const generateNameCell = useCallback(
    (name: string): Cell => ({
      value: name,
      ...nameCellStyle,
    }),
    []
  );

  const generateOrderIDCell = useCallback(
    ({ order_id: orderId }: ExportedTaskModel): Cell => ({
      value: orderId || "",
      ...cellStyle,
    }),
    []
  );

  const generateHeaderCell = useCallback(
    (name: string): Cell => ({
      value: name,
      ...headerCellStyle,
    }),
    []
  );

  const generateTitleCell = useCallback(
    ({ name }: ExportedTaskModel): Cell => ({
      value: name,
      ...cellStyle,
      fontWeight: "bold",
    }),
    []
  );

  const generateCreatorCell = useCallback(
    ({ created_by }: ExportedTaskModel): Cell => {
      if (!created_by) return { value: "-", ...cellStyle };

      const isOrderForm =
        created_by.user_id === "00000000-0000-0000-0000-000000000000";

      const value = isOrderForm
        ? t({
            id: "tasks-export.created-by-order-form",
            defaultMessage: "Created by Order Form",
          })
        : `${created_by.first_name} ${created_by.last_name}`;

      return { value, ...cellStyle };
    },
    []
  );

  const generateDescriptionCell = useCallback(
    ({ desc }: ExportedTaskModel): Cell => ({
      value: desc ? htmlToPlainText(stripTaskDescriptionPrefix(desc)) : "-",
      ...cellStyle,
    }),
    []
  );

  const generateDueDateCell = useCallback(
    ({ due_at: dueAt }: ExportedTaskModel): Cell => ({
      value: dueAt ? handleDateFormatting(dueAt) : "",
      ...cellStyle,
    }),
    [handleDateFormatting]
  );

  const generateAssigneesCell = useCallback(
    ({ assignees }: ExportedTaskModel): Cell => ({
      value: assignees.length ? formatUsersList(assignees) : "",
      ...cellStyle,
    }),
    []
  );

  const generateLabelCell = useCallback(
    ({ label }: ExportedTaskModel): Cell => ({
      value: label?.text || "",
      backgroundColor: label?.color,
      ...highlightedCellStyle,
      color: label?.color ? "#ffffff" : "#000000",
    }),
    []
  );

  const generateStatusCell = useCallback(
    ({ status }: ExportedTaskModel): Cell => {
      const colors: {
        [key: string]: string;
      } = {
        [TaskDefaultStatusId.pending]: "#2196F3",
        [TaskDefaultStatusId.completed]: "#B50870",
        custom: "#F33A9E",
      };

      const { text } = getStatusById(status || "");

      return {
        value: `\n${status && colors[status] ? text : status || ""}\n`,
        backgroundColor: status ? colors[status] || colors.custom : undefined,
        ...highlightedCellStyle,
      };
    },
    [getStatusById]
  );

  const generatePriorityCell = useCallback(
    ({ priority }: ExportedTaskModel): Cell => {
      const hasPriority = priority !== TaskPriority.none;
      return {
        value: hasPriority ? getPriorityDetailsById(priority).shortLabel : "",
        backgroundColor: hasPriority
          ? taskPriorityColorMap[priority]
          : undefined,
        ...highlightedCellStyle,
      };
    },
    [getPriorityDetailsById]
  );

  const generateCustomerCell = useCallback(
    ({ customer }: ExportedTaskModel): Cell => ({
      value: customer ? formatUserName(customer) : "",
      ...cellStyle,
    }),
    []
  );

  const generateTimeSpentCell = useCallback(
    ({ duration }: ExportedTaskModel): Cell => {
      const formatDuration = (value: number): string => {
        const totalMinutes = Math.floor(value / 60000);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return t(
          {
            id: "tasks-page.time-spent-on-task-duration-export",
            defaultMessage: "{hours} h  {minutes} min",
          },
          { hours, minutes }
        );
      };
      return {
        value: duration ? formatDuration(duration) : "",
        ...cellStyle,
      };
    },
    [t]
  );

  const generateRecurrenceSettingsCell = useCallback(
    ({ recurrence }: ExportedTaskModel): Cell => {
      const dayAbbreviations = [
        "",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun",
      ];

      const monthAbbreviations = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const getEndsAt = () => {
        if (!recurrence.end_at) {
          return t({
            id: "tasks-export.never-ends",
            defaultMessage: "never ends",
          });
        }

        if ("counter" in recurrence.end_at && recurrence.end_at.counter) {
          return t(
            {
              id: "tasks-export.recurring-ends-after-occurrences",
              defaultMessage:
                "ends after {counter, plural, one {occurrence} other {# occurrences}}",
            },
            { counter: recurrence.end_at.counter }
          );
        }

        if ("date" in recurrence.end_at && recurrence.end_at.date) {
          return t(
            {
              id: "tasks-export.recurring-task-end-date",
              defaultMessage: "ends at {date}",
            },
            {
              date: formatDate(
                recurrence.end_at.date,
                undefined,
                "dd MMM yyyy"
              ),
            }
          );
        }

        return "";
      };

      const getWeeklyRecurrence = () => {
        if (!("on" in recurrence.interval)) {
          return "";
        }
        const sortedDays =
          Array.isArray(recurrence.interval.on) &&
          recurrence.interval.on.length > 0
            ? [...recurrence.interval.on].sort((a, b) => a - b)
            : [];

        if (recurrence.interval.freq > 1 && "on" in recurrence.interval) {
          return `${t(
            {
              id: "tasks-export.recurring-task-repeat-weekly",
              defaultMessage: "Every {value} weeks on {days}",
            },
            {
              value: recurrence.interval.freq,
              days: `${sortedDays
                .map((day) => dayAbbreviations[day])
                .join(", ")}`,
            }
          )}, ${getEndsAt()}`;
        }
        if (recurrence.interval.freq === 1 && "on" in recurrence.interval) {
          return `${t(
            {
              id: "tasks-export.recurring-task-repeat-weekly",
              defaultMessage: "Weekly on {days}",
            },
            {
              days: `${sortedDays
                .map((day) => dayAbbreviations[day])
                .join(", ")}`,
            }
          )}, ${getEndsAt()}`;
        }
        return "";
      };

      const getMonthlyRecurrence = () => {
        if (!("on" in recurrence.interval)) {
          return "";
        }
        const day =
          "day" in recurrence.interval.on && recurrence.interval.on.day;
        const week =
          "week" in recurrence.interval.on && recurrence.interval.on.week;
        const dayWithoutWeeks =
          "date" in recurrence.interval.on && recurrence.interval.on.date;
        if (recurrence.interval.freq > 1 && "on" in recurrence.interval) {
          if (week) {
            return `${t(
              {
                id: "tasks-export.recurring-task-repeat-monthly-every-on-day-in-week",
                defaultMessage: "Every {value} months on {week} {day}",
              },
              {
                value: recurrence.interval.freq,
                week: getOrdinalWord(Number(week)),
                day: day ? dayAbbreviations[Number(day)] : "",
              }
            )}, ${getEndsAt()}`;
          }
          return `${t(
            {
              id: "tasks-export.recurring-task-repeat-monthly-every",
              defaultMessage: "Every {value} months on {day} day",
            },
            {
              value: recurrence.interval.freq,
              day: dayWithoutWeeks,
            }
          )}, ${getEndsAt()}`;
        }

        if (recurrence.interval.freq === 1) {
          if (week) {
            return `${t(
              {
                id: "tasks-export.recurring-task-repeat-monthly-on-day-in-week",
                defaultMessage: "Monthly on {week} {day}",
              },
              {
                week: getOrdinalWord(Number(week)),
                day: day ? dayAbbreviations[Number(day)] : "",
              }
            )}, ${getEndsAt()}`;
          }

          return `${t(
            {
              id: "tasks-export.recurring-task-repeat-monthly-on-day",
              defaultMessage: "Monthly on {day} day",
            },
            {
              day: dayWithoutWeeks ? getOrdinal(dayWithoutWeeks) : "",
            }
          )}, ${getEndsAt()}`;
        }
        return "";
      };

      const getYearlyRecurrence = () => {
        if (!("on" in recurrence.interval)) {
          return "";
        }
        if (recurrence.interval.freq > 1) {
          return `${t(
            {
              id: "tasks-export.recurring-task-repeat-yearly-every",
              defaultMessage: "Every {value} years on {date}",
            },
            {
              value: recurrence.interval.freq,
              date: `${
                "date" in recurrence.interval.on && recurrence.interval.on.date
              } ${
                "month" in recurrence.interval.on &&
                monthAbbreviations[recurrence.interval.on.month]
              }`,
            }
          )}, ${getEndsAt()}`;
        }
        if (recurrence.interval.freq === 1) {
          return `${t(
            {
              id: "tasks-export.recurring-task-repeat-yearly",
              defaultMessage: "Yearly on {date}",
            },
            {
              date: `${
                "date" in recurrence.interval.on && recurrence.interval.on.date
              } ${
                "month" in recurrence.interval.on &&
                monthAbbreviations[recurrence.interval.on.month]
              }`,
            }
          )}, ${getEndsAt()}`;
        }
        return "";
      };

      const getValue = () => {
        switch (recurrence.interval.every) {
          case "day":
            return `${t(
              {
                id: "tasks-export.recurring-task-repeat-every-day",
                defaultMessage:
                  "Daily every {frequency, plural, one {day} other {# days}}",
              },
              { frequency: recurrence.interval.freq }
            )}, ${getEndsAt()}`;
          case "week":
            return getWeeklyRecurrence();
          case "month":
            return getMonthlyRecurrence();
          case "year":
            return getYearlyRecurrence();
          default:
            return "";
        }
      };

      return {
        value: (() => {
          if (!recurrence) return "";
          try {
            return getValue();
          } catch (error) {
            return "";
          }
        })(),
        ...cellStyle,
      };
    },
    [getOrdinalWord, t]
  );

  const generateBoardCell = useCallback(
    ({ board }: ExportedTaskModel): Cell => {
      const hasBoardAccess = board && getBoardByName(board.name);

      return {
        value: !board
          ? ""
          : hasBoardAccess
          ? board.name
          : t({
              id: "tasks-export.board-locked",
              defaultMessage: "Board 🔒",
            }),
        backgroundColor: !board
          ? undefined
          : hasBoardAccess
          ? board.color
          : "#F33A9E",
        ...highlightedCellStyle,
      };
    },
    [getBoardByName, t]
  );

  const generateAttachmentsCell = useCallback(
    ({ attachments }: ExportedTaskModel): Cell => ({
      value: attachments.length
        ? attachments.map((attachment) => attachment).join("\n\n")
        : "",
      ...cellStyle,
      color: "#2196F3",
    }),
    []
  );

  const generateCustomFieldCell = useCallback(
    (
      { custom_fields: customFields }: ExportedTaskModel,
      customField: InternalTaskCustomField
    ): Cell => {
      let value = customFields?.[customField.id] || "";

      switch (customField.type) {
        case "date":
          if (value) {
            try {
              value = formatDate(value);
            } catch (error) {
              // DO NOTHING
            }
          }
          break;
        case "dropdown":
          value =
            customField.values?.find((el) => el.id === value)?.value || "";
          break;
        default:
          break;
      }

      return {
        value,
        ...cellStyle,
      };
    },
    []
  );

  const generateClientEmailCell = useCallback(
    ({ custom_fields: customFields }: ExportedTaskModel): Cell => ({
      value: customFields?.[TASK_ORDER_EMAIL_ID],
      ...cellStyle,
    }),
    []
  );

  const generateClientPhoneNumberCell = useCallback(
    ({ custom_fields: customFields }: ExportedTaskModel): Cell => ({
      value: customFields?.[TASK_ORDER_PHONE_ID],
      ...cellStyle,
    }),
    []
  );

  const generateChecklistNameCell = useCallback(
    ({ name, is_completed: isCompleted }: ExportedTaskChecklist): Cell => ({
      ...cellStyle,
      value: `${isCompleted ? "✓ " : ""}${parseMention(name)}`,
      color: "#5A6367",
      backgroundColor: isCompleted ? "#EBFFF5" : undefined,
    }),
    []
  );

  const generateChecklistDueDateCell = useCallback(
    ({ due_at: dueAt }: ExportedTaskChecklist): Cell => ({
      value: dueAt ? handleDateFormatting(dueAt) : "",
      ...cellStyle,
    }),
    [handleDateFormatting]
  );

  const generateChecklistAssigneeCell = useCallback(
    ({ user }: ExportedTaskChecklist): Cell => ({
      value: user ? formatUserName(user) : "",
      ...cellStyle,
    }),
    []
  );

  return {
    generateAssigneesCell,
    generateAttachmentsCell,
    generateBoardCell,
    generateCustomFieldCell,
    generateCustomerCell,
    generateTimeSpentCell,
    generateRecurrenceSettingsCell,
    generateDueDateCell,
    generateHeaderCell,
    generateLabelCell,
    generateNameCell,
    generateOrderIDCell,
    generatePriorityCell,
    generateTitleCell,
    generateStatusCell,
    generateClientEmailCell,
    generateClientPhoneNumberCell,
    generateChecklistNameCell,
    generateChecklistDueDateCell,
    generateChecklistAssigneeCell,
    generateDescriptionCell,
    generateCreatorCell,
  };
};
