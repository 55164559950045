import {
  Button,
  LoadingSpinner,
  SidebarDrawer,
  MobileDrawer,
} from "@jugl-web/ui-components";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { OrderFormServicesFieldValue } from "@jugl-web/rest-api/orders/types";
import { InventoryItem, useRestApiProvider } from "@jugl-web/rest-api";
import { useInView } from "react-intersection-observer";
import { useSearchInput, useTranslations, cx } from "@jugl-web/utils";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { usePrevious } from "react-use";
import { ReactComponent as ServiceIcon } from "./assets/service.svg";
import { ServiceDrawerItem } from "./components/ServiceDrawerItem";

export type ManageServiceDrawerRefState = {
  items: OrderFormServicesFieldValue["items"];
  itemsQty?: { [key: string]: number };
};
export const ManageServiceDrawer = React.forwardRef<
  ManageServiceDrawerRefState,
  {
    isOpen: boolean;
    entityId: string;
    currency: string;
    items: OrderFormServicesFieldValue["items"];
    onRequestClose: (
      selectedItems?: OrderFormServicesFieldValue["items"]
    ) => void;
    headerEndContent?: React.ReactNode;
    searchBarEndContent?: React.ReactNode;
    emptyInventoryContent?: React.ReactNode;
    isMobile?: boolean;
    isOrderForms?: boolean;
    invoiceId?: string;
    onSubmit?: (items: string[], itemsQty?: { [key: string]: number }) => void;
    onItemChange?: (item: InventoryItem) => void;
  }
>(
  (
    {
      isOpen,
      items,
      entityId,
      currency,
      onRequestClose,
      headerEndContent,
      searchBarEndContent,
      emptyInventoryContent,
      isMobile,
      isOrderForms,
      invoiceId,
      onSubmit,
      onItemChange,
    },
    ref
  ) => {
    const isOpenRef = useRef(isOpen);
    const [selectedServices, setSelectedServices] = useState<
      OrderFormServicesFieldValue["items"] & { qty?: number }
    >(items);
    const [itemsQty, setItemsQty] = useState<{ [key: string]: number }>();
    const { searchQuery, inputProps, reset } = useSearchInput({
      debounce: 500,
      onSearch: (value) => {
        if (!isOpenRef.current) return;
        if (value) {
          loadInventoryItemsNextPage(true, false, value);
        }
        if (value === "" || !value) {
          loadInventoryItemsNextPage(true, true, undefined);
        }
      },
    });

    const previousIsOpen = usePrevious(isOpen);
    const [isFetchingInternal, setIsFetchingInternal] = useState(false);
    const [data, setData] = useState<{
      items: InventoryItem[];
      page: number;
      hasMore: boolean;
      isInitialized: boolean;
    }>({
      items: [],
      page: 0,
      hasMore: true,
      isInitialized: false,
    });

    const { t } = useTranslations();
    const { inventoryApi } = useRestApiProvider();
    const { ref: moreInventoryItemsLoadingRef, inView } = useInView();
    const [loadMoreItems, { isLoading, isFetching, isError }] =
      inventoryApi.useLazyGetInventoryItemsListQuery();

    const loadInventoryItemsNextPage = useCallback(
      async (
        shouldReset?: boolean,
        clearSearchValue?: boolean,
        searchValue?: string
      ) => {
        if (
          !isOpen ||
          (!shouldReset && (isLoading || !data.hasMore || isFetchingInternal))
        ) {
          return;
        }
        setIsFetchingInternal(true);

        if (shouldReset) {
          setData({
            items: [],
            page: 0,
            hasMore: true,
            isInitialized: false,
          });
        }

        const response = await loadMoreItems({
          params: {
            page: shouldReset ? 1 : data.page + 1,
            page_size: 10,
            invoice_id: invoiceId,
            search: clearSearchValue
              ? undefined
              : searchValue || searchQuery || undefined,
          },
          entityId,
        });
        if (response?.data?.data) {
          const hasMore = response.data.total_pages > response.data.page_number;
          const newItemsState = () => {
            if (shouldReset) return response.data?.data || [];
            if (!response.data) return data.items;
            const uniqueItems = new Map(
              [...data.items, ...response.data.data].map((item) => [
                item.id,
                item,
              ])
            );
            return Array.from(uniqueItems.values());
          };
          setData((prev) => ({
            ...prev,
            items: newItemsState(),
            page: response.data?.page_number || 1,
            isInitialized: true,
            hasMore,
          }));
        }
        setIsFetchingInternal(false);
      },
      [
        data.hasMore,
        data.items,
        data.page,
        entityId,
        invoiceId,
        isFetchingInternal,
        isLoading,
        isOpen,
        loadMoreItems,
        searchQuery,
      ]
    );

    useEffect(() => {
      if (!isOpen) {
        setSelectedServices(items);
      }
    }, [isOpen, items]);

    useEffect(() => {
      if (inView && isOpen) {
        loadInventoryItemsNextPage();
      }
    }, [inView, isOpen, loadInventoryItemsNextPage]);

    useEffect(() => {
      if (!previousIsOpen && isOpen) {
        loadInventoryItemsNextPage();
        isOpenRef.current = isOpen;
      }
      if (previousIsOpen && !isOpen) {
        setData({
          items: [],
          page: 0,
          hasMore: true,
          isInitialized: false,
        });
        isOpenRef.current = isOpen;
      }
    }, [isOpen, loadInventoryItemsNextPage, previousIsOpen]);

    useEffect(() => {
      if (ref && "current" in ref && isOpen) {
        ref.current = {
          items: selectedServices,
          itemsQty,
        };
      }
    }, [ref, selectedServices, isOpen, itemsQty]);

    const content = useMemo(() => {
      if (isLoading) {
        return (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        );
      }

      if (isError) {
        return (
          <div className="flex h-full w-full flex-col items-center justify-center gap-4">
            <span className="text-tertiary-400 text-lg">
              {t({
                id: "order-form-wizard-page.something-went-wrong",
                defaultMessage: "Something went wrong!",
              })}
            </span>
            <Button onClick={() => loadInventoryItemsNextPage()}>
              {t({
                id: "order-form-wizard-page.click-to-try-again",
                defaultMessage: "Click to try again",
              })}
            </Button>
          </div>
        );
      }

      if (
        isOrderForms &&
        !searchQuery &&
        !isFetching &&
        data.items.length === 0
      ) {
        return (
          <span className="font-secondary mx-8 leading-[160%] text-[#828282]">
            {t({
              id: "order-form-wizard-page.no-services-created-yet",
              defaultMessage: "No Services created yet 😔",
            })}
          </span>
        );
      }

      if (
        invoiceId &&
        !searchQuery &&
        !isFetching &&
        data.items.length === 0 &&
        !data.hasMore
      ) {
        return (
          <div
            className={cx("flex flex-col gap-4 px-8", {
              "px-0": isMobile,
            })}
          >
            <div className="font-secondary text-[#828282]">
              {t({
                id: "order-form-wizard-page.all-items-were-added",
                defaultMessage: "All existing Items were added to the Order ✅",
              })}
            </div>
            {emptyInventoryContent}
          </div>
        );
      }
      return (
        <>
          <div
            className={cx("flex items-center", {
              "mx-8": !isMobile,
              "mb-4": isMobile,
            })}
          >
            <SearchInput
              containerClassName="w-full"
              variant="filled"
              color="grey"
              onClear={reset}
              {...inputProps}
            />
            {searchBarEndContent}
          </div>
          <div className="mb-1 flex flex-col">
            {data.items.length
              ? data.items.map((item, idx) => (
                  <React.Fragment key={item.id}>
                    <div
                      className={cx({
                        "mx-8": !isMobile,
                      })}
                    >
                      <ServiceDrawerItem
                        currency={currency}
                        item={item}
                        searchQuery={searchQuery}
                        isChecked={selectedServices.includes(item.id)}
                        onChange={() => {
                          if (selectedServices.includes(item.id)) {
                            setSelectedServices((prev) =>
                              prev.filter((el) => el !== item.id)
                            );
                            return;
                          }
                          setSelectedServices((prev) => [...prev, item.id]);
                          onItemChange?.(item);
                        }}
                        onQtyChange={(qty) => {
                          setItemsQty((prev) => ({
                            ...prev,
                            [item.id]: qty,
                          }));
                        }}
                        isOrderForms={isOrderForms}
                        isMobile={isMobile}
                      />
                    </div>
                    {data.items.length - 1 !== idx && (
                      <div className="bg-grey-200 my-2.5 h-px w-full" />
                    )}
                  </React.Fragment>
                ))
              : !isFetching && (
                  <span className="font-secondary mx-8 text-center leading-[160%] text-[#828282]">
                    {t({
                      id: "order-form-wizard-page.no-results",
                      defaultMessage: "No Results 😔",
                    })}
                  </span>
                )}
            {isFetching && (
              <div className="flex h-14 items-center justify-center">
                <LoadingSpinner />
              </div>
            )}
            {data.hasMore &&
              !isFetchingInternal &&
              !isLoading &&
              !isFetching && (
                <div ref={moreInventoryItemsLoadingRef} className="h-px" />
              )}
          </div>
        </>
      );
    }, [
      currency,
      data.hasMore,
      data.items,
      emptyInventoryContent,
      inputProps,
      invoiceId,
      isError,
      isFetching,
      isFetchingInternal,
      isLoading,
      isMobile,
      isOrderForms,
      loadInventoryItemsNextPage,
      moreInventoryItemsLoadingRef,
      onItemChange,
      reset,
      searchBarEndContent,
      searchQuery,
      selectedServices,
      t,
    ]);

    const handleClose = useCallback(() => {
      onRequestClose(isOrderForms ? selectedServices : items);
      reset();
    }, [isOrderForms, items, onRequestClose, reset, selectedServices]);

    const actions = useMemo(
      () =>
        !isError && (
          <>
            {selectedServices.length > 0 && (
              <Button
                className={cx({
                  "w-[250px]": !isMobile,
                  "w-full": isMobile,
                })}
                onClick={() => setSelectedServices([])}
                color="grey"
              >
                {t({ id: "common.clear", defaultMessage: "Clear" })}
              </Button>
            )}
            <Button
              className={cx({
                "w-[250px]": !isMobile,
                "w-full": isMobile,
              })}
              onClick={() => {
                handleClose();
                onSubmit?.(selectedServices, itemsQty);
              }}
              isDisabled={!selectedServices.length}
            >
              {selectedServices.length
                ? t(
                    {
                      id: "order-form-wizard-page.select-services-amount",
                      defaultMessage: "Select {servicesAmount}",
                    },
                    { servicesAmount: selectedServices.length }
                  )
                : t({
                    id: "common.select",
                    defaultMessage: "Select",
                  })}
            </Button>
          </>
        ),
      [handleClose, isError, isMobile, itemsQty, onSubmit, selectedServices, t]
    );

    if (isMobile) {
      return (
        <MobileDrawer
          header={{
            title: t({
              id: "order-form-wizard-page.select-items/services",
              defaultMessage: "Select Items / Services",
            }),
            customEndSlot: headerEndContent,
          }}
          isOpen={isOpen}
          initialFocus="none"
          size="xl"
          placement="bottom"
          hasBackdrop
          onClose={handleClose}
        >
          <MobileDrawer.Content>{content}</MobileDrawer.Content>
          <MobileDrawer.Actions>
            <div className="flex justify-center gap-4 px-4">{actions}</div>
          </MobileDrawer.Actions>
        </MobileDrawer>
      );
    }
    return (
      <SidebarDrawer
        isOpen={isOpen}
        onClose={handleClose}
        title={
          <div className="flex items-center gap-3">
            <ServiceIcon />
            <span>
              {t({
                id: "order-form-wizard-page.select-items/services",
                defaultMessage: "Select Items / Services",
              })}
            </span>
          </div>
        }
        className="w-[640px]"
      >
        <SidebarDrawer.Content className="flex flex-col gap-4 pt-6">
          {content}
        </SidebarDrawer.Content>
        <SidebarDrawer.Actions className="flex items-center justify-center gap-4">
          {actions}
        </SidebarDrawer.Actions>
      </SidebarDrawer>
    );
  }
);
