import {
  Checkbox,
  InteractiveContainer,
  Tooltip,
} from "@jugl-web/ui-components";
import { FC, ReactNode } from "react";
import { ReactComponent as HintIcon } from "./assets/hint.svg";

interface CheckboxButtonProps {
  label: ReactNode;
  hint: ReactNode;
  isChecked: boolean;
  onClick: () => void;
}

export const CheckboxButton: FC<CheckboxButtonProps> = ({
  label,
  hint,
  isChecked,
  onClick,
}) => (
  <InteractiveContainer
    className="hover:bg-grey-100 group flex h-10 w-full items-center justify-between gap-1 rounded-lg bg-transparent px-2 transition-colors"
    onClick={onClick}
  >
    <div className="flex items-center gap-2 overflow-hidden">
      <Checkbox readOnly isChecked={isChecked} className="shrink-0" />
      <span className="font-secondary text-dark-700 cursor-pointer truncate text-base">
        {label}
      </span>
    </div>
    <Tooltip
      placement="top"
      delay="none"
      renderTrigger={({ ref, props }) => (
        <HintIcon
          ref={ref}
          className="shrink-0 opacity-0 transition-opacity group-hover:opacity-100"
          {...props}
        />
      )}
      className="font-secondary text-sm leading-3"
    >
      {hint}
    </Tooltip>
  </InteractiveContainer>
);
