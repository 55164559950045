import { Button, MobileDrawer } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React from "react";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { useTaskChecklistContext } from "./TaskChecklistProvider";

export const MoveChecklistConfirmationScreen: React.FC<{
  targetTask: PreviewTask;
  onClose: () => void;
}> = ({ targetTask, onClose }) => {
  const { t } = useTranslations();
  const { selectedChecklistIds, onMoveChecklist, isMoveChecklistItemsLoading } =
    useTaskChecklistContext();

  return (
    <>
      <MobileDrawer.Content className="mt-0 py-0">
        <div className="font-secondary text-sm leading-[21px] text-[#4F4F4F]">
          {t(
            {
              id: "tasks-page.move-subtasks-confirmation",
              defaultMessage:
                "Do you really want to move {count} {count, plural, one {subtask} other {subtasks}} to the Task {taskName}?",
            },
            {
              count: selectedChecklistIds.length,
              taskName: (
                <span className="text-primary-800 font-medium">
                  {targetTask.name}
                </span>
              ),
            }
          )}
        </div>
      </MobileDrawer.Content>
      <MobileDrawer.Actions className="mt-12 flex flex-row items-center gap-4 pt-0">
        <Button onClick={onClose} color="grey" fullWidth>
          {t({ id: "common.cancel", defaultMessage: "Cancel" })}
        </Button>
        <Button
          onClick={async () => {
            const isChecklistMovedSuccesfully = await onMoveChecklist(
              targetTask.id
            );
            if (isChecklistMovedSuccesfully) {
              onClose();
            }
          }}
          fullWidth
          isDisabled={isMoveChecklistItemsLoading}
        >
          {t({ id: "tasks-page.move", defaultMessage: "Move" })}
        </Button>
      </MobileDrawer.Actions>
    </>
  );
};
