import { PlainButton, Tooltip } from "@jugl-web/ui-components";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import {
  ListBoxItemComponent,
  ListBoxItemComponentProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { FC, useMemo, useState, useRef } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { Transition } from "@headlessui/react";
import { Highlightify } from "@jugl-web/utils/utils/Highlightify";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useUserGeneralProfile } from "../../hooks/useUserGeneralProfile";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { useUserRolePermissionWarning } from "../../hooks/useUserRolePermissionWarning";
import { AppModule } from "../../../common/types";
import { hasRoleAccessToModule } from "../../utils/hasRoleAccessToModule";
import { ReactComponent as ChevronDownIcon } from "./assets/chevron-down.svg";

interface UserListBoxItemProps extends ListBoxItemComponentProps {
  entityId: string;
  userId: string;
  module?: AppModule;
  onExpandClick: () => void;
}

export const UserListBoxItem: FC<UserListBoxItemProps> = ({
  entityId,
  userId,
  module,
  onExpandClick,
  ...listBoxItemProps
}) => {
  const { t } = useTranslations();
  const { usersApi } = useRestApiProvider();
  const { profile, safeProfile } = useUserGeneralProfile({ entityId, userId });
  const { data: userCustomFields } = usersApi.useGetUserCustomFieldsQuery({
    entityId,
  });

  const elementRef = useRef<HTMLDivElement>(null);
  const bookmarkedAttributes = useMemo(
    () =>
      userCustomFields?.value
        .filter((el) => el.bookmark)
        .sort((a, b) => a.bookmark - b.bookmark),
    [userCustomFields]
  );

  const hasBookmarkedAttributes = useMemo(() => {
    const bookmarkedIds = bookmarkedAttributes?.map((el) => el.id);
    const fieldValues = profile?._user.work_info.filter((el) =>
      bookmarkedIds?.includes(el.key)
    );
    return fieldValues?.some((el) => el.value);
  }, [bookmarkedAttributes, profile?._user.work_info]);

  const shouldHighlightExpandButton = useMemo(() => {
    if (
      !bookmarkedAttributes ||
      !listBoxItemProps ||
      !listBoxItemProps.highlightedText
    ) {
      return false;
    }
    const { highlightedText } = listBoxItemProps;
    const bookmarkedIds = bookmarkedAttributes.map((el) => el.id);
    const fieldValues = profile?._user.work_info.filter((el) =>
      bookmarkedIds.includes(el.key)
    );

    return fieldValues?.some((el) =>
      el.value?.toLowerCase().includes(highlightedText.toLowerCase())
    );
  }, [bookmarkedAttributes, listBoxItemProps, profile?._user.work_info]);

  const getUserRolePermissionWarning = useUserRolePermissionWarning();
  const [isExpanded, setIsExpanded] = useState(false);

  const isDisabled = useMemo(() => {
    if (!profile || listBoxItemProps.isSelected || !module) {
      return false;
    }

    return !hasRoleAccessToModule(profile.role, module);
  }, [listBoxItemProps.isSelected, profile, module]);

  const bookmarkedElements = useMemo(
    () =>
      bookmarkedAttributes?.map((field) => {
        const fieldValue = profile?._user.work_info.find(
          (el) => el.key === field.id
        );
        const isPhoneType = field.type === "phone";
        const isMailType = field.type === "email";
        let valueToShow = fieldValue?.value || "";
        if (isPhoneType && fieldValue?.value) {
          const [, code, number] = fieldValue.value.split(",");
          valueToShow = `(${code?.replace("+", "")}) ${number}`;
        }
        if (!valueToShow) {
          return null;
        }
        return (
          <div key={field.id} className="flex flex-col gap-0.5 py-[14px]">
            <div className="truncate text-[13px] text-[#828282]">
              {field.name}
            </div>
            <div>
              <div
                className="text-dark truncate text-sm font-medium"
                title={valueToShow || "-"}
              >
                <Highlightify
                  searchWord={listBoxItemProps.highlightedText || ""}
                  highlightClassName="text-primary"
                >
                  {(!isPhoneType && !isMailType && valueToShow) || null}
                  {valueToShow && isPhoneType && (
                    <a href={`tel:${valueToShow}`} className="text-dark">
                      {valueToShow}
                    </a>
                  )}
                  {valueToShow && isMailType && (
                    <a href={`mailto:${valueToShow}`} className="text-dark">
                      {valueToShow}
                    </a>
                  )}
                </Highlightify>
              </div>
            </div>
          </div>
        );
      }),
    [
      bookmarkedAttributes,
      listBoxItemProps.highlightedText,
      profile?._user.work_info,
    ]
  );

  return (
    <div className="overflow-clip">
      <ListBoxItemComponent
        {...listBoxItemProps}
        label={safeProfile.displayName}
        className={cx(
          "font-secondary relative z-10 rounded-xl pl-1 transition-all duration-300",
          {
            "bg-grey-100": !listBoxItemProps.isSelected && isExpanded,
            "rounded-bl-none rounded-br-none": isExpanded,
          }
        )}
        height={62}
        startSlot={
          <>
            <div className="relative z-10 flex items-center">
              <PlainButton
                className={cx(
                  "hover:bg-primary/10 pointer-events-auto rounded-full",
                  {
                    "bg-primary/10": shouldHighlightExpandButton && !isExpanded,
                  }
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isExpanded) {
                    onExpandClick();
                  }
                  setIsExpanded((prev) => !prev);
                }}
              >
                <ChevronDownIcon
                  className={cx("hover:fill-primary fill-[#BDBDBD]", {
                    "fill-primary": shouldHighlightExpandButton,
                    "rotate-180 transition-all": isExpanded,
                  })}
                />
              </PlainButton>

              <Avatar
                className="h-[40px] w-[40px] border border-solid border-white"
                size="md"
                imageUrl={safeProfile.avatar}
                username={safeProfile.displayName}
              />
            </div>
          </>
        }
        secondaryText={
          profile?.department?.name ||
          t({
            id: "common.no-department",
            defaultMessage: "No department",
          })
        }
        isDisabled={isDisabled}
        endSlot={
          isDisabled ? (
            <Tooltip
              renderTrigger={({ props, ref }) => (
                <div className="h-5 w-5">
                  <InfoIcon ref={ref} {...props} />
                </div>
              )}
            >
              {getUserRolePermissionWarning(module)}
            </Tooltip>
          ) : undefined
        }
      />
      <Transition
        show={isExpanded}
        enter="transition ease duration-500 transform"
        enterFrom="opacity-0 -translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-200 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-full"
      >
        <div
          ref={elementRef}
          className={cx(
            "font-secondary overflow-hidden rounded-b-xl px-4",

            {
              "bg-grey-100": !listBoxItemProps.isSelected,
              "bg-primary-50": listBoxItemProps.isSelected,
              "rounded-tl-none rounded-tr-none": isExpanded,
            }
          )}
        >
          <div className="h-[1px] w-full bg-[#EAECEE]" />
          {hasBookmarkedAttributes ? (
            bookmarkedElements
          ) : (
            <div className="pt-[14px] pb-2 text-[13px] text-[#828282]">
              {t({
                id: "common.no-info-added",
                defaultMessage: "No info added",
              })}
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};
