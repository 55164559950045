import {
  RenderTriggerProps,
  ListBoxItem,
  Checkbox,
} from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { DiscountItemDataDto } from "@jugl-web/rest-api/inventory/models/DiscountItem";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as DiscountIcon } from "./assets/discount.svg";

type PickerProps = {
  value: string;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
  onSubmit?: (ids: string[], setOnAll: boolean) => void;
};
export const DiscountPicker = ({
  value,
  onSubmit,
  renderTrigger,
}: PickerProps) => {
  const { inventoryApi } = useRestApiProvider();
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const [isDiscountSelected, setIsDiscountSelected] = useState(false);

  const [listState, setListState] = useState<{
    items: DiscountItemDataDto[];
    page: number;
    hasMore: boolean;
    isInitialized: boolean;
  }>({
    items: [],
    page: 0,
    hasMore: true,
    isInitialized: false,
  });

  const [isOpen, setIsOpen] = useState(false);

  const [loadDiscounts] = inventoryApi.useLazyGetDiscountItemsListQuery();
  const [isApplyOnAllItemsChecked, setIsApplyOnAllItemsChecked] =
    useState(false);

  const handleLoadList = useCallback(
    async (searchQuery?: string, reset?: boolean) => {
      if (!listState.hasMore && !reset) {
        return;
      }
      const response = await loadDiscounts({
        entityId: entity.id,
        params: {
          page: reset ? 1 : listState.page + 1,
          page_size: 10,
          search: searchQuery || undefined,
          status: "active",
        },
      });

      if ("data" in response && response.data?.data) {
        const hasMore = response.data?.total_pages > response.data.page_number;
        const items = () => {
          if (!response.data) return reset ? [] : listState.items;
          if (reset) {
            return [...response.data.data];
          }
          const uniqueItems = new Map(
            [...listState.items, ...response.data.data].map((item) => [
              item.id,
              item,
            ])
          );
          return Array.from(uniqueItems.values());
        };
        setListState((prevState) => ({
          ...prevState,
          items: items(),
          page: reset ? 1 : prevState.page + 1,
          hasMore,
          isInitialized: true,
        }));
      }
    },
    [
      entity.id,
      listState.hasMore,
      listState.items,
      listState.page,
      loadDiscounts,
    ]
  );

  const discountListItems = useMemo<ListBoxItem<DiscountItemDataDto>[]>(
    () =>
      listState.items?.map((discount) => ({
        id: discount.id,
        value: discount,
      })) || [],
    [listState.items]
  );

  useEffect(() => {
    if (isOpen && !listState.isInitialized) {
      handleLoadList();
    }
  }, [handleLoadList, isOpen, listState.isInitialized]);

  return (
    <>
      <ResourcePickerPopover
        onUnmount={() => {
          setIsApplyOnAllItemsChecked(false);
          setListState({
            items: [],
            page: 0,
            hasMore: true,
            isInitialized: false,
          });
        }}
        items={discountListItems}
        selectionBehavior={{ mode: "single", canToggle: true }}
        loading={!listState.isInitialized ? "skeleton" : undefined}
        defaultSelectedIds={value ? [value] : undefined}
        onSelect={({ isSelected }) => {
          setIsDiscountSelected(isSelected);
          setIsApplyOnAllItemsChecked(false);
        }}
        hasSearch
        onSearch={(searchQuery) => handleLoadList(searchQuery, true)}
        maxVisibleItems={4}
        itemSize="xl"
        renderStartIcon={() => <DiscountIcon />}
        renderSecondaryText={(item) => (
          <span className="text-dark-700">{item.value.discount} %</span>
        )}
        spaceBetweenItems="compact"
        renderLabel={(item) => item.value.name}
        onReachEnd={handleLoadList}
        placement="bottom"
        className="z-[2000] w-[340px]"
        renderTrigger={(props) => {
          if (props.isOpen) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
          return renderTrigger(props);
        }}
        onSubmit={(ids) => {
          onSubmit?.(ids, isApplyOnAllItemsChecked);
        }}
        customFooterElement={
          <div className="flex gap-1.5 px-3 pt-3 pb-5">
            <Checkbox
              label={t({
                id: "order-form-wizard-page.add-discount.apply-to-all-items",
                defaultMessage: "Apply to all items",
              })}
              labelClassName="font-secondary text-dark-600 text-sm"
              onChange={() =>
                setIsApplyOnAllItemsChecked(!isApplyOnAllItemsChecked)
              }
              isDisabled={!isDiscountSelected}
              isChecked={isApplyOnAllItemsChecked}
            />
          </div>
        }
        customButtonLabel={t({
          id: "common.apply",
          defaultMessage: "Apply",
        })}
      />
    </>
  );
};
