import React, { useContext, useState, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { Button } from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { ChatListPicker } from "@web-src/components/ChatListPicker";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { ChatItem } from "@web-src/modules/chats/types";
import { ChatMessageType, ChatType } from "@jugl-web/rest-api";
import { useSendMessage } from "@web-src/modules/chats/hooks/useSendMessage";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { CallsContext } from "../../../../../../providers";

export const ShareCallToChatSidebar: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onGoBack?: () => void;
}> = ({ isOpen, onClose, onGoBack }) => {
  const { t } = useTranslations();
  const [search, setSearch] = useState("");
  const { activeCallProps } = useContext(CallsContext);
  const [selectedChats, setSelectedChats] = useState<ChatItem[]>([]);
  const { conversations } = useConversations();
  const sendMessage = useSendMessage();
  const { entity } = useEntity();
  const meId = useSelector(selectUserId);

  const visibleChats = useMemo(
    () =>
      conversations.filter(
        (item) =>
          item.data.title?.toLowerCase().includes(search.toLocaleLowerCase()) &&
          !item.data.deleted &&
          (item.data.type === ChatType.chat ||
            item.data.users?.includes(meId || ""))
      ),
    [conversations, search, meId]
  );

  const chatsToDisplay = useMemo(
    () => visibleChats.map((el) => el.data),
    [visibleChats]
  );

  const handleShare = async () => {
    try {
      selectedChats.forEach((forwardChat) => {
        if (!forwardChat.id || !entity?.id) {
          return;
        }
        sendMessage?.({
          to: forwardChat.id,
          type:
            forwardChat.type === ChatType.muc
              ? ChatMessageType.muc
              : ChatMessageType.chat,
          extraPayload: { conference: activeCallProps?.conferenceMessage },
        });
      });
      onClose();
    } catch (error) {
      // TODO: handle error
    }
  };

  return (
    <SidebarDrawer
      className="width-[480px]"
      title={t({
        id: "call-conference.share-call-to-chat-title",
        defaultMessage: "Choose chat to share",
      })}
      onClose={onClose}
      isOpen={isOpen}
      onGoBack={onGoBack}
    >
      <SidebarDrawer.Content>
        <div className="mt-6 mb-2 px-10">
          <SearchInput
            variant="blank"
            className="bg-grey-100 my-2"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
        <ChatListPicker
          chats={chatsToDisplay}
          value={selectedChats}
          onSelect={setSelectedChats}
          shouldDisableBroadcastChat
        />
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button color="primary" fullWidth uppercase onClick={handleShare}>
          {t({
            id: "common.share",
            defaultMessage: "Share",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};
