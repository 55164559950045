import { AvatarSelect, AvatarSelectProps } from "@jugl-web/ui-components/web";
import {
  InteractiveContainer,
  Menu,
  MenuItem,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { ReactNode } from "react";
import { cx } from "@jugl-web/utils";
import { ReactComponent as OptionsVerticalIcon } from "./assets/options-vertical.svg";

export type ResourceMainSettingsItemProps = {
  avatarSelectProps: AvatarSelectProps;
  title: ReactNode;
  details: {
    title: React.ReactNode;
    content: React.ReactNode;
    icon?: React.ReactNode;
    onClick?: () => void;
  }[];
  menuItems: MenuItem[];
};

export const ResourceMainSettingsItem: React.FC<
  ResourceMainSettingsItemProps
> = ({ avatarSelectProps, title, details, menuItems }) => (
  <div className="flex items-center justify-between gap-5 rounded-xl bg-[#F7F8F9] p-6">
    <div className="flex items-center gap-14 truncate">
      <AvatarSelect {...avatarSelectProps} />
      <div className="flex flex-col truncate">
        <span className="font-secondary px-1 py-2 text-[26px] font-medium leading-[39px]">
          {title}
        </span>
        <div className="flex flex-col gap-1">
          {details.map((item, idx) => (
            <InteractiveContainer
              key={+idx}
              className={cx(
                "bg-grey-200 font-secondary text-dark-700 flex w-min items-center gap-2 rounded-lg py-1 px-2 text-sm leading-[21px] transition-colors",
                item.onClick && "hover:bg-dark-100"
              )}
              onClick={item.onClick}
              isDisabled={!item.onClick}
            >
              <span>{item.title}</span>
              <span className="text-dark-800">{item.content}</span>
              {item.icon}
            </InteractiveContainer>
          ))}
        </div>
      </div>
    </div>
    <Menu
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          as={PlainButton}
          ref={triggerRef}
          className="flex h-12 w-12 shrink-0 items-center justify-center rounded-[10px] transition-colors hover:bg-[#EEF2F5]"
        >
          <OptionsVerticalIcon />
        </Trigger>
      )}
      onSelect={(el, event, close) => close()}
      className="py-2"
      sections={[menuItems]}
    />
  </div>
);
