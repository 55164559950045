import {
  FILTER_SET_NAME_CHAR_LIMIT,
  TASK_TEXT_FIELD_CHAR_LIMIT,
  TITLE_MAX_LENGTH,
  TITLE_MIN_LENGTH,
} from "./consts";

export const taskNameValidator = (name: string) =>
  name.trim().length >= TITLE_MIN_LENGTH &&
  name.trim().length <= TITLE_MAX_LENGTH;

export const taskLabelValidator = (text: string) =>
  text.trim().length > 0 && text.trim().length <= TASK_TEXT_FIELD_CHAR_LIMIT;

export const taskChecklistNameValidator = (name: string) =>
  name.trim().length > 0;

export const taskCustomFieldNameValidator = (name: string) =>
  name.trim().length > 0;

export const taskCustomStatusValidator = (status: string) =>
  status.trim().length > 0;

export const filterSetNameValidator = (name: string) =>
  name.trim().length > 0 && name.trim().length <= FILTER_SET_NAME_CHAR_LIMIT;
