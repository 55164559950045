import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Text, LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import useDebounce from "@web-src/hooks/useDebounce";
import {
  useTranslations,
  PaginationComponent,
  PaginationItem,
  cx,
} from "@jugl-web/utils";
import { useLazyMessageSearchQuery } from "@web-src/modules/chats/chatsApi";
import { SearchChatMessage } from "@web-src/modules/chats/types";
import { ChatSideBar } from "@web-src/modules/chats/components/ChatSideBar";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { MessageCard } from "./components/MessageCard";
import { useChatsPageWithSelectedChatProvider } from "../../providers/ChatsPageProvider";

export const ChatMessageSearchSidebar: React.FC = () => {
  const { entityId } = useEntitySelectedProvider();
  const [searchQuery, setSearchQuery] = useState("");
  const [messages, setMessages] =
    useState<PaginationItem<SearchChatMessage>[]>();
  const [switchedChats, setSwitchedChats] = useState(false);
  const { t } = useTranslations();
  const { activeChatId, activeChatState, setSearchedMessage, closeSideBar } =
    useChatsPageWithSelectedChatProvider();
  const searchedMessage = activeChatState?.searchedMessage;

  const debouncedSearchQuery = useDebounce(searchQuery);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setSwitchedChats(false);
  };
  const isQueryValid = useMemo(
    () =>
      debouncedSearchQuery.trim() !== "" && debouncedSearchQuery.length >= 3,
    [debouncedSearchQuery]
  );
  const [
    loadMessages,
    { isLoading, isFetching: isSearchFetching, data: searchResults },
  ] = useLazyMessageSearchQuery();
  const parseResponseData = (data: SearchChatMessage[]) =>
    data.map((item) => ({
      data: item,
      id: item.msg_id,
    }));
  useEffect(() => {
    if (debouncedSearchQuery && isQueryValid) {
      const handleMessages = async () => {
        const response = await loadMessages({
          params: {
            term: debouncedSearchQuery,
            chat_id: activeChatId,
            entity_id: entityId || "",
          },
        });
        if ("data" in response) {
          const parsedMessages = parseResponseData(response.data?.data || []);
          setMessages(parsedMessages);
        }
      };
      handleMessages();
    }
    setSearchedMessage(activeChatId, null);
  }, [
    activeChatId,
    debouncedSearchQuery,
    isQueryValid,
    loadMessages,
    entityId,
    setSearchedMessage,
  ]);

  const handleClose = () => {
    closeSideBar(activeChatId);
    setSearchedMessage(activeChatId, null);
    setSearchQuery("");
    setMessages(undefined);
  };
  useEffect(() => {
    setSearchQuery("");
    setMessages(undefined);
    setSwitchedChats(true);
  }, [activeChatId]);
  const handleMessageSelect = useCallback(
    (id: string, createdAt: string) => {
      setSearchedMessage(activeChatId, {
        id,
        createdAt,
      });
    },
    [activeChatId, setSearchedMessage]
  );
  const handleOnReachEnd = async () => {
    if (
      !isQueryValid ||
      !messages ||
      searchResults?.total_entries === searchResults?.current_entries ||
      isSearchFetching
    ) {
      return;
    }
    const response = await loadMessages({
      params: {
        term: debouncedSearchQuery,
        chat_id: activeChatId,
        entity_id: entityId,
        start_from: searchResults?.current_entries,
      },
    });
    if (
      "data" in response &&
      messages &&
      messages?.at(-1)?.data.msg_id !== response.data?.data[0]?.msg_id
    ) {
      const parsedResponse = parseResponseData(response.data?.data || []);
      setMessages([...messages, ...parsedResponse]);
    }
  };
  return (
    <ChatSideBar
      title={t({
        id: "chats-page.message-search-title",
        defaultMessage: "Search In Chat",
      })}
      onRequestClose={handleClose}
      header={
        <>
          <div className="px-8 py-8">
            <SearchInput
              autoFocus
              variant="filled"
              color="primary"
              onChange={handleSearchChange}
              value={searchQuery}
              onClear={() => {
                setSearchQuery("");
                setMessages(undefined);
              }}
            />
          </div>
          {!switchedChats && isQueryValid && (
            <div
              className={cx("mb-3 px-8", {
                hidden: isSearchFetching || isLoading || searchQuery === "",
              })}
            >
              <Text variant="body2" className="text-dark-800 font-medium">
                {t({
                  id: "chats-page.message-search-results",
                  defaultMessage: "Search results",
                })}
                ({searchResults?.total_entries || 0})
              </Text>
            </div>
          )}
        </>
      }
    >
      {!switchedChats && isQueryValid && (
        <PaginationComponent
          items={messages || []}
          isLoading={isLoading || isSearchFetching}
          endReached={handleOnReachEnd}
          renderer={(idx, item: PaginationItem<SearchChatMessage>) => (
            <MessageCard
              key={item.data.msg_id}
              onClick={handleMessageSelect}
              searchQuery={debouncedSearchQuery}
              isSelected={searchedMessage?.id === item.data.msg_id}
              {...item.data}
            />
          )}
        />
      )}

      {isSearchFetching && (
        <div className="flex w-full items-center justify-center">
          <LoadingSpinner size="md" color="primary" />
        </div>
      )}
    </ChatSideBar>
  );
};
