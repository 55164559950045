import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";
import { HookOutOfContextError } from "@jugl-web/utils";
import { ChatMessageModel } from "@jugl-web/rest-api";
import { ChatMessageBubbleAction } from "@web-src/modules/chats/components/ChatMessage/types/ChatMessageBubbleAction";
import { useActiveChat } from "@web-src/modules/chats/hooks/useActiveChat";

type SideBarType = "chatInfo" | "search";

type MessageSideBarType = "reactions" | "messageInfo";

type SearchedMessage = {
  id: string;
  createdAt: string;
};

type ChatState = {
  openedSideBar?: {
    type: SideBarType;
  };
  openedMessageSideBar?: {
    type: MessageSideBarType;
    message: ChatMessageModel;
  };
  messageAction?: {
    type: ChatMessageBubbleAction;
    isMulti: boolean;
    messages: ChatMessageModel[];
  };
  searchedMessage?: SearchedMessage;
};

type ChatsPageContextValue = {
  activeChatId: string | null;
  activeChatState: ChatState | null;

  openSideBar: (chatId: string, type: SideBarType) => void;
  closeSideBar: (chatId: string) => void;

  openMessageSideBar: (
    type: MessageSideBarType,
    message: ChatMessageModel,
    chatId?: string
  ) => void;
  closeMessageSideBar: (chatId?: string) => void;

  setMessageAction: (
    chatId: string,
    message: ChatMessageModel,
    action: ChatMessageBubbleAction
  ) => void;
  resetMessageAction: (chatId: string) => void;
  changeMessageSelectState: (
    chatId: string,
    message: ChatMessageModel,
    isSelected: boolean
  ) => void;
  setSearchedMessage: (chatId: string, message: SearchedMessage | null) => void;
};

interface ChatsPageChatSelectedContextValue extends ChatsPageContextValue {
  activeChatId: string;
}

const ChatsPageContext = createContext<ChatsPageContextValue | null>(null);

export const ChatsPageProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { chat: activeChat } = useActiveChat();
  const activeChatId = activeChat?.id || null;
  const [chatsState, setChatsState] = useState<Record<string, ChatState>>({});

  const openMessageSideBar: ChatsPageContextValue["openMessageSideBar"] =
    useCallback(
      (type, message, chatId) => {
        if (!chatId && !activeChatId) {
          // eslint-disable-next-line no-console
          console.warn("Trying to open sidebar without chatId");
          return;
        }
        const chatIdToUse = (chatId || activeChatId) as string;
        setChatsState((prev) => ({
          ...prev,
          [chatIdToUse]: {
            ...(prev[chatIdToUse] || {}),
            openedMessageSideBar: { type, message },
          },
        }));
      },
      [activeChatId]
    );

  const closeMessageSideBar = useCallback(
    (chatId?: string) => {
      if (!chatId && !activeChatId) {
        // eslint-disable-next-line no-console
        console.error("Trying to close sidebar without chatId");
        return;
      }
      const chatIdToUse = (chatId || activeChatId) as string;
      setChatsState((prev) => ({
        ...prev,
        [chatIdToUse]: {
          ...(prev[chatIdToUse] || {}),
          openedMessageSideBar: undefined,
        },
      }));
    },
    [activeChatId]
  );

  const setMessageAction = useCallback(
    (
      chatId: string,
      message: ChatMessageModel,
      action: ChatMessageBubbleAction
    ) => {
      setChatsState((prev) => ({
        ...prev,
        [chatId]: {
          ...(prev[chatId] || {}),
          messageAction: {
            type: action,
            chatId,
            isMulti: [
              ChatMessageBubbleAction.DELETE,
              ChatMessageBubbleAction.FORWARD,
            ].includes(action),
            messages: [message],
          },
        },
      }));
    },
    []
  );

  const resetMessageAction = useCallback((chatId: string) => {
    setChatsState((prev) => ({
      ...prev,
      [chatId]: {
        ...(prev[chatId] || {}),
        messageAction: undefined,
        multiMessageAction: undefined,
      },
    }));
  }, []);

  const changeMessageSelectState = useCallback(
    (chatId: string, message: ChatMessageModel, isSelected: boolean) => {
      setChatsState((prev) => {
        const chatState = prev[chatId];
        if (!chatState) {
          return prev;
        }
        const { messageAction } = chatState;
        if (!messageAction?.isMulti) {
          return prev;
        }
        const { messages } = messageAction;
        let newMessages: ChatMessageModel[] = [];
        if (
          isSelected &&
          !messages.find((item) => item.msg_id === message.msg_id)
        ) {
          newMessages = [...messages, message];
        } else {
          newMessages = messages.filter(
            (item) => item.msg_id !== message.msg_id
          );
        }

        return {
          ...prev,
          [chatId]: {
            ...chatState,
            messageAction:
              newMessages.length === 0
                ? undefined
                : {
                    ...messageAction,
                    messages: newMessages,
                  },
          },
        };
      });
    },
    []
  );

  const openSideBar: ChatsPageContextValue["openSideBar"] = useCallback(
    (chatId, sidebarType) => {
      setChatsState((prev) => ({
        ...prev,
        [chatId]: {
          ...(prev[chatId] || {}),
          openedSideBar: { type: sidebarType },
        },
      }));
    },
    []
  );

  const closeSideBar = useCallback((chatId: string) => {
    setChatsState((prev) => ({
      ...prev,
      [chatId]: {
        ...(prev[chatId] || {}),
        openedSideBar: undefined,
      },
    }));
  }, []);

  const setSearchedMessage = useCallback(
    (chatId: string, message: SearchedMessage | null) => {
      setChatsState((prev) => ({
        ...prev,
        [chatId]: {
          ...(prev[chatId] || {}),
          searchedMessage: message || undefined,
        },
      }));
    },
    []
  );

  const value: ChatsPageContextValue = useMemo(
    () => ({
      activeChatId,
      activeChatState: activeChatId ? chatsState[activeChatId] || null : null,
      openMessageSideBar,
      closeMessageSideBar,
      setMessageAction,
      resetMessageAction,
      changeMessageSelectState,
      openSideBar,
      closeSideBar,
      setSearchedMessage,
    }),
    [
      activeChatId,
      chatsState,
      closeMessageSideBar,
      openMessageSideBar,
      setMessageAction,
      resetMessageAction,
      changeMessageSelectState,
      openSideBar,
      closeSideBar,
      setSearchedMessage,
    ]
  );

  return (
    <ChatsPageContext.Provider value={value}>
      {children}
    </ChatsPageContext.Provider>
  );
};

export const useChatsPageProvider = () => {
  const context = useContext(ChatsPageContext);

  if (!context) {
    throw new HookOutOfContextError("useChatsPageProvider", "ChatsPageContext");
  }

  return context;
};

export const useChatsPageWithSelectedChatProvider = () => {
  const context = useChatsPageProvider();
  if (!context.activeChatId || context.activeChatId === null) {
    throw new Error("No active chat");
  }
  return context as ChatsPageChatSelectedContextValue;
};
