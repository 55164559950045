import { FC } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { PeopleInfoSidebar } from "@web-src/modules/people/components/PeopleInfoSidebar";
import { InteractiveContainer } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { CPanelPageProvider, useCPanelPageContext } from "./CPanelPageProvider";
import { CPanelTable } from "./components/CPanelTable";
import { PageControlBar } from "./components/PageControlBar";
import { UserDocumentsSidebar } from "./components/UserDocumentsSidebar";
import { UserActivitySideBar } from "./components/UserActivitySideBar";
import { UserInvitationSidebar } from "./components/UserInvitationSidebar";
import { InvitationLinkAlert } from "./components/InvitationLinkAlert";
import { SettingsTabsLayout } from "../../components/SettingsTabsLayout/SettingsTabsLayout";
import { ReactComponent as PenIcon } from "./assets/pen.svg";
import { ManageProfileAttributesDialog } from "./components/ManageProfileAttributesDialog";

const CPanelPageContent: FC = () => {
  const { openedSideBar, openedAlert, setOpenedSideBar, setOpenedAlert } =
    useCPanelPageContext();
  const { subscriptionInfo } = useEntitySelectedProvider();
  const { isOnboardingActive } = useOnboarding();
  const { t } = useTranslations();
  const isSubscriptionExpired = subscriptionInfo?.planIsActive === false;
  return (
    <SettingsTabsLayout
      selectedTab="members"
      rightContent={<PageControlBar />}
      headerRightContent={
        <InteractiveContainer
          className="bg-grey-100 hover:bg-grey-200 flex h-10 items-center justify-center gap-2 rounded-lg px-2.5 transition-colors"
          onClick={() => setOpenedSideBar({ sideBar: "profileAttributes" })}
        >
          <PenIcon />
          <span className="text-dark-800 text-sm leading-[21px]">
            {t({
              id: "cpanel-page.profile-attributes",
              defaultMessage: "Profile Attributes",
            })}
          </span>
        </InteractiveContainer>
      }
      isSubscriptionExpired={isSubscriptionExpired}
    >
      <div className="flex h-full flex-col">
        <CPanelTable />
        {isOnboardingActive && <OnboardingProgressBar />}
      </div>
      <InvitationLinkAlert
        isOpen={openedAlert?.alert === "copyInvitation"}
        onRequestClose={() => setOpenedAlert(null)}
      />
      <UserDocumentsSidebar
        isOpen={openedSideBar?.sideBar === "documents"}
        user={openedSideBar?.user}
        onRequestClose={() => setOpenedSideBar(null)}
      />
      <UserActivitySideBar
        isOpen={openedSideBar?.sideBar === "loginActivity"}
        user={openedSideBar?.user}
        onRequestClose={() => setOpenedSideBar(null)}
      />
      <UserInvitationSidebar
        isOpen={openedSideBar?.sideBar === "invite"}
        user={openedSideBar?.user}
        onRequestClose={() => setOpenedSideBar(null)}
      />
      <ManageProfileAttributesDialog
        isOpen={openedSideBar?.sideBar === "profileAttributes"}
        onClose={() => setOpenedSideBar(null)}
      />
      <PeopleInfoSidebar
        isOpen={openedSideBar?.sideBar === "userProfile"}
        userId={openedSideBar?.user?.id}
        onRequestClose={() => setOpenedSideBar(null)}
      />
    </SettingsTabsLayout>
  );
};

export const CPanelPage: FC = () => (
  <CPanelPageProvider>
    <CPanelPageContent />
  </CPanelPageProvider>
);
