import React, { useCallback, useMemo, useState } from "react";
import { useToast, useTranslations } from "@jugl-web/utils";
import { PlainButton, Menu } from "@jugl-web/ui-components/cross-platform";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { ChatItem } from "@web-src/modules/chats/types";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import { useConversations } from "@web-src/modules/chats/hooks/useConversations";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { ChatSettingsDrawer } from "@web-src/modules/chats/components/ChatSettingsDrawer";
import { ChatAdminsDrawer } from "@web-src/modules/chats/components/ChatAdminsDrawer";
import { ReactComponent as ThreeDotsVertical } from "./assets/three-dots-vertical.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as NotificationsMuteAllExceptMentionsIcon } from "./assets/notifications_mute_all_except_mentions.svg";
import { ReactComponent as NotificationsMuteAllIcon } from "./assets/notifications_mute_all.svg";
import { ReactComponent as NotificationsMuteNoneIcon } from "./assets/notifications_mute_none.svg";
import { ReactComponent as EditGroupIcon } from "./assets/edit-group.svg";
import { LeaveGroupConfirmationAlert } from "./component/LeaveGroupConfirmationAlert";
import { LeaveGroupSingleAdminAlert } from "./component/LeaveGroupSingleAdminAlert";

export const GroupChatMoreMenu: React.FC<{ chat: ChatItem }> = ({ chat }) => {
  const { t } = useTranslations();
  const { getChatNotificationsPreferences, setChatNotificationsPreferences } =
    useNotifications();
  const chatNotificationPreferences = useMemo(
    () => chat?.id && getChatNotificationsPreferences(chat?.id),
    [chat?.id, getChatNotificationsPreferences]
  );
  const { entity } = useEntity();
  const { toast } = useToast({ variant: "web" });
  const meId = useSelector(selectUserId);
  const { workspacesApi } = useRestApiProvider();
  const [deleteWorkspaceParticipant, { isLoading }] =
    workspacesApi.useDeleteWorkspaceParticipantMutation();
  const { deleteGroupConversation } = useConversations();
  const leaveGroupConfirmationAlertState = useConfirmationDialogState<{
    type: "leave" | "delete";
    name: string;
  }>();
  const [
    isLeaveGroupSingleAdminAlertOpen,
    setIsLeaveGroupSingleAdminAlertOpen,
  ] = useState(false);
  const [isChatAdminsDrawerOpen, setIsChatAdminsDrawerOpen] = useState(false);

  const { data } = workspacesApi.useGetWorkspaceParticipantsQuery(
    entity?.id && chat.id
      ? {
          entityId: entity.id,
          params: {
            page: 1,
            page_size: 0,
            role: "admin",
          },
          workspaceId: chat.id,
        }
      : skipToken
  );

  const [editWorkspaceIsOpen, setEditWorkspaceIsOpen] = useState(false);

  const handleLeaveWorkspace = useCallback(async () => {
    if (!data?.total_entries) return;
    if (data.total_entries < 2 && chat.role === "admin") {
      setIsLeaveGroupSingleAdminAlertOpen(true);
      return;
    }

    leaveGroupConfirmationAlertState.open({
      metadata: {
        name: chat.title || "",
        type: "leave",
      },
      onConfirm: async () => {
        if (!chat?.id || !entity?.id || !meId) {
          return;
        }
        const resp = await deleteWorkspaceParticipant({
          id: chat?.id,
          entityId: entity?.id,
          userId: meId,
        });
        if ("data" in resp) {
          toast(
            t({
              id: "chats-group.left-group",
              defaultMessage: "Left the group",
            })
          );
          leaveGroupConfirmationAlertState.close();
        }
      },
    });
  }, [
    deleteWorkspaceParticipant,
    chat.id,
    chat.title,
    chat.role,
    entity?.id,
    meId,
    toast,
    t,
    data,
    leaveGroupConfirmationAlertState,
  ]);

  const handleDeleteWorkspace = useCallback(async () => {
    leaveGroupConfirmationAlertState.open({
      metadata: {
        name: chat.title || "",
        type: "delete",
      },
      onConfirm: async () => {
        if (!chat?.id) return;
        try {
          leaveGroupConfirmationAlertState.close();
          await deleteGroupConversation(chat.id);
          toast(
            t(
              {
                id: "chats-page.group-was-deleted",
                defaultMessage: "Group {groupName} was deleted",
              },
              {
                groupName: chat?.title,
              }
            )
          );
        } catch {
          // TODO: error handling
        }
      },
    });
  }, [
    deleteGroupConversation,
    chat.id,
    chat?.title,
    toast,
    t,
    leaveGroupConfirmationAlertState,
  ]);

  const $notificationsIcon = useMemo(() => {
    switch (chatNotificationPreferences) {
      case "all":
        return <NotificationsMuteAllIcon />;
      case "all_except_mentions":
        return <NotificationsMuteAllExceptMentionsIcon />;
      case "none":
      default:
        return <NotificationsMuteNoneIcon />;
    }
  }, [chatNotificationPreferences]);

  return (
    <>
      <Menu
        placement="bottom-end"
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger
            as={PlainButton}
            className="bg-grey-100 h-10 w-10 rounded-lg hover:brightness-90"
            ref={triggerRef}
            onClick={(e) => e.stopPropagation()}
          >
            <ThreeDotsVertical />
          </Trigger>
        )}
        sections={[
          [
            {
              id: "group-settings",
              value: "none",
              isHidden: chat.role !== "admin",
              label: t({
                id: "chats-page.group-settings",
                defaultMessage: "Group Settings",
              }),
              icon: <EditGroupIcon />,
              onSelect: (e, close) => {
                setEditWorkspaceIsOpen(true);
                close();
              },
            },
            {
              id: "2",
              label: t({
                id: "chats-page.mute-chat",
                defaultMessage: "Mute chat",
              }),
              icon: $notificationsIcon,
              submenu: {
                placement: "left",
                sections: [
                  [
                    {
                      id: "none",
                      isSelected: chatNotificationPreferences === "none",
                      value: "none",
                      label: t({
                        id: "chats-page.unmute",
                        defaultMessage: "Unmute",
                      }),
                      toggle: "radio",
                      icon: <NotificationsMuteNoneIcon />,
                      onSelect: (e, close) => {
                        if (!chat?.id) {
                          return;
                        }
                        setChatNotificationsPreferences(
                          chat.id,
                          chat.type,
                          "none"
                        );
                        close();
                      },
                    },
                    {
                      id: "all",
                      isSelected: chatNotificationPreferences === "all",
                      value: "all",
                      label: t({
                        id: "chats-page.mute",
                        defaultMessage: "Mute",
                      }),
                      toggle: "radio",
                      icon: <NotificationsMuteAllIcon />,
                      onSelect: (e, close) => {
                        if (!chat?.id) {
                          return;
                        }
                        setChatNotificationsPreferences(
                          chat.id,
                          chat.type,
                          "all"
                        );
                        close();
                      },
                    },
                    {
                      id: "all_except_mention",
                      isSelected:
                        chatNotificationPreferences === "all_except_mentions",
                      value: "all_except_mention",
                      label: t({
                        id: "chats-page.notify-just-mentions",
                        defaultMessage: "Notify just @mentions",
                      }),
                      toggle: "radio",
                      icon: <NotificationsMuteAllExceptMentionsIcon />,
                      onSelect: (e, close) => {
                        if (!chat?.id) {
                          return;
                        }
                        setChatNotificationsPreferences(
                          chat.id,
                          chat.type,
                          "all_except_mentions"
                        );
                        close();
                      },
                    },
                  ],
                ],
              },
            },
            {
              id: "0",
              label: t({
                id: "chats-page.leave-group",
                defaultMessage: "Leave group",
              }),
              isHidden: !data,
              labelClassName: "text-[#EA5353]",
              icon: <LeaveIcon />,
              onSelect: (e, close) => {
                close();
                handleLeaveWorkspace();
              },
            },
            {
              id: "1",
              isHidden: chat.role !== "admin",
              label: t({
                id: "chats-page.delete-chat",
                defaultMessage: "Delete group",
              }),
              labelClassName: "text-[#EA5353]",
              icon: <DeleteIcon />,
              onSelect: (e, close) => {
                close();
                handleDeleteWorkspace();
              },
            },
          ],
        ]}
      />

      {chat.id && (
        <ChatAdminsDrawer
          isOpen={isChatAdminsDrawerOpen}
          onRequestClose={() => setIsChatAdminsDrawerOpen(false)}
          chatId={chat.id}
        />
      )}
      <LeaveGroupSingleAdminAlert
        isOpen={isLeaveGroupSingleAdminAlertOpen}
        onRequestClose={() => setIsLeaveGroupSingleAdminAlertOpen(false)}
        onDelete={() => {
          setIsLeaveGroupSingleAdminAlertOpen(false);
          handleDeleteWorkspace();
        }}
        onRoleManage={() => {
          setIsLeaveGroupSingleAdminAlertOpen(false);
          setIsChatAdminsDrawerOpen(true);
        }}
      />
      <LeaveGroupConfirmationAlert
        isOpen={leaveGroupConfirmationAlertState.isOpen}
        onRequestClose={leaveGroupConfirmationAlertState.close}
        name={leaveGroupConfirmationAlertState.metadata?.name}
        type={leaveGroupConfirmationAlertState.metadata?.type}
        onConfirm={leaveGroupConfirmationAlertState.confirm}
        isDisabled={isLoading}
      />
      <ChatSettingsDrawer
        isOpen={editWorkspaceIsOpen}
        onRequestClose={() => setEditWorkspaceIsOpen(false)}
        chat={chat}
      />
    </>
  );
};
