export enum ChatMessageAttachmentType {
  image = 1,
  audio = 2,
  video = 3,
  doc = 4,
  otherFile = 5,
  voice = 6,
  gif = 7,
  gifDirectUrl = 8,
}
