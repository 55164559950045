import { FC } from "react";
import { ChatMessageAttachmentType } from "@jugl-web/rest-api";
import { cx } from "@jugl-web/utils";
import { useChatMessageProvider } from "../../../../providers/ChatMessageProvider";
import { VideoMeetingAttachment } from "../VideoMeetingAttachment";
import { MediaPreviewAttachment } from "../MediaPreviewAttachment";
import { DirectGifAttachment } from "../DirectGifAttachment";
import { FileAttachment } from "../FileAttachment";
import { AudioAttachment } from "../AudioAttachment";

export const ChatMessageBubbleAttachment: FC = () => {
  const { message } = useChatMessageProvider();
  const attachment = message.payload.attachments?.[0];
  if (message.payload.conference) {
    return <VideoMeetingAttachment conference={message.payload.conference} />;
  }
  if (!attachment) {
    return null;
  }
  switch (attachment.type) {
    case ChatMessageAttachmentType.image:
    case ChatMessageAttachmentType.gif:
    case ChatMessageAttachmentType.video:
      return <MediaPreviewAttachment />;
    case ChatMessageAttachmentType.gifDirectUrl:
      return <DirectGifAttachment />;
    case ChatMessageAttachmentType.otherFile:
    case ChatMessageAttachmentType.doc:
      return (
        <div className={cx("h-10 px-1")}>
          <FileAttachment />
        </div>
      );
    case ChatMessageAttachmentType.voice:
    case ChatMessageAttachmentType.audio:
      return <AudioAttachment />;
    default:
      return (
        <div className="flex h-[80px] max-w-[300px] items-center justify-center rounded bg-gray-50 px-8 text-center text-sm text-gray-500">
          {/* TODO: i18n */}
          Attachment type temporarily not supported
        </div>
      );
  }
};
