import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { EmptyState } from "@jugl-web/ui-components/cross-platform/ListBox";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useDndMonitor } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useInventoryTable } from "../../../../InventoryTableContext";
import { ReactComponent as DragAndDropIcon } from "./assets/drag-and-drop.svg";

const ColumnItem = ({
  id,
  label,
  visible,
  hideToggle,
  onToggle,
}: {
  id: string;
  visible: boolean;
  label: string;
  hideToggle?: boolean;
  onToggle: (id: string, isVisible: boolean) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString({
      ...transform,
      x: 0,
      y: transform?.y || 0,
      scaleX: transform?.scaleX ?? 1,
      scaleY: transform?.scaleY ?? 1,
    }),
    transition,
  };

  return (
    <InteractiveContainer
      key={id}
      className="flex items-center justify-between gap-2 px-3 py-1.5"
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      id={id}
    >
      <div className="flex items-center gap-3 overflow-hidden">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-md">
          <DragAndDropIcon />
        </div>
        <span className="text-dark truncate text-sm leading-3">{label}</span>
      </div>
      {!hideToggle && (
        <Switch
          isChecked={visible}
          className="shrink-0"
          onChange={() => {
            onToggle(id, !visible);
          }}
        />
      )}
    </InteractiveContainer>
  );
};

export const ManageColumnsPopoverContent: FC<unknown> = () => {
  const { searchQuery, inputProps, reset } = useSearchInput();
  const { tableSettings, setTableSettings } = useInventoryTable();
  const { t } = useTranslations();

  const translations: { [key: string]: string } = {
    name: t({
      id: "common.name",
      defaultMessage: "Name",
    }),
    category: t({
      id: "common.category",
      defaultMessage: "Category",
    }),
    price: t({
      id: "common.price",
      defaultMessage: "Price",
    }),
    unit: t({
      id: "common.unit",
      defaultMessage: "Unit",
    }),
    description: t({
      id: "common.description",
      defaultMessage: "Description",
    }),
    settings: t({
      id: "common.settings",
      defaultMessage: "Settings",
    }),
    stock: t({
      id: "common.stock",
      defaultMessage: "Stock",
    }),
    warehouse: t({
      id: "common.warehouse",
      defaultMessage: "Warehouse",
    }),
    reorder_point: t({
      id: "common.reorder_point",
      defaultMessage: "Reorder Point",
    }),
    processing_orders: t({
      id: "inventory-item-stock-form-page.processing-orders",
      defaultMessage: "Processing Orders",
    }),
    available_for_sale: t({
      id: "inventory-item-stock-form-page.available-for-sale",
      defaultMessage: "Available for sale",
    }),
    sku: t({
      id: "common.sku",
      defaultMessage: "SKU",
    }),
    dimensions: t({
      id: "common.dimensions",
      defaultMessage: "Dimensions",
    }),
    weight: t({
      id: "common.weight",
      defaultMessage: "Weight",
    }),
    upc: t({
      id: "common.upc",
      defaultMessage: "UPC",
    }),
    mpn: t({
      id: "common.mpn",
      defaultMessage: "MPN",
    }),
    ean: t({
      id: "common.ean",
      defaultMessage: "EAN",
    }),
    isbn: t({
      id: "common.isbn",
      defaultMessage: "ISBN",
    }),
  };
  const sortedKeys = Object.keys(tableSettings.columns)
    .map((key) => {
      const column = tableSettings.columns[key];
      return column;
    })
    .filter((column) =>
      translations[column.id]?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.order - b.order);
  const handleToggle = (id: string, isVisible: boolean) => {
    const newColumns = { ...tableSettings };
    newColumns.columns[id] = {
      ...tableSettings.columns[id],
      visible: isVisible,
    };
    setTableSettings(newColumns);
  };

  useDndMonitor({
    onDragEnd: (event) => {
      const newColumns = { ...tableSettings };
      const active = event.active?.id;
      const over = event.over?.id;

      if (over && active && over !== active) {
        const activeOrder = tableSettings.columns[active].order;
        const overOrder = tableSettings.columns[over].order;

        const isMovingUp = activeOrder > overOrder;

        Object.keys(newColumns.columns).forEach((key) => {
          const currentOrder = newColumns.columns[key].order;

          if (isMovingUp) {
            if (currentOrder >= overOrder && currentOrder < activeOrder) {
              newColumns.columns[key].order += 1;
            }
          } else if (currentOrder <= overOrder && currentOrder > activeOrder) {
            newColumns.columns[key].order -= 1;
          }
        });

        newColumns.columns[active].order = overOrder;

        setTableSettings(newColumns);
      }
    },
  });

  return (
    <div className="flex w-[327px] flex-col gap-6">
      <SearchInput
        autoFocus
        variant="filled"
        color="grey"
        onClear={reset}
        {...inputProps}
      />

      <div className="jugl__custom-scrollbar -mr-2 flex max-h-[300px] flex-col gap-2 overflow-y-auto overflow-x-hidden pb-[45px] pr-2">
        <>
          <SortableContext
            items={sortedKeys}
            strategy={verticalListSortingStrategy}
          >
            {sortedKeys.map(({ id, visible }) => (
              <ColumnItem
                label={translations[id]}
                id={id}
                visible={visible}
                hideToggle={id === "name"}
                onToggle={handleToggle}
              />
            ))}
          </SortableContext>
        </>
        {sortedKeys.length === 0 && <EmptyState />}
      </div>
    </div>
  );
};
