import { DEFAULT_AUDIO_WAVEFORM_LENGTH } from "./consts";

export const getAudioWaveform = async (audio: File): Promise<number[]> => {
  const audioContext = new window.AudioContext();
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 64;
  const arrayBuffer = await audio.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(analyser);
  source.start();
  const sound = audioBuffer.getChannelData(0);
  const segmentLength = audioBuffer.length / DEFAULT_AUDIO_WAVEFORM_LENGTH;
  let waveform = Array(DEFAULT_AUDIO_WAVEFORM_LENGTH)
    .fill(0)
    .map((_, i) => {
      const start = Math.floor(i * segmentLength);
      const end = Math.floor((i + 1) * segmentLength);
      const segmentSound = sound.slice(start, end);
      const averageVolume = Math.sqrt(
        segmentSound.reduce((acc, sample) => acc + sample * sample, 0) /
          segmentSound.length
      );
      return averageVolume;
    });
  const maxVolume = Math.max(...waveform);
  if (maxVolume === 0) {
    return waveform;
  }
  waveform = waveform.map((volume) => volume / maxVolume);
  source.stop();
  audioContext.close();
  return waveform;
};
