import {
  Button,
  ConfirmationPopup,
} from "@jugl-web/ui-components/cross-platform";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { isEqual } from "lodash";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DropdownValueList,
  DropdownValueListItem,
  DropdownValuesListHandle,
} from "@jugl-web/domain-resources/custom-fields/components/DropdownValueList/DropdownValueList";
import { EditableDropdownFieldName } from "@jugl-web/domain-resources/custom-fields/components/EditableDropdownFieldName/EditableDropdownFieldName";
import { ReactComponent as AddIcon } from "../assets/add.svg";
import { ReactComponent as ChecklistIcon } from "../assets/checklist.svg";
import { ReactComponent as WarningIcon } from "../assets/warning.svg";
import { ManageProfileAttributesDialogScreenToParametersMap } from "../types";

interface UserCustomDropdownFieldValuesScreenProps {
  fieldName: string;
  items: DropdownValueListItem[];
  submitButton: {
    label: string;
    isDisabled: boolean;
    onClick: () => void;
  };
  onChangeFieldName: Dispatch<SetStateAction<string>>;
  onChangeItems: Dispatch<SetStateAction<DropdownValueListItem[]>>;
  onCancel: () => void;
}

export const UserCustomDropdownFieldValuesScreen: FC<
  UserCustomDropdownFieldValuesScreenProps
> = ({
  fieldName,
  items,
  submitButton,
  onChangeFieldName,
  onChangeItems,
  onCancel,
}) => {
  const { variant } = useAppVariant();
  const { t } = useTranslations();

  const $cachedItemsRef = useRef<DropdownValueListItem[]>(items);
  const $dropdownValueListRef = useRef<DropdownValuesListHandle | null>(null);

  const [discardChangesDialogState, setDiscardChangesDialogState] = useState<{
    onDiscard: () => void;
  } | null>(null);
  const isDiscardChangesDialogOpen = !!discardChangesDialogState?.onDiscard;

  const { transitionRequest$ } =
    useScreenTransitionManager<ManageProfileAttributesDialogScreenToParametersMap>();

  const hasNoItem = items.length === 0 || items[0].text === "";

  const haveItemsChanged = useCallback(() => {
    if ($cachedItemsRef.current.length !== items.length) {
      return true;
    }

    return !isEqual($cachedItemsRef.current, items);
  }, [items]);

  useEffect(() => {
    const subscription = transitionRequest$.subscribe((request) => {
      if (haveItemsChanged()) {
        setDiscardChangesDialogState({ onDiscard: request.resolve });
      } else {
        request.resolve();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [transitionRequest$, haveItemsChanged]);

  return (
    <>
      <BottomCenteredDrawer.Content>
        <div
          className="flex items-center justify-between px-12 pt-6 pb-4"
          style={{
            boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <EditableDropdownFieldName
            fieldName={fieldName}
            onChange={(updatedFieldName) =>
              onChangeFieldName(updatedFieldName.trimStart())
            }
          />
          <Button
            variant="contained"
            color="grey"
            className="h-10 p-3 font-normal"
            iconStart={<ChecklistIcon />}
            iconEnd={<AddIcon className="ml-4" />}
            onClick={() => $dropdownValueListRef.current?.addItem()}
          >
            {t({
              id: "cpanel-page.add-new-value",
              defaultMessage: "Add new value",
            })}
          </Button>
        </div>
        <DropdownValueList
          ref={$dropdownValueListRef}
          items={items}
          onChange={onChangeItems}
          autoAddItem
          className="mt-6 px-12"
        />
      </BottomCenteredDrawer.Content>
      <BottomCenteredDrawer.Actions className="flex items-center justify-center gap-3.5">
        <Button
          variant="contained"
          color="grey"
          className="w-[200px]"
          onClick={onCancel}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="w-[200px]"
          isDisabled={hasNoItem || submitButton.isDisabled}
          onClick={submitButton.onClick}
        >
          {submitButton.label}
        </Button>
      </BottomCenteredDrawer.Actions>
      <ConfirmationPopup
        variant={variant}
        isOpen={isDiscardChangesDialogOpen}
        icon={<WarningIcon />}
        title={t({
          id: "cpanel-page.discard-changes-warning-title",
          defaultMessage: "Discard changes",
        })}
        message={t({
          id: "cpanel-page.discard-changes-warning-description",
          defaultMessage:
            "If you discard changes, all dropdown values will be deleted. Are you sure?",
        })}
        hasCancelButton
        buttons={[
          {
            label: t({
              id: "common.discard",
              defaultMessage: "Discard",
            }),
            color: "primary",
            onClick: () => {
              setDiscardChangesDialogState(null);
              discardChangesDialogState?.onDiscard();
            },
          },
        ]}
        onRequestClose={() => setDiscardChangesDialogState(null)}
      />
    </>
  );
};
