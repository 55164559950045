import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as PlayIcon } from "./assets/play.svg";
import { ReactComponent as PauseIcon } from "./assets/pause.svg";
import { RangeSlider } from "./components/RangeSlider";

export const ChatAudioPlayer: React.FC<{
  src: string;
  name: string;
  variant: "incoming" | "outgoing";
}> = ({ src, name, variant }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [fullDuration, setFullDuration] = useState(0);
  const [playingDuration, setPlayingDuration] = useState(0);
  const [isReady, setIsReady] = React.useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const timeRef = useRef<HTMLSpanElement>(null);
  const playAnimationRef = useRef<number>();
  const isOutgoing = variant === "outgoing";

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleProgressChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setPlayingDuration(value);
    }
  };

  const updateCurrentTimeAnimation = useCallback(() => {
    if (!audioRef.current) {
      playAnimationRef.current = requestAnimationFrame(
        updateCurrentTimeAnimation
      );
      return;
    }
    setPlayingDuration(audioRef.current.currentTime);
    playAnimationRef.current = requestAnimationFrame(
      updateCurrentTimeAnimation
    );
  }, []);

  useEffect(() => {
    if (isPlaying) {
      updateCurrentTimeAnimation();
    } else if (playAnimationRef.current) {
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [isPlaying, updateCurrentTimeAnimation]);

  const buttonClassNames = useMemo(() => {
    if (isOutgoing) {
      if (isPlaying) {
        return "bg-white text-gradients-g3 hover:bg-grey-200";
      }
      return "bg-white text-primary hover:bg-grey-200";
    }
    if (isPlaying) {
      return "bg-gradients-g3 text-white hover:bg-[#9D4AD7]";
    }
    return "bg-primary text text-white hover:bg-primary-600";
  }, [isPlaying, isOutgoing]);

  return (
    <div className="flex items-center gap-2.5 p-2">
      <audio
        hidden
        onEnded={() => setIsPlaying(false)}
        ref={audioRef}
        onLoadedMetadata={(e) => setFullDuration(e.currentTarget.duration)}
        onCanPlay={() => {
          setIsReady(true);
        }}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        src={src}
      />
      <InteractiveContainer
        onClick={togglePlayPause}
        className={cx(
          "bg-primary flex h-11 w-11 shrink-0 items-center justify-center rounded-full transition-colors",
          buttonClassNames
        )}
        isDisabled={!isReady}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </InteractiveContainer>
      <div className="flex grow flex-col gap-0.5 overflow-hidden">
        <span
          className={cx(
            "truncate font-medium",
            isOutgoing ? "text-white" : "text-dark"
          )}
        >
          {name}
        </span>
        <div className="flex items-center gap-2">
          <span
            className={cx("text-sm", isOutgoing ? "text-white" : "text-grey")}
            ref={timeRef}
          >
            {formatTime(playingDuration || fullDuration)}
          </span>
          {isPlaying || playingDuration > 0 ? (
            <RangeSlider
              onChange={handleProgressChange}
              variant={variant}
              min={0}
              max={fullDuration}
              step={0.0001}
              value={playingDuration}
            />
          ) : (
            <div className="w-[176px]" />
          )}
        </div>
      </div>
    </div>
  );
};
