import { InventoryItemDataModel } from "@jugl-web/rest-api/inventory/models/InventoryItem";
import { useTranslations, priceDisplay, cx } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { parseDimensionsString } from "@web-src/modules/inventory/pages/InventoryItemFormPage/utils";
import { useMemo, Fragment } from "react";
import { Linkify } from "@jugl-web/utils/utils/Linkify";

export const ItemInfoTab = ({ data }: { data: InventoryItemDataModel }) => {
  const { desc, price, category, unit, info } = data;
  const parsedDimensions = info?.dim && parseDimensionsString(info?.dim);
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const tableData = useMemo(
    () => [
      {
        id: "description",
        label: t({
          id: "common.description",
          defaultMessage: "Description",
        }),
        value: (
          <div title={desc} className="max-w-[400px] truncate">
            <Linkify>{desc}</Linkify>
          </div>
        ),
      },
      {
        id: "unit",
        label: t({
          id: "common.unit",
          defaultMessage: "Unit",
        }),
        value: unit,
        isHidden: !unit || category === "service",
      },
      {
        id: "price",
        label: t({
          id: "common.price",
          defaultMessage: "Price",
        }),
        value: (
          <>
            {priceDisplay(parseFloat(price) * 100, entity.currency)}{" "}
            <span className="text-grey-700 pl-1">
              /
              {category === "service"
                ? t({
                    id: "inventory-items-sidebar.service",
                    defaultMessage: "Service",
                  })
                : unit}
            </span>
          </>
        ),
      },
      {
        id: "dimensions",
        label: t({
          id: "common.dimensions",
          defaultMessage: "Dimensions",
        }),
        isHidden:
          category === "service" ||
          !info?.dim ||
          (parsedDimensions &&
            Object.entries(parsedDimensions.value).every(
              ([, value]) => !value
            )),
        value: (
          <>
            {parsedDimensions ? (
              <>
                {parsedDimensions.value.width || 0} x{" "}
                {parsedDimensions.value.height || 0} x{" "}
                {parsedDimensions.value.depth || 0} {parsedDimensions.unit}
              </>
            ) : null}
          </>
        ),
      },
      {
        id: "weight",
        label: t({
          id: "common.weight",
          defaultMessage: "Weight",
        }),
        value: info?.weight,
        isHidden: !info?.weight || category === "service",
      },
      {
        id: "sku",
        label: t({
          id: "common.sku",
          defaultMessage: "SKU",
        }),
        value: info?.sku,
        isHidden: !info?.sku || category === "service",
      },
      {
        id: "upc",
        label: t({
          id: "common.upc",
          defaultMessage: "UPC",
        }),
        value: info?.upc,
        isHidden: !info?.upc || category === "service",
      },
      {
        id: "mnp",
        label: t({
          id: "common.mpn",
          defaultMessage: "MPN",
        }),
        value: info?.mpn,
        isHidden: !info?.mpn || category === "service",
      },
      {
        id: "ean",
        label: t({
          id: "common.ean",
          defaultMessage: "EAN",
        }),
        value: info?.ean,
        isHidden: !info?.ean || category === "service",
      },
      {
        id: "isbn",
        label: t({
          id: "common.isbn",
          defaultMessage: "ISBN",
        }),
        value: info?.isbn,
        isHidden: !info?.isbn || category === "service",
      },
    ],
    [category, desc, entity.currency, info, parsedDimensions, price, t, unit]
  );

  return (
    <div className="align-between flex h-full w-full border-collapse flex-col justify-between overflow-hidden overflow-y-auto pt-10">
      <div className="bg-dark-100 border-dark-100 grid border-collapse grid-cols-[160px_auto] gap-[1px] overflow-hidden rounded-xl border border-solid">
        {tableData.map(
          (row) =>
            !row.isHidden && (
              <Fragment key={row.label}>
                <div
                  className={cx(
                    "text-dark font-secondary border-dark-100 flex h-[53px] w-[160px] border-collapse items-center bg-white pl-4 text-sm font-medium"
                  )}
                >
                  <div className="w-[160px]">{row.label}</div>
                </div>
                <div
                  className={cx(
                    "text-dark-800 border-dark-100 font-secondary flex h-[53px] w-full border-collapse items-center bg-white pl-4 text-left text-sm"
                  )}
                >
                  {row.value}
                </div>
              </Fragment>
            )
        )}
      </div>
    </div>
  );
};
