import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useMemo, useState } from "react";
import {
  Button,
  InteractiveContainer,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import {
  apiUTCToLocalDateTime,
  cx,
  priceDisplay,
  useTranslations,
} from "@jugl-web/utils";
import format from "date-fns/format";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { AppSumoSubscriptionChangeAlert } from "@web-src/modules/entities/components/AppSumoSubscriptionChangeAlert";
import { useEntitySubscriptionProvider } from "@web-src/modules/entities/providers/EntitySubscriptionProvider";
import { addDays } from "date-fns";
import { SubscriptionInfoItem } from "./components/SubscriptionInfoItem";
import { BillingInfoItem } from "./components/BillingInfoItem";
import { PaymentMethodInfoItem } from "./components/PaymentMethodInfoItem";
import { SectionTitle } from "./components/SectionTitle";
import { InvoicesTable } from "./components/InvoicesTable";
import {
  WorkspaceSubscriptionPageProvider,
  useWorkspaceSubscriptionPageProvider,
} from "./WorkspaceSubscriptionPageProvider";
import { SeatsUpdateSideBar } from "./components/SeatsUpdateSideBar";
import { CancelSubscriptionAlert } from "./components/CancelSubscriptionAlert";
import { SettingsTabsLayout } from "../../components/SettingsTabsLayout/SettingsTabsLayout";
import { SubscriptionIncompleteItem } from "./components/SubscriptionIncompleteItem";

const WorkspaceSubscriptionPageContent: React.FC = () => {
  const { subscriptionInfo } = useEntitySelectedProvider();
  const { navigateToManageSubscription, isCardPaymentNotSupported } =
    useEntitySubscriptionProvider();
  const { seatsUpdateState } = useWorkspaceSubscriptionPageProvider();
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const [isDealMirrorChangeAlertVisible, setIsAppSumoChangeAlertVisible] =
    useState<boolean>(false);
  const { workspaceDeleteDate }: { workspaceDeleteDate: string } = useMemo(
    () => ({
      workspaceDeleteDate: format(
        addDays(apiUTCToLocalDateTime(subscriptionInfo.graceTill), 30),
        "MMMM dd, yyyy"
      ),
    }),
    [subscriptionInfo]
  );

  const isSubscriptionExpired = subscriptionInfo?.planIsActive === false;

  const subscriptionInfoText: { text: string; className?: string } | null =
    useMemo(() => {
      if (
        !subscriptionInfo ||
        isSubscriptionExpired ||
        subscriptionInfo.isDealMirror
      ) {
        return null;
      }
      const humanizedValidFromTime = format(
        apiUTCToLocalDateTime(subscriptionInfo.validFrom),
        "dd MMMM, yyyy"
      );
      const humanizedValidTillTime = format(
        apiUTCToLocalDateTime(subscriptionInfo.planTill),
        "dd MMMM, yyyy"
      );
      if (subscriptionInfo.status === "trialing") {
        return {
          text: `Trial started on ${humanizedValidFromTime} - Ends on ${humanizedValidTillTime}`,
        };
      }
      if (subscriptionInfo.isInGrace) {
        return {
          text: `Grace Period Ends on ${format(
            apiUTCToLocalDateTime(subscriptionInfo.graceTill),
            "dd MMMM, yyyy"
          )}`,
        };
      }
      if (subscriptionInfo.status === "active" && subscriptionInfo.isInPlan) {
        return {
          text: `Subscription will renew on ${format(
            apiUTCToLocalDateTime(subscriptionInfo.planTill),
            "dd MMMM, yyyy"
          )}`,
        };
      }
      if (subscriptionInfo.status === "canceled") {
        return {
          text: `Subscription will end on ${format(
            apiUTCToLocalDateTime(subscriptionInfo.planTill),
            "dd MMMM, yyyy"
          )}`,
          className: "text-red-500",
        };
      }
      return null;
    }, [isSubscriptionExpired, subscriptionInfo]);

  const isPaymentFailed =
    subscriptionInfo.status === "incomplete" ||
    subscriptionInfo.status === "past_due";
  const canCancelSubscription =
    (subscriptionInfo?.status === "active" || isPaymentFailed) &&
    subscriptionInfo.planInfo?.type !== "basic" &&
    subscriptionInfo.planIsActive;
  const [isCancelSubscriptionOpen, setIsCancelSubscriptionOpen] =
    useState(false);

  return (
    <>
      <CancelSubscriptionAlert
        isOpen={isCancelSubscriptionOpen}
        onRequestClose={() => setIsCancelSubscriptionOpen(false)}
      />
      <AppSumoSubscriptionChangeAlert
        isOpen={isDealMirrorChangeAlertVisible}
        onRequestClose={() => setIsAppSumoChangeAlertVisible(false)}
        onConfirm={() => navigateToPage("workspaceSelectSubscription")}
      />
      <SettingsTabsLayout
        selectedTab="subscription"
        isSubscriptionExpired={isSubscriptionExpired}
        rightContent={
          <div className="flex gap-4">
            {isSubscriptionExpired && (
              <Button
                onClick={() => navigateToPage("entitySelect")}
                color="white"
              >
                {t({
                  id: "common.switch-workspace",
                  defaultMessage: "Switch workspace",
                })}
              </Button>
            )}
            <Button onClick={navigateToManageSubscription} color="primary">
              {t({
                id: "common.manage-subscription-plan",
                defaultMessage: "Manage plan",
              })}
            </Button>
          </div>
        }
      >
        <MultiSectionLayout
          rightSide={seatsUpdateState !== 0 && <SeatsUpdateSideBar />}
        >
          <div className="p-12">
            <div className="flex flex-col gap-2">
              <SectionTitle>Subscription</SectionTitle>
              <div className="flex items-center gap-2">
                {subscriptionInfo.balance < 0 &&
                  subscriptionInfo.planInfo?.currency && (
                    <>
                      <div className="text-grey-900 text-sm">
                        Unused balance:{" "}
                        {priceDisplay(
                          -subscriptionInfo.balance,
                          subscriptionInfo.planInfo?.currency
                        )}
                      </div>
                      <div className="bg-grey-900 h-1 w-1 rounded-full" />
                    </>
                  )}
                {subscriptionInfoText && (
                  <div
                    className={cx(
                      "text-grey-900 text-sm",
                      subscriptionInfoText.className
                    )}
                  >
                    {subscriptionInfoText.text}
                  </div>
                )}
              </div>
              {isSubscriptionExpired && (
                <div className="text-tertiary-800">
                  {t({
                    id: "workspace-settings-page.select-plan-not-to-lose-workspace",
                    defaultMessage:
                      "Select your Plan, not to lose the Workspace.",
                  })}
                  <br />
                  {t(
                    {
                      id: "workspace-settings-page.workspace-will-be-deleted",
                      defaultMessage:
                        "On {removeDate}, this Workspace will be unfortunately deleted",
                    },
                    {
                      removeDate: workspaceDeleteDate,
                    }
                  )}
                </div>
              )}
              {canCancelSubscription && (
                <InteractiveContainer
                  className="text-primary-500 inline-block w-max underline"
                  onClick={async () => {
                    setIsCancelSubscriptionOpen(true);
                  }}
                >
                  Cancel Subscription
                </InteractiveContainer>
              )}
            </div>
            {!!subscriptionInfo && (
              <div className="mt-8 mb-20 flex flex-col gap-4">
                {isPaymentFailed && (
                  <SubscriptionIncompleteItem
                    subscriptionInfo={subscriptionInfo}
                  />
                )}
                <SubscriptionInfoItem subscriptionInfo={subscriptionInfo} />
                <BillingInfoItem />
                {!isCardPaymentNotSupported && (
                  <PaymentMethodInfoItem subscriptionInfo={subscriptionInfo} />
                )}
              </div>
            )}
          </div>
          <InvoicesTable />
        </MultiSectionLayout>
      </SettingsTabsLayout>
    </>
  );
};

export const WorkspaceSubscriptionPage: React.FC = () => (
  <WorkspaceSubscriptionPageProvider>
    <WorkspaceSubscriptionPageContent />
  </WorkspaceSubscriptionPageProvider>
);
